import React, { useState } from "react";
import { FurnitureFormType } from "../../../../Models/Furniture/type";
import { Description, Link, TooltipBox, TooltipCard, TooltipText } from "../../style";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  Furniture: FurnitureFormType;
}

const FurnitureDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const handleShowTooltip = () => {
    setShowTooltip(!showTooltip);
  }

  return (
    <div>
      <div>


        {activity.Furniture.Mobiliario?.map((item, index) => (
          <div key={index}>
            {activity.Furniture.Mobiliario.length > 1 ? (<u>Mobiliário {index + 1}</u>) : (<u>Mobiliário </u>)}

            {showTooltip === true ? (
              <TooltipCard >
                <TooltipText>
                  Descrição completa
                </TooltipText>
                <TooltipBox>
                  {item.attributes.map((tarefa) => (
                    tarefa.itemName === 'Item' ? (
                      <p >{tarefa.value}</p>
                    ) : null
                  ))}
                </TooltipBox>
              </TooltipCard>
            ) : null}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value && tarefa.itemName !== 'Item' && tarefa.value && tarefa.itemName !== 'Link' || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  {tarefa.itemName === 'Link' ? (

                    <Link target="_blank" href={tarefa.value}>
                      <p >{tarefa.value}</p>
                    </Link>
                  ) : null}

                  {tarefa.itemName === 'Item' ? (
                    <Description onClick={handleShowTooltip} >
                      <p>{tarefa.value}</p>
                    </Description>
                  ) : null}

                </p>
              </div>
            ))}

            <hr />
          </div>
        ))}

      </div>
    </div>
  );
};

export default FurnitureDescription;