import React from "react";

import { Container, Logo, ProjectDetails } from "./style";

import logoImg from "../../assets/arquitetool.svg";

interface HeaderProps {
  projetcName?: string;
  adress?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <Container>
      <Logo>
        <img src={logoImg} alt="Logo arquitetools" />
      </Logo>

      <ProjectDetails>
        <p className="projectName">{props?.projetcName}</p>
        <p className="adress">{props?.adress}</p>
      </ProjectDetails>

      <div></div>
    </Container>
  );
};

export default Header;
