import styled from "styled-components";

export const Container=styled.div`
    display: grid;
    grid-gap: 1.5rem;
    padding: 1rem;
`

export const FlexElement=styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`

export const Input = styled.input`
    margin-left: 0.3rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #F1F1F2;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 0.3rem;
    padding: 0.4rem;
    max-width: 80px;
`

export const CenterButton=styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`