import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  ContanierFinalization,
  ItemContainerFinzalization,
  TotalContainer,
  ContainerButtonSendBudget,
} from "../../style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"

interface budgetProps {
  activity: activitiesProps,
  supplier: SupplierProps
  margin:number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  numero_folhas?: string,
  altura_porta?: string,
  comprimento_porta?: string,
  material_ferragem?: string,
  descricao?: string,

  cor_ferragem?: string,
  cor_vidro?: string,
  tipo_porta?: string,
  porta_outros?: string
  material_acabamento_outros?: string
}

const PortaVidroEsquadriasBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [portaVidroEsquadrias, setPortaVidroEsquadrias] = useState<string>('0,00')
  const [ferragem, setFerragem] = useState<string>('0,00')

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    setTotalBudgetPayment(sum([portaVidroEsquadrias, ferragem]))
  }, [portaVidroEsquadrias, ferragem])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([portaVidroEsquadrias, ferragem]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const submitBudget = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          portaVidroEsquadrias: (Number(portaVidroEsquadrias.replace(',', '.')) + Number(portaVidroEsquadrias.replace(',', '.')) * (props.margin / 100)).toString(),
          ferragem: (Number(ferragem.replace(',', '.')) + Number(ferragem.replace(',', '.')) * (props.margin / 100)).toString(),
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItens>
        <div className="upContent">
          <p>Itens a serem trabalhados</p>
          <p>Medidas</p>
          <p>Preço unit.</p>
          <p className="item">Preço total</p>
        </div>

        {activity.tipo_porta !== '' ? (
          <ItemContentList>
            <p>Tipo: {activity.tipo_porta === "Outros" ? (
              activity.porta_outros
            ) : (
              activity.tipo_porta
            )}, Cor do vidro: {activity.cor_vidro}</p>
            <p>{activity.altura_porta}m x {activity.comprimento_porta}m | {activity.numero_folhas} Folhas</p>
            <ContentInputPrice>
              R$:
              <PriceItem onChange={(e) => setPortaVidroEsquadrias(multiply('1', e.target.value))} />
            </ContentInputPrice>
            {portaVidroEsquadrias && portaVidroEsquadrias !== '0' ?
              (<p className="item">{portaVidroEsquadrias}</p>) :
              (<p className="item">0,00</p>)
            }
          </ItemContentList>
        ) : (<></>)}

        {
          activity.material_ferragem ? (
            <div className="upContent">
              <p>Ferragem</p>
              <p>Cor</p>
              <p>Preço unit.</p>
              <p className="item">Preço total</p>
            </div>
          ) : (<></>)
        }

        {activity.material_ferragem ? (
          <ItemContentList>
            {activity.material_ferragem === "Outros" ? (
              <p>{activity.material_acabamento_outros}</p>
            ) : (
              <p>{activity.material_ferragem}</p>
            )}
            <p>{activity.cor_ferragem}</p>
            <ContentInputPrice>
              R$:
              <PriceItem onChange={(e) => setFerragem(multiply('1', e.target.value))} />
            </ContentInputPrice>
            {ferragem && ferragem !== '0' ?
              (<p className="item">{ferragem}</p>) :
              (<p className="item">0,00</p>)
            }
          </ItemContentList>
        ) : (<></>)}
        


      </ListContainerItens>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default PortaVidroEsquadriasBudget;