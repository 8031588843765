import { activityAndPendencesType } from "../type"

export const editBeforeInitServiceActivityName = (activityStateVar: activityAndPendencesType, setStateVar: any, indexActivity: number, textValue: string) => {
    const activityCopy = activityStateVar
    activityCopy.architectActivities.beforeInitService.splice(indexActivity, 1, { activity: textValue, check: activityCopy.architectActivities.beforeInitService[indexActivity].check, renameOrDelete: activityCopy.architectActivities.beforeInitService[indexActivity].renameOrDelete })
    setStateVar({ ...activityCopy })
}

export const editDuringServiceActivityName = (activityStateVar: activityAndPendencesType, setStateVar: any, indexActivity: number, textValue: string) => {
    const activityCopy = activityStateVar
    activityCopy.architectActivities.duringService.splice(indexActivity, 1, { activity: textValue, check: activityCopy.architectActivities.duringService[indexActivity].check, renameOrDelete: activityCopy.architectActivities.duringService[indexActivity].renameOrDelete })
    setStateVar({ ...activityCopy })
}

export const editAfterServiceActivityName = (activityStateVar: activityAndPendencesType|any, setStateVar: any, indexActivity: number, textValue: string) => {
    const activityCopy = activityStateVar
    activityCopy.architectActivities.afterService.splice(indexActivity, 1, { activity: textValue, check: activityCopy.architectActivities.afterService[indexActivity].check, renameOrDelete: activityCopy.architectActivities.afterService[indexActivity].renameOrDelete })
    setStateVar({ ...activityCopy })
}
