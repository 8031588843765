import React, { useContext, useState } from "react";
import { UserProviderProps } from "../types/typesEntitiesProject";

interface ModalContextTypes {
  showModal: boolean;
  setShowModal?: () => void;
  modalNameHeader: string;
  dependencesModal: boolean;
  setShowDependences: () => void;
  setModalNameHeader: React.Dispatch<React.SetStateAction<string>>;
}

const defaultStepModal = {
  showModal: false,
  modalNameHeader: "",
  setModalNameHeader: () => {},
  dependencesModal: false,
  setShowDependences: () => {},
};

const ModalContext = React.createContext<ModalContextTypes>(defaultStepModal);

export const UseModalContext = () => useContext(ModalContext);

export const ModalProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [showModal, setShow] = useState(defaultStepModal.showModal);
  const [dependencesModal, setShowDependencesModal] = useState(false);

  const [modalNameHeader, setModalNameHeader] = useState<string>("");

  const setShowModal = () => {
    setShow(!showModal);
  };

  const setShowDependences = () => {
    setShowDependencesModal(!dependencesModal);
  };

  return (
    <ModalContext.Provider
      value={{
        dependencesModal,
        showModal,
        modalNameHeader,
        setShowModal,
        setModalNameHeader,
        setShowDependences,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
