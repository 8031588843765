import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { budgetsParams } from "../../types/paramId";
import { ProjectProps, /* SupplierProps */ } from '../../types/typesEntitiesProject';
import { DetailmentBudgetActivityContextProvider } from './Context/DetailmentBudgetActivityContext';
import { useParams } from "react-router-dom";

import HeaderBudgets from "../../components/HeaderBudgets";
import NavigationMenu from './Components/NavigationMenu';
import ListNecessities from './Components/NecessitiesList';
import SendDocuments from './Components/SendDocuments';

import { Container } from "./style"


const DetailmentBudgetActivity: React.FC = () => {
    const paramUrl = useParams();

    const [projetc, setProjetc] = useState<ProjectProps>();
    // const [supplier, setSupplier] = useState<SupplierProps>({});

    const [optionSelected, setOptionSelected] = useState<string>('optionSendDocument')

    useEffect(() => {
        if (paramUrl.projectId) {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}/project/${paramUrl.projectId}`)
                .then((res) => {
                    setProjetc(res.data[0]);
                });
        }
    }, [paramUrl.projectId]);


    return (
        <DetailmentBudgetActivityContextProvider>
            <HeaderBudgets projetcName={projetc?.name} adress={projetc?.adress} />
            <Container>
                <NavigationMenu optionSelected={optionSelected} setOptionSelected={setOptionSelected} />

                {optionSelected === "optionSendDocument" ? (
                    <SendDocuments setOptionSelected={setOptionSelected} />
                ) :
                    optionSelected === "necessitiesList" ? (
                        <ListNecessities />
                    ) : (<></>)}
            </Container>
        </DetailmentBudgetActivityContextProvider>
    )
}

export default DetailmentBudgetActivity