import React from "react";
import { allActivitiesProps } from "../../../types/typesEntitiesProject"

import DoorsAndWindowsMaterialsDescription from "../Materials/index";
import DoorsAndWindowsDescription from "./DoorsAndWindows";

interface props {
    activity: allActivitiesProps
}

const DoorsAndWindowsStepDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Portas e janelas" ? (<DoorsAndWindowsDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<DoorsAndWindowsMaterialsDescription activity={props.activity} />) : (<></>)}
        </>
    )
}

export default DoorsAndWindowsStepDescription