import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
  DowloadContent,
} from "../style";

import {
  activitiesProps,
  SupplierProps,
} from "../../../types/typesEntitiesProject";

import { multiply, sum } from "../script/calculate";

import FooterBudget from "../Components/FooterBudget";

interface budgetProps {
  activity: activitiesProps;
  supplier: SupplierProps;
  margin: number;
}

interface WoodWorkProps {
  quantidade?: string;
  nome?: string;
  arquivoProjeto?: FileList[];
  link?: string;
  valor?: string;
}

interface activityProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string;
  activityType?: string;
  stepType?: string;

  projetoMarcenaria?: WoodWorkProps[];
}

const MarcenariaBudget: React.FC<budgetProps> = (props) => {
  const [activity, setActivity] = useState<activityProps>(props.activity);

  const [projetoMarcenaria, setProjetoMarcenaria] = useState<string[]>([
    "0,00",
  ]);

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>("0,00");
  const [conditions, setConditions] = useState<string>("");

  useEffect(() => {
    activity.projetoMarcenaria?.forEach(() => {
      setProjetoMarcenaria((prev) => [...prev, "0,00"]);
    });
  }, [activity]);

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    setTotalBudgetPayment(sum(projetoMarcenaria));
  }, [projetoMarcenaria]);

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(
        parseFloat(minimumBudgetValue).toFixed(2).replace(".", ",")
      );
    } else {
      setTotalBudgetPayment(sum(projetoMarcenaria));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (
    index: number,
    stateVar: string[],
    setStateRef: any,
    value: string,
    quantityMultiply?: string
  ) => {
    var stateCopy = Array.from(stateVar);
    quantityMultiply
      ? stateCopy.splice(
          index,
          1,
          multiply(quantityMultiply, value.replace(",", "."))
        )
      : stateCopy.splice(index, 1, multiply("1", value.replace(",", ".")));
    setStateRef(stateCopy);

    var activityCopy = activity;
    if (activityCopy.projetoMarcenaria) {
      activityCopy.projetoMarcenaria[index].valor = stateCopy[index];
      setActivity(activityCopy);
    }
  };

  const submitBudget = () => {
    const projetoMarcenariaMargin: number[] = [];

    projetoMarcenaria.forEach((item) => {
      if (item != "0,00") {
        projetoMarcenariaMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    if (activity.projetoMarcenaria) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
          projectId: activity?.projectId,
          activityId: activity?._id,
          stepId: activity.stepId,
          providerId: props.supplier._id,
          supplierName: props.supplier.supplierName,
          typeSupplier: props.supplier.typeSupplier,
          activityName: activity.activityName,
          conditions,
          selectedStartDate: "",
          workingDays,
          selectedBudget: false,
          budget: {
            projetoMarcenaria: projetoMarcenariaMargin,
          },
          minimumBudgetValue,
          totalBudgetPayment: (
            Number(totalBudgetPayment.replace(",", ".")) +
            Number(totalBudgetPayment.replace(",", ".")) * (props.margin / 100)
          ).toString(),
        })
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          }
        });
    }
  };

  return (
    <div>
      <ListContainerItens>
        {activity.projetoMarcenaria?.length ? (
          <>
            {activity.projetoMarcenaria.map((element, index) => (
              <>
                <div className="upContent">
                  <p>Projeto</p>
                  <p>Quantidade</p>
                  <p>Preço unit.</p>
                  <p className="item">Preço total</p>
                </div>

                <ItemContentList key={index}>
                  <p>
                    {element.nome}{" "}
                    <DowloadContent href={element.link}>
                      Fazer download
                    </DowloadContent>
                  </p>
                  <p>{element.quantidade} un.</p>
                  <ContentInputPrice>
                    R$:
                    <PriceItem
                      onChange={(e) =>
                        updateState(
                          index,
                          projetoMarcenaria,
                          setProjetoMarcenaria,
                          e.target.value,
                          element.quantidade
                        )
                      }
                    />
                  </ContentInputPrice>
                  {projetoMarcenaria[index] !== "0" ? (
                    <p className="item">{projetoMarcenaria[index]}</p>
                  ) : (
                    <p className="item">0,00</p>
                  )}
                </ItemContentList>
              </>
            ))}
          </>
        ) : (
          <></>
        )}
      </ListContainerItens>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  );
};

export default MarcenariaBudget;
