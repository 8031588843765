export const Forro = {
    Forro: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm²',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: 'Gesso'
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Sanca: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Cortineiro: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Tabica: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ]
}
