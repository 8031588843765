import styled from "styled-components";

interface ButtonProps {
  backgroundColor: string;
  borderColor: string;
}

export const Page = styled.div`
  touch-action: manipulation;
  @media (max-width: 480px) {
    max-width: 152em;
    -webkit-transform: scale(0.16);
    -webkit-transform-origin: 0 0;
  }
`;

export const Container = styled.div`
  @media (max-width: 480px) {
    max-width: 152em;
    -webkit-transform: scale(0.16);
    -webkit-transform-origin: 0 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 100px;
  }

  select {
    @media (max-width: 480px) {
      font-size: 100px;
      width: 16.2em;
      height: 2.5em;
      margin-left: 1em;
      border: 5px solid #e5e5e5;
    }
  }

  P {
    margin-right: 0.5rem;
  }
`;

export const ButtonNewProject = styled.a`
  padding: 0.75vw;
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 0.3rem;
  :hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
  img {
    margin-right: 0.5rem;
    color: var(--secondary-color);
  }

  @media (max-width: 480px) {
    width: 21.3em;
    font-size: 100px;
    margin-left: 1em;
    height: 2.5em;
    border: 3px solid var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.3em;

    img {
      width: 1em;
      margin-right: 0.5em;
    }
  }

  cursor: pointer;
`;

export const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding-left: 1rem;
  padding-right: 0.5rem;
  grid-gap: 1rem;
  margin: 0 1.5rem 1.5rem 1.5rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    width: 150em;
  }

  .NoregisterIdentifield {
    position: absolute;
    width: 100vw;

    P {
      display: flex;
      justify-content: center;
      margin-top: 5rem;
    }
  }
`;

export const Form = styled.div`
  .grid1,
  .grid2,
  .grid3,
  .grid4,
  .grid5 {
    display: grid;
  }

  .grid1 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid2 {
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
  }

  .grid3 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid4 {
    grid-template-columns: 0.2fr 1.5fr 0.5fr 0.2fr;
    grid-column-gap: 1rem;
  }

  .grid5 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }
`;

export const FormProjectData = styled.div`
  .grid1,
  .grid2 {
    display: grid;
  }

  .grid1 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid2 {
    grid-template-columns: 0.5fr 1fr;
    grid-column-gap: 1rem;
  }
`;

export const ReviewForm = styled.div`
  width: 38rem;
`;

export const ContainerInputReviewForm = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;

  label {
    font-size: 0.85rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  select {
    padding: 0.5rem;
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    color: #141414;
  }

  div {
    display: flex;
    width: 100%;
  }

  .marginContrataionContainer {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;

    input {
      border: none;
      background-color: #f1f1f2;
    }
  }
`;

export const FormReview = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.3fr;

  .grid1 {
    display: grid;
    margin-right: 1rem;
  }

  .grid2 {
    margin-left: 2rem;
  }
`;

export const MenuActionsPage = styled.div`
  background-color: var(--primary-color);
  margin: 0 auto;
  padding: 0.6rem;
  width: 95vw;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    width: 67.5em;
    height: 32em;
    font-size: 35px;
    display: grid;
  }

  .leftContent {
    display: flex;
    align-items: center;

    @media (max-width: 480px) {
      width: 133.3rem;
      display: grid;
      margin-left: 1em;
      margin-top: 1em;
    }

    div {
      @media (max-width: 480px) {
        width: 133.3rem;
        margin-left: 1.7em;
        margin-top: 0.3em;
      }
    }

    input {
      @media (max-width: 480px) {
        width: 100%;
        height: 2.5em;
        font-size: 100px;
        margin-left: 1em;
      }
    }

    img {
      @media (max-width: 480px) {
        width: 185px;
        margin-left: 5rem;
      }
    }
  }
`;

export const FormProjectDataReview = styled.div`
  .grid1,
  .grid2 {
    display: grid;
  }

  .grid1 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid2 {
    grid-template-columns: 0.5fr 1fr;
    grid-column-gap: 1rem;
  }
`;

export const ModalWarnNoFileProject = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(66 66 66 / 35%);
  z-index: 1;
  transition: 0.5s;
`;

export const BoxMessage = styled.div`
  background-color: var(--primary-color);
  border-radius: 0.4rem;
  padding: 2rem;
  display: grid;
  justify-content: center;

  img {
    margin: 0 auto;
  }

  p {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  .buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .confirmActionMessage {
    font-size: 0.85rem;
    color: var(--text-light-color);
    font-weight: 400;
  }
`;

export const ButtonActionModalNoFileProject = styled.a<ButtonProps>`
  border: 1px solid ${(props) => props.borderColor};
  padding: 0.5rem;
  text-align: center;
  margin-top: 2rem;
  border-radius: 0.3rem;
  background-color: ${(props) => props.backgroundColor};

  cursor: pointer;
`;
export const ContainerInputForm = styled.div`
  display: grid;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  label {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }

  select {
    padding: 0.5rem;
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    color: #141414;
  }

  #number {
    width: 100%;
  }

  div {
    display: flex;
    width: 100%;
  }

  .marginContrataionContainer {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;

    input {
      border: none;
      background-color: #f1f1f2;
    }
  }
`;

export const ContainerInputFormProjectData = styled.div`
  display: grid;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  label {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }

  select {
    padding: 0.7rem;
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    color: #777777;
    border-radius: 5px;
  }

  #number {
    width: 100%;
  }

  div {
    display: flex;
    width: 100%;
  }

  .marginContrataionContainer {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;

    input {
      border: none;
      background-color: #f1f1f2;
    }
  }
`;
export const OptionTypeProjectForm = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      color: #777777;
      width: 100%;
      cursor: pointer;
      div {
        width: 100%;
        padding: 1rem;
        border-bottom: 0.2rem solid #f1f2f3;
      }
      background-color: var(--primary-color);
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label > div {
    color: #000000;
    border-bottom: 0.2rem solid var(--secondary-color);
    cursor: pointer;
  }
`;

export const OptionTypeProjectReviewForm = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      color: #777777;
      width: 100%;
      cursor: pointer;
      div {
        width: 100%;
        padding: 1rem;
        border-bottom: 0.2rem solid #f1f2f3;
      }
      background-color: var(--primary-color);
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label > div {
    color: #000000;
    border-bottom: 0.2rem solid var(--secondary-color);
    cursor: pointer;
  }
`;

export const FilesListExibition = styled.div`
  font-size: 10px;
`;

export const ContainerListItens = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 60rem;
  max-height: 13.5rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: 1px solid #e1e1e1;
  margin-top: 0.3rem;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
`;

export const ContainerReviewListItens = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 37.1rem;
  max-height: 13.5rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: 1px solid #e1e1e1;
  margin-top: 0.3rem;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
`;

export const Item = styled.span`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  margin: 4px 0;
  margin-right: 0.25rem;
  border: 1px solid #e1e1e1;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  background-color: var(--primary-color);

  img {
    width: 0.5rem;
    margin-left: 0.35rem;
    cursor: pointer;
  }
`;

export const OrientationDescrible = styled.div`
  color: #777777;
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;

export const SelectedSteps = styled.div`
  max-width: 60rem;

  label {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
`;
export const InputForm = styled.input`
  border: 1px solid #e1e1e1;
  background-color: #f1f1f2;
  padding: 0.7rem;
  border-radius: 0.3rem;
`;

export const ReviewInputForm = styled.input`
  border: 1px solid #e1e1e1;
  background-color: #f1f1f2;
  padding: 0.7rem;
  border-radius: 0.3rem;
  width: 100%;
`;

export const FileTitle = styled.p`
  max-width: 21ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UploadContentMessage = styled.div`
  text-align: center;
  padding: 1rem;
`;

export const ContainerModal = styled.div`
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(66 66 66 / 35%);
  z-index: 1;
  transition: 0.5s;
`;
export const FormContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;
  min-width: 50rem;
  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const HeaderFormContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    background-color: #f1f2f3;
    padding: 0.4rem;
    border-radius: 0.3rem;
  }
  img {
    cursor: pointer;
    margin: 0.1rem;
  }
`;

export const FooterFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CentralContent = styled.div`
  padding: 1rem;
`;

export const DownloadButton = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    float: center;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--secondary-color);
    padding: 0.75rem;
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    font-size: 0.85rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 1rem;
    :hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
    }

    img {
      margin-right: 0.5rem;
    }
  }
`;

export const ConfirmationNewProject = styled.div`
  width: 40rem;
  button {
    float: right;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;
