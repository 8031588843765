import React from "react";

import ClosureDescription from "./Closure";
import FlagstoneDescription from "./Flagstone";
import FloorDescription from "./Floor";
import FundationDescription from "./Fundation";
import ReframingDescription from "./Reframing";
import SoculoDescription from "./Soculo";
import StructureDescription from "./Structure";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import StairwaysDescription from "./Stairways";
import MaterialsDescription from "../Materials";
import NicheDescription from "./Niche";

interface props {
    activity: allActivitiesProps
}

const MasonaryDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Estrutura" ? (<StructureDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Fechamento" ? (<ClosureDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Laje" ? (<FlagstoneDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Piso" ? (<FloorDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Fundacao" ? (<FundationDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Requadramento de vao" ? (<ReframingDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Soculo em alvenaria" ? (<SoculoDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Escadas" ? (<StairwaysDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Nicho" ? (<NicheDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}
        </>
    )
}

export default MasonaryDescription