interface stepsShowElementsProps {
    stepId: string
}

export const changeExpandStepBudgets = (
    stepsShowElements: stepsShowElementsProps[],
    setStepsShowElements: React.Dispatch<React.SetStateAction<stepsShowElementsProps[]>>,
    scaleY: number,
    setScaleY: React.Dispatch<React.SetStateAction<number>>,
    stepId: string
) => {
    const stepsShowElementsCopy = stepsShowElements
    const indexStep = stepsShowElementsCopy.findIndex(step => step.stepId === stepId)

    if (indexStep < 0) {
        stepsShowElementsCopy.push({ stepId: stepId })
        setStepsShowElements([...stepsShowElementsCopy])
    } else {
        stepsShowElementsCopy.splice(indexStep, 1)
        setStepsShowElements([...stepsShowElementsCopy])
    }

    if (scaleY === -1) {
        setScaleY(1);
    } else
        setScaleY(-1)

    return <></>
}