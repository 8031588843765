import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Impermeabilization } from "../../../Models/Impermeabilization/ImpermeabilizationModel";
import { ImpermeabilizationFormType } from "../../../Models/Impermeabilization/type";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesImpermeabilizationProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Impermeabilization: ImpermeabilizationFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  impermeabilizationActivities: activitiesImpermeabilizationProps[];
  impermeabilizationActivityName: string;
  indexImpermeabilizationActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesImpermeabilizationProps[]>
  >;
  setNewImpermeabilizationActivity?: () => void;
  setImpermeabilizationActivityName?: React.Dispatch<
    React.SetStateAction<string>
  >;
  setIndexImpermeabilizationActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editImpermeabilizationActivity?: (
    index: number,
    activity: activitiesImpermeabilizationProps
  ) => void;
  deleteImpermeabilizationActivity?: (index: number) => void;
}

const activityContextDefault = {
  impermeabilizationActivities: [],
  impermeabilizationActivityName: "",
  indexImpermeabilizationActivitySelected: 0,
  setNewImpermeabilizationActivity: () => {},
  setImpermeabilizationActivityName: () => {},
  setIndexImpermeabilizationActivitySelected: () => {},
  editImpermeabilizationActivity: () => {},
  deleteImpermeabilizationActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseImpermeabilizationActivityContext = () =>
  useContext(ActivitiesContext);

export const ImpermeabilizationActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [impermeabilizationActivities, setActivity] = useState<
    activitiesImpermeabilizationProps[]
  >(activityContextDefault.impermeabilizationActivities);
  const [
    indexImpermeabilizationActivitySelected,
    setIndexImpermeabilizationActivitySelected,
  ] = useState<number>(0);
  const [impermeabilizationActivityName, setImpermeabilizationActivityName] =
    useState<string>("Impermeabilizacao");

  const editImpermeabilizationActivity = (
    index: number,
    activity: activitiesImpermeabilizationProps
  ) => {
    let activitiesEdited = impermeabilizationActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteImpermeabilizationActivity = (index: number) => {
    const activity = [...impermeabilizationActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewImpermeabilizationActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: impermeabilizationActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Impermeabilizacao",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Impermeabilization: Impermeabilization,
        Materials: Materials,
      },
    ]);
    console.log(impermeabilizationActivities);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        impermeabilizationActivities,
        impermeabilizationActivityName,
        indexImpermeabilizationActivitySelected,
        setActivity,
        setImpermeabilizationActivityName,
        setNewImpermeabilizationActivity,
        editImpermeabilizationActivity,
        deleteImpermeabilizationActivity,
        setIndexImpermeabilizationActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
