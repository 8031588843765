import React from "react";
import moment from "moment";
import { Container, LineElement, CircularElement } from "./style"
import { dateFormate } from "../GanttMonth/scriptDate"
import { propsDate } from "../type"

const CurrentTimeLine: React.FC<propsDate> = (props) => {
    const setMarker = () => {
        if (dateFormate(props.date) == moment().format('DD/MM/YYYY').toString()) {
            return (
                <Container>
                    <CircularElement />
                    <LineElement />
                </Container>
            )
        } else {
            return <></>
        }
    }
    return (
        <>
            {setMarker()}
        </>
    )
}

export default CurrentTimeLine