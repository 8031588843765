import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { AirConditionerProject } from "../../../Models/ProjectStep/AirConditionerProjectModel";
import { ArchitecturalProject } from "../../../Models/ProjectStep/ArchitecturalProjectModel";
import { EletricProject } from "../../../Models/ProjectStep/EletricProjectModel";
import { HydrosanitaryProject } from "../../../Models/ProjectStep/HydrosanitaryProjectModel";
import { StructuralProject } from "../../../Models/ProjectStep/StructuralProjectModel";
import {
  AirConditionerProjectFormType,
  ArchitecturalProjectFormType,
  EletricProjectFormType,
  HydrosanitaryProjectFormType,
  StructuralProjectFormType,
} from "../../../Models/ProjectStep/type";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesProjectStepProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  AirConditionerProject: AirConditionerProjectFormType;
  ArchitecturalProject: ArchitecturalProjectFormType;
  EletricProject: EletricProjectFormType;
  HydrosanitaryProject: HydrosanitaryProjectFormType;
  StructuralProject: StructuralProjectFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  projectStepActivities: activitiesProjectStepProps[];
  projectStepActivityName: string;
  indexProjectStepActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesProjectStepProps[]>
  >;
  setNewProjectStepActivity?: () => void;
  setProjectStepActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexProjectStepActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editProjectStepActivity?: (
    index: number,
    activity: activitiesProjectStepProps
  ) => void;
  deleteProjectStepActivity?: (index: number) => void;
}

const activityContextDefault = {
  projectStepActivities: [],
  projectStepActivityName: "",
  indexProjectStepActivitySelected: 0,
  setNewProjectStepActivity: () => {},
  setProjectStepActivityName: () => {},
  setIndexProjectStepActivitySelected: () => {},
  editProjectStepActivity: () => {},
  deleteProjectStepActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseProjectStepActivityContext = () =>
  useContext(ActivitiesContext);

export const ProjectStepActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [projectStepActivities, setActivity] = useState<
    activitiesProjectStepProps[]
  >(activityContextDefault.projectStepActivities);
  const [
    indexProjectStepActivitySelected,
    setIndexProjectStepActivitySelected,
  ] = useState<number>(0);
  const [projectStepActivityName, setProjectStepActivityName] =
    useState<string>("Revestimento de parede");

  const editProjectStepActivity = (
    index: number,
    activity: activitiesProjectStepProps
  ) => {
    let activitiesEdited = projectStepActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteProjectStepActivity = (index: number) => {
    const activity = [...projectStepActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewProjectStepActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: projectStepActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Projeto",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        AirConditionerProject: AirConditionerProject,
        ArchitecturalProject: ArchitecturalProject,
        EletricProject: EletricProject,
        HydrosanitaryProject: HydrosanitaryProject,
        StructuralProject: StructuralProject,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        projectStepActivities,
        projectStepActivityName,
        indexProjectStepActivitySelected,
        setActivity,
        setProjectStepActivityName,
        setNewProjectStepActivity,
        editProjectStepActivity,
        deleteProjectStepActivity,
        setIndexProjectStepActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
