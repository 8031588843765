import moment from "moment";
export const dateActualy = () => {
    return moment().format('L')
}

export const monthActualy = () => {
    var month = moment().format('L').split("/")
    return parseInt(month[0])
}

export const yearActualy = () => {
    var yaear = moment().format('L').split("/")
    return parseInt(yaear[2])
}