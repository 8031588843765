import styled from "styled-components";

export const ContainerNotification = styled.div`
    width: 30rem;
    position: absolute;
    margin-right: 2rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    background-color: var(--primary-color);
    right: 0;
    border: 1px solid #F1F2F3;
    box-sizing: border-box;
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.2);
`
export const HeaderContainerNotification = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;

    .optionMenuNotifications{
        display: flex;
        p{
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-left: 0.5rem;
            cursor: pointer;
        }
        span{
            margin-right: 0.5rem;
            padding-top: 0.5rem;
            font-size: 0.8rem;
        }
    }

    p, span{
        color: var(--text-light-color);
        font-weight: 400;
    }

    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label > div >p{
        color: #000000;
        border-bottom: 3px solid var(--secondary-color);
    }
`

export const NoReadNotifications = styled.div`
    border-top: 1px solid #F1F2F3;
   
    .actionsContentNoReadNotifications{
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        margin-left: 0.45rem;
        margin-right: 0.45rem;

        .noRead{
            color: var(--text-light-color);
        }
    }
`

export const ContentNotification = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    span{
        display: flex;
    }

    .textElement{
        display:flex;
    }

    .dateContent{
        color: var(--text-light-color);
        font-weight: 400;
    }

    .notificationContainer{
        cursor: pointer;
        font-weight: 400;
        .grayText{
            color: var(--text-light-color);
        }
    }
`

export const Text = styled.p`
    cursor:pointer;
    color: var(--text-light-color);
`

export const ContainerButtonConfirmModal = styled.div`
    width:25vw;
    margin-top:0.5rem;
    display:flex;
    justify-content:center;
`

export const ReadNotifications = styled.div`
    background-color: #F1F2F3;
    border-radius: 0.3rem;
    padding-bottom: 1rem;
    .read{
        padding: 1.3rem;
        color: var(--text-light-color);
    }
`

export const CircularImg = styled.div`
    margin-left:0.85rem;
    img{
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 100%;
    }
`

export const ButtonMarkReadNotifications = styled.a`
    color: var(--secondary-color);

    cursor: pointer;
`

export const OptionsNotification = styled.div`
    display: grid;
    margin-right: 1rem;

    cursor: pointer;
    div{
        width: 0.25rem;
        height: 0.25rem;
        margin: 0.1rem;
        border-radius: 100%;
        background-color: #777777;
    }
`

export const NoNotification = styled.div`
    display:flex;
    justify-content:center;
    padding:0.75rem;
`
