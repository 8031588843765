import { activityAndPendencesType } from "../type"

export const removeBeforeInitServiceAcitivity = (activityStateVar: activityAndPendencesType, setStateVar: any, indexActivity: number) => {
    const activityCopy = activityStateVar
    if (activityStateVar.architectActivities.beforeInitService.length > 1) {
        activityCopy.architectActivities.beforeInitService.splice(indexActivity, 1)
    }
    setStateVar({ ...activityCopy })
}

export const removeDuringServiceAcitivity = (activityStateVar: activityAndPendencesType, setStateVar: any, indexActivity: number) => {
    const activityCopy = activityStateVar
    if (activityStateVar.architectActivities.duringService.length > 1) {
        activityCopy.architectActivities.duringService.splice(indexActivity, 1)
    }
    setStateVar({ ...activityCopy })
}

export const removeAfterServiceAcitivity = (activityStateVar: activityAndPendencesType|any, setStateVar: any, indexActivity: number) => {
    const activityCopy = activityStateVar
    if (activityStateVar.architectActivities.afterService.length > 1) {
        activityCopy.architectActivities.afterService.splice(indexActivity, 1)
    }
    setStateVar({ ...activityCopy })
}
