import styled from "styled-components";

interface CompleteProps {
  width: string;
  backgroundColor: string;
  valueScaleY: number;
}

interface InputDateBlocked {
  isEditAuthorized: boolean;
}

interface ContainerProps {
  borderColor: string;
  width: string;
}

interface StepInfoContentProps {
  width: string;
}
export const Container = styled.div<ContainerProps>`
  width: ${(props) => props.width};
  background-color: var(--primary-color);
  border-radius: 0.2rem;
  margin-right: 2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  border-left: 0.3rem solid ${(props) => props.borderColor};

  .alert {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    font-size: 45px;
    /* min-height: 8rem; */
    width: 45rem;
    min-height: 19rem;
    white-space: nowrap;
    align-items: center;
    justify-content: center;

    img {
      width: 1.3rem;
    }
  }

  p {
    @media (max-width: 480px) {
      display: flex;
      align-items: center;
    }
  }

  div {
    @media (max-width: 480px) {
      min-height: 19rem;
      font-size: 50px;
    }
  }
`;

export const Complete = styled.div<CompleteProps>`
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 480px) {
    height: 8rem;
  }

  div {
    @media (max-width: 480px) {
      width: 43.5rem;
    }
  }

  img {
    cursor: pointer;
    transform: scaleY(${(props) => props.valueScaleY});
    @media (max-width: 480px) {
      width: 35px;
    }
  }
`;

export const StepInfoContent = styled.div<StepInfoContentProps>`
  width: ${(props) => props.width};
  position: absolute;
  display: flex;
  justify-content: space-between;

  p {
    padding: 0.2rem;
    margin-left: 1rem;

    @media (max-width: 480px) {
      font-size: 60px;
      min-width: 38rem;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .step-name {
    @media (max-width: 480px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 36rem;
      margin-right: 10px;
      display: inline-block;
      vertical-align: bottom;
    }
  }

  img {
    @media (max-width: 480px) {
      width: 67px;
    }
  }
`;

export const CronogramContentDate = styled.div`
  display: flex;
  margin-right: 0.75rem;
`;

export const InputIfo = styled.input<InputDateBlocked>`
  width: 7rem;
  border: none;
  background-color: transparent;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;

  cursor: ${(props) => (props.isEditAuthorized ? "pointer" : "not-allowed")};
`;

export const DurationWorkingDaysInput = styled.input`
  width: 4rem;
  border: none;
  background-color: transparent;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
  }
`;
