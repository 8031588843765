import React, { useState } from "react";
import { AutomationsFormType } from "../../../../Models/Eletric/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  Automations: AutomationsFormType;
}

const AutomationsDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>
        {activity.Automations.BlasterInfravermelho?.map((item, index) => (
          <div key={index}>
            {activity.Automations.BlasterInfravermelho.length > 1 ? (<u>Blaster infravermelho {index + 1}</u>) : (<u>Blaster infravermelho </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Automations.AssistenteVirtual?.map((item, index) => (
          <div key={index}>
            {activity.Automations.AssistenteVirtual.length > 1 ? (<u>Assistente virtual {index + 1}</u>) : (<u>Assistente virtual </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Automations.Roteador?.map((item, index) => (
          <div key={index}>
            {activity.Automations.Roteador.length > 1 ? (<u>Roteador {index + 1}</u>) : (<u>Roteador </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Automations.TomadaSmart?.map((item, index) => (
          <div key={index}>
            {activity.Automations.TomadaSmart.length > 1 ? (<u>Tomada smart {index + 1}</u>) : (<u>Tomada smart </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
      </div>
    </div>
  );
};

export default AutomationsDescription;