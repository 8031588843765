import React, { useState, useEffect } from "react";

import { UseAirConditionerActivityContext } from "../../../../Context/ActivitiesContext/AirConditioner/AirConditionerContext";
import { attributeType } from "../../../../Models/attributeType";
import { MaterialsFormType } from "../../../../Models/attributeType";
import { Materials } from "../../../../Models/Materials/MaterialsModel";
import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";

import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json";
import {
  ActivityDateContainer,
  AttributeNameItem,
  ContainerGrid,
  Select,
  TotalPriceInput,
} from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import axios from "axios";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const MaterialsAirConditionerStepForm: React.FC<stepFormProps> = (props) => {
  const {
    airConditionerActivities,
    indexAirConditionerActivitySelected,
    editAirConditionerActivity,
  } = UseAirConditionerActivityContext();

  const [assignment, setAssignment] = useState<string>("");
  const [activityStartDate, setActivityStartDate] = useState<string>("");
  const [activityEndDate, setActivityEndDate] = useState<string>("");
  const [allActivities, setAllActivities] = useState<string[]>([]);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [date, setDate] = useState<string>("");
  const [itensFormList, setItensFormList] =
    useState<MaterialsFormType>(Materials);

  useEffect(() => {
    if (airConditionerActivities.length > 0) {
      let airConditionerActivityCopy =
        airConditionerActivities[indexAirConditionerActivitySelected];
      airConditionerActivityCopy.activityType = "Materiais";
      editAirConditionerActivity?.(
        indexAirConditionerActivitySelected,
        airConditionerActivityCopy
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexAirConditionerActivitySelected]);

  useEffect(() => {
    if (airConditionerActivities.length > 0) {
      setItensFormList(
        airConditionerActivities[indexAirConditionerActivitySelected].Materials
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexAirConditionerActivitySelected, airConditionerActivities]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/activitiesProject/63614a83e6a28ef603306720`
      )
      .then((res) => {
        setAllActivities(res.data);
      });
  }, []);

  useEffect(() => {
    setDate(activityStartDate + " à " + activityEndDate);
  }, [activityStartDate, activityEndDate]);

  useEffect(() => {
    setTotalPrice(quantity * unitPrice);
  }, [quantity, unitPrice]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemMateriais(0);
    }
  }, []);

  const addAssignment = () => {
    if (assignment === "Materiais para ar-condicionado") {
      addMateriais({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Link",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Data",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Data inicial",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Data final",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Preço unitário",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "un",
            value: "",
          },
          {
            itemName: "Preço total",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    }
  };

  const addMateriais = (object: attributeType) => {
    const itensFormListCopy = itensFormList;
    const materialsActivitiesCopy = airConditionerActivities;
    itensFormListCopy.Materiais.push(object);
    materialsActivitiesCopy[0].Materials = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, materialsActivitiesCopy[0]);
  };

  const removeItemMateriais = (index: number) => {
    const itensFormListCopy = itensFormList;
    const airConditionerActivitiesCopy = airConditionerActivities;
    itensFormListCopy.Materiais.splice(index, 1);
    airConditionerActivitiesCopy[0].Materials = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0]);
  };

  const editMateriaisValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const airConditionerActivitiesCopy = airConditionerActivities;
    setDate(activityStartDate + " á " + activityEndDate);
    itensFormListCopy.Materiais.map((item, index) => (
      <div key={index}>
        {item.attributes.map((attribute, index) => (
          <div key={index}>
            {attribute.itemName === "Data inicial"
              ? (itensFormListCopy.Materiais[
                  indexItemForm
                ].attributes[3].value = activityStartDate)
              : attribute.itemName === "Data final"
              ? (itensFormListCopy.Materiais[
                  indexItemForm
                ].attributes[4].value = activityEndDate)
              : attribute.itemName === "Data"
              ? (itensFormListCopy.Materiais[
                  indexItemForm
                ].attributes[2].value =
                  activityStartDate + " à " + activityEndDate)
              : null}
          </div>
        ))}
      </div>
    ));

    setActivityStartDate("");
    setActivityEndDate("");
    itensFormListCopy.Materiais[indexItemForm].attributes[indexItem].value =
      value;
    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0]);
  };

  const editUnitPriceValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const unitPriceNumber = value;
    setUnitPrice(parseFloat(unitPriceNumber.toString().replace(",", ".")));
    const itensFormListCopy = itensFormList;
    const airConditionerActivitiesCopy = airConditionerActivities;
    itensFormListCopy.Materiais[indexItemForm].attributes[indexItem].value =
      value;
    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0]);
  };

  const editQuantityValues = (value: string, indexItemForm: number) => {
    setQuantity(parseFloat(value));
    const itensFormListCopy = itensFormList;
    const airConditionerActivitiesCopy = airConditionerActivities;
    itensFormListCopy.Materiais[indexItemForm].attributes[6].value = value;
    itensFormListCopy.Materiais[indexItemForm].attributes[7].value = (
      parseFloat(unitPrice.toString().replace(",", ".")) * parseFloat(value)
    )
      .toFixed(2)
      .replace(".", ",");

    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0]);
  };

  const editMateriaisAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const airConditionerActivitiesCopy = airConditionerActivities;
    itensFormListCopy.Materiais[indexItemForm].attributes[indexItem].itemName =
      value;
    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0]);
  };

  const editMateriaisUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const airConditionerActivitiesCopy = airConditionerActivities;
    itensFormListCopy.Materiais[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0]);
  };

  return (
    <div>
      <div>
        <Assignment
          assignmentList={assignmentList}
          assignment={assignment}
          setAssignment={setAssignment}
          addAssignment={addAssignment}
        />
      </div>
      <br />
      <div>
        <p>Materiais</p>
        {itensFormList.Materiais.map((itemForm, indexItemForm) => (
          <ContainerGrid key={indexItemForm}>
            {itemForm.attributes.map((attributeItem, indexAttribute) =>
              attributeItem.itemName === "Tipo" ? (
                <div>
                  <AttributeNameItem
                    placeholder={attributeItem.itemName}
                    onChange={(e) =>
                      editMateriaisAttributeName(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  />
                  <Select
                    value={attributeItem.value}
                    onChange={(e) =>
                      editMateriaisValues(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  >
                    <option value="Gesso">Gesso</option>
                    <option value="Pvc">Pvc</option>
                    <option value="Madeira">Madeira</option>
                    <option value="Isopor">Isopor</option>
                    <option value="Cimento">Cimento</option>
                    <option value="Aparente">Aparente</option>
                    <option value="Fibra">Fibra</option>
                    <option value="Drywall reto">Drywall reto</option>
                    <option value="Drywall tabicado">Drywall tabicado</option>
                    <option value="Jateamento de celulose">
                      Jateamento de celulose
                    </option>
                    <option value="Outros">Outros</option>
                  </Select>
                  <div>
                    {attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input
                            type="text"
                            id="tipooutros"
                            placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) =>
                              editMateriaisValues(
                                e.target.value,
                                indexAttribute,
                                indexItemForm
                              )
                            }
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : attributeItem.itemName === "Data" ? (
                <div>
                  <AttributeNameItem
                    placeholder={attributeItem.itemName}
                    onChange={(e) =>
                      editMateriaisAttributeName(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  />
                  <Select
                    value={attributeItem.value}
                    onChange={(e) =>
                      editMateriaisValues(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  >
                    <option value="Indefinida">Indefinida</option>
                    <option value="Selecionar">Selecionar</option>
                    {attributeItem.value !== "Indefinida" &&
                    attributeItem.value !== "Selecionar" &&
                    attributeItem.value !== "" &&
                    attributeItem.value !== " à " ? (
                      <option>{attributeItem.value}</option>
                    ) : null}
                  </Select>
                  <div>
                    {attributeItem.value === "Apos atividade" ? (
                      <div>
                        {allActivities.map((item, index) => (
                          <div>{item}</div>
                        ))}
                      </div>
                    ) : attributeItem.value === "Selecionar" ? (
                      <ActivityDateContainer>
                        <input
                          type="date"
                          name=""
                          id=""
                          value={`${activityStartDate.split("/")[2]}-${
                            activityStartDate.split("/")[1]
                          }-${activityStartDate.split("/")[0]}`}
                          onChange={(e) =>
                            setActivityStartDate(
                              `${e.target.value.split("-")[2]}/${
                                e.target.value.split("-")[1]
                              }/${e.target.value.split("-")[0]}`
                            )
                          }
                        />
                        à
                        <input
                          type="date"
                          name=""
                          id=""
                          value={`${activityEndDate.split("/")[2]}-${
                            activityEndDate.split("/")[1]
                          }-${activityEndDate.split("/")[0]}`}
                          onChange={(e) =>
                            setActivityEndDate(
                              `${e.target.value.split("-")[2]}/${
                                e.target.value.split("-")[1]
                              }/${e.target.value.split("-")[0]}`
                            )
                          }
                        />
                        <button
                          onClick={() =>
                            editMateriaisValues(
                              date,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        >
                          Definir
                        </button>
                      </ActivityDateContainer>
                    ) : null}
                  </div>
                </div>
              ) : attributeItem.itemName === "Data inicial" ||
                attributeItem.itemName ===
                  "Data final" ? null : attributeItem.itemName ===
                "Preço total" ? (
                <p>
                  Preço total{" "}
                  {totalPrice !== 0 && totalPrice !== undefined ? (
                    <TotalPriceInput>
                      R$
                      <input
                        disabled
                        type="text"
                        value={attributeItem.value}
                      ></input>
                    </TotalPriceInput>
                  ) : null}
                </p>
              ) : attributeItem.itemName === "Preço unitário" ? (
                <div className="resizeContentInputs">
                  <AttributeNameItem
                    placeholder={attributeItem.itemName}
                    onChange={(e) =>
                      editMateriaisAttributeName(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  />
                  <InputActivity>
                    <input
                      type="text"
                      value={attributeItem.value}
                      onChange={(e) =>
                        editUnitPriceValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem
                        defaultValue={attributeItem.unit_of_measurement}
                        options={[
                          "m²",
                          "m³",
                          "m linear",
                          "peça",
                          "cm",
                          "un",
                          "m",
                        ]}
                        function={(e) =>
                          editMateriaisUnitOfMeasurement(
                            e,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </InputActivity>
                </div>
              ) : attributeItem.itemName === "Quantidade" ? (
                <div className="resizeContentInputs">
                  <AttributeNameItem
                    placeholder={attributeItem.itemName}
                    onChange={(e) =>
                      editMateriaisAttributeName(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  />
                  <InputActivity>
                    <input
                      type="text"
                      value={attributeItem.value}
                      onChange={(e) =>
                        editQuantityValues(e.target.value, indexItemForm)
                      }
                    />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem
                        defaultValue={attributeItem.unit_of_measurement}
                        options={[
                          "m²",
                          "m³",
                          "m linear",
                          "peça",
                          "cm",
                          "un",
                          "m",
                        ]}
                        function={(e) =>
                          editMateriaisUnitOfMeasurement(
                            e,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </InputActivity>
                </div>
              ) : (
                <div className="resizeContentInputs">
                  <AttributeNameItem
                    placeholder={attributeItem.itemName}
                    onChange={(e) =>
                      editMateriaisAttributeName(
                        e.target.value,
                        indexAttribute,
                        indexItemForm
                      )
                    }
                  />
                  <InputActivity>
                    <input
                      type="text"
                      value={attributeItem.value}
                      onChange={(e) =>
                        editMateriaisValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem
                        defaultValue={attributeItem.unit_of_measurement}
                        options={[
                          "m²",
                          "m³",
                          "m linear",
                          "peça",
                          "cm",
                          "un",
                          "m",
                        ]}
                        function={(e) =>
                          editMateriaisUnitOfMeasurement(
                            e,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </InputActivity>
                </div>
              )
            )}
            <div
              className="cancelX"
              onClick={() => removeItemMateriais(indexItemForm)}
            >
              X
            </div>
          </ContainerGrid>
        ))}
      </div>
    </div>
  );
};

export default MaterialsAirConditionerStepForm;
