import styled from "styled-components";

export const Container = styled.div`
    padding: 1.75rem;
`

export const ContentBox = styled.div`
    display:flex;
    justify-content:space-between;
    background-color:var(--primary-color);
    padding:0.5rem;
    width:100%;
    border-radius:0.3rem;
    margin-bottom:1rem;

    .containerAddMenbers{
        display: flex;
        align-items:center;
    }

    .leftContents{
        display: flex;
        align-items:center;
    }
`



export const Tr = styled.tr`
    border-bottom: 5px solid #E5E5E5;
    color: #777777;
    font-weight: 700;
`

export const ContainerInput = styled.div`
    margin-left: 0.3rem;
    display: flex;
    align-items:center;
    background: #F1F1F2;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 0.3rem;
    padding: 0.4rem;

    input{
        border: none;
        background: #F1F1F2;
        width: 20rem;
        color: #777777;
    }

    select{
        border: none;
        background: #F1F1F2;
        color: #777777;
    }
`

export const Button = styled.button`
    margin-left: 0.5rem;
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--secondary-color);
    padding: 0.75rem;
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    font-size: 0.85rem;
    cursor: pointer;
    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    img{
        margin-right: 0.3rem;
    }

    span {
        display: flex;
        font-size: 1.25rem;
        align-items: center;
        margin-right: 0.4rem;
        margin-bottom: 0.3rem;
    }
`

export const DisabledButton = styled.button`
    margin-left: 0.5rem;
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #777777;
    padding: 0.75rem;
    border: 1px solid #777777;
    border-radius: 0.3rem;
    font-size: 0.85rem;
    cursor: not-allowed;

    :hover {
       
    }

    img{
        margin-right: 0.3rem;
    }

    span {
        display: flex;
        font-size: 1.25rem;
        align-items: center;
        margin-right: 0.4rem;
        margin-bottom: 0.3rem;
    }
`

export const ModalContent = styled.div`
    padding: 1.5rem;
    display: grid;
    
    h1 {
        text-align: center;
    }

    .button {
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonArea{
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .copyMessage{
        p{
            text-align: center;
            margin-top: 20px; 
        }
    }
`
export const ContainerModal = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(66 66 66 / 35%);
    z-index: 3;
    transition: 0.5s;

    div {
        max-height: 50rem;
        max-width: 130rem;

    }

    
`

export const FormContainer = styled.div`
    background-color: var(--primary-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    overflow-y: hidden;

    .form{
        padding: 1rem;
    }

    overflow-y: auto;

`
export const HeaderFormContainer = styled.div`
    padding: 1rem;
    display: grid;
        grid-template-columns: 6fr 0fr 0fr 0fr ;
        grid-column-gap: 1rem;
    
    align-items: center;
    box-shadow: 0px 1px 0px #F1F2F3;
    div{
        background-color: #F1F2F3;
        padding: 0.4rem;
        border-radius: 0.3rem;
    }
    img{
        cursor: pointer;
        margin: 0.1rem;
    }
`

export const FormRegisterServiceInformation = styled.form`
    padding: 1rem;
    min-width: 67rem;
    .gridContent{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-column-gap: 2rem;
    }

    .group1{
        p{
            margin:0.5rem;
        }
    }

    .group1, .group2{
        display: grid;
        margin-top: 0.5rem;
        max-width: 34rem;
        
        label{
            font-size:0.95rem;
            
        }
    }
`

export const ContainerListItens = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width:25rem;
    max-height: 10rem;
    padding:0.3rem;
    align-items:center;
    border-radius: 0.3rem;
    border: 1px solid #E1E1E1;
    overflow-x: auto;
    overflow-y: hidden;

    div {
        display: flex;
    }
    ::-webkit-scrollbar {
        height: 0.5rem;
        background-color: #E1E1E1;
    }
    ::-webkit-scrollbar-thumb {
        background: #333333;
        border-radius: 0.3rem;
    }
`
export const Item = styled.span`
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin-right: 0.25rem;
    border: 1px solid #E1E1E1;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    background-color: var(--primary-color);
    margin: 0.1rem;
    
    img{
        width: 0.5rem;
        margin-left: 0.35rem;
        cursor: pointer;
    }
`

export const OrientationDescrible = styled.div`
    color: #777777;
    font-size: 0.8rem;
    margin-top:0.2rem;
`

export const SavedLinksDescription = styled.div`
    display: grid;
    font-size: 0.95rem;
    padding: 1rem;
    
    .itensDescription{
        display: grid;

            ::-webkit-scrollbar {
        height: 0.5rem;
        background-color: #E1E1E1;
    }
    ::-webkit-scrollbar-thumb {
        background: #333333;
        border-radius: 0.3rem;
    }
    }

    div{
        color: #777777;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
    }

`
