import styled from "styled-components";

export const Container = styled.div`
    height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const Image = styled.img`
   
`