import React, { useEffect, useState } from "react";
import axios from "axios";

import {
    PriceItem,
    ContentInputPrice,
    ListContainerItens,
    ItemContentList,
    TotalContainer,
    ItemContentListPapelDeParede,
    ListContainerItensPapelDeParede,
} from "./style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";
import { WallpaperFormType } from "../../../../Models/Paint/type";

interface budgetProps {
    activity: any,
    supplier: SupplierProps
    margin: number
}

interface activityProps {
    _id?: string
    projectId?: string;
    stepId?: string;
    budgetId?: [];
    activityName?: string;
    activityStatus?: string,
    activityType?: string,
    stepType?: string

    Wallpaper: WallpaperFormType
}

const PapelDeParedeBudget: React.FC<budgetProps> = (props) => {

    const [activity] = useState<activityProps>(props.activity);

    const [papelDeParede, setPapelDeParede] = useState<string[]>(["0,00"])



    const [workingDays, setWorkingDays] = useState<number>(0);
    const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
    const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
    const [conditions, setConditions] = useState<string>('')

    useEffect(() => {
        activity.Wallpaper.PapelDeParede.forEach(() => {
            setPapelDeParede((prev) => [...prev, "0,00"])
        })
        // eslint-disable-next-line
    }, [activity])

    /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
    useEffect(() => {
        const papelDeParedeTotal = sum(papelDeParede)

        setTotalBudgetPayment(sum([papelDeParedeTotal]))
    }, [papelDeParede])

    /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
    const papelDeParedeTotal = sum(papelDeParede)

    useEffect(() => {
        if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
            setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
        } else {
            setTotalBudgetPayment(sum([papelDeParedeTotal]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minimumBudgetValue]);

    const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
        const stateCopy = Array.from(stateVar)
        stateCopy.splice(index, 1, multiply('1', value))
        quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
        setStateRef(stateCopy)
    }

    const submitBudget = () => {
        const papelDeParedeMargin: number[] = []

        papelDeParede.forEach((item) => {
            if (item != "0,00") {
                papelDeParedeMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
            }
        })
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
                projectId: activity?.projectId,
                activityId: activity?._id,
                stepId: activity.stepId,
                providerId: props.supplier._id,
                supplierName: props.supplier.supplierName,
                typeSupplier: props.supplier.typeSupplier,
                activityName: activity.activityName,
                conditions,
                selectedStartDate: '',
                workingDays,
                selectedBudget: false,
                budget: {
                    PapelDeParede: papelDeParedeMargin
                },
                minimumBudgetValue,
                totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
            })
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            });
    };
    return (
        <div>
            <ListContainerItensPapelDeParede>

                {activity.Wallpaper.PapelDeParede?.map((item, index) => (
                    <div key={index}>
                        {activity.Wallpaper.PapelDeParede.length > 1 ? (<P>Papel de parede {index + 1}</P>) : (<P>Papel de parede</P>)}
                        <div className="upContent">

                            {item.attributes?.map((tarefa, index) => (
                                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
                            ))}
                            <span>Preço unit.</span>
                            <span className="item">Preço total</span>
                        </div>
                        <ItemContentListPapelDeParede >
                            {item.attributes?.map((tarefa, index) => (
                                <p >
                                    <span onClick={() => console.log(tarefa.itemName)}>
                                        {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                                    </span>
                                </p>
                            ))}

                            <ContentInputPrice>
                                R$:
                                <PriceItem onChange={(e) => updateState(index, papelDeParede, setPapelDeParede, e.target.value)} />
                            </ContentInputPrice>
                            {papelDeParede && papelDeParede[index] !== '0' ?
                                (<p className="item">{papelDeParede[index]}</p>) :
                                (<p className="item">0,00</p>)
                            }
                        </ItemContentListPapelDeParede>
                    </div>
                ))}
            </ListContainerItensPapelDeParede>

            <TotalContainer>
                Valor total: <p>R${totalBudgetPayment}</p>
            </TotalContainer>

            <FooterBudget
                setWorkingDays={setWorkingDays}
                setMinimunBudgetValue={setMinimunBudgetValue}
                setConditions={setConditions}
                submitBudget={() => submitBudget()}
            />
        </div>
    )
};

export default PapelDeParedeBudget;