import styled from "styled-components";

export const OptionTypeRegister = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      color: #777777;
      width: 100%;
      cursor: pointer;
      div {
        width: 100%;
        padding: 1rem;
        border-bottom: 0.2rem solid #f1f2f3;
      }
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label > div {
    color: #000000;
    border-bottom: 0.2rem solid var(--secondary-color);
    cursor: pointer;
  }
`;

export const FormNewSupplier = styled.form`
  padding: 1rem;
  .grid1,
  .grid2,
  .grid3,
  .grid4 {
    display: grid;
  }

  .grid1 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid2 {
    grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr;
    grid-column-gap: 1rem;
  }

  .grid3 {
    grid-template-columns: 0.2fr 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid4 {
    grid-template-columns: 1fr 0.5fr 0.5fr;
    grid-column-gap: 1rem;

    .selectElement {
      margin-left: 0;
    }

    .information {
      width: 12rem;
      color: var(--text-light-color);
      font-weight: 300;
      font-size: 0.85rem;
    }
  }

  .buttonModal {
    width: 8rem;
  }

  .alert {
    color: red;
  }
`;

export const FormRegisterDataPayment = styled.form`
  padding: 1rem;

  .group1,
  .group2 {
    display: grid;
  }

  .group1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 90%;
      margin-right: 1rem;
    }
  }

  .group2 {
    grid-template-columns: 1fr 1fr 2.1fr;
    grid-column-gap: 1rem;
  }

  .buttonModal {
    width: 8rem;
  }
`;

export const FormRegisterServiceInformation = styled.form`
  padding: 1rem;

  .gridContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }

  .group1 {
    p {
      margin: 0.5rem;
    }
  }

  .group1,
  .group2 {
    display: grid;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    label {
      font-size: 0.85rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const ContainerListItens = styled.div`
  display: flex;
  max-width: 25rem;
  height: 2.5rem;
  padding: 0.3rem;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid #e1e1e1;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 0.3rem;

  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
`;

export const Item = styled.span`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  margin-right: 0.25rem;
  border: 1px solid #e1e1e1;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  background-color: var(--primary-color);

  img {
    width: 0.5rem;
    margin-left: 0.35rem;
    cursor: pointer;
  }
`;

export const OrientationDescrible = styled.div`
  color: #777777;
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;
