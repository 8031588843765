import React from "react";
import { GlobalStyle } from "./style/Global";

import { GanttProvider } from "./Context/GanttContext";
import { ModalProvider } from "./Context/ModalContext";
import { LoginContextProvider } from "./Context/UserContext";
import { StepContextProvider } from "./Context/stepContext";
import { AuthAcessProvider } from "./Context/AuthAcessContext";

import Router from "./router";
import { EditWeeklySummaryProvider } from "./Context/EditWeeklySummaryContext";

const App: React.FC = () => {
  return (
    <div className="App">
      <LoginContextProvider>
        <AuthAcessProvider>
          <ModalProvider>
            <GanttProvider>
              <StepContextProvider>
                <EditWeeklySummaryProvider>
                  <Router />
                </EditWeeklySummaryProvider>
              </StepContextProvider>
            </GanttProvider>
          </ModalProvider>
        </AuthAcessProvider>
      </LoginContextProvider>
      <GlobalStyle />
    </div>
  );
};

export default App;
