import styled from "styled-components";

interface MonthVisibleProps {
  width: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.2rem;
  padding: 0.75rem;
  background-color: var(--primary-color);

  @media (max-width: 480px) {
    width: 100rem;
  }

  p {
    margin-left: 0.5rem;
    margin-right: 1rem;
    @media (max-width: 480px) {
      font-size: 55px;
      margin-bottom: 1rem;
    }
  }

  .options {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 0.9rem;
    @media (max-width: 480px) {
      display: grid;
    }
  }

  button {
    @media (max-width: 480px) {
      font-size: 55px;
    }
  }

  .actionsMenu {
    width: 100%;
    display: contents;
    align-items: center;
  }
`;

export const MonthVisible = styled.div<MonthVisibleProps>`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;

  p {
    justify-content: center;
    @media (max-width: 480px) {
      font-size: 60px;
    }
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    font-size: 60px;
    select {
      font-size: 60px;
    }
  }

  @media (max-width: 480px) {
    width: 70rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 60px;

    /* Estilizando a barra de rolagem no geral */
    ::-webkit-scrollbar {
      position: absolute;
      margin: 1rem; /* Margem superior */
      background-color: #e8e8e8; /* Cor de fundo */
      padding: 1rem;
      border-radius: 5px;
      height: 1vh;
    }

    /* Estilizando o scroll (cabo) da barra de rolagem */
    ::-webkit-scrollbar-thumb {
      background-color: #121317; /* Cor do scroll */
      border-radius: 5px;
    }

    text-align: center;
    justify-content: start;

    img {
      width: 1.5rem;
    }
  }
`;

export const ContentSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  padding: 0.4rem;
  border: 1px solid #e5e5e5;
  margin-right: 1rem;
  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
`;

export const PeriodContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #7592cc40;
  padding: 0.4rem;
  border-radius: 0.4rem;
  border-color: #7592cc;
  margin-right: 1rem;
  color: #141414;
  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
  p {
    font-weight: 300;
  }

  .periodSelect {
    background-color: #7592cc05;
    margin-left: 0.25rem;
    cursor: pointer;
    color: #141414;

    @media (max-width: 480px) {
      font-size: 60px;
    }
  }
  }
`;

export const Select = styled.select`
  margin-left: 0.5rem;
  border: none;
  cursor: pointer;
  font-weight: 600;
`;

export const ButtonClear = styled.button`
  width: -webkit-max-content;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 0.9rem;
  border: none;
  background-color: transparent;
`;
