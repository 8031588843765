import styled from "styled-components";

export const Container = styled.div`
    margin-top:1rem;
    display: grid;
    padding: 0.25rem;
`

export const ContainerButtom = styled.div`
    display: flex;
    justify-content:center;
    button{
        padding: 0.5rem;
        margin: 0 auto;
    }
    img{
        margin: 0.3rem;
    }
`

export const FlexContainer = styled.div`
    display: flex;
    align-items:center;
    div{
        margin-right:3rem;
    }

    .fileIcon{
        width: 0.5rem;
        img{
            width: 2rem;
            cursor: pointer;
        }
    }

    .containerBancada{
        margin: 0;
        div{
            margin-right: 0.5rem;
        }
        :last-child{
            margin-right: 0;
        }
    }

    .containerBorder{
        border: 1px solid #E1E1E1;
        display: flex;
        align-items:center;
        padding: 0.5rem;
        border-radius:0.4rem;

        div{
            margin: 0;
        }
    }

    .selectContainerDiv{
        width: 12rem
    }
    .observation{
        color: #777777;
        font-size:0.7rem;
    }
    :last-child{
        margin: 0;
    }
`


export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr 1fr 0fr;
    grid-gap:3rem;
    padding: 0.75rem;
    border: 1px solid #E1E1E1;
    border-radius:0.3rem;
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    
    .content{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .resizeInputs{
        width: 6rem;
        p{
            white-space: nowrap;
        }
        input{
            width: 100%;
        }
    }

    .flexContentInput{
        display: flex;
        align-items:center;
       
        p{
            margin-right:0.75rem;
        }

        div{
            width: 6rem;
            input{
                width: 100%;
            }
            margin: 0.3rem;
        }
    }

    .cancelX{
      color: #777777;
      cursor: pointer;
    }
`

export const ContainerGrid2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 0fr;
    grid-gap:20rem;
    padding: 0.75rem;
    border: 1px solid #E1E1E1;
    border-radius:0.3rem;
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    width: 50rem;
    
    .content{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .resizeInputs{
        width: 6rem;
        p{
            white-space: nowrap;
        }
        input{
            width: 100%;
        }
    }

    .flexContentInput{
        display: flex;
        align-items:center;
       
        p{
            margin-right:0.75rem;
        }

        div{
            width: 6rem;
            input{
                width: 100%;
            }
            margin: 0.3rem;
        }
    }

    .cancelX{
      color: #777777;
      cursor: pointer;
      margin-left: -4rem;
    }
`
export const StyleContainerGrid2 = styled.div`
    justify-content: center;
    display: grid;
`


export const ContentNoMargin = styled.div`
    div{
        margin-bottom:1rem;
    }

   
    .titleSectionSeparated{
        margin-top:0.5rem;
        color: #777777;
        font-size:0.9rem;
    }

    .textNoChashLine{
        white-space: nowrap;
    }

    .no_marginBottom{
        div{
            margin: 0;
        }
    }


    .contentSizeInputs{
        display: flex;
        div{
            margin-top:0.5rem;
            width: 6rem;
            margin-right:1rem;
            :last-child{
                margin-right:0;
            }
            input{
                width: 100%;
            }
            p{
                white-space: nowrap;
            }
        }
    }

    .resizeContentInputs{
       width: 8rem;
       white-space: nowrap;
       input{
           margin-right:0.5rem;
           width: 100%;
       }
    }

    .contentGrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:1.5rem;
        div{
            width: 10rem;
            p{
                white-space: nowrap;
            }
            input{
                width: 100%;
            }
        }
    }

    .contentGrid2{
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap:3rem;
    }

    .contentGrid3{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap:4rem;
    }

    option{
        text-align: center;
    }
`

export const Content = styled.div`
    :first-child{
        margin-right: 15rem;
    }

    div{
        margin-bottom:1rem;
    }

   
    .titleSectionSeparated{
        margin-top:0.5rem;
        color: #777777;
        font-size:0.9rem;
    }

    .textNoChashLine{
        white-space: nowrap;
    }

    .no_marginBottom{
        div{
            margin: 0;
        }
    }
    .contentSizeInputs{
        display: flex;
        div{
            margin-top:0.5rem;
            width: 6rem;
            margin-right:1rem;
            :last-child{
                margin-right:0;
            }
            input{
                width: 100%;
            }
            p{
                white-space: nowrap;
            }
        }
    }

    .resizeContentInputs{
       width: 8rem;
       white-space: nowrap;
       input{
           margin-right:0.5rem;
           width: 100%;
       }
    }

    .contentGrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:1.5rem;
        div{
            width: 10rem;
            p{
                white-space: nowrap;
            }
            input{
                width: 100%;
            }
        }
    }

    .contentGrid2{
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap:3rem;
    }

    .contentGrid3{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap:4rem;
    }
    option{
        text-align: center;
    }
`

export const Select = styled.select`
    width: 100%;
    border-radius:0.3rem;
    padding: 0.5rem;
    background-color: #E5E5E5;
    border: none;
    color: #777777;
    margin-top:0.75rem
`

export const CheckBoxContainer = styled.div`
    .buttonSelectOption{
        display: flex;
        align-items:center;
        div{
            margin: 0;
        }
        label{
            color: #77777780;
            padding: 0.5rem;
            font-size: 0.85rem;
            cursor: pointer;
        }
    }

    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label{
        color: #000000;
        cursor: pointer;
    }

    input[type="checkbox"]:checked ~ label{
        color: #000000;
        cursor: pointer;
    }
`

export const ActivityName = styled.div`
    display: flex;
    align-items:center;
    div{
        padding: 0.5rem;
        border: 1px solid #E5E5E5;
        font-weight:400;
        font-size:0.9rem;
        margin-left:0.5rem;
        display: flex;
        justify-content:center;
        border-radius:0.3rem;
        white-space: nowrap;

        cursor: pointer;
    }
`

export const BoxContent = styled.div`
    padding: 0.5rem;
    border: 1px solid #E1E1E1;
    border-radius:0.3rem;
    display: grid;
    grid-gap:1rem;
    margin-top: 0.5rem;
    div{
        margin-bottom: 0;
    }

    .flexContent{
        display: flex;
        justify-content:space-between;
        div{
            width: 8rem;
            input{
                width: 100%;
            }
        }
    }

    .cancelButton{
        margin: 0;
        p{
          color: #777777;
          padding: 0.5rem;

        }
        cursor: pointer;
        
    }
`

export const Button = styled.button`
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    font-size: 0.85rem;
    margin-top: 0.7rem;
    cursor: pointer;
    padding: 0 0.5rem;
    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }
    img{
        margin-right: 0.5rem;
    }
`

export const ContentSpecialListItens = styled.div`
 input:disabled{
       cursor: not-allowed;
   }
`

export const ContainerListItens = styled.div`
    display: flex;
    width: 20rem;
    display: inline-flex;
    overflow-x: scroll;
    background-color: #E1E1E1;
    border-radius: 0.3rem;
    border: 1px solid #E1E1E1;
    border-right: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .list{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    input{
        width: 10rem;
        background-color: #E1E1E1;
        padding: 0.2rem;
        border: none;
        margin: 0.5rem;
        font-weight: 600;
    }
`

export const Item = styled.span`
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0.25rem;
    border: 1px solid #E1E1E1;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    background-color: var(--primary-color);

    img{
        width: 0.5rem;
        margin-left: 0.5rem;
        cursor: pointer;
    }

    div{
        margin-right: 1rem;
    }
`

export const OrientationDescrible = styled.div`
    color: #777777;
    font-size: 0.75rem;
`

export const P = styled.p`
    color: #777777;
    font-size: 0.9rem;
    padding: 0.5rem;
    padding-left:0;
`

export const ContainerForm = styled.div`
    display:grid;
    grid-template-columns:1fr 2fr;
    grid-gap:1rem;
    padding:0.5rem;
    margin-bottom: 1rem
`

export const ContentInlineInput = styled.div`
    display:grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap:1rem;

    input {
    padding: 0;
    }
`

export const RemoveItem = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: 500;
`


export const AssignmentContent = styled.div`
    display: flex;
    align-items: center;
    button{
        margin: 0;
    }
`
export const InputActivity = styled.div`
    margin-top: 0.5rem;
    margin-bottom:0.5rem;
    padding: 0.5rem;
    background-color: #E1E1E1;
    color: #777777;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size:0.9rem;
    input{
        border: none;
        background-color: #E1E1E1;
        width: 100%;
    }

    .noBreakLine{
        white-space: nowrap;
    }
`

export const AttributeNameItem = styled.input`
    border: none;
    padding: 0.5rem;
`

export const ContainerCheckBox = styled.div`
    color: #121317;
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
    margin-top:0.5rem;

    .buttonAddFile{
        padding: 0.25rem;
        font-size: 0.75rem;
        height: 1.75rem;
        margin-right: 1rem;
    }

    .activityContentItem,
    .actionsActivityContentItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .actionsActivityContentItem{
        span{
            cursor: pointer;
        }
    }
    
    label{
        display: flex;
        align-items: center;
    }

    input[type="checkbox"]{
        width: 1.15rem;
        height: 1.15rem;
        margin-right: 0.5rem;
        margin-bottom: 0.1rem;
        position: relative;
        cursor: pointer;
    }

    input[type='checkbox']::before {
        content: ' ';
        width: inherit;
        height: inherit;
        position: absolute;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
    }

    input[type='checkbox']:checked::after {
        content: ' ';
        width: 0.3rem;
        height: 0.7rem;
        margin-top: 0.1rem;
        margin-left: 0.4rem;
        border-color: #777777;
        border-style: solid;
        border-width: 0 2px 2px 0;
        position: absolute;
        transform: rotate(45deg);
    }

`
export const InputFile = styled.div`
    max-width: 25rem;
    background-color: #F1F1F2;
    border: 1px solid #E1E1E1;
    border-radius: 0.3rem;
    color: #141414;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    margin-top: 1.5rem;

    p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
 
    label{  
        margin: 0.2rem;
        white-space: nowrap;
        background-color: var(--primary-color);
        padding: 0.4rem;
        border: 1px solid #E1E1E1;
        color: var(--black-color);
        border-radius: 0.3rem;
        cursor: pointer;
    }
`