import styled from "styled-components";

interface buttonProps {
  selectedButton: boolean;
}

export const Container = styled.div`
  min-height: 3.5rem;
  background-color: var(--primary-color);
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 1px;
  display: flex;
  gap: 2rem;

  @media (max-width: 480px) {
    margin-top: 7rem;
    align-items: center;
    min-height: 16rem;
    max-height: 36rem;
    width: 152rem;
    text-align: center;
    justify-content: start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Button = styled.button<buttonProps>`
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: ${(props) => (props.selectedButton ? "#5774b6" : "#777777")};
  border-bottom: 3px solid
    ${(props) => (props.selectedButton ? "#5774b6" : "#ffff")};
  cursor: pointer;

  :hover {
    color: var(--secondary-color);
  }

  @media (max-width: 480px) {
    font-size: 78px;
  }
`;
