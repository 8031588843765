import styled from "styled-components";

export const CentralContent = styled.div`
    width: 25rem;
    padding: 1rem;
    display: grid;

    p{
        margin-top: 1rem;
    }

    select{
        padding: 0.5rem;
        margin-top: 1rem;
        border-radius: 0.3rem;
        border: 1px solid #E5E5E5;
        background-color: #F1F1F2;
    }
`

export const Button = styled.a`
display: flex;
margin: 1rem;
align-items: center;
justify-content: center;
background-color: var(--primary-color);
color: var(--secondary-color);
padding: 0.75rem;
padding-left: 2rem;
padding-right: 2rem;
border: 1px solid var(--secondary-color);
border-radius: 0.3rem;
font-size: 0.85rem;
cursor: pointer;
:hover{
    background-color: var(--secondary-color);
    color: var(--primary-color)
}
`
