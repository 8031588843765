import React, { useState, useEffect } from "react";
import { UseLiningActivityContext } from "../../../../Context/ActivitiesContext/Lining/LiningContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid } from "./style"

import { ForroFormType } from "../../../../Models/Lining/type";
import { attributeType } from "../../../../Models/attributeType";
import { Forro } from "../../../../Models/Lining/LiningModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const Lining: React.FC<stepFormProps> = (props) => {
  const { liningActivities, editLiningActivity, indexLiningActivitySelected } = UseLiningActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<ForroFormType>(Forro)

  useEffect(() => {
    if (liningActivities.length > 0) {
      let liningActivityCopy = liningActivities[indexLiningActivitySelected]
      liningActivityCopy.activityType = 'Forro'
      editLiningActivity?.(indexLiningActivitySelected, liningActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexLiningActivitySelected])


  useEffect(() => {
    if (liningActivities.length > 0) {
      setItensFormList(liningActivities[indexLiningActivitySelected].Forro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexLiningActivitySelected, liningActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemCortineiro(0)
      removeItemForro(0)
      removeItemSanca(0)
      removeItemTabica(0)
    }
  }, [])
  const addAssignment = () => {
    if (assignment === "Forro") {
      addForro({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: 'Gesso'
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Cortineiro") {
      addCortineiro({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Sanca") {
      addSanca({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Tabica") {
      addTabica(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm linear',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    }
  }

  const addForro = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Forro.push(object)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const addCortineiro = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Cortineiro.push(object)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const addSanca = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Sanca.push(object)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const addTabica = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Tabica.push(object)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const removeItemForro = (index: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Forro.splice(index, 1)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const removeItemCortineiro = (index: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Cortineiro.splice(index, 1)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const removeItemSanca = (index: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Sanca.splice(index, 1)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const removeItemTabica = (index: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Tabica.splice(index, 1)
    liningActivitiesCopy[0].Forro = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editForroValues = (value: string, indexItem: number, indexForro: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Forro[indexForro].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editForroAttributeName = (value: string, indexItem: number, indexForro: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Forro[indexForro].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editForroUnitOfMeasurement = (value: string, indexItem: number, indexForro: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Forro[indexForro].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editCortineiroValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Cortineiro[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editCortineiroAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Cortineiro[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editCortineiroUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Cortineiro[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editSancaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Sanca[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editSancaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Sanca[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editSancaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Sanca[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editTabicaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Tabica[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editTabicaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Tabica[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  const editTabicaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const liningActivitiesCopy = liningActivities
    itensFormListCopy.Tabica[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editLiningActivity?.(0, liningActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.Forro.length ? (
        <div>
          <P>Forro</P>
          {itensFormList.Forro.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editForroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editForroValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editForroValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editForroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editForroValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editForroUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemForro(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.Cortineiro.length ? (
        <div>
          <P>Cortineiro</P>
          {itensFormList.Cortineiro.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editCortineiroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editCortineiroValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editCortineiroUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemCortineiro(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.Sanca.length ? (
        <div>
          <P>Sanca</P>
          {itensFormList.Sanca.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSancaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editSancaValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editSancaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemSanca(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.Tabica.length ? (
        <div>
          <P>Tabica</P>
          {itensFormList.Tabica.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTabicaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editTabicaValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editTabicaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemTabica(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default Lining;
