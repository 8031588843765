import { createGlobalStyle } from 'styled-components'



export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        text-decoration: none;
        --primary-color: #ffffff;
        --secondary-color: #5774b6;
        --black-color: #121317;
        --blue-light:#7592cc;
        --border-color: #c85b3e;
        --text-light-color: #A5A5A5;
        --dark-background: rgba(0, 0, 0, 0.9);
        --red-color:#FF0000;

        --fontSpaceGotesk:'Space Grotesk', sans-serif;
    }

    textarea:focus, input:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
    }

    p, a, button, input{
        font-family: 'Space Grotesk', sans-serif;
    }
    
    div{
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
    }

    body{
        background-color: #e8e8e8;
    }

    input[type="file"] {
        display: none;
        font-family: 'Space Grotesk', sans-serif;
    }

    input[type="date"] {
        font-family: 'Space Grotesk', sans-serif;
        text-transform:uppercase;
        color: #141414;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        //font-size: 0px;
        font-family: 'Space Grotesk', sans-serif;
    }
`