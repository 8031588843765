import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "./style"

import { Option } from "../../style";
import timelineIcon from "../../../../assets/timeline-icon.svg";
import cotationMapIcon from "../../../../assets/cotation-map-icon.svg";
import listIcon from "../../../../assets/list-icon.svg";
import weeklySummaryIcon from "../../../../assets/weekly-summary-icon.svg";
interface contentVisualizationProps {
    optionSelected: string
}

const ContentVisualizationMenu: React.FC<contentVisualizationProps> = (props) => {
    const [option, setOption] = useState<string>('Gestão')
    return (
        <Container>
            <p>Visualizações</p>
            <div>
                <Link onClick={() => setOption('Gestão')} className="link" to="/gestaoCronograma">
                    {props.optionSelected === "Gestão" ? (
                        
                        <Option className="Option selected" >
                            <img src={timelineIcon} className=""></img>
                            Cronograma
                        </Option>
                    ) : (
                        <Option className="Option" >
                           <img src={timelineIcon} className=""></img>
                           Cronograma
                        </Option>
                    )}
                </Link>
            </div>
            <div >
                <Link onClick={() => setOption('Mapa de cotação')} className="link" to="/mapaCotacao">
                    {props.optionSelected === "Mapa cotacao" ? (
                        <Option className="Option selected" >
                            <img src={cotationMapIcon} className=""></img>
                            Mapa de cotação
                        </Option>
                    ) : (
                        <Option className="Option">
                            <img src={cotationMapIcon} className=""></img>
                            Mapa de cotação
                        </Option>
                    )}
                </Link>
            </div>
            <div >
                <Link onClick={() => setOption('Lista')} className="link" to="/gestaoLista">
                    {props.optionSelected === "Lista" ? (
                        <Option className="Option selected" >
                            <img src={listIcon} className=""></img>
                            Lista
                        </Option>
                    ) : (
                        <Option className="Option">
                            <img src={listIcon} className=""></img>
                            Lista
                        </Option>
                    )}
                </Link>
            </div>
            <div >
                <Link onClick={() => setOption('ResumoSemanal')} className="link" to="/gestaoResumoSemanal">
                    {props.optionSelected === "ResumoSemanal" ? (
                        <Option className="Option selected" >
                            <img src={weeklySummaryIcon} className=""></img>
                            Resumo semanal
                        </Option>
                    ) : (
                        <Option className="Option">
                            <img src={weeklySummaryIcon} className=""></img>
                            Resumo semanal
                        </Option>
                    )}
                </Link>
            </div>
        </Container>
    )
}

export default ContentVisualizationMenu