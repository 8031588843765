import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { BaseBoard } from "../../../Models/FloorsAndCoverings/BaseBoardModel";
import { CeramicFloorCovering } from "../../../Models/FloorsAndCoverings/CeramicFloorCoveringModel";
import { StoneFloorCovering } from "../../../Models/FloorsAndCoverings/StoneFloorCoveringModel";
import { VinylFloorCovering } from "../../../Models/FloorsAndCoverings/VinylFloorCoveringModel";
import { WallCovering } from "../../../Models/FloorsAndCoverings/WallCoveringModel";
import { Flooring } from "../../../Models/FloorsAndCoverings/FlooringModel";
import { WoodCovering } from "../../../Models/FloorsAndCoverings/WoodCoveringModel";
import {
  BaseBoardFormType,
  CeramicFloorCoveringFormType,
  FlooringFormType,
  StoneFloorCoveringFormType,
  VinylFloorCoveringFormType,
  WallCoveringFormType,
  WoodCoveringFormType,
} from "../../../Models/FloorsAndCoverings/type";
import { UserProviderProps } from "../../../types/typesEntitiesProject";
export interface activitiesFloorsAndCoveringsProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  StoneFloorCovering: StoneFloorCoveringFormType;
  VinylFloorCovering: VinylFloorCoveringFormType;
  CeramicFloorCovering: CeramicFloorCoveringFormType;
  WallCovering: WallCoveringFormType;
  BaseBoard: BaseBoardFormType;
  Flooring: FlooringFormType;
  WoodCovering: WoodCoveringFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  floorsAndCoveringsActivities: activitiesFloorsAndCoveringsProps[];
  floorsAndCoveringsActivityName: string;
  indexFloorsAndCoveringsActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesFloorsAndCoveringsProps[]>
  >;
  setNewFloorsAndCoveringsActivity?: () => void;
  setFloorsAndCoveringsActivityName?: React.Dispatch<
    React.SetStateAction<string>
  >;
  setIndexFloorsAndCoveringsActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editFloorsAndCoveringsActivity?: (
    index: number,
    activity: activitiesFloorsAndCoveringsProps
  ) => void;
  deleteFloorsAndCoveringsActivity?: (index: number) => void;
}

const activityContextDefault = {
  floorsAndCoveringsActivities: [],
  floorsAndCoveringsActivityName: "",
  indexFloorsAndCoveringsActivitySelected: 0,
  setNewFloorsAndCoveringsActivity: () => {},
  setFloorsAndCoveringsActivityName: () => {},
  setIndexFloorsAndCoveringsActivitySelected: () => {},
  editFloorsAndCoveringsActivity: () => {},
  deleteFloorsAndCoveringsActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseFloorsAndCoveringsActivityContext = () =>
  useContext(ActivitiesContext);

export const FloorsAndCoveringsActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [floorsAndCoveringsActivities, setActivity] = useState<
    activitiesFloorsAndCoveringsProps[]
  >(activityContextDefault.floorsAndCoveringsActivities);
  const [
    indexFloorsAndCoveringsActivitySelected,
    setIndexFloorsAndCoveringsActivitySelected,
  ] = useState<number>(0);
  const [floorsAndCoveringsActivityName, setFloorsAndCoveringsActivityName] =
    useState<string>("Revestimento de parede");

  const editFloorsAndCoveringsActivity = (
    index: number,
    activity: activitiesFloorsAndCoveringsProps
  ) => {
    let activitiesEdited = floorsAndCoveringsActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteFloorsAndCoveringsActivity = (index: number) => {
    const activity = [...floorsAndCoveringsActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewFloorsAndCoveringsActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: floorsAndCoveringsActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Pisos e revestimento",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        StoneFloorCovering: StoneFloorCovering,
        VinylFloorCovering: VinylFloorCovering,
        CeramicFloorCovering: CeramicFloorCovering,
        WallCovering: WallCovering,
        BaseBoard: BaseBoard,
        Flooring: Flooring,
        WoodCovering: WoodCovering,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        floorsAndCoveringsActivities,
        floorsAndCoveringsActivityName,
        indexFloorsAndCoveringsActivitySelected,
        setActivity,
        setFloorsAndCoveringsActivityName,
        setNewFloorsAndCoveringsActivity,
        editFloorsAndCoveringsActivity,
        deleteFloorsAndCoveringsActivity,
        setIndexFloorsAndCoveringsActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
