import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ItemContentListLimpeza,
  ListContainerItensLimpeza,
} from "./style";

import { activitiesProps, SupplierProps } from "../../../types/typesEntitiesProject";
import { multiply, sum } from "../script/calculate"
import { CleaningFormType } from "../../../Models/Cleaning/type";

import FooterBudget from "../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  Cleaning: CleaningFormType
}

const LimpezaBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [limpeza, setLimpeza] = useState<string[]>(["0,00"])



  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Cleaning.Limpeza.forEach(() => {
      setLimpeza((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const limpezaTotal = sum(limpeza)

    setTotalBudgetPayment(sum([limpezaTotal]))
  }, [limpeza])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const limpezaTotal = sum(limpeza)

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([limpezaTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const limpezaMargin: number[] = []

    limpeza.forEach((item) => {
      if (item != "0,00") {
        limpezaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Limpeza: limpezaMargin
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensLimpeza>

        {activity.Cleaning.Limpeza?.map((item, index) => (
          <div key={index}>
            {activity.Cleaning.Limpeza.length > 1 ? (<P>Limpeza {index + 1}</P>) : (<P>Limpeza</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListLimpeza >
              {item.attributes?.map((tarefa, index) => (
                <p key={index}>
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, limpeza, setLimpeza, e.target.value)} />
              </ContentInputPrice>
              {limpeza && limpeza[index] !== '0' ?
                (<p className="item">{limpeza[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListLimpeza>
          </div>
        ))}
      </ListContainerItensLimpeza>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default LimpezaBudget;