import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  ContanierFinalization,
  ItemContainerFinzalization,
  TotalContainer,
  ContainerButtonSendBudget,
} from "../../style";

import {activitiesProps, SupplierProps} from "../../../../types/typesEntitiesProject";

import {multiply, sum} from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"
interface budgetProps{
    activity:activitiesProps,
    supplier:SupplierProps
    margin:number
}
interface activityProps{
    _id?: string
    projectId?: string;
    stepId?: string;
    budgetId?: [];
    activityName?: string;
    activityStatus?: string,
    activityType?:string,
    stepType?:string
     
    item_revestido?:string,
    espessura_chapa?:string,
    altura_pedras?:string,
    largura_pedras?:string,
    altura_item_revestido?:string,
    largura_item_revestido?:string,
    pedra?:string,
    cor_pedra?:string,
    acabamento_pedra?:string,
}

const RevestimentoBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [revestimento, setRevestimento]=useState<string>('0,00')
  const [pedras, setPedras]=useState<string>('0,00')
 
  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment]=useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(()=>{
    setTotalBudgetPayment(sum([revestimento,pedras]))
  },[revestimento,pedras])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {
    if (parseFloat(minimumBudgetValue)>parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.',','));
    } else {
        setTotalBudgetPayment(sum([revestimento,pedras]))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const submitBudget = () => {
    
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier:props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget:false,
        budget:{
            revestimento:(Number(revestimento.replace(',', '.')) + Number(revestimento.replace(',', '.')) * (props.margin / 100)).toString(),
            pedras:(Number(pedras.replace(',', '.')) + Number(pedras.replace(',', '.')) * (props.margin / 100)).toString()
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
      <div>
          <ListContainerItens>
            <div className="upContent">
                <p>Revestimento</p>
                <p>Medidas</p>
                <p>Preço unit.</p>
                <p className="item">Preço total</p>
            </div>

            {activity.item_revestido !== '' ?(
                <ItemContentList>
                    <p>Revestimento de ({activity.item_revestido}) - Chapa de {activity.espessura_chapa}mm</p>
                    <p>{activity.largura_item_revestido + " cm x " + activity.altura_item_revestido + "cm"}</p>
                    <ContentInputPrice>
                        R$:
                        <PriceItem onChange={(e)=>setRevestimento(multiply("1", e.target.value))}/>
                    </ContentInputPrice>
                    {revestimento && revestimento !== '0'?
                        (<p className="item">{revestimento}</p>):
                        (<p className="item">0,00</p>)
                    }
                </ItemContentList>
            ):(<></>)}

            {activity.pedra !== '' ?(
                <ItemContentList>
                    <p>Pedra ({activity.cor_pedra}) - {activity.acabamento_pedra}</p>
                    <p>{activity.altura_pedras + " cm x " + activity.largura_pedras + "cm"}</p>
                    <ContentInputPrice>
                        R$:
                        <PriceItem onChange={(e)=>setPedras(multiply("1", e.target.value))}/>
                    </ContentInputPrice>
                    {pedras && pedras !== '0'?
                        (<p className="item">{pedras}</p>):
                        (<p className="item">0,00</p>)
                    }
                </ItemContentList>
            ):(<></>)}

          </ListContainerItens>

          <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
      </div>
  )
};

export default RevestimentoBudget;