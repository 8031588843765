import React, { SetStateAction, useEffect, useState } from "react";
import axios from "axios";

import { Container, CenterButton, FlexElement } from "../styles";
import { Button } from "../../stylesDomElements/htmlStyledElements";
import { dependencesProps } from "../../../pages/ConstructionManagement/type";
import { UseGanttContext } from "../../../Context/GanttContext";

import cancelXicon from "../../../assets/cancelXicon.svg"

import { DeleteButton, Bold, LightTextDependence, TextDelete } from "./style"
import { UseModalContext } from "../../../Context/ModalContext";

interface showDependecyAndDeleteProps {
    dependences: dependencesProps[]
    setShowDependencyDelete: React.Dispatch<SetStateAction<boolean>>
    setpName: string
}

const ShowAndDeleteDependecy: React.FC<showDependecyAndDeleteProps> = (props) => {
    const { stepId } = UseGanttContext();
    const { setModalNameHeader } = UseModalContext()
    const [dependences, setDependences] = useState<dependencesProps[]>([])

    useEffect(() => {
        setModalNameHeader("Dependências: " + props.setpName)
    }, [])

    useEffect(() => {
        setDependences(props.dependences)
    }, [])


    const deleteDependency = async (dependencyId: string) => {
        const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteDependency/${dependencyId}`)
        if (res.status === 200) {
            var dependencesCopy = dependences
            var dependenceIndex = dependencesCopy.findIndex((dependence) => dependence._id === dependencyId)
            if (dependenceIndex >= 0) {
                dependencesCopy.splice(dependenceIndex, 1)
                setDependences([...dependencesCopy])
            }
        }
    }

    return (
        <Container>
            {dependences.length > 0 ? (
                dependences.map((dependency, index) => (
                    <FlexElement key={index}>
                        <LightTextDependence>
                            Começa {Number(dependency.daysOfDelay) > 0 ?(dependency.daysOfDelay + ' dia(s)'):('')} após o {dependency.initAfter.toLowerCase()} de <Bold>{dependency.stepReference}</Bold>
                            
                        </LightTextDependence>
                        <DeleteButton onClick={() => deleteDependency(dependency._id)}>
                            <img src={cancelXicon} alt="cancel X icon" />
                            <TextDelete>
                                Excluir
                            </TextDelete>
                        </DeleteButton>
                    </FlexElement>
                ))
            ) : (
                <LightTextDependence>
                    Não há dependencias para esta etapa
                </LightTextDependence>
            )}
            <CenterButton>
                <Button onClick={() => props.setShowDependencyDelete(false)}>Adicionar</Button>
            </CenterButton>
        </Container>
    )
}

export default ShowAndDeleteDependecy