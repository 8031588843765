const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
];

const semiAnual = [
    { namePeriod: 'Primeiro Semestre', init: 0, finish: 180 },
    { namePeriod: 'Segundo Semestre', init: 181, finish: 364 },
];

export function semiAnualInterval(semianualNumber: number) {
    const dataInicio = new Date();
    const semestreInicio = semianualNumber == 1 ? new Date(dataInicio.getFullYear(), 0, 1) : new Date(dataInicio.getFullYear(), 6, 1);
    const semestreFim = semianualNumber == 1 ? new Date(dataInicio.getFullYear(), 5, 30) : new Date(dataInicio.getFullYear() + 1, 0, 0);

    var intervals: string[] = []

    let dataAtual = new Date(semestreInicio);

    while (dataAtual <= semestreFim) {
        const semanaInicio = new Date(dataAtual);
        semanaInicio.setDate(semanaInicio.getDate() + (0 - semanaInicio.getDay()));
        const semanaFim = new Date(semanaInicio);
        semanaFim.setDate(semanaFim.getDate() + 13);

        const nomeMesInicio = months[semanaInicio.getMonth()];
        const nomeMesFim = months[semanaFim.getMonth()];

        const diaInicio = semanaInicio.getDate();
        const diaFim = semanaFim.getDate();

        var strIntervalo

        if (nomeMesInicio === nomeMesFim) {
            strIntervalo = `${nomeMesInicio} ${diaInicio}-${diaFim}`;
        } else {
            strIntervalo = `${nomeMesInicio} ${diaInicio}-${diaFim} ${nomeMesFim}`;
        }
        intervals.push(strIntervalo)
        dataAtual.setDate(dataAtual.getDate() + 14);
    }

    return intervals
}

export const totalSemianualPeriod = (semianualNumber: number) => {
    const dataAtual = new Date();
    const semestreInicio = semianualNumber == 1 ? new Date(dataAtual.getFullYear(), 0, 1) : new Date(dataAtual.getFullYear(), 6, 1);
    const semestreFim = semianualNumber == 1 ? new Date(dataAtual.getFullYear(), 5, 30) : new Date(dataAtual.getFullYear(), 11, 31);

    const diff = semestreFim.getTime() - semestreInicio.getTime();
    const diasSemestre = diff / (1000 * 3600 * 24) + 1;

    return diasSemestre;
}

function validSemianualDate(dateString: string, semester: number, year: number) {
    const [day, month, inputYear] = dateString.split('/');
    const date = new Date(Number(inputYear), Number(month) - 1, Number(day));

    if (date.getFullYear() !== year) {
        return false;
    }

    const firstSemesterMonths = [0, 1, 2, 3, 4, 5];
    const secondSemesterMonths = [6, 7, 8, 9, 10, 11];

    if (semester === 1) {
        return firstSemesterMonths.includes(date.getMonth());
    } else if (semester === 2) {
        return secondSemesterMonths.includes(date.getMonth());
    } else {
        return false;
    }
}

export function markDowPeridActualGantt(data: string, semester: number, yearNumber: number, period: string) {
    const formatPeriod = [{ "monthName": "Jan", "monthNumber": 1, "daysMonth": 31 }, { "monthName": "Fev", "monthNumber": 2, "daysMonth": 28 }, { "monthName": "Mar", "monthNumber": 3, "daysMonth": 31 }, { "monthName": "Abr", "monthNumber": 4, "daysMonth": 30 }, { "monthName": "Mai", "monthNumber": 5, "daysMonth": 31 }, { "monthName": "Jun", "monthNumber": 6, "daysMonth": 30 }, { "monthName": "Jul", "monthNumber": 7, "daysMonth": 31 }, { "monthName": "Ago", "monthNumber": 8, "daysMonth": 31 }, { "monthName": "Set", "monthNumber": 9, "daysMonth": 30 }, { "monthName": "Out", "monthNumber": 10, "daysMonth": 31 }, { "monthName": "Nov", "monthNumber": 11, "daysMonth": 30 }, { "monthName": "Dez", "monthNumber": 12, "daysMonth": 31 }]

    if (validSemianualDate(data, semester, yearNumber)) {
        var day = Number(data.split('/')[0])
        var month = Number(data.split('/')[1])
        //var year = Number(data.split('/')[2])

        var initialPeriod = period.split('-')[0]
        var finalPeriod = period.split('-')[1]

        var periodSelected = formatPeriod.find(format => format.monthNumber === month)

        if (periodSelected?.monthName === initialPeriod.split(' ')[0] &&
            Number(initialPeriod.split(' ')[1]) <= periodSelected.daysMonth ||
            periodSelected?.monthName === finalPeriod.split(' ')[0]
        ) {
            if (finalPeriod.split(' ').length > 1) {
                if (day >= Number(initialPeriod.split(' ')[1]) && day <= Number(finalPeriod.split(' ')[1])) {
                    return true
                }
            } else if (day >= Number(initialPeriod.split(' ')[1]) && day <= Number(finalPeriod.split(' ')[0])) {
                return true
            }
        }
    } else {
        return false
    }
}

export function semianualPeriod(trimesterNumber: number, dataInicialStr: string, dataFinalStr: string, yearNumber: number) {
    const startDateParts = dataInicialStr.split("/");
    const endDateParts = dataFinalStr.split("/");
    const startDate = new Date(
        Number(startDateParts[2]),
        Number(startDateParts[1]) - 1,
        Number(startDateParts[0])
    );
    const endDate = new Date(
        Number(endDateParts[2]),
        Number(endDateParts[1]) - 1,
        Number(endDateParts[0])
    );
    const start = new Date(startDate.getFullYear(), 0, 0);
    const end = new Date(endDate.getFullYear(), 0, 0);

    const startDiff = startDate.getTime() - start.getTime();
    const endDiff = endDate.getTime() - end.getTime();
    const oneDay = 1000 * 60 * 60 * 24;

    var startDay = Math.floor(startDiff / oneDay);
    var endDay = Math.floor(endDiff / oneDay);

    console.log(semiAnual[trimesterNumber].finish + 1)

    if (semiAnual[trimesterNumber].finish + 1 === 181) { /* 1º Semestre */
        if (validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && validSemianualDate(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay}/${endDay + 1}`;
        } else if (!validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && validSemianualDate(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${1}/${endDay + 1}`;
        } else if (validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && !validSemianualDate(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay}/${181}`;
        } else if (Number(startDateParts[2]) < Number(endDateParts[2]) && endDay > semiAnual[trimesterNumber].finish + 1) {
            return `${1}/${semiAnual[trimesterNumber].finish + 1}`;
        } else {
            return `-1/-1`;
        }
    } else if (semiAnual[trimesterNumber].finish + 1 === 365) { /* 2º trimestre em diante*/
        if (validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && validSemianualDate(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay - (semiAnual[trimesterNumber].init)}/${endDay - (semiAnual[trimesterNumber].finish + 1)}`;
        } else if (!validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && validSemianualDate(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${1}/${endDay - (semiAnual[trimesterNumber].finish + 1)}`;
        } else if (validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && !validSemianualDate(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay - (semiAnual[trimesterNumber].init + 1)}/${365}`;
        } else if (startDay < semiAnual[trimesterNumber].init && endDay > semiAnual[trimesterNumber].finish + 1 && Number(endDateParts[2]) === yearNumber) {
            return `${1}/${(semiAnual[trimesterNumber].finish + 1) - Number(365)}`;
        } else if (!validSemianualDate(dataInicialStr, trimesterNumber + 1, yearNumber) && endDay > semiAnual[trimesterNumber].finish + 1) {
            return `${1}/${(semiAnual[trimesterNumber].finish + 1) - Number(365)}`;
        } else if (Number(startDateParts[2]) === yearNumber && Number(endDateParts[2]) > yearNumber) {
            return `${1}/${365}`;
        } else {
            return `-1/-1`;
        }
    }
}