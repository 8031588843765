import styled from "styled-components";

export const Form = styled.div`
    .grid1, .grid2, .grid3, .grid4, .grid6{
        display: grid;
    }

    .grid1{
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
    }

    .grid2{
        grid-template-columns: 1fr 0.5fr 0.5fr;
        grid-column-gap: 1rem;

        #rt{
            text-align: end;
        }
    }

    .grid3{
        grid-template-columns: 0.2fr 1fr 0.5fr 0.5fr;
        grid-column-gap: 1rem;
    }

    .grid4{
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1rem;
    }

    .grid5{
        width:100%;
        justify-content: end;
        display: flex;
        
        div{
            width: max-content;
        }
    }

    .grid6{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 1rem;
    
        .information{
            font-weight: 300;
            color: var(--text-light-color);
            width: 11rem;
            padding-top: 0.5rem;
            font-size: 0.85rem;
        }

        .selectElement{
            width: 100%;
            margin-left: 0;
        }

        .containerBoxColor{
            display: flex;
            align-items: center;
            border: 1px solid #E1E1E1;
            background-color: #F1F1F2;
            border-radius: 0.3rem;
            color: #777777;
            font-weight: normal;
            font-size:0.9rem;

            input{
                border: none;
                width: 2rem;
                height:2rem;
                margin: 0.2rem;
            }
        }
    }

    .buttonRegister{
        width: 8rem;
    }
    
    .rtInput{
        border: 1px solid #E1E1E1;
        background-color: #F1F1F2;
        padding: 0.7rem;
        border-radius: 0.3rem;
        display: flex;

        input{
            border: none;
            background-color: #F1F1F2;
            margin-right: 0.2rem;
            width: 95%;
        }
    }
`

export const ContainerBoxInput = styled.div`
    display: grid;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    label{
        font-size:0.85rem;
        margin-bottom: 0.5rem;
    }

    div{
        display: flex;
        align-items: center;
        border: 1px solid #E1E1E1;
        background-color: #F1F1F2;
        
        border-radius: 0.3rem;

        .imgEye{
            width: 1.2rem;
            margin: 0.5rem;
            cursor: pointer;
        }

        input{
            border: none;
            background-color: #F1F1F2;
        }

        select{
            padding: 0.3rem;
            border-radius: 0.3rem;
            border: 1px solid #E1E1E1;
            margin-right: 0.5rem;
        }
    }
`

export const ContainerListItens = styled.div`
    display: flex;
    color: #c4c4c4;
    width: 22.4rem;
    overflow-x: scroll;
    background-color: #fff;
    border-radius: 0.3rem;
    border: 1px solid #E1E1E1;


    .list{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    input{
        width: 10rem;
        background-color: #E1E1E1;
        padding: 0.2rem;
        border: none;
        margin: 0.5rem;
        font-weight: 600;
    }

    ::-webkit-scrollbar {
        height: 0.5rem;
        background-color: #E1E1E1;
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        background: #333333;
        border-radius: 0.3rem;      
    }
`
export const Item = styled.span`
    width:max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0.25rem;
    border: 1px solid #E1E1E1;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    background-color: var(--primary-color);

    img{
        width: 0.5rem;
        margin-left: 0.35rem;
        cursor: pointer;
    }

    #socialMediaIcon {
        width: 1rem;
    }
`