import styled from "styled-components";

interface ButtonProps {
    color: string
    backgroundColor: string
    borderColor?: string
    hoverColor?: string
}

export const TopBar = styled.div`
    padding: 1rem 2rem 1rem 2rem;
    .ContainerInput{
        margin: 0;
    }
`

export const ActionsTopMenu = styled.div`
    background-color: var(--primary-color);
    padding: 0.75rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexItens{
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .typeStep{
        display: flex;
        align-items: center;

        p{
            margin-right: 0.5rem;
        }

        .selectOption{
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 0.5rem;
        }
    }

    .fileContainer{
        display: flex;
        align-items: center;

        p{
            margin-right: 0.5rem;
        }
    }
`

export const ActivitiesContainer = styled.div`
    background-color: var(--primary-color);
    overflow-y:auto;
    padding: 1rem;
    border-radius: 0.3rem;
    max-height: 90vh;

    #activityName{
        width: 100%;
        border: none;
        font-size: 1rem;
        color: #000000;
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 500;
    }

    .flexItens{
        display: flex;
        gap: 1rem;
    }
`

export const SelectStepContainer = styled.div`
    display: grid;
    height:71vh;
    grid-template-columns: 1fr 4fr;
    margin: 2rem;
    margin-bottom: 1rem;
    margin-top: 0;
    grid-column-gap: 1rem;
`

export const Activity = styled.div`
    background-color: var(--primary-color);
    padding: 0.75rem;
    display: flex;
    align-items: center;
    border: 1px solid #E5E5E5;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    border-radius: 0.3rem;

    input{
        border: none;
        font-size: 0.9rem;
        font-weight: bold;
        color: #141414;
        background-color: #ffff;
    }

    cursor: pointer;
`

export const ButtonAddActivity = styled.button`
    background-color: transparent;
    padding: 0.75rem;
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color)
    }

    cursor: pointer;
    img{
        margin-right: 0.5rem;
    }
`

export const Button = styled.button<ButtonProps>`
    width: 7rem;
    padding: 0.5rem;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    border-radius: 0.3rem;
    margin-left: 1rem;
    cursor: pointer;
    :hover{
        background-color: ${props => props.hoverColor};
    }
`

export const BottonsContainer = styled.div`
    display: flex;
    justify-content: end;
    margin: 2rem;
    margin-top: 0;
    margin-bottom: 1rem;
`

export const SelectionBox = styled.div`
    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked+label+div{
        background-color:rgba(0, 218, 205, 0.1);
    }
`
export const ContentMessageInformation = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
`
