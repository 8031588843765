import React from "react";
import { UseGanttContext } from "../../Context/GanttContext";
import { UseSelectionBudgetContext } from "../SelectionBudget/Context/SelectionBudgetContext"

import { ActivityDescriptionContainer, ModifyActivity, ActivityNameContainer } from "./style"

import AirConditioningDescription from "./AirConditioning";
import CrockeryAndMetalsDescription from "./CrockeryandMetals";
import EletricDescription from "./Electric";
import FloorsAndCoatingDescription from "./FloorsAndCoating";
import GlassworksDescription from "./Glassworks";
import HydraulicDescription from "./Hydraulics";
import LiningDescription from "./Lining";
import MarbleWorkDescription from "./MarbleWork";
import MasonaryDescription from "./Masonary";
import PaintingAndWallpaperDescription from "./Painting";
import RoofDescription from "./Roof";
import SawmillActivityDescription from "./Sawmill";
import ImpermeabilizationDescription from "./Impermeabilization";
import WoodworkActivityDescription from "./Woodwork";
import FramesDescription from "./Frames";
import FurnitureDescription from "./Furniture/Furniture";

import { stateType } from "../SelectionBudget/type"
import DoorsAndWindowsStepDescription from "./DoorsAndWindows";
import DemolitionStepDescription from "./Demolition";
import ProjectStepDescription from "./ProjectStep";
import CleaningDescription from "./Cleaning";
import MovingDescription from "./Moving";


const ActivityDescription: React.FC<stateType> = (props) => {
    const { primarySelectColor } = UseGanttContext()
    const { setModifyActivity } = UseSelectionBudgetContext()

    console.log(props.activity.stepType)

    return (
        <ActivityDescriptionContainer>
            <ActivityNameContainer backgroundColor={primarySelectColor}>
                <h4>{props.activity.activityName}</h4>
            </ActivityNameContainer>

            <div className="item">
                {props.activity.stepType === "Demolicao" ? (
                    <DemolitionStepDescription activity={props.activity} />)
                    : props.activity.stepType === "Alvenaria" ? (
                        <MasonaryDescription activity={props.activity} />)
                        : props.activity.stepType === "Loucas e metais" ? (
                            <CrockeryAndMetalsDescription activity={props.activity} />)
                            : props.activity.stepType === "Hidraulica" ? (
                                <HydraulicDescription activity={props.activity} />)
                                : props.activity.stepType === "Eletrica" ? (
                                    <EletricDescription activity={props.activity} />)
                                    : props.activity.stepType === "Pisos e revestimento" ? (
                                        <FloorsAndCoatingDescription activity={props.activity} />)
                                        : props.activity.stepType === "Vidracaria" ? (
                                            <GlassworksDescription activity={props.activity} />)
                                            : props.activity.stepType === "Forro" ? (
                                                <LiningDescription activity={props.activity} />)
                                                : props.activity.stepType === "Portas e janelas" ? (
                                                    <DoorsAndWindowsStepDescription activity={props.activity} />)
                                                    : props.activity.stepType === "Marmoraria" ? (
                                                        <MarbleWorkDescription activity={props.activity} />)
                                                        : props.activity.stepType === "Pintura" ? (
                                                            <PaintingAndWallpaperDescription activity={props.activity} />)
                                                            : props.activity.stepType === "Telhado" ? (
                                                                <RoofDescription activity={props.activity} />)
                                                                : props.activity.stepType === "Impermeabilizacao" ? (
                                                                    <ImpermeabilizationDescription activity={props.activity} />)
                                                                    : props.activity.stepType === "Ar condicionado" ? (
                                                                        <AirConditioningDescription activity={props.activity} />)
                                                                        : props.activity.stepType === "Serralheria" ? (
                                                                            <SawmillActivityDescription activity={props.activity} />)
                                                                            : props.activity.stepType === "Marcenaria" ? (
                                                                                <WoodworkActivityDescription activity={props.activity} />)
                                                                                : props.activity.stepType === "Esquadrias" ? (
                                                                                    <FramesDescription activity={props.activity} />)
                                                                                    : props.activity.stepType === "Mobiliario" ? (
                                                                                        <FurnitureDescription activity={props.activity} />)
                                                                                        : props.activity.stepType === "Projeto" ? (
                                                                                            <ProjectStepDescription activity={props.activity} />)
                                                                                            : props.activity.stepType === "Limpeza" ? (
                                                                                                <CleaningDescription activity={props.activity} />)
                                                                                                : props.activity.stepType === "Mudança" ? (
                                                                                                    <MovingDescription activity={props.activity} />
                                                                                                ) : (<></>)}
            </div>

            {!props.activity.selectedBudget ? (
                <ModifyActivity>
                    <p onClick={() => setModifyActivity?.(true)}>Alterar atividade</p>
                </ModifyActivity>
            ) : (<></>)}
        </ActivityDescriptionContainer>
    )
}

export default ActivityDescription