import React, { useEffect, useState } from "react"
import axios from "axios"
import { UseStepContext } from "../../../Context/stepContext"
import { UseUserContext } from "../../../Context/UserContext"
import { ActionSelect, Input } from "../../../components/stylesDomElements/htmlStyledElements"
import { NewPartDivisorContent } from "./style"

const PartDivisor: React.FC = () => {
    const { projectSelected } = UseUserContext()
    const { step, setStep } = UseStepContext()
    const [partialsList, setPartialsList] = useState<string[]>([])
    const [partDivisor, setPartDivisor] = useState<string>('Geral')
    const [newPartDivisor, setNewPartDivisor] = useState<string>('')

    useEffect(() => {
        if (projectSelected) {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}/partialsList/${projectSelected}`)
                .then((res) => {
                    setPartialsList(res.data);
                });
        }
    }, [projectSelected]);

    useEffect(() => {
        const stepCopy = step
        if (partDivisor === 'new') {
            stepCopy.partialsDivisor = [newPartDivisor]
        } else {
            stepCopy.partialsDivisor = [partDivisor]
        }
        setStep?.(stepCopy)
    }, [newPartDivisor])

    return (
        <>
            {partDivisor === "new" ? (
                <NewPartDivisorContent>
                    <Input type='text' onChange={(e) => setNewPartDivisor(e.target.value)} />
                    <a href="#" onClick={() => setPartDivisor('')}>{'<'}</a>
                </NewPartDivisorContent>
            ) : (
                <ActionSelect>
                    Cômodo:
                    <select value={partDivisor} name="partDivisor" id="partDivisor" onChange={(e) => setPartDivisor(e.target.value)}>
                        <option key={0} value="Geral">Geral</option>
                        {partialsList.map((partial, index) => (
                            <option key={index + 1} value={partial}>{partial}</option>
                        ))}
                        <option key={partialsList.length + 1} value="new">Novo cômodo</option>
                    </select>
                </ActionSelect>
            )}
        </>
    )
}



export default PartDivisor