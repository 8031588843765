import React, { useState} from "react";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    item_revestido?:string,
    pedra?:string,
    cor_pedra?:string,
    acabamento_pedra?:string,
    altura_item_revestido?:string,
    largura_item_revestido?:string,
    espessura_chapa?:string,
    altura_pedras?:string,
    largura_pedras?:string,
    dimensaoPedrasNaoAplicavel?:boolean
}

const CoatingDescription: React.FC<stateType> = (props) => {    
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>

            <div className="item">
                {activity?.item_revestido ? (
                    <div className="line">
                        <p>Item revestido</p>
                        <p>
                            {activity.item_revestido} 
                        </p>
                    </div>
                ) : null}

                {activity?.pedra ? (
                    <div className="line">
                        <p><br/>Pedra</p>
                        <p>
                        <br/>{activity.pedra} 
                        </p>
                    </div>
                ) : null}

                {activity?.cor_pedra ? (
                    <div className="line">
                        <p>Cor da pedra</p>
                        <p>
                            {activity.cor_pedra} 
                        </p>
                    </div>
                ) : null}

                {activity?.acabamento_pedra ? (
                    <div className="line">
                        <p>Acabamento</p>
                        <p>
                            {activity.acabamento_pedra}
                        </p>
                    </div>
                ) : null}

                    <p className="bold"><br/>Dimensões item a ser revestido:</p>    

                {activity?.altura_item_revestido ? (
                    <div className="line">
                        <p><br/>Altura</p>
                        <p>
                            <br/>{activity.altura_item_revestido} cm
                        </p>
                    </div>
                ) : null}     

                {activity?.largura_item_revestido ? (
                    <div className="line">
                        <p>Largura</p>
                        <p>
                            {activity.largura_item_revestido} cm
                        </p>
                    </div>
                ) : null}

                {activity?.espessura_chapa ? (
                    <div className="line">
                        <p><br/>Espessura da chapa</p>
                        <p>
                        <br/>{activity.espessura_chapa}
                        </p>
                    </div>
                ) : null}

                    <p className="bold"><br/>Dimensões das pedras:</p>

                {activity?.dimensaoPedrasNaoAplicavel ? (
                    <div className="line">
                        <p>Não se aplica/Não definido</p>
                        <p>
                            {activity.dimensaoPedrasNaoAplicavel}
                        </p>
                    </div>
                ) : null}  

                {activity?.altura_pedras ? (
                    <div className="line">
                        <p><br/>Altura</p>
                        <p>
                            <br/>{activity.altura_pedras} cm
                        </p>
                    </div>
                ) : null}

                {activity?.largura_pedras ? (
                    <div className="line">
                        <p>Largura</p>
                        <p>
                            {activity.largura_pedras} cm
                        </p>
                    </div>
                ) : null}       

            </div>
        </div>
    );
};

export default CoatingDescription;
