import styled from "styled-components";

export const DeleteButton=styled.button`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem;
    border-radius: 0.3rem;
    border: 1px solid #E1E1E1;
    background-color: #fff;
    cursor: pointer;
`

export const LightTextDependence=styled.p`
    font-weight: 400;
`

export const Bold=styled.b`
    font-weight: 500;
`

export const TextDelete=styled.p`
    font-size: 0.8rem;
`
