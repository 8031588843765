import styled from "styled-components";


export const Container = styled.div`
    display: grid;
    justify-items: center;
    grid-template-columns:1fr 1fr;
    grid-gap:1rem;
    margin-top: 2rem;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 0.3rem;
    margin: 1rem;
    

    input{
        border-radius:0.3rem;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
        padding: 0.5rem;
   } 

   select{
    border-radius:0.3rem;
    padding: 0.5rem;
    background-color: #F1F1F2;
    border: 1px solid #E1E1E1;
    color: #0000;
   }

   label{
       margin-bottom: 0.75rem;
   }
`
export const ScreenContainer=styled.div`
    padding: 1rem;
`

export const LeftContainer = styled.div`
    width: 45vw;
    margin-top: 0.7rem;
    
   .content1{
       display: grid;
       input{
           width: 100%;
           color: #141414;
       }
   }

   .contentDefault{
    display: grid;
    input{
        width: 15rem;
    }

    select {
        color: #141414;
    }
   }

   .content2, .content3, .content4 {
        display: flex;
        justify-content:space-between;
        margin-bottom: 0.75rem;
    }
`

export const RightContainer = styled.div`
    width: 45vw;
    margin-top: 0.7rem;
    
    .googleMap{
        height: 5rem;
        margin-bottom: 0.5rem;
    }

    .contentDefault{
    display: grid;
    margin-bottom: 0.75rem;

    }
   
   .content1, .content3{
    display: grid;
    margin-bottom: 0.75rem;
   
   }
   
   .content2{
    display: flex;
    justify-content:space-between;
   }

   .content3{
    textarea{
        border-radius:0.3rem;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
        padding: 0.5rem;
        color: #0000;
        resize:none;
        height: 5rem;
    }
   }

   .buttons{
       display: flex;
       justify-content:end;
   }

   .videoButton{
        label {
            margin: 0;
        }
   }
`

export const ContentFiles = styled.div`
    display: flex;
    justify-content: space-between;
`

export const ContainerImage = styled.div`
    height: 18rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    img{
        width: 100%;
        height: 100%;
    }
`

export const Button = styled.button`
    background-color: transparent;
    color:var(--secondary-color);
    padding: 0.75rem;
    border-radius:0.3rem;
    border: 1px solid var(--secondary-color);
    font-weight: 500;
    margin: 0.5rem 2rem;
    cursor:pointer;
    :hover{
        background-color: var(--secondary-color);
        color: #ffff;
    }
`
export const InputForm = styled.input`
    border: 1px solid #E1E1E1;
    background-color: #F1F1F2;
    padding: 0.7rem;
    border-radius: 0.3rem;
`
export const InputFile = styled.div`
    max-width: 25rem;
    background-color: #F1F1F2;
    padding-left: 0.5rem;
    border: 1px solid #E1E1E1;
    border-radius: 0.3rem;
    color: #141414;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;

    label {
        padding: 0.3rem;
        margin-top: 0.3rem;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        border-radius: 0.3rem;
        margin-right: 0.2rem;
        cursor: pointer;
    }
    `

export const ContainerInputForm = styled.div`
    display: grid;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    label{
        font-size:0.85rem;
        margin-bottom: 0.5rem;
    }

    select{
        padding: 0.5rem;
        border: 1px solid #E1E1E1;
        background-color: #F1F1F2;
        color: #141414;
    }

    #number{
        width: 100%;
    }

    div{
        display: flex;
        width: 100%;
    }

    .marginContrataionContainer{
        border: 1px solid #E1E1E1;
        background-color: #F1F1F2;
        padding: 0.7rem;
        border-radius: 0.3rem;

        display:flex;
        justify-content: space-between;

        input{
            border:none;
            background-color: #F1F1F2;
        }
    }
`

export const SubmitArea = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #ffff;
margin: -1rem 1rem;;
border-radius: 0.3rem;

.buttons{
    justify-content: space-between;
}
`
export const SelectedVideos = styled.div`
    max-width: 60rem;
    
    label{
    font-size:0.85rem;
    margin-bottom: 0.5rem;
    }

`
export const SelectedFiles = styled.div`
    max-width: 60rem;
    margin-top: 10px;

    label{
    font-size:0.85rem;
    margin-bottom: 0.5rem;
    }

`

export const ContainerListItens = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 60rem;
    max-height: 13.5rem;
    padding:0.3rem;
    border-radius: 0.3rem;
    border: 1px solid #E1E1E1;
    margin-top:0.3rem;
    overflow-x: hidden;
    overflow-y: auto;
   

    ::-webkit-scrollbar {
        height: 0.5rem;
        background-color: #E1E1E1;
    }
    ::-webkit-scrollbar-thumb {
        background: #333333;
        border-radius: 0.3rem;
    }
`
export const Item = styled.span`
  
    display: flex;
    justify-content: space-between;
    padding: 4px 8px ;
    margin: 4px 0;
    margin-right: 0.25rem;
    border: 1px solid #E1E1E1;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    background-color: var(--primary-color);

    img{
        width: 0.5rem;
        margin-left: 0.35rem;
        cursor: pointer;
    }
`

