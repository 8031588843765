import moment from "moment"

export const yearlyMonths = () => {
    var yearly: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    return yearly
}

export const markdowSelectPeriod = (monthName: string, yearNumber: number) => {
    const formatPeriod = [{ "monthName": "Janeiro", "monthNumber": 1, "daysMonth": 31 }, { "monthName": "Fevereiro", "monthNumber": 2, "daysMonth": 28 }, { "monthName": "Março", "monthNumber": 3, "daysMonth": 31 }, { "monthName": "Abril", "monthNumber": 4, "daysMonth": 30 }, { "monthName": "Maio", "monthNumber": 5, "daysMonth": 31 }, { "monthName": "Junho", "monthNumber": 6, "daysMonth": 30 }, { "monthName": "Julho", "monthNumber": 7, "daysMonth": 31 }, { "monthName": "Agosto", "monthNumber": 8, "daysMonth": 31 }, { "monthName": "Setembro", "monthNumber": 9, "daysMonth": 30 }, { "monthName": "Outubro", "monthNumber": 10, "daysMonth": 31 }, { "monthName": "Novembro", "monthNumber": 11, "daysMonth": 30 }, { "monthName": "Dezembro", "monthNumber": 12, "daysMonth": 31 }]

    var month = Number(moment().format('DD/MM/YYYY').toString().split('/')[1])
    var year = Number(moment().format('DD/MM/YYYY').toString().split('/')[2])

    var periodSelected = formatPeriod.find((period => period.monthNumber === month))
    if (periodSelected?.monthName === monthName && year === yearNumber) {
        return true
    }

}