export const primaryColor = [
  "#5E6FB4",
  "#5363A2",
  "#4A5890",
  "#415C7D",
  "#38506B",
  "#2F4460",
  "#263856",
  "#1D2C4D",
  "#142240",
  "#5667AB",
  "#4D5B9D",
  "#44508F",
  "#3B4681",
  "#323B74",
  "#293065",
  "#202558",
  "#17194B",
  "#0E0F3E",
  "#050631",
  "#000924",
  "#53679E",
  "#4B6388",
  "#436F73",
];
export const secondaryColor = [
  "#5E6FB450",
  "#5363A250",
  "#4A589050",
  "#415C7D50",
  "#38506B50",
  "#2F446050",
  "#26385650",
  "#1D2C4D50",
  "#14224050",
  "#5667AB50",
  "#4D5B9D50",
  "#44508F50",
  "#3B468150",
  "#323B7450",
  "#29306550",
  "#20255850",
  "#17194B50",
  "#0E0F3E50",
  "#05063150",
  "#00092450",
  "#53679E50",
  "#4B638850",
  "#436F7350",
];
