import React, { useState, useEffect } from "react";

import { DropDownElement } from "../stylesDomElements/htmlStyledElements";

import { UseGanttContext } from "../../Context/GanttContext";

import dropDownIcon from "../../assets/dropDown.svg";

interface dropDonwProps {
  function?: any;
  scaleY: number;
}

const DropDown: React.FC<dropDonwProps> = (props) => {
  const [scaleY, setScaleY] = useState<number>(props.scaleY);

  useEffect(() => {
    setScaleY(props.scaleY);
  }, [props.scaleY]);

  const modifyIconDropDown = () => {
    props.function();
    if (scaleY === -1) {
      setScaleY(1);
    } else setScaleY(-1);
  };

  return (
    <DropDownElement
      className="dropDownElement"
      paddingRight="0"
      valueScaleY={scaleY}
    >
      <span>
        <img
          onClick={() => modifyIconDropDown()}
          src={dropDownIcon}
          alt="seta direcional"
        />
      </span>
    </DropDownElement>
  );
};

export default DropDown;
