import React, { useState, useEffect } from "react";

import { UseHydraulicContext } from "../../../../Context/ActivitiesContext/Hydraulic/HidraulicContext";
import { attributeType } from "../../../../Models/attributeType";
import { ColdWaterFormType } from "../../../../Models/Hydraulic/type";
import { ColdWater } from "../../../../Models/Hydraulic/ColdWaterModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const ColdWaterStepForm: React.FC<stepFormProps> = (props) => {
  const { hydraulicActivities, indexHydraulicActivitySelected, editHydraulicActivity } = UseHydraulicContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<ColdWaterFormType>(ColdWater)

  useEffect(() => {
    if (hydraulicActivities.length > 0) {
      let hydraulicActivityCopy = hydraulicActivities[indexHydraulicActivitySelected]
      hydraulicActivityCopy.activityType = 'Agua fria'
      editHydraulicActivity?.(indexHydraulicActivitySelected, hydraulicActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHydraulicActivitySelected])

  useEffect(() => {
    if (hydraulicActivities.length > 0) {
      setItensFormList(hydraulicActivities[indexHydraulicActivitySelected].ColdWater)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHydraulicActivitySelected, hydraulicActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemEmendas(0)
      removeItemItensEspeciais(0)
      removeItemPontoDeAguaFria(0)
      removeItemPontoDeFiltro(0)
      removeItemPontoDeRegistro(0)
      removeItemRalo(0)
      removeItemRetirarPontoDeAguaFria(0)
      removeItemTubulacao(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Ponto de agua fria") {
      addPontoDeAguaFria({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Retirar ponto de agua fria") {
      addRetirarPontoDeAguaFria({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Ponto de filtro") {
      addPontoDeFiltro({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Ponto de registro") {
      addPontoDeRegistro(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Tubulacao") {
      addTubulacao(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm linear',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Emendas") {
      addEmendas(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Ralo") {
      addRalo(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Itens especiais") {
      addItensEspeciais(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    }
  }

  const addRalo = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
    console.log('oi')
  }

  const addRetirarPontoDeAguaFria = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaFria.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addPontoDeRegistro = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addPontoDeFiltro = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeFiltro.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addTubulacao = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addEmendas = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addPontoDeAguaFria = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaFria.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addItensEspeciais = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.ItensEspeciais.push(object)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemRalo = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemRetirarPontoDeAguaFria = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaFria.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemPontoDeRegistro = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemPontoDeFiltro = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeFiltro.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemTubulacao = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemEmendas = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemPontoDeAguaFria = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaFria.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemItensEspeciais = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.ItensEspeciais.splice(index, 1)
    hydraulicActivitiesCopy[0].ColdWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }



  const editRaloValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRaloAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRetirarPontoDeAguaFriaValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaFria[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRetirarPontoDeAguaFriaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaFria[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeRegistroValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeRegistroAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeFiltroValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeFiltro[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeFiltroAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeFiltro[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }


  const editTubulacaoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editEmendasValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editEmendasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeAguaFriaValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaFria[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeAguaFriaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaFria[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editItensEspeciaisValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.ItensEspeciais[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editItensEspeciaisAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.ItensEspeciais[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }


  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Ralo.length ? (
        <div>
          <p>Ralo</p>
          {itensFormList.Ralo.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRaloAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRaloValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRaloValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRaloAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRaloValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m linear']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRalo(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


      {itensFormList.PontoDeRegistro.length ? (
        <div>
          <p>Ponto de registro</p>
          {itensFormList.PontoDeRegistro.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeRegistroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeRegistroValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeRegistroValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeRegistroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeRegistroValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeRegistro(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.RetirarPontoDeAguaFria.length ? (
        <div>
          <p>Retirar ponto de água fria</p>
          {itensFormList.RetirarPontoDeAguaFria.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRetirarPontoDeAguaFriaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRetirarPontoDeAguaFriaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRetirarPontoDeAguaFriaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRetirarPontoDeAguaFriaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRetirarPontoDeAguaFriaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRetirarPontoDeAguaFria(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PontoDeFiltro.length ? (
        <div>
          <p>Ponto de filtro</p>
          {itensFormList.PontoDeFiltro.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeFiltroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeFiltroValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeFiltroValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeFiltroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeFiltroValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeFiltro(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Tubulacao.length ? (
        <div>
          <p>Tubulação</p>
          {itensFormList.Tubulacao.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTubulacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTubulacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editTubulacaoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemTubulacao(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Emendas.length ? (
        <div>
          <p>Emendas</p>
          {itensFormList.Emendas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmendasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmendasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemEmendas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PontoDeAguaFria.length ? (
        <div>
          <p>Ponto de água fria</p>
          {itensFormList.PontoDeAguaFria.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeAguaFriaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeAguaFriaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeAguaFriaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeAguaFriaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeAguaFriaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeAguaFria(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.ItensEspeciais.length ? (
        <div>
          <p>Itens especiais</p>
          {itensFormList.ItensEspeciais.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editItensEspeciaisAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editItensEspeciaisValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editItensEspeciaisValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editItensEspeciaisAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editItensEspeciaisValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemItensEspeciais(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ColdWaterStepForm;
