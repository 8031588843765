import React, { SetStateAction } from "react"

import { constructor, worker } from "../../../../Config/dictionary"

import { PaginationElement } from "../../style"
import { SupplierProps } from "../../../../types/typesEntitiesProject"

interface paginationContentProps {
    suppliers: SupplierProps[],
    intervalShowSuppliers: number,
    setIntervalShowSuppliers: React.Dispatch<SetStateAction<number>>
}

const PaginationContent: React.FC<paginationContentProps> = (props) => {
    const previosInterval = () => {
        if (props.intervalShowSuppliers >= 10) {
            props.setIntervalShowSuppliers(props.intervalShowSuppliers - 5)
        }
    }
    const nextInterval = () => {
        if (props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).length >= 5) {
            props.setIntervalShowSuppliers(props.intervalShowSuppliers + 5)
        }
    }

    return (
        <PaginationElement>
            {props.intervalShowSuppliers >= 10 ? (
                <button className="activeButton" onClick={() => previosInterval()}>{"< Anterior"}</button>
            ) : (
                <button className="disabled">{"< Anterior"}</button>
            )}

            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).length >= 5 ? (
                <>
                    <button className="activeButton" onClick={() => nextInterval()}>{"Próximo >"}</button>
                </>
            ) : (
                <button className="disabled">{"Próximo >"}</button>
            )}
        </PaginationElement>
    )
}

export default PaginationContent