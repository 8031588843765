import React, { useState } from "react";
import { DoorsAndWindowsFormType } from "../../../../Models/DoorsAndWindows/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  DoorsAndWindows: DoorsAndWindowsFormType;
}

const DoorsAndWindowsDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>
        {activity.DoorsAndWindows.Porta?.map((item, index) => (
          <div key={index}>
            {activity.DoorsAndWindows.Porta.length > 1 ? (<u>Porta {index + 1}</u>) : (<u>Porta </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.DoorsAndWindows.Janela?.map((item, index) => (
          <div key={index}>
            {activity.DoorsAndWindows.Janela.length > 1 ? (<u>Janela {index + 1}</u>) : (<u>Janela </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />

      </div>
    </div>
  );
};

export default DoorsAndWindowsDescription;