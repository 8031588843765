export const PassageOfInfra = {
    Conduite: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Fornecedor',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
    NovosInterruptores: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
    NovasTomadas: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
    NovosPontosDeIluminacao: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}