import React, { useEffect } from "react"

import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const AirConditionerController: React.FC = (props) => {
  const { activitySelected, setActivitySelected } = UseStepContext()

  useEffect(() => {
    setActivitySelected('Ar condicionado')
  }, [])

  return <div>
    <ActionSelect>
      Atividade:
      <select value={activitySelected} name="impermeabilizationStep" id="impermeabilizationStep" onChange={(e) => setActivitySelected?.(e.target.value)}>
        <option value="Ar condicionado">Ar condicionado</option>
        <option value="Materiais para ar-condicionado">Materiais</option>
      </select>
    </ActionSelect>
  </div>
}

export default AirConditionerController