import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { Furniture } from "../../../Models/Furniture/FurnitureModel";
import { FurnitureFormType } from "../../../Models/Furniture/type";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesFurnitureProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Furniture: FurnitureFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  furnitureActivities: activitiesFurnitureProps[];
  furnitureActivityName: string;
  indexFurnitureActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesFurnitureProps[]>
  >;
  setNewFurnitureActivity?: () => void;
  setFurnitureActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexFurnitureActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editFurnitureActivity?: (
    index: number,
    activity: activitiesFurnitureProps
  ) => void;
  deleteFurnitureActivity?: (index: number) => void;
}

const activityContextDefault = {
  furnitureActivities: [],
  furnitureActivityName: "",
  indexFurnitureActivitySelected: 0,
  setNewFurnitureActivity: () => {},
  setFurnitureActivityName: () => {},
  setIndexFurnitureActivitySelected: () => {},
  editFurnitureActivity: () => {},
  deleteFurnitureActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseFurnitureActivityContext = () => useContext(ActivitiesContext);

export const FurnitureActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [furnitureActivities, setActivity] = useState<
    activitiesFurnitureProps[]
  >(activityContextDefault.furnitureActivities);

  const [indexFurnitureActivitySelected, setIndexFurnitureActivitySelected] =
    useState<number>(0);

  const [furnitureActivityName, setFurnitureActivityName] =
    useState<string>("Mobiliario");

  const editFurnitureActivity = (
    index: number,
    activity: activitiesFurnitureProps
  ) => {
    let activitiesEdited = furnitureActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteFurnitureActivity = (index: number) => {
    const activity = [...furnitureActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewFurnitureActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: furnitureActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Mobiliario",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Furniture: Furniture,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        furnitureActivities,
        furnitureActivityName,
        indexFurnitureActivitySelected,
        setActivity,
        setFurnitureActivityName,
        setNewFurnitureActivity,
        editFurnitureActivity,
        deleteFurnitureActivity,
        setIndexFurnitureActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
