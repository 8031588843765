import styled from "styled-components";

interface dropDownProps {
  valueScaleY: number;
  paddingRight: string;
}

interface scaleY {
  valueScaleY?: number;
}

/**Inicio Modal */
export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(66 66 66 / 35%);
  z-index: 3;
  transition: 0.5s;

  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
`;

export const TopBar = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
`;

export const ContainerInput = styled.div`
  padding: 0.5rem;
  background-color: #f1f2f3;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  margin-right: 6vw;

  img {
    margin-left: 0.5rem;
    margin-right: 0.75rem;
  }
`;

export const Input = styled.input`
  width: 19vw;
  border: none;
  background-color: #f1f2f3;
  padding: 0.25rem;
`;

export const MenuActionsPage = styled.div`
  background-color: var(--primary-color);
  margin: 0 auto;
  padding: 0.6rem;
  width: 95vw;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;

  .leftContent {
    display: flex;
    align-items: center;
  }
`;

export const FormContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;

  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const HeaderFormContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 0px #f1f2f3;
  div {
    background-color: #f1f2f3;
    padding: 0.4rem;
    border-radius: 0.3rem;
  }
  img {
    cursor: pointer;
    margin: 0.1rem;
  }
`;
/**Fim Modal */

export const Select = styled.select`
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid #e5e5e5;
`;

export const InputFile = styled.div`
  max-width: 25rem;
  background-color: #f1f1f2;
  border: 1px solid #e1e1e1;
  border-radius: 0.3rem;
  color: #141414;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    margin: 0.2rem;
    white-space: nowrap;
    background-color: var(--primary-color);
    padding: 0.4rem;
    border: 1px solid #e1e1e1;
    color: var(--black-color);
    border-radius: 0.3rem;
    cursor: pointer;
  }
`;

export const Table = styled.table`
  width: 95vw;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1rem auto;
  margin-top: 0;

  .leftContent {
    text-align: left;
  }

  .leftContentType {
    text-align: left;
    color: #141414;
    font-weight: 500;
  }

  th {
    font-weight: 400;
    padding: 0.5rem;
    text-align: center;
  }

  .headerTable {
    th {
      font-weight: 500;
    }
  }

  tbody {
    background-color: var(--primary-color);
    th {
      color: #777777;
    }
    .actionElement {
      display: flex;
      justify-content: start;
      img {
        width: 1rem;
      }
    }
  }

  .justifyLeft {
    text-align: left;
  }

  .separate {
    background-color: #e5e5e5;
    th {
      padding: 0.2rem;
    }
  }
`;

export const ActionSelect = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2.5rem;

  font-weight: 500;

  select {
    margin-left: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid #e5e5e5;

    cursor: pointer;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  background-color: #f1f1f2;
  border: 1px solid #e1e1e1;
  border-radius: 0.3rem;
  img {
    margin: 0.5rem;
    cursor: pointer;
  }

  input {
    border: none;
    background-color: #f1f1f2;
    padding: 0.5rem;
  }
  span {
    color: var(--text-light-color);
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0.5rem;
  }
`;

export const ContainerInputForm = styled.div`
  display: grid;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  label {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }

  select {
    max-height: 2.2rem;
    padding: 0.5rem;
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    color: #141414;
  }

  #number {
    width: 100%;
  }

  div {
    display: flex;
    width: 100%;
  }

  .marginContrataionContainer {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;

    input {
      border: none;
      background-color: #f1f1f2;
    }
  }

  .alert {
    color: red;
  }
`;

export const InputForm = styled.input`
  border: 1px solid #e1e1e1;
  background-color: #f1f1f2;
  padding: 0.7rem;
  border-radius: 0.3rem;
`;

export const ContainerCheckBox = styled.div`
  color: #777777;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;

  .buttonAddFile {
    padding: 0.25rem;
    font-size: 0.75rem;
    height: 1.75rem;
    margin-right: 1rem;
  }

  .activityContentItem,
  .actionsActivityContentItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actionsActivityContentItem {
    span {
      cursor: pointer;
    }
  }

  label {
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
    position: relative;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: " ";
    width: inherit;
    height: inherit;
    position: absolute;
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
  }

  input[type="checkbox"]:checked::after {
    content: " ";
    width: 0.3rem;
    height: 0.7rem;
    margin-top: 0.1rem;
    margin-left: 0.4rem;
    border-color: #777777;
    border-style: solid;
    border-width: 0 2px 2px 0;
    position: absolute;
    transform: rotate(45deg);
  }
`;

export const FooterFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--secondary-color);
  padding: 0.75rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.3rem;
  font-size: 0.85rem;
  cursor: pointer;
  margin-left: 1rem;
  :hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }

  img {
    margin-right: 0.5rem;
  }
`;

export const ReturnButton = styled.button`
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.3rem;
  font-weight: 500;
  font-size: 0.85rem;
  margin-top: 0.7rem;
  cursor: pointer;
`;

export const MenuOverlay = styled.div`
  position: absolute;
  background-color: var(--dark-background);
  border-radius: 0.5rem;
  display: grid;
  margin-top: 0.5rem;
  margin-left: 3.5rem;
  margin-right: 0.5rem;

  img {
    margin: 0.5rem;
  }

  div {
    border-top: 1px solid #ffffff;
  }

  .pointsMenu {
    display: flex;
    justify-content: flex-end;
    border: none;

    .poitElements {
      position: absolute;
      margin: 0.5rem;
      width: 1.75rem;
      height: 1.75rem;
      display: grid;
      align-content: center;
      justify-content: center;
      border: none;

      cursor: pointer;
    }
  }
`;

export const OptionButton = styled.button<scaleY>`
  color: var(--primary-color);
  font-weight: 400;
  margin: 0.5rem;
  border: none;
  background-color: transparent;
  font-size: 0.85rem;
  cursor: pointer;
  a {
    color: var(--primary-color);
  }
  img {
    cursor: pointer;
    transform: scaleY(${(props) => props.valueScaleY});
  }
`;

export const InputActivity = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #e1e1e1;
  color: #777777;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 0.9rem;
  input {
    border: none;
    background-color: #e1e1e1;
    width: 100%;
  }

  .noBreakLine {
    white-space: nowrap;
  }

  button {
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    border-radius: 5px;
    padding: 0 0.3rem 0 0.3rem;
    color: var(--secondary-color);
    font-weight: bold;
    :hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
      font-weight: bold;
    }
  }
`;

export const TooltipText = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;

  img {
    max-width: 0.8rem;
  }

  input {
    border: none;
    font-size: 0.9rem;
    color: #777777;
    width: 8rem;
    text-overflow: ellipsis;
    background-color: transparent;
  }
`;
export const TooltipBox = styled.div`
  visibility: hidden;
  border-radius: 4px;
  font-size: 0.65rem;
  position: absolute;
`;
export const TooltipCard = styled.div`
  ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #777777;
    background-color: #fff;
    border: 1px solid rgba(119, 119, 119, 0.8);
    width: 230px;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;
  }
`;
export const DropDownElement = styled.div<dropDownProps>`
  padding-bottom: 0.5rem;
  padding-right: ${(props) => props.paddingRight};
  padding-left: 0.75rem;

  img {
    cursor: pointer;
    transform: scaleX(${(props) => props.valueScaleY});
  }
`;

export const ContentElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
`;
