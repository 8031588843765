import React, { useEffect } from "react"

import { UsePaintActivityContext } from "../../../Context/ActivitiesContext/Paint/PaintContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect, } from "../../stylesDomElements/htmlStyledElements";

const PaintController: React.FC = () => {
    const { paintActivities, editPaintActivity } = UsePaintActivityContext()
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Pintura')
    }, [])

    useEffect(() => {
        if (paintActivities.length) {
            var activityEdited = paintActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = activitySelected
            editPaintActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    /* useEffect(() => {
        if (paintActivities.length > 0 && paintActivities[0].activityType !== "") {
            setActivitySelected(paintActivities[0].activityType)
        }
        paintActivities.length > 0 && paintActivities[0].activityType === "" ? (setActivitySelected('Pintura')) : (<></>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) */

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="paintStep" id="paintStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Pintura">Pintura</option>
                <option value="Papel de parede">Papel de parede</option>
                <option value="Materiais para pintura">Materiais</option>

            </select>
        </ActionSelect>
    </div>
}

export default PaintController