import React from "react";

import {
  ContanierFinalization,
  ItemContainerFinzalization,
  ContentInputPrice,
  PriceItem,
  ContainerButtonSendBudget,
  ConditionsPayment,
  ContentInputObservations,
} from "../../style";

interface footerBudgetProps {
  setMinimunBudgetValue: React.Dispatch<React.SetStateAction<string>>;
  setWorkingDays: React.Dispatch<React.SetStateAction<number>>;
  setConditions: React.Dispatch<React.SetStateAction<string>>;
  setObservations?: React.Dispatch<React.SetStateAction<string>>;
  submitBudget: any;
}

const FooterBudget: React.FC<footerBudgetProps> = (props) => {
  return (
    <div>
      <ContanierFinalization>
        <ItemContainerFinzalization>
          <p>Observações</p>
          <ContentInputObservations>
            <PriceItem
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setMinimunBudgetValue(e.target.value)
              }
            />
          </ContentInputObservations>
        </ItemContainerFinzalization>

        <ItemContainerFinzalization>
          <p>Prazo de entrega</p>
          <div className="workingDays">
            <input
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setWorkingDays(parseInt(e.target.value))
              }
            />
            dias úteis
          </div>
        </ItemContainerFinzalization>
        <ConditionsPayment>
          <p>Condições de pagamento</p>
          <div>
            <input
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.setConditions(e.target.value)
              }
            />
          </div>
        </ConditionsPayment>
      </ContanierFinalization>

      <ContainerButtonSendBudget onClick={props.submitBudget}>
        <button>Enviar orçamento</button>
      </ContainerButtonSendBudget>
      <br />
    </div>
  );
};

export default FooterBudget;
