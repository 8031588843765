import styled from "styled-components";

export const TopBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 2rem;
`;
export const Container = styled.div`
  touch-action: manipulation;
  @media (max-width: 480px) {
    max-width: 152em;
    -webkit-transform: scale(0.16);
    -webkit-transform-origin: 0 0;
  }
`;
