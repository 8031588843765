import React, { useEffect, useState } from "react"
import { UseDemolitionActivityContext } from "../../../Context/ActivitiesContext/Demolition/DemolitionContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";
import { textTransform } from "../script"

const DemolitionController: React.FC = () => {
  const { activitySelected, setActivitySelected } = UseStepContext()
  const { demolitionActivities, editDemolitionActivity } = UseDemolitionActivityContext()

  useEffect(() => {
    setActivitySelected?.('Demolicao')
  }, [])

  useEffect(() => {
    if (demolitionActivities.length > 0) {
      var activityEdited = demolitionActivities[0]
      activityEdited.activityType = activitySelected
      activityEdited.activityName = textTransform(activitySelected)
      editDemolitionActivity?.(0, activityEdited)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySelected])

  /* useEffect(() => {
      if (masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType !== "") {
          setOptionSelected(masonaryActivities[props.indexSelected].activityType)
      }
      masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType === "" ? (setOptionSelected('Fundacao')) : (<></>)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indexSelected]) */

  return <div>
    <ActionSelect>
      Atividade:
      <select value={activitySelected} name="DemolitionStep" id="DemolitionStep" onChange={(e) => setActivitySelected(e.target.value)}>
        <option value="Demolicao">Demolição</option>
        <option value="Materiais para demolicao">Materiais</option>
      </select>
    </ActionSelect>
  </div>
}

export default DemolitionController