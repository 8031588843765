import styled from "styled-components";

export const BudgetContainer = styled.div`
    padding: 0.5rem;
    width: 50vw;

    .containerButton{
        display: flex;
        justify-content: end;
    }

    .contractButton{
        padding-right: 2.5rem;
        padding-left: 2.5rem;
    }

    .containerBudget{
     display: block;
     height: 25rem;
     //overflow-y: scroll
    }

`


export const FilterContainer = styled.div`
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;

    .order{
        margin-right: 0.5rem;
    }

    .buttonSelectOption{
        margin-right: 0.85rem;
        label{
            border: 1px solid #E5E5E5;
            padding: 0.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-radius: 0.3rem;
            font-size: 0.85rem;
            cursor: pointer;
        }
    }

    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label{
        color: #000000;
        background-color:#7592cc50;
        border: 1px solid #7592CC;
        cursor: pointer;
    }
`

export const ClearFilters = styled.a`
    text-decoration: underline;
    margin-left: 0.75rem;
    cursor: pointer;
`

export const ItensBudgetSelect = styled.div`
    .budgetItemList{
        :nth-child(even) {background: #7592cc50}
        :nth-child(odd) {background: #fff}
        border-radius: 0.3rem;
    }
    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label>div{
        color: #000000;
        border: 2px solid #7592CC;
        cursor: pointer;
    }
`

export const HeaderTableContent = styled.div`
    width: 100%;
    margin: 0.5rem auto;
    margin-top:0;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0fr;
    justify-content:center;

    div{
        display: flex;
        justify-content:center;

    }
`

export const ItemBudget = styled.div`
    width: 100%;
    margin: 0.5rem auto;
    padding: 0.6rem;
    border: 1px solid #F1F2F3;
    margin-top:0.5rem;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0fr;
    justify-content:center;
    cursor: pointer;

   
    div{
        display: flex;
        justify-content:center;
        font-weight:400;
        color:#777777;
    }

    .supplierName{
        font-weight:500;
        color: #141414;
        text-align: center;
    }

    .viewBudgetDetails{
        padding: 0.5rem;
        position: absolute;
        display: contents;
        cursor: alias ;
        img{
            width: 1rem;
        }
    }
`

export const WarningBudgets = styled.div`
    display: flex;
    justify-content: center;
`

export const TextCondition = styled.p`
    font-size: 0.75rem;
    text-align: center;
`

export const Button=styled.button`
    padding: 0.5rem;
    display: flex;
    background-color: #fff;
    border: 1px solid #7592CC;
    color: #7592CC;
    margin-top: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;

    :hover{
        background-color: #7592CC;
        color: #fff;
    }
`
