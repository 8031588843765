export const arrayDaysMonth = (month: number) => {
    const dateObj = new Date()
    var year = dateObj.getFullYear()
    var monthActualy = month - 1
    var numberOfDays = new Date(year, monthActualy + 1, 0).getDate()

    var daysMonth = []
    for (var i = 0; i < numberOfDays; i++) {
        daysMonth[i] = i + 1
    }
    return daysMonth
}

export const arrayDaysMonthActualy = () => {
    const dateObj = new Date()
    var year = dateObj.getFullYear()
    var monthActualy = dateObj.getMonth() + 1
    var numberOfDays = new Date(year, monthActualy, 0).getDate()
    var daysMonth = []
    for (var i = 0; i < numberOfDays; i++) {
        daysMonth[i] = i + 1
    }

    return daysMonth
}

