import React, { useState, useEffect } from "react";
import axios from "axios";

import { UseUserContext } from "../../Context/UserContext";
import { UseModalContext } from "../../Context/ModalContext";
import { simpleSupplier, constructor, worker } from "../../Config/dictionary";

import {
  ContainerSearch,
  Button,
  ContentElement,
  Table,
} from "../../components/stylesDomElements/htmlStyledElements";
import { Container } from "./style";

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import DropDown from "../../components/DropDownElement";
import SendInvite from "./SendInvite";

import searchIcon from "../../assets/searchIcon.svg";
import addIcon from "../../assets/addIcon.svg";

import OverlayOptions from "./OverlayOptions";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import AcessMenu from "../../components/AcessMenu";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";

interface supplierType {
  _id: string;
  supplierName?: string;
  typeSupplier: String;
  status: String;
  avaliation: String;
  worksDeeds: Number;
  typeWork: String;
  commission: String;
  stepsAtuationList: [];
  favoritedSuppliers: [];
  invitedById: String;
  link: string;
}

const MySuppliers: React.FC = () => {
  const { acessLink, editPermission } = UseAuthAcessContext();
  const { userId, setprojectSelected, projectSelected } = UseUserContext();
  const { setShowModal } = UseModalContext();

  const [suppliers, setSuppliers] = useState<supplierType[]>([]);
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);
  const [suppliersFilter, setSupplierFilter] = useState<supplierType[]>([]);

  const [suppliersExibition, setsuppliersExibition] = useState([
    { typeSupplier: "Arquiteto" },
    { typeSupplier: "Fornecedor avulso" },
    { typeSupplier: "Empreiteira" },
    { typeSupplier: "Construtora" },
  ]);

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    } else {
      const projectId = localStorage.getItem("projectId");
      if (projectId && typeof setprojectSelected !== "undefined") {
        setprojectSelected(projectId);
      }
    }
  }, []);

  useEffect(() => {
    if (editPermission === "Editor" || editPermission === "") {
      setIsEditAuthorized(true);
    } else if (editPermission === "Visualizacao") {
      setIsEditAuthorized(false);
    }
  }, [editPermission]);

  /*   useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/listSuppliersInvited/${userId}`)
      .then((res) => {
        setSuppliers(res.data);
      });
  }, [userId]); */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/listSuppliersInvited/${userId}`
        );
        const suppliersData = response.data;

        const suppliersWithLinks = await Promise.all(
          suppliersData.map(async (supplier: any) => {
            try {
              const linkRes = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/listLinksBudgetSupplier/${projectSelected}/${supplier._id}`
              );
              const { budgetLinks } = linkRes.data;

              // Se houver links, armazena o primeiro. Caso contrário, armazena a mensagem 'Não há link disponível'.
              const link =
                budgetLinks.length > 0
                  ? budgetLinks[0]
                  : "Não há link disponível";

              // Retorna um objeto com os dados do fornecedor e o link encontrado
              return { ...supplier, link };
            } catch (error) {
              // Em caso de erro na requisição do link, retorna o fornecedor com a mensagem de erro
              return { ...supplier, link: "Erro ao buscar o link" };
            }
          })
        );

        setSuppliers(suppliersWithLinks);

        if (suppliers) {
          console.log(suppliers);
        }
      } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/listSuppliersInvited/${userId}`)
      .then((res) => {
        setSupplierFilter(res.data);
      });
  }, [suppliersFilter, userId]);

  const changeExpandSuppliers = (typeSupplier: string) => {
    var suppliersExibitionCopy = suppliersExibition;
    var indexStep = suppliersExibitionCopy.findIndex(
      (supplier) => supplier.typeSupplier === typeSupplier
    );

    if (indexStep < 0) {
      suppliersExibitionCopy.push({ typeSupplier: typeSupplier });
      setsuppliersExibition([...suppliersExibitionCopy]);
    } else {
      suppliersExibitionCopy.splice(indexStep, 1);
      setsuppliersExibition([...suppliersExibitionCopy]);
    }
    return;
  };

  const filterSuppliers = (supplierName: string) => {
    console.log(supplierName);
    var supplierListed = suppliersFilter.filter(
      (supplier) =>
        supplier.supplierName?.includes(supplierName) ||
        supplierName === "" ||
        supplierName === " "
    );
    if (supplierListed.length) {
      setSuppliers(supplierListed);
    } else {
      setSuppliers(suppliersFilter);
    }
  };

  return (
    <div>
      <SendInvite />
      <Header />

      {!acessLink ? (
        <ItensMenu optionSelected="Meus fornecedores" />
      ) : (
        <AcessMenu />
      )}

      <Container>
        <ContentElement>
          <ContainerSearch>
            <img src={searchIcon} alt="Icone Lupa" />
            <input
              type="text"
              placeholder="Pesquisar fornecedor"
              onChange={(e) => filterSuppliers(e.target.value)}
            />
            <span onClick={() => filterSuppliers("")}>X</span>
          </ContainerSearch>

          {isEditAuthorized ? (
            <Button onClick={() => setShowModal?.()}>
              <img src={addIcon} alt="Icone de adicionar" /> Convidar
            </Button>
          ) : null}
        </ContentElement>

        <Table>
          <thead>
            <tr className="headerTable">
              <th className="leftContent">Tipo de fornecedor</th>
              <th>Orçamento</th>
              <th>Status</th>
              <th>Avaliação</th>
              <th>Trabalhos feitos</th>
              <th>Tipos de obra</th>
              <th>Minha comissão</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {/** Arquitetos*/}
            <tr>
              <th className="leftContentType">Arquiteto</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="dropDonw">
                {suppliers.filter(
                  (SupplierFilter) =>
                    SupplierFilter.typeSupplier === "Arquiteto"
                ).length > 0 ? (
                  <DropDown
                    scaleY={-1}
                    function={() => changeExpandSuppliers("Arquiteto")}
                  />
                ) : (
                  <DropDown
                    scaleY={1}
                    function={() => changeExpandSuppliers("Arquiteto")}
                  />
                )}
              </th>
            </tr>

            {suppliersExibition.filter(
              (SupplierFilter) => SupplierFilter.typeSupplier === "Arquiteto"
            ).length > 0 ? (
              suppliers
                .filter(
                  (supplierFilter) =>
                    supplierFilter.typeSupplier === "Arquiteto"
                )
                .map((architect, index) => (
                  <tr key={index}>
                    <th className="leftContent">{architect.supplierName}</th>
                    <th>{architect.link}</th>
                    <th>{architect.status}</th>
                    <th>{architect.avaliation}</th>
                    <th>{String(architect.worksDeeds)}</th>
                    <th>{architect.typeWork}</th>
                    <th>R$ {architect.commission}</th>
                    <th className="actionElement">
                      <br />
                      {isEditAuthorized ? (
                        <OverlayOptions supplierId={architect._id} />
                      ) : null}
                    </th>
                  </tr>
                ))
            ) : (
              <></>
            )}
            <tr className="separate">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            {/**Fornecedores avulsos */}
            <tr>
              <th className="leftContentType">Fornecedores avulsos</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="dropDonw">
                {suppliers.filter(
                  (SupplierFilter) =>
                    SupplierFilter.typeSupplier === simpleSupplier
                ).length > 0 ? (
                  <DropDown
                    scaleY={-1}
                    function={() => changeExpandSuppliers(simpleSupplier)}
                  />
                ) : (
                  <DropDown
                    scaleY={1}
                    function={() => changeExpandSuppliers(simpleSupplier)}
                  />
                )}
              </th>
            </tr>

            {suppliersExibition.filter(
              (supplierFilter) => supplierFilter.typeSupplier === simpleSupplier
            ).length > 0 ? (
              suppliers
                .filter(
                  (supplierFilter) =>
                    supplierFilter.typeSupplier === simpleSupplier
                )
                .map((simpleSupplier, index) => (
                  <tr key={index}>
                    <th className="leftContent">
                      {simpleSupplier.supplierName}
                    </th>
                    <th>
                      {simpleSupplier.link !== "Não há link disponível" ? (
                        <a
                          href={simpleSupplier.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Acessar
                        </a>
                      ) : null}
                    </th>
                    <th>{simpleSupplier.status}</th>
                    <th>{simpleSupplier.avaliation}</th>
                    <th>{String(simpleSupplier.worksDeeds)}</th>
                    <th>{simpleSupplier.typeWork}</th>
                    <th>R$ {simpleSupplier.commission}</th>
                    <th className="actionElement">
                      <br />
                      {isEditAuthorized ? (
                        <OverlayOptions supplierId={simpleSupplier._id} />
                      ) : null}
                    </th>
                  </tr>
                ))
            ) : (
              <></>
            )}
            <tr className="separate">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            {/**Construtora */}
            <tr>
              <th className="leftContentType">Construtora</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="dropDonw">
                {suppliers.filter(
                  (supplierFilter) =>
                    supplierFilter.typeSupplier === constructor
                ).length > 0 ? (
                  <DropDown
                    scaleY={-1}
                    function={() => changeExpandSuppliers(constructor)}
                  />
                ) : (
                  <DropDown
                    scaleY={1}
                    function={() => changeExpandSuppliers(constructor)}
                  />
                )}
              </th>
            </tr>
            {suppliersExibition.filter(
              (supplierFilter) => supplierFilter.typeSupplier === constructor
            ).length > 0 ? (
              suppliers
                .filter(
                  (supplierFilter) =>
                    supplierFilter.typeSupplier === constructor
                )
                .map((constructor, index) => (
                  <tr key={index}>
                    <th className="leftContent">{constructor.supplierName}</th>
                    <th>{constructor.link}</th>
                    <th>{constructor.status}</th>
                    <th>{constructor.avaliation}</th>
                    <th>{String(constructor.worksDeeds)}</th>
                    <th>{constructor.typeWork}</th>
                    <th>R$ {constructor.commission}</th>
                    <th className="actionElement">
                      <br />
                      {isEditAuthorized ? (
                        <OverlayOptions supplierId={constructor._id} />
                      ) : null}
                    </th>
                  </tr>
                ))
            ) : (
              <></>
            )}
            <tr className="separate">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            {/**Empreiteira */}
            <tr>
              <th className="leftContentType">Empreiteira</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="dropDonw">
                {suppliers.filter(
                  (supplierFilter) => supplierFilter.typeSupplier === worker
                ).length > 0 ? (
                  <DropDown
                    scaleY={-1}
                    function={() => changeExpandSuppliers(worker)}
                  />
                ) : (
                  <DropDown
                    scaleY={1}
                    function={() => changeExpandSuppliers(worker)}
                  />
                )}
              </th>
            </tr>
            {suppliersExibition.filter(
              (supplierFilter) => supplierFilter.typeSupplier === worker
            ).length > 0 ? (
              suppliers
                .filter(
                  (supplierFilter) => supplierFilter.typeSupplier === worker
                )
                .map((builderCompany, index) => (
                  <tr key={index}>
                    <th className="leftContent">
                      {builderCompany.supplierName}
                    </th>
                    <th>{builderCompany.link}</th>
                    <th>{builderCompany.status}</th>
                    <th>{builderCompany.avaliation}</th>
                    <th>{String(builderCompany.worksDeeds)}</th>
                    <th>{builderCompany.typeWork}</th>
                    <th>R$ {builderCompany.commission}</th>
                    <th className="actionElement">
                      <br />
                      {isEditAuthorized ? (
                        <OverlayOptions supplierId={builderCompany._id} />
                      ) : null}
                    </th>
                  </tr>
                ))
            ) : (
              <></>
            )}
            <tr className="separate">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            {/**Gestor de obras */}
            <tr>
              <th className="leftContentType">Gestor de obras</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="dropDonw">
                {/* {suppliers.filter(SupplierFilter => SupplierFilter.typeSupplier === "Gestor de obras").length > 0 ? (
                                <DropDown scaleY={-1} function={() => changeExpandSuppliers("Gestor de obras")} />
                            ) : (
                                <DropDown scaleY={1} function={() => changeExpandSuppliers("Gestor de obras")} />
                            )} */}
              </th>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default MySuppliers;
