import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ItemContentListForro,
  ListContainerItensForro,
  ListContainerItensSancaCortineiroTabica,
  ItemContentListSancaCortineiroTabica,
} from "./style";

import { SupplierProps } from "../../../types/typesEntitiesProject";
import { multiply, sum } from "../script/calculate"
import { ForroFormType } from "../../../Models/Lining/type";
import FooterBudget from "../Components/FooterBudget"
import { P } from "../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string
  Forro: ForroFormType
}

const ForroBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [sanca, setSanca] = useState<string[]>(["0,00"])
  const [cortineiro, setCortineiro] = useState<string[]>(["0,00"])
  const [tabica, setTabica] = useState<string[]>(["0,00"])
  const [forro, setForro] = useState<string[]>(["0,00"])
  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Forro.Forro.forEach(() => {
      setForro((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.Forro.Sanca?.forEach(() => {
      setSanca((prev) => [...prev, "0,00"])
    })

    activity.Forro.Cortineiro?.forEach(() => {
      setCortineiro((prev) => [...prev, "0,00"])
    })

    activity.Forro.Tabica?.forEach(() => {
      setTabica((prev) => [...prev, "0,00"])
    })

  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const forroTotal = sum(forro)
    const sancaTotal = sum(sanca)
    const cortineiroTotal = sum(cortineiro)
    const tabicaTotal = sum(tabica)
    setTotalBudgetPayment(sum([forroTotal, sancaTotal, cortineiroTotal, tabicaTotal]))
  }, [forro, sanca, cortineiro, tabica])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const forroTotal = sum(forro)
  const sancaTotal = sum(sanca)
  const cortineiroTotal = sum(cortineiro)
  const tabicaTotal = sum(tabica)
  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([forroTotal, sancaTotal, cortineiroTotal, tabicaTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const tabicaMargin: number[] = []
    const sancaMargin: number[] = []
    const cortineiroMargin: number[] = []
    const forroMargin: number[] = []

    tabica.forEach((item) => {
      if (item != "0,00") {
        tabicaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    forro.forEach((item) => {
      if (item != "0,00") {
        forroMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    sanca.forEach((item) => {
      if (item != "0,00") {
        sancaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    cortineiro.forEach((item) => {
      if (item != "0,00") {
        cortineiroMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Forro: forroMargin,
          Sanca: sancaMargin,
          Cortineiro: cortineiroMargin,
          Tabica: tabicaMargin
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensForro>

        {activity.Forro.Forro?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Forro.length > 1 ? (<P>Forro {index + 1}</P>) : (<P>Forro</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListForro >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, forro, setForro, e.target.value)} />
              </ContentInputPrice>
              {forro && forro[index] !== '0' ?
                (<p className="item">{forro[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListForro>
          </div>
        ))}

      </ListContainerItensForro>

      <ListContainerItensSancaCortineiroTabica>
        {activity.Forro.Sanca?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Sanca.length > 1 ? (<P>Sanca {index + 1}</P>) : (<P>Sanca</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListSancaCortineiroTabica >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, sanca, setSanca, e.target.value)} />
              </ContentInputPrice>
              {sanca && sanca[index] !== '0' ?
                (<p className="item">{sanca[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListSancaCortineiroTabica>
          </div>
        ))}
      </ListContainerItensSancaCortineiroTabica>

      <ListContainerItensSancaCortineiroTabica>
        {activity.Forro.Cortineiro?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Cortineiro.length > 1 ? (<P>Cortineiro {index + 1}</P>) : (<P>Cortineiro</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListSancaCortineiroTabica >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, cortineiro, setCortineiro, e.target.value)} />
              </ContentInputPrice>
              {cortineiro && cortineiro[index] !== '0' ?
                (<p className="item">{cortineiro[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListSancaCortineiroTabica>
          </div>
        ))}
      </ListContainerItensSancaCortineiroTabica>


      <ListContainerItensSancaCortineiroTabica>
        {activity.Forro.Tabica?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Tabica.length > 1 ? (<P>Tabica {index + 1}</P>) : (<P>Tabica</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListSancaCortineiroTabica >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, tabica, setTabica, e.target.value)} />
              </ContentInputPrice>
              {tabica && tabica[index] !== '0' ?
                (<p className="item">{tabica[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListSancaCortineiroTabica>
          </div>
        ))}
      </ListContainerItensSancaCortineiroTabica>


      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default ForroBudget;