import React, { useEffect } from 'react'

import { UsePaintActivityContext } from "../../../Context/ActivitiesContext/Paint/PaintContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import PaintStepForm from '../../StepsForm/Painting/Paint'
import WallpaperStepForm from '../../StepsForm/Painting/Wallpaper'

import { Button } from "../../stylesDomElements/htmlStyledElements"
import { textButton } from '../script/textButtonSubmit'
import { RequestApi } from "../RequestApi"

import { Container } from "../style"
import MaterialsPaintingStepForm from '../../StepsForm/Painting/Materials'

const PaintModifyActivities: React.FC<activityProps> = (props) => {
    const { paintActivities, setActivity, setIndexPaintActivitySelected } = UsePaintActivityContext()
    const { setModalNameHeader } = UseModalContext()
    console.log(props.activity)
    useEffect(() => {

        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexPaintActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Pintura" ? (<PaintStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Papel de parede" ? (<WallpaperStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsPaintingStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(paintActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(paintActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default PaintModifyActivities