import React, { useEffect, useState } from "react";

import { UseModalContext } from "../../Context/ModalContext";

import {
  ContainerModal,
  FormContainer,
  HeaderFormContainer,
  FooterFormContainer,
} from "../stylesDomElements/htmlStyledElements";

import { CentralContent, Button } from "./style";

import NewSupplier from "../NewSupplier";
import NewArchitect from "../NewArchitect";

import iconCloseModal from "../../assets/XIcon.svg";

interface Props {
  setFeedbackUserRegister: React.Dispatch<React.SetStateAction<boolean>>
}


const ModalTypeRegister: React.FC<Props> = ({ setFeedbackUserRegister }) => {
  const { showModal, setShowModal } = UseModalContext();

  const [registerType, setRegisterType] = useState("");

  useEffect(() => {
    if (!showModal) {
      setRegisterType("");
    }
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <ContainerModal>
          <>
            {registerType === "" ? (
              <FormContainer>
                <HeaderFormContainer>
                  <p>Cadastro</p>
                  <img
                    src={iconCloseModal}
                    alt="icone de fechar"
                    onClick={() => setShowModal?.()}
                  />
                </HeaderFormContainer>

                <CentralContent>
                  <p>Eu sou...</p>

                  <select
                    className="selectElement"
                    onChange={(e) => setRegisterType(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="Arquiteto">Arquiteto</option>
                    <option value="Fornecedor avulso">Fornecedor</option>
                    <option value="Construtora">Construtora</option>
                    <option value="Empreiteiro">Empreiteiro</option>
                  </select>
                </CentralContent>

                <FooterFormContainer>
                  <div></div>
                  <Button className="buttonRegister">Avançar</Button>
                </FooterFormContainer>
              </FormContainer>
            ) : (
              <></>
            )}
            {registerType === "Arquiteto" && <NewArchitect setFeedbackUserRegister={setFeedbackUserRegister} />}{" "}
            {registerType === "Fornecedor avulso" && <NewSupplier typeSupplier="Fornecedor avulso" setFeedbackUserRegister={setFeedbackUserRegister} />}
            {registerType === "Construtora" && <NewSupplier typeSupplier="Construtora" setFeedbackUserRegister={setFeedbackUserRegister} />}
            {registerType === "Empreiteiro" && <NewSupplier typeSupplier="Empreiteiro" setFeedbackUserRegister={setFeedbackUserRegister} />}
          </>
        </ContainerModal>
      ) : null}
    </>
  );
};

export default ModalTypeRegister;
