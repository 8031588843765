import styled from "styled-components";
import { devices } from "../../style/Responsive";

interface budgetTypeProps {
  budgetType: string;
}

export const Container = styled.div<budgetTypeProps>`
  height: 100vh;
  .paginationContent {
    margin-top: 20px;
    display: grid;
    justify-content: center;

    p {
      text-align: center;
    }
  }

  .budgetTypeSelection {
    float: right;
    margin-right: 2rem;

    label {
      margin-right: 0.5rem;
    }

    button {
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      cursor: pointer;
    }

    .itemButton {
      border-radius: 4px 0px 0px 4px;
      margin-right: 0.5px;

      background-color: ${(props) =>
        props.budgetType === "item"
          ? "rgba(117, 146, 204, 0.2)"
          : "var(--primary-color)"};

      color: ${(props) =>
        props.budgetType === "item" ? "#141414" : "#777777"};

      border: ${(props) =>
        props.budgetType === "item"
          ? "1px solid var(--secondary-color);"
          : "var(--primary-color)"};
    }

    .setButton {
      border-radius: 0px 4px 4px 0px;
      margin-left: 0.5px;

      background-color: ${(props) =>
        props.budgetType === "set"
          ? "rgba(117, 146, 204, 0.2)"
          : "var(--primary-color)"};

      color: ${(props) => (props.budgetType === "set" ? "#141414" : "#777777")};

      border: ${(props) =>
        props.budgetType === "set"
          ? "1px solid var(--secondary-color);"
          : "var(--primary-color)"};
    }
  }
`;

export const DowloadContent = styled.a`
  text-decoration: underline;
  color: #777777;
  margin-left: 1rem;
`;

export const TopBarBudget = styled.div`
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  padding: 1rem;

  @media${devices.cell} {
    font-size: 0.75rem;
  }
  @media${devices.cells} {
    display: grid;
    justify-content: center;
  }
  @media${devices.cells2} {
    display: grid;
    justify-content: center;
  }
  .left {
    font-size: 0.85rem;
    color: var(--text-light-color);
    font-weight: 400;

    @media${devices.cells} {
      justify-content: center;
    }

    @media${devices.cells2} {
      justify-content: center;
    }

    p {
      margin-right: 0.75rem;
      font-size: 1.05rem;
      color: #000;
      font-weight: 500;

      @media${devices.cell} {
        font-size: 0.75rem;
      }

      @media${devices.cells} {
        font-size: 0.75rem;
      }
    }
  }

  @media${devices.cell} {
    font-size: 0.65rem;
  }

  @media${devices.cells} {
    font-size: 0.65rem;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    margin: 0.75rem;
    img {
      width: 1rem;
    }
  }
`;

export const ContentButtonTopBarBudget = styled.a`
  font-weight: 400;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  margin-left: 1rem;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;

  cursor: pointer;

  img {
    margin: 0 0.25rem 0 0.25rem;
  }

  a {
    color: #000;
  }
`;

export const ContentElementBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  align-items: center;
  background-color: var(--primary-color);
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
  P {
    font-weight: 400;
    color: #777777;
    color: var(--text-light-color);
    font-size: 0.95rem;

    @media${devices.cell} {
      font-size: 0.75rem;
    }

    @media${devices.cells} {
      font-size: 0.7rem;
    }
  }
  .item {
    display: flex;
    justify-content: end;
  }
`;

export const PriceItem = styled.input`
  width: 100%;
  border: none;
  padding: 0.25rem;
  background-color: transparent;
  margin-left: 0.5rem;
`;

export const ContentInputPrice = styled.div`
  width: 10rem;
  border: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3rem;
  padding: 0.25rem;
  background-color: #f1f1f2;
  margin-right: 0.5rem;
  font-weight: 400;
  color: #777777;
  font-size: 0.85rem;

  @media${devices.tablet} {
    width: auto;
    margin-right: 0;
  }

  @media${devices.cells} {
    font-size: 0.75rem;
  }

  @media${devices.cells2} {
    font-size: 0.65rem;
    justify-content: start;
    margin-left: 0;
    width: 4.5rem;
  }
`;

export const ContentInputObservations = styled.div`
  width: 100%;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  padding: 0.25rem;
  background-color: #f1f1f2;
  margin-right: 0.5rem;
  font-weight: 400;
  color: #777777;
  font-size: 0.85rem;

  @media${devices.tablet} {
    width: auto;
    margin-right: 0;
  }

  @media${devices.cells} {
    font-size: 0.75rem;
  }

  @media${devices.cells2} {
    font-size: 0.65rem;
    justify-content: start;
    margin-left: 0;
    width: 4.5rem;
  }
`;

export const ListContainerItens = styled.div`
  margin: 2rem;
  .upContent {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    padding: 0.5rem;
    grid-gap: 0.5rem;
    .item {
      display: flex;
      justify-content: end;
      margin-right: 0.5rem;
      white-space: nowrap;
    }

    @media${devices.cell} {
      font-size: 0.8rem;
    }

    @media${devices.cells} {
      font-size: 0.7rem;
    }

    @media${devices.cells2} {
      font-size: 0.7rem;
    }
  }

  .metricColum {
    padding-right: 1.2rem;
  }

  .priceColum {
    width: 12rem;
  }
`;

export const ItemContentList = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--primary-color);
  border-radius: 0.3rem;
  font-weight: 400;
  color: #777777;
  grid-gap: 0.5rem;

  P {
    @media${devices.cell} {
      font-size: 0.75rem;
    }

    @media${devices.cells} {
      font-size: 0.7rem;
    }
  }

  .item {
    display: flex;
    justify-content: end;
    margin-right: 0.5rem;
    white-space: nowrap;
  }

  .flexContent {
    display: flex;
  }

  div > ul {
    padding: 1rem;
  }
`;

export const ObservationsArea = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 2rem;
`;

export const ContanierFinalization = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  margin: 2rem;
  border-radius: 0.3rem;

  @media${devices.tablet} {
    display: block;
  }
`;

export const ConditionsPayment = styled.div`
  background-color: var(--primary-color);
  border-radius: 0.3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  p {
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  @media${devices.cell} {
    font-size: 0.75rem;
  }

  @media${devices.cells} {
    font-size: 0.65rem;
  }

  div {
    width: 100%;
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin-top: 0.2rem;
  }

  input {
    width: 100%;
    border: none;
    padding: 0.1rem;
    background-color: #f1f1f2;
  }
`;

export const Observations = styled.div`
  background-color: var(--primary-color);
  border-radius: 0.3rem;
  margin: 0 1rem 0 2rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  p {
    margin-right: 1rem;
  }

  @media${devices.cell} {
    font-size: 0.75rem;
  }

  @media${devices.cells} {
    font-size: 0.65rem;
  }

  div {
    width: 100%;
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin-top: 0.2rem;
  }

  input {
    width: 100%;
    border: none;
    padding: 0.1rem;
    background-color: #f1f1f2;
  }
`;

export const ItemContainerFinzalization = styled.div`
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.3rem;

  p {
    margin-right: 1rem;
  }

  P {
    @media${devices.cell} {
      font-size: 0.75rem;
    }

    @media${devices.cells} {
      font-size: 0.65rem;
    }
  }

  .workingDays {
    display: flex;
    padding: 0.25rem;
    align-items: center;
    justify-content: space-between;
    color: #777777;
    font-size: 0.85rem;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
    background-color: #f1f1f2;
    font-weight: 400;
    white-space: nowrap;

    @media${devices.laptop} {
      width: auto;
    }

    @media${devices.cells2} {
      font-size: 0.65rem;
    }

    input[type="number"] {
      width: 6rem;
      background-color: transparent;
      border: none;
      padding: 0.25rem;
      text-align: right;

      @media${devices.laptop} {
        width: 4rem;
      }

      @media${devices.tableS} {
        margin-right: 2rem;
      }

      @media${devices.cells2} {
        width: 2.8rem;
        font-size: 0.65rem;
      }
    }
  }

  .selectInputContainer {
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
    padding: 0.5rem;

    input {
      width: 10rem;
      border: none;
      background-color: transparent;
      padding: 0.1rem;

      @media${devices.tablet} {
        width: 100%;
      }

      @media${devices.laptop} {
        width: auto;
      }

      @media${devices.cells2} {
        width: 5rem;
        font-size: 0.7rem;
      }
    }
  }

  @media${devices.laptop} {
    justify-content: center;
    align-items: center;
    display: grid;
    text-align: center;
  }

  @media${devices.tablet} {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  @media${devices.tableS} {
    text-align: inherit;
  }
`;

export const TotalContainer = styled.div`
  margin: 2.5rem;
  display: flex;
  align-self: center;
  justify-content: flex-end;

  font-weight: 400;
  color: var(--text-light-color);
  font-size: 0.95rem;

  p {
    color: #000;
    font-weight: 500;
    margin-left: 0.5rem;
    font-size: 1rem;

    @media${devices.cells} {
      font-size: 0.7rem;
    }
  }

  @media${devices.cells} {
    font-size: 0.7rem;
  }
`;

export const ContainerButtonSendBudget = styled.div`
  margin: 0 2.2rem 0 2.2rem;
  display: flex;
  align-self: center;
  justify-content: flex-end;

  button {
    padding: 0.75rem 1.2rem 0.75rem 1.2rem;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    cursor: pointer;

    :hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
    }

    @media${devices.cells} {
      font-size: 0.75rem;
    }
  }
`;

export const FinalizationContainer = styled.div`
  background-color: var(--primary-color);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-left: 1.7rem;
  margin-right: 1.7rem;

  .paymentConditionContent {
    display: flex;
    align-items: center;
  }
`;

export const ItemContent = styled.div`
  width: 7rem;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
  align-items: center;
  color: #777777;
  font-size: 0.85rem;
  border: 1px solid #e1e1e1;
  border-radius: 0.3rem;
  background-color: #f1f1f2;
  font-weight: 400;
  margin-left: 0.5rem;

  input {
    margin-left: 0.7rem;
    width: 70%;
    border: none;
    padding: 0.25rem;
    background-color: transparent;
    text-align: right;
  }
`;
export const Iframe = styled.iframe`
  width: 100%;
  height: 83vh;
`;

export const ContainerInput = styled.div`
  width: 60rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.3rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  input {
    border: none;
    width: 100%;
    background-color: #e1e1e1;
    padding: 0.5rem;
    border-radius: 0.3rem;
  }
`;

export const ModalContent = styled.div`
  padding: 1.5rem;
  margin-bottom: 2rem;

  h1 {
    text-align: center;
  }

  button {
    float: right;
    margin-right: 0.5rem;
  }
`;

export const FormContainerOf3dPictures = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;
  width: 90%;
  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const FormContainerOfProjectArchives = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(66 66 66 / 35%);
  z-index: 3;
  transition: 0.5s;

  div {
    max-height: 30rem;
    max-width: 120rem;
  }
`;

export const FormContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;

  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;
export const HeaderFormContainer = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 6fr 0fr 0fr 0fr;
  grid-column-gap: 1rem;

  align-items: center;
  box-shadow: 0px 1px 0px #f1f2f3;
  div {
    background-color: #f1f2f3;
    padding: 0.4rem;
    border-radius: 0.3rem;
  }
  img {
    cursor: pointer;
    margin: 0.1rem;
  }
`;
