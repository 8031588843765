import React, { useState } from "react";
import { ForroFormType } from "../../../Models/Lining/type";

import { stateType } from "../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  Forro: ForroFormType;
}

const LiningDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>
        {activity.Forro.Forro?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Forro.length > 1 ? (<u>Forro {index + 1}</u>) : (<u>Forro </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Forro.Sanca?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Sanca.length > 1 ? (<u>Sanca {index + 1}</u>) : (<u>Sanca </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Forro.Cortineiro?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Cortineiro.length > 1 ? (<u>Cortineiro {index + 1}</u>) : (<u>Cortineiro </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Forro.Tabica?.map((item, index) => (
          <div key={index}>
            {activity.Forro.Tabica.length > 1 ? (<u>Tabica {index + 1}</u>) : (<u>Tabica </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
      </div>
    </div>
  );
};

export default LiningDescription;