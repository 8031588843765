import React, { useState, useEffect } from "react";

import { UsePaintActivityContext } from "../../../../Context/ActivitiesContext/Paint/PaintContext";
import { attributeType } from "../../../../Models/attributeType";
import { WallpaperFormType } from "../../../../Models/Paint/type";
import { Wallpaper } from "../../../../Models/Paint/WallpaperModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const WallpaperStepForm: React.FC<stepFormProps> = (props) => {
    const { paintActivities, indexPaintActivitySelected, editPaintActivity } = UsePaintActivityContext();

    const [assignment, setAssignment] = useState<string>('')
    const [itensFormList, setItensFormList] = useState<WallpaperFormType>(Wallpaper)

    useEffect(() => {
        if (paintActivities.length > 0) {
            let paintActivityCopy = paintActivities[indexPaintActivitySelected]
            paintActivityCopy.activityType = 'Papel de parede'
            editPaintActivity?.(indexPaintActivitySelected, paintActivityCopy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexPaintActivitySelected])

    useEffect(() => {
        if (paintActivities.length > 0) {
            setItensFormList(paintActivities[indexPaintActivitySelected].Wallpaper)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexPaintActivitySelected, paintActivities]);

    useEffect(() => {
        if (props.stepFormExibition) {
            removeItemPapelDeParede(0)
        }
    }, [])

    const addAssignment = () => {
        if (assignment === "Papel de parede") {
            addPapelDeParede({
                attributes: [
                    {
                        itemName: 'Descrição',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Medida',
                        unit_of_measurement: 'm²',
                        value: ''
                    },
                    {
                        itemName: 'Tipo',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Quantidade',
                        unit_of_measurement: '',
                        value: ''
                    },
                ]
            })
        }
    }

    const addPapelDeParede = (object: attributeType) => {
        var itensFormListCopy = itensFormList
        var paintActivitiesCopy = paintActivities
        itensFormListCopy.PapelDeParede.push(object)
        paintActivitiesCopy[0].Wallpaper = itensFormListCopy
        setItensFormList(itensFormListCopy)
        editPaintActivity?.(0, paintActivitiesCopy[0])
        console.log('oi')
    }



    const removeItemPapelDeParede = (index: number) => {
        var itensFormListCopy = itensFormList
        var paintActivitiesCopy = paintActivities
        itensFormListCopy.PapelDeParede.splice(index, 1)
        paintActivitiesCopy[0].Wallpaper = itensFormListCopy
        setItensFormList(itensFormListCopy)
        editPaintActivity?.(0, paintActivitiesCopy[0])
    }



    const editPapelDeParedeValues = (value: string, indexItem: number, indexItemForm: number) => {
        var itensFormListCopy = itensFormList
        var paintActivitiesCopy = paintActivities
        itensFormListCopy.PapelDeParede[indexItemForm].attributes[indexItem].value = value
        setItensFormList(itensFormListCopy)
        editPaintActivity?.(0, paintActivitiesCopy[0])
    }

    const editPapelDeParedeAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
        var itensFormListCopy = itensFormList
        var paintActivitiesCopy = paintActivities
        itensFormListCopy.PapelDeParede[indexItemForm].attributes[indexItem].itemName = value
        setItensFormList(itensFormListCopy)
        editPaintActivity?.(0, paintActivitiesCopy[0])
    }

    const editPapelDeParedeUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
        const itensFormListCopy = itensFormList
        const paintActivitiesCopy = paintActivities
        itensFormListCopy.PapelDeParede[indexItemForm].attributes[indexItem].unit_of_measurement = value
        setItensFormList(itensFormListCopy)
        editPaintActivity?.(0, paintActivitiesCopy[0])
    }

    return (
        <div>
            <div>
                <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
            </div>
            <br />
            {itensFormList.PapelDeParede.length ? (
                <div>
                    <p>Papel de parede</p>
                    {itensFormList.PapelDeParede.map((itemForm, indexItemForm) => (
                        <ContainerGrid key={indexItemForm}>
                            {itemForm.attributes.map((attributeItem, indexAttribute) => (
                                attributeItem.itemName === "Tipos" ? (
                                    <div >
                                        <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPapelDeParedeAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                                        <Select value={attributeItem.value} onChange={(e) => editPapelDeParedeValues(e.target.value, indexAttribute, indexItemForm)}>
                                            <option value="Gesso">Gesso</option>
                                            <option value="Pvc">Pvc</option>
                                            <option value="Madeira">Madeira</option>
                                            <option value="Isopor">Isopor</option>
                                            <option value="Cimento">Cimento</option>
                                            <option value="Aparente">Aparente</option>
                                            <option value="Fibra">Fibra</option>
                                            <option value="Drywall reto">Drywall reto</option>
                                            <option value="Drywall tabicado">Drywall tabicado</option>
                                            <option value="Jateamento de celulose">Jateamento de celulose</option>
                                            <option value="Outros">Outros</option>
                                        </Select>
                                        <div>{attributeItem.value === "Outros" ? (
                                            <div>
                                                <InputActivity>
                                                    <input type="text" id="tipooutros" placeholder="Digite o tipo"
                                                        value={attributeItem.value}
                                                        onChange={(e) => editPapelDeParedeValues(e.target.value, indexAttribute, indexItemForm)}
                                                    />
                                                </InputActivity>
                                            </div>
                                        ) : (
                                            <></>
                                        )}</div>

                                    </div>
                                ) : (
                                    <div className="resizeContentInputs">
                                        <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPapelDeParedeAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                                        <InputActivity>
                                            <input type="text" value={attributeItem.value} onChange={(e) => editPapelDeParedeValues(e.target.value, indexAttribute, indexItemForm)} />
                                            {attributeItem.unit_of_measurement ? (
                                                <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editPapelDeParedeUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                                            ) : (<></>)}
                                        </InputActivity>
                                    </div>
                                )
                            ))}
                            <div className="cancelX" onClick={() => removeItemPapelDeParede(indexItemForm)}>X</div>
                        </ContainerGrid>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default WallpaperStepForm;

