import React, { useState } from "react";
import { InstallationsFormType } from "../../../../Models/Eletric/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  Installations: InstallationsFormType;
}

const InstallationsDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>


        {activity.Installations.Lampadas ? (
          activity.Installations.Lampadas?.map((item, index) => (
            <div key={index}>
              {activity.Installations.Lampadas.length > 1 ? (<u>Lâmpadas {index + 1}</u>) : (<u>Lâmpadas </u>)}
              <br /><br />
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>
          ))
        ) : null}

        {activity.Installations.Interruptores ? (
          activity.Installations.Interruptores?.map((item, index) => (
            <div key={index}>
              {activity.Installations.Interruptores.length > 1 ? (<u>Interruptores {index + 1}</u>) : (<u>Interruptores </u>)}
              <br /><br />
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>
          ))
        ) : null}

        {activity.Installations.Tomadas ? (
          activity.Installations.Tomadas?.map((item, index) => (
            <div key={index}>
              {activity.Installations.Tomadas.length > 1 ? (<u>Tomadas {index + 1}</u>) : (<u>Tomadas </u>)}
              <br /><br />
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>

          ))
        ) : null}

        {activity.Installations.FitasDeLed ? (
          activity.Installations.FitasDeLed?.map((item, index) => (
            <div key={index}>
              {activity.Installations.FitasDeLed.length > 1 ? (<u>Fitas de led {index + 1}</u>) : (<u>Fitas de led </u>)}
              <br /><br />
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>

          ))
        ) : null}

        {activity.Installations.Luminarias ? (
          activity.Installations.Luminarias?.map((item, index) => (
            <div key={index}>
              {activity.Installations.Luminarias.length > 1 ? (<u>Luminarias {index + 1}</u>) : (<u>Luminarias </u>)}
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>

          ))
        ) : null}

        {activity.Installations.Spot ? (
          activity.Installations.Spot?.map((item, index) => (
            <div key={index}>
              {activity.Installations.Spot.length > 1 ? (<u>Spot{index + 1}</u>) : (<u>Spot</u>)}
              <br /><br />
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>

          ))
        ) : null}

        {activity.Installations.Arandela ? (
          activity.Installations.Arandela?.map((item, index) => (
            <div key={index}>
              {activity.Installations.Arandela.length > 1 ? (<u>Arandela {index + 1}</u>) : (<u>Arandela </u>)}
              <br /><br />
              {item.attributes?.map((tarefa, index) => (

                <div className="line" key={index}>
                  <p>
                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                  </p>
                  <p>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                  </p>
                </div>
              ))}
              <hr />
            </div>

          ))
        ) : null}

        {activity.Installations.Trilhos?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Trilhos.length > 1 ? (<u>Trilhos {index + 1}</u>) : (<u>Trilhos </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}


        {activity.Installations.Pendentes?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Pendentes.length > 1 ? (<u>Pendentes {index + 1}</u>) : (<u>Pendentes </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.Interfone?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Interfone.length > 1 ? (<u>Interfone {index + 1}</u>) : (<u>Interfone </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.CaboDeInternet?.map((item, index) => (
          <div key={index}>
            {activity.Installations.CaboDeInternet.length > 1 ? (<u>Cabo de internet {index + 1}</u>) : (<u>Cabo de internet </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.Interfone?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Interfone.length > 1 ? (<u>Interfone {index + 1}</u>) : (<u>Interfone </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.CameraDeSeguranca?.map((item, index) => (
          <div key={index}>
            {activity.Installations.CameraDeSeguranca.length > 1 ? (<u>Câmera de segurança {index + 1}</u>) : (<u>Câmera de segurança </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.Sensores?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Sensores.length > 1 ? (<u>Sensores {index + 1}</u>) : (<u>Sensores </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.Sensores?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Sensores.length > 1 ? (<u>Sensores {index + 1}</u>) : (<u>Sensores </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.Plafon?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Plafon.length > 1 ? (<u>Plafon {index + 1}</u>) : (<u>Plafon </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.PontoDeDados?.map((item, index) => (
          <div key={index}>
            {activity.Installations.PontoDeDados.length > 1 ? (<u>Ponto de dados {index + 1}</u>) : (<u>Ponto de dados </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.PontoDeTv?.map((item, index) => (
          <div key={index}>
            {activity.Installations.PontoDeTv.length > 1 ? (<u>Ponto de TV {index + 1}</u>) : (<u>Ponto de TV </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.Exaustor?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Exaustor.length > 1 ? (<u>Exaustor {index + 1}</u>) : (<u>Exaustor </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}

        {activity.Installations.PontoDeForca?.map((item, index) => (
          <div key={index}>
            {activity.Installations.PontoDeForca.length > 1 ? (<u>Ponto de força {index + 1}</u>) : (<u>Ponto de força </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>

        ))}
      </div>
    </div>
  );
};

export default InstallationsDescription;