import React, { useEffect, useState } from "react";
import axios from "axios";

import { UseUserContext } from "../../Context/UserContext";

import {
  TopBar,
  MenuActionsPage,
  Tr,
  TableContainer,
} from "./style";

import { ContainerSearch, ActionSelect, Table, TooltipBox, TooltipCard, TooltipText } from "../../components/stylesDomElements/htmlStyledElements"
import { BudgetsProps, activitiesProps, SupplierProps, allActivitiesProps } from "../../types/typesEntitiesProject"

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import DropDown from '../../components/DropDownElement';
import Comparatives from "../CotationMap/Comparatives"

import searchIcon from "../../assets/searchIcon.svg";
import seachBlackIcon from "../../assets/lupaBlackIcon.svg"

import { primaryColor } from "../../Config/colorConfig";
import informationIcon from "../../assets/informationIcon.svg"
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import AcessMenu from "../../components/AcessMenu";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";

interface stepProps {
  _id: string;
  clientId: string;
  name: string;
  percentage: number;
  quantity: number;
  measure: string;
  budgetName: string;
  valueUnit: string;
  startDate: string;
  endDate: string;
}

interface stepsShowElementsProps {
  stepId: string
}

const ListManagement: React.FC = () => {
  const { acessLink } = UseAuthAcessContext()
  const { projectSelected, setprojectSelected } = UseUserContext();

  const [activities, setActivities] = useState<activitiesProps[]>([])
  const [budgets, setBudgets] = useState<BudgetsProps[]>([]);
  const [step, setSteps] = useState<stepProps[]>([]);

  const [activitySelected, setActivitySelected] = useState<allActivitiesProps>()
  const [budgetSelected, setBudgetSelected] = useState<BudgetsProps>()
  const [suppliers, setSuppliers] = useState<SupplierProps[]>([])

  const [scaleY, setScaleY] = useState<number>(1);

  const [stepsShowElements, setStepsShowElements] = useState<stepsShowElementsProps[]>([])

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    } else {
      const projectId = localStorage.getItem("projectId");
      if (projectId && typeof setprojectSelected !== 'undefined') {
        setprojectSelected(projectId);
      }
    }
  }, []);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/listBudgetsProject/${projectSelected}`)
        .then((res) => {
          setBudgets(res.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgets]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/steps/${projectSelected}`)
        .then((res) => {
          setSteps(res.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (projectSelected) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/activitiesProject/${projectSelected}`).then(res => {
        setActivities(res.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/suppliers`).then(res => {
      setSuppliers(res.data)
    })
  }, [])

  const totalStepCalc = (stepId: string) => {
    // eslint-disable-next-line eqeqeq
    let totalSelected = 0
    let totalNoSelected = 0

    budgets.filter(budgetFilter => budgetFilter.stepId === stepId && budgetFilter.selectedBudget).forEach((budget) => {
      totalSelected += parseFloat(budget.totalBudgetPayment.replace(',', '.'))
    })
    activities.filter(activityFilter => activityFilter.stepId === stepId).forEach((activity) => {
      if (!activity.selectedBudget && budgets.filter(budgetFilter => budgetFilter.activityId === activity._id).length > 0) {
        budgets.filter(budgetFilter => budgetFilter.activityId === activity._id).forEach((budget, index) => {
          totalNoSelected += parseFloat(budget.totalBudgetPayment.replace(',', '.'))
        })
        totalNoSelected = totalNoSelected / budgets.filter(budgetFilter => budgetFilter.activityId === activity._id).length
      }
    })
    return (totalSelected + totalNoSelected).toFixed(2).replace('.', ',')
  };

  const totalCalcActivity = (activityId?: string) => {
    let total = 0
    // eslint-disable-next-line eqeqeq
    budgets.filter(budgetFilter => budgetFilter.activityId === activityId).forEach((budget, index) => {
      total += parseFloat(budget.totalBudgetPayment.replace(',', '.'))
    })

    return (total / budgets.filter(budgetFilter => budgetFilter.activityId === activityId).length).toFixed(2)
  };

  const changeExpandStepBudgets = (stepId: string) => {
    const stepsShowElementsCopy = stepsShowElements
    const indexStep = stepsShowElementsCopy.findIndex(step => step.stepId === stepId)

    if (indexStep < 0) {
      stepsShowElementsCopy.push({ stepId: stepId })
      setStepsShowElements([...stepsShowElementsCopy])
    } else {
      stepsShowElementsCopy.splice(indexStep, 1)
      setStepsShowElements([...stepsShowElementsCopy])
    }
    modifyIconDropDown()
  }

  const modifyIconDropDown = () => {
    if (scaleY === -1) {
      setScaleY(1);
    } else
      setScaleY(-1)
  }

  const oppenBudgetVisualization = (activity: any, budget: BudgetsProps) => {
    setActivitySelected(activity)
    setBudgetSelected(budget)
  }

  return (
    <div>
      <Header />

      {!acessLink ? (
        <ItensMenu optionSelected="Lista" />
      ) : (
        <AcessMenu />
      )}

      <TopBar>
        <MenuActionsPage>
          <div className="contentLefth">
            <ActionSelect>
              Visualizar por:
              <select>
                <option value="steps">Etapas</option>
              </select>
            </ActionSelect>
            <ActionSelect>
              Ordenar por:
              <select>
                <option value="cronogram">Cronograma</option>
              </select>
            </ActionSelect>

            <ContainerSearch>
              <img src={searchIcon} alt="Icone Lupa" />
              <input type="text" placeholder="Pesquisar" />
              <span>X</span>
            </ContainerSearch>
          </div>
        </MenuActionsPage >
      </TopBar>

      {activitySelected === undefined ? (
        <TableContainer>
          <Table>
            <thead>
              <tr className="headerTable">
                <th>Etapa/Atividade</th>
                <th>Fornecedor</th>
                <th>Prazo</th>
                <th>Valor total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {step.map((step, stepIndex) => (
                <>
                  <Tr trBorderColor={primaryColor[stepIndex]} key={stepIndex}>
                    <th>{step.name}</th>
                    <th>{step.budgetName}</th>
                    <th>{step.endDate}</th>
                    <th>{totalStepCalc(step._id)}</th>
                    <th className="dropDonw">
                      <DropDown scaleY={1} function={() => changeExpandStepBudgets(step._id)} />
                    </th>
                  </Tr>

                  {stepsShowElements.filter(stepShow => stepShow.stepId === step._id).length > 0 ? (
                    <>
                      {/**Atividade com orçamento selecionado */}
                      {activities.filter(activityFilter => activityFilter.stepId === step._id).map((activity) =>
                        budgets.filter(budgetFilter => budgetFilter.activityId === activity._id).map((budget, budgetIndex) => (
                          activity.selectedBudget && budget.selectedBudget ? (
                            <Tr trBorderColor={primaryColor[stepIndex]} key={budgetIndex}>
                              <th>{budget.activityName}</th>
                              <th>{budget.supplierName}</th>
                              <th>{activity.endDate}</th>
                              <th>{budget.totalBudgetPayment}</th>
                              <th className="actionElement" onClick={() => oppenBudgetVisualization(activity, budget)}>
                                <img src={seachBlackIcon} alt="icone de lupa" />
                              </th>
                            </Tr>
                          ) : (<></>)
                        ))
                      )}

                      {/**Atividade com orçamento não selecionado */}
                      {activities.filter(activityFilter => activityFilter.stepId === step._id && !activityFilter.selectedBudget).map((activity, indexBudgetActivity) =>
                        budgets.findIndex(budget => budget.activityId === activity._id) >= 0 ? (
                          <Tr trBorderColor={primaryColor[stepIndex]} key={indexBudgetActivity}>
                            <th>{activity.activityName}</th>
                            <th>A selecionar</th>
                            <th>{activity.endDate}</th>
                            <th>
                              <TooltipCard >
                                <TooltipText>
                                  <img src={informationIcon} alt="icone de informação" />
                                  <p>{totalCalcActivity(activity._id)}</p>
                                </TooltipText>
                                <TooltipBox>
                                  Estimativa baseada nos orçamentos recebidos
                                </TooltipBox>
                              </TooltipCard>
                            </th>
                            <th>
                            </th>
                          </Tr>
                        ) : (<></>)
                      )}
                    </>
                  ) : (<></>)}
                  <tr className="separate">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      ) : (<></>)}

      {activitySelected && activitySelected._id && budgetSelected ? (
        <Comparatives
          comparativeScreenConfigure="list visualization"
          budgetSuppliersInformation={false}
          setActivityComparativeSelected={setActivitySelected}
          trBorderColor={primaryColor[step.findIndex(stepFind => stepFind._id === activitySelected.stepId)]}
          activity={activitySelected} activityId={activitySelected._id}
          budgets={[budgetSelected]}
          suppliers={suppliers.filter(suppliers => suppliers._id === budgetSelected.providerId)} />
      ) : (<></>)}
    </div>
  );
};

export default ListManagement;
