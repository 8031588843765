export const BaseBoard = {
    Rodape: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Área',
                    unit_of_measurement: 'm²',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Tipo de peça',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Fornecedor',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}