import React, { useState } from "react";
import { WoodWorkProps } from "../../../types/typesEntitiesProject"

import { stateType } from "../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    projetoMarcenaria?: WoodWorkProps[]
}

const WoodworkActivityDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            {activity.projetoMarcenaria?.map((element, index) => (
                <>
                    <b><br />Projeto {index + 1}</b>
                    <div className="item">
                        <div className="line">
                            <p><b>Quantidade: </b></p>
                            <p>{element.quantidade}un.</p>
                        </div>
                        <div className="line">
                            <p><b>Nome: </b></p>
                            <p>{element.nome}</p>
                        </div>
                        <div className="line">
                            <a href={element.link}>Download arquivo</a>
                        </div>
                    </div>
                </>

            ))}
        </div>
    );
};

export default WoodworkActivityDescription;
