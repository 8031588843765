import styled from "styled-components";

export const Th = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    
    a {
        max-width: 21ch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        float: left;
        color: #000;
        :hover {
            color: #0000FF;
        }
    }
`

export const MenuOverlay = styled.div`
    background-color: var(--dark-background);
    border-radius: 0.3rem;
    display: grid;
    position: absolute;
    right: 0;
    margin-right: 3rem;
    margin-top:1.7rem;
`

export const OptionButton = styled.a`
    color: var(--primary-color);
    font-weight: 400;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    font-size:0.85rem;
    cursor: pointer;

    a{
        color: var(--primary-color);
        font-weight: 400;
    }
`