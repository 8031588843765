import React, { useEffect, useState } from "react";

import { BudgetsProps, allActivitiesProps, SupplierProps } from "../../../types/typesEntitiesProject"
import { ComparativeHeader, ActivityBudgetContainer } from "../style"

import DemolicaoComparartive from "./Demolition";
import EstruturaComparartive from "./Masonary/Structure"
import PisoComparartive from "./Masonary/Floor"
import FundacaoComparative from "./Masonary/Fundation"
import FechamentoComparartive from "./Masonary/Closure";
import LajeComparartive from "./Masonary/Flagstone";
import RequadramentoComparartive from "./Masonary/Refraiming";
import EscadasComparative from "./Masonary/Stairways";

import ImpermeabilizacaoComparartive from "./Waterproofing/Impermeabilizacao";
import PainelEletricoComparartive from "./Electric/ElectricPanel";
import PassagemConduiteComparartive from "./Electric/PassageOfInfra";
import PassagemFiosComparartive from "./Electric/PassageOfWires";
import InstalcoesComparartive from "./Electric/Installations";
import AutomacoesComparartive from "./Electric/Automations";
import AguaFriaComparartive from "./Hydraulics/ColdWater";
import AguaQuenteComparartive from "./Hydraulics/HotWater";
import EsgotoComparartive from "./Hydraulics/Sewer";
import GasComparartive from "./Hydraulics/Gas";
import RevestimentoDePisoCeramicoComparartive from "./FloorsAndCoating/CeramicFloorCovering";
import RevestimentoDePisoPedrasComparartive from "./FloorsAndCoating/StoneFloorCovering";
import RevestimentoDePisoVinilicoComparartive from "./FloorsAndCoating/VinylFloorCovering";
import RevestimentoDeParedeComparartive from "./FloorsAndCoating/WallCovering";
import AssoalhoComparartive from "./FloorsAndCoating/Flooring";
import RevestimentoDeMadeiraComparartive from "./FloorsAndCoating/WoodCovering";
import RodapeComparartive from "./FloorsAndCoating/BaseBoard";
import ForroComparartive from "./Lining";
import PinturaComparartive from "./Painting/Paint";
import VasoComparartive from "./TablewareAndMetals/Vases";
import TorneiraMisturadorComparartive from "./TablewareAndMetals/FaucetsAndMixer";
import CubasComparartive from "./TablewareAndMetals/Vats";
import ChuveiroComparartive from "./TablewareAndMetals/Showers";
import AcessorioComparartive from "./TablewareAndMetals/Accessories";
import TelhadoComparartive from "./Roof";
import BoxBanheiroComparartive from "./Glassworks/Bathroombox";
import FechamentoSacadaComparartive from "./Glassworks/BalconyClosing";
import PortaVidroEsquadriasComparartive from "./Glassworks/GlassdoorandFrames";
import EspelhosComparartive from "./Glassworks/Mirrors";
import NichoComparartive from "./Masonary/Niche";
import MarmorariaRevestimentoComparartive from "./MarbleWork/Coating";
import TentoSoleiraBagueteRodabaseComparartive from "./MarbleWork/TentoSoleiraBagueteRodabase";
import ArCondicionadoComparartive from "./AirConditioning";
import PiasBancadasComparartive from "./MarbleWork/SinksAndCountertops";
import PapelDeParedeComparartive from "./Painting/Wallpaper";
import PrateleiraComparartive from "./MarbleWork/Shelfs";
import SoculoComparartive from "./Masonary/Socle";
import SawMillComparartive from "./SawMill/Tables";
import MarcenariaComparartive from "./WoodWork";
import DoorsAndWindowsComparartive from "./DoorsAndWindows";
import MateriaisComparative from "./Materials";
import EsquadriasComparartive from "./Frames";
import MobiliarioComparartive from "./Furniture";

import HeaderList from "./Components/HeaderList";
import { constructor, simpleSupplier, worker } from "../../../Config/dictionary";
import { selectMinBudgetInSimpleSuppliers } from "../scripts/budgetMinValue";
import LimpezaComparartive from "./Cleaning";
import MudancaComparartive from "./Moving";



interface comparativeProps {
    activityId: string
    budgets: BudgetsProps[]
    activity: allActivitiesProps
    suppliers: SupplierProps[]
    setActivityComparativeSelected?: any
    trBorderColor: string
    budgetSuppliersInformation: boolean,

    comparativeScreenConfigure: string
}

export const Comparatives: React.FC<comparativeProps> = (props) => {
    const [budgets] = useState<BudgetsProps[]>(props.budgets.filter(budget => budget.activityId === props.activityId))
    const [suppliers, setSuppliers] = useState<SupplierProps[]>([])
    const [suppliersVisualization, setSupplierVisualization] = useState<string>('onlySimpleSuppliers')
    const [destackMinItemValue, setDestackMinItemValue] = useState<boolean>(true)
    useEffect(() => {
        console.log(props.comparativeScreenConfigure)
        props.comparativeScreenConfigure === "comparative visualization" ? (
            setDestackMinItemValue(true)
        ) : (
            setDestackMinItemValue(false)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.comparativeScreenConfigure === "selectionBudgets visualization") {
            setSuppliers(props.suppliers)
        } else if (props.comparativeScreenConfigure === "comparative visualization") {
            setSuppliers(props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier))
        }
    }, [])

    const filterSuppliers = (filterValue: string) => {
        const suppliersCopy = props.suppliers
        if (filterValue === 'onlySimpleSuppliers') {
            setSupplierVisualization('onlySimpleSuppliers')
            setSuppliers(suppliersCopy.filter(supplier => supplier.typeSupplier === simpleSupplier))
        } else if (filterValue === "allSuppliers") {
            setSupplierVisualization('allSuppliers')
            const filterSuppliers = props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker)
            /**Setar o menor oçamento entre os avulsos aqui  */

            filterSuppliers.unshift(selectMinBudgetInSimpleSuppliers(budgets, props.activity, suppliers))
            setSuppliers(filterSuppliers)
        }
    }

    return (
        <div>
            <ComparativeHeader>
                <HeaderList
                    activityId={props.activityId}
                    budgets={budgets}
                    comparativeScreenConfigure={props.comparativeScreenConfigure}
                    suppliers={suppliers}
                    setActivityComparativeSelected={props.setActivityComparativeSelected}
                    suppliersVisualization={suppliersVisualization}
                    filterFunction={filterSuppliers}
                />

                {props.activity ? (
                    <ActivityBudgetContainer trBorderColor={props.trBorderColor} >
                        <p className="textLeft">{props.activity.activityName}</p>
                        <p>{" "}</p>
                        {suppliers.map((supplier) => (
                            budgets.findIndex(budgetFind => budgetFind.providerId === supplier._id) !== -1 ? (
                                <p className="centerItens">R${Number(budgets[budgets.findIndex(budgetFind => budgetFind.providerId === supplier._id)].totalBudgetPayment).toFixed(2)}</p>
                            ) : (<></>)
                        ))}
                    </ActivityBudgetContainer>
                ) : (<></>)}
            </ComparativeHeader>
            {
                props.activity.activityType === "Demolicao" ? (<DemolicaoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                    props.activity.activityType === "Fundacao" ? (<FundacaoComparative destackMinItemValue={destackMinItemValue} activity={props.activity} budget={props.budgets} suppliers={suppliers} />) :
                        props.activity.activityType === "Estrutura" ? (<EstruturaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                            props.activity.activityType === "Fechamento" ? (<FechamentoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                props.activity.activityType === "Piso" ? (<PisoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                    props.activity.activityType === "Laje" ? (<LajeComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                        props.activity.activityType === "Requadramento de vao" ? (<RequadramentoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                            props.activity.activityType === "Escadas" ? (<EscadasComparative destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                props.activity.activityType === "Impermeabilizacao" ? (<ImpermeabilizacaoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                    props.activity.activityType === "Painel eletrico" ? (<PainelEletricoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                        props.activity.activityType === "Passagem de conduite" ? (<PassagemConduiteComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                            props.activity.activityType === "Passagem de fios" ? (<PassagemFiosComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                props.activity.activityType === "Automacoes" ? (<AutomacoesComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                    props.activity.activityType === "Instalacoes" ? (<InstalcoesComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                        props.activity.activityType === "Agua quente" ? (<AguaQuenteComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                            props.activity.activityType === "Agua fria" ? (<AguaFriaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                props.activity.activityType === "Esgoto" ? (<EsgotoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                    props.activity.activityType === "Gas" ? (<GasComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                        props.activity.activityType === "Ar-Montagem da infra" ? (<ArCondicionadoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                            props.activity.activityType === "Revestimento de madeira" ? (<RevestimentoDeMadeiraComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                props.activity.activityType === "Assoalho" ? (<AssoalhoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                    props.activity.activityType === "Revestimento de parede" ? (<RevestimentoDeParedeComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                        props.activity.activityType === "Revestimento de piso vinilico" ? (<RevestimentoDePisoVinilicoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                            props.activity.activityType === "Revestimento de piso - Pedras" ? (<RevestimentoDePisoPedrasComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                props.activity.activityType === "Revestimento de piso ceramico" ? (<RevestimentoDePisoCeramicoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                    props.activity.activityType === "Rodape" ? (<RodapeComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                        props.activity.activityType === "Forro" ? (<ForroComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                            props.activity.activityType === "Papel de parede" ? (<PapelDeParedeComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                props.activity.activityType === "Pintura" ? (<PinturaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                    props.activity.activityType === "Vasos" ? (<VasoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                        props.activity.activityType === "Cubas" ? (<CubasComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                            props.activity.activityType === "Torneira e misturador" ? (<TorneiraMisturadorComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                props.activity.activityType === "Chuveiros" ? (<ChuveiroComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                    props.activity.activityType === "Acessorios" ? (<AcessorioComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                        props.activity.activityType === "Telhado" ? (<TelhadoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                            props.activity.activityType === "Fechamento de sacada" ? (<FechamentoSacadaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                props.activity.activityType === "Box de banheiro" ? (<BoxBanheiroComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                    props.activity.activityType === "Porta de vidro e esquadrias" ? (<PortaVidroEsquadriasComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                        props.activity.activityType === "Espelhos" ? (<EspelhosComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                props.activity.activityType === "Prateleira" ? (<PrateleiraComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                    props.activity.activityType === "Revestimento" ? (<MarmorariaRevestimentoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                        props.activity.activityType === "Tento" ||
                                                                                                                                                                                            props.activity.activityType === "Soleira" ||
                                                                                                                                                                                            props.activity.activityType === "Roda base" ||
                                                                                                                                                                                            props.activity.activityType === "Baguete" ? (<TentoSoleiraBagueteRodabaseComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                            props.activity.activityType === "Pias e bancadas" ? (<PiasBancadasComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                props.activity.activityType === "Soculo em alvenaria" ? (<SoculoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                props.activity.activityType === "Nicho" ? (<NichoComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :  
                                                                                                                                                                                                props.activity.activityType === "Mesas" ? (<SawMillComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                        props.activity.activityType === "Marcenaria" ? (<MarcenariaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                            props.activity.activityType === "Portas e janelas" ? (<DoorsAndWindowsComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                                props.activity.activityType === "Esquadrias" ? (<EsquadriasComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                                    props.activity.activityType === "Mobiliario" ? (<MobiliarioComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                                        props.activity.activityType === "Limpeza" ? (<LimpezaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                                            props.activity.activityType === "Mudança" ? (<MudancaComparartive destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                                                props.activity.activityType === "Materiais" ? (<MateriaisComparative destackMinItemValue={destackMinItemValue} activity={props.activity} budget={budgets} suppliers={suppliers} />) :
                                                                                                                                                                                                                                    (<></>)
            }
        </div>
    )
}

export default Comparatives