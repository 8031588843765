import { BudgetsProps, allActivitiesProps, SupplierProps } from "../../../types/typesEntitiesProject"

import { simpleSupplier } from "../../../Config/dictionary"

export const budgetMinValue = (budgets: BudgetsProps[], activity: allActivitiesProps) => {
    const budgetsCopy = budgets?.filter(budget => budget.typeSupplier === simpleSupplier && budget.activityId === activity._id)
    const values: number[] = []

    budgetsCopy.forEach((item => {
        if (parseFloat(item.totalBudgetPayment.replace(',', '.')) > 0.00) {
            values.push(parseFloat(item.totalBudgetPayment.replace(',', '.')))
        }
    }))

    const min = Math.min(...values);

    return min.toFixed(2).replace('.', ',')
}

export const selectMinBudgetInSimpleSuppliers = (budgets: BudgetsProps[], activity: allActivitiesProps, suppliers: SupplierProps[]) => {
    const budgetsCopy = budgets?.filter(budget => budget.typeSupplier === simpleSupplier && budget.activityId === activity._id)
    const budgetsMinValue: BudgetsProps[] = []

    /**Filtar os orçamentos dos fornecedores avulsos*/
    budgetsCopy.map((budgetSimpleSupplier) => {
        const minBudget = budgetsCopy.filter(budgetFilter => budgetFilter.activityId === budgetSimpleSupplier.activityId).reduce((a, b) => {
            if (b.totalBudgetPayment.replace(',', '.') < a.totalBudgetPayment.replace(',', '.')) a = b
            return a
        })
        budgetsMinValue.push(minBudget)
        if (budgetsMinValue.findIndex(budgetFind => budgetFind._id === minBudget._id) === -1) {
            budgetsMinValue.push(minBudget)
        }
    })

    return suppliers.filter(supplier => supplier._id === budgetsMinValue[0].providerId)[0]
}