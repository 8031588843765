import React, { useState } from "react";

import { MenuOverlay } from "../../AcessProject/Tr/style"
import { OptionButton } from "../../../components/stylesDomElements/htmlStyledElements"
import { TypeWorkContainer, OptionsButton } from "./style"

import dropDownIcon from "../../../assets/expand_less.svg"
import pointsMenu from "../../../assets/pointsMenu.svg";
//import axios from "axios";

interface optionProps {
    supplierId: string
}

const OptionMenuOverlay: React.FC<optionProps> = (/* props */) => {
    const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
    const [scaleY, setScaleY] = useState<number>(-1);

    const modify = () => {
        if (scaleY === 1) {
            setScaleY(-1);
        } else if (scaleY === -1) {
            setScaleY(1);
        }
    };

    /* const deleteSupplier = () => {
        console.log(props.supplierId)
        axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteSupplier/${props.supplierId}`).then((res) => {
            if (res.status === 200)
                window.location.reload()
        })
    } */
    return (
        <div>
            <OptionsButton onClick={() => setVisibleMenu(!visibleMenu)}>
                <img src={pointsMenu} alt="pontinhos" />
            </OptionsButton>

            {visibleMenu ? (
                <MenuOverlay>
                    <OptionButton /* onClick={() => deleteSupplier()} */>
                        Desativar fornecedor
                    </OptionButton>
                    <OptionButton onClick={() => modify()} valueScaleY={scaleY}>
                        Alterar indicação <img src={dropDownIcon} alt="dropDown" />
                    </OptionButton>
                    <TypeWorkContainer>
                        {scaleY === 1 ? (
                            <>
                                <div>
                                    <input type="radio" name="typeWork" id="obraGrande" />
                                    <label htmlFor="obraGrande">Obras grandes</label>
                                </div>
                                <div>
                                    <input type="radio" name="typeWork" id="obraMedia" />
                                    <label htmlFor="obraMedia">Obras médias</label>
                                </div>
                                <div>
                                    <input type="radio" name="typeWork" id="obraPequena" />
                                    <label htmlFor="obraPequena">Obras pequenas</label>
                                </div>
                                <div>
                                    <input type="radio" name="typeWork" id="todos" />
                                    <label htmlFor="obraPequena">Todos</label>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </TypeWorkContainer>
                    <OptionButton>Retirar da lista</OptionButton>
                </MenuOverlay>
            ) : null}
        </div>
    )
}

export default OptionMenuOverlay