import React, { useState } from "react";

import { PlantProps } from "../../../types/typesEntitiesProject";
import { Tr } from "./style";
import { Th, MenuOverlay } from "./style";
import { OptionButton } from "../../../components/stylesDomElements/htmlStyledElements"

import pointsMenu from "../../../assets/pointsMenu.svg";
import axios from "axios";

const TrTable: React.FC<PlantProps> = (props) => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);

  const deletePlant = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/deletePlant/${props._id}`)
      .then((res) => {
        if (res.status === 200) setVisibleMenu(!visibleMenu);
        window.location.reload()
      });
  };

  return (
    <>
      {visibleMenu ? (
        <MenuOverlay>
          <OptionButton onClick={() => deletePlant()}>Excluir</OptionButton>
          <OptionButton onClick={() => setVisibleMenu(!visibleMenu)}><a href={props.archiveUrl} download>Baixar arquivo</a></OptionButton>
        </MenuOverlay>
      ) : null}

      <Th>
        <a href={props.archiveUrl} download>{props.archiveName}</a>
        <p onClick={() => deletePlant()}>
          X
        </p>
      </Th>
    </>
  );
};

export default TrTable;
