import styled from "styled-components";

export const CentralContent = styled.div`
    width: 25rem;
    padding: 1rem;
    display: grid;


    p{
        margin-top: 1rem;
    }

    select{
        padding: 0.5rem;
        margin-top: 1rem;
        border-radius: 0.3rem;
        border: 1px solid #E5E5E5;
        background-color: #F1F1F2;
    }

    #email{
        margin-top:1rem;
        padding: 0.7rem;
    }

    input{
        padding: 0.5rem;
        width: 23rem;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
        border-radius: 0.3rem;
    }
`

export const Button = styled.a`
    display: flex;
    margin: 1rem;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    font-size: 0.85rem;
    cursor: pointer;
    :hover{
        background-color: var(--secondary-color);
        color: var(--primary-color)
    }
`
export const ConfirmationMessage = styled.div`
    color: var(--secondary-color);
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--primary-color);
`
