import React, { useEffect, useState } from "react";
import axios from "axios";
import { differenceInBusinessDays } from "date-fns";
import {
  Container,
  Complete,
  StepInfoContent,
  InputIfo,
  DurationWorkingDaysInput,
  CronogramContentDate,
} from "./style";

import { UseGanttContext } from "../../Context/GanttContext";
import dropDownIcon from "../../assets/dropDown.svg";

import iconCheck from "../../assets/checkIconGreen.svg";
import { UseUserContext } from "../../Context/UserContext";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
interface StepProps {
  _id: string;
  stepName: string;
  porcentage: number;
  backgroundColor: string;
  borderColor: string;
  dropDownDirection?: number;
  startDate?: string;
  endDate?: string;
  children?: any;
}

const Steps: React.FC<StepProps> = (props) => {
  const { projectSelected } = UseUserContext();
  const { setStepId, filterPeriod } = UseGanttContext();
  const { editPermission } = UseAuthAcessContext();
  const [scaleY, setScaleY] = useState<number>(1);

  const [startDate, setStartdate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [intervalDays, setIntervalDays] = useState<string>("");
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);
  const [showIconRequest, setShowIconRequest] = useState<boolean>(false);
  const [typeOperation, setTypeOperation] = useState<string>("");
  const [dateAlert, setDateAlert] = useState<string>("");

  useEffect(() => {
    if (editPermission === "Editor" || editPermission === "") {
      setIsEditAuthorized(true);
    } else if (editPermission === "Visualizacao") {
      setIsEditAuthorized(false);
    }
  }, [editPermission]);

  useEffect(() => {
    if (props.startDate && props.endDate) {
      setStartdate(parseDateDefault(props.startDate));
      setEndDate(parseDateDefault(props.endDate));
    }
  }, []);

  useEffect(() => {
    setIntervalDays(
      differenceInBusinessDays(
        new Date(
          Number(endDate.split("-")[0]),
          Number(endDate.split("-")[1]),
          Number(endDate.split("-")[2])
        ),
        new Date(
          Number(startDate.split("-")[0]),
          Number(startDate.split("-")[1]),
          Number(startDate.split("-")[2])
        )
      ).toString()
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.dropDownDirection) {
      setScaleY(props.dropDownDirection);
    }
  }, [props.dropDownDirection]);

  const modify = () => {
    if (scaleY === -1) {
      setScaleY(1);
      setStepId?.([props._id]);
    } else {
      setScaleY(-1);
      setStepId?.([props._id]);
    }
  };

  const parseDateDefault = (date: string) => {
    var partsDate = date.split("/");
    return `${partsDate[2]}-${partsDate[1]}-${partsDate[0]}`;
  };

  const changeUpdateStartDate = (startDate: string) => {
    if (isEditAuthorized) {
      setShowIconRequest(true);
      setStartdate(startDate);
      setTypeOperation("onlyStartAndFinishDate");
    }
  };

  const changeUpdateEndDate = (endDate: string) => {
    if (isEditAuthorized) {
      setShowIconRequest(true);
      setEndDate(endDate);
      setTypeOperation("onlyStartAndFinishDate");
    }
  };

  const changeUpdateIntervalDays = (intervalDays: string) => {
    setShowIconRequest(true);
    setIntervalDays(intervalDays);
    setTypeOperation("onlyIntervalDays");
  };

  const sendNewStepDates = () => {
    if (new Date(startDate) <= new Date(endDate)) {
      setDateAlert("");
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/editDateStep`, {
          stepId: props._id,
          startDate,
          intervalDays,
          endDate,
          projectId: projectSelected,
          typeOperation: typeOperation,
        })
        .then((res) => {
          if (res.status === 200) {
            setShowIconRequest(false);
            window.location.reload();
          }
        });
    } else {
      // Exibir uma mensagem de erro ou realizar alguma ação adequada quando as datas forem inválidas
      setDateAlert(
        "A data de início deve ser menor ou igual à data de término."
      );
      setIntervalDays("0");
    }
  };

  return (
    <Container
      borderColor={props.borderColor}
      width={filterPeriod === "cronogram" ? "33rem" : "18rem"}
    >
      <Complete
        valueScaleY={scaleY}
        width={`${props.porcentage.toFixed(1)}%`}
        backgroundColor={`${props.backgroundColor}`}
      >
        <StepInfoContent
          width={filterPeriod === "cronogram" ? "33rem" : "17rem"}
        >
          <p>
            <span className="step-name">{props.stepName}</span>
            <span>({props.porcentage.toFixed(1)}%)</span>
          </p>
          {filterPeriod !== "cronogram" && props.children && (
            <img
              src={dropDownIcon}
              alt="dropDown icone"
              onClick={() => modify()}
            />
          )}

          {showIconRequest && (
            <img
              src={iconCheck}
              alt="icon check"
              onClick={() => sendNewStepDates()}
            />
          )}

          {filterPeriod === "cronogram" && (
            <CronogramContentDate>
              <InputIfo
                isEditAuthorized={isEditAuthorized}
                type="date"
                value={startDate}
                onChange={(e) => changeUpdateStartDate(e.target.value)}
              />
              <DurationWorkingDaysInput
                type="number"
                placeholder={intervalDays}
                onChange={(e) => changeUpdateIntervalDays(e.target.value)}
              />
              <InputIfo
                isEditAuthorized={isEditAuthorized}
                type="date"
                value={endDate}
                onChange={(e) => changeUpdateEndDate(e.target.value)}
              />
            </CronogramContentDate>
          )}
        </StepInfoContent>
      </Complete>
      {props.children}

      {dateAlert !== "" && <p className="alert">{dateAlert}</p>}
    </Container>
  );
};

export default Steps;
