import React, { useEffect, useState } from "react"
import { UseDoorsAndWindowsActivityContext } from "../../../Context/ActivitiesContext/DoorsAndWindows/DoorsAndWindowsContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";
import { textTransform } from "../script"

const DoorsAndWindowsController: React.FC = () => {
  const { activitySelected, setActivitySelected } = UseStepContext()
  const { doorsAndWindowsActivities, editDoorsAndWindowsActivity } = UseDoorsAndWindowsActivityContext()

  useEffect(() => {
    setActivitySelected?.('Portas e janelas')
  }, [])

  useEffect(() => {
    if (doorsAndWindowsActivities.length > 0) {
      var activityEdited = doorsAndWindowsActivities[0]
      activityEdited.activityType = activitySelected
      activityEdited.activityName = textTransform(activitySelected)
      editDoorsAndWindowsActivity?.(0, activityEdited)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySelected])

  /* useEffect(() => {
      if (masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType !== "") {
          setOptionSelected(masonaryActivities[props.indexSelected].activityType)
      }
      masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType === "" ? (setOptionSelected('Fundacao')) : (<></>)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indexSelected]) */

  return <div>
    <ActionSelect>
      Atividade:
      <select value={activitySelected} name="DoorsAndWindowsStep" id="DoorsAndWindowsStep" onChange={(e) => setActivitySelected(e.target.value)}>
        <option value="Portas e janelas">Portas e janelas</option>
        <option value="Materiais para portas e janelas">Materiais</option>
      </select>
    </ActionSelect>
  </div>
}

export default DoorsAndWindowsController