import React, { useState, useEffect } from "react"
import axios from "axios"
import { UseUserContext } from "../../../Context/UserContext"

import {
    ContainerModal,
    FormContainer,
    HeaderFormContainer,
    Button
} from "../../../components/stylesDomElements/htmlStyledElements";

import XIcon from "../../../assets/XIcon.svg"

import {
    ContainerNotification,
    HeaderContainerNotification,
    ReadNotifications,
    NoReadNotifications,
    CircularImg,
    ContentNotification,
    ButtonMarkReadNotifications,
    OptionsNotification,
    NoNotification,
    Text,
    ContainerButtonConfirmModal
} from "./style"


interface notificationComponentProps {
    renderContainerNotification: boolean
}

interface notificationProps {
    _id: string,
    receiverId: string,
    typeNotification: string,
    urlImage: string,
    senderName: string,
    projectReferenceName: string,
    projectId: string,
    textMessage: string,
    read: boolean,
    date: string
}

interface acessProps {
    _id: string,
    projectId: string,
    permission: string,
    accept: boolean
}

const Notifications: React.FC<notificationComponentProps> = (props) => {
    const { userId } = UseUserContext()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [accessList, setAccessList] = useState<acessProps[]>([])
    const [notifications, setNotifications] = useState<notificationProps[]>([])

    const [notificationSelected, setNotificationSelected] = useState<notificationProps>()

    useEffect(() => {
        if (userId && userId !== "") {
            axios.get(`${process.env.REACT_APP_BASE_URL}/notifications/${userId}`).then(res => {
                setNotifications(res.data)
            })
        }
    }, [userId])

    useEffect(() => {
        if (userId && userId !== "") {
            axios.get(`${process.env.REACT_APP_BASE_URL}/listGuestAcess/${userId}`).then(res => {
                setAccessList(res.data)
            })
        }
    }, [userId])

    const selectNotification = (notification: notificationProps) => {
        if (notification.typeNotification === "invite access" && notification.read === false) {
            setNotificationSelected(notification)
            setShowModal(true)
        }
    }

    const accessConfirm = (accessId: string, notificationId?: string) => {
        axios.put(`${process.env.REACT_APP_BASE_URL}/confirmAcess/${accessId}`, {
            accept: true
        }).then(res => {
            if (notificationId)
                readNotification(notificationId)
            if (res.status === 200)
                setShowModal(false)
        })
    }

    const readNotification = (notificationId?: string) => {
        axios.put(`${process.env.REACT_APP_BASE_URL}/readNotification/${notificationId}`)
    }

    return (
        <div>
            {showModal ? (
                <ContainerModal>
                    <FormContainer>
                        {accessList.filter(access => access.accept === false && access.projectId === notificationSelected?.projectId).map((access, index) =>
                            <>
                                <HeaderFormContainer>
                                    <p>{notificationSelected?.senderName + " " + notificationSelected?.textMessage}</p>
                                    <div>
                                        <img src={XIcon} alt="Icone de fachar" onClick={() => setShowModal(false)} />
                                    </div>
                                </HeaderFormContainer>
                                <div className="form">
                                    <p>Projeto: {notificationSelected?.projectReferenceName}</p><br />
                                    <p>Tipo de acesso: {access?.permission}</p>

                                    <ContainerButtonConfirmModal>
                                        <Button onClick={() => accessConfirm(access._id, notificationSelected?._id)}>Confirmar acesso</Button>
                                    </ContainerButtonConfirmModal>
                                </div>
                            </>
                        )}
                    </FormContainer>
                </ContainerModal>
            ) : (<></>)}

            {props.renderContainerNotification ? (
                <ContainerNotification>
                    {notifications ? (
                        <>
                            <HeaderContainerNotification>
                                <div>
                                    <input type="radio" id="key1" name="drone" value="huey" />
                                    <label htmlFor="key1">
                                        <div className="optionMenuNotifications">
                                            <p>Tudo</p>
                                            <span>{""}</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <input type="radio" id="key2" name="drone" value="dewey" />
                                    <label htmlFor="key2">
                                        <div className="optionMenuNotifications">
                                            <p>Técnico</p>
                                            <span>{""}</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <input type="radio" id="key3" name="drone" value="louie" />
                                    <label htmlFor="key3">
                                        <div className="optionMenuNotifications">
                                            <p>Financeiro</p>
                                            <span>{""}</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <input type="radio" id="key4" name="drone" value="louie" />
                                    <label htmlFor="key4">
                                        <div className="optionMenuNotifications">
                                            <p>Apontamento</p>
                                            <span>{""}</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                                    <input type="radio" id="key5" name="drone" value="louie" />
                                    <label htmlFor="key5">
                                        <div className="optionMenuNotifications">
                                            <p>Outros</p>
                                            <span>{""}</span>
                                        </div>
                                    </label>
                                </div>
                            </HeaderContainerNotification>

                            {notifications.filter(notification => notification.read === false).length > 0 ? (
                                <NoReadNotifications>
                                    <div className="actionsContentNoReadNotifications">
                                        <div className="noRead">Não lidas</div>
                                        <ButtonMarkReadNotifications>
                                            Marcar todas como lidas
                                        </ButtonMarkReadNotifications>
                                    </div>

                                    {notifications.filter(notificationFiltered => notificationFiltered.read === false).map((notification, index) => (
                                        <ContentNotification key={index} onClick={() => selectNotification(notification)}>
                                            <CircularImg><img src={notification.urlImage} alt="" /></CircularImg>
                                            <div className="notificationContainer">
                                                <span>
                                                    <b>{notification.projectReferenceName} &nbsp; </b>
                                                    <p className="grayText"> {/* | O fornecedor */}</p>
                                                </span>
                                                <div className="textElement">
                                                    {notification.senderName}&nbsp;
                                                    <Text>{notification.textMessage}</Text>
                                                </div>
                                            </div>
                                            <div className="dateContent">{notification.date}</div>

                                            <OptionsNotification>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </OptionsNotification>
                                        </ContentNotification>
                                    ))}
                                </NoReadNotifications>
                            ) : (<></>)}

                            {notifications.filter(notification => notification.read === true).length > 0 ? (
                                <ReadNotifications>
                                    <div className="read">
                                        <p>Lidas</p>
                                    </div>
                                    {notifications.filter(notification => notification.read === true).map((notificationRead) =>
                                        <ContentNotification>
                                            <CircularImg><img src={notificationRead.urlImage} alt="" /></CircularImg>
                                            <div className="notificationContainer">
                                                <span>
                                                    <b>Condomínio Boulevard &nbsp;</b>
                                                    <p className="grayText"></p>
                                                </span>
                                                <span>
                                                    <b>{notificationRead.senderName} &nbsp;</b>
                                                    <p className="grayText"> {notificationRead.textMessage}</p>
                                                </span>
                                            </div>
                                            <div className="dateContent">20 out</div>

                                            <OptionsNotification>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </OptionsNotification>
                                        </ContentNotification>
                                    )}
                                </ReadNotifications>
                            ) : (<></>)}
                        </>
                    ) : (<NoNotification>Não há notificações</NoNotification>)}
                </ContainerNotification>
            ) : null}
        </div>
    )
}

export default Notifications