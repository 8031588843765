import React, { useState, useEffect } from "react";

import { UseMasonaryActivityContext } from "../../../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { attributeType } from "../../../../Models/attributeType";
import { NicheFormType } from "../../../../Models/Masonary/type";
import { Niche } from "../../../../Models/Masonary/NicheModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const NicheStepForm: React.FC<stepFormProps> = (props) => {
  const { masonaryActivities, indexMasonaryActivitySelected, editMasonaryActivity } = UseMasonaryActivityContext();

  const [assignment, setAssignment] = useState<string>('')
  const [itensFormList, setItensFormList] = useState<NicheFormType>(Niche)

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      let masonaryActivityCopy = masonaryActivities[indexMasonaryActivitySelected]
      masonaryActivityCopy.activityType = 'Nicho'
      editMasonaryActivity?.(indexMasonaryActivitySelected, masonaryActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected])

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      setItensFormList(masonaryActivities[indexMasonaryActivitySelected].Niche)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected, masonaryActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemNicho(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Nicho") {
      addNicho({
        attributes: [
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
        },
        {
            itemName: 'Área',
            unit_of_measurement: 'm²',
            value: ''
        },
        {
            itemName: 'Altura',
            unit_of_measurement: 'm',
            value: ''
        },
        {
            itemName: 'Largura',
            unit_of_measurement: 'm',
            value: ''
        },
        {
            itemName: 'Profundidade',
            unit_of_measurement: 'm',
            value: ''
        },
        {
            itemName: 'Espessura da aba',
            unit_of_measurement: 'm',
            value: ''
        },
        {
            itemName: 'Pedra',
            unit_of_measurement: '',
            value: ''
        },
        {
            itemName: 'Acabamento da pedra',
            unit_of_measurement: '',
            value: ''
        },
        {
            itemName: 'Cor da pedra',
            unit_of_measurement: '',
            value: ''
        },
        {
            itemName: 'Número de divisórias horizontais',
            unit_of_measurement: 'un',
            value: ''
        },
        ]
      })
    }
  }

  const addNicho = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var NicheActivitiesCopy = masonaryActivities
    itensFormListCopy.Nicho.push(object)
    NicheActivitiesCopy[0].Niche = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, NicheActivitiesCopy[0])
  }



  const removeItemNicho = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Nicho.splice(index, 1)
    masonaryActivitiesCopy[0].Niche = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }



  const editNichoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Nicho[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editNichoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Nicho[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editNichoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Nicho[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Nicho.length ? (
        <div>
          <p>Nicho</p>
          {itensFormList.Nicho.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNichoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Interno">Interno</option>
                      <option value="Externo">Externo</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === "Pedra" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNichoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Granito">Granito</option>
                      <option value="Quartzo">Quartzo</option>
                      <option value="Mármore">Mármore</option>
                      <option value="Prime">Prime</option>
                      <option value="Lâmina ultracompactada">Lâmina ultracompactada</option>
                      <option value="Travertino">Travertino</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === "Acabamento da pedra" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNichoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Natural">Natural</option>
                      <option value="Polido">Polido</option>
                      <option value="Levigado/Satinado">Levigado/Satinado</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNichoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editNichoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editNichoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemNicho(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default NicheStepForm;

