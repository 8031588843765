import React from "react";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import { Container, Button } from "./style";

const AcessMenu: React.FC = () => {
  const { features, actualPage, setActualPage } = UseAuthAcessContext();
  return (
    <Container>
      {features.map((acessName, index) => (
        <Button
          selectedButton={acessName === actualPage}
          onClick={() => setActualPage(acessName)}
          key={index}
        >
          {acessName}
        </Button>
      ))}
    </Container>
  );
};
export default AcessMenu;
