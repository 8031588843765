import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { UseUserContext } from "../../Context/UserContext";
import {
  ContainerModal,
  FormContainer,
  HeaderFormContainer,
  FooterFormContainer,
  RevisionButton,
  ImgContentPendingReview,
  FeatureIcon,
  ReviewCicularOptions,
  Item,
  FormReviewContainer,
  ContainerInputDataReviewForm,
} from "./style";

import { CentralContent, Button } from "./style";
import iconCloseModal from "../../assets/XIcon.svg";
import TrTable from "./Tr/index";



import {
  Container,
  CicularOptions,
  PointIcon,
  ImgContent,
  LinkButton,
  MenuOverlay,
  FormReview,
  FormProjectDataReview,
  DownloadButton,
  ReviewForm,
  ContainerInputReviewForm,
  OptionTypeProjectReviewForm,
  ContainerReviewListItens,
  ReviewInputForm,
  SelectedSteps
} from "./style";



import alertIcon from "../../assets/alertIcon.svg";
import { PlantProps } from "../../types/typesEntitiesProject";
import { ContainerInputFormProjectData, FileTitle, FormProjectData, UploadContentMessage } from "../../pages/Home/style";
import { UseModalContext } from "../../Context/ModalContext";

interface CardProps {
  _id: string;
  imgProject: string;
  projectName: string;
  projectAdress: string;
  status: string;
  progress: number;
  client?: string;
}

interface ProjectProps {
  _id: string
  architectId: string,
  name: string,
  urlImage: string,
  urlArchiveProject: {
    archiveUrl: string,
    archiveName: string,
    type: string,
    size: string,
    modification: string
  }[],
  constructinEnbededLink: string,
  adress: string,
  adressNumber: number,
  adressAditional: string,
  adressCep: string,
  dimension: string,
  typeProject: string,
  typeConstruction: string,
  startDate: string,
  endDate: string,
  clienteName: string,
  responsibleName: string,
  status: string,
  progress: number
  description: string
}

const CardProject: React.FC<CardProps> = (props) => {
  const { userId, token } = UseUserContext();
  const { setShowModal, setModalNameHeader } = UseModalContext();
  const { projectSelected } = UseUserContext();
  const [projects, setProjects] = useState<ProjectProps[]>([]);
  const [filterProjects, setFilterProjects] = useState<string>("");


  const [typeShowModal, setTypeShowModal] = useState<boolean>(false);
  const [deleteAProject, setDeleteAProject] = useState("");
  const { setprojectSelected } = UseUserContext();
  const [renderMenuOverlay, setRenderMenuOverlay] = useState<boolean>(false);
  const [adminPanel, setAdminPanel] = useState<boolean>(false);
  const [pendingReview, setPendingReview] = useState<boolean>(false);
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [name, setNameProject] = useState<string>();
  const [fileProjetc, setFileProjetc] = useState<FileList>();
  const [image, setImage] = useState<FileList>();
  const [adress, setAdress] = useState<string>("");
  const [adressNumber, setAdressNumber] = useState<number>();
  const [adressAditional, setAdressAditional] = useState<string>("");
  const [adressCep, setAdressCep] = useState<string>("");
  const [dimension, setDimension] = useState<string>();
  const [typeProject, setTypeProject] = useState<string>();
  const [typeConstruction, setTypeConstruction] = useState<string>();
  const [projectSize, setProjectSize] = useState<string>();
  const [projectPeriod, setProjectPeriod] = useState<string>();
  const [projectFollowUp, setProjectFollowUp] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [clienteName, setClientName] = useState<string>();
  const [responsibleName, setResponsibleName] = useState<string>();

  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
  const [progressUploadedArchiveProject, setProgressUploadedArchiveProject] = useState<number>(0)
  const [uploadMessageArchiveProject, setUploadMessageArchiveProject] = useState<string>('Fazendo upload do PDF do projeto!')

  const [progressUploadedImageProject, setProgressUploadedImageProject] = useState<number>(0)
  const [uploadMessageImageProject, setUploadMessageImageProject] = useState<string>('Fazendo upload da capa do projeto!')

  const [step, setStep] = useState<number>(1);
  const [checked, setChecked] = useState<boolean>(true)
  const [project, setProject] = useState<ProjectProps>();
  const [archives, setArchives] = useState<PlantProps[]>([]);


  useEffect(() => {
    if (userId) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/projects/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setProjects(res.data);
        });
    }
  }, [token, userId]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/listPlants/${projectSelected}`)
        .then((res) => {
          setArchives(res.data);
        });
    }
  }, [projectSelected]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
        });
    }
  }, [projectSelected]);

  const ModalExibition = () => {
    setTypeShowModal(true);
    setDeleteAProject("1")
  }

  const saveLocalIdProjectSelected = () => {
    localStorage.removeItem('projectId')
    localStorage.setItem('projectId', props._id)
    setprojectSelected?.(props._id)
  }
  const selectTypeProjectRegister = (typeRegister: number) => {
    if (typeRegister !== step) {
      setStep(typeRegister)
      setChecked(!checked)
    }
  }

  const deleteProject = () => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteProject/${props._id}`).then(res => {
      if (res) {
        setRenderMenuOverlay(false)
        window.location.reload()
      }
    })
  }

  const openReviewModal = () => {
    localStorage.setItem('projectId', props._id)
    setprojectSelected?.(props._id)
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
          setShowReviewModal(true);
        });
    }

  }

  return (
    <>

      {showReviewModal ? (
        <ContainerModal>
          <FormReviewContainer>
            <HeaderFormContainer>
              <p>{project?.name} - Revisão e aprovação</p>
              <img
                src={iconCloseModal}
                alt="icone de fechar"
                onClick={() => setShowReviewModal(false)}
              />
            </HeaderFormContainer>
            <hr />
            <CentralContent>
              <div>
                {step !== 0 ? (
                  <OptionTypeProjectReviewForm>
                    <div>
                      <input
                        type="radio"
                        id="newRegister"
                        name="typeRegister"
                        value=""
                        checked={checked}
                        onClick={() => selectTypeProjectRegister(1)}
                      />
                      <label htmlFor="newRegister">
                        <div>Dados Cadastrais</div>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        id="dataPayment"
                        name="typeRegister"
                        value=""
                        checked={!checked}
                        onClick={() => selectTypeProjectRegister(2)}
                      />
                      <label htmlFor="dataPayment">
                        {" "}
                        <div>Dados de Projeto</div>
                      </label>
                    </div>
                  </OptionTypeProjectReviewForm>
                ) : (<></>)}

                {step === 1 ? (
                  <FormReview>
                    <ReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="name">Nome do projeto</label>
                        <ReviewInputForm
                          id="name"
                          type="text"
                          required
                          placeholder={project?.name}
                          value={name}
                          onChange={(e) => setNameProject(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="adress">Endereço da obra</label>
                        <ReviewInputForm
                          id="adress"
                          type="text"
                          required
                          value={adress}
                          placeholder={project?.adress}
                          onChange={(e) => setAdress(e.target.value)}
                        />
                      </ContainerInputReviewForm>

                      <ContainerInputReviewForm>
                        <label htmlFor="cep">Cep</label>
                        <ReviewInputForm
                          id="cep"
                          type="text"
                          placeholder={project?.adressCep}
                          value={adressCep}
                          required
                          onChange={(e) => setAdressCep(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="number">Numero</label>
                        <ReviewInputForm
                          id="number"
                          type="number"
                          placeholder={project?.adressNumber.toString()}
                          value={adressNumber}
                          required
                          onChange={(e) => setAdressNumber(parseInt(e.target.value))}
                        />
                      </ContainerInputReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="aditionalAdress">Complemento</label>
                        <ReviewInputForm
                          id="aditionalAdress"
                          type="text"
                          value={adressAditional}
                          placeholder={project?.adressAditional}
                          onChange={(e) => setAdressAditional(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="initProject">Previsão de início</label>
                        <ReviewInputForm
                          id="initProject"
                          type="text"
                          value={startDate}
                          placeholder={project?.startDate}
                          required
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="findProject">Previsão de encerramento</label>
                        <ReviewInputForm
                          id="findProject"
                          type="text"
                          value={endDate}
                          placeholder={project?.endDate}
                          required
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                    </ReviewForm>

                    <div className="grid2">
                      <SelectedSteps>
                        <ContainerReviewListItens>
                          {project?.urlArchiveProject.map((archive, index) => (
                            <Item>
                              <FileTitle>
                                <TrTable
                                  key={index}
                                  projectId={project._id}
                                  archiveUrl={archive.archiveUrl}
                                  archiveName={archive.archiveName}
                                  size={archive.size}
                                  type={archive.type}
                                  modification={archive.modification}
                                ></TrTable>
                              </FileTitle>
                            </Item>
                          ))}

                          {archives.map((item, index) => (
                            <Item>
                              <FileTitle>
                                <TrTable
                                  key={index}
                                  _id={item._id}
                                  projectId={item.projectId}
                                  archiveUrl={item.archiveUrl}
                                  archiveName={item.archiveName}
                                  size={item.size}
                                  type={item.type}
                                  modification={item.modification}
                                ></TrTable>
                              </FileTitle>
                            </Item>
                          ))}
                        </ContainerReviewListItens>
                      </SelectedSteps>

                      <DownloadButton>
                        <button>Baixar todos</button>
                      </DownloadButton>

                      <ContainerInputReviewForm>
                        <label htmlFor="clientName">Nome do cliente</label>
                        <ReviewInputForm
                          id="clientName"
                          type="text"
                          value={clienteName}
                          placeholder={project?.clienteName}
                          required
                          onChange={(e) => setClientName(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                      <ContainerInputReviewForm>
                        <label htmlFor="responsibleName">Nome do responsável</label>
                        <ReviewInputForm
                          id="responsibleName"
                          type="text"
                          value={responsibleName}
                          placeholder={project?.responsibleName}
                          required
                          onChange={(e) => setResponsibleName(e.target.value)}
                        />
                      </ContainerInputReviewForm>
                    </div>
                    <div></div>
                    <FooterFormContainer>
                      <div></div>
                      <Button onClick={() => selectTypeProjectRegister(2)}>Próximo</Button>
                    </FooterFormContainer>
                  </FormReview>
                ) : step === 2 ? (
                  <FormProjectDataReview>
                    <div className="grid1">
                      <ContainerInputDataReviewForm>
                        <label htmlFor="typeProjet">Tipo de projeto</label>
                        <select
                          id="typeProjet"
                          value={project?.typeProject}
                          required
                          onChange={(e) => setTypeProject(e.target.value)}
                        >
                          <option value="">Selecione</option>
                          <option value="Escritorio">Escritório</option>
                          <option value="Loja">Loja</option>
                          <option value="Area comum">Área comum</option>
                          <option value="Casa Residencial">Casa Residencial</option>
                          <option value="Apartamento">Apartamento</option>
                          <option value="Outros">Outros</option>
                        </select>
                      </ContainerInputDataReviewForm>
                      <ContainerInputDataReviewForm>
                        <label htmlFor="typeConstruction">Tipo de construção</label>
                        <select
                          id="typeConstruction"
                          value={project?.typeConstruction}
                          required
                          onChange={(e) => setTypeConstruction(e.target.value)}
                        >
                          <option value="">Selecione</option>
                          <option value="Obra do zero">Obra do zero</option>
                          <option value="Reforma leve">Reforma leve</option>
                          <option value="Reforma pesada">Reforma pesada</option>
                          <option value="Outros">Outros</option>
                        </select>
                      </ContainerInputDataReviewForm>
                      <ContainerInputDataReviewForm>
                        <label htmlFor="projectSize">Tamanho de obra</label>
                        <select
                          id="projectSize"
                          value={project?.dimension}
                          required
                          onChange={(e) => setProjectSize(e.target.value)}
                        >
                          <option value="">Selecione</option>
                          <option value="Pequena">Pequena</option>
                          <option value="Média">Média</option>
                          <option value="Grande">Grande</option>
                        </select>
                      </ContainerInputDataReviewForm>
                      <ContainerInputDataReviewForm>
                        <label htmlFor="projectFollowUp">Como a obra será acompanhada?</label>
                        <select
                          id="projectFollowUp"
                          value={projectFollowUp}
                          required
                          onChange={(e) => setProjectFollowUp(e.target.value)}
                        >
                          <option value="">Selecione</option>
                          <option value="Por mim">Por mim</option>
                          <option value="Selecionar na plataforma">Selecionar na plataforma</option>
                          <option value="Indicar">Indicar</option>
                          <option value="Pelo cliente">Pelo cliente</option>
                        </select>
                      </ContainerInputDataReviewForm>
                    </div>

                    <div className="grid2">
                      <ContainerInputDataReviewForm>
                        <label htmlFor="projectPeriod">Período da obra</label>
                        <select
                          id="projectPeriod"
                          value={projectPeriod}
                          required
                          onChange={(e) => setProjectPeriod(e.target.value)}
                        >
                          <option value="">Selecione</option>
                          <option value="Diurno">Diurno</option>
                          <option value="Noturno">Noturno</option>
                          <option value="Dia de semana - diurno/noturno">Dia de semana - diurno/noturno</option>
                          <option value="Dia de semana + fins de semana - diurno/noturno">Dia de semana + fins de semana - diurno/noturno</option>
                        </select>
                      </ContainerInputDataReviewForm>
                    </div>
                    <FooterFormContainer>
                      <div></div>
                      <Button>Aprovar</Button>
                    </FooterFormContainer>
                  </FormProjectDataReview>
                ) : (<></>)}

                {loadingRequest ? (
                  <UploadContentMessage>
                    {uploadMessageArchiveProject}
                    <br />
                    {progressUploadedArchiveProject}%

                    {image ? (
                      <>
                        <br />
                        <br />
                        {uploadMessageImageProject}
                        <br />
                        {progressUploadedImageProject}%
                      </>
                    ) : (
                      <></>
                    )}
                  </UploadContentMessage>
                ) : (
                  <></>
                )}
              </div>
            </CentralContent>
          </FormReviewContainer>
        </ContainerModal>
      ) : null}


      <Container>

        {typeShowModal === true ? (
          <ContainerModal>
            <>
              {deleteAProject === "1" ? (
                <FormContainer>
                  <HeaderFormContainer>
                    <p> </p>
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeShowModal(false)}
                    />
                  </HeaderFormContainer>

                  <CentralContent>
                    <p>Tem certeza que deseja deletar este projeto ?</p>
                  </CentralContent>

                  <FooterFormContainer>
                    <div></div>
                    <Button className="buttonRegister" type="submit" onClick={() => deleteProject()}>Sim</Button>
                  </FooterFormContainer>
                </FormContainer>
              ) : (
                <></>
              )}
            </>
          </ContainerModal>
        ) : null}
        {
          renderMenuOverlay ? (
            <MenuOverlay>
              <div className="pointsMenu" onClick={() => setRenderMenuOverlay(!renderMenuOverlay)}>
                <div className="poitElements">
                  <PointIcon />
                  <PointIcon />
                  <PointIcon />
                </div>
              </div>

              <LinkButton>Editar</LinkButton>
              <LinkButton>Arquivar</LinkButton>
              <LinkButton>
                Upload de PDF <img src={alertIcon} alt="icone de alerta" />
              </LinkButton>
              <div>
                <LinkButton onClick={() => ModalExibition()}>Excluir projeto</LinkButton>
              </div>
            </MenuOverlay>
          ) : (<></>)
        }
        {pendingReview ? (
          <ImgContentPendingReview backgroundUrl={props.imgProject}>
            <ReviewCicularOptions onClick={() => setRenderMenuOverlay(!renderMenuOverlay)}>
              <FeatureIcon />
              <PointIcon />
            </ReviewCicularOptions>
            <RevisionButton onClick={() => openReviewModal()}>Revisar</RevisionButton>
          </ImgContentPendingReview>
        ) : (
          <ImgContent backgroundUrl={props.imgProject}>
            <CicularOptions onClick={() => setRenderMenuOverlay(!renderMenuOverlay)}>
              <PointIcon />
              <PointIcon />
              <PointIcon />
            </CicularOptions>
          </ImgContent>
        )}

        <Link to={`/gestaoCronograma`} onClick={() => saveLocalIdProjectSelected()}>
          <div className="containerProjectInformations">
            <p className="projectName">{props.projectName}</p>
            <p className="projectAdress">{props.projectAdress}</p>
            <br />
            <div className="status">
              Status: <p>{props.status}</p>
            </div>
            <div className="progress">
              Progresso: <p>{props.progress}%</p>
            </div>
            {adminPanel ? (
              <div className="progress">
                Cliente: <p>{props.client}</p>
              </div>
            ) : null}
          </div>
        </Link>
      </Container>
    </>
  );
};

export default CardProject;
