import React, { useEffect } from 'react'

import { UseEletricActivityContext } from "../../../Context/ActivitiesContext/Eletric/EletricContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"

import { RequestApi } from "../RequestApi"

import Automations from '../../StepsForm/Eletric/Automations'
import Conduit from '../../StepsForm/Eletric/PassageOfInfra'
import ElectricPanel from '../../StepsForm/Eletric/ElectricPanel'
import Instalations from '../../StepsForm/Eletric/Instalations'
import PassingOfWires from '../../StepsForm/Eletric/PassageOfWires'

import { Container } from "../style"
import { textButton } from '../script/textButtonSubmit'
import MaterialsEletricStepForm from '../../StepsForm/Eletric/Materials'

const ElectricModifyActivities: React.FC<activityProps> = (props) => {
    const { eletricActivities, setActivity, setIndexEletricActivitySelected } = UseEletricActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexEletricActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Painel eletrico" ? (<ElectricPanel stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Passagem da infra" ? (<Conduit stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Passagem de fios" ? (<PassingOfWires stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Instalacoes" ? (<Instalations stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Automacoes" ? (<Automations stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsEletricStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(eletricActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(eletricActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default ElectricModifyActivities