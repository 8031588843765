import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget } from "../../style"
import { GreenDestack } from "../../../style"

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface activityProps {
    selectedBudget?: boolean,
    numero_folhas?: string,
    altura_porta?: string,
    comprimento_porta?: string,
    material_ferragem?: string,
    descricao?: string,
    cor_ferragem?: string,
    cor_vidro?: string,
    tipo_porta?: string,

}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        portaVidroEsquadrias: string,
        ferragem: string
    }
}

export const PortaVidroEsquadriasComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: string, activityId?: string, itemType?: any) => {
        const budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        const totalBudgetsList: number[] = []
        /**Identifica o tipo de item para calcular o menor valor total */
        itemType === "portaVidroEsquadrias" ? (budgetsFilter.forEach((budget) => {
            budget.budget?.portaVidroEsquadrias ? totalBudgetsList.push(parseFloat(budget.budget?.portaVidroEsquadrias)) : (<></>)
        })) : itemType === "ferragem" ? (budgetsFilter.forEach((budget) => {
            budget.budget?.ferragem ? totalBudgetsList.push(parseFloat(budget.budget?.ferragem)) : (<></>)
        })) : (<></>)

        if (!totalItem) {
            return <p>R$0,00</p>
        }

        const minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        })

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>
    }

    return (
        <ContainerItensBudget>
            {budgets ? (
                <>
                    <SectionItensBudget>
                        <p className="itemSectionName">Itens</p>
                        <p>Medidas</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                    </SectionItensBudget>

                    {activity.tipo_porta && activity.cor_vidro ? (
                        <ItemBudget>
                            <p className="itemName">Porta {activity.tipo_porta} - vidro {activity.cor_vidro}</p>
                            <p>{activity.altura_porta}m X {activity.comprimento_porta}m <br /> Nº folhas: {activity.numero_folhas} un.</p>
                            {props.suppliers ? (
                                <>
                                    {/**Item orçado pelo Fornecedor avulso */}
                                    {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                        budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                            budget.budget?.portaVidroEsquadrias ? renderItemBudget(budget.budget?.portaVidroEsquadrias, budget.activityId, 'portaVidroEsquadrias') : ('')
                                        ))
                                    ))}
                                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                    {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                        budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                            budget.budget?.portaVidroEsquadrias ? renderItemBudget(budget.budget?.portaVidroEsquadrias, budget.activityId, 'portaVidroEsquadrias') : ('')
                                        ))
                                    ))}
                                </>
                            ) : (<></>)}
                        </ItemBudget>
                    ) : (<></>)}

                    {activity.cor_ferragem ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Ferragem</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>
                            <ItemBudget>
                                <p className="itemName">Ferragem {activity.cor_ferragem},  {activity.material_ferragem}</p>
                                <p>1 un.</p>
                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.ferragem ? renderItemBudget(budget.budget?.ferragem, budget.activityId, 'ferragem') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.ferragem ? renderItemBudget(budget.budget?.ferragem, budget.activityId, 'ferragem') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudget>
                        </>
                    ) : (<></>)}
                </>
            ) : (<></>)}
        </ContainerItensBudget>
    )
}

export default PortaVidroEsquadriasComparartive