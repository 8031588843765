import React, { useState } from "react";
import { WallpaperFormType } from "../../../../Models/Paint/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  Wallpaper: WallpaperFormType;
}

const WallpaperDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>


        {activity.Wallpaper.PapelDeParede?.map((item, index) => (
          <div key={index}>
            {activity.Wallpaper.PapelDeParede.length > 1 ? (<u>Papel de parede {index + 1}</u>) : (<u>Papel de parede </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
            <hr />
          </div>
        ))}

      </div>
    </div>
  );
};

export default WallpaperDescription;