import React from "react";

import { SupplierProps } from "../../../../types/typesEntitiesProject"
import { constructor, worker } from "../../../../Config/dictionary"

import { SectionItensBudget } from "../../style"

interface TopElementItensBudgets {
    suppliers: SupplierProps[]
}

const TopElementItensBudgets: React.FC<TopElementItensBudgets> = (props) => {
    return (
        <SectionItensBudget>
            <p className="itemSectionName">Etapa</p>
            <p >Fornecedores Avulsos</p>
            {props.suppliers?.map((supplier) => (
                supplier.typeSupplier === constructor || supplier.typeSupplier === worker ? (
                    <p>{supplier.supplierName}</p>
                ) : (<></>)
            ))}
        </SectionItensBudget>
    )
}

export default TopElementItensBudgets