import React, { useState } from "react";
import { PassageOfInfraFormType } from "../../../../Models/Eletric/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  PassageOfInfra: PassageOfInfraFormType;
}

const PassageOfInfraDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>
        {activity.PassageOfInfra.Conduite?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.Conduite.length > 1 ? (<u>Conduíte {index + 1}</u>) : (<u>Conduíte </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.PassageOfInfra.NovosInterruptores?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.NovosInterruptores.length > 1 ? (<u>Novos interruptores {index + 1}</u>) : (<u>Novos interruptores </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.PassageOfInfra.NovasTomadas?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.NovasTomadas.length > 1 ? (<u>Novas tomadas {index + 1}</u>) : (<u>Novas tomadas </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.PassageOfInfra.NovosPontosDeIluminacao?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.NovosPontosDeIluminacao.length > 1 ? (<u>Novos pontos de iluminação {index + 1}</u>) : (<u>Novos pontos de iluminação </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
      </div>
    </div>
  );
};

export default PassageOfInfraDescription;