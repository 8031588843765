import React, { useEffect, useState } from "react";
import axios from "axios";

import {
    PriceItem,
    ContentInputPrice,
    ListContainerItens,
    ItemContentList,
    TotalContainer,
} from "../../style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"

interface sawmillActivity {
    comprimento: string
    largura: string
    altura: string
    material_tampo: string
    material_estrutura: string
    pintura: string
    adicionais: string
    quantidade: string,
    valor: string
}

interface budgetProps {
    activity: activitiesProps,
    supplier: SupplierProps
    margin: number
}

interface activityProps {
    _id?: string
    projectId?: string;
    stepId?: string;
    budgetId?: [];
    activityName?: string;
    activityStatus?: string,
    activityType?: string,
    stepType?: string

    sawmillActivityTable?: sawmillActivity[]
}

const MesaSerralheriaBudget: React.FC<budgetProps> = (props) => {
    const [activity, setActivity] = useState<activityProps>(props.activity);

    const [sawmillActivityTable, setSawmillActivityTable] = useState<string[]>(['0,00'])

    const [workingDays, setWorkingDays] = useState<number>(0);
    const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
    const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
    const [conditions, setConditions] = useState<string>('')

    useEffect(() => {
        activity.sawmillActivityTable?.forEach(() => {
            setSawmillActivityTable((prev) => [...prev, "0,00"])
        })
    }, [])

    /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
    useEffect(() => {
        setTotalBudgetPayment(sum(sawmillActivityTable))
    }, [sawmillActivityTable])

    /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
    useEffect(() => {
        if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
            setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
        } else {
            setTotalBudgetPayment(sum(sawmillActivityTable))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minimumBudgetValue]);

    const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
        var stateCopy = Array.from(stateVar)
        quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value.replace(',', '.')))) : (
            stateCopy.splice(index, 1, multiply('1', value.replace(',', '.')))
        )
        setStateRef(stateCopy)

        var activityCopy = activity
        if (activityCopy.sawmillActivityTable) {
            activityCopy.sawmillActivityTable[index].valor = stateCopy[index]
            setActivity(activityCopy)
        }
    }

    const submitBudget = () => {
        var sawmillActivityTableMargin: number[] = []

        sawmillActivityTable.forEach((item) => {
            if (item != "0,00") {
                sawmillActivityTableMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
            }
        })
        if (activity.sawmillActivityTable) {
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
                    projectId: activity?.projectId,
                    activityId: activity?._id,
                    stepId: activity.stepId,
                    providerId: props.supplier._id,
                    supplierName: props.supplier.supplierName,
                    typeSupplier: props.supplier.typeSupplier,
                    activityName: activity.activityName,
                    conditions,
                    selectedStartDate: '',
                    workingDays,
                    selectedBudget: false,
                    budget: {
                        mesaSerralheria: sawmillActivityTableMargin
                    },
                    minimumBudgetValue,
                    totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
                })
                .then((res) => {
                    if (res.status === 200) {
                        window.location.reload();
                    }
                });
        }
    };

    return (
        <div>
            <ListContainerItens>
                {activity.sawmillActivityTable?.length ? (
                    <>
                        {activity.sawmillActivityTable.map((element, index) => (
                            <>
                                <div className="upContent">
                                    <p>Mesa de {element.material_estrutura}, {element.pintura} - {element.quantidade} un.</p>
                                    <p>Medidas</p>
                                    <p>Preço unit.</p>
                                    <p className="item">Preço total</p>
                                </div>

                                <ItemContentList key={index}>
                                    <p>Tampo: {element.material_tampo}</p>
                                    <p> {element.altura}m x {element.comprimento}m x {element.largura}m</p>
                                    <ContentInputPrice>
                                        R$:
                                        <PriceItem onChange={(e) => updateState(index, sawmillActivityTable, setSawmillActivityTable, e.target.value, element.quantidade)} />
                                    </ContentInputPrice>
                                    {sawmillActivityTable[index] !== '0' ?
                                        (<p className="item">{sawmillActivityTable[index]}</p>) :
                                        (<p className="item">0,00</p>)
                                    }
                                </ItemContentList>
                            </>
                        ))}
                    </>
                ) : (<></>)}
            </ListContainerItens>

            <TotalContainer>
                Valor total: <p>R${totalBudgetPayment}</p>
            </TotalContainer>

            <FooterBudget
                setWorkingDays={setWorkingDays}
                setMinimunBudgetValue={setMinimunBudgetValue}
                setConditions={setConditions}
                submitBudget={() => submitBudget()}
            />
        </div>
    )
};

export default MesaSerralheriaBudget;