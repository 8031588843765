import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr 5fr 1.5fr;
    grid-gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    .buttonElement{
        margin-top: 1.5rem;
    }
`

export const ContentItem = styled.div`
    display: grid;
    label{
        margin-bottom: 0.5rem;
    }
`

export const Select = styled.select`
    border: 1px solid #E1E1E1;
    background-color: #F1F1F2;
    padding: 0.7rem;
    border-radius: 0.3rem;
    color: #777777;
`