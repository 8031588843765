import React, { useEffect, useState } from "react";
import axios from "axios";
import { UseModalContext } from "../../Context/ModalContext";

import {
  FormContainer,
  HeaderFormContainer,
  ContainerInputForm,
  InputFile,
  InputForm,
  Select,
  Button,
  FooterFormContainer,
} from "../stylesDomElements/htmlStyledElements";

import iconCloseModal from "../../assets/XIcon.svg";
import visiblePasswordIcon from "../../assets/visibility_on.svg";
import noVisiblePasswordIcon from "../../assets/visibility_off.svg";
import iconClose from "../../assets/XIcon.svg";
import iconFacebook from "../../assets/facebook.svg";
import iconInstagram from "../../assets/instagram.svg";
import iconLinkedin from "../../assets/linkedin.svg";
import addIcon from "../../assets/addIcon.svg";
import iconSite from "../../assets/siteIcon.svg";
import { Form, ContainerBoxInput, ContainerListItens, Item } from "./style";

interface Props {
  setFeedbackUserRegister: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewArchitect: React.FC<Props> = ({ setFeedbackUserRegister }) => {
  const { setShowModal } = UseModalContext();

  const [name, setName] = useState<string>("");
  const [logoImage, setlogoImage] = useState<FileList>();

  const [nameOffice, setNameOffice] = useState<string>("");
  const [cpf_cnpj, setCpf_cnpj] = useState<string>("");
  const [rt, setRt] = useState<number>(0);
  const [adressOffice, setAdressOffice] = useState<string>("");
  const [numberAdress, setNumberAdress] = useState<number>(0);
  const [adressComplement, setAdressComplement] = useState<string>("");
  const [cepAdress, setCepAdress] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [site_socialMedia, setSite_socialMedia] = useState<string>("");
  const [socialMediaImage, setSocialMediaImage] = useState<FileList>();
  const [typeOnlineDivulgation, setTypeOnlineDivulgation] =
    useState<string>("");
  const [contactPreference, setContactPreference] = useState<string>("");
  const [primaryColor, setPrimaryColor] = useState("#000000");
  const [socialMediaAtuationList, setSocialMediaAtuationList] = useState<
    Array<{ name: string; socialMedia: string }>
  >([]);
  const [socialMediaListed, setSocialMediaListed] =
    useState<string>("selecione");
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [phoneExists, setPhoneExists] = useState<boolean>(false);

  useEffect(() => {
    if (email !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/verifyIfEmailArchitectExists`,
          {
            email: email,
          }
        )
        .then((res) => {
          setEmailExists(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (phone !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/verifyIfPhoneArchitectExists`,
          {
            phone: phone,
          }
        )
        .then((res) => {
          setPhoneExists(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [email, phone]);

  useEffect(() => {
    cepAdress.replace(/\D/g, "");
    axios.get(`https://viacep.com.br/ws/${cepAdress}/json/`).then((res) => {
      if (!res.data.erro === true) {
        setAdressOffice(
          `${res.data.logradouro}, ${res.data.bairro} - ${res.data.localidade}`
        );
        setAdressComplement(res.data.complemento);
        console.log(res.data);
      }
    });
  }, [cepAdress]);

  const [password, setPassword] = useState<string>("");

  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const setFileImageProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && e.target.files.item.name ? (
      setlogoImage(e.target.files)
    ) : (
      <></>
    );
  };

  const addSocialMediaList = () => {
    setSocialMediaAtuationList((prev) => [
      ...prev,
      {
        name: site_socialMedia,
        socialMedia: socialMediaListed,
      },
    ]);
    setSocialMediaListed("selecione");
  };

  const dropItem = (index: number) => {
    let SocialMediaCopy = socialMediaAtuationList;
    SocialMediaCopy.splice(index, 1);
    setSocialMediaAtuationList([...SocialMediaCopy]);
  };

  const submit = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newArchitect`, {
        name,
        logoImage:
          "https://marcas-logos.net/wp-content/plugins/accelerated-mobile-pages/images/SD-default-image.png",
        nameOffice,
        cpf_cnpj,
        rt,
        adressOffice,
        numberAdress,
        adressComplement,
        cepAdress,
        phone,
        email,
        site_socialMedia,
        typeOnlineDivulgation,
        contactPreference,
        primaryColor,
        password: password,
      })
      .then((res) => {
        console.log(res);
        setFeedbackUserRegister(true);
        setShowModal?.();
      });
    if (logoImage !== undefined) {
      const imageArchive = new FormData();
      imageArchive.append("file", logoImage[0]);
      imageArchive.append("cpf_cnpj", cpf_cnpj);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/uploadImageOffice`,
          imageArchive
        )
        .then((res) => {
          console.log(res);
        });
    }
  };

  useEffect(() => {
    var inputPassword = document.getElementById("password");
    if (visiblePassword) inputPassword?.setAttribute("type", "text");
    else inputPassword?.setAttribute("type", "password");
  }, [visiblePassword]);

  return (
    <div>
      <FormContainer>
        <HeaderFormContainer>
          <p>Seu cadastro</p>
          <img
            src={iconCloseModal}
            alt="Icone de fechar"
            onClick={() => setShowModal?.()}
          />
        </HeaderFormContainer>

        <Form className="form">
          <div className="grid1">
            <ContainerInputForm>
              <label htmlFor="name">Seu nome</label>
              <InputForm
                value={name}
                id="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </ContainerInputForm>

            <ContainerInputForm>
              <label htmlFor="name">Logo do escritório (JPG ou PNG)</label>
              <InputFile>
                {logoImage?.item(0)?.name ? (
                  <p>{logoImage.item(0)?.name}</p>
                ) : (
                  <p>Faça o upload do arquivo</p>
                )}
                <label htmlFor="img">Selecione o arquivo</label>
                <InputForm
                  name="img"
                  id="img"
                  type="file"
                  onChange={(event) => setFileImageProfile(event)}
                />
              </InputFile>
            </ContainerInputForm>
          </div>

          <div className="grid2">
            <ContainerInputForm>
              <label htmlFor="nameOffice">Nome do escritório</label>
              <InputForm
                value={nameOffice}
                id="nameOffice"
                type="text"
                onChange={(e) => setNameOffice(e.target.value)}
              />
            </ContainerInputForm>

            <ContainerInputForm>
              <label htmlFor="cpf-cnpj">CPF/CNPJ</label>
              <InputForm
                value={cpf_cnpj}
                id="cpf-cnpj"
                type="text"
                minLength={8}
                maxLength={14}
                onChange={(e) => setCpf_cnpj(e.target.value)}
              />
            </ContainerInputForm>

            <ContainerInputForm>
              <label htmlFor="rt">Reserva técnica (RT)</label>
              <div className="rtInput">
                <input
                  value={rt}
                  id="rt"
                  type="number"
                  onChange={(e) => setRt(Number(e.target.value))}
                />
                %
              </div>
            </ContainerInputForm>
          </div>

          <div className="grid3">
            <ContainerInputForm>
              <label htmlFor="cep">Cep</label>
              <InputForm
                value={cepAdress}
                id="cep"
                type="text"
                onChange={(e) => setCepAdress(e.target.value)}
              />
            </ContainerInputForm>
            <ContainerInputForm>
              <label htmlFor="officeAdress"> Endereço do escritório</label>
              <InputForm
                id="officeAdress"
                type="text"
                name="officeAdress"
                value={adressOffice}
                onChange={(e) => setAdressOffice(e.target.value)}
              />
            </ContainerInputForm>

            <ContainerInputForm>
              <label htmlFor="number">Numero</label>
              <InputForm
                value={numberAdress}
                id="number"
                type="number"
                onChange={(e) => setNumberAdress(parseInt(e.target.value))}
              />
            </ContainerInputForm>

            <ContainerInputForm>
              <label htmlFor="aditionalAdress">Complemento</label>
              <InputForm
                id="aditionalAdress"
                type="text"
                value={adressComplement}
                onChange={(e) => setAdressComplement(e.target.value)}
              />
            </ContainerInputForm>
          </div>

          <div className="grid4">
            <ContainerInputForm>
              {phoneExists ? (
                <label className="alert">Telefone já cadastrado</label>
              ) : (
                <label htmlFor="phone">Telefone</label>
              )}
              <InputForm
                id="phone"
                value={phone}
                type="text"
                onChange={(e) => setPhone(e.target.value)}
              />
            </ContainerInputForm>

            <ContainerInputForm>
              {emailExists ? (
                <label className="alert">Email já cadastrado</label>
              ) : (
                <label htmlFor="mail">Email</label>
              )}
              <InputForm
                id="mail"
                value={email}
                type="text"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </ContainerInputForm>

            <ContainerBoxInput>
              <label htmlFor="siteSocialNetwork">Site ou rede social</label>
              <div className="gridContent">
                <InputForm
                  id="siteSocialMedia"
                  type="text"
                  value={site_socialMedia}
                  required
                  onChange={(e) => setSite_socialMedia(e.target.value)}
                />
                <Select
                  name="bank_select"
                  id="socialMediaSelection"
                  value={socialMediaListed}
                  onChange={(e) => setSocialMediaListed(e.target.value)}
                >
                  <option value="Selecione">Selecione</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Linkedin">Linkedin</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Site">Site</option>
                </Select>
                <Button type="submit" onClick={() => addSocialMediaList()}>
                  <img src={addIcon} alt="Icone de adicionar" />
                  Add
                </Button>
              </div>
            </ContainerBoxInput>
          </div>

          <div className="grid5">
            <ContainerListItens>
              {socialMediaAtuationList.map((item, index) => {
                const getIcon = (type: string) => {
                  switch (type) {
                    case "Facebook":
                      return iconFacebook;
                    case "Instagram":
                      return iconInstagram;
                    case "Linkedin":
                      return iconLinkedin;
                    case "Site":
                      return iconSite;
                    default:
                      return iconSite;
                  }
                };

                return (
                  <div key={index}>
                    <Item key={index}>
                      <img
                        id="socialMediaIcon"
                        src={getIcon(item.socialMedia)}
                        alt="icone de rede social"
                      />
                      {item.name}
                      <img
                        src={iconClose}
                        alt="icone de fechar"
                        onClick={() => dropItem(index)}
                      />
                    </Item>
                  </div>
                );
              })}
            </ContainerListItens>
          </div>

          <div className="grid6">
            <ContainerInputForm>
              <label htmlFor="preferenceContact">Contato de preferência</label>

              <Select
                className="selectElement"
                value={contactPreference}
                onChange={(e) => setContactPreference(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="email">Email</option>
                <option value="sms">Sms</option>
                <option value="whatsapp">Whatsapp</option>
              </Select>
            </ContainerInputForm>

            <div className="information">
              <br />
              <br />
              Não enviaremos mensagens promocionais e afins.
            </div>

            <ContainerInputForm>
              <label htmlFor="primaryColor">Cor primária</label>
              <div className="containerBoxColor">
                <input
                  type="color"
                  id="primaryColor"
                  placeholder="#000000"
                  value={primaryColor}
                  onChange={(e) => setPrimaryColor(e.target.value)}
                />
                {primaryColor}
              </div>
            </ContainerInputForm>

            <div className="information">
              {" "}
              <br /> <br /> Essa será a cor principal dos elementos do seu
              board.
            </div>
          </div>

          <FooterFormContainer>
            <ContainerBoxInput>
              <label htmlFor="password">Senha (minímo 8 caracteres)</label>

              <div>
                <InputForm
                  id="password"
                  type="password"
                  value={password}
                  minLength={8}
                  autoComplete="new-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                {visiblePassword ? (
                  <img
                    className="imgEye"
                    onClick={() => setVisiblePassword(!visiblePassword)}
                    src={visiblePasswordIcon}
                    alt="icone de exibir senha"
                  />
                ) : (
                  <img
                    className="imgEye"
                    onClick={() => setVisiblePassword(!visiblePassword)}
                    src={noVisiblePasswordIcon}
                    alt="icone de ocultar senha"
                  />
                )}
              </div>
            </ContainerBoxInput>
            {!emailExists && !phoneExists && (
              <Button className="buttonRegister" onClick={() => submit()}>
                Cadastrar
              </Button>
            )}
          </FooterFormContainer>
        </Form>
      </FormContainer>
    </div>
  );
};

export default NewArchitect;
