import React, { useEffect } from 'react'

import { UseMasonaryActivityContext } from "../../../Context/ActivitiesContext/Mansonary/MansonaryContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"
import { textButton } from '../script/textButtonSubmit'
import { RequestApi } from "../RequestApi"

import StructureStepForm from '../../StepsForm/Masonry/Structure'
import ClosureStepForm from '../../StepsForm/Masonry/Closure'
import FlagstoneStepForm from '../../StepsForm/Masonry/Flagstone'
import FloorStepForm from '../../StepsForm/Masonry/Floor'
import FundationStepForm from '../../StepsForm/Masonry/Fundation'
import RefraimingStepForm from '../../StepsForm/Masonry/Refraiming'
import SoculoStepForm from '../../StepsForm/Masonry/Soculo'

import { Container } from "../style"
import StairwaysStepForm from '../../StepsForm/Masonry/Stairways'
import MaterialsMasonaryStepForm from '../../StepsForm/Masonry/Materials'
import NicheStepForm from '../../StepsForm/Masonry/Nicho'

const MasonaryModifyActivities: React.FC<activityProps> = (props) => {
    const { masonaryActivities, setActivity, setIndexMasonaryActivitySelected } = UseMasonaryActivityContext()
    const { setModalNameHeader } = UseModalContext()
    console.log('modify alvenaria')
    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexMasonaryActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Estrutura" ? (<StructureStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Fechamento" ? (<ClosureStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Laje" ? (<FlagstoneStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Piso" ? (<FloorStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Fundacao" ? (<FundationStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Requadramento de vao" ? (<RefraimingStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Soculo em alvenaria" ? (<SoculoStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Escadas" ? (<StairwaysStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Nicho" ? (<NicheStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsMasonaryStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(masonaryActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(masonaryActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default MasonaryModifyActivities