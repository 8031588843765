import React, { useEffect, useState } from "react";
import axios from "axios";
import { UseModalContext } from "../../Context/ModalContext";

import {
  ContainerModal,
  FormContainer,
  HeaderFormContainer,
  FooterFormContainer,
} from "../stylesDomElements/htmlStyledElements";

import { CentralContent, Button, ConfirmationMessage } from "./style";

import iconCloseModal from "../../assets/XIcon.svg";
import { MessageConfirm } from "../../pages/PasswordResetValidation/style";

const ModalForgetPassword: React.FC = () => {
  const { showModal, setShowModal } = UseModalContext();
  const [forgetPassword, setForgetPassword] = useState("");
  const [nameButtonSend, setNameButtonSend] = useState<string>('Enviar')


  const [emailToResetPassword, setEmailToResetPassword] = useState<string>('');
  const [confirmationMessageEmailSend, setConfirmationMessageEmailSend] = useState<string>('')

  const emailValidate = () => {
    axios
      .post(`https://api.arquitetool.com.br/communication/sendEmailResetPassword`, {
        emailToResetPassword,
      })
      .then((res) => {
        console.log(res)
        setNameButtonSend('Enviado')
        setConfirmationMessageEmailSend('Código enviado, verifique sua caixa de entrada')
      })
      .catch((err) => {
        console.log(err)
        setNameButtonSend('Enviar')
        setConfirmationMessageEmailSend('Email inválido')
      });
  }

  useEffect(() => {
    if (showModal === false) {
      setForgetPassword("");
    }
  }, [showModal]);



  return (
    <>
      {showModal ? (
        <ContainerModal>
          <>
            {forgetPassword === "" ? (
              <FormContainer>
                <HeaderFormContainer>
                  <p>Esqueci minha senha</p>
                  <img
                    src={iconCloseModal}
                    alt="icone de fechar"
                    onClick={() => setShowModal?.()}
                  />
                </HeaderFormContainer>

                <CentralContent>
                  <p>Enviaremos um email com instruções de como redefinir sua senha.</p>

                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@exemplo.com"
                    value={emailToResetPassword}
                    onChange={(e) => setEmailToResetPassword(e.target.value)}
                  />
                  <ConfirmationMessage>{confirmationMessageEmailSend}</ConfirmationMessage>
                </CentralContent>

                <FooterFormContainer>
                  <div></div>
                  <Button className="buttonRegister" type="submit" onClick={() => emailValidate()}>{nameButtonSend}</Button>
                </FooterFormContainer>
              </FormContainer>
            ) : (
              <></>
            )}
          </>
        </ContainerModal>
      ) : null}
    </>
  );
};

export default ModalForgetPassword;
