import React from "react";

import CeramicFloorCoveringDescription from "./CeramicFloorCovering";
import BaseBoardDescription from "./BaseBoard";
import FlooringDescription from "./Flooring";
import StoneFloorCoveringDescription from "./StoneFloorCovering";
import VinylFloorCoveringDescription from "./VinylFloorCovering";
import WallFloorCoveringDescription from "./WallCovering";
import WoodCoveringDescription from "./WoodCovering";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const FloorsAndCoatingDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Rodape" ? (<BaseBoardDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Revestimento de parede" ? (<WallFloorCoveringDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Revestimento de piso ceramico" ? (<CeramicFloorCoveringDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Assoalho" ? (<FlooringDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Revestimento de piso - Pedras" ? (<StoneFloorCoveringDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Revestimento de piso vinilico" ? (<VinylFloorCoveringDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Revestimento de madeira" ? (<WoodCoveringDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default FloorsAndCoatingDescription