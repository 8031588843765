import styled from "styled-components";

export const Container = styled.div`
  display: grid;

  @media (max-width: 820px) {
    width: 78.1em;
  }

  @media (max-width: 480px) {
    height: 35rem;
    width: 152em;

    img {
      width: 3rem;
      display: none;
    }

    .logo {
      display: none;
    }
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding: 1rem;

  h2 {
    @media (max-width: 480px) {
      font-size: 140px;
    }
  }

  .retrunIconHeaderContainer {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .ProfileNotificationContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    align-items: center;
    margin-right: 1.5rem;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  img {
    margin-right: 1.5rem;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProjectName = styled.h2`
  display: flex;
  justify-content: center;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 50px;
  }
`;

export const ProfileImg = styled.div`
  img {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
  }
`;

export const Notification = styled.div`
  cursor: pointer;
`;

export const Icons = styled.div`
  img {
    max-width: 2rem;
    cursor: pointer;
    margin-left: 0.4rem;
  }

  @media (max-width: 480px) {
    img {
      max-width: 5rem;
    }
  }
`;
