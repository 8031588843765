import axios from "axios";

import { sendActivityFile } from "./script/requestActivityFile"

export const RequestApi = (bodyRequest: any, request: string) => {
    if (request === "activityReplace") {
        if (bodyRequest[0].activityType === "Marcenaria") {
            sendActivityFile(bodyRequest, 'activityReplace')
        } else {
            axios.put(`${process.env.REACT_APP_BASE_URL}/activityReplace`, { bodyRequest: bodyRequest[0] }).then(res => {
                if (res.status === 200)
                    window.location.reload()
            })
        }
        if (bodyRequest[0].activityType === "Escadas") {
            sendActivityFile(bodyRequest, 'activityReplace')
        } else {
            axios.put(`${process.env.REACT_APP_BASE_URL}/activityReplace`, { bodyRequest: bodyRequest[0] }).then(res => {
                if (res.status === 200)
                    window.location.reload()
            })
        }

    } else if (request === "sendCotations") {
        if (bodyRequest[0].activityType === "Marcenaria") {
            sendActivityFile(bodyRequest, "sendCotations")
        } else {
            axios.post(`${process.env.REACT_APP_BASE_URL}/sendCotations`, bodyRequest[0]).then(res => {
                if (res.status === 200)
                    window.location.reload()
            })
        }

        if (bodyRequest[0].activityType === "Escadas") {
            sendActivityFile(bodyRequest, "sendCotations")
        } else {
            axios.post(`${process.env.REACT_APP_BASE_URL}/sendCotations`, bodyRequest[0]).then(res => {
                if (res.status === 200)
                    window.location.reload()
            })
        }
    }
}

