import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../style"
import { GreenDestack } from "../../style"
import { ForroFormType } from "../../../../Models/Lining/type";


interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface attributeType {
    attributes: {
        itemName: string,
        unit_of_measurement: string,
        value: string
    }[]
}

interface activityProps {
    selectedBudget?: boolean,
    Forro?: ForroFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        Forro?: string[]
        Sanca?: string[]
        Cortineiro?: string[]
        Tabica?: string[]
    }
}

export const ForroComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: string, activityId?: string, itemType?: any) => {
        if (!totalItem) {
            return <p>R$0,00</p>
        }
        return <p>R${Number(totalItem).toFixed(2)}</p>
    }

    console.log(props.suppliers)

    return (
        <ContainerItensBudget>
            <>
                {activity.Forro?.Forro.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Forro</p>
                            <p>{"Medidas"}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Forro?.Forro?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                        {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                    </p>
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Forro ? renderItemBudget(budget.budget?.Forro[index], budget.activityId, 'Forro') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Forro ? renderItemBudget(budget.budget?.Forro[index], budget.activityId, 'Forro') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : (null)}
                {activity.Forro?.Sanca.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Sanca</p>
                            <p>{"Medidas"}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Forro?.Sanca?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Sanca ? renderItemBudget(budget.budget?.Sanca[index], budget.activityId, 'Sanca') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Sanca ? renderItemBudget(budget.budget?.Sanca[index], budget.activityId, 'Sanca') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Forro?.Cortineiro.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Cortineiro</p>
                            <p>{"Medidas"}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Forro?.Cortineiro?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Cortineiro ? renderItemBudget(budget.budget?.Cortineiro[index], budget.activityId, 'Cortineiro') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Cortineiro ? renderItemBudget(budget.budget?.Cortineiro[index], budget.activityId, 'Cortineiro') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Forro?.Tabica.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Tabica</p>
                            <p>{"Medidas"}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Forro?.Tabica?.map((item, index) => (
                            <div key={index}>
                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Tabica ? renderItemBudget(budget.budget?.Tabica[index], budget.activityId, 'Tabica') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Tabica ? renderItemBudget(budget.budget?.Tabica[index], budget.activityId, 'Tabica') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>
                            </div>
                        ))}
                    </>
                ) : (null)}
            </>
        </ContainerItensBudget>
    )
}

export default ForroComparartive