import React, { useState, useEffect } from "react";

import { UseHydraulicContext } from "../../../../Context/ActivitiesContext/Hydraulic/HidraulicContext";
import { attributeType } from "../../../../Models/attributeType";
import { HotWaterFormType } from "../../../../Models/Hydraulic/type";
import { HotWater } from "../../../../Models/Hydraulic/HotWaterModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const HotWaterStepForm: React.FC<stepFormProps> = (props) => {
  const { hydraulicActivities, indexHydraulicActivitySelected, editHydraulicActivity } = UseHydraulicContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<HotWaterFormType>(HotWater)

  useEffect(() => {
    if (hydraulicActivities.length > 0) {
      let hydraulicActivityCopy = hydraulicActivities[indexHydraulicActivitySelected]
      hydraulicActivityCopy.activityType = 'Agua quente'
      editHydraulicActivity?.(indexHydraulicActivitySelected, hydraulicActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHydraulicActivitySelected])

  useEffect(() => {
    if (hydraulicActivities.length > 0) {
      setItensFormList(hydraulicActivities[indexHydraulicActivitySelected].HotWater)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHydraulicActivitySelected, hydraulicActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemEmendas(0)
      removeItemPontoDeAguaQuente(0)
      removeItemPontoDeRegistro(0)
      removeItemRalo(0)
      removeItemRetirarPontoDeAguaQuente(0)
      removeItemTubulacao(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Ponto de agua quente") {
      addPontoDeAguaQuente({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Retirar ponto de agua quente") {
      addRetirarPontoDeAguaQuente({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Ponto de registro") {
      addPontoDeRegistro(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Tubulacao") {
      addTubulacao(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm linear',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Emendas") {
      addEmendas(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Ralo") {
      addRalo(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    }
  }

  const addRalo = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo.push(object)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addRetirarPontoDeAguaQuente = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaQuente.push(object)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addPontoDeRegistro = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro.push(object)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addTubulacao = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao.push(object)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addEmendas = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas.push(object)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addPontoDeAguaQuente = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaQuente.push(object)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemRalo = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo.splice(index, 1)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemRetirarPontoDeAguaQuente = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaQuente.splice(index, 1)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemPontoDeRegistro = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro.splice(index, 1)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemTubulacao = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao.splice(index, 1)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemEmendas = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas.splice(index, 1)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemPontoDeAguaQuente = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaQuente.splice(index, 1)
    hydraulicActivitiesCopy[0].HotWater = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRaloValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRaloAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Ralo[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRetirarPontoDeAguaQuenteValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaQuente[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRetirarPontoDeAguaQuenteAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeAguaQuente[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeRegistroValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeRegistroAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeRegistro[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editEmendasValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editEmendasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeAguaQuenteValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaQuente[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeAguaQuenteAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.PontoDeAguaQuente[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Ralo.length ? (
        <div>
          <p>Ralo</p>
          {itensFormList.Ralo.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRaloAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRaloValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRaloValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRaloAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRaloValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m linear']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRalo(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


      {itensFormList.PontoDeRegistro.length ? (
        <div>
          <p>Ponto de registro</p>
          {itensFormList.PontoDeRegistro.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeRegistroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeRegistroValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeRegistroValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeRegistroAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeRegistroValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeRegistro(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.RetirarPontoDeAguaQuente.length ? (
        <div>
          <p>Retirar ponto de água quente</p>
          {itensFormList.RetirarPontoDeAguaQuente.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRetirarPontoDeAguaQuenteAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRetirarPontoDeAguaQuenteValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRetirarPontoDeAguaQuenteValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRetirarPontoDeAguaQuenteAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRetirarPontoDeAguaQuenteValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRetirarPontoDeAguaQuente(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


      {itensFormList.Tubulacao.length ? (
        <div>
          <p>Tubulação</p>
          {itensFormList.Tubulacao.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTubulacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTubulacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editTubulacaoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemTubulacao(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Emendas.length ? (
        <div>
          <p>Emendas</p>
          {itensFormList.Emendas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmendasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmendasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemEmendas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PontoDeAguaQuente.length ? (
        <div>
          <p>Ponto de água quente</p>
          {itensFormList.PontoDeAguaQuente.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeAguaQuenteAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeAguaQuenteValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeAguaQuenteValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeAguaQuenteAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeAguaQuenteValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeAguaQuente(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

    </div>
  );
};

export default HotWaterStepForm;
