import React, { useEffect } from 'react'

import { UseFloorsAndCoveringsActivityContext } from "../../../Context/ActivitiesContext/FloorsAndCoverings/FloorsAndCoveringsContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"

import { RequestApi } from "../RequestApi"

import CeramicFloorCoveringStepForm from '../../StepsForm/FloorsAndCoverings/CeramicFloorCovering'
import VinylFloorCoveringStepForm from '../../StepsForm/FloorsAndCoverings/VinylFloorCovering'
import StoneFloorCoveringStepForm from '../../StepsForm/FloorsAndCoverings/StoneFloorCovering'
import BaseBoardStepForm from '../../StepsForm/FloorsAndCoverings/BaseBoard'
import WoodCoveringStepForm from '../../StepsForm/FloorsAndCoverings/WoodCovering'
import WallCoveringStepForm from '../../StepsForm/FloorsAndCoverings/WallCovering'
import FlooringStepForm from '../../StepsForm/FloorsAndCoverings/Flooring'

import { textButton } from '../script/textButtonSubmit'
import { Container } from "../style"
import MaterialsFloorsAndCoveringsStepForm from '../../StepsForm/FloorsAndCoverings/Materials'


const FloorsAndCoatingModifyActivities: React.FC<activityProps> = (props) => {
    const { floorsAndCoveringsActivities, setActivity, setIndexFloorsAndCoveringsActivitySelected } = UseFloorsAndCoveringsActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        console.log(props.activityType)
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexFloorsAndCoveringsActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Revestimento de madeira" ? (<WoodCoveringStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Revestimento de piso ceramico" ? (<CeramicFloorCoveringStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Revestimento de piso vinilico" ? (<VinylFloorCoveringStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Revestimento de piso - Pedras" ? (<StoneFloorCoveringStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Rodape" ? (<BaseBoardStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Revestimento de parede" ? (<WallCoveringStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Assoalho" ? (<FlooringStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsFloorsAndCoveringsStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(floorsAndCoveringsActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(floorsAndCoveringsActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default FloorsAndCoatingModifyActivities