import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
  ListContainerItensHotWater,
  ItemContentListHotWater,
  ListContainerItensTubulacao,
  ItemContentListTubulacao,
} from "./style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { HotWaterFormType } from "../../../../Models/Hydraulic/type";

import FooterBudget from "../../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  HotWater: HotWaterFormType
}

const AguaQuenteBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [pontoDeAguaQuente, setPontoDeAguaQuente] = useState<string[]>(["0,00"])
  const [retirarPontoDeAguaQuente, setRetirarPontoDeAguaQuente] = useState<string[]>(["0,00"])
  const [pontoDeRegistro, setPontoDeRegistro] = useState<string[]>(["0,00"])
  const [tubulacao, setTubulacao] = useState<string[]>(["0,00"])
  const [emendas, setEmendas] = useState<string[]>(["0,00"])
  const [ralo, setRalo] = useState<string[]>(["0,00"])

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.HotWater.PontoDeAguaQuente.forEach(() => {
      setPontoDeAguaQuente((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.HotWater.RetirarPontoDeAguaQuente?.forEach(() => {
      setRetirarPontoDeAguaQuente((prev) => [...prev, "0,00"])
    })

    activity.HotWater.PontoDeRegistro?.forEach(() => {
      setPontoDeRegistro((prev) => [...prev, "0,00"])
    })

    activity.HotWater.Tubulacao?.forEach(() => {
      setTubulacao((prev) => [...prev, "0,00"])
    })

    activity.HotWater.Emendas?.forEach(() => {
      setEmendas((prev) => [...prev, "0,00"])
    })

    activity.HotWater.Ralo?.forEach(() => {
      setRalo((prev) => [...prev, "0,00"])
    })
  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const pontoDeAguaQuenteTotal = sum(pontoDeAguaQuente)
    const retirarPontoDeAguaQuenteTotal = sum(retirarPontoDeAguaQuente)
    const pontoDeRegistroTotal = sum(pontoDeRegistro)
    const tubulacaoTotal = sum(tubulacao)
    const emendasTotal = sum(emendas)
    const raloTotal = sum(ralo)

    setTotalBudgetPayment(sum([pontoDeAguaQuenteTotal, retirarPontoDeAguaQuenteTotal, pontoDeRegistroTotal, tubulacaoTotal, emendasTotal, raloTotal]))
  }, [pontoDeAguaQuente, retirarPontoDeAguaQuente, pontoDeRegistro, tubulacao, emendas, ralo])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const pontoDeAguaQuenteTotal = sum(pontoDeAguaQuente)
  const retirarPontoDeAguaQuenteTotal = sum(retirarPontoDeAguaQuente)
  const pontoDeRegistroTotal = sum(pontoDeRegistro)
  const tubulacaoTotal = sum(tubulacao)
  const emendasTotal = sum(emendas)
  const raloTotal = sum(ralo)




  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([pontoDeAguaQuenteTotal, retirarPontoDeAguaQuenteTotal, pontoDeRegistroTotal, tubulacaoTotal, emendasTotal, raloTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const pontoDeAguaQuenteMargin: number[] = []
    const retirarPontoDeAguaQuenteMargin: number[] = []
    const pontoDeRegistroMargin: number[] = []
    const tubulacaoMargin: number[] = []
    const emendasMargin: number[] = []
    const raloMargin: number[] = []

    pontoDeAguaQuente.forEach((item) => {
      if (item != "0,00") {
        pontoDeAguaQuenteMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    retirarPontoDeAguaQuente.forEach((item) => {
      if (item != "0,00") {
        retirarPontoDeAguaQuenteMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    pontoDeRegistro.forEach((item) => {
      if (item != "0,00") {
        pontoDeRegistroMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    tubulacao.forEach((item) => {
      if (item != "0,00") {
        tubulacaoMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    emendas.forEach((item) => {
      if (item != "0,00") {
        emendasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    ralo.forEach((item) => {
      if (item != "0,00") {
        raloMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          PontoDeAguaQuente: pontoDeAguaQuenteMargin,
          RetirarPontoDeAguaQuente: retirarPontoDeAguaQuenteMargin,
          PontoDeRegistro: pontoDeRegistroMargin,
          Tubulacao: tubulacaoMargin,
          Emendas: emendasMargin,
          Ralo: raloMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensHotWater>

        {activity.HotWater.PontoDeAguaQuente?.map((item, index) => (
          <div key={index}>
            {activity.HotWater.PontoDeAguaQuente.length > 1 ? (<P>Ponto de água quente {index + 1}</P>) : (<P>Ponto de água quente</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListHotWater >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pontoDeAguaQuente, setPontoDeAguaQuente, e.target.value)} />
              </ContentInputPrice>
              {pontoDeAguaQuente && pontoDeAguaQuente[index] !== '0' ?
                (<p className="item">{pontoDeAguaQuente[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListHotWater>
          </div>
        ))}

      </ListContainerItensHotWater>

      <ListContainerItensHotWater>
        {activity.HotWater.RetirarPontoDeAguaQuente?.map((item, index) => (
          <div key={index}>
            {activity.HotWater.RetirarPontoDeAguaQuente.length > 1 ? (<P>Retirar ponto de água quente {index + 1}</P>) : (<P>Retirar ponto de água quente</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListHotWater >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, retirarPontoDeAguaQuente, setRetirarPontoDeAguaQuente, e.target.value)} />
              </ContentInputPrice>
              {retirarPontoDeAguaQuente && retirarPontoDeAguaQuente[index] !== '0' ?
                (<p className="item">{retirarPontoDeAguaQuente[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListHotWater>
          </div>
        ))}
      </ListContainerItensHotWater>

      <ListContainerItensHotWater>
        {activity.HotWater.PontoDeRegistro?.map((item, index) => (
          <div key={index}>
            {activity.HotWater.PontoDeRegistro.length > 1 ? (<P>Ponto de registro {index + 1}</P>) : (<P>Ponto de registro</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListHotWater >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pontoDeRegistro, setPontoDeRegistro, e.target.value)} />
              </ContentInputPrice>
              {pontoDeRegistro && pontoDeRegistro[index] !== '0' ?
                (<p className="item">{pontoDeRegistro[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListHotWater>
          </div>
        ))}

      </ListContainerItensHotWater>

      <ListContainerItensTubulacao>
        {activity.HotWater.Tubulacao?.map((item, index) => (
          <div key={index}>
            {activity.HotWater.Tubulacao.length > 1 ? (<P>Tubulação {index + 1}</P>) : (<P>Tubulação</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListTubulacao >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, tubulacao, setTubulacao, e.target.value)} />
              </ContentInputPrice>
              {tubulacao && tubulacao[index] !== '0' ?
                (<p className="item">{tubulacao[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListTubulacao>
          </div>
        ))}
      </ListContainerItensTubulacao>

      <ListContainerItensTubulacao>
        {activity.HotWater.Emendas?.map((item, index) => (
          <div key={index}>
            {activity.HotWater.Emendas.length > 1 ? (<P>Emendas {index + 1}</P>) : (<P>Emendas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListTubulacao >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, emendas, setEmendas, e.target.value)} />
              </ContentInputPrice>
              {emendas && emendas[index] !== '0' ?
                (<p className="item">{emendas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListTubulacao>
          </div>
        ))}

      </ListContainerItensTubulacao>
      <ListContainerItensTubulacao>
        {activity.HotWater.Ralo?.map((item, index) => (
          <div key={index}>
            {activity.HotWater.Ralo.length > 1 ? (<P>Ralo {index + 1}</P>) : (<P>Ralo</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListTubulacao >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, ralo, setRalo, e.target.value)} />
              </ContentInputPrice>
              {ralo && ralo[index] !== '0' ?
                (<p className="item">{ralo[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListTubulacao>
          </div>
        ))}

      </ListContainerItensTubulacao>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default AguaQuenteBudget;