export const Furniture = {
    Mobiliario: [
        {
            attributes: [
                {
                    itemName: 'Item',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Link',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Fornecedor',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}