import React, { useState, useEffect } from "react";
import axios from "axios";

import { UseUserContext } from "../../Context/UserContext";

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import { Button } from "../../components/stylesDomElements/htmlStyledElements";
import { Component, Container, ContainerInput, Iframe, Page } from "./style";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import AcessMenu from "../../components/AcessMenu";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";

interface constructinEnbededLinkProps {
  constructinEnbededLink: string;
}

const FollowUp: React.FC = () => {
  const { acessLink, editPermission } = UseAuthAcessContext();
  const { projectSelected, setprojectSelected } = UseUserContext();
  const [constructinEnbededLink, setConstructinEnbededLink] =
    useState<string>();
  const [project, setProject] = useState<constructinEnbededLinkProps>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    } else {
      const projectId = localStorage.getItem("projectId");
      if (projectId && typeof setprojectSelected !== "undefined") {
        setprojectSelected(projectId);
      }
    }
  }, []);

  useEffect(() => {
    if (editPermission === "Editor" || editPermission === "") {
      setIsEditAuthorized(true);
    } else if (editPermission === "Visualizacao") {
      setIsEditAuthorized(false);
    }
  });

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
          setLoading(false);
        });
    }
  }, [projectSelected, acessLink]);

  const sendLinkConstructin = () => {
    if (constructinEnbededLink) {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/updateConstructinEnbededLink/${projectSelected}`,
          {
            constructinEnbededLink,
          }
        )
        .then((res) => {
          if (res.status === 200) window.location.reload();
        });
    }
  };
  return (
    <Page>
      <Header />

      {!acessLink ? (
        <ItensMenu optionSelected="Acompanhamento" />
      ) : (
        <AcessMenu />
      )}

      <Component>
        {loading ? (
          <></>
        ) : !project?.constructinEnbededLink ? (
          <Container>
            {isEditAuthorized ? (
              <>
                <label htmlFor="iframeText">Insira seu link ConstructIN</label>
                <div className="flexElements">
                  <ContainerInput>
                    <input
                      id="iframeText"
                      type="text"
                      placeholder="Digite aqui o link"
                      onChange={(e) =>
                        setConstructinEnbededLink(e.target.value)
                      }
                    />
                  </ContainerInput>
                  <Button
                    className="button"
                    onClick={() => sendLinkConstructin()}
                  >
                    <p>Cadastrar</p>
                  </Button>
                </div>
              </>
            ) : (
              <label>Não há acompanhamento em 3D até o momento</label>
            )}
          </Container>
        ) : (
          <>
            {window.innerWidth < 480 ? (
              <div className="toCellPhoneVisualization">
                <br />
                <br />
                <br />
                <Button>
                  <a
                    href={project?.constructinEnbededLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Clique para explorar a obra em 3D!
                  </a>
                </Button>
              </div>
            ) : (
              <Iframe src={project?.constructinEnbededLink}></Iframe>
            )}
          </>
        )}
      </Component>
    </Page>
  );
};

export default FollowUp;
