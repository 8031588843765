import React from "react";

import ArchitecturalProjectDescription from "./ArchitecturalProject";
import StructuralProjectDescription from "./StructuralProject";
import EletricProjectDescription from "./EletricProject";
import AirConditionerProjectDescription from "./AirConditionerProject";
import HydrosanitaryProjectDescription from "./HydrosanitaryProject";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const ProjectStepDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Projeto estrutural" ? (<StructuralProjectDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Projeto de ar condicionado" ? (<AirConditionerProjectDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Projeto arquitetonico" ? (<ArchitecturalProjectDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Projeto eletrico" ? (<EletricProjectDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Projeto hidrossanitario" ? (<HydrosanitaryProjectDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default ProjectStepDescription