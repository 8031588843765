import React, { useState, useEffect } from "react";
import { UseAirConditionerActivityContext } from "../../../../Context/ActivitiesContext/AirConditioner/AirConditionerContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { CheckBoxContainer, ContainerCheckBox, ContainerGrid } from "./style"

import { AirConditionerFormType } from "../../../../Models/AirConditioner/type";
import { attributeType } from "../../../../Models/attributeType";
import { AirConditioner } from "../../../../Models/AirConditioner/AirConditionerModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const AirConditionerStepForm: React.FC<stepFormProps> = (props) => {
  const { airConditionerActivities, editAirConditionerActivity, indexAirConditionerActivitySelected } = UseAirConditionerActivityContext()

  const [assignment, setAssignment] = useState<string>('')
  const [structureExist, setStructureExist] = useState<string>('Não')
  const [itensFormList, setItensFormList] = useState<AirConditionerFormType>(AirConditioner)

  useEffect(() => {
    if (airConditionerActivities.length > 0) {
      const airConditionerActivityCopy = airConditionerActivities[indexAirConditionerActivitySelected]
      airConditionerActivityCopy.activityType = 'Ar condicionado'
      editAirConditionerActivity?.(indexAirConditionerActivitySelected, airConditionerActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexAirConditionerActivitySelected])


  useEffect(() => {
    if (airConditionerActivities.length > 0) {
      setItensFormList(airConditionerActivities[indexAirConditionerActivitySelected].AirConditioner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexAirConditionerActivitySelected, airConditionerActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemInstalacaoDeConjuntosEInfra(0)
      removeItemRemocaoDeConjuntosEInfra(0)
      removeItemLimpezaDeConjuntos(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Instalacao de conjuntos e infra") {
      addInstalacaoDeConjuntosEInfra({
        attributes: [
          {
            itemName: 'Metragem da tubulação',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Material da tubulação',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Diâmetro da tubulação',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Condensadora',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Evaporadora 1',
            unit_of_measurement: 'W',
            value: ''
          },
          {
            itemName: 'Evaporadora 2',
            unit_of_measurement: 'W',
            value: ''
          },
          {
            itemName: 'Evaporadora 3',
            unit_of_measurement: 'W',
            value: ''
          },
          {
            itemName: 'Já existe infraestrutura para esse conjunto no local',
            unit_of_measurement: '',
            value: 'Não'
          }
        ]
      })
    } else if (assignment === "Limpeza de conjuntos") {
      addLimpezaDeConjuntos({
        attributes: [
          {
            itemName: 'Condensadoras a serem limpas',
            unit_of_measurement: 'un',
            value: ''
          },
          {
            itemName: 'Evaporadoras a serem limpas',
            unit_of_measurement: 'un',
            value: ''
          }
        ]
      })
    } else if (assignment === "Remocao de conjuntos e infra") {
      addRemocaoDeConjuntosEInfra({
        attributes: [
          {
            itemName: 'Condensadoras a serem removidas',
            unit_of_measurement: 'un',
            value: ''
          },
          {
            itemName: 'Evaporadoras a serem removidas',
            unit_of_measurement: 'un',
            value: ''
          },
          {
            itemName: 'Metragem da tubulação a ser removida',
            unit_of_measurement: 'm linear',
            value: ''
          }
        ]
      })
    }
  }

  const addInstalacaoDeConjuntosEInfra = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.InstalacaoDeConjuntosEInfra.push(object)
    airConditionerActivitiesCopy[0].AirConditioner = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const addLimpezaDeConjuntos = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.LimpezaDeConjuntos.push(object)
    airConditionerActivitiesCopy[0].AirConditioner = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const addRemocaoDeConjuntosEInfra = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.RemocaoDeConjuntosEInfra.push(object)
    airConditionerActivitiesCopy[0].AirConditioner = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const removeItemInstalacaoDeConjuntosEInfra = (index: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.InstalacaoDeConjuntosEInfra.splice(index, 1)
    airConditionerActivitiesCopy[0].AirConditioner = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const removeItemLimpezaDeConjuntos = (index: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.LimpezaDeConjuntos.splice(index, 1)
    airConditionerActivitiesCopy[0].AirConditioner = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const removeItemRemocaoDeConjuntosEInfra = (index: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.RemocaoDeConjuntosEInfra.splice(index, 1)
    airConditionerActivitiesCopy[0].AirConditioner = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const editInstalacaoDeConjuntosEInfraValues = (value: string, indexItem: number, indexInstalacaoDeConjuntosEInfra: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.InstalacaoDeConjuntosEInfra[indexInstalacaoDeConjuntosEInfra].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const editInstalacaoDeConjuntosEInfraAttributeName = (value: string, indexItem: number, indexInstalacaoDeConjuntosEInfra: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.InstalacaoDeConjuntosEInfra[indexInstalacaoDeConjuntosEInfra].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }



  const editInstalacaoDeConjuntosEInfraUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.InstalacaoDeConjuntosEInfra[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const editLimpezaDeConjuntosValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.LimpezaDeConjuntos[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const editLimpezaDeConjuntosAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.LimpezaDeConjuntos[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }



  const editLimpezaDeConjuntosUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.LimpezaDeConjuntos[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const editRemocaoDeConjuntosEInfraValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.RemocaoDeConjuntosEInfra[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  const editRemocaoDeConjuntosEInfraAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.RemocaoDeConjuntosEInfra[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }



  const editRemocaoDeConjuntosEInfraUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const airConditionerActivitiesCopy = airConditionerActivities
    itensFormListCopy.RemocaoDeConjuntosEInfra[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editAirConditionerActivity?.(0, airConditionerActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.InstalacaoDeConjuntosEInfra.length ? (
        <div>
          <P>Instalação de conjuntos e infra</P>
          {itensFormList.InstalacaoDeConjuntosEInfra.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInstalacaoDeConjuntosEInfraAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editInstalacaoDeConjuntosEInfraValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editInstalacaoDeConjuntosEInfraValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === 'Já existe infraestrutura para esse conjunto no local' ? (<div>
                  <CheckBoxContainer>
                    <ContainerCheckBox>
                      <input
                        value={attributeItem.value}
                        type="checkbox"
                        name="Infraestrutura"
                        id="Infraestrutura"
                        onChange={(e) => editInstalacaoDeConjuntosEInfraValues('Sim', indexAttribute, indexItemForm)}
                      />
                      <label htmlFor="Infraestrutura">Já existe infraestrutura para esse conjunto no local</label>
                    </ContainerCheckBox>
                  </CheckBoxContainer>

                </div>) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInstalacaoDeConjuntosEInfraAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editInstalacaoDeConjuntosEInfraValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editInstalacaoDeConjuntosEInfraUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemInstalacaoDeConjuntosEInfra(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.LimpezaDeConjuntos.length ? (
        <div>
          <P>Limpeza de conjuntos</P>
          {itensFormList.LimpezaDeConjuntos.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div key={indexAttribute} className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLimpezaDeConjuntosAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editLimpezaDeConjuntosValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editLimpezaDeConjuntosUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemLimpezaDeConjuntos(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.RemocaoDeConjuntosEInfra.length ? (
        <div>
          <P>Remoção de conjuntos</P>
          {itensFormList.RemocaoDeConjuntosEInfra.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs" key={indexAttribute}>
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRemocaoDeConjuntosEInfraAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editRemocaoDeConjuntosEInfraValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editRemocaoDeConjuntosEInfraUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemRemocaoDeConjuntosEInfra(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default AirConditionerStepForm;
