export const Soculo = {
    SoculoEmAlvenaria: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Comprimento',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}