import React, { useEffect } from "react"

import { UseHydraulicContext } from "../../../Context/ActivitiesContext/Hydraulic/HidraulicContext"
import { UseStepContext } from "../../../Context/stepContext"
import { textTransform } from "../script"

import { ActionSelect, } from "../../stylesDomElements/htmlStyledElements";

const HydraulicController: React.FC = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()
    const { hydraulicActivities, editHydraulicActivity } = UseHydraulicContext()

    useEffect(() => {
        setActivitySelected?.('Agua fria')
    }, [])

    useEffect(() => {
        if (hydraulicActivities.length > 0) {
            var activityEdited = hydraulicActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editHydraulicActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="impermeabilizationStep" id="impermeabilizationStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Agua fria">Água fria</option>
                <option value="Agua quente">Água quente</option>
                <option value="Esgoto">Esgoto</option>
                <option value="Gas">Gás</option>
                <option value="Materiais para hidraulica">Materiais</option>
            </select>
        </ActionSelect>

    </div>
}

export default HydraulicController