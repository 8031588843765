import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { shelf } from "../../../../../types/typesEntitiesProject";
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget } from "../../style"
import { GreenDestack } from "../../../style"

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface activityProps {
    selectedBudget?: boolean,
    prateleira?: shelf[]
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        prateleira: string[]
    }
}

export const ShelfComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: string, activityId?: string, itemType?: any) => {
        var budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        var totalBudgetsList: number[] = []
        /**Identifica o tipo de item para calcular o menor valor total */
        itemType === "prateleira" ? (budgetsFilter.forEach((budget, index) => {
            budget.budget?.prateleira ? totalBudgetsList.push(parseFloat(budget.budget?.prateleira[index])) : (<></>)
        })) : (<></>)

        if(!totalItem){
            return <p>R$0,00</p>
        }

        var minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        })

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>
    }

    return (
        <ContainerItensBudget>
            {budgets ? (
                <>
                    <SectionItensBudget>
                        <p className="itemSectionName">Itens</p>
                        <p>Medidas</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                        <p>{" "}</p>
                    </SectionItensBudget>
                    {activity.prateleira?.map((element, index) => (
                        <ItemBudget key={index}>
                            <p className="itemName">Prateleiras</p>
                            <p>{element.comprimento_shelf}cm X {element.largura_shelf}cm X {element.altura_shelf} cm</p>

                            {props.suppliers ? (
                                <>
                                    {/**Item orçado pelo Fornecedor avulso */}
                                    {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                        budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                            budget.budget?.prateleira ? renderItemBudget(budget.budget?.prateleira[index], budget.activityId, 'prateleira') : ('')
                                        ))
                                    ))}
                                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                    {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                        budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                            budget.budget?.prateleira ? renderItemBudget(budget.budget?.prateleira[index], budget.activityId, 'prateleira') : ('')
                                        ))
                                    ))}
                                </>
                            ) : (<></>)}
                        </ItemBudget>
                    ))}
                </>
            ) : (<></>)}
        </ContainerItensBudget>
    )
}

export default ShelfComparartive