import React from "react";

import moment from "moment";

import { SelectDayActualyContent } from "./style"
import { dateFormate } from "../GanttMonth/scriptDate"
import { propsDate } from "../type"

const SelectDayActualy: React.FC<propsDate> = (props) => {
    const setMarker = () => {
        if (dateFormate(props.date) == moment().format('DD/MM/YYYY').toString()) {
            return (
                <SelectDayActualyContent width="1.5rem" backgroundColor="#7592CC" textColor="#FFF">
                    {props.date.split('/')[0]}
                </SelectDayActualyContent>
            )
        } else {
            return (
                <SelectDayActualyContent width="1.5rem" backgroundColor="transparent" textColor="#A5A5A5">
                    {props.date.split('/')[0]}
                </SelectDayActualyContent>
            )
        }
    }

    return (
        <>
            {setMarker()}
        </>
    )
}

export default SelectDayActualy