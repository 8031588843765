import { activityAndPendencesType } from "../type"
import moment from "moment"

/**Edit Check */
export const editCheckActivityBeforeInitService = (activityStateVar: activityAndPendencesType | any, setStateVar: any, indexActivity: number, checkValue: boolean) => {
    const activityCopy = activityStateVar
    activityCopy.architectActivities.beforeInitService.splice(
        indexActivity,
        1,
        {
            activity: activityCopy.architectActivities.beforeInitService[indexActivity].activity,
            check: checkValue,
            renameOrDelete: activityCopy.architectActivities.beforeInitService[indexActivity].renameOrDelete,
            checkUpdatedAt: moment().format("DD/MM/YYYY"),
            createdAt: activityCopy.architectActivities.beforeInitService[indexActivity].createdAt,
            attachment: activityCopy.architectActivities.beforeInitService[indexActivity].attachment,
            attachmentList: activityCopy.architectActivities.beforeInitService[indexActivity].attachmentList
        }
    )
    setStateVar({ ...activityCopy })
}

export const editCheckActivityDuringServiceService = (activityStateVar: activityAndPendencesType | any, setStateVar: any, indexActivity: number, checkValue: boolean) => {
    const activityCopy = activityStateVar
    activityCopy.architectActivities.duringService.splice(
        indexActivity,
        1,
        {
            activity: activityCopy.architectActivities.duringService[indexActivity].activity,
            check: checkValue,
            renameOrDelete: activityCopy.architectActivities.duringService[indexActivity].renameOrDelete,
            checkUpdatedAt: moment().format("DD/MM/YYYY"),
            createdAt: activityCopy.architectActivities.duringService[indexActivity].createdAt,
            attachment: activityCopy.architectActivities.duringService[indexActivity].attachment,
            attachmentList: activityCopy.architectActivities.duringService[indexActivity].attachmentList
        }
    )
    setStateVar({ ...activityCopy })
}

export const editCheckActivityAfterService = (activityStateVar: activityAndPendencesType | any, setStateVar: any, indexActivity: number, checkValue: boolean) => {
    const activityCopy = activityStateVar
    activityCopy.architectActivities.afterService.splice(
        indexActivity,
        1,
        {
            activity: activityCopy.architectActivities.afterService[indexActivity].activity,
            check: checkValue,
            renameOrDelete: activityCopy.architectActivities.afterService[indexActivity].renameOrDelete,
            checkUpdatedAt: moment().format("DD/MM/YYYY"),
            createdAt: activityCopy.architectActivities.afterService[indexActivity].createdAt,
            attachment: activityCopy.architectActivities.afterService[indexActivity].attachment,
            attachmentList: activityCopy.architectActivities.afterService[indexActivity].attachmentList
        }
    )
    setStateVar({ ...activityCopy })
}