import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { UseUserContext } from "../../Context/UserContext";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import axios from "axios";

import { Icons } from "./style";
import {
  Container,
  ContentHeader,
  Action,
  Logo,
  ProjectName,
  ProfileImg,
  Notification,
} from "./style";
/** */
import Notifications from "./Notifications";

import returnIcon from "../../assets/returnArrow.svg";
import logoImg from "../../assets/arquitetool.svg";

import helpIcon from "../../assets/helpIcon.svg";
import addSupliersIcon from "../../assets/addSupliersIcon.svg";

import bellIcon from "../../assets/bellicon.svg";
interface HeaderProps {
  nameHeader?: string;
  imageProfile?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const pathname = useLocation().pathname;
  const [projectName, setProjectName] = useState<string>("");
  const { editPermission } = UseAuthAcessContext();
  const { imageProfile, userId, projectSelected } = UseUserContext();
  const [renderContainerNotification, setRenderContainerNotification] =
    useState<boolean>(false);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProjectName(res.data[0].name);
        });
    }
  }, [projectSelected]);

  const returnPage = () => {
    window.history.back();
  };

  return (
    <Container>
      <ContentHeader>
        <div className="retrunIconHeaderContainer">
          {projectName === "" || pathname === "/acessLink" ? (
            <></>
          ) : (
            <Link to="#">
              <Action onClick={() => returnPage()}>
                <img src={returnIcon} alt="Icone de voltar" />
              </Action>
            </Link>
          )}

          <Logo>
            {pathname === "/acessLink" ? (
              <img className="logo" src={logoImg} alt="Logo arquitetools" />
            ) : (
              <Link to="/home">
                <img className="logo" src={logoImg} alt="Logo arquitetools" />
              </Link>
            )}
          </Logo>
        </div>
        {props.nameHeader ? (
          <ProjectName>{props?.nameHeader}</ProjectName>
        ) : (
          <ProjectName>{projectName}</ProjectName>
        )}
        <div className="ProfileNotificationContainer">
          {editPermission === "" ? (
            <>
              <Icons>
                <img src={helpIcon} alt="Icone de ajuda" />
              </Icons>
              <Icons>
                <img src={addSupliersIcon} alt="Icone de pessoas" />
              </Icons>
              <Icons>
                <Notification
                  onClick={() =>
                    setRenderContainerNotification(!renderContainerNotification)
                  }
                >
                  <img src={bellIcon} alt="Iocne notificação" />
                </Notification>
              </Icons>
              <Icons>
                <ProfileImg>
                  <img src={imageProfile} alt="Foto de perfil" />
                </ProfileImg>
              </Icons>
            </>
          ) : null}
        </div>
      </ContentHeader>

      <Notifications
        renderContainerNotification={renderContainerNotification}
      />
    </Container>
  );
};

export default Header;
