import styled from "styled-components"

export const ActivitiesContainer = styled.div`
    background-color: var(--primary-color);
    overflow-y:auto;
    padding: 1rem;
    border-radius: 0.3rem;
    max-height: 90vh;

    #activityName{
        width: 100%;
        border: none;
        font-size: 1rem;
        color: #000000;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
    }

    .flexContainerRow{
        display: flex;
        grid-column-gap: 5rem;
        margin-top: 2rem;

        div{
            p{
                font-size: 0.85rem;
            }
        }
    }
`

export const MessageContent=styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`