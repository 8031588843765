import React, { useEffect, useState, createContext, useContext } from "react";
import axios from "axios";

import { Navigate } from "react-router-dom";

import { UseModalContext } from "../../Context/ModalContext";

import {
  Container,
  ImageContainer,
  CredentialsContainer,
  ButtonLoginActived,
  ButtonLoginNoActived,
  LoginArea,
  ButtonOptional,
  HeaderFormContainer,
  FormContainer,
} from "./style";

import ModalTypeRegister from "../../components/ModalTypeRegister";
import ModalForgetPassword from "../../components/ForgetPasswordModal";
import ErrLogin from "./ErrLogin";

import facebookLogo from "../../assets/facebookLogo.svg";
import googleIcon from "../../assets/Google__G__Logo.svg.png";
import LogoArquitetool from "../../assets/arquitetool.svg";
import eyeVisibleOn from "../../assets/visibility_on.svg";
import eyeVisibleOff from "../../assets/visibility_off.svg";
import Modal from "../../components/Modal";
import iconCloseModal from "../../assets/XIcon.svg";

const Login: React.FC = () => {
  localStorage.clear();
  const { setShowModal } = UseModalContext();
  const [email, setEmail] = useState<string>();

  const [password, setPassword] = useState<string>();
  const [googleLoginUrl, setGoogleLoginUrl] = useState<string>("");

  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const [nameButtonlogin, setNameButonLogin] = useState<string>("Entrar");
  const [errStatusLogin, setErrLogin] = useState<number>(0);

  const [typeShowModal, setTypeShowModal] = useState<boolean>(false);
  const [feedbackUserRegister, setFeedbackUserRegister] =
    useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LOGIN_AUTENTICATION_URL}/urlGoogleLogin`)
      .then((res) => {
        setGoogleLoginUrl(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    var inputPassword;
    inputPassword = document.getElementById("passwordLogin");
    visiblePassword
      ? inputPassword?.setAttribute("type", "text")
      : inputPassword?.setAttribute("type", "password");
  }, [visiblePassword]);

  const ModalForgetPasswordExibition = () => {
    setShowModal?.();
    setTypeShowModal(true);
  };

  const ModalRegisterExibition = () => {
    setShowModal?.();
    setTypeShowModal(false);
  };

  async function submitLogin() {
    if (email && password) {
      setNameButonLogin("Entrando...");
      setErrLogin(0);
      axios
        .post(`${process.env.REACT_APP_LOGIN_AUTENTICATION_URL}/auth`, {
          email,
          password,
        })
        .then((res) => {
          if (res.data.token) {
            var token = res.data.token;
            localStorage.setItem("token", token);
            window.location.href = "/home";
          }
        })
        .catch((err) => {
          setNameButonLogin("Entrar");
          // eslint-disable-next-line eqeqeq
          if (err == "Error: Request failed with status code 404") {
            setErrLogin(404);
          }
        });
    }
  }

  return (
    <Container>
      {typeShowModal === true ? (
        <ModalForgetPassword />
      ) : (
        <ModalTypeRegister setFeedbackUserRegister={setFeedbackUserRegister} />
      )}

      <ImageContainer>
        <img
          src={
            "https://i.pinimg.com/564x/df/98/07/df9807e06f434d2ba3d8e33729bf2dd1.jpg"
          }
          alt="Imagem Login"
        />
      </ImageContainer>
      <CredentialsContainer>
        <LoginArea>
          <div>
            <img className="logoArquitetool" src={LogoArquitetool} alt="logo" />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="passwordLogin">Senha</label>
            <div className="passwordContent">
              <input
                type="password"
                name="password"
                id="passwordLogin"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {visiblePassword ? (
                <img
                  className="visiblePassword"
                  src={eyeVisibleOn}
                  alt="icone olho visivel"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                />
              ) : (
                <img
                  className="visiblePassword"
                  src={eyeVisibleOff}
                  alt="icone olho não visível"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                />
              )}
            </div>
          </div>
          <ErrLogin errStatus={errStatusLogin} />
          {email && password ? (
            <ButtonLoginActived onClick={() => submitLogin()}>
              {nameButtonlogin}
            </ButtonLoginActived>
          ) : (
            <ButtonLoginNoActived disabled>Entrar</ButtonLoginNoActived>
          )}

          <p onClick={() => ModalForgetPasswordExibition()}>Esqueci a senha</p>
        </LoginArea>

        <div>
          <ButtonOptional
            borderColor="#d4d4d4"
            hoverColor="#d4d4d455"
            onClick={() => ModalRegisterExibition()}
          >
            {" "}
            Ainda não sou cadastrado
          </ButtonOptional>

          <ButtonOptional
            href={googleLoginUrl}
            borderColor="#EA4335"
            hoverColor="#EA433520"
          >
            <img className="googleIcon" src={googleIcon} alt="Logo Google" />{" "}
            Entrar com Google
          </ButtonOptional>
          <ButtonOptional borderColor="#4267B2" hoverColor="#4267B220">
            <img
              className="facebookIcon"
              src={facebookLogo}
              alt="Logo Facebook"
            />{" "}
            Entrar com Facebook
          </ButtonOptional>
        </div>
        {feedbackUserRegister ? (
          <div>
            <FormContainer>
              <HeaderFormContainer>
                <p>Usuário cadastrado com sucesso &nbsp;</p>

                <img
                  src={iconCloseModal}
                  alt="icone de fechar"
                  onClick={() => setFeedbackUserRegister(false)}
                />
              </HeaderFormContainer>
            </FormContainer>
          </div>
        ) : (
          <></>
        )}
      </CredentialsContainer>
    </Container>
  );
};

export default Login;
