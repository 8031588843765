import React, { SetStateAction, useContext, useEffect, useState } from "react";
import {
  BudgetsProps,
  SupplierProps,
  UserProviderProps,
} from "../../../types/typesEntitiesProject";

interface selectionBudgetContextTypes {
  modifyActivity: boolean;
  setModifyActivity?: React.Dispatch<SetStateAction<boolean>>;

  showBudgetSupplier: boolean;
  setShowBudgetSupplier?: React.Dispatch<SetStateAction<boolean>>;

  budget?: BudgetsProps;
  setBudget?: React.Dispatch<SetStateAction<BudgetsProps>>;

  supplier?: SupplierProps;
  setSupplier?: React.Dispatch<SetStateAction<SupplierProps>>;
}

const defaltSelectionBudgetContext = {
  modifyActivity: false,
  showBudgetSupplier: false,
};

const SelectionBudgetContext = React.createContext<selectionBudgetContextTypes>(
  defaltSelectionBudgetContext
);

export const UseSelectionBudgetContext = () =>
  useContext(SelectionBudgetContext);

export const SelectionBudgetContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [modifyActivity, setModifyActivity] = useState(Boolean);
  const [showBudgetSupplier, setShowBudgetSupplier] = useState(Boolean);
  const [budget, setBudget] = useState<BudgetsProps>(Object);
  const [supplier, setSupplier] = useState<SupplierProps>(Object);

  return (
    <SelectionBudgetContext.Provider
      value={{
        modifyActivity,
        setModifyActivity,
        showBudgetSupplier,
        setShowBudgetSupplier,
        budget,
        setBudget,
        supplier,
        setSupplier,
      }}
    >
      {children}
    </SelectionBudgetContext.Provider>
  );
};
