import React, { useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { parse } from "querystring";

import { Container, Image } from "./style";
import loadingIcon from "../../assets/loading.gif";

const ValidateSocialLogin: React.FC = () => {
  useEffect(() => {
    const parseValue = parse(window.location.search);

    axios
      .post(`${process.env.REACT_APP_LOGIN_AUTENTICATION_URL}/validateUser`, {
        code: parseValue?.["?code"],
      })
      .then((res) => {
        if (res.data === "User not found") {
          window.location.href = "/";
        } else {
          localStorage.setItem("token", res.data.token);
          <Navigate to="/home" />
        }
      });
  }, []);
  return (
    <Container>
      <Image src={loadingIcon} />
    </Container>
  );
};

export default ValidateSocialLogin;
