import React from "react";
import AcessoriesDescription from "./Accessories";
import FaucetsAndMixerDescription from "./FaucetsAndMixer";
import ShowersDescription from "./Showers";
import VatsDescription from "./Vats";
import VasesDescription from "./Vases";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const CrockeryAndMetalsDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Acessorios" ? (<AcessoriesDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Torneira e misturador" ? (<FaucetsAndMixerDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Chuveiros" ? (<ShowersDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Cubas" ? (<VatsDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Vasos" ? (<VasesDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default CrockeryAndMetalsDescription