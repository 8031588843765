import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";

import {
  UserProviderProps,
  WoodWorkProps,
} from "../../../types/typesEntitiesProject";

export interface activitiesWoodWorkProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  projetoMarcenaria: WoodWorkProps[];
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  woodWorkActivities: activitiesWoodWorkProps[];
  woodWorkActivityName: string;
  indexWoodWorkActivitySelected: number;
  uploadArchiveProgress: number;
  setUploadArchiveProgress: React.Dispatch<React.SetStateAction<number>>;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesWoodWorkProps[]>>;
  setNewWoodWorkActivity?: () => void;
  setWoodWorkActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexWoodWorkActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editWoodWorkActivity?: (
    index: number,
    activity: activitiesWoodWorkProps
  ) => void;
  deleteWoodWorkActivity?: (index: number) => void;
}

const activityContextDefault = {
  woodWorkActivities: [],
  woodWorkActivityName: "",
  indexWoodWorkActivitySelected: 0,
  uploadArchiveProgress: 0,
  setUploadArchiveProgress: () => {},
  setNewWoodWorkActivity: () => {},
  setWoodWorkActivityName: () => {},
  setIndexWoodWorkActivitySelected: () => {},
  editWoodWorkActivity: () => {},
  deleteWoodWorkActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseWoodWorkActivityContext = () => useContext(ActivitiesContext);

export const WoodWorkActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [woodWorkActivities, setActivity] = useState<activitiesWoodWorkProps[]>(
    activityContextDefault.woodWorkActivities
  );

  const [indexWoodWorkActivitySelected, setIndexWoodWorkActivitySelected] =
    useState<number>(0);

  const [woodWorkActivityName, setWoodWorkActivityName] =
    useState<string>("Marcenaria");

  const [uploadArchiveProgress, setUploadArchiveProgress] = useState<number>(0);

  const editWoodWorkActivity = (
    index: number,
    activity: activitiesWoodWorkProps
  ) => {
    let activitiesEdited = woodWorkActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteWoodWorkActivity = (index: number) => {
    const activity = [...woodWorkActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewWoodWorkActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: woodWorkActivityName,
        activityStatus: "inProgress",
        activityType: "Marcenaria",
        stepType: "Marcenaria",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        projetoMarcenaria: [
          {
            quantidade: "",
            nome: "",
            arquivoProjeto: [],
            link: "",
          },
        ],
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        woodWorkActivities,
        woodWorkActivityName,
        indexWoodWorkActivitySelected,
        uploadArchiveProgress,
        setUploadArchiveProgress,
        setActivity,
        setWoodWorkActivityName,
        setNewWoodWorkActivity,
        editWoodWorkActivity,
        deleteWoodWorkActivity,
        setIndexWoodWorkActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
