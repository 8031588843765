/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from "react";
import axios from "axios";

import { UseStepContext } from "../../Context/stepContext";
import { UseUserContext } from "../../Context/UserContext";
import { UseGanttContext } from "../../Context/GanttContext";
import { UseDemolitionActivityContext } from "../../Context/ActivitiesContext/Demolition/DemolitionContext";
import { UseMasonaryActivityContext } from "../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { UseImpermeabilizationActivityContext } from "../../Context/ActivitiesContext/Impermeabilization/ImpermeabilizationContext";
import { UseEletricActivityContext } from "../../Context/ActivitiesContext/Eletric/EletricContext";
import { UseHydraulicContext } from "../../Context/ActivitiesContext/Hydraulic/HidraulicContext";
import { UseAirConditionerActivityContext } from "../../Context/ActivitiesContext/AirConditioner/AirConditionerContext";
import { UseFloorsAndCoveringsActivityContext } from "../../Context/ActivitiesContext/FloorsAndCoverings/FloorsAndCoveringsContext";
import { UseLiningActivityContext } from "../../Context/ActivitiesContext/Lining/LiningContext";
import { UsePaintActivityContext } from "../../Context/ActivitiesContext/Paint/PaintContext";
import { UseTablewareAndMetalsActivityContext } from "../../Context/ActivitiesContext/TablewareAndMetals/TablewareAndMetalsContext";
import { UseRoofActivityContext } from "../../Context/ActivitiesContext/Roof/RoofContext";
import { UseGlassworkActivityContext } from "../../Context/ActivitiesContext/Glasswork/GlassworkContext";
import { UseMarbleworkActivityContext } from "../../Context/ActivitiesContext/Marblework/MarbleworkContext";
import { UseSawmillActivityContext } from "../../Context/ActivitiesContext/Sawmill/SawmillContext";
import { UseWoodWorkActivityContext } from "../../Context/ActivitiesContext/WoodWork";
import { UseFramesActivityContext } from "../../Context/ActivitiesContext/Frames/FramesContext";
import { UseFurnitureActivityContext } from "../../Context/ActivitiesContext/Furniture/FurnitureContext";
import { UseDoorsAndWindowsActivityContext } from "../../Context/ActivitiesContext/DoorsAndWindows/DoorsAndWindowsContext";

import { allActivitiesProps } from "../../types/typesEntitiesProject";
import { stepProps } from "../ConstructionManagement/type";
import { StepProps } from "../../types/typesEntitiesProject";

import {
  Select,
  ContainerInput,
  Input,
} from "../../components/stylesDomElements/htmlStyledElements";

import { primaryColor, secondaryColor } from "../../Config/colorConfig";

import {
  TopBar,
  ActionsTopMenu,
  SelectStepContainer,
  ButtonAddActivity,
  ContentMessageInformation,
} from "./style";

import addIcon from "../../assets/addIcon.svg";

import PartDivisor from "./PartDivisor";
import ShowActivityController from "./ShowActivityController";
import Header from "../../components/Header";

import Steps from "../../components/Steps";
import Activity from "../../components/Activity";

import DemolitionController from "../../components/StepsForm/Demolition";
import MasonryController from "../../components/StepsForm/Masonry";
import ImpermeabilizationController from "../../components/StepsForm/Impermeabilization";
import HydraulicController from "../../components/StepsForm/Hydraulic";
import EletricController from "../../components/StepsForm/Eletric";
import FloorsAndCoveringsController from "../../components/StepsForm/FloorsAndCoverings";
import PaintController from "../../components/StepsForm/Painting";
import RoofController from "../../components/StepsForm/Roof";
import AirConditionerController from "../../components/StepsForm/AirConditioner/";
import GlassworksController from "../../components/StepsForm/Glassworks";
import LiningController from "../../components/StepsForm/Lining";
import TablewareAndMetalsController from "../../components/StepsForm/TablewareAndMetals";
import MarbleWorkController from "../../components/StepsForm/MarbleWork";
import SawmillController from "../../components/StepsForm/Sawmill";
import WoodWorkController from "../../components/StepsForm/WoodWork";
import DoorsAndWindowsController from "../../components/StepsForm/DoorsAndWindows";
import FramesController from "../../components/StepsForm/Frames/index";
import FurnitureController from "../../components/StepsForm/Furniture";
import CleaningController from "../../components/StepsForm/Cleaning";
import MovingController from "../../components/StepsForm/Moving";

import { sendStepActivityFile } from "./script";

import { activitiesProps } from "../../types/typesEntitiesProject";
import { UseProjectStepActivityContext } from "../../Context/ActivitiesContext/ProjectStep/ProjectStepContext";
import ProjectStepController from "../../components/StepsForm/ProjectStep";
import { UseCleaningActivityContext } from "../../Context/ActivitiesContext/Cleaning/CleaningContext";
import { UseMovingActivityContext } from "../../Context/ActivitiesContext/Moving/MovingContext";

const StepSelection: React.FC = () => {
  const { projectSelected, setprojectSelected } = UseUserContext();
  const { step, setStep, setActivitySelected } = UseStepContext();
  const { stepId } = UseGanttContext();

  const [steps, setSteps] = useState<stepProps[]>([]);
  const [activitiesProject, setActivitiesProject] = useState<
    allActivitiesProps[]
  >([]);
  const [stepName, setStepName] = useState<string>("");
  const [activities, setActivities] = useState<activitiesProps[]>([]);
  const [newlyProjectSelected, setNewlyProjectSelected] = useState<string>("");

  /**Definição dos contextos das atividades das etapas */
  const { demolitionActivities, setNewDemolitionActivity, setActivity } =
    UseDemolitionActivityContext();
  const { masonaryActivities, setNewMasonaryActivity } =
    UseMasonaryActivityContext();
  const { impermeabilizationActivities, setNewImpermeabilizationActivity } =
    UseImpermeabilizationActivityContext();
  const { eletricActivities, setNewEletricActivity } =
    UseEletricActivityContext();
  const { hydraulicActivities, setNewHydraulicActivity } =
    UseHydraulicContext();
  const { airConditionerActivities, setNewAirConditionerActivity } =
    UseAirConditionerActivityContext();
  const { floorsAndCoveringsActivities, setNewFloorsAndCoveringsActivity } =
    UseFloorsAndCoveringsActivityContext();
  const { liningActivities, setNewLiningActivity } = UseLiningActivityContext();
  const { paintActivities, setNewPaintActivity } = UsePaintActivityContext();
  const { tablewareAndMetalsActivities, setNewTablewareAndMetalsActivity } =
    UseTablewareAndMetalsActivityContext();
  const { roofActivities, setNewRoofActivity } = UseRoofActivityContext();
  const { glassworkActivities, setNewGlassworkActivity } =
    UseGlassworkActivityContext();
  const { marbleworkActivities, setNewMarbleworkActivity } =
    UseMarbleworkActivityContext();
  const {
    sawmillActivities,
    setNewSawmillActivity,
    indexSawmillActivitySelected,
  } = UseSawmillActivityContext();
  const {
    woodWorkActivities,
    setNewWoodWorkActivity,
    setUploadArchiveProgress,
  } = UseWoodWorkActivityContext();
  const { doorsAndWindowsActivities, setNewDoorsAndWindowsActivity } =
    UseDoorsAndWindowsActivityContext();
  const { framesActivities, setNewFramesActivity } = UseFramesActivityContext();
  const { furnitureActivities, setNewFurnitureActivity } =
    UseFurnitureActivityContext();
  const { projectStepActivities, setNewProjectStepActivity } =
    UseProjectStepActivityContext();
  const { cleaningActivities, setNewCleaningActivity } =
    UseCleaningActivityContext();
  const { movingActivities, setNewMovingActivity } = UseMovingActivityContext();

  useEffect(() => {
    if (projectSelected !== null || projectSelected !== undefined) {
      const newlyProjectSelected = localStorage.getItem("projectId");
      if (newlyProjectSelected && setprojectSelected) {
        setprojectSelected(newlyProjectSelected);
      }
    }
  }, [projectSelected, setprojectSelected]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/steps/${projectSelected}`)
        .then((res) => {
          setSteps(res.data);
        });
    }
  }, [projectSelected]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/activitiesProject/${projectSelected}`
        )
        .then((res) => {
          setActivitiesProject(res.data);
        });
    }
  }, [activitiesProject, projectSelected]);

  useEffect(() => {
    const stepIndex = steps.findIndex((element) => element._id === stepId[0]);
    if (stepIndex !== -1) {
      steps[stepIndex].showActivity
        ? (steps[stepIndex].showActivity = false)
        : (steps[stepIndex].showActivity = true);
      setSteps([...steps]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  /**Para atualizar a variável activities com os valores da atividade atual */
  useEffect(() => {
    stepName === "Demolicao" ? (
      setActivities(demolitionActivities)
    ) : stepName === "Alvenaria" ? (
      setActivities(masonaryActivities)
    ) : stepName === "Impermeabilizacao" ? (
      setActivities(impermeabilizationActivities)
    ) : stepName === "Eletrica" ? (
      setActivities(eletricActivities)
    ) : stepName === "Hidraulica" ? (
      setActivities(hydraulicActivities)
    ) : stepName === "Ar condicionado" ? (
      setActivities(airConditionerActivities)
    ) : stepName === "Pisos e revestimento" ? (
      setActivities(floorsAndCoveringsActivities)
    ) : stepName === "Forro" ? (
      setActivities(liningActivities)
    ) : stepName === "Pintura" ? (
      setActivities(paintActivities)
    ) : stepName === "Loucas e metais" ? (
      setActivities(tablewareAndMetalsActivities)
    ) : stepName === "Telhado" ? (
      setActivities(roofActivities)
    ) : stepName === "Vidracaria" ? (
      setActivities(glassworkActivities)
    ) : stepName === "Marmoraria" ? (
      setActivities(marbleworkActivities)
    ) : stepName === "Serralheria" ? (
      setActivities(sawmillActivities)
    ) : stepName === "Portas e janelas" ? (
      setActivities(doorsAndWindowsActivities)
    ) : stepName === "Esquadrias" ? (
      setActivities(framesActivities)
    ) : stepName === "Mobiliario" ? (
      setActivities(furnitureActivities)
    ) : stepName === "Marcenaria" ? (
      setActivities(woodWorkActivities)
    ) : stepName === "Projeto" ? (
      setActivities(projectStepActivities)
    ) : stepName === "Limpeza" ? (
      setActivities(cleaningActivities)
    ) : stepName === "Mudança" ? (
      setActivities(movingActivities)
    ) : (
      <></>
    );
  }, [
    demolitionActivities,
    masonaryActivities,
    impermeabilizationActivities,
    stepName,
    eletricActivities,
    hydraulicActivities,
    airConditionerActivities,
    floorsAndCoveringsActivities,
    liningActivities,
    paintActivities,
    tablewareAndMetalsActivities,
    roofActivities,
    glassworkActivities,
    marbleworkActivities,
    sawmillActivities,
    woodWorkActivities,
    doorsAndWindowsActivities,
    framesActivities,
    furnitureActivities,
    projectStepActivities,
    cleaningActivities,
    movingActivities,
  ]);

  useEffect(() => {
    const newStep = step;
    newStep.name = stepName;
    newStep.projectId = projectSelected;
    setStep?.(newStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, stepName]);

  useEffect(() => {
    stepName === "Demolicao" ? (
      setNewDemolitionActivity?.()
    ) : /* setIndexDemolitionActivitySelected?.(activities.length) */ stepName ===
      "Alvenaria" ? (
      setNewMasonaryActivity?.()
    ) : /* setIndexMasonaryActivitySelected?.(activities.length) */ stepName ===
      "Impermeabilizacao" ? (
      setNewImpermeabilizationActivity?.()
    ) : /* setIndexImpermeabilizationActivitySelected?.(activities.length) */
    stepName === "Eletrica" ? (
      setNewEletricActivity?.()
    ) : /* setIndexEletricActivitySelected?.(activities.length) */
    stepName === "Hidraulica" ? (
      setNewHydraulicActivity?.()
    ) : /* setIndexHydraulicActivitySelected?.(activities.length) */
    stepName === "Ar condicionado" ? (
      setNewAirConditionerActivity?.()
    ) : /* setIndexAirConditionerActivitySelected?.(activities.length) */
    stepName === "Pisos e revestimento" ? (
      setNewFloorsAndCoveringsActivity?.()
    ) : /* setIndexFloorsAndCoveringsActivitySelected?.(activities.length) */
    stepName === "Forro" ? (
      setNewLiningActivity?.()
    ) : /*  setIndexLiningActivitySelected?.(activities.length) */
    stepName === "Pintura" ? (
      setNewPaintActivity?.()
    ) : /* setIndexPaintActivitySelected?.(activities.length) */
    stepName === "Loucas e metais" ? (
      setNewTablewareAndMetalsActivity?.()
    ) : /* setIndexTablewareAndMetalsActivitySelected?.(activities.length) */
    stepName === "Telhado" ? (
      setNewRoofActivity?.()
    ) : /* setIndexRoofActivitySelected?.(activities.length) */
    stepName === "Vidracaria" ? (
      setNewGlassworkActivity?.()
    ) : /* setIndexGlassworkActivitySelected?.(activities.length) */
    stepName === "Marmoraria" ? (
      setNewMarbleworkActivity?.()
    ) : /* setIndexMarbleworkActivitySelected?.(activities.length) */
    stepName === "Serralheria" ? (
      setNewSawmillActivity?.()
    ) : /* setIndexSawmillActivitySelected?.(activities.length) */
    stepName === "Portas e janelas" ? (
      setNewDoorsAndWindowsActivity?.()
    ) : /* setIndexSawmillActivitySelected?.(activities.length) */
    stepName === "Esquadrias" ? (
      setNewFramesActivity?.()
    ) : /* setIndexSawmillActivitySelected?.(activities.length) */
    stepName === "Mobiliario" ? (
      setNewFurnitureActivity?.()
    ) : /* setIndexSawmillActivitySelected?.(activities.length) */
    stepName === "Marcenaria" ? (
      setNewWoodWorkActivity?.()
    ) : /* setIndexWoodWorkActivitySelected?.(activities.length) */
    stepName === "Projeto" ? (
      setNewProjectStepActivity?.()
    ) : /* setIndexProjectStepActivitySelected?.(activities.length) */
    stepName === "Limpeza" ? (
      setNewCleaningActivity?.()
    ) : /* setIndexCleaningActivitySelected?.(activities.length) */
    stepName === "Mudança" ? (
      setNewMovingActivity?.()
    ) : (
      /* setIndexMovingActivitySelected?.(activities.length) */
      <></>
    );
  }, [stepName]);

  const renameActivity = (value: string) => {
    const activitiesCopy = Array.from(activities);
    activitiesCopy[0].activityName = value;
    activitiesCopy.splice(0, 0, ...activitiesCopy);
  };

  const sendSteps = () => {
    if (stepName === "Marcenaria") {
      sendStepActivityFile(activities, step, setUploadArchiveProgress);
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/newStep`, [step, activities])
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          }
        });
    }
  };

  const setEditActivity = (activity: allActivitiesProps, step: any) => {
    setActivity?.([activity]);
    setActivities([activity]);
    setStep?.(step);
  };

  return (
    <div>
      <Header />
      <TopBar>
        <ActionsTopMenu>
          <div className="flexItens">
            <div className="typeStep">
              <p>Adicionar etapa:</p>
              <Select
                className="selectOption"
                onChange={(e) => setStepName(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Demolicao">Demolição</option>
                <option value="Alvenaria">Alvenaria</option>
                <option value="Impermeabilizacao">Impermeabilização</option>
                <option value="Eletrica">Elétrica</option>
                <option value="Hidraulica">Hidráulica</option>
                <option value="Ar condicionado">Ar condicionado</option>
                <option value="Pisos e revestimento">
                  Pisos e revestimento
                </option>
                <option value="Forro">Forro</option>
                <option value="Pintura">Pintura</option>
                <option value="Loucas e metais">Louças e metais</option>
                <option value="Telhado">Telhado</option>
                <option value="Vidracaria">Vidraçaria</option>
                <option value="Marmoraria">Marmoraria</option>
                <option value="Serralheria">Serralheria</option>
                <option value="Marcenaria">Marcenaria</option>
                <option value="Esquadrias">Esquadrias</option>
                <option value="Mobiliario">Mobiliario</option>
                <option value="Portas e janelas">Portas e janelas</option>
                <option value="Projeto">Projeto</option>
                <option value="Limpeza">Limpeza</option>
                <option value="Mudança">Mudança</option>
              </Select>
            </div>

            {/*<div className="fileContainer">
              <p>Projeto da etapa</p>
                <InputFile>
                Faça o upload do arquivo
                <label htmlFor="img">Selecione o arquivo</label>
                <InputForm name="img" id="img" type="file" />
              </InputFile>
            </div> */}
            <PartDivisor />
            <div>
              {stepName === "Demolicao" && activities?.length > 0 ? (
                <DemolitionController />
              ) : stepName === "Alvenaria" && activities?.length > 0 ? (
                <MasonryController />
              ) : stepName === "Impermeabilizacao" && activities?.length > 0 ? (
                <ImpermeabilizationController />
              ) : stepName === "Eletrica" && activities?.length > 0 ? (
                <EletricController />
              ) : stepName === "Hidraulica" && activities?.length > 0 ? (
                <HydraulicController />
              ) : stepName === "Ar condicionado" && activities?.length > 0 ? (
                <AirConditionerController />
              ) : stepName === "Pisos e revestimento" &&
                activities?.length > 0 ? (
                <FloorsAndCoveringsController />
              ) : stepName === "Forro" && activities?.length > 0 ? (
                <LiningController />
              ) : stepName === "Pintura" && activities?.length > 0 ? (
                <PaintController />
              ) : stepName === "Loucas e metais" && activities?.length > 0 ? (
                <TablewareAndMetalsController />
              ) : stepName === "Telhado" && activities?.length > 0 ? (
                <RoofController />
              ) : stepName === "Vidracaria" && activities?.length > 0 ? (
                <GlassworksController />
              ) : stepName === "Marmoraria" && activities?.length > 0 ? (
                <MarbleWorkController />
              ) : stepName === "Portas e janelas" && activities?.length > 0 ? (
                <DoorsAndWindowsController />
              ) : stepName === "Serralheria" && activities?.length > 0 ? (
                <SawmillController
                  indexSelected={indexSawmillActivitySelected}
                />
              ) : stepName === "Esquadrias" && activities?.length > 0 ? (
                <FramesController />
              ) : stepName === "Mobiliario" && activities?.length > 0 ? (
                <FurnitureController />
              ) : stepName === "Marcenaria" && activities?.length > 0 ? (
                <WoodWorkController />
              ) : stepName === "Projeto" && activities?.length > 0 ? (
                <ProjectStepController />
              ) : stepName === "Limpeza" && activities?.length > 0 ? (
                <CleaningController />
              ) : stepName === "Mudança" && activities?.length > 0 ? (
                <MovingController />
              ) : (
                <></>
              )}
            </div>
          </div>

          {activities.length ? (
            <ContainerInput className="ContainerInput">
              <Input
                type="text"
                placeholder="Renomear atividade"
                onChange={(e) => renameActivity(e.target.value)}
              />
            </ContainerInput>
          ) : (
            <></>
          )}

          <ButtonAddActivity onClick={() => sendSteps()}>
            <img src={addIcon} alt="adicionar" />
            Adicionar atividade
          </ButtonAddActivity>
        </ActionsTopMenu>
      </TopBar>

      <SelectStepContainer>
        <div>
          {steps.length ? (
            steps.map((step, stepIndex) => (
              <Steps
                stepName={step.name}
                porcentage={step.percentage}
                backgroundColor={secondaryColor[stepIndex]}
                borderColor={primaryColor[stepIndex]}
                _id={step._id}
                key={stepIndex}
              >
                {step.showActivity ? (
                  activitiesProject.map((activity, index) => {
                    if (activity.stepId === step._id) {
                      return (
                        <div key={index}>
                          <Activity
                            oppenModalActivity={() => {}}
                            activityId={activity._id}
                            activityName={activity.activityName}
                            percentage={activity.percentage}
                            backgroundColor={secondaryColor[stepIndex]}
                            setEditActivity={() =>
                              setEditActivity?.(activity, step)
                            }
                          />
                        </div>
                      );
                    } else return null;
                  })
                ) : (
                  <></>
                )}
              </Steps>
            ))
          ) : (
            <ContentMessageInformation>
              As etapas/atividades serão exibidas aqui!
            </ContentMessageInformation>
          )}
        </div>

        <ShowActivityController />
      </SelectStepContainer>
    </div>
  );
};

export default StepSelection;
