import React, { useEffect } from 'react'

import { UseDemolitionActivityContext } from "../../../Context/ActivitiesContext/Demolition/DemolitionContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"
import { textButton } from '../script/textButtonSubmit'
import { RequestApi } from "../RequestApi"


import { Container } from "../style"

import DemolitionStepForm from '../../StepsForm/Demolition/Demolition'
import MaterialsDemolitionStepForm from '../../StepsForm/Demolition/Materials'

const DemolitionModifyActivities: React.FC<activityProps> = (props) => {
    const { demolitionActivities, setActivity, setIndexDemolitionActivitySelected } = UseDemolitionActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexDemolitionActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Demolicao" ? (<DemolitionStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsDemolitionStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(demolitionActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(demolitionActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default DemolitionModifyActivities