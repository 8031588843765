import React, { SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import Xarrow from "react-xarrows";

import {
  GanttContainer,
  GanttRowDays,
  GanttColummSeparate,
  GanttRow,
  GantRowBars,
  Li,
  MenuOverlay,
  OptionButton,
} from "../style";

import { UseGanttContext } from "../../../../Context/GanttContext";
import { UseAuthAcessContext } from "../../../../Context/AuthAcessContext";

import { allActivitiesProps } from "../../../../types/typesEntitiesProject";
import { stepProps, dependencesProps } from "../../type";

import Steps from "../../../../components/Steps";
import Activity from "../../../../components/Activity";
import SelectTrimesterPeriodActual from "./SelectTrimesterPeriodActual";

import {
  markDowPeridActualGantt,
  thisTrimester,
} from "../GanttTrimester/trimester";
import {
  trimesterFormat,
  totalTrimesterPeriod,
  periodoTrimestral,
} from "../GanttTrimester/trimester";
import { primaryColor, secondaryColor } from "../../../../Config/colorConfig";
import CurrentTimeLine from "../CurrentTimeLine";
import { UseModalContext } from "../../../../Context/ModalContext";

interface propsProps {
  startDate: string;
  endDate: string;
  setActivitySelected: React.Dispatch<
    SetStateAction<allActivitiesProps | undefined>
  >;
  setShowModal: () => void;
  steps: stepProps[];
  setSteps: React.Dispatch<SetStateAction<stepProps[]>>;
  actvities: allActivitiesProps[];
  dependences: dependencesProps[];
}

const GanttTrimester: React.FC<propsProps> = (props) => {
  const { editPermission } = UseAuthAcessContext();
  const { setShowDependences, setModalNameHeader } = UseModalContext();
  const { stepId, indexPeriod, yearNumber, setStepId, setPrimarySelectColor } =
    UseGanttContext();
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);
  const [showDependencyMenu, setShowDependencyMenu] = useState<boolean>(false);
  const [xAxis, setXAxis] = useState<number>(0);
  const [yAxis, setYAxis] = useState<number>(0);

  // função que manipula o evento de clique com botão direito do mouse
  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault(); // previne o comportamento padrão do navegador para o clique com botão direito
    setShowDependencyMenu(true); // exibe o menu overlay
    // define a posição do menu overlay com base na posição do clique com botão direito do mouse
    setXAxis(event.clientX);
    setYAxis(event.clientY);
  };

  // função que manipula o evento de clique em qualquer lugar fora do menu overlay
  const handleMenuClose = () => {
    setShowDependencyMenu(false); // esconde o menu overlay
  };

  const oppenDependency = () => {
    setShowDependences?.();
    setModalNameHeader("Dependências");
  };

  useEffect(() => {
    periodoTrimestral(indexPeriod, props.startDate, props.endDate, yearNumber);
  }, []);

  useEffect(() => {
    if (editPermission === "Editor" || editPermission === "") {
      setIsEditAuthorized(true);
    } else if (editPermission === "Visualizacao") {
      setIsEditAuthorized(false);
    }
  }, [editPermission]);

  useEffect(() => {
    const stepIndex = props.steps.findIndex(
      (element) => element._id === stepId[0]
    );
    if (stepIndex !== -1) {
      props.steps[stepIndex].showActivity
        ? (props.steps[stepIndex].showActivity = false)
        : (props.steps[stepIndex].showActivity = true);
      props.setSteps([...props.steps]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  const oppenModalActivity = (
    activitySelected: allActivitiesProps,
    primarySelectColor: string
  ) => {
    if (isEditAuthorized) {
      props.setActivitySelected(activitySelected);
      props.setShowModal?.();
      setPrimarySelectColor?.(primarySelectColor);
    }
  };

  const clickShowOppenModalActitivity = (
    stepId: string,
    activity: allActivitiesProps,
    stepsLenght: number,
    primarySelectColor: string
  ) => {
    if (isEditAuthorized === true) {
      setStepId?.([stepId]);
      if (
        props.steps.filter((step) => step._id === stepId)[0].scaleY === 1 ||
        props.steps.filter((step) => step._id === stepId)[0].scaleY ===
          undefined
      ) {
        props.steps.filter((step) => step._id === stepId)[0].scaleY = -1;
        props.setSteps([...props.steps]);
      } else if (
        props.steps.filter((step) => step._id === stepId)[0].scaleY === -1
      ) {
        props.steps.filter((step) => step._id === stepId)[0].scaleY = 1;
        props.setSteps([...props.steps]);
      }
      if (stepsLenght === 1) {
        oppenModalActivity(activity, primarySelectColor);
      }
    }
  };

  return (
    <GanttContainer onClick={handleMenuClose}>
      {/**Linhas do gantt */}
      <GanttRowDays
        className="gantt__row"
        columsRepeat={trimesterFormat(indexPeriod).length}
        width="24vw"
      >
        <div className="gantt__row-first"></div>
        {trimesterFormat(indexPeriod).map((periodtrimester, index) => (
          <span key={index}>
            <SelectTrimesterPeriodActual
              period={periodtrimester}
              trimesterNumber={indexPeriod}
              yearNumber={yearNumber}
            />
          </span>
        ))}
      </GanttRowDays>

      {/**Colunas do Gantt */}
      <GanttColummSeparate
        className="gantt__row"
        columsRepeat={trimesterFormat(indexPeriod).length}
        width="24vw"
      >
        <span></span>
        {trimesterFormat(indexPeriod).map((period) => (
          <span>
            {markDowPeridActualGantt(
              moment().format("DD/MM/YYYY").toString(),
              indexPeriod + 1,
              yearNumber,
              period
            ) && <CurrentTimeLine />}
          </span>
        ))}
      </GanttColummSeparate>

      {/**Etapas e atividades*/}
      {props.steps.map((step, stepIndex) => (
        <GanttRow onContextMenu={handleContextMenu} width={"24vw"}>
          <div className="gantt__row-first">
            <Steps
              stepName={step.name}
              porcentage={step.percentage}
              backgroundColor={secondaryColor[stepIndex]}
              borderColor={primaryColor[stepIndex]}
              _id={step._id}
              dropDownDirection={step.scaleY}
              startDate={step.startDate}
              endDate={step.endDate}
            >
              {step.showActivity ? (
                props.actvities.map((activity) => {
                  if (activity.stepId === step._id) {
                    return (
                      <Activity
                        oppenModalActivity={() =>
                          oppenModalActivity(activity, primaryColor[stepIndex])
                        }
                        activityId={activity._id}
                        activityName={activity.activityName}
                        percentage={activity.percentage}
                        backgroundColor={secondaryColor[stepIndex]}
                      />
                    );
                  } else return null;
                })
              ) : (
                <></>
              )}
            </Steps>
            {/* <p>Inicio: <b>{step.startDate}</b> Fim: <b>{step.endDate}</b></p> */}
          </div>

          {/**Exibe a barra horizontal do gant para a etapa*/}
          <GantRowBars
            columsRepeat={totalTrimesterPeriod(indexPeriod)}
            width="auto"
          >
            {!step.showActivity ||
            (step.showActivity &&
              props.actvities.filter((item) => item.stepId === step._id)
                .length > 1) ? (
              <Li
                isEditAuthorized={isEditAuthorized}
                width={1}
                id={step.name}
                className="liContent"
                gridColum={periodoTrimestral(
                  indexPeriod,
                  step.startDate,
                  step.endDate,
                  yearNumber
                )}
                gridRow={stepIndex + 1}
                backgroundColor={primaryColor[stepIndex]}
                onClick={() =>
                  clickShowOppenModalActitivity(
                    step._id,
                    props.actvities.filter(
                      (activityFind) => activityFind.stepId === step._id
                    )[0],
                    props.actvities.filter((item) => item.stepId === step._id)
                      .length,
                    primaryColor[stepIndex]
                  )
                }
              />
            ) : (
              <></>
            )}
            {showDependencyMenu && (
              <MenuOverlay
                xAxis={xAxis}
                yAxis={yAxis}
                onClick={() => oppenDependency()}
              >
                <OptionButton>Dependências</OptionButton>
              </MenuOverlay>
            )}

            {/**Exibe a barra horizontal do gant para a atividade*/}
            {props.actvities.map((activity, activityIndex) =>
              activity.stepId === step._id ? (
                step.showActivity && (
                  <Li
                    isEditAuthorized={isEditAuthorized}
                    width={1}
                    gridColum={periodoTrimestral(
                      indexPeriod,
                      activity.startDate || "",
                      activity.endDate || "",
                      yearNumber
                    )}
                    gridRow={stepIndex + 1 + (activityIndex + 1)}
                    backgroundColor={primaryColor[stepIndex]}
                    border={"none"}
                    onClick={() =>
                      oppenModalActivity(activity, primaryColor[stepIndex])
                    }
                  />
                )
              ) : (
                <></>
              )
            )}
          </GantRowBars>
        </GanttRow>
      ))}
      {props.dependences.map((dependence) => (
        <Xarrow
          start={`${
            props.steps.find((step) => step.name === dependence.stepReference)
              ?.name
          }`} //can be react ref
          end={`${
            props.steps.find((step) => step._id === dependence.stepId)?.name
          }`} //or an id
          startAnchor={dependence.initAfter === "Fim" ? "right" : "left"}
          endAnchor={"left"}
          //labels={'começa após o ' + dependence.initAfter + ' de ' + dependence.stepReference}
          path="grid"
          headSize={6}
          strokeWidth={2}
          lineColor={"black"}
          headColor={"black"}
        />
      ))}
    </GanttContainer>
  );
};

export default GanttTrimester;
