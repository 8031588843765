import React from "react";

import StepSelection from "./StepSelection";

import { DemolitionActivityContextProvider } from "../../Context/ActivitiesContext/Demolition/DemolitionContext";
import { MasonaryActivityContextProvider } from "../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { ImpermeabilizationActivityContextProvider } from "../../Context/ActivitiesContext/Impermeabilization/ImpermeabilizationContext";
import { HydraulicContextProvider } from "../../Context/ActivitiesContext/Hydraulic/HidraulicContext";
import { EletricActivityContextProvider } from "../../Context/ActivitiesContext/Eletric/EletricContext";
import { AirConditionerActivityContextProvider } from "../../Context/ActivitiesContext/AirConditioner/AirConditionerContext";
import { FloorsAndCoveringsActivityContextProvider } from "../../Context/ActivitiesContext/FloorsAndCoverings/FloorsAndCoveringsContext";
import { LiningActivityContextProvider } from "../../Context/ActivitiesContext/Lining/LiningContext";
import { PaintActivityContextProvider } from "../../Context/ActivitiesContext/Paint/PaintContext";
import { TablewareAndMetalsActivityContextProvider } from "../../Context/ActivitiesContext/TablewareAndMetals/TablewareAndMetalsContext";
import { RoofActivityContextProvider } from "../../Context/ActivitiesContext/Roof/RoofContext";
import { GlassworkActivityContextProvider } from "../../Context/ActivitiesContext/Glasswork/GlassworkContext";
import { MarbleworkActivityContextProvider } from "../../Context/ActivitiesContext/Marblework/MarbleworkContext";
import { SawmillActivityContextProvider } from "../../Context/ActivitiesContext/Sawmill/SawmillContext";
import { WoodWorkActivityContextProvider } from "../../Context/ActivitiesContext/WoodWork";
import { DoorsAndWindowsActivityContextProvider } from "../../Context/ActivitiesContext/DoorsAndWindows/DoorsAndWindowsContext";
import { FramesActivityContextProvider } from "../../Context/ActivitiesContext/Frames/FramesContext";
import { FurnitureActivityContextProvider } from "../../Context/ActivitiesContext/Furniture/FurnitureContext";
import { ProjectStepActivityContextProvider } from "../../Context/ActivitiesContext/ProjectStep/ProjectStepContext";
import { CleaningActivityContextProvider } from "../../Context/ActivitiesContext/Cleaning/CleaningContext";
import { MovingActivityContextProvider } from "../../Context/ActivitiesContext/Moving/MovingContext";

const NewStep: React.FC = () => {
  return (


    <CleaningActivityContextProvider>
      <MovingActivityContextProvider>
        <ProjectStepActivityContextProvider>
          <DoorsAndWindowsActivityContextProvider>
            <WoodWorkActivityContextProvider>
              <FurnitureActivityContextProvider>
                <FramesActivityContextProvider>
                  <SawmillActivityContextProvider>
                    <MarbleworkActivityContextProvider>
                      <GlassworkActivityContextProvider>
                        <RoofActivityContextProvider>
                          <TablewareAndMetalsActivityContextProvider>
                            <PaintActivityContextProvider>
                              <LiningActivityContextProvider>
                                <FloorsAndCoveringsActivityContextProvider>
                                  <AirConditionerActivityContextProvider>
                                    <HydraulicContextProvider>
                                      <EletricActivityContextProvider>
                                        <ImpermeabilizationActivityContextProvider>
                                          <MasonaryActivityContextProvider>
                                            <DemolitionActivityContextProvider>
                                              <StepSelection />
                                            </DemolitionActivityContextProvider>
                                          </MasonaryActivityContextProvider>
                                        </ImpermeabilizationActivityContextProvider>
                                      </EletricActivityContextProvider>
                                    </HydraulicContextProvider>
                                  </AirConditionerActivityContextProvider>
                                </FloorsAndCoveringsActivityContextProvider>
                              </LiningActivityContextProvider>
                            </PaintActivityContextProvider>
                          </TablewareAndMetalsActivityContextProvider>
                        </RoofActivityContextProvider>
                      </GlassworkActivityContextProvider>
                    </MarbleworkActivityContextProvider>
                  </SawmillActivityContextProvider>
                </FramesActivityContextProvider>
              </FurnitureActivityContextProvider>
            </WoodWorkActivityContextProvider>
          </DoorsAndWindowsActivityContextProvider>
        </ProjectStepActivityContextProvider>
      </MovingActivityContextProvider>
    </CleaningActivityContextProvider>


  );
};

export default NewStep;
