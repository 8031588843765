import React from "react";
import { Container, LineElement, CircularElement } from "./CurrentTimeLine/style"

const CurrentTimeLine: React.FC = () => {
    return (
        <>
            <Container>
                <CircularElement />
                <LineElement />
            </Container>
        </>
    )
}

export default CurrentTimeLine