import React from "react";
import AttachmentFile from "../AttachmentFile";
import { AttachmentProps } from "../AttachmenControllertype"

const AttachmentController: React.FC<AttachmentProps> = (props) => {
    return (
        <>
            {props.attachmentList.map((element, index) =>
                element.responsible === props.responsible ? (
                    props.period === element.period ? (
                        <AttachmentFile archive={element.archive} attachmentList={props.attachmentList} index={index} responsible={element.responsible} setAttachmentList={props.setAttachmentList} />
                    ) : props.period === undefined ? (
                        <AttachmentFile archive={element.archive} attachmentList={props.attachmentList} index={index} responsible={element.responsible} setAttachmentList={props.setAttachmentList} />
                    ) : (<></>)
                ) : (<></>)
            )}
        </>
    )
}

export default AttachmentController