import { SupplierProps, BudgetsProps } from "../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../Config/dictionary"


export function suppliersOrder(interval: number, suppliers: SupplierProps[], budgets: BudgetsProps[]) {
    var suppliersOrder: SupplierProps[] = []

    console.log(suppliers)

    if (interval === 10) {
        /**Exibir somente os empreiteiros e construtores */
        var constructorsAndWorkers = suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker)
        for (let index = interval - 5; index < interval; index++) {
            if (constructorsAndWorkers[index]) {
                suppliersOrder.push(constructorsAndWorkers[index])
            }
        }
        return suppliersOrder
    } else {
        /**Exibir os fornecedores avulsos e empreiteiros/construtores */
        var simpleSuppplierList = suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier)
        var constructorsAndWorkers = suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker)

        suppliersOrder = simpleSuppplierList

        for (let index = interval - 5; index < interval; index++) {
            if (constructorsAndWorkers[index]) {
                suppliersOrder.push(constructorsAndWorkers[index])
            }
        }
        return suppliersOrder
    }
}   