import React, { useEffect } from "react"

import { UseMarbleworkActivityContext } from "../../../Context/ActivitiesContext/Marblework/MarbleworkContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const MarbleWorkController: React.FC = (props) => {
    const { marbleworkActivities, editMarbleWorkActivity } = UseMarbleworkActivityContext()
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Revestimento')
    }, [])

    useEffect(() => {
        if (marbleworkActivities.length > 0) {
            var activityEdited = marbleworkActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = activitySelected
            editMarbleWorkActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    useEffect(() => {
        if (marbleworkActivities.length > 0 && marbleworkActivities[0].activityType !== "") {
            setActivitySelected(marbleworkActivities[0].activityType)
        }
        marbleworkActivities.length > 0 && marbleworkActivities[0].activityType === "" ? (setActivitySelected('Revestimento')) : (<></>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="MarbleWorkStep" id="masonryStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Revestimento">Revestimento</option>
                <option value="Tento">Tento</option>
                <option value="Soleira">Soleira</option>
                <option value="Baguete">Baguete</option>
                <option value="Roda base">Roda base</option>
                <option value="Pias e bancadas">Pias e Bancadas</option>
                <option value="Prateleira">Prateleira</option>
                <option value="Materiais para marmoaria">Materiais</option>

            </select>
        </ActionSelect>
    </div>
}

export default MarbleWorkController