import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  justify-items: center;
  margin-top: 1.4rem;
`;

export const CircularElement = styled.div`
  background-color: #5774b6;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
`;

export const LineElement = styled.div`
  background-color: #5774b6;
  width: 0.2rem;
  height: 100vh;
  z-index: 2;
`;
