import React, { useState, useEffect } from "react";

import { UseImpermeabilizationActivityContext } from "../../../../Context/ActivitiesContext/Impermeabilization/ImpermeabilizationContext";
import { attributeType } from "../../../../Models/attributeType";
import { ImpermeabilizationFormType } from "../../../../Models/Impermeabilization/type";
import { Impermeabilization } from "../../../../Models/Impermeabilization/ImpermeabilizationModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const ImpermeabilizationStepForm: React.FC<stepFormProps> = (props) => {
  const { impermeabilizationActivities, indexImpermeabilizationActivitySelected, editImpermeabilizationActivity } = UseImpermeabilizationActivityContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<ImpermeabilizationFormType>(Impermeabilization)
  const [otherBoxAreaMaterialValue, setOtherBoxAreaMaterialValue] = useState<string>('')
  const [otherBoxAreaTypeValue, setOtherBoxAreaTypeValue] = useState<string>('')
  const [otherDryAreaMaterialValue, setOtherDryAreaMaterialValue] = useState<string>('')
  const [otherDryAreaTypeValue, setOtherDryAreaTypeValue] = useState<string>('')

  const [newForm, setNewForm] = useState<attributeType[]>([]);

  useEffect(() => {
    if (impermeabilizationActivities.length > 0) {
      let impermeabilizationActivityCopy = impermeabilizationActivities[indexImpermeabilizationActivitySelected]
      impermeabilizationActivityCopy.activityType = 'Impermeabilizacao'
      editImpermeabilizationActivity?.(indexImpermeabilizationActivitySelected, impermeabilizationActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexImpermeabilizationActivitySelected])

  useEffect(() => {
    if (impermeabilizationActivities.length > 0) {
      setItensFormList(impermeabilizationActivities[indexImpermeabilizationActivitySelected].Impermeabilization)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexImpermeabilizationActivitySelected, impermeabilizationActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemAreaBox(0)
      removeItemAreaSeca(0)
    }
  }, [])

  useEffect(() => {
    let allforms: attributeType[] = []

    itensFormList.AreaBox.map((areaBox) => {
      areaBox.attributes.forEach((element) => {
        if (element.taskName) {
          element.taskName = "Area box"
        }
      })
      allforms.unshift(areaBox)
    })

    itensFormList.AreaSeca.map((areaSeca) => {
      areaSeca.attributes.forEach((element) => {
        if (element.taskName) {
          element.taskName = "Area seca"
        }
      })
      allforms.unshift(areaSeca)
    })

    setNewForm(allforms)
    console.log(allforms)

  }, [itensFormList])

  console.log(newForm)

  const addAssignment = () => {
    if (assignment === "Area seca") {
      addAreaSeca({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Area box") {
      addAreaBox({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    }
  }


  const addAreaBox = (object: attributeType) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaBox.unshift(object)
    impermeabilizationActivitiesCopy[0].Impermeabilization = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const addAreaSeca = (object: attributeType) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaSeca.unshift(object)
    impermeabilizationActivitiesCopy[0].Impermeabilization = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }


  const removeItemAreaSeca = (index: number) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaSeca.splice(index, 1)
    impermeabilizationActivitiesCopy[0].Impermeabilization = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const removeItemAreaBox = (index: number) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaBox.splice(index, 1)
    impermeabilizationActivitiesCopy[0].Impermeabilization = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }


  const editAreaSecaValues = (value: string, indexItem: number, indexItemForm: number) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaSeca[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const editAreaSecaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaSeca[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const editAreaSecaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaSeca[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const editAreaBoxValues = (value: string, indexItem: number, indexItemForm: number) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaBox[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const editAreaBoxAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    let itensFormListCopy = itensFormList
    let impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaBox[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  const editAreaBoxUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const impermeabilizationActivitiesCopy = impermeabilizationActivities
    itensFormListCopy.AreaBox[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editImpermeabilizationActivity?.(0, impermeabilizationActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {(itensFormList.AreaSeca.length ? (
        <div>
          <p>Área seca</p>
          {itensFormList.AreaSeca.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {(itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAreaSecaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editAreaSecaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Chao ao teto">Chão ao teto</option>
                      <option value="Rodape de 15cm">Rodapé de 15cm</option>
                      <option value="Outros">Outros</option>
                      {otherDryAreaTypeValue ? (<option value={otherDryAreaTypeValue} selected>{otherDryAreaTypeValue}</option>) : null}
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={otherDryAreaTypeValue}
                            onChange={(e) => {
                              setOtherDryAreaTypeValue(e.target.value);
                            }}
                          />
                          <button onClick={() => editAreaSecaValues(otherDryAreaTypeValue, indexAttribute, indexItemForm)}>&#10003;</button>
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === 'Material' ? (
                  <div>
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAreaSecaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editAreaSecaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Manta liquida">Manta líquida</option>
                      <option value="Manta asfaltica">Manta asfáltica</option>
                      <option value="Argamassa polimérica">Argamassa polimerica</option>
                      <option value="Outros">Outros</option>
                      {otherDryAreaMaterialValue ? (<option value={otherDryAreaMaterialValue} selected>{otherDryAreaMaterialValue}</option>) : null}
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipomateriais" placeholder="Digite o tipo"
                            value={otherDryAreaMaterialValue}
                            onChange={(e) => {
                              setOtherDryAreaMaterialValue(e.target.value);

                            }}
                          />
                          <button onClick={() => editAreaSecaValues(otherDryAreaMaterialValue, indexAttribute, indexItemForm)}>&#10003;</button>
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) :
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAreaSecaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editAreaSecaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editAreaSecaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
              )
              ))}
              <div className="cancelX" onClick={() => removeItemAreaSeca(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}

        </div>
      ) : null)}

      {/* {newForm.map((form, index) => (
        form.attributes.map((item) => (
          <p>{item.itemName}</p>
        ))
      ))} */}

      {(itensFormList.AreaBox.length ? (
        <div>
          <p>Área box</p>
          {itensFormList.AreaBox.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {(itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAreaBoxAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editAreaBoxValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Chao ao teto">Chão ao teto</option>
                      <option value="Rodape de 15cm">Rodapé de 15cm</option>
                      <option value="Outros">Outros</option>
                      {otherBoxAreaTypeValue ? (<option value={otherBoxAreaTypeValue} selected>{otherBoxAreaTypeValue}</option>) : null}
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={otherBoxAreaTypeValue}
                            onChange={(e) => {
                              setOtherBoxAreaTypeValue(e.target.value);
                            }}
                          />
                          <button onClick={() => editAreaBoxValues(otherBoxAreaTypeValue, indexAttribute, indexItemForm)}>&#10003;</button>
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === 'Material' ? (
                  <div>
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAreaBoxAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => { editAreaBoxValues(e.target.value, indexAttribute, indexItemForm); console.log(otherBoxAreaMaterialValue) }}>
                      <option value="Manta liquida">Manta líquida</option>
                      <option value="Manta asfaltica">Manta asfáltica</option>
                      <option value="Argamassa polimérica">Argamassa polimerica</option>
                      <option value="Outros">Outros</option>
                      {otherBoxAreaMaterialValue ? (<option value={otherBoxAreaMaterialValue} selected>{otherBoxAreaMaterialValue}</option>) : null}
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipomateriais" placeholder="Digite o tipo"
                            value={otherBoxAreaMaterialValue}
                            onChange={(e) => {
                              setOtherBoxAreaMaterialValue(e.target.value);

                            }}
                          />
                          <button onClick={() => editAreaBoxValues(otherBoxAreaMaterialValue, indexAttribute, indexItemForm)}>&#10003;</button>
                        </InputActivity>

                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) :
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAreaBoxAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editAreaBoxValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editAreaBoxUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
              )
              ))}
              <div className="cancelX" onClick={() => removeItemAreaBox(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null)}
    </div>
  );
};

export default ImpermeabilizationStepForm;
