import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--primary-color);
  padding-top: 0.5rem;
  padding-right: 2rem;
  padding-left: 1.5rem;
  margin-top: 1px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 820px) {
    width: 78rem;
  }

  @media (max-width: 480px) {
    margin-top: 7rem;
    align-items: center;
    height: 22rem;
    width: 152rem;

    button {
      font-size: 75px;
      white-space: nowrap;
      height: 10rem;
      border-radius: 30px;
      margin-bottom: 10px;
      position: absolute;
      left: 112rem;
      top: 28.1rem;
    }
  }
`;

export const MobileMenuIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuOptions = styled.div`
  display: flex;
  margin-top: 0.5rem;
  .selected {
    font-weight: 500;
    color: var(--secondary-color);
    margin-right: 0;
  }

  .dropDownElement {
    @media (max-width: 480px) {
      display: none;
    }
  }

  .boxSelect {
    display: flex;
    margin-top: 2px;
    margin-right: 2rem;
    border-bottom: 3px solid var(--secondary-color);
    margin-bottom: 1rem;
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
    }
  }

  /*   @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 20px;
    text-align: center;
    justify-content: center;
  } */

  @media (max-width: 480px) {
    width: 148rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 70px;
    text-align: center;
    justify-content: start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    /* Estilizando a barra de rolagem no geral */
    ::-webkit-scrollbar {
      position: absolute;
      margin: 1rem; /* Margem superior */
      background-color: #e8e8e8; /* Cor de fundo */
      padding: 1rem;
      border-radius: 5px;
      height: 2vh;
    }

    /* Estilizando o scroll (cabo) da barra de rolagem */
    ::-webkit-scrollbar-thumb {
      background-color: #121317; /* Cor do scroll */
      border-radius: 5px;
    }

    button {
      font-size: 40px;
    }

    img {
      width: 1.5rem;
    }
  }
`;

export const Option = styled.a`
  color: #777777;
  font-weight: 400;
  margin-right: 2.5rem;
  margin-top: 0.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  img {
    margin-right: 5px;
  }
  :hover {
    color: var(--blue-light);
  }

  @media (max-width: 480px) {
    display: flex;
    justify-content: center;
    font-size: 90px;

    .boxSelect {
      display: flex;
      justify-content: center;
    }
  }
`;

export const Button = styled.button`
  background-color: var(--primary-color);
  padding: 0.75rem;
  border-radius: 4px;
  color: var(--secondary-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  border: 1px solid var(--secondary-color);
  :hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }

  img {
    width: 1rem;
    margin-right: 0.5rem;
  }

  label {
    cursor: pointer;
  }

  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 50px;
    height: 5rem;
  }
`;
