import React from "react";
import { UseUserContext } from "../../Context/UserContext";

import ClientPanel from "./clientPanel";
import AdminPanel from "./adminPanel";

const Home: React.FC = () => {
    const { typeUser } = UseUserContext()
    return (
        <>
            {typeUser === "Admin" ? (
                <AdminPanel />
            ) : typeUser === "Arquiteto" ? (
                <ClientPanel />
            ) : ('')}
        </>
    )
}

export default Home