import React, { useContext, useEffect, useState } from "react";

import { textTransform } from "../../../components/StepsForm/script";
import { DoorsAndWindows } from "../../../Models/DoorsAndWindows/DoorsAndWindowsModel";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { DoorsAndWindowsFormType } from "../../../Models/DoorsAndWindows/type";
import { MaterialsFormType } from "../../../Models/attributeType";
import { UserProviderProps } from "../../../types/typesEntitiesProject";
export interface activitiesDoorsAndWindowsProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  DoorsAndWindows: DoorsAndWindowsFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  doorsAndWindowsActivities: activitiesDoorsAndWindowsProps[];
  doorsAndWindowsActivityName: string;
  indexDoorsAndWindowsActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesDoorsAndWindowsProps[]>
  >;
  setNewDoorsAndWindowsActivity?: () => void;
  setDoorsAndWindowsActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexDoorsAndWindowsActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editDoorsAndWindowsActivity?: (
    index: number,
    activity: activitiesDoorsAndWindowsProps
  ) => void;
  deleteDoorsAndWindowsActivity?: (index: number) => void;
}

const activityContextDefault = {
  doorsAndWindowsActivities: [],
  doorsAndWindowsActivityName: "",
  indexDoorsAndWindowsActivitySelected: 0,
  setActivity: () => {},
  setNewDoorsAndWindowsActivity: () => {},
  setDoorsAndWindowsActivityName: () => {},
  setIndexDoorsAndWindowsActivitySelected: () => {},
  editDoorsAndWindowsActivity: () => {},
  deleteDoorsAndWindowsActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseDoorsAndWindowsActivityContext = () =>
  useContext(ActivitiesContext);

export const DoorsAndWindowsActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [doorsAndWindowsActivities, setActivity] = useState<
    activitiesDoorsAndWindowsProps[]
  >(activityContextDefault.doorsAndWindowsActivities);
  const [
    indexDoorsAndWindowsActivitySelected,
    setIndexDoorsAndWindowsActivitySelected,
  ] = useState<number>(0);
  const [doorsAndWindowsActivityName, setDoorsAndWindowsActivityName] =
    useState<string>("Portas e janelas");

  const editDoorsAndWindowsActivity = (
    index: number,
    activity: activitiesDoorsAndWindowsProps
  ) => {
    let activitiesEdited = doorsAndWindowsActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteDoorsAndWindowsActivity = (index: number) => {
    const activity = [...doorsAndWindowsActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewDoorsAndWindowsActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: doorsAndWindowsActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Portas e janelas",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        DoorsAndWindows: DoorsAndWindows,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        doorsAndWindowsActivities,
        doorsAndWindowsActivityName,
        indexDoorsAndWindowsActivitySelected,
        setActivity,
        setDoorsAndWindowsActivityName,
        setNewDoorsAndWindowsActivity,
        editDoorsAndWindowsActivity,
        deleteDoorsAndWindowsActivity,
        setIndexDoorsAndWindowsActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
