import React, { useState } from "react";
import axios from "axios"
import {
    Header,
    ButtonValidationCode,
    ButtonSaveNewPassword,
    ContentValidationForm,
    Form,
    InputValidation,
    OptionTypeRegister,
    FormValidateCodeArea,
    FormValidateArea,
    MessageConfirm,
    NewPasswordInput,
    PasswordConfirmation
} from "./style";

import LogoArquitetool from "../../assets/arquitetool.svg"


const PasswordResetValidation: React.FC = (props) => {
    const [validationCode, setValidationCode] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [step, setStep] = useState<number>(1)
    const [messageConfirmCode, setMessageConfirmCode] = useState<string>('');
    const [wrongPassword, setWrongPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const validate = () => {
        axios.post(`${process.env.REACT_APP_LOGIN_AUTENTICATION_URL}/validateCode`, { code: validationCode }).then((res) => {
            if (res.status === 200) {
                setStep(2)
                setEmail(res.data.email);
                setMessageConfirmCode('')
            }
        }).catch((err) => {
            console.error(err)
            setMessageConfirmCode('Código inválido')
        })
    }


    const resetPassword = () => {
        if (newPassword === confirmNewPassword) {
            axios.put(`${process.env.REACT_APP_LOGIN_AUTENTICATION_URL}/updatePassword`, { email, password: confirmNewPassword }).then((res) => {
                if (res.status === 200) {
                    window.location.href = '/';
                }
            }).catch((err) => {
                console.error(err)
            })
        } else {
            setWrongPassword('"Nova senha" está diferente da confirmação')
        }
    }

    return (
        <div>
            <Header>
                <img src={LogoArquitetool} alt="logo arquitetool" />
            </Header>
            <Form>


                {step === 1 ? (
                    <>
                        <OptionTypeRegister>
                            <div>
                                <input
                                    type="radio"
                                    id="newRegister"
                                    name="typeRegister"
                                    value=""
                                    checked
                                />
                                <label htmlFor="newRegister">
                                    <div>Passo 1: Digite o código recebido em seu email</div>
                                </label>
                            </div>

                            <div>
                                <input
                                    type="radio"
                                    id="dataPayment"
                                    name="typeRegister"
                                    value=""
                                    disabled
                                />
                                <label htmlFor="dataPayment">
                                    {" "}
                                    <div>Passo 2: Redefina sua senha</div>
                                </label>
                            </div>
                        </OptionTypeRegister>

                        <ContentValidationForm>
                            <FormValidateCodeArea>
                                <div>
                                    <label>Código:</label>
                                    <InputValidation
                                        type="text"
                                        name="validation-code"
                                        id="validation-code"
                                        placeholder="Digite ou cole o código recebido"
                                        onChange={(e) => setValidationCode(e.target.value)}
                                    />
                                </div>
                            </FormValidateCodeArea>
                            <MessageConfirm>{messageConfirmCode}</MessageConfirm>
                            <ButtonValidationCode type="submit" onClick={() => validate()}>Validar</ButtonValidationCode>
                            <br />

                        </ContentValidationForm>


                    </>

                ) : (
                    <>
                        <OptionTypeRegister>
                            <div>
                                <input
                                    type="radio"
                                    id="newRegister"
                                    name="typeRegister"
                                    value=""
                                    disabled
                                />
                                <label htmlFor="newRegister">
                                    <div>Passo 1: Digite o código recebido em seu email</div>
                                </label>
                            </div>

                            <div>
                                <input
                                    type="radio"
                                    id="dataPayment"
                                    name="typeRegister"
                                    value=""
                                    checked
                                />
                                <label htmlFor="dataPayment">
                                    {" "}
                                    <div>Passo 2: Redefina sua senha</div>
                                </label>
                            </div>
                        </OptionTypeRegister>

                        <ContentValidationForm>
                            <FormValidateArea>
                                <div>
                                    <div className="NewPasswordArea">
                                        <label>Nova senha:</label>
                                        <NewPasswordInput
                                            type="password"
                                            name="validation-code"
                                            id="validation-code"
                                            placeholder="Digite ou cole o código recebido"
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </div>
                                    <br />
                                    <div className="ConfirmNewPasswordArea">
                                        <label>Confirme a senha:</label>
                                        <InputValidation
                                            type="password"
                                            name="validation-code"
                                            id="validation-code"
                                            placeholder="Digite ou cole o código recebido"
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </FormValidateArea>

                            <PasswordConfirmation><div>{wrongPassword}</div></PasswordConfirmation>

                            <MessageConfirm>{messageConfirmCode}</MessageConfirm>
                            <ButtonSaveNewPassword type="submit" onClick={() => resetPassword()}>Salvar</ButtonSaveNewPassword>
                            <br />

                        </ContentValidationForm>


                    </>
                )}

            </Form>
        </div>
    )
}

export default PasswordResetValidation