import React from "react"
import { useParams } from "react-router-dom";
import { budgetsParams } from "../../../../types/paramId";
import { Button } from "../../../../components/stylesDomElements/htmlStyledElements"
import { Container, ContentList, ItemName, ContainerButton, RemoveContent, FooterButton } from "../style"

import { UseDetailmentBudgetActivityContext } from "../../Context/DetailmentBudgetActivityContext"

import { addItem, editItem, removeItem } from "./scripts/script"

import addIcon from "../../../../assets/addIcon.svg"
import axios from "axios";

const ListNecessities: React.FC = () => {
    const paramUrl = useParams();

    const {
        documentsList,
        beforeInitService, setBeforeInitService,
        duringService, setDuringService,
        afterService, setafterService
    } = UseDetailmentBudgetActivityContext()

    const sendDetails = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/newListNecessitiesDetaiment`, {
            supplierId: paramUrl.supplierId,
            activityId: paramUrl.activityId,
            beforeInitService,
            duringService,
            afterService
        }).then(res => {
            console.log(res)
        })

        const formData = new FormData()
        documentsList.forEach((element) => {
            formData.append("file", element.photo[0][0])
            formData.append("typeDocument", element.typeDocument)
            formData.append("numberDocument", element.numberDocument)
        })

        formData.append("supplierId", paramUrl.supplierId || '')
        formData.append("activityId", paramUrl.activityId || '')
        axios.post(`${process.env.REACT_APP_BASE_URL}/sendDocumentsDetailment`, formData)

        window.location.reload()
    }

    return (
        <div>
            <Container>
                <ItemName className="ItemName">Antes do início do serviço</ItemName>
                {beforeInitService.map((element, index) => (
                    <ContentList key={index}>
                        <div className="inputElement">
                            <input type="text" value={element} onChange={(e) => { editItem(e.target.value, beforeInitService, index, setBeforeInitService) }} />
                        </div>
                        <RemoveContent onClick={() => removeItem(index, beforeInitService, setBeforeInitService)}>X</RemoveContent>
                    </ContentList>
                ))}

                <ContainerButton>
                    <Button onClick={() => addItem(setBeforeInitService)}>
                        <img src={addIcon} alt="Iconde de adição" />
                        Adicionar necessidade
                    </Button>
                </ContainerButton>

                <ItemName className="ItemName">Durante o serviço</ItemName>
                {duringService.map((element, index) => (
                    <ContentList key={index}>
                        <div className="inputElement">
                            <input type="text" value={element} onChange={(e) => { editItem(e.target.value, duringService, index, setDuringService) }} />
                        </div>
                        <RemoveContent onClick={() => removeItem(index, duringService, setDuringService)}>X</RemoveContent>
                    </ContentList>
                ))}

                <ContainerButton>
                    <Button onClick={() => addItem(setDuringService)}>
                        <img src={addIcon} alt="Iconde de adição" />
                        Adicionar necessidade
                    </Button>
                </ContainerButton>

                <ItemName className="ItemName">Após serviço</ItemName>
                {afterService.map((element, index) => (
                    <ContentList key={index}>
                        <div className="inputElement">
                            <input type="text" value={element} onChange={(e) => { editItem(e.target.value, afterService, index, setafterService) }} />
                        </div>
                        <RemoveContent onClick={() => removeItem(index, afterService, setafterService)}>X</RemoveContent>
                    </ContentList>
                ))}

                <ContainerButton>
                    <Button onClick={() => addItem(setafterService)}>
                        <img src={addIcon} alt="Iconde de adição" />
                        Adicionar necessidade
                    </Button>
                </ContainerButton>
            </Container>

            <FooterButton>
                <Button onClick={() => sendDetails()}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enviar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
            </FooterButton>
        </div>
    )
}

export default ListNecessities