import React, { SetStateAction, useEffect, useState } from "react";
import axios from "axios";

import {
  GanttContainer,
  GanttRowDays,
  GanttColummSeparate,
  GanttRow,
  GantRowBars,
  Li,
  MenuOverlay,
  OptionButton,
  DescriptionInfoCronogram,
} from "../style";

import { UseGanttContext } from "../../../../Context/GanttContext";
import { UseUserContext } from "../../../../Context/UserContext";
import { UseAuthAcessContext } from "../../../../Context/AuthAcessContext";

import { allActivitiesProps } from "../../../../types/typesEntitiesProject";
import { stepProps, dependencesProps } from "../../type";

import Steps from "../../../../components/Steps";
import SelectCronogramPeriodActual from "./SelectCronogramPeriodActual";

import { formateMontsCronogramExibition } from "../../../../scripts/formatDay";
import { markdowSelectPeriod, yearlyMonths } from "./cronogram";
import { primaryColor, secondaryColor } from "../../../../Config/colorConfig";
import CurrentTimeLine from "../CurrentTimeLine";
import Xarrow from "react-xarrows";
import { UseModalContext } from "../../../../Context/ModalContext";

interface propsProps {
  startDate: string;
  endDate: string;
  setActivitySelected: React.Dispatch<
    SetStateAction<allActivitiesProps | undefined>
  >;
  setShowModal: () => void;
  steps: stepProps[];
  setSteps: React.Dispatch<SetStateAction<stepProps[]>>;
  actvities: allActivitiesProps[];
  dependences: dependencesProps[];
}

const GanttCronogram: React.FC<propsProps> = (props) => {
  const { projectSelected } = UseUserContext();
  const { editPermission } = UseAuthAcessContext();
  const { setShowDependences, setModalNameHeader } = UseModalContext();
  const { yearNumber, setStepId } = UseGanttContext();
  const [suppliersBudgetSelected, setSuppliersBudgetSelected] = useState<
    {
      supplierName: string;
      stepId: string;
    }[]
  >([]);
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);
  const [showDependencyMenu, setShowDependencyMenu] = useState<boolean>(false);
  const [xAxis, setXAxis] = useState<number>(0);
  const [yAxis, setYAxis] = useState<number>(0);

  // função que manipula o evento de clique com botão direito do mouse
  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    stepId: string
  ) => {
    setStepId?.([stepId]);
    event.preventDefault(); // previne o comportamento padrão do navegador para o clique com botão direito
    setShowDependencyMenu(true); // exibe o menu overlay
    // define a posição do menu overlay com base na posição do clique com botão direito do mouse
    setXAxis(event.clientX);
    setYAxis(event.clientY);
  };

  // função que manipula o evento de clique em qualquer lugar fora do menu overlay
  const handleMenuClose = () => {
    setShowDependencyMenu(false); // esconde o menu overlay
  };

  const oppenDependency = () => {
    setShowDependences?.();
    setModalNameHeader("Dependências");
  };

  useEffect(() => {
    if (editPermission === "Editor" || editPermission === "") {
      setIsEditAuthorized(true);
    } else if (editPermission === "Visualizacao") {
      setIsEditAuthorized(false);
    }
  }, [editPermission]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/listSelectedBudgetsStep/${projectSelected}`
        )
        .then((res) => {
          setSuppliersBudgetSelected(res.data);
        });
    }
  }, [projectSelected]);

  return (
    <GanttContainer onClick={handleMenuClose}>
      {/**Linhas do gantt */}
      <GanttRowDays className="gantt__row" columsRepeat={12} width="37vw">
        <div className="gantt__row-first"></div>
        {yearlyMonths().map((element, index) => (
          <span key={index}>
            <SelectCronogramPeriodActual
              period={element}
              yearNumber={yearNumber}
            />{" "}
          </span>
        ))}
      </GanttRowDays>

      {/**Colunas do Gantt */}
      <GanttColummSeparate
        className="gantt__row"
        columsRepeat={12}
        width="37vw"
      >
        <span></span>
        {yearlyMonths().map((period, index) => (
          <span key={index}>
            {markdowSelectPeriod(period, yearNumber) && <CurrentTimeLine />}{" "}
          </span>
        ))}
      </GanttColummSeparate>

      <DescriptionInfoCronogram>
        <p>Etapas</p>
        <p>Início</p>
        <p>Duração (d)</p>
        <p>Término</p>
      </DescriptionInfoCronogram>

      {/**Etapas e atividades*/}
      {props.steps.map((step, stepIndex) => (
        <GanttRow
          onContextMenu={(e) => handleContextMenu(e, step._id)}
          width={"37vw"}
        >
          <div className="gantt__row-first">
            <Steps
              stepName={step.name}
              porcentage={step.percentage}
              backgroundColor={secondaryColor[stepIndex]}
              borderColor={primaryColor[stepIndex]}
              _id={step._id}
              dropDownDirection={step.scaleY}
              startDate={step.startDate}
              endDate={step.endDate}
            />
            <p>
              {suppliersBudgetSelected.filter(
                (supplier) => supplier.stepId === step._id
              ).length > 0
                ? suppliersBudgetSelected.filter(
                    (supplier) => supplier.stepId === step._id
                  )[0].supplierName
                : "A definir"}
            </p>
          </div>

          {/**Exibe a barra horizontal do gant para a etapa*/}
          <GantRowBars columsRepeat={365} width="auto">
            {!step.showActivity ||
            (step.showActivity &&
              props.actvities.filter((item) => item.stepId === step._id)
                .length > 1) ? (
              <Li
                isEditAuthorized={isEditAuthorized}
                width={1}
                id={step.name}
                className="liContent"
                gridColum={formateMontsCronogramExibition(
                  step.startDate,
                  step.endDate,
                  yearNumber
                )}
                gridRow={stepIndex + 1}
                backgroundColor={primaryColor[stepIndex]}
              />
            ) : (
              <></>
            )}
          </GantRowBars>
          {showDependencyMenu && (
            <MenuOverlay
              xAxis={xAxis}
              yAxis={yAxis}
              onClick={() => oppenDependency()}
            >
              <OptionButton>Dependências</OptionButton>
            </MenuOverlay>
          )}
        </GanttRow>
      ))}
      {props.dependences.map((dependence) => (
        <Xarrow
          start={`${
            props.steps.find((step) => step.name === dependence.stepReference)
              ?.name
          }`} //can be react ref
          end={`${
            props.steps.find((step) => step._id === dependence.stepId)?.name
          }`} //or an id
          startAnchor={dependence.initAfter === "Fim" ? "right" : "left"}
          endAnchor={"left"}
          path="grid"
          animateDrawing
          headSize={6}
          strokeWidth={2}
          lineColor={"black"}
          headColor={"black"}
        />
      ))}
    </GanttContainer>
  );
};

export default GanttCronogram;
