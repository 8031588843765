import React, { useEffect } from "react"

import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const LiningController: React.FC = () => {
  const { activitySelected, setActivitySelected } = UseStepContext()

  useEffect(() => {
    setActivitySelected('Forro')
  }, [])

  return <div>
    <ActionSelect>
      Atividade:
      <select value={activitySelected} name="LiningStep" id="LiningStep" onChange={(e) => setActivitySelected(e.target.value)}>
        <option value="Forro">Forro</option>
        <option value="Materiais para forro">Materiais</option>

      </select>
    </ActionSelect>
  </div>
}

export default LiningController