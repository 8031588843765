import axios from "axios"

import { activitiesProps } from "../../../types/typesEntitiesProject"

export const sendActivityFile = (activities: activitiesProps[], typeRequest: string) => {
    var activityFile: { projectFile: string }[]
    const formData = new FormData()
    activities.forEach((element) => {
        if (element.projetoMarcenaria) {
            element.projetoMarcenaria.forEach((project) => {
                if (project.arquivoProjeto.length > 0) {
                    formData.append("file", project.arquivoProjeto[0][0])
                }
                project.arquivoProjeto = []
            })
        }
    })

    axios
        .post(`${process.env.REACT_APP_BASE_URL}/newActivityImageFile`, formData)
        .then((res) => {
            activityFile = res.data

            if (activityFile.length > 0) {
                activities.forEach((element) => {
                    if (element.projetoMarcenaria) {
                        element.projetoMarcenaria.forEach((project, index) => {
                            project.link = activityFile[index].projectFile
                        })
                    }
                })
            }

            if (typeRequest === "activityReplace") {
                axios.put(`${process.env.REACT_APP_BASE_URL}/activityReplace`, { bodyRequest: activities[0] }).then(res => {
                    if (res.status === 200)
                        window.location.reload()

                })
            } else if (typeRequest === "sendCotations") {
                axios.post(`${process.env.REACT_APP_BASE_URL}/sendCotations`, activities[0]).then(res => {
                    if (res.status === 200)
                        window.location.reload()
                })
            }
        });
}