import React, { useState, useEffect } from "react";
import { UseDoorsAndWindowsActivityContext } from "../../../../Context/ActivitiesContext/DoorsAndWindows/DoorsAndWindowsContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid, ContainerGridJanela } from "./style"

import { DoorsAndWindowsFormType } from "../../../../Models/DoorsAndWindows/type";
import { attributeType } from "../../../../Models/attributeType";
import { DoorsAndWindows } from "../../../../Models/DoorsAndWindows/DoorsAndWindowsModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const DoorsAndWindowsStepForm: React.FC<stepFormProps> = (props) => {
  const { doorsAndWindowsActivities, editDoorsAndWindowsActivity, indexDoorsAndWindowsActivitySelected } = UseDoorsAndWindowsActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<DoorsAndWindowsFormType>(DoorsAndWindows)
  const [otherDoorTypeValue, setOtherDoorTypeValue] = useState<string>('')

  useEffect(() => {
    if (doorsAndWindowsActivities.length > 0) {
      let doorsAndWindowsActivityCopy = doorsAndWindowsActivities[indexDoorsAndWindowsActivitySelected]
      doorsAndWindowsActivityCopy.activityType = "Portas e janelas"
      editDoorsAndWindowsActivity?.(indexDoorsAndWindowsActivitySelected, doorsAndWindowsActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexDoorsAndWindowsActivitySelected])


  useEffect(() => {
    if (doorsAndWindowsActivities.length > 0) {
      setItensFormList(doorsAndWindowsActivities[indexDoorsAndWindowsActivitySelected].DoorsAndWindows);
    }
    console.log(doorsAndWindowsActivities)
    console.log(indexDoorsAndWindowsActivitySelected)
    console.log(DoorsAndWindows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexDoorsAndWindowsActivitySelected, doorsAndWindowsActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemJanela(0)
      removeItemPorta(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Porta") {
      addPorta({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Largura',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: 'De correr'
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Janela") {
      addJanela({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura esquadria',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Largura esquadria',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Altura peitoril',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    }
  }

  const addPorta = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Porta.push(object)
    doorsAndWindowsActivitiesCopy[0].DoorsAndWindows = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const addJanela = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Janela.push(object)
    doorsAndWindowsActivitiesCopy[0].DoorsAndWindows = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const removeItemPorta = (index: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Porta.splice(index, 1)
    doorsAndWindowsActivitiesCopy[0].DoorsAndWindows = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const removeItemJanela = (index: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Janela.splice(index, 1)
    doorsAndWindowsActivitiesCopy[0].DoorsAndWindows = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }


  const editPortaValues = (value: string, indexItem: number, indexDoorsAndWindows: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Porta[indexDoorsAndWindows].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const editPortaAttributeName = (value: string, indexItem: number, indexDoorsAndWindows: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Porta[indexDoorsAndWindows].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const editPortaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Porta[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const editJanelaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Janela[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const editJanelaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Janela[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  const editJanelaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const doorsAndWindowsActivitiesCopy = doorsAndWindowsActivities
    itensFormListCopy.Janela[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editDoorsAndWindowsActivity?.(0, doorsAndWindowsActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.Porta.length ? (
        <div>
          <P>Porta</P>
          {itensFormList.Porta.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPortaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPortaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="De correr">De correr</option>
                      <option value="De abrir">De abrir</option>
                      <option value="Basculante">Basculante</option>
                      <option value="Pivotante">Pivotante</option>
                      <option value="Sanfonada">Sanfonada</option>
                      <option value="Camarão">Camarão</option>
                      <option value="Balcão">Balcão</option>
                      <option value="Holandesa">Holandesa</option>
                      <option value="Bang bang">Bang bang</option>
                      <option value="Veneziana">Veneziana</option>
                      <option value="De rolo">De rolo</option>
                      <option value="Outros">Outros</option>
                      {otherDoorTypeValue ? (<option value={otherDoorTypeValue} selected>{otherDoorTypeValue}</option>) : null}

                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={otherDoorTypeValue}
                            onChange={(e) => setOtherDoorTypeValue(e.target.value)}
                          />
                          <button onClick={() => editPortaValues(otherDoorTypeValue, indexAttribute, indexItemForm)}>&#10003;</button>

                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPortaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPortaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editPortaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPorta(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.Janela.length ? (
        <div>
          <P>Janela</P>
          {itensFormList.Janela.map((itemForm, indexItemForm) => (
            <ContainerGridJanela key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editJanelaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editJanelaValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editJanelaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemJanela(indexItemForm)}>X</div>
            </ContainerGridJanela>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
}

export default DoorsAndWindowsStepForm;
