import React from "react";
import { allActivitiesProps } from "../../../types/typesEntitiesProject"

import MaterialsDescription from "../Materials/index";
import DemolitionDescription from "./Demolition";

interface props {
    activity: allActivitiesProps
}

const DemolitionStepDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Demolicao" ? (<DemolitionDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}
        </>
    )
}

export default DemolitionStepDescription