import styled from "styled-components";

export const ContainerButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    a{
        color:#000;
        img {
            margin-top: 0.2rem;
        }
    }

    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label>div{
        background-color: var(--secondary-color);
        border: 1px solid #fff;
        color: #fff;
        border: 1px solid #00DACD;
        cursor: pointer;
    }
`

