import styled from "styled-components";

interface ActivityNameContainerProps {
    backgroundColor: string
}

export const ActivityDescriptionContainer = styled.div`
    background-color: #F1F2F3;
    padding: 1.5rem;
    padding-bottom: 2rem;
    border-radius: 0.3rem;
    max-width: 30vw;
    min-width: 25vw;
   
    max-height: 58vh;
    overflow-y: auto;
    .bold{
        color: #141414;
        font-weight: 500;
    }

    h4{
        font-weight: 500;
    }

    p{
        color: #777777;
        font-weight: 400;
    }

    .item{
        margin-top: 1.5rem;

        .line{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:0.5rem;
        }

        a{
            color: #777777;
            text-decoration: underline;
        }
    }
`
export const ModifyActivity = styled.div`
    display:flex;
    justify-content:end;
    margin-top:1rem;
    cursor:pointer;
    p{
        padding:0.3rem;
        text-decoration:underline;
        color:#141414;
        :hover{
            font-weight:500;
        }
    }
`

export const ActivityNameContainer = styled.div<ActivityNameContainerProps>`
    background-color: ${props => props.backgroundColor};
    padding: 1rem;
    color: #fff;
    text-align: center;
    border-radius: 0.3rem;
`

export const Link = styled.a`

    p{
        cursor: pointer;
        max-width: 33ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const Description = styled.div`

    p{
        cursor: pointer;
        max-width: 33ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const TooltipText = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  font-weight:400;
  display: flex;
  align-items:center;
    
  img{
      max-width:0.8rem;
  }

  input{
      border: none;
      font-size: 0.9rem;
      color: #777777;
      width: 8rem;
      text-overflow: ellipsis;
      background-color: transparent;
  }
`
export const TooltipBox = styled.div`
   border-radius: 4px;
   font-size:0.65rem;
   position:absolute;
`
export const TooltipCard = styled.div`
    ${TooltipText}:hover + ${TooltipBox} {
        visibility: visible;
        color: #777777;
        background-color: #fff;
        border: 1px solid rgba(119, 119, 119, 0.8);
        width: 230px;
        padding: 0.5rem 0.5rem;
        border-radius: 4px;
    }
`