import styled from "styled-components";

export const DocumentContainer = styled.div`
    margin-top: 1rem;
    .ContainerCheckBox{
        margin-bottom: 0;
    }
    ul{
        margin-top: 0;
    }
`

export const DocumentItem = styled.a`
    color: #777777;
    font-weight: 400;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-left: 1rem;

    img{
        padding: 0.2rem;
        width: 1.8rem;
        margin-right: 0.5rem;
    }
`