import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Authresponse } from "./enum";
import { verify } from "jsonwebtoken";
import { UseUserContext } from "../../Context/UserContext";
import { NoAuthorizedContainer } from "./style";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";

import ConstructorManagement from "../ConstructionManagement";
import CotationMap from "../CotationMap";
import FollowUp from "../FollowUpProject";
import InformationProject from "../InformationProject";
import ListManagement from "../ListManagement";
import MySuppliers from "../MySuppliers";
import PlantsProject from "../PlantsProject";
import { UseGanttContext } from "../../Context/GanttContext";
import WeeklySummary from "../WeeklySummary";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Auth: React.FC = () => {
  const { setTypeUser, setUserId, setprojectSelected } = UseUserContext();
  const {
    actualPage,
    setfeatures,
    setAcessLink,
    setActualPage,
    setEditPermission,
  } = UseAuthAcessContext();
  const { setVisualizationType } = UseGanttContext();
  const query = useQuery();
  const [authorized, setAutorized] = useState<string>("error");

  useEffect(() => {
    checkLink();
  }, []);

  async function checkLink() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkLink`,
        {
          link: window.location.href,
        }
      );
      const data = response.data;
      if (data.linkExists) {
        authToken(query.get("token") || "");
      } else {
        /* window.location.href="/" */
        const timeout = setTimeout(function () {
          window.location.href = "/";
        }, 3000); //Após 3 segundos a função é executada.
      }
    } catch (error) {
      /* window.location.href="/" */
      const timeout = setTimeout(function () {
        window.location.href = "/";
      }, 3000); //Após 3 segundos a função é executada.
    }
  }

  function authToken(token: string) {
    var dataDecodeJwt: any;
    if (token) {
      localStorage.setItem("token", token);
      verify(token, process.env.REACT_APP_SECRET_KEY_JWT || "", (err, data) => {
        dataDecodeJwt = data;
        if (err) {
          setAutorized(Authresponse.error);
        } else {
          setAutorized(Authresponse.sucess);
          setTypeUser?.("architect");
          setUserId?.(dataDecodeJwt.architectId);
          setprojectSelected?.(dataDecodeJwt.projectId);
          setfeatures(dataDecodeJwt.features);
          setAcessLink(true);
          setActualPage(dataDecodeJwt.features[0]);
          setEditPermission(dataDecodeJwt.editAuthorization);
        }
      });
    } else {
      setAutorized(Authresponse.noToken);
    }
  }

  return (
    <div>
      {authorized === Authresponse.error ||
      authorized === Authresponse.noToken ? (
        <NoAuthorizedContainer>
          <h2>Esta página já não está disponível</h2>
          <p>Link inválido ou desativado</p>
        </NoAuthorizedContainer>
      ) : (
        <></>
      )}

      {authorized === Authresponse.sucess &&
        (actualPage === "Cronograma" ? (
          <>
            <ConstructorManagement />
            {setVisualizationType?.("Only-gantt")}
          </>
        ) : actualPage === "Cronograma de obra" ? (
          <>
            <ConstructorManagement />
            {setVisualizationType?.("Cronogram")}
          </>
        ) : actualPage === "Mapa de cotacao" ? (
          <CotationMap />
        ) : actualPage === "Acompanhamento" ? (
          <FollowUp />
        ) : actualPage === "Infos" ? (
          <InformationProject />
        ) : actualPage === "Lista" ? (
          <ListManagement />
        ) : actualPage === "Fornecedores" ? (
          <MySuppliers />
        ) : actualPage === "Plantas" ? (
          <PlantsProject />
        ) : actualPage === "Resumo semanal" ? (
          <WeeklySummary />
        ) : (
          <></>
        ))}
    </div>
  );
};

export default Auth;
