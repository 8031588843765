import styled from "styled-components";

export const Container = styled.div`
    width: 80vw;
`

export const FlexContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 1rem;
`

export const ElementDescribleShowItens = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ContainerListPendences = styled.div`
    background-color: #ffffff;
    padding: 2rem;
    border: 1px solid #F1F2F3;
    padding-bottom: 2rem;
    border-radius: 0.3rem;
    height: 25rem;
    width: 25rem;
    overflow-y: auto;

    h3{
        font-weight: 500;
    }

    ul{
        margin-top: 1rem;
        li{
            list-style: none;
        }
    }
`

export const Input = styled.input`
    width: 100%;
    background-color:transparent;
    padding:0.3rem;
    border:none;
    font-weight:bold;
    font-size: 0.9rem;

    cursor: pointer;
`

export const SendFile=styled.img`
    margin-right: 1rem;
    cursor: pointer;
`

export const AttachmentList=styled.ul`
    display: block;
    padding: 0 1.5rem;
    a{
        display: flex;
        align-items: center;
        color: #777777;
        font-size: 0.8rem;
    }
    img{
        width: 1.5rem;
        margin-right: 0.25rem;
    }
`

export const ActivityDateContainer=styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 0.75rem;

    button{
        color: var(--secondary-color);
        padding: 0.25rem;
        border: 1px solid var(--secondary-color);
        border-radius: 0.3rem;
        background-color: #fff;
        cursor: pointer;
        :hover {
            background-color: var(--secondary-color);
            color: var(--primary-color);
        }
    }
`

