import styled from "styled-components";

export const Container=styled.div`
    background-color: #fff;
    position: absolute;
    margin-top: 3rem;
    z-index: 4;
    padding: 0.15rem;
    border-radius: 0.3rem;
    border: 1px solid #777777;
    p {
        font-size: 10px;
        color: #777777;
        margin-left: 5px;
    }
    div{
        display: flex;
        margin: 0.25rem;
        
        .Option{
            margin: 0;
        }
    }
`