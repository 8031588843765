import React, { useRef } from "react";
import Xarrow from "react-xarrows";

/* "#FF0000",
  "#FF8A00",
  "#0085FF",
  "#ffd900",
  "#FF008A",
  "#00DB3D",
  "#00E0FF",
  "#015200",
  "#6E36A9", */

const boxStyle = { padding: "5px", marginTop: '5rem', };
const demo = { width: '7rem', padding: '1rem', backgroundColor: '#FF0000', display: 'flex', alignItems: 'center', justifyContent: 'left', border: '1px 0 0 0 solid black' }
const alv = { width: '7rem', padding: '1rem', backgroundColor: '#FF8A00', display: 'flex', alignItems: 'center', marginLeft: '8rem', justifyContent: 'left', border: '1px 0 0 0 solid black' }
const elet = { width: '7rem', padding: '1rem', backgroundColor: '#0085FF', display: 'flex', alignItems: 'center', marginLeft: '30rem', justifyContent: 'left', border: '1px 0 0 0 solid black' }


const Arrows: React.FC = () => {
    const box1Ref = useRef(null);
    return (
        <div>
            <h2 style={{ textAlign: 'center' }}>react-xarrows</h2>
            <div style={{ padding: '10rem', display: 'grid', gridRowGap: '2rem' }}>
                <div>
                    <p id="demo" ref={box1Ref} style={demo}>Demolição</p>
                </div>
                <div>
                    <p id="alv" style={alv}>Alvenaria</p>
                </div>
                <div>
                    <p id="elet" ref={box1Ref} style={elet}>Elétrica</p>
                </div>


                <Xarrow
                    start={'demo'} //can be react ref
                    end="alv" //or an id
                    startAnchor={'left'}
                    endAnchor={'left'}
                    path="smooth" // Estilo da linha
                    dashness={false} //Tipo da linha
                    _cpx1Offset={-120}
                    _cpy1Offset={50}
                />

                <Xarrow
                    start={'alv'} //can be react ref
                    end="elet" //or an id
                    startAnchor={'right'}
                    endAnchor={'left'}
                    path="grid" // Estilo da linha
                    dashness={false} //Tipo da linha
                />
            </div>
        </div>

    );
}

export default Arrows;