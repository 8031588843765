import React, { useContext, useState } from "react";

import { projectPeriodProps } from "../types/constructionManagement";

import { arrayDaysMonthActualy } from "../scripts/daysMonth";
import { monthActualy, yearActualy } from "../scripts/dateActualy";
import { UserProviderProps } from "../types/typesEntitiesProject";

interface GanttContextTipes {
  stepId: string[];
  renderActivitiesGantt: boolean;
  primarySelectColor: string;
  projectPeriod?: projectPeriodProps[];
  daysMonthSelected: number[];
  monthNumber: number;
  yearNumber: number;
  filterPeriod: string;
  filterStatus: string;
  filterSteps: string;
  visualizationType: string;
  indexPeriod: number;
  setMonthNumber?: React.Dispatch<React.SetStateAction<number>>;
  setYearNumber?: React.Dispatch<React.SetStateAction<number>>;
  setFilterPeriod: React.Dispatch<React.SetStateAction<string>>;
  setPrimarySelectColor?: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus?: React.Dispatch<React.SetStateAction<string>>;
  setFilterSteps?: React.Dispatch<React.SetStateAction<string>>;
  setDaysMonthSelected?: React.Dispatch<React.SetStateAction<number[]>>;
  setProjectPeriod?: React.Dispatch<
    React.SetStateAction<projectPeriodProps[] | any>
  >;
  setStepId?: React.Dispatch<React.SetStateAction<string[]>>;
  setRenderActivitiesGatt?: () => void;
  setVisualizationType?: React.Dispatch<React.SetStateAction<string>>;
  setIndexPeriod: React.Dispatch<React.SetStateAction<number>>;
}

const defaultState = {
  steps: [],
  daysMonthSelected: [],
  primarySelectColor: "",
  monthNumber: 0,
  yearNumber: new Date().getFullYear(),
  filterPeriod: "",
  filterStatus: "",
  filterSteps: "",
  stepId: [""],
  renderActivitiesGantt: true,
  visualizationType: "Only-gantt",
  indexPeriod: 0,
  setVisualizationType: () => {},
  setFilterPeriod: () => {},
  setIndexPeriod: () => {},
};

const GanttContext = React.createContext<GanttContextTipes>(defaultState);

export const UseGanttContext = () => useContext(GanttContext);

export const GanttProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [projectPeriod, setProjectPeriod] = useState<projectPeriodProps[]>([]);
  const [monthNumber, setMonthNumber] = useState<number>(monthActualy());
  const [yearNumber, setYearNumber] = useState<number>(yearActualy());
  const [indexPeriod, setIndexPeriod] = useState<number>(0);

  const [primarySelectColor, setPrimarySelectColor] = useState<string>("");
  const [filterPeriod, setFilterPeriod] = useState<string>("month");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterSteps, setFilterSteps] = useState<string>("");
  const [daysMonthSelected, setDaysMonthSelected] = useState<number[]>(
    arrayDaysMonthActualy()
  );

  const [visualizationType, setVisualizationType] =
    useState<string>("Only-gantt");

  const [renderActivitiesGantt, setRender] = useState(
    defaultState.renderActivitiesGantt
  );

  const [stepId, setStepId] = useState<string[]>(defaultState.stepId);

  const setRenderActivitiesGatt = () => {
    setRender(!renderActivitiesGantt);
  };

  return (
    <GanttContext.Provider
      value={{
        stepId,
        projectPeriod,
        primarySelectColor,
        daysMonthSelected,
        monthNumber,
        filterPeriod,
        filterStatus,
        filterSteps,
        renderActivitiesGantt,
        yearNumber,
        visualizationType,
        indexPeriod,
        setYearNumber,
        setProjectPeriod,
        setFilterPeriod,
        setPrimarySelectColor,
        setDaysMonthSelected,
        setMonthNumber,
        setFilterStatus,
        setFilterSteps,
        setRenderActivitiesGatt,
        setStepId,
        setVisualizationType,
        setIndexPeriod,
      }}
    >
      {children}
    </GanttContext.Provider>
  );
};
