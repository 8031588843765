import React, { useState, useEffect } from "react";

import { UseMasonaryActivityContext } from "../../../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { attributeType } from "../../../../Models/attributeType";
import { SoculoFormType } from "../../../../Models/Masonary/type";
import { Soculo } from "../../../../Models/Masonary/SoculoModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const SoculoStepForm: React.FC<stepFormProps> = (props) => {
    const { masonaryActivities, indexMasonaryActivitySelected, editMasonaryActivity } = UseMasonaryActivityContext();
    const [assignment, setAssignment] = useState<string>('')
    const [itensFormList, setItensFormList] = useState<SoculoFormType>(Soculo)

    useEffect(() => {
        if (masonaryActivities.length > 0) {
            let masonaryActivityCopy = masonaryActivities[indexMasonaryActivitySelected]
            masonaryActivityCopy.activityType = 'Soculo em alvenaria'
            editMasonaryActivity?.(indexMasonaryActivitySelected, masonaryActivityCopy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexMasonaryActivitySelected])

    useEffect(() => {
        if (masonaryActivities.length > 0) {
            setItensFormList(masonaryActivities[indexMasonaryActivitySelected].Soculo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexMasonaryActivitySelected, masonaryActivities]);

    useEffect(() => {
        if (props.stepFormExibition) {
            removeItemSoculoEmAlvenaria(0)
        }
    }, [])
    const addAssignment = () => {
        if (assignment === "Sóculo") {
            addSoculoEmAlvenaria({
                attributes: [
                    {
                        itemName: 'Descrição',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Comprimento',
                        unit_of_measurement: 'm linear',
                        value: ''
                    },
                    {
                        itemName: 'Altura',
                        unit_of_measurement: 'cm',
                        value: ''
                    },
                    {
                        itemName: 'Material',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Quantidade',
                        unit_of_measurement: '',
                        value: ''
                    }
                ]
            })
        }
    }

    const addSoculoEmAlvenaria = (object: attributeType) => {
        var itensFormListCopy = itensFormList
        var soculoActivitiesCopy = masonaryActivities
        itensFormListCopy.SoculoEmAlvenaria.push(object)
        soculoActivitiesCopy[0].Soculo = itensFormListCopy
        setItensFormList(itensFormListCopy)
        editMasonaryActivity?.(0, soculoActivitiesCopy[0])
    }

    const removeItemSoculoEmAlvenaria = (index: number) => {
        var itensFormListCopy = itensFormList
        var masonaryActivitiesCopy = masonaryActivities
        itensFormListCopy.SoculoEmAlvenaria.splice(index, 1)
        masonaryActivitiesCopy[0].Soculo = itensFormListCopy
        setItensFormList(itensFormListCopy)
        editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
    }

    const editSoculoEmAlvenariaValues = (value: string, indexItem: number, indexItemForm: number) => {
        var itensFormListCopy = itensFormList
        var masonaryActivitiesCopy = masonaryActivities
        itensFormListCopy.SoculoEmAlvenaria[indexItemForm].attributes[indexItem].value = value
        setItensFormList(itensFormListCopy)
        editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
    }

    const editSoculoEmAlvenariaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
        var itensFormListCopy = itensFormList
        var masonaryActivitiesCopy = masonaryActivities
        itensFormListCopy.SoculoEmAlvenaria[indexItemForm].attributes[indexItem].itemName = value
        setItensFormList(itensFormListCopy)
        editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
    }

    const editSoculoEmAlvenariaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
        const itensFormListCopy = itensFormList
        const masonaryActivitiesCopy = masonaryActivities
        itensFormListCopy.SoculoEmAlvenaria[indexItemForm].attributes[indexItem].unit_of_measurement = value
        setItensFormList(itensFormListCopy)
        editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
    }

    return (
        <div>
            <div>
                <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
            </div>
            <br />
            {itensFormList.SoculoEmAlvenaria.length ? (
                <div>
                    <p>Sóculo</p>
                    {itensFormList.SoculoEmAlvenaria.map((itemForm, indexItemForm) => (
                        <ContainerGrid key={indexItemForm}>
                            {itemForm.attributes.map((attributeItem, indexAttribute) => (
                                attributeItem.itemName === "Tipo" ? (
                                    <div >
                                        <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSoculoEmAlvenariaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                                        <Select value={attributeItem.value} onChange={(e) => editSoculoEmAlvenariaValues(e.target.value, indexAttribute, indexItemForm)}>
                                            <option value="Gesso">Gesso</option>
                                            <option value="Pvc">Pvc</option>
                                            <option value="Madeira">Madeira</option>
                                            <option value="Isopor">Isopor</option>
                                            <option value="Cimento">Cimento</option>
                                            <option value="Aparente">Aparente</option>
                                            <option value="Fibra">Fibra</option>
                                            <option value="Drywall reto">Drywall reto</option>
                                            <option value="Drywall tabicado">Drywall tabicado</option>
                                            <option value="Jateamento de celulose">Jateamento de celulose</option>
                                            <option value="Outros">Outros</option>
                                        </Select>
                                        <div>{attributeItem.value === "Outros" ? (
                                            <div>
                                                <InputActivity>
                                                    <input type="text" id="tipooutros" placeholder="Digite o tipo"
                                                        value={attributeItem.value}
                                                        onChange={(e) => editSoculoEmAlvenariaValues(e.target.value, indexAttribute, indexItemForm)}
                                                    />
                                                </InputActivity>
                                            </div>
                                        ) : (
                                            <></>
                                        )}</div>

                                    </div>
                                ) : (
                                    <div className="resizeContentInputs">
                                        <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSoculoEmAlvenariaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                                        <InputActivity>
                                            <input type="text" value={attributeItem.value} onChange={(e) => editSoculoEmAlvenariaValues(e.target.value, indexAttribute, indexItemForm)} />
                                            {attributeItem.unit_of_measurement && (
                                               <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editSoculoEmAlvenariaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                                            )}
                                        </InputActivity>
                                    </div>
                                )
                            ))}
                            <div className="cancelX" onClick={() => removeItemSoculoEmAlvenaria(indexItemForm)}>X</div>
                        </ContainerGrid>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default SoculoStepForm;
