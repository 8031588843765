import React, { useEffect, useState } from "react";

import { UseUserContext } from "../../../Context/UserContext"

import Modal from "../../../components/Modal";
import {
    InputForm,
    Select,
    FooterFormContainer,
    Button,
    ContainerCheckBox
} from "../../../components/stylesDomElements/htmlStyledElements";

import { ContentGrid, FlexContent, ContainerListItens, SelectedSteps, ContainerInputForm, Item } from "./style"


import iconClose from "../../../assets/XIcon.svg"
import axios from "axios";

const SendInvite: React.FC = () => {
    const { userId } = UseUserContext()
    const [stepsListed, setSteplisted] = useState<string>('selecione')

    const [supplierName, setSupplierName] = useState<string>('')
    const [stepsAtuationList, setStepsAtuationList] = useState<string[]>([])
    const [typeSupplier, settypeSupplier] = useState<string>('Fornecedor avulso')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [typeWork, setTypeWork] = useState<string>('')
    const [excluisveSupplier, setExclusiveSupplier] = useState<boolean>(false)


    useEffect(() => {
        if (typeSupplier === "Empreiteira") {
            setStepsAtuationList(['Demolicao', 'Alvenaria', 'Impermeabilizacao', 'Eletrica', 'Hidraulica', 'Ar condicionado', 'Pisos e revestimento',
                'Forro', 'Pintura', 'Loucas e metais', 'Telhado'])
        } else if (typeSupplier === "Construtora") {
            setStepsAtuationList(['Demolicao', 'Alvenaria', 'Impermeabilizacao', 'Eletrica', 'Hidraulica', 'Ar condicionado', 'Pisos e revestimento',
                'Forro', 'Pintura', 'Loucas e metais', 'Telhado', 'Vidracaria', 'Marmoraria'])
        } else if (typeSupplier === "Fornecedor avulso") {
            setStepsAtuationList([])
        }
    }, [typeSupplier])

    const addStepList = (step: string) => {
        setSteplisted(step);
        let stepAtuationListCopy = stepsAtuationList
        stepAtuationListCopy.push(step)
        setStepsAtuationList([...stepAtuationListCopy])
        setSteplisted("selecione");
    };

    const dropItem = (index: number) => {
        let stepAtuationCopy = stepsAtuationList
        stepAtuationCopy.splice(index, 1)
        setStepsAtuationList([...stepAtuationCopy])
    };

    const insertTypeWork = (typeWorkSelected: string, selected: boolean) => {
        const typeWorkCopy = typeWork
        var types = typeWorkCopy.split(',')

        if (!selected) {
            types.splice(types.findIndex(typeFilter => typeFilter === typeWorkSelected), 1)
        } else {
            if (types[0] === '') {
                types[0] = typeWorkSelected
            } else {
                types.push(typeWorkSelected)
            }
        }
        setTypeWork(types.toString())
    }

    const SendInvite = () => {
        if (supplierName && typeSupplier && stepsAtuationList.length && phone && email && typeWork) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/registerSupplierInvited`,
                {
                    supplierName: supplierName,
                    typeSupplier: typeSupplier,
                    stepsAtuationList: stepsAtuationList,
                    phone: phone,
                    email: email,
                    typeWork: typeWork,
                    status: 'Ativo',
                    avaliation: '',
                    worksDeeds: 0,
                    invitedById: userId,
                    commission: '0,00',
                    excluisveSupplier: excluisveSupplier
                }).then((res) => {
                    if (res.status === 200) {
                        window.location.reload()
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <div>
            <Modal titleForm="Convidar fornecedor">
                <div>
                    <ContentGrid>
                        <ContainerInputForm>
                            <label htmlFor="name">Nome do responsável</label>
                            <InputForm id="name" type="text" onChange={(e) => setSupplierName(e.target.value)} required />
                        </ContainerInputForm>
                        <FlexContent>
                            <ContainerInputForm>
                                <label htmlFor="selectTypeSupplier">Tipo de fornecedor</label>
                                <Select name="bank select" id="selectTypeSupplier" value={typeSupplier} onChange={(e) => settypeSupplier(e.target.value)}>
                                    <option value="Fornecedor avulso">Fornecedor avulso</option>
                                    <option value="Construtora">Construtora</option>
                                    <option value="Empreiteira">Empreiteira</option>
                                </Select>
                            </ContainerInputForm>

                            <div className="contentSelect">
                                <label htmlFor="">Tipo de obra</label>
                                <ContainerCheckBox>
                                    <div>
                                        <label htmlFor="check1">
                                            <input
                                                type="checkbox"
                                                name="tipoDeObra"
                                                id="check1"
                                                onChange={(e) => insertTypeWork('Pequenas', e.target.checked)}
                                            />
                                            <p>Pequenas</p>
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="check2">
                                            <input
                                                type="checkbox"
                                                name="tipoDeObra"
                                                id="check2"
                                                onChange={(e) => insertTypeWork('Médias', e.target.checked)}
                                            />
                                            <p>Médias</p>
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="check3">
                                            <input
                                                type="checkbox"
                                                name="tipoDeObra"
                                                id="check3"
                                                onChange={(e) => insertTypeWork('Grandes', e.target.checked)}
                                            />
                                            <p>Grandes</p>
                                        </label>
                                    </div>
                                </ContainerCheckBox>
                            </div>
                        </FlexContent>
                    </ContentGrid>
                    <ContentGrid>
                        <ContainerInputForm>
                            <label htmlFor="phone">Telefone (WhatsApp)</label>
                            <InputForm id="phone" type="text" required onChange={(e) => setPhone(e.target.value)} />
                        </ContainerInputForm>
                        <ContainerInputForm>
                            <label htmlFor="mail">Email</label>
                            <InputForm id="mail" type="text" required onChange={(e) => setEmail(e.target.value)} />
                        </ContainerInputForm>
                    </ContentGrid>

                    <ContentGrid>
                        <ContainerInputForm>
                            <label htmlFor="stepsSelection">Etapas de atuação</label>
                            <Select name="bank select" id="stepsSelection" value={stepsListed} onChange={(e) => addStepList(e.target.value)}>
                                <option value="selecione">Selecione</option>
                                <option value="Demolicao">Demolição</option>
                                <option value="Alvenaria">Alvenaria</option>
                                <option value="Impermeabilizacao">Impermeabilização</option>
                                <option value="Eletrica">Elétrica</option>
                                <option value="Hidraulica">Hidráulica</option>
                                <option value="Ar condicionado">Ar condicionado</option>
                                <option value="Pisos e revestimento">Pisos e revestimento</option>
                                <option value="Forro">Forro</option>
                                <option value="Pintura">Pintura</option>
                                <option value="Loucas e metais">Louças e metais</option>
                                <option value="Telhado">Telhado</option>
                                <option value="Vidracaria">Vidraçaria</option>
                                <option value="Marmoraria">Marmoraria</option>
                                <option value="Serralheria">Serralheria</option>
                                <option value="Marcenaria">Marcenaria</option>
                                <option value="Portas e janelas">Portas e janelas</option>
                                <option value="Esquadrias">Esquadrias</option>
                                <option value="Mobiliario">Mobiliario</option>
                                <option value="Projeto">Projeto</option>
                                <option value="Limpeza">Limpeza</option>
                                <option value="Mudança">Mudança</option>
                            </Select>
                        </ContainerInputForm>


                    </ContentGrid>
                    <SelectedSteps>
                        <label className="labelElement">Etapas selecionadas</label>

                        <ContainerListItens>
                            {stepsAtuationList.map(
                                (item, index) => (
                                    <div key={index}>
                                        {item !== "" ? (
                                            <Item key={index}>
                                                {item}
                                                <img src={iconClose} alt="icone de fechar" onClick={() => dropItem(index)} />
                                            </Item>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ))}
                        </ContainerListItens>
                    </SelectedSteps>
                    <ContainerCheckBox>
                        <div>
                            <label htmlFor="exclusiveSupplier">
                                <input
                                    type="checkbox"
                                    name="tipoDeObra"
                                    id="exclusiveSupplier"
                                    onChange={(e) => setExclusiveSupplier(e.target.checked)}
                                />
                                <p>Fornecedor exclusivo para mim <br />(Não serão enviados orçamentos de outros arquitetos)</p>
                            </label>
                        </div>
                    </ContainerCheckBox>
                    <br />

                    <FooterFormContainer>
                        <Button type="submit" onClick={() => SendInvite()} className="buttonRegister">Enviar convite</Button>
                    </FooterFormContainer>
                </div>
            </Modal>
        </div>
    )
}

export default SendInvite