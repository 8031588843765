import React, { useEffect } from "react"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";
interface sawmillProps {
    indexSelected: number
}

const SawmillController: React.FC<sawmillProps> = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Mesas')
    }, [])

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="sawmillStep" id="sawmillStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Mesas">Mesas</option>
                <option value="Materiais para serralheria">Materiais</option>

            </select>
        </ActionSelect>
    </div>
}

export default SawmillController

