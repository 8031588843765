import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { ContainerButton } from "./style";

interface paginationProps {
  supplierId: string;
  projectId: string;
  activityId: string;
}
interface listLinksProps {
  budgetLinks: string[];
}

const PaginationPage: React.FC<paginationProps> = (props) => {
  const [listLinks, setListLinks] = useState<listLinksProps>({
    budgetLinks: [],
  });
  const atualLink = window.location.href;
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/listLinksBudgetSupplier/${props.projectId}/${props.supplierId}`
      )
      .then((res) => {
        console.log("listLinksBudgetSupplier: " + res.data);
        setListLinks(res.data);
      });
  }, []);

  useEffect(() => {
    if (listLinks) {
      setPage(
        listLinks.budgetLinks.findIndex((link) => link === atualLink) + 1
      );
    }

    console.log(page);
  }, [listLinks]);

  useEffect(() => {
    console.log(atualLink);
    console.log(listLinks.budgetLinks);
  }, [atualLink, listLinks]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (listLinks?.budgetLinks) {
      window.location.href = listLinks?.budgetLinks[value - 1];
    }
  };

  return (
    <div>
      {listLinks?.budgetLinks.findIndex((link) => link === atualLink) !==
      undefined ? (
        <p>
          Orçamento{" "}
          {listLinks?.budgetLinks.findIndex((link) => link === atualLink) + 1}{" "}
          de {listLinks?.budgetLinks.length}
        </p>
      ) : (
        <></>
      )}

      <ContainerButton>
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            count={listLinks?.budgetLinks.length}
            page={page}
            defaultPage={page}
            onChange={handleChange}
          />
        </Stack>
      </ContainerButton>
    </div>
  );
};

export default PaginationPage;
