import React, { useState } from "react";

import { PlantProps } from "../../../types/typesEntitiesProject";
import { Button, Tr } from "./style";
import { Th, MenuOverlay } from "./style";
import { OptionButton } from "../../../components/stylesDomElements/htmlStyledElements"

import downloadIcon from '../../../assets/dowloadIcon.svg'
import pointsMenu from "../../../assets/pointsMenu.svg";
import axios from "axios";

const TrTable: React.FC<PlantProps> = (props) => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [downloadButton, setDownloadButton] = useState<boolean>(false);

  return (
    <>
      <Tr>

        <th><a href={props.archiveUrl} download>{props.archiveName}</a></th>
        <th><a href={props.archiveUrl} download>{props.type}</a></th>
        <th><a href={props.archiveUrl} download>{props.size}</a></th>
        <th><a href={props.archiveUrl} download>{props.modification}</a></th>

        <Th onClick={() => setVisibleMenu(!visibleMenu)}>
          <a href={props.archiveUrl} download><img src={downloadIcon} alt='Icone de download'></img></a>
        </Th>
      </Tr>
    </>
  );
};

export default TrTable;
