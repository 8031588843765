import React, { useState } from "react";
import { FramesFormType } from "../../../Models/Frames/type";

import { stateType } from "../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string,
  activityName?: string;
  activityType?: string;
  Frames: FramesFormType;
}

const FramesDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>
        {activity.Frames.Portas?.map((item, index) => (
          <div key={index}>
            {activity.Frames.Portas.length > 1 ? (<u>Porta {index + 1}</u>) : (<u>Porta </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Frames.Janelas?.map((item, index) => (
          <div key={index}>
            {activity.Frames.Janelas.length > 1 ? (<u>Janela {index + 1}</u>) : (<u>Janela </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
        {activity.Frames.Divisorias?.map((item, index) => (
          <div key={index}>
            {activity.Frames.Divisorias.length > 1 ? (<u>Divisória {index + 1}</u>) : (<u>Divisória </u>)}
            <br /><br />
            {item.attributes?.map((tarefa, index) => (

              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                </p>
                <p>
                  {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                </p>
              </div>
            ))}
          </div>

        ))}
        <hr />
      </div>
    </div>
  );
};

export default FramesDescription;