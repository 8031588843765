import React, { SetStateAction } from "react";

import { ActionSelect } from "../../../stylesDomElements/htmlStyledElements";
import { textTransform } from "../../script"
import {Button} from "../../style"
import { AssignmentContent } from "./style"

import addIcon from "../../../../assets/addIcon.svg"

interface assignmentProps {
    assignmentList: string[]
    assignment: string
    setAssignment: React.Dispatch<SetStateAction<string>>
    addAssignment: () => void
}

const Assignment: React.FC<assignmentProps> = (props) => {
    return (
        <AssignmentContent>
            <p>Tarefa</p>
            <ActionSelect>
                <select value={props.assignment} name="assignment" id="assignmentList" onChange={(e) => props.setAssignment(e.target.value)}>
                    <option value="">Selecione</option>
                    {props.assignmentList.map((item, index) => (
                        <option key={index} value={item}>{textTransform(item)}</option>
                    ))}
                </select>
            </ActionSelect>
            <Button onClick={() => props.addAssignment()}>
                <img src={addIcon} alt="Iconde de adição" />
                Adicionar
            </Button>
        </AssignmentContent>
    )
}

export default Assignment