import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
} from "../../style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"

interface budgetProps {
  activity: activitiesProps,
  supplier: SupplierProps
  margin:number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  tipo_box?: string,
  altura?: string,
  comprimento?: string,
  largura?: string,
  formato_ferragem?: string,

  cor_ferragem?: string,
  cor_vidro?: string,
  tipo_porta?: string,

  material_estrutura?: string,
  descricaoEstrutura?: string,
  descricao_puxador?: string,
}

const BoxBanheiroBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [box, setBox] = useState<string>('0,00')
  const [ferragem, setFerragem] = useState<string>('0,00')
  const [puxador, setPuxador] = useState<string>('0,00')

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    setTotalBudgetPayment(sum([box, ferragem, puxador]))
  }, [box, ferragem, puxador])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([box, ferragem, puxador]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const submitBudget = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          box: box,
          ferragem: (Number(ferragem.replace(',', '.')) + Number(ferragem.replace(',', '.')) * (props.margin / 100)).toString(),
          puxador: (Number(puxador.replace(',', '.')) + Number(puxador.replace(',', '.')) * (props.margin / 100)).toString()
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItens>
        <div className="upContent">
          <p>{activity.tipo_box} - Porta: {activity.tipo_porta}</p>
          <p>Medidas</p>
          <p>Preço unit.</p>
          <p className="item">Preço total</p>
        </div>

        {activity.tipo_box !== '' ? (
          <ItemContentList>
            <p>Cor vidro: {activity.cor_vidro}, Material: {activity.material_estrutura} - {activity.descricaoEstrutura}</p>
            <p>{activity.largura}cm x {activity.altura}cm x {activity.comprimento}cm</p>
            <ContentInputPrice>
              R$:
              <PriceItem onChange={(e) => setBox(multiply('1', e.target.value))} />
            </ContentInputPrice>
            {box && box !== '0' ?
              (<p className="item">{box}</p>) :
              (<p className="item">0,00</p>)
            }
          </ItemContentList>
        ) : (<></>)}

        <div className="upContent">
          <p>Ferragem</p>
          <p>Cor</p>
          <p>Preço unit.</p>
          <p className="item">Preço total</p>
        </div>

        {activity.formato_ferragem ? (
          <ItemContentList>
            <p>{activity.formato_ferragem}</p>
            <p>{activity.cor_ferragem}</p>
            <ContentInputPrice>
              R$:
              <PriceItem onChange={(e) => setFerragem(multiply('1', e.target.value))} />
            </ContentInputPrice>
            {ferragem && ferragem !== '0' ?
              (<p className="item">{ferragem}</p>) :
              (<p className="item">0,00</p>)
            }
          </ItemContentList>
        ) : (<></>)}

        {activity.descricao_puxador ? (
          <>
            <div className="upContent">
              <p>Puxador</p>
              <p></p>
              <p>Preço unit.</p>
              <p className="item">Preço total</p>
            </div>

            <ItemContentList>
              <p>{activity.descricao_puxador}</p>
              <p> </p>
              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => setPuxador(multiply('1', e.target.value))} />
              </ContentInputPrice>
              {puxador && puxador !== '0' ?
                (<p className="item">{puxador}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentList>
          </>
        ) : (<></>)}

      </ListContainerItens>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default BoxBanheiroBudget;