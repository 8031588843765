import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
} from "../../style";

import { activitiesProps, SupplierProps, espelhoProps } from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"

interface budgetProps {
  activity: activitiesProps,
  supplier: SupplierProps
  margin:number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  espelhos?: espelhoProps[];
}

const EspelhosBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [espelhos, setEspelhos] = useState<string[]>(['0,00'])

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.espelhos?.forEach(() => {
      setEspelhos((prev) => [...prev, "0,00"])
    })
  }, [])

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    setTotalBudgetPayment(sum(espelhos))
  }, [espelhos])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum(espelhos))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    var stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    var espelhosMargin: number[] = []

    espelhos.forEach((item) => {
      if (item != "0,00") {
        espelhosMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          espelhos: espelhosMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItens>
        <div className="upContent">
          <p>Itens a serem trabalhados</p>
          <p>Medidas</p>
          <p>Preço unit.</p>
          <p className="item">Preço total</p>
        </div>

        {activity.espelhos?.map((item, index) => (
          item.tipo_espelho !== '' ? (
            <div key={index}>
              <ItemContentList>
                <p><b>Tipo</b>: {item.tipo_espelho === "Outros" ? (
                  item.tipo_espelhos_outros
                ) : (item.tipo_espelho)}, <b>Cor vidro</b>: {item.cor_espelho === "Outros" ? (
                  item.cor_espelho_outros
                ) : (item.cor_espelho)}, <b>Acabamento borda</b>: {item.acabamento_borda === "Outros" ? (
                  item.acabamento_borda_outros
                ) : (item.cor_espelho)}</p>
                <p>{item.altura_espelho}cm x {item.comprimento_espelho}cm</p>
                <ContentInputPrice>
                  R$:
                  <PriceItem onChange={(e) => updateState(index, espelhos, setEspelhos, e.target.value)} />
                </ContentInputPrice>
                {espelhos && espelhos[index] !== '0' ?
                  (<p className="item">{espelhos[index]}</p>) :
                  (<p className="item">0,00</p>)
                }
              </ItemContentList>
            </div>
          ) : (<></>)
        ))}
      </ListContainerItens>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default EspelhosBudget;