import React from "react";

import { allActivitiesProps } from "../../../../types/typesEntitiesProject"
import { SelectionBudgetContextProvider } from "../../../../components/SelectionBudget/Context/SelectionBudgetContext";

import ActivitiesAndPendences from "../../../../components/ActivitiesAndPendences";
import SelectionBudget from "../../../../components/SelectionBudget";

import ModifyActivity from "../../../../components/ModifyActivities";

interface props {
    stepType?: string,
    requestCotation?: boolean,
    selectedBudget?: boolean,
    activitySelected?: allActivitiesProps
}

const DisplayFlow: React.FC<props> = (props) => {
    return (
        <>
            {props.activitySelected && (
                props.requestCotation ? (
                    props.selectedBudget ? (
                        <ActivitiesAndPendences activity={props.activitySelected} />
                    ) : (
                        <SelectionBudgetContextProvider>
                            <SelectionBudget activity={props.activitySelected} />
                        </SelectionBudgetContextProvider>
                    )
                ) : (
                    <ModifyActivity activity={[props.activitySelected]} activityType={props.activitySelected.activityType} endPointRequestApi="sendCotations" ModifyActivityExibition={true} />
                )
            )}
        </>
    )
}

export default DisplayFlow