import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  ContanierFinalization,
  ItemContainerFinzalization,
  TotalContainer,
  ContainerButtonSendBudget,
} from "../../style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"

interface budgetProps {
  activity: activitiesProps,
  supplier: SupplierProps
  margin:number
}

interface piaProps { tipoPia: string, largura: string, comprimento: string, altura: string, espessuraAba: string, diametroCorte: string }
interface medidas_bancadasProps {
  braco1: {
    altura: string,
    largura: string,
  }
  braco2: {
    altura: string,
    largura: string,
  }
  braco3: {
    altura: string,
    largura: string,
  }
}

interface painelProps { tipoPainel: string, altura: string, largura: string, saiaPainel: string }
interface recortesFurosItensSuspensosProps { tipo: string, nome: string, altura: string, largura: string, comprimento: string, diametro: string }
interface frontaoProps { altura: string, largura: string }

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  formato_bancada?: string,
  disposicao_bancada?: string,
  possuiPias?: boolean
  pias?: piaProps[],
  medidas_bancadas?: medidas_bancadasProps,
  possuiPainel?: boolean,
  painel?: painelProps,
  possui_recortes_furos_suspensos?: boolean,
  recortes_furos_suspensos?: recortesFurosItensSuspensosProps,
  tipo_saia?: string,
  altura_saia?: string,
  profundidade_saia?: string,
  frontao?: string,
  medidas_fundo?: frontaoProps,
  medidas_lateral_1?: frontaoProps,
  medidas_lateral_2?: frontaoProps,
  pingadeira?: boolean,
  rebaixo?: boolean,
  pedra?: string,
  cor_pedra?: string,
  acabamento_pedra?: string,
}

const PiasBancadasBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [pias_bancadas, setPiasBancadas] = useState<string>('0,00')

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    setTotalBudgetPayment(sum([pias_bancadas]))
  }, [pias_bancadas])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {

    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([pias_bancadas]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const submitBudget = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          pias_bancadas: (Number(pias_bancadas.replace(',', '.')) + Number(pias_bancadas.replace(',', '.')) * (props.margin / 100)).toString()
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItens>
        <div className="upContent">
          <p>{activity.formato_bancada} - {activity.disposicao_bancada}</p>
          <p>Medidas dos braços</p>
          <p></p>
          <p></p>
        </div>

        {activity.pedra !== '' ? (
          <ItemContentList>
            <p>{activity.pedra} - {activity.cor_pedra} - {activity.acabamento_pedra}</p>
            {activity.medidas_bancadas ? (
              <p>{activity.medidas_bancadas?.braco1.altura + "cm x " + activity.medidas_bancadas?.braco1.largura}cm |
                {" " + activity.medidas_bancadas?.braco2.altura + "cm x " + activity.medidas_bancadas?.braco2.largura}cm |
                {" " + activity.medidas_bancadas?.braco3.altura + "cm x " + activity.medidas_bancadas?.braco3.largura}cm</p>
            ) : (<></>)}
          </ItemContentList>
        ) : (<></>)}



        {activity.possuiPias && activity.pias ? (
          activity.pias.map((item, index) => (
            <>
              <div className="upContent">
                <p>Pias</p>
                <p>Medidas</p>
                <p></p>
                <p></p>
              </div>
              {item.tipoPia === "Esculpida" ? (
                <ItemContentList>
                  <p>{item.tipoPia}</p>
                  <p>{item.altura}cm x {item.comprimento}cm x {item.largura}cm</p>
                </ItemContentList>
              ) : item.tipoPia === "De embutir quadrada" ? (
                <ItemContentList>
                  <p>{item.tipoPia}</p>
                  <p>{item.comprimento}cm x {item.largura}cm</p>
                </ItemContentList>
              ) : item.tipoPia === "De embutir redonda" ? (
                <ItemContentList>
                  <p>{item.tipoPia}</p>
                  <p>{item.diametroCorte}cm</p>
                </ItemContentList>
              ) : item.tipoPia === "Aparente" ? (<ItemContentList>
                <p>{item.tipoPia}</p>
                <p>{item.altura}cm x {item.comprimento}cm x {item.largura}cm x {item.espessuraAba}cm</p>
              </ItemContentList>) : (<></>)}
            </>
          ))
        ) : (<></>)}

        {activity.possuiPainel && activity.painel ? (
          <>
            <div className="upContent">
              <p>Painel lateral ou de fundo</p>
              <p>Medidas</p>
              <p></p>
              <p></p>
            </div>
            <ItemContentList>
              <p>{activity.painel?.tipoPainel} - {activity.painel?.saiaPainel}</p>
              <p>{activity.painel.altura}cm x {activity.painel.largura}cm</p>
            </ItemContentList>
          </>
        ) : (<></>)}


        {activity.possui_recortes_furos_suspensos && activity.recortes_furos_suspensos ? (
          <>
            <div className="upContent">
              <p>Recortes, furos e/ou itens suspensos</p>
              <p>Medidas</p>
              <p></p>
              <p></p>
            </div>
            {activity.recortes_furos_suspensos.tipo === "Recortes" ? (
              <ItemContentList>
                <p>{activity.recortes_furos_suspensos?.tipo} - {activity.recortes_furos_suspensos?.nome}</p>
                <p>{activity.recortes_furos_suspensos?.largura}cm X {activity.recortes_furos_suspensos?.comprimento}cm</p>
              </ItemContentList>
            ) : activity.recortes_furos_suspensos.tipo === "Furos" ? (
              <ItemContentList>
                <p>{activity.recortes_furos_suspensos?.tipo} - {activity.recortes_furos_suspensos?.nome}</p>
                <p>{activity.recortes_furos_suspensos?.diametro}cm</p>
              </ItemContentList>
            ) : activity.recortes_furos_suspensos.tipo === "Itens Suspensos" ? (
              <ItemContentList>
                <p>{activity.recortes_furos_suspensos?.tipo} - {activity.recortes_furos_suspensos?.nome}</p>
                <p>{activity.recortes_furos_suspensos?.altura}cm x {activity.recortes_furos_suspensos?.largura}cm {activity.recortes_furos_suspensos?.comprimento}cm</p>
              </ItemContentList>
            ) : (<></>)}
          </>
        ) : (<></>)}

        <div className="upContent">
          <p>Acabamento de borda</p>
          <p>Medidas</p>
          <p></p>
          <p></p>
        </div>

        {activity.tipo_saia ? (
          <>
            {activity.tipo_saia === "Saia simples" ? (
              <ItemContentList>
                <p>{activity.tipo_saia}</p>
                <p>{activity.altura_saia}cm</p>
              </ItemContentList>
            ) : activity.tipo_saia === "Saia em L" ? (
              <ItemContentList>
                <p>{activity.tipo_saia}</p>
                <p>{activity.altura_saia}cm x {activity.profundidade_saia}cm</p>
              </ItemContentList>
            ) : activity.tipo_saia === "Saia Slim" ? (
              <ItemContentList>
                <p>{activity.tipo_saia}</p>
                <p>{" "}</p>
              </ItemContentList>
            ) : (<></>)}
          </>
        ) : (<></>)}

        {activity.frontao ? (
          <ItemContentList>
            <div>
              <p>{activity.frontao}</p>
              <ul>
                <li>{activity.pingadeira ? ("Possui pingadeira") : ("Não possui pingadeira")}</li>
                <li>{activity.rebaixo ? ("Possui rebaixo") : ("Não possui rebaixo")}</li>
              </ul>
            </div>
            <div>
              <p>Fundo: {activity.medidas_fundo?.altura}cm x {activity.medidas_fundo?.largura}cm</p><br />
              {activity.medidas_lateral_1?.altura && activity.medidas_lateral_1.largura ? (
                <div className="flexContent">
                  <p>Laterais  &nbsp; &nbsp;</p>
                  <div>
                    <p>{activity.medidas_lateral_1?.altura}cm x {activity.medidas_lateral_1?.largura}cm</p>
                    {activity.medidas_lateral_2?.altura && activity.medidas_lateral_2.largura ? (
                      <p>{activity.medidas_lateral_2?.altura}cm x {activity.medidas_lateral_2?.largura}cm</p>
                    ) : (<></>)}
                  </div>
                </div>
              ) : (<></>)}
            </div>
          </ItemContentList>
        ) : (<></>)}

        {/**Preço total pias e bancadas */}
        <div className="upContent">
          <p></p>
          <p></p>
          <p></p>
          <p className="item">Preço</p>
        </div>

        <ItemContentList>
          <div>

          </div>
          <div>
            Pias e bancadas total
          </div>
          <ContentInputPrice>
            R$:
            <PriceItem onChange={(e) => setPiasBancadas(multiply('1', e.target.value))} />
          </ContentInputPrice>

          {pias_bancadas && pias_bancadas !== '0' ?
            (<p className="item">{pias_bancadas}</p>) :
            (<p className="item">0,00</p>)
          }
        </ItemContentList>
      </ListContainerItens>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default PiasBancadasBudget;