import React, { SetStateAction, useState } from "react";
import axios from "axios";
import { Button, InputForm } from "../../../../components/stylesDomElements/htmlStyledElements"
import addIcon from "../../../../assets/addIcon.svg"
import moment from "moment";

import { Container, ContentItem, Select } from "./style"

interface props {
    activityId: string
    setActivityAndPendences: React.Dispatch<SetStateAction<any>>
}

const NewActivityPendency: React.FC<props> = (props) => {
    const [responsible, setResponsible] = useState<string>('Fornecedor')
    const [period, setPeriod] = useState<string>('beforeInitService')
    const [attachment, setAttachment] = useState<boolean>(true)
    const [description, setDescription] = useState<string>('')

    const addActivity = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/addActivityAndPendences/${props.activityId}`, {
            responsible,
            period,
            attachment,
            checkUpdatedAt: moment().format("DD/MM/YYYY"),
            createdAt: moment().format("DD/MM/YYYY"),
            description
        }).then(res => {
            props.setActivityAndPendences(res.data[0])
        })
    }

    const convertStringToBoolean = (value: string) => {
        if (value === 'true') {
            setAttachment(true)
        } else {
            setAttachment(false)
        }
    }

    return (
        <Container>
            <ContentItem>
                <label htmlFor="responsible">Responsável</label>
                <Select value={responsible} id="responsible" onChange={(e) => setResponsible(e.target.value)}>
                    <option value="supplier">Fornecedor</option>
                    <option value="architect">Arquiteto</option>
                </Select>
            </ContentItem>
            <ContentItem>
                <label htmlFor="">Período</label>
                <Select onChange={(e) => setPeriod(e.target.value)}>
                    <option value="beforeInitService">Antes do início do serviço</option>
                    <option value="duringService">Durante o serviço </option>
                    <option value="afterService">Após o serviço </option>
                </Select>
            </ContentItem>
            <ContentItem>
                <label htmlFor="">Anexo</label>
                <Select onChange={(e) => convertStringToBoolean(e.target.value)}>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                </Select>
            </ContentItem>
            <ContentItem>
                <label htmlFor="">Descrição</label>
                <InputForm className="marginDescription" onChange={(e) => setDescription(e.target.value)} />
            </ContentItem>
            <Button className="buttonElement" onClick={() => addActivity()}>
                <img src={addIcon} alt="Icone de adição0" />
                Nova atividade
            </Button>
        </Container>
    )
}

export default NewActivityPendency