import React, { useState, useEffect } from "react";

import {UseGlassworkActivityContext} from "../../../../Context/ActivitiesContext/Glasswork/GlassworkContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Container, Content, Select, FlexContainer } from "../../style";

const GlassDoor: React.FC = () => {
  const {glassworkActivities, editGlassworkActivity, indexGlassworkActivitySelected}=UseGlassworkActivityContext()

  const [numero_folhas, setNumeroFolhas]=useState<string>('')
  const [altura_porta, setAlturaPorta]=useState<string>('')
  const [comprimento_porta,setComprimentoPorta]=useState<string>('')
  const [material_ferragem, setMaterialFerragem]=useState<string>('')
  const [descricao, setDescricao]=useState<string>('')
  const [cor_vidro, setCorVidro]=useState<string>('Incolor')
  const [cor_ferragem, setCorFerragem]=useState<string>('Alumínio natural')
  const [tipo_porta, setTipoPorta] = useState<string>('')

  useEffect(()=>{
    if(glassworkActivities.length>0){
      let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
      activityEdited.cor_vidro = cor_vidro
      activityEdited.cor_ferragem = cor_ferragem;
      editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(glassworkActivities.length>0){
      setNumeroFolhas(glassworkActivities[indexGlassworkActivitySelected].numero_folhas);
      setAlturaPorta(glassworkActivities[indexGlassworkActivitySelected].altura_porta);
      setComprimentoPorta(glassworkActivities[indexGlassworkActivitySelected].comprimento_porta);
      setMaterialFerragem(glassworkActivities[indexGlassworkActivitySelected].material_ferragem)
      setDescricao(glassworkActivities[indexGlassworkActivitySelected].descricao)
      setCorVidro(glassworkActivities[indexGlassworkActivitySelected].cor_vidro)
      setCorFerragem(glassworkActivities[indexGlassworkActivitySelected].cor_ferragem)
      setTipoPorta(glassworkActivities[indexGlassworkActivitySelected].tipo_porta)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexGlassworkActivitySelected,  glassworkActivities]);

  const editNumeroFolhas=(value:string)=>{
    setNumeroFolhas(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.numero_folhas = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editAlturaPorta=(value:string)=>{
    setAlturaPorta(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.altura_porta = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editComprimentoPorta=(value:string)=>{
    setComprimentoPorta(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.comprimento_porta = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editMaterialFerragem=(value:string)=>{
    setMaterialFerragem(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.material_ferragem = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editDescricao=(value:string)=>{
    setDescricao(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.descricao = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorVidro=(value:string)=>{
    setCorVidro(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.cor_vidro = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorFerragem=(value:string)=>{
    setCorFerragem(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.cor_ferragem = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editTipoPorta=(value:string)=>{
    setTipoPorta(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.tipo_porta = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editTipoPortaOutros=(value:string)=>{
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.porta_outros = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editMaterialAcabamentoOutros=(value:string)=>{
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.material_acabamento_outros = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  return (
    <Container>
      <Content>
        <FlexContainer>
          <div>
            <p>Tipo de porta</p>
            <Select value={tipo_porta} onChange={(e)=>editTipoPorta(e.target.value)}>
              <option value="De abrir">De abrir</option>
              <option value="De correr">De correr</option>
              <option value="De correr Stanley">De correr Stanley</option>
              <option value="Boomerang">Boomerang</option>
              <option value="Pivotante">Pivotante</option>
              <option value="Camarão">Camarão</option>
              <option value="Outros">Outros</option>
              </Select>
              {tipo_porta === "Outros" ? (
            <div>
              <InputActivity>
                <input type="text" id="materialOutros" placeholder="Digite o tipo"
                value={glassworkActivities[indexGlassworkActivitySelected].porta_outros}
                onChange={(e)=>editTipoPortaOutros(e.target.value)} />
              </InputActivity>
            </div>
          ) : (
            <></>
          )}
          </div>
          <div>
            <p>Cor do vidro</p>
            <Select value={cor_vidro} onChange={(e)=>editCorVidro(e.target.value)}>
              <option value="Incolor">Incolor</option>
              <option value="Bronze">Bronze</option>
              <option value="Fumê">Fumê</option>
              <option value="Verde">Verde</option>
              <option value="Pontilhado">Pontilhado</option>
            </Select>
          </div>
          <div>
            <div></div>
            <p>Número de folhas</p>
            <InputActivity>
                <input type="number" value={numero_folhas} onChange={(e)=>editNumeroFolhas(e.target.value)} />
                
              </InputActivity>
          </div>
        </FlexContainer>
        <div>
          Dimensões da porta:
          <div className="contentSizeInputs">
            <div>
              <p>Altura</p>
              <InputActivity>
                <input type="text" value={altura_porta} onChange={(e)=>editAlturaPorta(e.target.value)} />
                m
              </InputActivity>
            </div>
            <div>
              <p>Comprimento</p>
              <InputActivity>
                <input type="text" value={comprimento_porta} onChange={(e)=>editComprimentoPorta(e.target.value)} />
                m
              </InputActivity>
            </div>
          </div>
        </div>
        <FlexContainer>
          <div>
            <p>Material do acabamento</p>
            <Select value={material_ferragem} onChange={(e)=>editMaterialFerragem(e.target.value)}>
            <option value="Aluminio">Alumínio</option>
            <option value="Aco inox">Aço inox</option>
            <option value="Aco com pintura">Aço com pintura</option>
            <option value="Aco galvanizado">Aço galvanizado</option>
            <option value="Outros">Outros</option>
            </Select>
             {material_ferragem === "Outros" ? (
            <div>
              <InputActivity>
                <input type="text" id="materialOutros" placeholder="Digite o material"
                value={glassworkActivities[indexGlassworkActivitySelected].material_acabamento_outros} 
                onChange={(e)=>editMaterialAcabamentoOutros(e.target.value)}
                />
              </InputActivity>
            </div>
            
          ) : (
            <></>
          )}
          </div>
          <div>
            <p>Cor da ferragem</p>
            <Select value={cor_ferragem} onChange={(e)=>editCorFerragem(e.target.value)}>
              <option value="Aluminio natural">Alumínio natural</option>
              <option value="Branco">Branco</option>
              <option value="Preto">Preto</option>
              <option value="Cromado">Cromado</option>
              <option value="Bronze">Bronze</option>
              <option value="Rose gold">Rose gold</option>
            </Select>
          </div>
        </FlexContainer>
        <div>
          <p>Descrição (Opcional)</p>
          <InputActivity>
            <input type="text" value={descricao} onChange={(e)=>editDescricao(e.target.value)}/>
            &emsp;
          </InputActivity>
        </div>
      </Content>
      <Content>
        <div></div>
      </Content>
    </Container>
  );
};

export default GlassDoor;
