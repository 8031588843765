import React from "react";

import AutomatiosDescription from "./Automations";
import PassageOfInfraDescription from "./PassageOfInfra";
import ElectricPanelDescription from "./ElectricPanel";
import InstallationsDescription from "./Installations";
import WirePassageDescription from "./WirePassage";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const EletricDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Painel eletrico" ? (<ElectricPanelDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Passagem da infra" ? (<PassageOfInfraDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Passagem de fios" ? (<WirePassageDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Instalacoes" ? (<InstallationsDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Automacoes" ? (<AutomatiosDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default EletricDescription