import React, { useState, useEffect } from "react";

import { UseSawmillActivityContext } from "../../../../Context/ActivitiesContext/Sawmill/SawmillContext";

import { sawmillActivity } from "../../../../types/typesEntitiesProject"

import { ContainerGrid, P, ContainerButtom } from "../../style";
import { InputActivity, Button } from "../../../stylesDomElements/htmlStyledElements";
import { Content, FlexContainer } from "../../style";

import addIcon from "../../../../assets/addIcon.svg";

interface componentProps {
    activityName?: boolean
}

const SawmillTable: React.FC = () => {
    const { sawmillActivities, editSawmillActivity, indexSawmillActivitySelected } = UseSawmillActivityContext()

    const [sawmillActivity, setSawmillActivity] = useState<sawmillActivity[]>([])

    useEffect(() => {
        if (sawmillActivities[indexSawmillActivitySelected]) {
            setSawmillActivity(sawmillActivities[indexSawmillActivitySelected].sawmillActivityTable)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexSawmillActivitySelected, sawmillActivities])

    useEffect(() => {
        if (sawmillActivities.length > 0 && sawmillActivities[indexSawmillActivitySelected].sawmillActivityTable.length > 0) {
            setSawmillActivity(sawmillActivities[indexSawmillActivitySelected].sawmillActivityTable)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sawmillActivity, indexSawmillActivitySelected, sawmillActivities])

    const editQuantidade = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: value,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: sawmillActivityCopy[index].largura,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editComprimento = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: value,
            largura: sawmillActivityCopy[index].largura,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editLargura = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: value,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editAltura = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: sawmillActivityCopy[index].largura,
            altura: value,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editMaterial_tampo = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: sawmillActivityCopy[index].largura,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: value,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editMaterial_estrutura = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: sawmillActivityCopy[index].largura,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: value,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editPintura = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: sawmillActivityCopy[index].largura,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: value,
            adicionais: sawmillActivityCopy[index].adicionais,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const editAdicionais = (index: number, value: string) => {
        const sawmillActivityCopy = Array.from(sawmillActivity)
        sawmillActivityCopy.splice(index, 1, {
            quantidade: sawmillActivityCopy[index].quantidade,
            comprimento: sawmillActivityCopy[index].comprimento,
            largura: sawmillActivityCopy[index].largura,
            altura: sawmillActivityCopy[index].altura,
            material_tampo: sawmillActivityCopy[index].material_tampo,
            material_estrutura: sawmillActivityCopy[index].material_estrutura,
            pintura: sawmillActivityCopy[index].pintura,
            adicionais: value,
        })
        setSawmillActivity(sawmillActivityCopy)
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable = sawmillActivityCopy;
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    const addMesa = () => {
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        activityEdited.sawmillActivityTable.push({
            comprimento: '',
            largura: '',
            altura: '',
            material_tampo: '',
            material_estrutura: '',
            pintura: '',
            adicionais: '',
            quantidade: ''
        })
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
        setSawmillActivity((prev) => [...prev, {
            comprimento: '',
            largura: '',
            altura: '',
            material_tampo: '',
            material_estrutura: '',
            pintura: '',
            adicionais: '',
            quantidade: ''
        }])
    }

    const removeItem = (index: number) => {
        let activityEdited = sawmillActivities[indexSawmillActivitySelected];
        const sawmillCopy = [...sawmillActivity]
        sawmillCopy.splice(index, 1)
        activityEdited.sawmillActivityTable = sawmillCopy
        setSawmillActivity(sawmillCopy);
        editSawmillActivity?.(indexSawmillActivitySelected, activityEdited);
    }

    return (
        <div>
            {sawmillActivity.map((element, index) =>
                <div key={index}>
                    <P>Mesa {index + 1}</P>
                    <ContainerGrid>
                        <Content>
                            <div>
                                <p>Quantidade</p>
                                <InputActivity>
                                    <input type="text" value={element.quantidade} onChange={(e) => editQuantidade(index, e.target.value)} />
                                </InputActivity>
                            </div>
                        </Content>
                        <Content>
                            <div>
                                <p>Material do tampo</p>
                                <InputActivity>
                                    <input type="text" value={element.material_tampo} onChange={(e) => editMaterial_tampo(index, e.target.value)} />
                                </InputActivity>
                            </div>
                        </Content>
                        <Content>
                            <div>
                                <p>Material estrutura</p>
                                <InputActivity>
                                    <input type="text" value={element.material_estrutura} onChange={(e) => editMaterial_estrutura(index, e.target.value)} />
                                </InputActivity>
                            </div>
                        </Content>
                        <div className="cancelX" onClick={() => removeItem(index)}>X</div>

                        <div>
                            Dimensões da mesa
                            <div className="flexContentInput">
                                <div>
                                    <p>Largura</p>
                                    <InputActivity>
                                        <input type="text" value={element.largura} onChange={(e) => editLargura(index, e.target.value)} />
                                        m
                                    </InputActivity>
                                </div>
                                <div>
                                    <p>Comprimento</p>
                                    <InputActivity>
                                        <input type="text" value={element.comprimento}  onChange={(e) => editComprimento(index, e.target.value)} />
                                        m
                                    </InputActivity>
                                </div>
                                <div>
                                    <p>Altura</p>
                                    <InputActivity>
                                        <input type="text" value={element.altura} onChange={(e) => editAltura(index, e.target.value)} />
                                        m
                                    </InputActivity>
                                </div>
                            </div>
                        </div>
                        <Content>
                            <div></div>
                            <div>
                                <p>Pintura</p>
                                <InputActivity>
                                    <input type="text" value={element.pintura}  onChange={(e) => editPintura(index, e.target.value)} />
                                </InputActivity>
                            </div>
                        </Content>
                        <Content>
                            <div></div>
                            <div>
                                <p>Adicionais</p>
                                <InputActivity>
                                    <input type="text" value={element.adicionais} onChange={(e) => editAdicionais(index, e.target.value)} />
                                </InputActivity>
                            </div>
                        </Content>
                    </ContainerGrid>
                </div>
            )}
            <ContainerButtom>
                <Button onClick={() => addMesa()} >
                    Adicionar Mesa
                    <img src={addIcon} alt="Iconde de adição" />
                </Button>
            </ContainerButtom>
        </div>
    )
}

export default SawmillTable