import styled from "styled-components";

export const Container = styled.div`
    padding: 1.75rem;

    .NoregisterIdentifield{
        position: absolute;
        width: 100vw;
     

        P{
            display: flex;
            justify-content: center;
            margin-top: 5rem;
        }
    }
`

export const ContentBox = styled.div`
    display:flex;
    justify-content:space-between;
    background-color:var(--primary-color);
    padding:0.5rem;
    width:100%;
    border-radius:0.3rem;
    margin-bottom:1rem;
`

export const InputFile = styled.div`
    background-color: #F1F1F2;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border: 1px solid #E1E1E1;
    border-radius: 0.3rem;
    color: var(--text-light-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;

    label{
        margin: 0.2rem;
        background-color: var(--primary-color);
        padding: 0.4rem;
        border: 1px solid #E1E1E1;
        color: var(--black-color);
        border-radius: 0.3rem;
        cursor: pointer;
    }
`

export const Tr = styled.tr`
    border-bottom: 5px solid #E5E5E5;
    color: #777777;
    font-weight: 700;
`
