import React, { useState, useEffect } from "react";
import { UseEletricActivityContext } from "../../../../Context/ActivitiesContext/Eletric/EletricContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid, InputFile } from "./style"

import { EletricPanelFormType } from "../../../../Models/Eletric/type";
import { attributeType } from "../../../../Models/attributeType";
import { EletricPanel } from "../../../../Models/Eletric/EletricPanelModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const EletricPanelStepForm: React.FC<stepFormProps> = (props) => {
  const { eletricActivities, editEletricActivity, indexEletricActivitySelected } = UseEletricActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<EletricPanelFormType>(EletricPanel)

  useEffect(() => {
    if (eletricActivities.length > 0) {
      let eletricActivityCopy = eletricActivities[indexEletricActivitySelected]
      eletricActivityCopy.activityType = 'Painel eletrico'
      editEletricActivity?.(indexEletricActivitySelected, eletricActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected])


  useEffect(() => {
    if (eletricActivities.length > 0) {
      setItensFormList(eletricActivities[indexEletricActivitySelected].EletricPanel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected, eletricActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemInstalacaoDePaineisNovos(0)
      removeItemModernizacaoDePainel(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Modernizacao de painel") {
      addModernizacaoDePainel({
        attributes: [
          {
            itemName: 'Amperagem',
            unit_of_measurement: 'A',
            value: ''
          },
          {
            itemName: 'Foto do painel',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Largura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Profundidade',
            unit_of_measurement: 'cm',
            value: ''
          },
        ]
      })
    } else if (assignment === "Instalacao de paineis novos") {
      addInstalacaoDePaineisNovos({
        attributes: [
          {
            itemName: 'Amperagem',
            unit_of_measurement: 'A',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Largura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Profundidade',
            unit_of_measurement: 'cm',
            value: ''
          },
        ]
      })
    }
  }

  const addModernizacaoDePainel = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.ModernizacaoDePainel.push(object)
    eletricActivitiesCopy[0].EletricPanel = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addInstalacaoDePaineisNovos = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.InstalacaoDePaineisNovos.push(object)
    eletricActivitiesCopy[0].EletricPanel = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemModernizacaoDePainel = (index: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.ModernizacaoDePainel.splice(index, 1)
    eletricActivitiesCopy[0].EletricPanel = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemInstalacaoDePaineisNovos = (index: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.InstalacaoDePaineisNovos.splice(index, 1)
    eletricActivitiesCopy[0].EletricPanel = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editModernizacaoDePainelValues = (value: string, indexItem: number, indexModernizacaoDePainel: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.ModernizacaoDePainel[indexModernizacaoDePainel].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editModernizacaoDePainelAttributeName = (value: string, indexItem: number, indexModernizacaoDePainel: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.ModernizacaoDePainel[indexModernizacaoDePainel].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editModernizacaoDePainelUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.ModernizacaoDePainel[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInstalacaoDePaineisNovosValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.InstalacaoDePaineisNovos[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInstalacaoDePaineisNovosAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.InstalacaoDePaineisNovos[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInstalacaoDePaineisNovosUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.InstalacaoDePaineisNovos[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  /*   const editModernizationPanelFile = (index: number, event: React.ChangeEvent<HTMLInputElement>, indexItem: number, indexItemForm: number) => {
     const itensFormListCopy = itensFormList
     const eletricActivitiesCopy = eletricActivities
     if (event.target.files) {
      itensFormListCopy?.ModernizacaoDePainel.splice(index, 1 {
        itensFormListCopy.ModernizacaoDePainel[indexItemForm].attributes[indexItem].value = [event.target.files]
      })
      setItensFormList(itensFormListCopy)
      editEletricActivity?.(0, eletricActivitiesCopy[0])
     }
    } */

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.ModernizacaoDePainel.length ? (
        <div>
          <P>Modernização de painel</P>
          {itensFormList.ModernizacaoDePainel.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editModernizacaoDePainelAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editModernizacaoDePainelValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall NovosPontosDeIluminacaodo">Drywall NovosPontosDeIluminacaodo</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editModernizacaoDePainelValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === 'Arquivo' ? (
                  <InputFile>

                    <label htmlFor={`ModernizationPanelFile`}>
                      Selecionar o arquivo
                    </label>
                    <input
                      type="file"
                      name="ModernizationPanelFile"
                      id={`ModernizationPanelFile`}
/*                     onChange={(event) => editModernizationPanelFile(index, event)}
 */                    multiple
                    />
                  </InputFile>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editModernizacaoDePainelAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editModernizacaoDePainelValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editModernizacaoDePainelUnitOfMeasurement(e, indexAttribute, indexItemForm)} />) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemModernizacaoDePainel(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.InstalacaoDePaineisNovos.length ? (
        <div>
          <P>Instalação de painéis novos</P>
          {itensFormList.InstalacaoDePaineisNovos.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInstalacaoDePaineisNovosAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editInstalacaoDePaineisNovosValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editInstalacaoDePaineisNovosUnitOfMeasurement(e, indexAttribute, indexItemForm)} />) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemInstalacaoDePaineisNovos(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default EletricPanelStepForm;
