export const Mesas = {
    Mesa: [
        {
            attributes: [
                {
                    itemName: 'Observações',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Comprimento',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Altura do peitoril',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Linha',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}
