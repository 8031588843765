export const DoorsAndWindows = {
    Porta: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: 'De correr'
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Janela: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura esquadria',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Largura esquadria',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Altura peitoril',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ]
}
