import React, { useEffect } from "react";
import axios from "axios";
import { Container, Complete } from "./style";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { UseUserContext } from "../../Context/UserContext";
import { UseGanttContext } from "../../Context/GanttContext";
interface ProgressTotalProps {
  total: number;
}

const ProgressTotal: React.FC<ProgressTotalProps> = (props) => {
  const { projectSelected } = UseUserContext();
  const { filterPeriod, setFilterPeriod } = UseGanttContext();
  useEffect(() => {
    if (projectSelected) {
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/updateProjectComplete/${props.total}/${projectSelected}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.total]);

  const changeUseGanttContext = (value: string) => {
    setFilterPeriod(value);
  };

  return (
    <Container width={filterPeriod === "cronogram" ? "33rem" : "18rem"}>
      <Complete width={props.total}>
        <p>
          <span>Progresso total</span> <span>({props.total}%)</span>
        </p>
      </Complete>
      {filterPeriod !== "cronogram" ? (
        <FiChevronRight
          size={22}
          style={{ cursor: "pointer" }}
          onClick={() => changeUseGanttContext?.("cronogram")}
        />
      ) : (
        <FiChevronLeft
          size={22}
          style={{ cursor: "pointer" }}
          onClick={() => changeUseGanttContext?.("month")}
        />
      )}
    </Container>
  );
};

export default ProgressTotal;
