import React, { useEffect } from "react";

import { allActivitiesProps } from "../../types/typesEntitiesProject"
import { UseSelectionBudgetContext } from "./Context/SelectionBudgetContext"
import { UseModalContext } from "../../Context/ModalContext";

import Comparatives from "../../pages/CotationMap/Comparatives";

import { ComparativeContainer, Container } from "./style"

import ActivityDescription from "../ActivityDescrition";

import Budget from "./Components/BudgetContainer";
import ModifyActivities from "../ModifyActivities";

interface selectionBudgetProps {
    activity: allActivitiesProps
}

const SelectionBudget: React.FC<selectionBudgetProps> = (props) => {
    const { setModalNameHeader } = UseModalContext();
    const { showBudgetSupplier, budget, supplier, modifyActivity } = UseSelectionBudgetContext()
    useEffect(() => {
        setModalNameHeader('Seleção de orçamento')
        // eslint-disable-next-line react-hooks/exhaustive-deps

        console.log(props.activity)
    }, [])
    return (
        <>
            {showBudgetSupplier && props.activity._id && budget && supplier ? (
                <ComparativeContainer>
                    <Comparatives
                        comparativeScreenConfigure="selectionBudgets visualization"
                        budgetSuppliersInformation={true}
                        trBorderColor={"#fff"}
                        activity={props.activity}
                        activityId={props.activity._id}
                        budgets={[budget]}
                        suppliers={[supplier]}
                    />
                </ComparativeContainer>
            ) : (
                <>
                    {modifyActivity ? (<ModifyActivities endPointRequestApi="activityReplace" activity={[props.activity]} activityType={props.activity.activityType} ModifyActivityExibition={true} />) : (
                        <Container>
                            <ActivityDescription activity={props.activity} />
                            {props.activity?._id ? (
                                <Budget activityId={props.activity?._id} />
                            ) : (<div>Atividade inválida</div>)}
                        </Container>
                    )}
                </>
            )}
        </>
    )
}

export default SelectionBudget