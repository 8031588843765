import styled from "styled-components";

export const OptionsButton = styled.div`
    cursor:pointer;
`

export const TypeWorkContainer = styled.div`
    display:grrid;
    justify-content: left;
    border-bottom: 1px solid #6a6a6a;
    div{
        display:flex;
        padding:0.5rem;
        align-items: self-end;
    }
    input{
        margin-right:0.3rem;
    }
`