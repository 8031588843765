import React, { useState, useEffect } from "react";
import { UseEletricActivityContext } from "../../../../Context/ActivitiesContext/Eletric/EletricContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid } from "./style"

import { PassageOfWiresFormType } from "../../../../Models/Eletric/type";
import { attributeType } from "../../../../Models/attributeType";
import { PassageOfWires } from "../../../../Models/Eletric/PassageOfWiresModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const PassageOfWiresStepForm: React.FC<stepFormProps> = (props) => {
  const { eletricActivities, editEletricActivity, indexEletricActivitySelected } = UseEletricActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<PassageOfWiresFormType>(PassageOfWires)
  const [otherWireTypeValue, setOtherWireTypeValue] = useState<string>('')
  const [otherWireMaterialValue, setOtherWireMaterialValue] = useState<string>('')

  useEffect(() => {
    if (eletricActivities.length > 0) {
      let eletricActivityCopy = eletricActivities[indexEletricActivitySelected]
      eletricActivityCopy.activityType = 'Passagem de fios'
      editEletricActivity?.(indexEletricActivitySelected, eletricActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected])


  useEffect(() => {
    if (eletricActivities.length > 0) {
      setItensFormList(eletricActivities[indexEletricActivitySelected].PassageOfWires);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected, eletricActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemFio(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Fio") {
      addFio({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Bitola do cabo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    }
  }

  const addFio = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Fio.push(object)
    eletricActivitiesCopy[0].PassageOfWires = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemFio = (index: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Fio.splice(index, 1)
    eletricActivitiesCopy[0].PassageOfWires = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editFioValues = (value: string, indexItem: number, indexFio: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Fio[indexFio].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editFioAttributeName = (value: string, indexItem: number, indexFio: number) => {
    var itensFormListCopy = itensFormList
    var eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Fio[indexFio].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editFioUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Fio[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.Fio.length ? (
        <div>
          <P>Fio</P>
          {itensFormList.Fio.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editFioAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editFioValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="110V">110V</option>
                      <option value="220V">220V</option>
                      <option value="Outros">Outros</option>
                      {otherWireTypeValue ? (<option value={otherWireTypeValue} selected>{otherWireTypeValue}</option>) : null}
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={otherWireTypeValue}
                            onChange={(e) => setOtherWireTypeValue(e.target.value)}
                          />
                          <button onClick={() => editFioValues(otherWireTypeValue, indexAttribute, indexItemForm)}>&#10003;</button>

                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === 'Bitola do cabo' ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editFioAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editFioValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="2,5mm">2,5mm</option>
                      <option value="4mm">4mm</option>
                      <option value="6mm">6mm</option>
                      <option value="8mm">8mm</option>
                      <option value="10mm">10mm</option>
                      <option value="12mm">12mm</option>
                      <option value="Outros">Outros</option>
                      {otherWireMaterialValue ? (<option value={otherWireMaterialValue} selected>{otherWireMaterialValue}</option>) : null}

                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={otherWireMaterialValue}
                            onChange={(e) => setOtherWireMaterialValue(e.target.value)}
                          />
                          <button onClick={() => editFioValues(otherWireMaterialValue, indexAttribute, indexItemForm)}>&#10003;</button>
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editFioAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editFioValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editFioUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemFio(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default PassageOfWiresStepForm;
