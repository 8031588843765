import styled from "styled-components";

interface SelectDayActualyContentProps {
  backgroundColor: string;
  textColor: string;
  width?: string;
}

export const SelectDayActualyContent = styled.div<SelectDayActualyContentProps>`
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  color: ${(props) => props.textColor};
  width: ${(props) => (props.width ? props.width : "0px")};

  font-size: 0.74rem;
  border-radius: 0.3rem;
  padding: 0.2rem;

  @media (max-width: 480px) {
    font-size: 35px;
  }
`;
