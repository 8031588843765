interface propsStep {
    percentage: number;
}

export  const progressTotalCalc = (steps:propsStep[], total:number)=>{
    var value = 0
   
    if(steps.length > 0){
     total =+ steps.map((step)=>{
        return value += step.percentage
      })
      total = value/steps.length
      return parseFloat(total.toFixed(2))
    } else return value
}
