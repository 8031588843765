export const Stairways = {
    Escadas: [
        {
            attributes: [
                {
                    itemName: 'Tipo de escada',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Comprimento',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Espessura',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Altura do pé direito',
                    unit_of_measurement: 'm',
                    value: ''
                },
            ],
            fileProject: [
                {
                    itemName: 'Arquivo',
                    link: '',
                    file: []
                },
            ]
        }
    ],
}

