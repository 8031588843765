import React, { useEffect } from 'react'

import { UseAirConditionerActivityContext } from "../../../Context/ActivitiesContext/AirConditioner/AirConditionerContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"
import { textButton } from '../script/textButtonSubmit'
import { RequestApi } from "../RequestApi"


import { Container } from "../style"

import AirConditionerStepForm from '../../StepsForm/AirConditioner/AirConditioner'
import MaterialsAirConditionerStepForm from '../../StepsForm/AirConditioner/Materials'

const AirConditionerModifyActivities: React.FC<activityProps> = (props) => {
    const { airConditionerActivities, setActivity, setIndexAirConditionerActivitySelected } = UseAirConditionerActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexAirConditionerActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Ar condicionado" ? (<AirConditionerStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsAirConditionerStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(airConditionerActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(airConditionerActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default AirConditionerModifyActivities