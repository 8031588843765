import styled from "styled-components";

export const Content=styled.div`
    background-color: var(--primary-color);
    padding-bottom: 0;
    display: grid;
    padding: 1rem;
    padding-bottom: 0;
    grid-template-columns: 1fr 1fr;
    margin: 1rem;

    label{
        cursor: pointer;
        display: flex;
        justify-content: center;
    }
    
    .selection{
        margin-top: 1rem;
        border-bottom: 0.2rem solid #A5A5A5;
    }

    input[type='radio']{
        display: none;
    }

    input[type='radio']:checked+label+div{
        border-bottom: 0.2rem solid var(--secondary-color);
    }
`