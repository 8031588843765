import React, { useState } from "react";
import { GasFormType } from "../../../../Models/Hydraulic/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;
    Gas: GasFormType;
}

const GasDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            <div>
                {activity.Gas.PontoDeGas?.map((item, index) => (
                    <div key={index}>
                        {activity.Gas.PontoDeGas.length > 1 ? (<u>Ponto de gás {index + 1}</u>) : (<u>Ponto de gás</u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}

                {activity.Gas.RetirarPontoDeGas?.map((item, index) => (
                    <div key={index}>
                        {activity.Gas.RetirarPontoDeGas.length > 1 ? (<u>Retirar ponto de gás {index + 1}</u>) : (<u>Retirar ponto de gás </u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}

                {activity.Gas.Tubulacao?.map((item, index) => (
                    <div key={index}>
                        {activity.Gas.Tubulacao.length > 1 ? (<u>Tubulação {index + 1}</u>) : (<u>Tubulação </u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}
                {activity.Gas.Emendas?.map((item, index) => (
                    <div key={index}>
                        {activity.Gas.Emendas.length > 1 ? (<u>Emendas {index + 1}</u>) : (<u>Emendas </u>)}
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}


            </div>
        </div>
    );
};

export default GasDescription;