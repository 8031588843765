import React, { useState, useEffect } from "react";

import { UseMasonaryActivityContext } from "../../../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { attributeType } from "../../../../Models/attributeType";
import { ReframingFormType } from "../../../../Models/Masonary/type";
import { Reframing } from "../../../../Models/Masonary/ReframingModel";
import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";

import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const ReframingStepForm: React.FC<stepFormProps> = (props) => {
  const { masonaryActivities, indexMasonaryActivitySelected, editMasonaryActivity } = UseMasonaryActivityContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<ReframingFormType>(Reframing)

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      let masonaryActivityCopy = masonaryActivities[indexMasonaryActivitySelected]
      masonaryActivityCopy.activityType = 'Requadramento de vao'
      editMasonaryActivity?.(indexMasonaryActivitySelected, masonaryActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected])

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      setItensFormList(masonaryActivities[indexMasonaryActivitySelected].Reframing)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected, masonaryActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemRequadramento(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Requadramento") {
      addRequadramento({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Largura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    }
  }

  const addRequadramento = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var reframingActivitiesCopy = masonaryActivities
    itensFormListCopy.Requadramento.push(object)
    reframingActivitiesCopy[0].Reframing = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, reframingActivitiesCopy[0])
    console.log('oi')
  }

  const removeItemRequadramento = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Requadramento.splice(index, 1)
    masonaryActivitiesCopy[0].Reframing = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editRequadramentoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Requadramento[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editRequadramentoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Requadramento[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editRequadramentoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Requadramento[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Requadramento.length ? (
        <div>
          <p>Requadramento</p>
          {itensFormList.Requadramento.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRequadramentoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRequadramentoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRequadramentoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRequadramentoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRequadramentoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editRequadramentoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRequadramento(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ReframingStepForm;
