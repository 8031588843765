import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../../style"
import { GreenDestack } from "../../../style"
import { PassageOfWiresFormType } from "../../../../../Models/Eletric/type";


interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface attributeType {
    attributes: {
        itemName: string,
        unit_of_measurement: string,
        value: string
    }[]
}

interface activityProps {
    selectedBudget?: boolean,
    PassageOfWires?: PassageOfWiresFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        Fio?: string[]
    }
}

export const PassagemDeFiosComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: any, activityId?: string, itemType?: any) => {
        if (!totalItem) {
            return <p>R$0,00</p>
        }
        return <p>R${totalItem}</p>
    }

    return (
        <ContainerItensBudget>
            <>
                {activity.PassageOfWires?.Fio.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Fio</p>

                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.PassageOfWires?.Fio?.map((item, index) => (
                            <div key={index}>
                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Fio ? renderItemBudget(budget.budget?.Fio[index], budget.activityId, 'Fio') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Fio ? renderItemBudget(budget.budget?.Fio[index], budget.activityId, 'Fio') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>
                            </div>
                        ))}
                    </>
                ) : (null)}
            </>
        </ContainerItensBudget>
    )
}

export default PassagemDeFiosComparartive