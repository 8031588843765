export const Niche = {
    Nicho: [
        {
            attributes: [
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Área',
                    unit_of_measurement: 'm²',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Profundidade',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Espessura da aba',
                    unit_of_measurement: 'm',
                    value: ''
                },
                {
                    itemName: 'Pedra',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Acabamento da pedra',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Cor da pedra',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Número de divisórias horizontais',
                    unit_of_measurement: 'un',
                    value: ''
                },
            ]
        }
    ],
}