import React, { useEffect } from "react";

import { UseTablewareAndMetalsActivityContext } from "../../../Context/ActivitiesContext/TablewareAndMetals/TablewareAndMetalsContext"
import { UseStepContext } from "../../../Context/stepContext"
import { textTransform } from "../script"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const TablewareAndMetalsController: React.FC = () => {
    const { tablewareAndMetalsActivities, editTablewareAndMetalsActivity } = UseTablewareAndMetalsActivityContext()
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Vasos')
    }, [])

    useEffect(() => {
        if (tablewareAndMetalsActivities.length > 0) {
            var activityEdited = tablewareAndMetalsActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editTablewareAndMetalsActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    useEffect(() => {
        if (tablewareAndMetalsActivities.length > 0 && tablewareAndMetalsActivities[0].activityType !== "") {
            setActivitySelected(tablewareAndMetalsActivities[0].activityType)
        }
        tablewareAndMetalsActivities.length > 0 && tablewareAndMetalsActivities[0].activityType === "" ? (setActivitySelected('Vasos')) : (<></>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="masonryStep" id="masonryStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Vasos">Vasos</option>
                <option value="Torneira e misturador">Torneira e misturador</option>
                <option value="Cubas">Cubas</option>
                <option value="Chuveiros">Chuveiros</option>
                <option value="Acessorios">Acessórios</option>
                <option value="Materiais para loucas e metais">Materiais</option>

            </select>
        </ActionSelect>
    </div>

};

export default TablewareAndMetalsController;
