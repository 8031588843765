import { verify } from "jsonwebtoken";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserProviderProps } from "../types/typesEntitiesProject";

/**A interface AuthAcessContextTypes define o formato dos dados que serão armazenados no contexto,
 * como a lista de funcionalidades disponíveis, o link de acesso, a página atual, etc. */
interface AuthAcessContextTypes {
  features: string[];
  setfeatures: React.Dispatch<React.SetStateAction<string[]>>;
  acessLink: boolean;
  setAcessLink: React.Dispatch<React.SetStateAction<boolean>>;
  actualPage: string;
  setActualPage: React.Dispatch<React.SetStateAction<string>>;
  editPermission: string;
  setEditPermission: React.Dispatch<React.SetStateAction<string>>;
}

/**O objeto defaultStepModal é o objeto padrão para o contexto, que será usado quando não houver valor definido. */
const defaultStepModal = {
  features: [],
  setfeatures: () => {},
  acessLink: false,
  setAcessLink: () => {},
  actualPage: "",
  setActualPage: () => {},
  editPermission: "",
  setEditPermission: () => {},
};

/**A função createContext cria o contexto de controle de acesso do React,
 * utilizando a interface AuthAcessContextTypes e o objeto defaultStepModal como valor inicial.  */
const AuthAcessContext =
  React.createContext<AuthAcessContextTypes>(defaultStepModal);

/**O hook UseAuthAcessContext é uma função que retorna o contexto de controle de acesso, permitindo que os componentes filhos o consumam. */
export const UseAuthAcessContext = () => useContext(AuthAcessContext);

/** O componente AuthAcessProvider é o componente que provê o contexto de controle de acesso para os componentes filhos.
 *Ele utiliza o hook useState para definir os estados dos dados de controle de acesso e retorna o componente filho com o contexto atualizado,
 *utilizando a propriedade value do componente Provider.*/
export const AuthAcessProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [features, setfeatures] = useState<string[]>([]);
  const [acessLink, setAcessLink] = useState<boolean>(false);
  const [actualPage, setActualPage] = useState<string>("");
  const [editPermission, setEditPermission] = useState<string>("");

  return (
    <AuthAcessContext.Provider
      value={{
        features,
        acessLink,
        actualPage,
        editPermission,
        setfeatures,
        setAcessLink,
        setActualPage,
        setEditPermission,
      }}
    >
      {children}
    </AuthAcessContext.Provider>
  );
};
