import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ListContainerItensFrames,
  ItemContentListFrames,
} from "./style";

import { SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate";
import { FramesFormType } from "../../../../Models/Frames/type";

import FooterBudget from "../../Components/FooterBudget";
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any;
  supplier: SupplierProps;
  margin: number;
}

interface activityProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string;
  activityType?: string;
  stepType?: string;

  Frames: FramesFormType;
}

const EsquadriasBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [portas, setPortas] = useState<string[]>(["0,00"]);
  const [janelas, setJanelas] = useState<string[]>(["0,00"]);
  const [divisorias, setDivisorias] = useState<string[]>(["0,00"]);

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>("0,00");
  const [conditions, setConditions] = useState<string>("");

  useEffect(() => {
    activity.Frames.Portas?.forEach(() => {
      setPortas((prev) => [...prev, "0,00"]);
    });
    // eslint-disable-next-line
    activity.Frames.Janelas?.forEach(() => {
      setJanelas((prev) => [...prev, "0,00"]);
    });

    activity.Frames.Divisorias?.forEach(() => {
      setDivisorias((prev) => [...prev, "0,00"]);
    });
  }, [activity]);

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const portasTotal = sum(portas);
    const janelasTotal = sum(janelas);
    const divisoriasTotal = sum(divisorias);
    setTotalBudgetPayment(sum([portasTotal, janelasTotal, divisoriasTotal]));
  }, [portas, janelas, divisorias]);

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const portasTotal = sum(portas);
  const janelasTotal = sum(janelas);
  const divisoriasTotal = sum(divisorias);

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(
        parseFloat(minimumBudgetValue).toFixed(2).replace(".", ",")
      );
    } else {
      setTotalBudgetPayment(sum([portasTotal, janelasTotal, divisoriasTotal]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (
    index: number,
    stateVar: string[],
    setStateRef: any,
    value: string,
    quantityMultiply?: string
  ) => {
    const stateCopy = Array.from(stateVar);
    stateCopy.splice(index, 1, multiply("1", value));
    quantityMultiply ? (
      stateCopy.splice(index, 1, multiply(quantityMultiply, value))
    ) : (
      <></>
    );
    setStateRef(stateCopy);
  };

  const submitBudget = () => {
    const portasMargin: number[] = [];
    const janelasMargin: number[] = [];
    const divisoriasMargin: number[] = [];

    portas.forEach((item) => {
      if (item != "0,00") {
        portasMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    janelas.forEach((item) => {
      if (item != "0,00") {
        janelasMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    divisorias.forEach((item) => {
      if (item != "0,00") {
        divisoriasMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: "",
        workingDays,
        selectedBudget: false,
        budget: {
          ParedeDeAlvenaria: portasMargin,
          Janelas: janelasMargin,
          Divisorias: divisoriasMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (
          Number(totalBudgetPayment.replace(",", ".")) +
          Number(totalBudgetPayment.replace(",", ".")) * (props.margin / 100)
        ).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensFrames>
        {activity.Frames.Portas?.map((item, index) => (
          <div key={index}>
            {activity.Frames.Portas.length > 1 ? (
              <P>Porta {index + 1}</P>
            ) : (
              <P>Porta</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? <span>{tarefa.itemName}</span> : ""}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListFrames>
              {item.attributes?.map((tarefa, index) => (
                <p>
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value ||
                    (tarefa.value && tarefa.unit_of_measurement)
                      ? tarefa.value + "" + tarefa.unit_of_measurement
                      : ""}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem
                  onChange={(e) =>
                    updateState(index, portas, setPortas, e.target.value)
                  }
                />
              </ContentInputPrice>
              {portas && portas[index] !== "0" ? (
                <p className="item">{portas[index]}</p>
              ) : (
                <p className="item">0,00</p>
              )}
            </ItemContentListFrames>
          </div>
        ))}
      </ListContainerItensFrames>

      <ListContainerItensFrames>
        {activity.Frames.Janelas?.map((item, index) => (
          <div key={index}>
            {activity.Frames.Janelas.length > 1 ? (
              <P>Janela {index + 1}</P>
            ) : (
              <P>Janela</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? <span>{tarefa.itemName}</span> : ""}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListFrames>
              {item.attributes?.map((tarefa, index) => (
                <p>
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value ||
                    (tarefa.value && tarefa.unit_of_measurement)
                      ? tarefa.value + "" + tarefa.unit_of_measurement
                      : ""}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem
                  onChange={(e) =>
                    updateState(index, janelas, setJanelas, e.target.value)
                  }
                />
              </ContentInputPrice>
              {janelas && janelas[index] !== "0" ? (
                <p className="item">{janelas[index]}</p>
              ) : (
                <p className="item">0,00</p>
              )}
            </ItemContentListFrames>
          </div>
        ))}
      </ListContainerItensFrames>

      <ListContainerItensFrames>
        {activity.Frames.Divisorias?.map((item, index) => (
          <div key={index}>
            {activity.Frames.Divisorias.length > 1 ? (
              <P>Divisória {index + 1}</P>
            ) : (
              <P>Divisória</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? <span>{tarefa.itemName}</span> : ""}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListFrames>
              {item.attributes?.map((tarefa, index) => (
                <p>
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value ||
                    (tarefa.value && tarefa.unit_of_measurement)
                      ? tarefa.value + "" + tarefa.unit_of_measurement
                      : ""}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem
                  onChange={(e) =>
                    updateState(
                      index,
                      divisorias,
                      setDivisorias,
                      e.target.value
                    )
                  }
                />
              </ContentInputPrice>
              {divisorias && divisorias[index] !== "0" ? (
                <p className="item">{divisorias[index]}</p>
              ) : (
                <p className="item">0,00</p>
              )}
            </ItemContentListFrames>
          </div>
        ))}
      </ListContainerItensFrames>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  );
};

export default EsquadriasBudget;
