export const EletricPanel = {
    ModernizacaoDePainel: [
        {
            attributes: [
                {
                    itemName: 'Amperagem',
                    unit_of_measurement: 'A',
                    value: ''
                },
                {
                    itemName: 'Foto do painel',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Profundidade',
                    unit_of_measurement: 'cm',
                    value: ''
                },
            ]
        }
    ],
    InstalacaoDePaineisNovos: [
        {
            attributes: [
                {
                    itemName: 'Amperagem',
                    unit_of_measurement: 'A',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Profundidade',
                    unit_of_measurement: 'cm',
                    value: ''
                },
            ]
        }
    ],
}