import React, { useEffect, useState } from "react";

import { UseMarbleworkActivityContext } from "../../../../Context/ActivitiesContext/Marblework/MarbleworkContext"

import { InputActivity, Button } from "../../../stylesDomElements/htmlStyledElements";
import { Content, Select, ContainerGrid, P, ContainerButtom } from "../../style";

import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import addIcon from "../../../../assets/addIcon.svg";

interface marbleProps {
  pedra: string,
  cor_pedra: string,
  descricao_marble: string,
  acabamento_pedra: string,
  altura_item_revestido: {
    tipo_medida: string;
    medida: string
  },
  largura_item_revestido: {
    tipo_medida: string;
    medida: string
  },
  profundidade_item_revestido: {
    tipo_medida: string;
    medida: string
  }
}

const ActivityGenericForm: React.FC = () => {
  const { marbleworkActivities, editMarbleWorkActivity, indexMarbleworkActivitySelected } = UseMarbleworkActivityContext()

  const [marble, setMarble] = useState<marbleProps[]>([{
    pedra: 'Granito',
    cor_pedra: '',
    descricao_marble: '',
    acabamento_pedra: 'Natural',
    altura_item_revestido: {
      tipo_medida: "",
      medida: ""
    },
    largura_item_revestido: {
      tipo_medida: "",
      medida: ""
    },
    profundidade_item_revestido: {
      tipo_medida: "",
      medida: ""
    }
  }])

  useEffect(() => {
    if (marbleworkActivities[indexMarbleworkActivitySelected]) {
      setMarble(marbleworkActivities[indexMarbleworkActivitySelected].marble)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMarbleworkActivitySelected, marbleworkActivities])

  const editPedra = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: value,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editCorPedra = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: value,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editDescricaoMarble = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: value,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editAcabamentoPedra = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: value,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editAlturaImtemRevestido = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: { tipo_medida: marbleCopy[index].altura_item_revestido.tipo_medida, medida: value },
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editLarguraItemRevestido = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: { tipo_medida: marbleCopy[index].largura_item_revestido.tipo_medida, medida: value },
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editProfundidadeItemRevestido = (index: number, value: string) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: { tipo_medida: marbleCopy[index].profundidade_item_revestido.tipo_medida, medida: value },
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editTipoMedidaAltura = (index: number, value: { tipo_medida: string, medida: string }) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: value,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editTipoMedidaLargura = (index: number, value: { tipo_medida: string, medida: string }) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: value,
      profundidade_item_revestido: marbleCopy[index].profundidade_item_revestido,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editTipoMedidaProfundidade = (index: number, value: { tipo_medida: string, medida: string }) => {
    const marbleCopy = Array.from(marble)
    marbleCopy.splice(index, 1, {
      pedra: marbleCopy[index].pedra,
      cor_pedra: marbleCopy[index].cor_pedra,
      descricao_marble: marbleCopy[index].descricao_marble,
      acabamento_pedra: marbleCopy[index].acabamento_pedra,
      altura_item_revestido: marbleCopy[index].altura_item_revestido,
      largura_item_revestido: marbleCopy[index].largura_item_revestido,
      profundidade_item_revestido: value,
    })
    setMarble(marbleCopy)
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble = marbleCopy;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const addMarble = () => {
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.marble.push(
      {
        pedra: 'Granito',
        cor_pedra: '',
        descricao_marble: '',
        acabamento_pedra: '',
        altura_item_revestido: {
          tipo_medida: "",
          medida: ""
        },
        largura_item_revestido: {
          tipo_medida: "",
          medida: ""
        },
        profundidade_item_revestido: {
          tipo_medida: "",
          medida: ""
        }
      }
    )
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    setMarble((prev) => [...prev, {
      pedra: 'Granito',
      cor_pedra: '',
      descricao_marble: '',
      acabamento_pedra: '',
      altura_item_revestido: {
        tipo_medida: "",
        medida: ""
      },
      largura_item_revestido: {
        tipo_medida: "",
        medida: ""
      },
      profundidade_item_revestido: {
        tipo_medida: "",
        medida: ""
      }

    }])
  }

  const removeItem = (index: number) => {
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    const marbleCopy = [...marble]
    marbleCopy.splice(index, 1)
    activityEdited.marble = marbleCopy
    setMarble(marbleCopy);
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  return (
    <div>
      {marble.map((element, index) =>
        <div>
          <P>Atividade {index + 1}</P>
          <ContainerGrid>
            <Content>
              <div>
                <p>Pedra</p>
                <Select value={element.pedra} onChange={(e) => editPedra(index, e.target.value)}>
                  <option value="Granito">Granito</option>
                  <option value="Quartzo">Quartzo</option>
                  <option value="Mármore">Mármore</option>
                  <option value="Prime">Prime</option>
                  <option value="Lâmina ultracompctada">Lâmina ultracompctada</option>
                  <option value="Travertino">Travertino</option>
                </Select>
              </div>
              <div className="resizeContentInputs">
                <br />
                <p>Cor da pedra</p>
                <InputActivity>
                  <input type="text" value={element.cor_pedra} onChange={(e) => editCorPedra(index, e.target.value)} />
                  &nbsp;
                </InputActivity>
              </div>
              <div>
                <p className="textNoChashLine">Acabamento da pedra</p>
                <Select value={element.acabamento_pedra} onChange={(e) => editAcabamentoPedra(index, e.target.value)}>
                  <option value="Natural">Natural</option>
                  <option value="Polido">Polido</option>
                  <option value="Levigado/Satinado">Levigado/Satinado</option>
                </Select>
              </div>
              <div>
                Dimensões do item a ser revestido:
                <div>
                  <div>
                    <p>Altura</p>
                    <InputActivity>
                      <input type="text" value={element.altura_item_revestido.medida} onChange={(e) => editAlturaImtemRevestido(index, e.target.value)} />
                      {/* <SelectTypeMeasure value={element.altura_item_revestido.tipo_medida} onChange={(e) => editTipoMedidaAltura(index, { tipo_medida: e.target.value, medida: element.altura_item_revestido.medida })}>
                        <option value="m²">m²</option>
                        <option value="Peças">Peças</option>
                        <option value="m linear">m linear</option>
                        <option value="cm">cm</option>
                      </SelectTypeMeasure> */}
                      <SelectTypeMeasureItem defaultValue={element.altura_item_revestido.tipo_medida} options={['m²', 'Peças', 'm linear', 'cm']} function={()=>{}} />
                    </InputActivity>
                  </div>
                  <div>
                    <p>Largura</p>
                    <InputActivity>
                      <input type="text" value={element.largura_item_revestido.medida} onChange={(e) => editLarguraItemRevestido(index, e.target.value)} />
                      {/* <SelectTypeMeasure value={element.largura_item_revestido.tipo_medida} onChange={(e) => editTipoMedidaLargura(index, { tipo_medida: e.target.value, medida: element.altura_item_revestido.medida })}>
                        <option value="m²">m²</option>
                        <option value="Peças">Peças</option>
                        <option value="m linear">m linear</option>
                        <option value="cm">cm</option>
                      </SelectTypeMeasure> */}
                    </InputActivity>
                  </div>
                  <div>
                    <p>Profundidade</p>
                    <InputActivity>
                      <input type="text" value={element.profundidade_item_revestido.medida} onChange={(e) => editProfundidadeItemRevestido(index, e.target.value)} />
                      {/* <SelectTypeMeasure value={element.profundidade_item_revestido.tipo_medida} onChange={(e) => editTipoMedidaProfundidade(index, { tipo_medida: e.target.value, medida: element.altura_item_revestido.medida })}>
                        <option value="m²">m²</option>
                        <option value="Peças">Peças</option>
                        <option value="m linear">m linear</option>
                        <option value="cm">cm</option>
                      </SelectTypeMeasure> */}
                    </InputActivity>
                  </div>
                </div>
              </div>
              <div className="">
                <p>Descrição</p>
                <InputActivity>
                  <input type="text" value={element.descricao_marble} onChange={(e) => editDescricaoMarble(index, e.target.value)} />
                  &nbsp;
                </InputActivity>
              </div>
            </Content>

            <div></div>
            <div className="cancelX" onClick={() => removeItem(index)}>X</div>
          </ContainerGrid>
        </div>
      )}
      <ContainerButtom>
        <Button onClick={() => addMarble()}>
          Adicionar Atividade
          <img src={addIcon} alt="Iconde de adição" />
        </Button>
      </ContainerButtom>
    </div>
  );
};

export default ActivityGenericForm;
