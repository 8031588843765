import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
  ItemContentListSapatas,
  ListContainerItensSapatas,
  ListContainerItensInstallations,
  ItemContentListInstallations,
} from "./style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { InstallationsFormType } from "../../../../Models/Eletric/type";

import FooterBudget from "../../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  Installations: InstallationsFormType
}

const InstalacoesBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [lampadas, setLampadas] = useState<string[]>(["0,00"])
  const [interruptores, setInterruptores] = useState<string[]>(["0,00"])
  const [tomadas, setTomadas] = useState<string[]>(["0,00"])
  const [fitasDeLed, setFitasDeLed] = useState<string[]>(["0,00"])
  const [luminarias, setLuminarias] = useState<string[]>(["0,00"])
  const [spot, setSpot] = useState<string[]>(["0,00"])
  const [arandela, setArandela] = useState<string[]>(["0,00"])
  const [trilhos, setTrilhos] = useState<string[]>(["0,00"])
  const [pendentes, setPendentes] = useState<string[]>(["0,00"])
  const [antena, setAntena] = useState<string[]>(["0,00"])
  const [caboDeInternet, setCaboDeInternet] = useState<string[]>(["0,00"])
  const [interfone, setInterfone] = useState<string[]>(["0,00"])
  const [cameraDeSeguranca, setCameraDeSeguranca] = useState<string[]>(["0,00"])
  const [sensores, setSensores] = useState<string[]>(["0,00"])
  const [plafon, setPlafon] = useState<string[]>(["0,00"])
  const [pontoDeDados, setPontoDeDados] = useState<string[]>(["0,00"])
  const [pontoDeTv, setPontoDeTv] = useState<string[]>(["0,00"])
  const [exaustor, setExaustor] = useState<string[]>(["0,00"])
  const [pontoDeForca, setPontoDeForca] = useState<string[]>(["0,00"])

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Installations.Lampadas.forEach(() => {
      setLampadas((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.Installations.Interruptores?.forEach(() => {
      setInterruptores((prev) => [...prev, "0,00"])
    })

    activity.Installations.Tomadas?.forEach(() => {
      setTomadas((prev) => [...prev, "0,00"])
    })

    activity.Installations.FitasDeLed?.forEach(() => {
      setFitasDeLed((prev) => [...prev, "0,00"])
    })

    activity.Installations.Luminarias?.forEach(() => {
      setLuminarias((prev) => [...prev, "0,00"])
    })

    activity.Installations.Spot?.forEach(() => {
      setSpot((prev) => [...prev, "0,00"])
    })

    activity.Installations.Arandela?.forEach(() => {
      setArandela((prev) => [...prev, "0,00"])
    })

    activity.Installations.Trilhos?.forEach(() => {
      setTrilhos((prev) => [...prev, "0,00"])
    })

    activity.Installations.Pendentes?.forEach(() => {
      setPendentes((prev) => [...prev, "0,00"])
    })

    activity.Installations.Antena?.forEach(() => {
      setAntena((prev) => [...prev, "0,00"])
    })

    activity.Installations.CaboDeInternet?.forEach(() => {
      setCaboDeInternet((prev) => [...prev, "0,00"])
    })

    activity.Installations.Interfone?.forEach(() => {
      setInterfone((prev) => [...prev, "0,00"])
    })

    activity.Installations.CameraDeSeguranca?.forEach(() => {
      setCameraDeSeguranca((prev) => [...prev, "0,00"])
    })

    activity.Installations.Sensores?.forEach(() => {
      setSensores((prev) => [...prev, "0,00"])
    })

    activity.Installations.Plafon?.forEach(() => {
      setPlafon((prev) => [...prev, "0,00"])
    })

    activity.Installations.PontoDeDados?.forEach(() => {
      setPontoDeDados((prev) => [...prev, "0,00"])
    })

    activity.Installations.PontoDeTv?.forEach(() => {
      setPontoDeTv((prev) => [...prev, "0,00"])
    })

    activity.Installations.Exaustor?.forEach(() => {
      setExaustor((prev) => [...prev, "0,00"])
    })

    activity.Installations.PontoDeForca?.forEach(() => {
      setPontoDeForca((prev) => [...prev, "0,00"])
    })
  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const lampadasTotal = sum(lampadas)
    const interruptoresTotal = sum(interruptores)
    const tomadasTotal = sum(tomadas)
    const fitasDeLedTotal = sum(fitasDeLed)
    const luminariasTotal = sum(luminarias)
    const spotTotal = sum(spot)
    const arandelaTotal = sum(arandela)
    const trilhosTotal = sum(trilhos)
    const pendentesTotal = sum(pendentes)
    const antenaTotal = sum(antena)
    const caboDeInternetTotal = sum(caboDeInternet)
    const interfoneTotal = sum(interfone)
    const cameraDeSegurancaTotal = sum(cameraDeSeguranca)
    const sensoresTotal = sum(sensores)
    const plafonTotal = sum(plafon)
    const pontoDeDadosTotal = sum(pontoDeDados)
    const pontoDeTvTotal = sum(pontoDeTv)
    const exaustorTotal = sum(exaustor)
    const pontoDeForcaTotal = sum(pontoDeForca)

    setTotalBudgetPayment(sum([lampadasTotal, interruptoresTotal, tomadasTotal, fitasDeLedTotal, luminariasTotal, spotTotal, arandelaTotal, trilhosTotal, pendentesTotal, antenaTotal, caboDeInternetTotal, interfoneTotal, cameraDeSegurancaTotal, sensoresTotal, plafonTotal, pontoDeDadosTotal, pontoDeTvTotal, exaustorTotal, pontoDeForcaTotal]))
  }, [lampadas, interruptores, tomadas, fitasDeLed, luminarias, spot, arandela, trilhos, pendentes, antena, caboDeInternet, interfone, cameraDeSeguranca, sensores, plafon, pontoDeDados, pontoDeTv, exaustor, pontoDeForca])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const lampadasTotal = sum(lampadas)
  const interruptoresTotal = sum(interruptores)
  const tomadasTotal = sum(tomadas)
  const fitasDeLedTotal = sum(fitasDeLed)
  const luminariasTotal = sum(luminarias)
  const spotTotal = sum(spot)
  const arandelaTotal = sum(arandela)
  const trilhosTotal = sum(trilhos)
  const pendentesTotal = sum(pendentes)
  const antenaTotal = sum(antena)
  const caboDeInternetTotal = sum(caboDeInternet)
  const interfoneTotal = sum(interfone)
  const cameraDeSegurancaTotal = sum(cameraDeSeguranca)
  const sensoresTotal = sum(sensores)
  const plafonTotal = sum(plafon)
  const pontoDeDadosTotal = sum(pontoDeDados)
  const pontoDeTvTotal = sum(pontoDeTv)
  const exaustorTotal = sum(exaustor)
  const pontoDeForcaTotal = sum(pontoDeForca)

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([lampadasTotal, interruptoresTotal, tomadasTotal, fitasDeLedTotal, luminariasTotal, spotTotal, arandelaTotal, trilhosTotal, pendentesTotal, antenaTotal, caboDeInternetTotal, interfoneTotal, cameraDeSegurancaTotal, sensoresTotal, plafonTotal, pontoDeDadosTotal, pontoDeTvTotal, exaustorTotal, pontoDeForcaTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const lampadasMargin: number[] = []
    const interruptoresMargin: number[] = []
    const tomadasMargin: number[] = []
    const fitasDeLedMargin: number[] = []
    const luminariasMargin: number[] = []
    const spotMargin: number[] = []
    const arandelaMargin: number[] = []
    const trilhosMargin: number[] = []
    const pendentesMargin: number[] = []
    const antenaMargin: number[] = []
    const caboDeInternetMargin: number[] = []
    const interfoneMargin: number[] = []
    const cameraDeSegurancaMargin: number[] = []
    const sensoresMargin: number[] = []
    const plafonMargin: number[] = []
    const pontoDeDadosMargin: number[] = []
    const pontoDeTvMargin: number[] = []
    const exaustorMargin: number[] = []
    const pontoDeForcaMargin: number[] = []

    lampadas.forEach((item) => {
      if (item != "0,00") {
        lampadasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    interruptores.forEach((item) => {
      if (item != "0,00") {
        interruptoresMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    tomadas.forEach((item) => {
      if (item != "0,00") {
        tomadasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    fitasDeLed.forEach((item) => {
      if (item != "0,00") {
        fitasDeLedMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    luminarias.forEach((item) => {
      if (item != "0,00") {
        luminariasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    arandela.forEach((item) => {
      if (item != "0,00") {
        arandelaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    spot.forEach((item) => {
      if (item != "0,00") {
        spotMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    trilhos.forEach((item) => {
      if (item != "0,00") {
        trilhosMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    pendentes.forEach((item) => {
      if (item != "0,00") {
        pendentesMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    antena.forEach((item) => {
      if (item != "0,00") {
        antenaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    caboDeInternet.forEach((item) => {
      if (item != "0,00") {
        caboDeInternetMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    interfone.forEach((item) => {
      if (item != "0,00") {
        interfoneMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    cameraDeSeguranca.forEach((item) => {
      if (item != "0,00") {
        cameraDeSegurancaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    sensores.forEach((item) => {
      if (item != "0,00") {
        sensoresMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    plafon.forEach((item) => {
      if (item != "0,00") {
        plafonMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    pontoDeDados.forEach((item) => {
      if (item != "0,00") {
        pontoDeDadosMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    pontoDeTv.forEach((item) => {
      if (item != "0,00") {
        pontoDeTvMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    exaustor.forEach((item) => {
      if (item != "0,00") {
        exaustorMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    pontoDeForca.forEach((item) => {
      if (item != "0,00") {
        pontoDeForcaMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Lampadas: lampadasMargin,
          Interruptores: interruptoresMargin,
          Tomadas: tomadasMargin,
          FitasDeLed: fitasDeLedMargin,
          Luminarias: luminariasMargin,
          Spot: spotMargin,
          Arandela: arandelaMargin,
          Trilhos: trilhosMargin,
          Pendentes: pendentesMargin,
          Antena: antenaMargin,
          CaboDeInternet: caboDeInternetMargin,
          Interfone: interfoneMargin,
          CameraDeSeguranca: cameraDeSegurancaMargin,
          Sensores: sensoresMargin,
          Plafon: plafonMargin,
          PontoDeDados: pontoDeDadosMargin,
          PontoDeTv: pontoDeTvMargin,
          Exaustor: exaustorMargin,
          PontoDeForca: pontoDeForcaMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensInstallations>

        {activity.Installations.Lampadas?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Lampadas.length > 1 ? (<P>Lampadas {index + 1}</P>) : (<P>Lampadas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, lampadas, setLampadas, e.target.value)} />
              </ContentInputPrice>
              {lampadas && lampadas[index] !== '0' ?
                (<p className="item">{lampadas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Interruptores?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Interruptores.length > 1 ? (<P>Interruptores {index + 1}</P>) : (<P>Interruptores</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, interruptores, setInterruptores, e.target.value)} />
              </ContentInputPrice>
              {interruptores && interruptores[index] !== '0' ?
                (<p className="item">{interruptores[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}
      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Tomadas?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Tomadas.length > 1 ? (<P>Tomadas {index + 1}</P>) : (<P>Tomadas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, tomadas, setTomadas, e.target.value)} />
              </ContentInputPrice>
              {tomadas && tomadas[index] !== '0' ?
                (<p className="item">{tomadas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}
      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.FitasDeLed?.map((item, index) => (
          <div key={index}>
            {activity.Installations.FitasDeLed.length > 1 ? (<P>Fitas de led {index + 1}</P>) : (<P>Fitas de led</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, fitasDeLed, setFitasDeLed, e.target.value)} />
              </ContentInputPrice>
              {fitasDeLed && fitasDeLed[index] !== '0' ?
                (<p className="item">{fitasDeLed[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Luminarias?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Luminarias.length > 1 ? (<P>Luminarias {index + 1}</P>) : (<P>Luminarias</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, luminarias, setLuminarias, e.target.value)} />
              </ContentInputPrice>
              {luminarias && luminarias[index] !== '0' ?
                (<p className="item">{luminarias[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}
      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Spot?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Spot.length > 1 ? (<P>Spot {index + 1}</P>) : (<P>Spot</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, spot, setSpot, e.target.value)} />
              </ContentInputPrice>
              {spot && spot[index] !== '0' ?
                (<p className="item">{spot[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>
      <ListContainerItensInstallations>
        {activity.Installations.Arandela?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Arandela.length > 1 ? (<P>Arandela {index + 1}</P>) : (<P>Arandela</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, arandela, setArandela, e.target.value)} />
              </ContentInputPrice>
              {arandela && arandela[index] !== '0' ?
                (<p className="item">{arandela[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Trilhos?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Trilhos.length > 1 ? (<P>Trilhos {index + 1}</P>) : (<P>Trilhos</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, trilhos, setTrilhos, e.target.value)} />
              </ContentInputPrice>
              {trilhos && trilhos[index] !== '0' ?
                (<p className="item">{trilhos[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>


      <ListContainerItensInstallations>
        {activity.Installations.Pendentes?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Pendentes.length > 1 ? (<P>Pendentes {index + 1}</P>) : (<P>Pendentes</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pendentes, setPendentes, e.target.value)} />
              </ContentInputPrice>
              {pendentes && pendentes[index] !== '0' ?
                (<p className="item">{pendentes[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Antena?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Antena.length > 1 ? (<P>antena {index + 1}</P>) : (<P>antena</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, antena, setAntena, e.target.value)} />
              </ContentInputPrice>
              {antena && antena[index] !== '0' ?
                (<p className="item">{antena[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.CaboDeInternet?.map((item, index) => (
          <div key={index}>
            {activity.Installations.CaboDeInternet.length > 1 ? (<P>Cabo de internet {index + 1}</P>) : (<P>Cabo de internet</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, caboDeInternet, setCaboDeInternet, e.target.value)} />
              </ContentInputPrice>
              {caboDeInternet && caboDeInternet[index] !== '0' ?
                (<p className="item">{caboDeInternet[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Interfone?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Interfone.length > 1 ? (<P>Interfone {index + 1}</P>) : (<P>Interfone</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, interfone, setInterfone, e.target.value)} />
              </ContentInputPrice>
              {interfone && interfone[index] !== '0' ?
                (<p className="item">{interfone[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.CameraDeSeguranca?.map((item, index) => (
          <div key={index}>
            {activity.Installations.CameraDeSeguranca.length > 1 ? (<P>Câmera de segurança {index + 1}</P>) : (<P>Câmera de segurança</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, cameraDeSeguranca, setCameraDeSeguranca, e.target.value)} />
              </ContentInputPrice>
              {cameraDeSeguranca && cameraDeSeguranca[index] !== '0' ?
                (<p className="item">{cameraDeSeguranca[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Sensores?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Sensores.length > 1 ? (<P>Sensores {index + 1}</P>) : (<P>Sensores</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, sensores, setSensores, e.target.value)} />
              </ContentInputPrice>
              {sensores && sensores[index] !== '0' ?
                (<p className="item">{sensores[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Plafon?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Plafon.length > 1 ? (<P>Plafon {index + 1}</P>) : (<P>Plafon</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, plafon, setPlafon, e.target.value)} />
              </ContentInputPrice>
              {plafon && plafon[index] !== '0' ?
                (<p className="item">{plafon[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>


      <ListContainerItensInstallations>
        {activity.Installations.PontoDeDados?.map((item, index) => (
          <div key={index}>
            {activity.Installations.PontoDeDados.length > 1 ? (<P>Ponto de dados {index + 1}</P>) : (<P>Ponto de dados</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pontoDeDados, setPontoDeDados, e.target.value)} />
              </ContentInputPrice>
              {pontoDeDados && pontoDeDados[index] !== '0' ?
                (<p className="item">{pontoDeDados[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.PontoDeTv?.map((item, index) => (
          <div key={index}>
            {activity.Installations.PontoDeTv.length > 1 ? (<P>Ponto de TV {index + 1}</P>) : (<P>Ponto de TV</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pontoDeTv, setPontoDeTv, e.target.value)} />
              </ContentInputPrice>
              {pontoDeTv && pontoDeTv[index] !== '0' ?
                (<p className="item">{pontoDeTv[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.Exaustor?.map((item, index) => (
          <div key={index}>
            {activity.Installations.Exaustor.length > 1 ? (<P>Exaustor {index + 1}</P>) : (<P>Exaustor</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, exaustor, setExaustor, e.target.value)} />
              </ContentInputPrice>
              {exaustor && exaustor[index] !== '0' ?
                (<p className="item">{exaustor[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <ListContainerItensInstallations>
        {activity.Installations.PontoDeForca?.map((item, index) => (
          <div key={index}>
            {activity.Installations.PontoDeForca.length > 1 ? (<P>Ponto de força {index + 1}</P>) : (<P>Ponto de força</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListInstallations >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pontoDeForca, setPontoDeForca, e.target.value)} />
              </ContentInputPrice>
              {pontoDeForca && pontoDeForca[index] !== '0' ?
                (<p className="item">{pontoDeForca[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListInstallations>
          </div>
        ))}

      </ListContainerItensInstallations>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default InstalacoesBudget;