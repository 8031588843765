export const Materials = {
    Materiais: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Link',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Data',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Data inicial',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Data final',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Preço unitário',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Preço total',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}