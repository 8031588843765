import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { Paint } from "../../../Models/Paint/PaintModel";
import { PaintFormType, WallpaperFormType } from "../../../Models/Paint/type";
import { Wallpaper } from "../../../Models/Paint/WallpaperModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesPaintProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Paint: PaintFormType;
  Wallpaper: WallpaperFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  paintActivities: activitiesPaintProps[];
  paintActivityName: string;
  indexPaintActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesPaintProps[]>>;
  setNewPaintActivity?: () => void;
  setPaintActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexPaintActivitySelected?: React.Dispatch<React.SetStateAction<number>>;
  editPaintActivity?: (index: number, activity: activitiesPaintProps) => void;
  deletePaintActivity?: (index: number) => void;
}

const activityContextDefault = {
  paintActivities: [],
  paintActivityName: "",
  indexPaintActivitySelected: 0,
  setNewPaintActivity: () => {},
  setPaintActivityName: () => {},
  setIndexPaintActivitySelected: () => {},
  editPaintActivity: () => {},
  deletePaintActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UsePaintActivityContext = () => useContext(ActivitiesContext);

export const PaintActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [paintActivities, setActivity] = useState<activitiesPaintProps[]>(
    activityContextDefault.paintActivities
  );

  const [indexPaintActivitySelected, setIndexPaintActivitySelected] =
    useState<number>(0);

  const [paintActivityName, setPaintActivityName] = useState<string>("Pintura");

  const editPaintActivity = (index: number, activity: activitiesPaintProps) => {
    let activitiesEdited = paintActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deletePaintActivity = (index: number) => {
    const activity = [...paintActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewPaintActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: paintActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Pintura",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Paint: Paint,
        Wallpaper: Wallpaper,

        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        paintActivities,
        paintActivityName,
        indexPaintActivitySelected,
        setActivity,
        setPaintActivityName,
        setNewPaintActivity,
        editPaintActivity,
        deletePaintActivity,
        setIndexPaintActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
