export const PassageOfWires = {
    Fio: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Tipo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Bitola do cabo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}