import React, { useEffect } from "react"

import { UseFurnitureActivityContext } from "../../../Context/ActivitiesContext/Furniture/FurnitureContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect, } from "../../stylesDomElements/htmlStyledElements";

const FurnitureController: React.FC = () => {
    const { furnitureActivities, editFurnitureActivity } = UseFurnitureActivityContext()
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Mobiliario')
    }, [])

    useEffect(() => {
        if (furnitureActivities.length) {
            var activityEdited = furnitureActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = activitySelected
            editFurnitureActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    /* useEffect(() => {
        if (furnitureActivities.length > 0 && furnitureActivities[0].activityType !== "") {
            setActivitySelected(furnitureActivities[0].activityType)
        }
        furnitureActivities.length > 0 && furnitureActivities[0].activityType === "" ? (setActivitySelected('Pintura')) : (<></>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) */

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="furnitureStep" id="furnitureStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Mobiliario">Mobiliário</option>
                <option value="Materiais para pintura">Materiais</option>

            </select>
        </ActionSelect>
    </div>
}

export default FurnitureController