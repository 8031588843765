import styled from "styled-components";
import { devices } from "../../../../style/Responsive"

export const Container = styled.div`
    height: 100vh;
    .paginationContent{
        display: grid;
        justify-content: center;

        p{
            text-align: center;
        }
    }
`

export const DowloadContent = styled.a`
    text-decoration: underline;
    color: #777777;
    margin-left: 1rem;
`

export const TopBarBudget = styled.div`
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.3rem;
    background-color: var(--primary-color);
    padding: 1rem;

    @media${devices.cell}{
        font-size: 0.75rem;
    }
    @media${devices.cells}{
        display:grid;
        justify-content: center;
    }
    @media${devices.cells2}{
        display:grid;
        justify-content: center;
    }
    .left{
        font-size: 0.85rem;
        color: var(--text-light-color);
        font-weight: 400;
        
        @media${devices.cells}{
            justify-content: center;
        }

        @media${devices.cells2}{
            justify-content: center;
        }

        p{
            margin-right: 0.75rem;
            font-size: 1.05rem;
            color: #000;
            font-weight: 500;

            @media${devices.cell}{
                font-size: 0.75rem;
            } 

            @media${devices.cells}{
                font-size: 0.75rem;

            }
        }
    }

    @media${devices.cell}{
        font-size: 0.65rem;
    }

    @media${devices.cells}{
        font-size: 0.65rem;
    }

    .left, .right{
        display: flex;
        align-items: center;
        margin: 0.75rem;
        img{
            width: 1.0rem;
        }
    }
`

export const ContentButtonTopBarBudget = styled.div`
    font-weight: 400;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border: 1px solid #E5E5E5;
    margin-left: 1rem;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;

    cursor: pointer;

    img{
        margin: 0 0.25rem 0 0.25rem;
    }
`

export const ContentElementBox = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    background-color: var(--primary-color);
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.3rem;
    P{
        font-weight: 400;
        color: #777777;
        color: var(--text-light-color);
        font-size: 0.95rem;

        @media${devices.cell}{       
            font-size: 0.75rem;   
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }

    }
    .item{
            display: flex;
            justify-content: end;
        }
`

export const PriceItem = styled.input`
    width: 100%;
    border: none;
    padding: 0.25rem;
    background-color: transparent;
    margin-left: 0.5rem;
`

export const ContentInputPrice = styled.div`
    width: 10rem;
    border: 1px solid #E1E1E1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.3rem;
    padding: 0.25rem;
    background-color: #F1F1F2;
    margin-right: 0.5rem;
    font-weight: 400;
    color: #777777;
    font-size: 0.85rem;

    @media${devices.tablet}{
        width: auto;
        margin-right: 0;
    }
    
    @media${devices.cells}{
        font-size: 0.75rem;
    }

    @media${devices.cells2}{
        font-size: 0.65rem;
        justify-content: start;
        margin-left: 0;
        width: 4.5rem;
    }

`

export const ListContainerItens = styled.div`
    margin: 2rem;
    .upContent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
        padding: 0.5rem;
        grid-gap: 0.5rem;
        .item{
            display: flex;
            justify-content: end;
            margin-right: 0.5rem;
            white-space: nowrap;
        }
        .p {
           .span{
            display: flex;
            white-space: nowrap;
           }
        }

        @media${devices.cell}{       
            font-size: 0.80rem; 
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }

        @media${devices.cells2}{
            font-size: 0.70rem;
        }

    }

    .metricColum{
        padding-right: 1.2rem;
    }

    .priceColum{
        width: 12rem;
    }
`

export const ListContainerItensPorta = styled.div`
    margin: 2rem;
    .upContent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 0.5rem;
        grid-gap: 0.5rem;
       
        .item{
            display: flex;
            justify-content: end;
            margin-right: 0.5rem;
            white-space: nowrap;
        }
        .p {
           .span{
            display: flex;
            white-space: nowrap;
           }
        }

        @media${devices.cell}{       
            font-size: 0.80rem; 
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }

        @media${devices.cells2}{
            font-size: 0.70rem;
        }

    }

    .metricColum{
        padding-right: 1.2rem;
    }

    .priceColum{
        width: 12rem;
    }
`

export const ItemContentListPorta = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--primary-color);
    border-radius: 0.3rem;
    font-weight: 400;
    color: #777777;
    grid-gap: 0.5rem;
    

    P {
        @media${devices.cell}{       
            font-size: 0.75rem;   
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }
    }

    .item{
        display: flex;
        justify-content: end;
        margin-right: 0.5rem;
        white-space: nowrap;
    }

    .flexContent{
        display:flex;
    }

    div>ul{
        padding:1rem;
    }
 
`

export const ListContainerItensJanela = styled.div`
    margin: 2rem;
    .upContent{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
        padding: 0.5rem;
        grid-gap: 0.5rem;
        .item{
            display: flex;
            justify-content: end;
            margin-right: 0.5rem;
            white-space: nowrap;
        }
        .p {
           .span{
            display: flex;
            white-space: nowrap;
           }
        }

        @media${devices.cell}{       
            font-size: 0.80rem; 
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }

        @media${devices.cells2}{
            font-size: 0.70rem;
        }

    }

    .metricColum{
        padding-right: 1.2rem;
    }

    .priceColum{
        width: 12rem;
    }
`

export const ItemContentListJanela = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--primary-color);
    border-radius: 0.3rem;
    font-weight: 400;
    color: #777777;
    grid-gap: 0.5rem;
    

    P {
        @media${devices.cell}{       
            font-size: 0.75rem;   
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }
    }

    .item{
        display: flex;
        justify-content: end;
        margin-right: 0.5rem;
        white-space: nowrap;
    }

    .flexContent{
        display:flex;
    }

    div>ul{
        padding:1rem;
    }
 
`

export const ItemContentList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--primary-color);
    border-radius: 0.3rem;
    font-weight: 400;
    color: #777777;
    grid-gap: 0.5rem;
    

    P {
        @media${devices.cell}{       
            font-size: 0.75rem;   
        }

        @media${devices.cells}{
            font-size: 0.70rem;
        }
    }

    .item{
        display: flex;
        justify-content: end;
        margin-right: 0.5rem;
        white-space: nowrap;
    }

    .flexContent{
        display:flex;
    }

    div>ul{
        padding:1rem;
    }
 
`

export const ContanierFinalization = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    margin: 2rem;
    border-radius: 0.3rem;

    @media${devices.tablet}{
        display:block;
    }

`

export const ConditionsPayment = styled.div`
    background-color: var(--primary-color);
    padding: 0.5rem;
    @media${devices.cell}{       
            font-size: 0.75rem;   
    }

    @media${devices.cells}{
        font-size: 0.65rem;
    }

    div{
        width: 100%;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
        border-radius: 0.3rem;
        padding: 0.5rem;
        margin-top: 0.2rem;
    }

    input{
        width: 100%;
        border: none;
        padding: 0.1rem;
        background-color: #F1F1F2;
    }
`

export const ItemContainerFinzalization = styled.div`
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.3rem;
   

    P {
        @media${devices.cell}{       
            font-size: 0.75rem;   
        }

        @media${devices.cells}{
            font-size: 0.65rem;
        }

    }

    .workingDays{
        display: flex;
        padding: 0.25rem;
        align-items: center;
        justify-content: space-between;
        color: #777777;
        font-size: 0.85rem;
        border: 1px solid #E1E1E1;
        border-radius: 0.3rem;
        background-color: #F1F1F2;
        font-weight: 400;
        white-space: nowrap;

        @media${devices.laptop}{
            width: auto;  
        }

        @media${devices.cells2}{
            font-size: 0.65rem;
        } 

        input[type="number"]{
            width: 6rem;
            background-color: transparent;
            border: none;
            padding: 0.25rem;
            text-align: right;
            
            @media${devices.laptop}{
                width: 4rem;
            } 

            @media${devices.tableS}{
                margin-right: 2rem;
            }
            
            @media${devices.cells2}{
                width: 2.8rem;
                font-size: 0.65rem;
            } 

        }
    }

    .selectInputContainer{
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
        border-radius: 0.3rem;
        padding: 0.5rem;

        input{
            width: 10rem;
            border: none;
            background-color: transparent;
            padding: 0.1rem;
            
            @media${devices.tablet}{
                width: 100%;
            } 
            
            @media${devices.laptop}{
                width: auto;
            } 
            
            @media${devices.cells2}{
                width: 5rem;
                font-size: 0.7rem;
            } 
        }
    }
    
    @media${devices.laptop} {
        justify-content: center;
        align-items: center;
        display: grid;
        text-align: center;
    }

    @media${devices.tablet}{
        display: grid;
        grid-template-columns: 2fr 1fr;
        margin-bottom:0.5rem;
        text-align: center;
    }

    @media${devices.tableS}{
        text-align: inherit;
    }

`

export const TotalContainer = styled.div`
    margin: 2.5rem;
    display: flex;
    align-self: center;
    justify-content: flex-end;

    font-weight: 400;
    color: var(--text-light-color);
    font-size: 0.95rem;

    p{
        color: #000;
        font-weight: 500;
        margin-left: 0.5rem;
        font-size: 1rem;
        
        @media${devices.cells}{
            font-size: 0.70rem;
        }
    }

    @media${devices.cells}{
        font-size: 0.70rem;
    }
`

export const ContainerButtonSendBudget = styled.div`
    margin: 2.2rem;
    display: flex;
    align-self: center;
    justify-content: flex-end;

    button{
        padding: 0.75rem 1.2rem 0.75rem 1.2rem;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border: none;
        border-radius: 0.3rem;
        cursor: pointer;

        @media${devices.cells}{
            font-size: 0.75rem;
        }
    }
`

export const FinalizationContainer = styled.div`
    background-color: var(--primary-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-left: 1.7rem;
    margin-right: 1.7rem;
    

    .paymentConditionContent{
        display: flex;
        align-items: center;
 }
`


export const ItemContent = styled.div`
    width: 7rem;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem;
    align-items: center;
    color: #777777;
    font-size: 0.85rem;
    border: 1px solid #E1E1E1;
    border-radius: 0.3rem;
    background-color: #F1F1F2;
    font-weight: 400;
    margin-left: 0.5rem;

    input{
        margin-left: 0.7rem;
        width: 70%;
        border: none;
        padding: 0.25rem;
        background-color: transparent;
        text-align: right;
    }

`


