import React, { useEffect, useState } from "react";
import { UseGanttContext } from "../../Context/GanttContext";
import { arrayDaysMonth } from "../../scripts/daysMonth";

import {
  Container,
  PeriodContent,
  ContentSelect,
  Select,
  ButtonClear,
  Filters,
  MonthVisible,
} from "./style";

import rightArrow from "../../assets/rightArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";

import { monthActualy, yearActualy } from "../../scripts/dateActualy";
import { trimesterName } from "../../scripts/trimesterCalculate";

const MenuGraph: React.FC = () => {
  const {
    projectPeriod,
    indexPeriod,
    filterPeriod,
    yearNumber,
    setYearNumber,
    setMonthNumber,
    setFilterPeriod,
    setDaysMonthSelected,
    setIndexPeriod,
  } = UseGanttContext();

  const [periodExibition, setPeriodExibition] =
    useState<string>("Carregando ...");
  const [indexProjectPeriod, setIndexProjectPeriod] = useState<number>(0);

  useEffect(() => {
    setIndexPeriod(0);
  }, [filterPeriod]);

  useEffect(() => {
    if (projectPeriod) {
      setIndexProjectPeriod(
        projectPeriod.findIndex(
          (period) =>
            period.indexMonth + 1 === monthActualy() &&
            period.year === yearActualy().toString()
        )
      );
    }
  }, [projectPeriod]);

  useEffect(() => {
    if (projectPeriod && filterPeriod === "month") {
      if (projectPeriod[indexProjectPeriod] && indexProjectPeriod !== -1) {
        setPeriodExibition(
          projectPeriod[indexProjectPeriod].month +
            " " +
            projectPeriod[indexProjectPeriod].year
        );
        setMonthNumber?.(projectPeriod[indexProjectPeriod].indexMonth + 1);
        setYearNumber?.(Number(projectPeriod[indexProjectPeriod].year));
      }
    } else if (filterPeriod === "cronogram") {
      setPeriodExibition(yearNumber.toString());
    }
  }, [
    indexProjectPeriod,
    projectPeriod,
    filterPeriod,
    yearNumber,
    filterPeriod,
  ]);

  useEffect(() => {
    if (filterPeriod === "trimester") {
      setPeriodExibition(trimesterName(yearNumber, indexPeriod + 1));
    } else if (filterPeriod === "semianual") {
      if (indexPeriod === 0) {
        setPeriodExibition(`Janeiro - Junho ${yearNumber}`);
      }
      if (indexPeriod === 1) {
        setPeriodExibition(`Julho - Dezembro ${yearNumber}`);
      }
    }
  }, [filterPeriod, indexPeriod]);

  const nextMonth = () => {
    if (projectPeriod?.length && filterPeriod === "month") {
      if (indexProjectPeriod + 1 < projectPeriod.length) {
        setIndexProjectPeriod(indexProjectPeriod + 1);
        setDaysMonthSelected?.(
          arrayDaysMonth(
            Number(projectPeriod[indexProjectPeriod + 1].indexMonth + 1)
          )
        );
      }
    } else if (filterPeriod === "cronogram") {
      if (
        projectPeriod?.findIndex(
          (period) => Number(period.year) === yearNumber + 1
        ) !== -1
      ) {
        setYearNumber?.(yearNumber + 1);
      }
    } else if (filterPeriod === "trimester") {
      if (indexPeriod < 3) {
        setIndexPeriod(indexPeriod + 1);
      } else if (indexPeriod === 3) {
        setIndexPeriod(0);
        setYearNumber?.(yearNumber + 1);
      }
    } else if (filterPeriod === "semianual") {
      if (indexPeriod === 0) {
        setIndexPeriod(1);
      } else if (indexPeriod === 1) {
        setIndexPeriod(0);
        setYearNumber?.(yearNumber + 1);
      }
    }
  };

  const previos = () => {
    if (filterPeriod === "month") {
      if (projectPeriod?.length && indexProjectPeriod - 1 !== -1) {
        setIndexProjectPeriod(indexProjectPeriod - 1);
        setDaysMonthSelected?.(
          arrayDaysMonth(
            Number(projectPeriod[indexProjectPeriod - 1].indexMonth + 1)
          )
        );
      }
    } else if (filterPeriod === "cronogram") {
      if (
        projectPeriod?.findIndex(
          (period) => Number(period.year) === yearNumber - 1
        ) !== -1
      ) {
        setYearNumber?.(yearNumber - 1);
      }
    } else if (filterPeriod === "trimester") {
      if (indexPeriod > 0) {
        setIndexPeriod(indexPeriod - 1);
      } else if (indexPeriod === 0) {
        setIndexPeriod(indexPeriod + 3);
        setYearNumber?.(yearNumber - 1);
      }
    } else if (filterPeriod === "semianual") {
      if (indexPeriod === 0) {
        setIndexPeriod(1);
        setYearNumber?.(yearNumber - 1);
      } else if (indexPeriod === 1) {
        setIndexPeriod(0);
        //setYearNumber?.(yearNumber - 1)
      }
    }
  };

  return (
    <Container>
      <MonthVisible width={"auto"}>
        <button>
          {projectPeriod?.[indexProjectPeriod - 1] &&
          filterPeriod === "month" ? (
            <img
              src={leftArrow}
              alt="seta esquerda"
              onClick={() => previos()}
            />
          ) : (
            <></>
          )}

          {filterPeriod === "trimester" ? (
            <img
              src={leftArrow}
              alt="seta esquerda"
              onClick={() => previos()}
            />
          ) : (
            <></>
          )}

          {filterPeriod === "semianual" ? (
            <img
              src={leftArrow}
              alt="seta esquerda"
              onClick={() => previos()}
            />
          ) : (
            <></>
          )}

          {projectPeriod?.findIndex(
            (period) => Number(period.year) === yearNumber - 1
          ) !== -1 && filterPeriod === "cronogram" ? (
            <img
              src={leftArrow}
              alt="seta esquerda"
              onClick={() => previos()}
            />
          ) : (
            <></>
          )}
        </button>
        <p>{periodExibition}</p>
        <button>
          {projectPeriod?.[indexProjectPeriod + 1] &&
          filterPeriod === "month" ? (
            <img
              src={rightArrow}
              alt="seta direita"
              onClick={() => nextMonth()}
            />
          ) : (
            <></>
          )}

          {filterPeriod === "trimester" ? (
            <img
              src={rightArrow}
              alt="seta direita"
              onClick={() => nextMonth()}
            />
          ) : (
            <></>
          )}

          {filterPeriod === "semianual" ? (
            <img
              src={rightArrow}
              alt="seta direita"
              onClick={() => nextMonth()}
            />
          ) : (
            <></>
          )}

          {projectPeriod?.findIndex(
            (period) => Number(period.year) === yearNumber + 1
          ) !== -1 && filterPeriod === "cronogram" ? (
            <img
              src={rightArrow}
              alt="seta direita"
              onClick={() => nextMonth()}
            />
          ) : (
            <></>
          )}
        </button>
      </MonthVisible>

      {filterPeriod !== "cronogram" && (
        <Filters>
          <p>Filtros:</p>

          <PeriodContent>
            Período:
            <Select
              className="periodSelect"
              value={filterPeriod}
              onChange={(e) => setFilterPeriod(e.target.value)}
            >
              <option value="month">Mensal</option>
              <option value="trimester">Trimestre</option>
              <option value="semianual">Semestre</option>
            </Select>
          </PeriodContent>

          <ContentSelect>
            Status:
            <Select>
              <option value="Status">Todos</option>
              <option value="inProgress">Em progresso</option>
              <option value="">Concluído</option>
            </Select>
          </ContentSelect>

          <ContentSelect>
            {" "}
            Etapas:
            <Select>
              <option value="">Todos</option>
            </Select>
          </ContentSelect>

          <ButtonClear>Limpar filtros</ButtonClear>
        </Filters>
      )}
    </Container>
  );
};

export default MenuGraph;
