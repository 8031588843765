import React, { useState } from "react";
import { ImpermeabilizationFormType } from "../../../../Models/Impermeabilization/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;
    Impermeabilization: ImpermeabilizationFormType;
}

const ImpermeabilizationDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            {activity.Impermeabilization.AreaSeca?.map((item, index) => (
                <div key={index}>
                    {activity.Impermeabilization.AreaSeca.length > 1 ? (<u>Área seca {index + 1}</u>) : (<u>Área seca </u>)}
                    <br /><br />
                    {item.attributes?.map((tarefa, index) => (

                        <div className="line" key={index}>
                            <p>
                                {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                            </p>
                            <p>
                                {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                            </p>
                        </div>
                    ))}
                    <hr />
                </div>

            ))}

            {activity.Impermeabilization.AreaBox?.map((item, index) => (
                <div key={index}>
                    {activity.Impermeabilization.AreaBox.length > 1 ? (<u>Área box {index + 1}</u>) : (<u>Área box </u>)}
                    <br /><br />
                    {item.attributes?.map((tarefa, index) => (

                        <div className="line" key={index}>
                            <p>
                                {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                            </p>
                            <p>
                                {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                            </p>
                        </div>
                    ))}
                    <hr />
                </div>

            ))}

        </div>
    );
};

export default ImpermeabilizationDescription;