import React, { useState, useEffect } from "react";

import { UseMarbleworkActivityContext } from "../../../../Context/ActivitiesContext/Marblework/MarbleworkContext"

import { InputActivity, ContainerCheckBox } from "../../../stylesDomElements/htmlStyledElements";
import {
  Container,
  Content,
  Select,
  FlexContainer,
  BoxContent,
  CheckBoxContainer,
  Button,
  ContainerButtom
} from "../../style";

import addIcon from "../../../../assets/addIcon.svg"

const disabledColorStyle = { color: "#77777780" }
const buttonDisabledStyle = {
  cursor: "no-drop",
  backgroundColor: "#e8e8e8",
  color: "#77777780",
  border: "1px solid #7592cc"
}

const buttonAbledStyle = {
  backgroundColor: "transparent",
  color: "var(--secondary-color)",
  border: "1px solid var(--secondary-color)",
  hover: {
    backgroundColor: "var(--secondary-color)",
    color: "var(--primary-color)"
  }
}

interface piaProps { tipoPia: string, largura: string, comprimento: string, altura?: string, espessuraAba?: string, diametroCorte?: string }
interface medidas_bancadasProps {
  braco1: {
    altura: string,
    largura: string,
  }
  braco2: {
    altura: string,
    largura: string,
  }
  braco3: {
    altura: string,
    largura: string,
  }
}

interface painelProps { tipoPainel: string, altura: string, largura: string, saiaPainel: string }

interface recortesFurosItensSuspensosProps { tipo: string, nome: string, altura: string, largura: string, comprimento: string, diametro: string }

interface frontaoProps { altura: string, largura: string }

const SinkAndCountertops: React.FC = () => {
  const { marbleworkActivities, indexMarbleworkActivitySelected, editMarbleWorkActivity } = UseMarbleworkActivityContext()

  const [formato_bancada, setFormatoBancada] = useState<string>('')
  const [disposicao_bancada, setDisposicaoBancada] = useState<string>('')
  const [possuiPias, setPossuiPias] = useState<boolean>(false)
  const [pias, setPias] = useState<piaProps[]>([{ tipoPia: 'Esculpida', largura: '', comprimento: '', altura: '', espessuraAba: '', diametroCorte: '' }])
  const [pedra, setPedra] = useState<string>('Granito')
  const [cor_pedra, setCorPedra] = useState<string>('')
  const [acabamento_pedra, setAcabamentoPedra] = useState<string>('Levigado/Satinado')
  const [medidas_bancadas, setMedidasBancadas] = useState<medidas_bancadasProps>({ braco1: { altura: '', largura: '' }, braco2: { altura: '', largura: '' }, braco3: { altura: '', largura: '' } })
  const [possuiPainel, setPossuiPainel] = useState<boolean>(false)
  const [painel, setPainel] = useState<painelProps>({ tipoPainel: 'Lateral/Virada até o chão', altura: '', largura: '', saiaPainel: 'Em ambos os lados' })
  const [possui_recortes_furos_suspensos, setPossuiRecortesFurosSuspensos] = useState<boolean>(false)
  const [recortes_furos_suspensos, setRecortesFurosSuspensos] = useState<recortesFurosItensSuspensosProps>({ tipo: 'Recortes', nome: '', altura: '', largura: '', comprimento: '', diametro: '' })
  const [tipo_saia, setTipoSaia] = useState<string>('')
  const [altura_saia, setAlturaSaia] = useState<string>('')
  const [profundidade_saia, setProfundidadeSaia] = useState<string>('')
  const [frontao, setFrontao] = useState<string>('')
  const [medidas_fundo, setFundo] = useState<frontaoProps>({ altura: '', largura: '' })
  const [medidas_lateral_1, setMedidasLateral1] = useState<frontaoProps>({ altura: '', largura: '' })
  const [medidas_lateral_2, setMedidasLateral2] = useState<frontaoProps>({ altura: '', largura: '' })
  const [pingadeira, setPingadeira] = useState<boolean>(false)
  const [rebaixo, setRebaixo] = useState<boolean>(false)


  useEffect(() => {
    if (marbleworkActivities.length > 0) {
      let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
      activityEdited.pedra = pedra;
      activityEdited.acabamento_pedra = acabamento_pedra;

      activityEdited.painel = painel
      activityEdited.recortes_furos_suspensos = recortes_furos_suspensos
      editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pias])

  useEffect(() => {
    if (marbleworkActivities.length > 0) {
      setFormatoBancada(marbleworkActivities[indexMarbleworkActivitySelected].formato_bancada)
      setDisposicaoBancada(marbleworkActivities[indexMarbleworkActivitySelected].disposicao_bancada)
      setPossuiPias(marbleworkActivities[indexMarbleworkActivitySelected]?.possuiPias)
      setPias(marbleworkActivities[indexMarbleworkActivitySelected].pias)
      setPedra(marbleworkActivities[indexMarbleworkActivitySelected].pedra)
      setAcabamentoPedra(marbleworkActivities[indexMarbleworkActivitySelected].acabamento_pedra)
      setCorPedra(marbleworkActivities[indexMarbleworkActivitySelected].cor_pedra)
      setMedidasBancadas(marbleworkActivities[indexMarbleworkActivitySelected].medidas_bancadas)
      setPossuiPainel(marbleworkActivities[indexMarbleworkActivitySelected].possuiPainel)
      setPainel(marbleworkActivities[indexMarbleworkActivitySelected].painel)
      setPossuiRecortesFurosSuspensos(marbleworkActivities[indexMarbleworkActivitySelected].possui_recortes_furos_suspensos)
      setRecortesFurosSuspensos(marbleworkActivities[indexMarbleworkActivitySelected].recortes_furos_suspensos)
      setTipoSaia(marbleworkActivities[indexMarbleworkActivitySelected].tipo_saia)
      setAlturaSaia(marbleworkActivities[indexMarbleworkActivitySelected].altura_saia)
      setProfundidadeSaia(marbleworkActivities[indexMarbleworkActivitySelected].profundidade_saia)
      setFrontao(marbleworkActivities[indexMarbleworkActivitySelected].frontao)
      setFundo(marbleworkActivities[indexMarbleworkActivitySelected].medidas_fundo)
      setMedidasLateral1(marbleworkActivities[indexMarbleworkActivitySelected].medidas_lateral_1)
      setMedidasLateral2(marbleworkActivities[indexMarbleworkActivitySelected].medidas_lateral_2)
      setPingadeira(marbleworkActivities[indexMarbleworkActivitySelected].pingadeira)
      setRebaixo(marbleworkActivities[indexMarbleworkActivitySelected].rebaixo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMarbleworkActivitySelected, marbleworkActivities])

  useEffect(() => {
    if (marbleworkActivities.length > 0 && marbleworkActivities[indexMarbleworkActivitySelected].pias.length) {
      setPias(marbleworkActivities[indexMarbleworkActivitySelected].pias)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pias])

  const editPossuiPias = () => {
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.possuiPias = !possuiPias
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editPossuiPainel = () => {
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.possuiPainel = !possuiPainel
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editPossui_recortes_furos_suspensos = () => {
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.possui_recortes_furos_suspensos = !possui_recortes_furos_suspensos
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editPingadeira = () => {
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.pingadeira = !pingadeira
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editRebaixo = () => {
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.rebaixo = !rebaixo
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  useEffect(() => {
    if (marbleworkActivities.length > 0 && marbleworkActivities[indexMarbleworkActivitySelected].medidas_bancadas) {
      setMedidasBancadas(marbleworkActivities[indexMarbleworkActivitySelected].medidas_bancadas)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medidas_bancadas.braco1])

  const addPia = () => {
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias.push({ tipoPia: '', largura: '', comprimento: '', altura: '', espessuraAba: '' })
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    setPias((prev) => [...prev, { tipoPia: '', largura: '', comprimento: '', altura: '', espessuraAba: '' }])
  }

  const removePia = (index: number) => {
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias.splice(index, 1)
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    setPias([...activityEdited.pias])
  }

  const editFormatoBancada = (value: string) => {
    setFormatoBancada(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.formato_bancada = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }
  const editDisposicaoBancada = (value: string) => {
    setDisposicaoBancada(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.disposicao_bancada = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editTipoPia = (index: number, value: string) => {
    const activitiesCopy = Array.from(pias)
    activitiesCopy.splice(index, 1, {
      tipoPia: value,
      largura: activitiesCopy[index].largura,
      comprimento: activitiesCopy[index].comprimento,
      altura: activitiesCopy[index].altura,
      diametroCorte: activitiesCopy[index].diametroCorte,
      espessuraAba: activitiesCopy[index].espessuraAba
    })
    setPias(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editLarguraPia = (index: number, value: string) => {
    const activitiesCopy = Array.from(pias)
    activitiesCopy.splice(index, 1, {
      tipoPia: activitiesCopy[index].tipoPia,
      largura: value,
      comprimento: activitiesCopy[index].comprimento,
      altura: activitiesCopy[index].altura,
      diametroCorte: activitiesCopy[index].diametroCorte,
      espessuraAba: activitiesCopy[index].espessuraAba
    })

    setPias(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editComprimentoPia = (index: number, value: string) => {
    const activitiesCopy = Array.from(pias)
    activitiesCopy.splice(index, 1, {
      tipoPia: activitiesCopy[index].tipoPia,
      largura: activitiesCopy[index].largura,
      comprimento: value,
      altura: activitiesCopy[index].altura,
      diametroCorte: activitiesCopy[index].diametroCorte,
      espessuraAba: activitiesCopy[index].espessuraAba
    })

    setPias(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editAlturaPia = (index: number, value: string) => {
    const activitiesCopy = Array.from(pias)
    activitiesCopy.splice(index, 1, {
      tipoPia: activitiesCopy[index].tipoPia,
      largura: activitiesCopy[index].largura,
      comprimento: activitiesCopy[index].comprimento,
      altura: value,
      diametroCorte: activitiesCopy[index].diametroCorte,
      espessuraAba: activitiesCopy[index].espessuraAba
    })

    setPias(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editDiametroCortePia = (index: number, value: string) => {
    const activitiesCopy = Array.from(pias)
    activitiesCopy.splice(index, 1, {
      tipoPia: activitiesCopy[index].tipoPia,
      largura: activitiesCopy[index].largura,
      comprimento: activitiesCopy[index].comprimento,
      diametroCorte: value,
      altura: activitiesCopy[index].altura,
      espessuraAba: activitiesCopy[index].espessuraAba
    })

    setPias(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editEspessuraAbaPia = (index: number, value: string) => {
    const activitiesCopy = Array.from(pias)
    activitiesCopy.splice(index, 1, {
      tipoPia: activitiesCopy[index].tipoPia,
      largura: activitiesCopy[index].largura,
      comprimento: activitiesCopy[index].comprimento,
      altura: activitiesCopy[index].altura,
      diametroCorte: activitiesCopy[index].diametroCorte,
      espessuraAba: value
    })

    setPias(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pias = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editPedra = (value: string) => {
    setPedra(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.pedra = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editCorPedra = (value: string) => {
    setCorPedra(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.cor_pedra = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editAcabamentoPedra = (value: string) => {
    setAcabamentoPedra(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.acabamento_pedra = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editBraco1 = (option: string, value: string) => {
    const activitiesCopy = medidas_bancadas
    option === "altura" ? (
      activitiesCopy.braco1.altura = value) :
      option === "largura" ? (
        activitiesCopy.braco1.largura = value) :
        (<></>)

    setMedidasBancadas(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.medidas_bancadas = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editBraco2 = (option: string, value: string) => {
    const activitiesCopy = medidas_bancadas
    option === "altura" ? (
      activitiesCopy.braco2.altura = value) :
      option === "largura" ? (
        activitiesCopy.braco2.largura = value) :
        (<></>)

    setMedidasBancadas(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.medidas_bancadas = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editBraco3 = (option: string, value: string) => {
    const activitiesCopy = medidas_bancadas
    option === "altura" ? (
      activitiesCopy.braco3.altura = value) :
      option === "largura" ? (
        activitiesCopy.braco3.largura = value) :
        (<></>)

    setMedidasBancadas(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.medidas_bancadas = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editPainel = (option: string, value: string) => {
    const activitiesCopy = painel
    option === "tipoPainel" ? (
      activitiesCopy.tipoPainel = value) :
      option === "altura" ? (
        activitiesCopy.altura = value) :
        option === "largura" ? (
          activitiesCopy.largura = value) :
          option === "saiaPainel" ? (
            activitiesCopy.saiaPainel = value) : (<></>)

    setPainel(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.painel = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editItensRecortesItensFurosSupensos = (option: string, value: string) => {
    const activitiesCopy = recortes_furos_suspensos
    option === "comprimento" ? (
      activitiesCopy.comprimento = value) :
      option === "diametro" ? (
        activitiesCopy.diametro = value) :
        option === "altura" ? (
          activitiesCopy.altura = value) :
          option === "largura" ? (
            activitiesCopy.largura = value) :
            option === "nome" ? (
              activitiesCopy.nome = value) :
              option === "tipo" ? (
                activitiesCopy.tipo = value) : (<></>)

    setRecortesFurosSuspensos(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.recortes_furos_suspensos = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editTipoSaia = (value: string) => {
    setTipoSaia(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.tipo_saia = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editTipoSaiaAltura = (value: string) => {
    setAlturaSaia(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.altura_saia = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editTipoSaiaProfundidade = (value: string) => {
    setProfundidadeSaia(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.profundidade_saia = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  const editMedidaLateral1 = (option: string, value: string) => {
    const activitiesCopy = medidas_lateral_1
    option === "altura" ? (
      activitiesCopy.altura = value) :
      option === "largura" ? (
        activitiesCopy.largura = value) : (<></>)

    setMedidasLateral1(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.medidas_lateral_1 = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editMedidaLateral2 = (option: string, value: string) => {
    const activitiesCopy = medidas_lateral_2
    option === "altura" ? (
      activitiesCopy.altura = value) :
      option === "largura" ? (
        activitiesCopy.largura = value) : (<></>)

    setMedidasLateral2(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.medidas_lateral_2 = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editMedidaFundo = (option: string, value: string) => {
    const activitiesCopy = medidas_fundo
    option === "altura" ? (
      activitiesCopy.altura = value) :
      option === "largura" ? (
        activitiesCopy.largura = value) : (<></>)

    setFundo(activitiesCopy)

    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.medidas_fundo = activitiesCopy
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editFrontao = (value: string) => {
    setFrontao(value)
    let activitiesCopy = marbleworkActivities[indexMarbleworkActivitySelected]
    activitiesCopy.frontao = value
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activitiesCopy)
  }

  return (
    <Container>
      <Content>
        {/**Formato/Disposição da bancada */}
        <FlexContainer>
          <div>
            <p>Formato da bancada</p>
            <Select value={formato_bancada} onChange={(e) => editFormatoBancada(e.target.value)}>
              <option value="Reta">Reta</option>
              <option value="Reta com chanfro">Reta com chanfro</option>
              <option value="Ovalada">Ovalada</option>
              <option value="Orgânica">Orgânica</option>
            </Select>
          </div>
          <div>
            <p>Disposição da bancada</p>
            <Select value={disposicao_bancada} onChange={(e) => editDisposicaoBancada(e.target.value)}>
              <option value="Simples">Simples</option>
              <option value="Em L">Em L</option>
              <option value="Em U">Em U</option>
            </Select>
          </div>
        </FlexContainer>

        <div>
          <CheckBoxContainer>
            <div className="buttonSelectOption">
              <ContainerCheckBox>
                <input type="checkbox" name="pia" id="pia" onClick={() => editPossuiPias()} checked={possuiPias} />
              </ContainerCheckBox>
              <input type="radio" id="pia" name="pia" value="pia" checked={possuiPias} />
              <label htmlFor="pia" onClick={() => editPossuiPias()}>
                Possui pia
              </label>
            </div>
          </CheckBoxContainer>
        </div>

        {
          pias.map((element, index) => (
            <div>
              <p className="titleSectionSeparated">Pia {index + 1}</p>
              <BoxContent>
                <FlexContainer>
                  {
                    possuiPias ?
                      (
                        <div className="selectContainerDiv">
                          <p>Tipo da pia</p>
                          <Select value={pias[index].tipoPia} onChange={(e) => editTipoPia(index, e.target.value)}>
                            <option value="Esculpida">Esculpida</option>
                            <option value="De embutir quadrada">
                              De embutir quadrada
                            </option>
                            <option value="De embutir redonda">De embutir redonda</option>
                            <option value="Aparente">Aparente</option>
                            <option value="Semi embutida">Semi embutida</option>
                          </Select>
                        </div>
                      ) :
                      (
                        <div className="selectContainerDiv">
                          <p style={disabledColorStyle}>Tipo da pia</p>
                          <Select disabled style={disabledColorStyle}>
                            <option value="Esculpida">Esculpida</option>
                            <option value="De embutit quadrada">
                              De embutit quadrada
                            </option>
                            <option value="De embutit redonda">De embutit redonda</option>
                            <option value="Aparente">Aparente</option>
                            <option value="Semi embutida">Semi embutida</option>
                          </Select>
                        </div>
                      )
                  }
                  {
                    element.tipoPia === "Esculpida" ? (
                      <div className="contentSizeInputs">
                        {
                          possuiPias ? (<div>
                            <p>Largura</p>
                            <InputActivity>
                              <input type="text" value={element.largura} onChange={(e) => editLarguraPia(index, e.target.value)} />
                              cm
                            </InputActivity>
                          </div>) : (
                            <div>
                              <p style={disabledColorStyle}>Largura</p>
                              <InputActivity>
                                <input disabled type="text" />
                                <p style={disabledColorStyle}>cm</p>
                              </InputActivity>
                            </div>
                          )
                        }
                        {
                          possuiPias ? (<div>
                            <p>Comprimento</p>
                            <InputActivity>
                              <input type="text" value={element.comprimento} onChange={(e) => editComprimentoPia(index, e.target.value)} />
                              cm
                            </InputActivity>
                          </div>) : (
                            <div>
                              <p style={disabledColorStyle}>Comprimento</p>
                              <InputActivity>
                                <input disabled type="text" />
                                <p style={disabledColorStyle}>cm</p>
                              </InputActivity>
                            </div>
                          )
                        }
                        {
                          possuiPias ? (<div>
                            <p>Altura</p>
                            <InputActivity>
                              <input type="text" value={element.altura} onChange={(e) => editAlturaPia(index, e.target.value)} />
                              cm
                            </InputActivity>
                          </div>) : (
                            <div>
                              <p style={disabledColorStyle}>Altura</p>
                              <InputActivity>
                                <input disabled type="text" />
                                <p style={disabledColorStyle}>cm</p>
                              </InputActivity>
                            </div>
                          )
                        }
                      </div>
                    ) : element.tipoPia === "De embutir quadrada" ? (
                      <div className="contentSizeInputs">
                        {
                          possuiPias ? (<div>
                            <p>Largura</p>
                            <InputActivity>
                              <input type="text" value={element.largura} onChange={(e) => editLarguraPia(index, e.target.value)} />
                              cm
                            </InputActivity>
                          </div>) : (
                            <div>
                              <p style={disabledColorStyle}>Largura</p>
                              <InputActivity>
                                <input disabled type="text" />
                                <p style={disabledColorStyle}>cm</p>
                              </InputActivity>
                            </div>
                          )
                        }
                        {
                          possuiPias ? (<div>
                            <p>Comprimento</p>
                            <InputActivity>
                              <input type="text" value={element.comprimento} onChange={(e) => editComprimentoPia(index, e.target.value)} />
                              cm
                            </InputActivity>
                          </div>) : (
                            <div>
                              <p style={disabledColorStyle}>Comprimento</p>
                              <InputActivity>
                                <input disabled type="text" />
                                <p style={disabledColorStyle}>cm</p>
                              </InputActivity>
                            </div>
                          )
                        }
                      </div>
                    ) : element.tipoPia === "De embutir redonda" ? (
                      <div className="contentSizeInputs">
                        {
                          possuiPias ? (<div>
                            <p>Diâmetro do corte</p>
                            <InputActivity>
                              <input type="text" value={element.diametroCorte} onChange={(e) => editDiametroCortePia(index, e.target.value)} />
                              cm
                            </InputActivity>
                          </div>) : (
                            <div>
                              <p style={disabledColorStyle}>Diâmetro do corte</p>
                              <InputActivity>
                                <input disabled type="text" />
                                <p style={disabledColorStyle}>cm</p>
                              </InputActivity>
                            </div>
                          )
                        }
                      </div>
                    ) :
                      (
                        <div className="contentSizeInputs">
                          {
                            possuiPias ? (<div>
                              <p>Largura</p>
                              <InputActivity>
                                <input type="text" value={element.largura} onChange={(e) => editLarguraPia(index, e.target.value)} />
                                cm
                              </InputActivity>
                            </div>) : (
                              <div>
                                <p style={disabledColorStyle}>Largura</p>
                                <InputActivity>
                                  <input disabled type="text" />
                                  <p style={disabledColorStyle}>cm</p>
                                </InputActivity>
                              </div>
                            )
                          }
                          {
                            possuiPias ? (<div>
                              <p>Comprimento</p>
                              <InputActivity>
                                <input type="text" value={element.comprimento} onChange={(e) => editComprimentoPia(index, e.target.value)} />
                                cm
                              </InputActivity>
                            </div>) : (
                              <div>
                                <p style={disabledColorStyle}>Comprimento</p>
                                <InputActivity>
                                  <input disabled type="text" />
                                  <p style={disabledColorStyle}>cm</p>
                                </InputActivity>
                              </div>
                            )
                          }
                          {
                            possuiPias ? (<div>
                              <p>Altura</p>
                              <InputActivity>
                                <input type="text" value={element.altura} onChange={(e) => editAlturaPia(index, e.target.value)} />
                                cm
                              </InputActivity>
                            </div>) : (
                              <div>
                                <p style={disabledColorStyle}>Altura</p>
                                <InputActivity>
                                  <input disabled type="text" />
                                  <p style={disabledColorStyle}>cm</p>
                                </InputActivity>
                              </div>
                            )
                          }
                          {
                            possuiPias ? (<div>
                              <p>Espessura da aba</p>
                              <InputActivity>
                                <input type="text" value={element.espessuraAba} onChange={(e) => editEspessuraAbaPia(index, e.target.value)} />
                                cm
                              </InputActivity>
                            </div>) : (
                              <div>
                                <p style={disabledColorStyle}>Espessura da aba</p>
                                <InputActivity>
                                  <input disabled type="text" />
                                  <p style={disabledColorStyle}>cm</p>
                                </InputActivity>
                              </div>
                            )
                          }
                        </div>
                      )
                  }
                  <div className="cancelButton">
                    {
                      possuiPias ? (<p onClick={() => removePia(index)} >x</p>) : (<p style={disabledColorStyle}>x</p>)
                    }
                  </div>
                </FlexContainer>
              </BoxContent>
            </div>
          ))
        }

        {possuiPias ? (
          <ContainerButtom>
            <Button style={buttonAbledStyle} onClick={() => addPia()}>
              <img src={addIcon} alt="Icone de adição" />
              Adicionar pia
            </Button>
          </ContainerButtom>) :
          (<ContainerButtom>
            <Button style={buttonDisabledStyle}>
              <img src={addIcon} alt="Icone de adição" />
              Adicionar pia
            </Button>
          </ContainerButtom>
          )}

        <FlexContainer>
          <div>
            <p>Pedra</p>
            <Select value={pedra} onChange={(e) => editPedra(e.target.value)}>
              <option value="Granito">Granito</option>
              <option value="Quartzo">Quartzo</option>
              <option value="Mármore">Mármore</option>
              <option value="Prime">Prime</option>
              <option value="Lâmina ultracompctada">
                Lâmina ultracompctada
              </option>
              <option value="Travertino">Travertino</option>
            </Select>
          </div>
          <div className="resizeContentInputs">
            <br />
            <p>Cor da pedra</p>
            <InputActivity>
              <input type="text" value={cor_pedra} onChange={(e) => editCorPedra(e.target.value)} />
              &nbsp;
            </InputActivity>
          </div>
          <div>
            <p className="textNoChashLine">Acabamento da pedra</p>
            <Select value={acabamento_pedra} onChange={(e) => editAcabamentoPedra(e.target.value)}>
              <option value="Levigado/Satinado">Levigado/Satinado</option>
              <option value="Natural">Natural</option>
              <option value="Polido">Polido</option>
            </Select>
          </div>
        </FlexContainer>

        <div>
          Medidas da bancada
          <FlexContainer>
            {disposicao_bancada === 'Simples' ? (
              <div className="containerBancada">
                <p className="titleSectionSeparated">Braço 1</p>
                <div className="contentSizeInputs containerBorder">
                  <div>
                    <p>Altura</p>
                    <InputActivity>
                      <input type="text" value={medidas_bancadas.braco1.altura} onChange={(e) => editBraco1('altura', e.target.value)} />
                      cm
                    </InputActivity>
                  </div>
                  <div>
                    <p>Largura</p>
                    <InputActivity>
                      <input type="text" value={medidas_bancadas.braco1.largura} onChange={(e) => editBraco1('largura', e.target.value)} />
                      cm
                    </InputActivity>
                  </div>
                </div>
              </div>
            ) : disposicao_bancada === "Em L" ? (
              <>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Braço 1</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco1.altura} onChange={(e) => editBraco1('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco1.largura} onChange={(e) => editBraco1('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Braço 2</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco2.altura} onChange={(e) => editBraco2('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco2.largura} onChange={(e) => editBraco2('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
              </>
            ) : disposicao_bancada === "Em U" ? (
              <>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Braço 1</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco1.altura} onChange={(e) => editBraco1('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco1.largura} onChange={(e) => editBraco1('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Braço 2</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco2.altura} onChange={(e) => editBraco2('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco2.largura} onChange={(e) => editBraco2('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Braço 3</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco3.altura} onChange={(e) => editBraco3('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_bancadas.braco3.largura} onChange={(e) => editBraco3('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
              </>
            ) : (<></>)}
          </FlexContainer>

          <div>
            <CheckBoxContainer>
              <div className="buttonSelectOption">
                <ContainerCheckBox>
                  <input type="checkbox" name="possuiPainel" id="possuiPainel" onClick={() => editPossuiPainel()} checked={possuiPainel} />
                </ContainerCheckBox>
                <input type="radio" id="possuiPainel" name="possuiPainel" value="possuiPainel" checked={possuiPainel} />
                <label htmlFor="possuiPainel" onClick={() => editPossuiPainel()}>
                  Possui painel lateral ou de fundo
                </label>
              </div>
            </CheckBoxContainer>
          </div>

          <FlexContainer>
            {
              possuiPainel ? (<div className="selectContainerDiv">
                <p>Tipo do painel</p>
                <Select value={painel.tipoPainel} onChange={(e) => editPainel('tipoPainel', e.target.value)}>
                  <option value="Lateral/Virada até o chão">Lateral/Virada até o chão</option>
                  <option value="Panel de fundo">Panel de fundo</option>
                </Select>
              </div>) :
                (<div className="selectContainerDiv">
                  <p style={disabledColorStyle}>Tipo da painel</p>
                  <Select disabled style={disabledColorStyle}>
                    <option value="Painel lateral">Painel lateral</option>
                    <option value="Virada até o chão">Virada até o chão</option>
                    <option value="Panel de fundo">Panel de fundo</option>
                  </Select>
                </div>)
            }
            <div className="contentSizeInputs">
              {
                possuiPainel ? (<div>
                  <br />
                  <p>Altura</p>
                  <InputActivity>
                    <input type="text" value={painel.altura} onChange={(e) => editPainel('altura', e.target.value)} />
                    cm
                  </InputActivity>
                </div>) : (
                  <div>
                    <br />
                    <p style={disabledColorStyle}>Altura</p>
                    <InputActivity>
                      <input disabled type="text" />
                      <p style={disabledColorStyle}>cm</p>
                    </InputActivity>
                  </div>
                )
              }
              {
                possuiPainel ? (<div>
                  <br />
                  <p>Largura</p>
                  <InputActivity>
                    <input type="text" value={painel.largura} onChange={(e) => editPainel('largura', e.target.value)} />
                    cm
                  </InputActivity>
                </div>) : (
                  <div>
                    <br />
                    <p style={disabledColorStyle}>Largura</p>
                    <InputActivity>
                      <input disabled type="text" />
                      <p style={disabledColorStyle}>cm</p>
                    </InputActivity>
                  </div>
                )
              }
            </div>
            {
              possuiPainel ? (<div className="selectContainerDiv">
                <p>Saia no painel</p>
                <Select value={painel.saiaPainel} onChange={(e) => editPainel('saiaPainel', e.target.value)}>
                  <option value="Em ambos os lados">Em ambos os lados</option>
                  <option value="Apenas em um lado">Apenas em um lado</option>
                  <option value="Não">Não</option>
                </Select>
              </div>) :
                (<div className="selectContainerDiv">
                  <p style={disabledColorStyle}>Saia no painel</p>
                  <Select disabled style={disabledColorStyle}>
                    <option value="Em ambos os lados">Em ambos os lados</option>
                    <option value="Apenas em um lado">Apenas em um lado</option>
                    <option value="Não">Não</option>
                  </Select>
                </div>)
            }
          </FlexContainer>
        </div>

        <div>
          <div>
            <CheckBoxContainer>
              <div className="buttonSelectOption">
                <ContainerCheckBox>
                  <input type="checkbox" name="possui_recortes_furos_suspensos" id="possui_recortes_furos_suspensos" onClick={() => editPossui_recortes_furos_suspensos()} checked={possui_recortes_furos_suspensos} />
                </ContainerCheckBox>
                <input type="radio" id="possui_recortes_furos_suspensos" name="possui_recortes_furos_suspensos" value="possui_recortes_furos_suspensos" checked={possui_recortes_furos_suspensos} />
                <label htmlFor="possui_recortes_furos_suspensos" onClick={() => editPossui_recortes_furos_suspensos()}>
                  Possui recortes, furos e/ou itens suspensos
                </label>
              </div>
            </CheckBoxContainer>
          </div>

          <FlexContainer>
            {
              possui_recortes_furos_suspensos ? (
                <div className="selectContainerDiv">
                  <p>Tipo</p>
                  <Select value={recortes_furos_suspensos.tipo} onChange={(e) => editItensRecortesItensFurosSupensos('tipo', e.target.value)}>
                    <option value="Recortes">Recortes</option>
                    <option value="Furos">Furos</option>
                    <option value="Itens Suspensos">Itens Suspensos</option>
                  </Select>
                </div>
              ) :
                (
                  <div className="selectContainerDiv">
                    <p style={disabledColorStyle}>Tipo</p>
                    <Select disabled style={disabledColorStyle}>
                      <option value="Recortes">Recortes</option>
                      <option value="Furos">Furos</option>
                      <option value="Itens Suspensos">Itens Suspensos</option>
                    </Select>
                  </div>
                )
            }
            <div className="contentSizeInputs">
              {
                possui_recortes_furos_suspensos ? (
                  <div>
                    <br />
                    <p>Nome</p>
                    <InputActivity>
                      <input type="text" value={recortes_furos_suspensos.nome} onChange={(e) => editItensRecortesItensFurosSupensos('nome', e.target.value)} />
                      &nbsp;
                    </InputActivity>
                  </div>) : (
                  <div>
                    <br />
                    <p style={disabledColorStyle}>Nome</p>
                    <InputActivity>
                      <input disabled type="text" />
                      &nbsp;
                    </InputActivity>
                  </div>
                )
              }
              {
                recortes_furos_suspensos.tipo === "Recortes" ? (
                  <>
                    {possui_recortes_furos_suspensos ? (
                      <div>
                        <br />
                        <p>Largura</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.largura} onChange={(e) => editItensRecortesItensFurosSupensos('largura', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    ) : (
                      <div>
                        <br />
                        <p style={disabledColorStyle}>Largura</p>
                        <InputActivity>
                          <input disabled type="text" />
                          <p style={disabledColorStyle}>cm</p>
                        </InputActivity>
                      </div>
                    )
                    }
                    {possui_recortes_furos_suspensos ? (
                      <div>
                        <br />
                        <p>Comprimento</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.comprimento} onChange={(e) => editItensRecortesItensFurosSupensos('comprimento', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    ) : (
                      <div>
                        <br />
                        <p style={disabledColorStyle}>Comprimento</p>
                        <InputActivity>
                          <input disabled type="text" />
                          <p style={disabledColorStyle}>cm</p>
                        </InputActivity>
                      </div>
                    )
                    }
                  </>
                ) : recortes_furos_suspensos.tipo === "Furos" ? (
                  <>
                    {possui_recortes_furos_suspensos ? (
                      <div>
                        <br />
                        <p>Diametro</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.diametro} onChange={(e) => editItensRecortesItensFurosSupensos('diametro', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    ) : (
                      <div>
                        <br />
                        <p>Diametro</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.diametro} onChange={(e) => editItensRecortesItensFurosSupensos('diametro', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    )}
                  </>
                ) : recortes_furos_suspensos.tipo === "Itens Suspensos" ? (
                  <>
                    {possui_recortes_furos_suspensos ? (
                      <div>
                        <br />
                        <p>Altura</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.altura} onChange={(e) => editItensRecortesItensFurosSupensos('altura', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    ) : (
                      <div>
                        <br />
                        <p style={disabledColorStyle}>Altura</p>
                        <InputActivity>
                          <input disabled type="text" />
                          <p style={disabledColorStyle}>cm</p>
                        </InputActivity>
                      </div>
                    )}
                    {possui_recortes_furos_suspensos ? (
                      <div>
                        <br />
                        <p>Largura</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.largura} onChange={(e) => editItensRecortesItensFurosSupensos('largura', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    ) : (
                      <div>
                        <br />
                        <p style={disabledColorStyle}>Largura</p>
                        <InputActivity>
                          <input disabled type="text" />
                          <p style={disabledColorStyle}>cm</p>
                        </InputActivity>
                      </div>
                    )
                    }
                    {possui_recortes_furos_suspensos ? (
                      <div>
                        <br />
                        <p>Comprimento</p>
                        <InputActivity>
                          <input type="text" value={recortes_furos_suspensos.comprimento} onChange={(e) => editItensRecortesItensFurosSupensos('comprimento', e.target.value)} />
                          cm
                        </InputActivity>
                      </div>
                    ) : (
                      <div>
                        <br />
                        <p style={disabledColorStyle}>Comprimento</p>
                        <InputActivity>
                          <input disabled type="text" />
                          <p style={disabledColorStyle}>cm</p>
                        </InputActivity>
                      </div>
                    )
                    }
                  </>
                ) : (<></>)
              }
            </div>
          </FlexContainer>
        </div>

        <div>
          <p> Acabamento de borda </p>

          <FlexContainer>
            <div className="selectContainerDiv">
              <p>Tipo de saia</p>
              <Select value={tipo_saia} onChange={(e) => editTipoSaia(e.target.value)}>
                <option value="Saia Slim">Saia Slim</option>
                <option value="Saia simples">Saia simples</option>
                <option value="Saia em L">Saia em L</option>
              </Select>
            </div>
            <div className="contentSizeInputs">
              {tipo_saia === "Saia simples" ? (
                <div>
                  <br />
                  <p>Altura</p>
                  <InputActivity>
                    <input type="text" value={altura_saia} onChange={(e) => editTipoSaiaAltura(e.target.value)} />
                    cm
                  </InputActivity>
                </div>
              ) : tipo_saia === "Saia em L" ? (
                <>
                  <div>
                    <br />
                    <p>Altura</p>
                    <InputActivity>
                      <input type="text" value={altura_saia} onChange={(e) => editTipoSaiaAltura(e.target.value)} />
                      cm
                    </InputActivity>
                  </div>
                  <div>
                    <br />
                    <p>Profundidade</p>
                    <InputActivity>
                      <input type="text" value={profundidade_saia} onChange={(e) => editTipoSaiaProfundidade(e.target.value)} />
                      cm
                    </InputActivity>
                  </div>
                </>
              ) : (<div></div>)}
            </div>
          </FlexContainer>
        </div>

        <FlexContainer>
          <div className="selectContainerDiv">
            <p>Frontão</p>
            <Select value={frontao} onChange={(e) => editFrontao(e.target.value)}>
              <option value="Nenhum">Nenhum</option>
              <option value="Somente no fundo">Somente no fundo</option>
              <option value="No fundo e 1 lateral">No fundo e 1 lateral</option>
              <option value="No fundo e 2 laterais">No fundo e 2 laterais</option>
            </Select>
          </div>
          <div></div>
        </FlexContainer>

        {
          frontao === "Somente no fundo" ? (
            <FlexContainer>
              <div className="containerBancada">
                <p className="titleSectionSeparated">Medidas do fundo</p>
                <div className="contentSizeInputs containerBorder">
                  <div>
                    <p>Altura</p>
                    <InputActivity>
                      <input type="text" value={medidas_fundo.altura} onChange={(e) => editMedidaFundo('altura', e.target.value)} />
                      cm
                    </InputActivity>
                  </div>
                  <div>
                    <p>Largura</p>
                    <InputActivity>
                      <input type="text" value={medidas_fundo.largura} onChange={(e) => editMedidaFundo('largura', e.target.value)} />
                      cm
                    </InputActivity>
                  </div>
                </div>
              </div>
              <div></div>
            </FlexContainer>
          ) :
            frontao === "No fundo e 1 lateral" ? (
              <FlexContainer>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Medidas do fundo</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_fundo.altura} onChange={(e) => editMedidaFundo('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_fundo.largura} onChange={(e) => editMedidaFundo('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Medidas lateral 1</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_lateral_1.altura} onChange={(e) => editMedidaLateral1('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_lateral_1.largura} onChange={(e) => editMedidaLateral1('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
              </FlexContainer>
            ) :
              frontao === "No fundo e 2 laterais" ? (<FlexContainer>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Medidas do fundo</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_fundo.altura} onChange={(e) => editMedidaFundo('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_fundo.largura} onChange={(e) => editMedidaFundo('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Medidas lateral 1</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_lateral_1.altura} onChange={(e) => editMedidaLateral1('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_lateral_1.largura} onChange={(e) => editMedidaLateral1('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
                <div className="containerBancada">
                  <p className="titleSectionSeparated">Medidas lateral 2</p>
                  <div className="contentSizeInputs containerBorder">
                    <div>
                      <p>Altura</p>
                      <InputActivity>
                        <input type="text" value={medidas_lateral_2.altura} onChange={(e) => editMedidaLateral2('altura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                    <div>
                      <p>Largura</p>
                      <InputActivity>
                        <input type="text" value={medidas_lateral_2.largura} onChange={(e) => editMedidaLateral2('largura', e.target.value)} />
                        cm
                      </InputActivity>
                    </div>
                  </div>
                </div>
              </FlexContainer>) : (<></>)
        }

        <div>
          <CheckBoxContainer>
            <div className="buttonSelectOption">
              <ContainerCheckBox>
                <input type="checkbox" name="pingadeira" id="pingadeira" onClick={() => editPingadeira()} checked={pingadeira} />
              </ContainerCheckBox>
              <input type="radio" id="pingadeira" name="pingadeira" value="pingadeira" checked={pingadeira} />
              <label htmlFor="pingadeira" onClick={() => editPingadeira()}>
                Pingadeira
              </label>
            </div>
          </CheckBoxContainer>
        </div>
        <div>
          <CheckBoxContainer>
            <div className="buttonSelectOption">
              <ContainerCheckBox>
                <input type="checkbox" name="rebaixo" id="rebaixo" onClick={() => editRebaixo()} checked={rebaixo} />
              </ContainerCheckBox>
              <input type="radio" id="rebaixo" name="rebaixo" value="rebaixo" checked={rebaixo} />
              <label htmlFor="rebaixo" onClick={() => editRebaixo()}>
                Rebaixo
              </label>
            </div>
          </CheckBoxContainer>
        </div>
      </Content>
      <Content>
        <div></div>
      </Content>
    </Container>
  );
};

export default SinkAndCountertops;
