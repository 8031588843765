import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import {simpleSupplier, constructor, worker} from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget } from "../../style"
import { GreenDestack } from "../../../style"

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue:boolean
    suppliers: SupplierProps[]
}

interface piaProps { tipoPia: string, largura: string, comprimento: string, altura?: string, espessuraAba?: string, diametroCorte?: string }
interface medidas_bancadasProps {
    braco1: {
        altura: string,
        largura: string,
    }
    braco2: {
        altura: string,
        largura: string,
    }
    braco3: {
        altura: string,
        largura: string,
    }
}

interface painelProps { tipoPainel: string, altura: string, largura: string, saiaPainel: string }
interface recortesFurosItensSuspensosProps { tipo: string, nome: string, altura: string, largura: string, comprimento: string, diametro: string }
interface frontaoProps { altura: string, largura: string }

interface activityProps {
    selectedBudget?: boolean,
    formato_bancada?: string,
    disposicao_bancada?: string,
    possuiPias?: boolean
    pias?: piaProps[],
    medidas_bancadas?: medidas_bancadasProps,
    possuiPainel?: boolean,
    painel?: painelProps,
    possui_recortes_furos_suspensos?: boolean,
    recortes_furos_suspensos?: recortesFurosItensSuspensosProps,
    tipo_saia?: string,
    altura_saia?: string,
    profundidade_saia?: string,
    frontao?: string,
    medidas_fundo?: frontaoProps,
    medidas_lateral_1?: frontaoProps,
    medidas_lateral_2?: frontaoProps,
    pedra?: string,
    cor_pedra?: string,
    acabamento_pedra?: string,
    pingadeira?: boolean,
    rebaixo?: boolean,
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        bancada: string,
        pias: string[],
        painelLateralFundo: string,
        recorteFurosItensSuspensos: string,
        acabamentoBorda: string,
        frontao: string
    }
}

export const PiasBancadasComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: string, activityId?: string, itemType?: any) => {
        var budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        var totalBudgetsList: number[] = []
        /**Identifica o tipo de item para calcular o menor valor total */
        itemType === "pias" ? (budgetsFilter.forEach((budget) => {
            budget.budget?.pias ? budget.budget.pias.forEach((pia) => {
                totalBudgetsList.push(parseFloat(pia))
            }) : (<></>)
        })) :
            itemType === "bancada" ? (budgetsFilter.forEach((budget) => {
                budget.budget?.bancada ? totalBudgetsList.push(parseFloat(budget.budget?.bancada)) : (<></>)
            })) : itemType === "painelLateralFundo" ? (budgetsFilter.forEach((budget) => {
                budget.budget?.painelLateralFundo ? totalBudgetsList.push(parseFloat(budget.budget?.painelLateralFundo)) : (<></>)
            })) : itemType === "recorteFurosItensSuspensos" ? (budgetsFilter.forEach((budget) => {
                budget.budget?.recorteFurosItensSuspensos ? totalBudgetsList.push(parseFloat(budget.budget?.recorteFurosItensSuspensos)) : (<></>)
            })) : itemType === "acabamentoBorda" ? (budgetsFilter.forEach((budget) => {
                budget.budget?.acabamentoBorda ? totalBudgetsList.push(parseFloat(budget.budget?.acabamentoBorda)) : (<></>)
            })) : itemType === "frontao" ? (budgetsFilter.forEach((budget) => {
                budget.budget?.frontao ? totalBudgetsList.push(parseFloat(budget.budget?.frontao)) : (<></>)
            })) : (<></>)

        var minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        })

        if(!totalItem){
            return <p>R$0,00</p>
        }

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>
    }

    return (
        <ContainerItensBudget>
            {budgets ? (
                <>
                    {activity.formato_bancada ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Bancada {activity.formato_bancada} - {activity.disposicao_bancada}</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>

                            <ItemBudget>
                                <p className="itemName">{activity.pedra} - {activity.cor_pedra} - {activity.acabamento_pedra}</p>
                                <p>
                                    <>
                                        {activity.disposicao_bancada === "Simples" ? (
                                            // eslint-disable-next-line
                                            activity.medidas_bancadas?.braco1.altura + 'cm' + ' X ' + activity.medidas_bancadas?.braco1.largura + 'cm '
                                        ) : activity.disposicao_bancada === "Em L" ? (
                                            // eslint-disable-next-line
                                            activity.medidas_bancadas?.braco1.altura + 'cm ' + ' X ' + activity.medidas_bancadas?.braco1.largura + 'cm |' +
                                            // eslint-disable-next-line
                                            activity.medidas_bancadas?.braco2.altura + 'cm ' + ' X ' + activity.medidas_bancadas?.braco2.largura + 'cm '
                                        ) : activity.disposicao_bancada === "Em U" ? (
                                            // eslint-disable-next-line
                                            activity.medidas_bancadas?.braco1.altura + 'cm ' + ' X ' + activity.medidas_bancadas?.braco1.largura + 'cm |' +
                                            // eslint-disable-next-line
                                            activity.medidas_bancadas?.braco2.altura + 'cm ' + ' X ' + activity.medidas_bancadas?.braco2.largura + 'cm |' +
                                            // eslint-disable-next-line
                                            activity.medidas_bancadas?.braco3.altura + 'cm ' + ' X ' + activity.medidas_bancadas?.braco3.largura + 'cm '
                                        ) : (<></>)}
                                    </>
                                </p>
                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.bancada ? renderItemBudget(budget.budget?.bancada, budget.activityId, 'bancada') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.bancada ? renderItemBudget(budget.budget?.bancada, budget.activityId, 'bancada') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudget>
                        </>
                    ) : (<></>)}

                    {activity.pias ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Pias</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>

                            {activity.pias.map((pia, indexPia) => (
                                <ItemBudget>
                                    <p className="itemName">{pia.tipoPia}</p>
                                    <p>
                                        <>
                                            {pia.tipoPia === "Esculpida" ? (
                                                // eslint-disable-next-line
                                                pia.largura + 'cm' + ' X ' + pia.comprimento + 'cm ' + ' X ' + pia.altura + 'cm '
                                            ) : pia.tipoPia === "De embutir quadrada" ? (
                                                // eslint-disable-next-line
                                                pia.largura + 'cm ' + ' X ' + pia.comprimento + 'cm'
                                            ) : pia.tipoPia === "De embutir redonda" ? (
                                                // eslint-disable-next-line
                                                pia.diametroCorte + 'cm '
                                            ) : pia.tipoPia === "Aparente" ? (
                                                // eslint-disable-next-line
                                                pia.largura + 'cm ' + ' X ' + pia.comprimento + 'cm' + ' X ' + pia.altura + 'cm' + ' X ' + pia.espessuraAba + 'cm'
                                            ) : (<></>)}
                                        </>
                                    </p>
                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.pias ? renderItemBudget(budget.budget?.pias[indexPia], budget.activityId, 'pias') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.pias ? renderItemBudget(budget.budget?.pias[indexPia], budget.activityId, 'pias') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudget>
                            ))}
                        </>
                    ) : (<></>)}

                    {activity.painel ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Painel lateral ou de fundo</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>

                            <ItemBudget>
                                <p className="itemName">{activity.painel.tipoPainel} - Saia no painel {activity.painel.saiaPainel}</p>
                                <p>
                                    {activity.painel.altura}cm X {activity.painel.largura}cm
                                </p>
                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.painelLateralFundo ? renderItemBudget(budget.budget?.painelLateralFundo, budget.activityId, 'painelLateralFundo') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.painelLateralFundo ? renderItemBudget(budget.budget?.painelLateralFundo, budget.activityId, 'painelLateralFundo') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudget>
                        </>
                    ) : (<></>)}

                    {activity.possui_recortes_furos_suspensos ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Recortes, furos e/ou itens suspensos</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>

                            <ItemBudget>
                                <p className="itemName">{activity.recortes_furos_suspensos?.tipo} - {activity.recortes_furos_suspensos?.nome}</p>
                                <p>
                                    <>
                                        {activity.recortes_furos_suspensos?.tipo === "Recortes" ? (
                                            // eslint-disable-next-line
                                            activity.recortes_furos_suspensos.largura + 'cm' + ' X ' + activity.recortes_furos_suspensos.comprimento + 'cm '
                                        ) : activity.recortes_furos_suspensos?.tipo === "Furos" ? (
                                            // eslint-disable-next-line
                                            activity.recortes_furos_suspensos.diametro + 'cm'
                                        ) : activity.recortes_furos_suspensos?.tipo === "Itens Suspensos" ? (
                                            // eslint-disable-next-line
                                            activity.recortes_furos_suspensos.altura + 'cm' + ' X ' + activity.recortes_furos_suspensos.largura + 'cm' + ' X ' + activity.recortes_furos_suspensos.comprimento + 'cm '
                                        ) : (<></>)}
                                    </>
                                </p>
                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.recorteFurosItensSuspensos ? renderItemBudget(budget.budget?.recorteFurosItensSuspensos, budget.activityId, 'recorteFurosItensSuspensos') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.recorteFurosItensSuspensos ? renderItemBudget(budget.budget?.recorteFurosItensSuspensos, budget.activityId, 'recorteFurosItensSuspensos') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudget>
                        </>
                    ) : (<></>)}

                    {activity.tipo_saia ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Acabamento de borda</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>

                            <ItemBudget>
                                <p className="itemName">{activity.tipo_saia}</p>
                                <p>
                                    <>
                                        {activity.tipo_saia === "Saia Slim" ? (
                                            " "
                                        ) : activity.tipo_saia === "Saia simples" ? (
                                            // eslint-disable-next-line
                                            activity.altura_saia + 'cm'
                                        ) : activity.tipo_saia === "Saia em L" ? (
                                            // eslint-disable-next-line
                                            activity.altura_saia + 'cm' + ' X ' + activity.profundidade_saia + 'cm'
                                        ) : (<></>)}
                                    </>
                                </p>
                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.acabamentoBorda ? renderItemBudget(budget.budget?.acabamentoBorda, budget.activityId, 'acabamentoBorda') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.acabamentoBorda ? renderItemBudget(budget.budget?.acabamentoBorda, budget.activityId, 'acabamentoBorda') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudget>
                        </>
                    ) : (<></>)}

                    {activity.frontao !== "Nenhum" ? (
                        <>
                            <SectionItensBudget>
                                <p className="itemSectionName">Frontão</p>
                                <p>Medidas</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                                <p>{" "}</p>
                            </SectionItensBudget>

                            <ItemBudget>
                                <div className="groupContent">
                                    <div>{activity.frontao}</div>
                                    <div>{activity.pingadeira ? ("Possui pingadeira") : ("Não possui pingadeira")}</div>
                                    <div>{activity.rebaixo ? ("Possui rebaixo") : ("Não possui rebaixo")}</div>
                                </div>
                                <div >
                                    {activity.frontao === "Somente no fundo" ? (
                                        <p>Fundo: {activity.medidas_fundo?.altura}cm X {activity.medidas_fundo?.largura}cm </p>
                                    ) : activity.frontao === "No fundo e 1 lateral" ? (
                                        <>
                                            <p>Fundo: {activity.medidas_fundo?.altura}cm X {activity.medidas_fundo?.largura}cm </p>
                                            <p>Lateral: {activity.medidas_lateral_1?.altura}cm X {activity.medidas_lateral_1?.largura}cm </p>
                                        </>
                                    ) : activity.frontao === "No fundo e 2 laterais" ? (
                                        <>
                                            <p>Fundo: {activity.medidas_fundo?.altura}cm X {activity.medidas_fundo?.largura}cm </p>
                                            <p>Lateral 1: {activity.medidas_lateral_1?.altura}cm X {activity.medidas_lateral_1?.largura}cm </p>
                                            <p>Lateral 2: {activity.medidas_lateral_2?.altura}cm X {activity.medidas_lateral_2?.largura}cm </p>
                                        </>
                                    ) : (<></>)}
                                </div>
                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.frontao ? renderItemBudget(budget.budget?.frontao, budget.activityId, 'frontao') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.frontao ? renderItemBudget(budget.budget?.frontao, budget.activityId, 'frontao') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : (<></>)}
                            </ItemBudget>
                        </>
                    ) : (<></>)}
                </>
            ) : (<></>)}
        </ContainerItensBudget>
    )
}

export default PiasBancadasComparartive