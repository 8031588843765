import React, { SetStateAction, useState } from "react"

import { InputFile, InputForm, Select, Button } from "../../../../components/stylesDomElements/htmlStyledElements"
import { Container, Content, HeaderElementItens, ContainerButton, RemoveContent, FooterButton } from "../style"

import { UseDetailmentBudgetActivityContext } from "../../Context/DetailmentBudgetActivityContext"

import { sendCocumentsOrLisNecessitiesProps } from "../../documentType"

import { editFile, editNumberDocument, editTypeDocument, removeDocument, addDocument } from "./scripts/script"

import addIcon from "../../../../assets/addIcon.svg"

const SendDocuments: React.FC<sendCocumentsOrLisNecessitiesProps> = (props) => {
    const { documentsList, setDocumentsList } = UseDetailmentBudgetActivityContext()

    return (
        <div>
            <HeaderElementItens>
                <p>Foto</p>
                <p>Tipo documento</p>
                <p className="centerItem">Número</p>
            </HeaderElementItens>

            <Container>
                {documentsList.map((element, index) => (
                    <Content key={index}>
                        <div>
                            <InputFile>
                                {element.photo[0]?.item(0)?.name ? (<p>{element.photo[0].item(0)?.name}</p>) : (<p>Faça o upload do arquivo</p>)}
                                <label key={index} htmlFor={"img" + index}>Selecione o arquivo</label>
                                <InputForm
                                    name="img"
                                    id={"img" + index}
                                    type="file"
                                    onChange={(e) => editFile(e, index, setDocumentsList, documentsList)}
                                />
                            </InputFile>
                        </div>

                        <div>
                            <Select value={element.typeDocument} onChange={(e) => editTypeDocument(e.target.value, index, setDocumentsList, documentsList)} >
                                <option selected value="RG">RG</option>
                                <option value="CNH">CNH</option>
                                <option value="Placa de veículo">Placa de veículo</option>
                            </Select>
                        </div>

                        <div className="inputElement">
                            <input type="text" value={element.numberDocument} onChange={(e) => editNumberDocument(e.target.value, index, setDocumentsList, documentsList)} />
                        </div>

                        <RemoveContent onClick={() => removeDocument(index, setDocumentsList, documentsList)}>X</RemoveContent>
                    </Content>
                ))}

                <ContainerButton>
                    <Button onClick={() => addDocument(setDocumentsList)}>
                        <img src={addIcon} alt="Icone de adição" />
                        Adicionar documento
                    </Button>
                </ContainerButton>
            </Container>

            <FooterButton>
                <Button onClick={() => props.setOptionSelected('necessitiesList')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Próximo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
            </FooterButton>
        </div>
    )
}
export default SendDocuments