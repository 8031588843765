import React, { useState, useEffect } from "react";

import { UseMasonaryActivityContext } from "../../../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { attributeTypeWithFile } from "../../../../Models/attributeType";
import { StairwaysFormType } from "../../../../Models/Masonary/type";
import { Stairways } from "../../../../Models/Masonary/StairwaysModel";
import { InputActivity, InputFile } from "./style";

import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select, UploadMessage } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const StairwaysStepForm: React.FC<stepFormProps> = (props) => {
  const { masonaryActivities, indexMasonaryActivitySelected, editMasonaryActivity, uploadArchiveProgress } = UseMasonaryActivityContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<StairwaysFormType>(Stairways)

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      let masonaryActivityCopy = masonaryActivities[indexMasonaryActivitySelected]
      masonaryActivityCopy.activityType = 'Escadas'
      editMasonaryActivity?.(indexMasonaryActivitySelected, masonaryActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected])

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      setItensFormList(masonaryActivities[indexMasonaryActivitySelected].Stairways)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected, masonaryActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemEscadas(0)
    }
  }, [])

  const AddAssignment = () => {
    if (assignment === "Escadas") {
      addEscadas({
        attributes: [
          {
            itemName: 'Tipo de escada',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Largura',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Comprimento',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Expressura',
            unit_of_measurement: 'm',
            value: ''
          },
          {
            itemName: 'Altura do pé direito',
            unit_of_measurement: 'm',
            value: ''
          },
        ],
        fileProject: [
          {
            itemName: 'Arquivo',
            link: '',
            file: []
          },
        ]
      })
    }
  }

  const addEscadas = (object: attributeTypeWithFile) => {
    var itensFormListCopy = itensFormList
    var stairwaysActivitiesCopy = masonaryActivities
    itensFormListCopy.Escadas.push(object)
    stairwaysActivitiesCopy[0].Stairways = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, stairwaysActivitiesCopy[0])
    console.log('oi')
  }

  const removeItemEscadas = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Escadas.splice(index, 1)
    masonaryActivitiesCopy[0].Stairways = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEscadasValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Escadas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEscadasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Escadas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const UploudFile = (event: React.ChangeEvent<HTMLInputElement>, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    if (event.target.files) (
      itensFormListCopy.Escadas[indexItemForm].fileProject[indexItem].file = [event.target.files]
    )
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEscadasUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Escadas[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={AddAssignment} />
      </div>
      <br />
      {itensFormList.Escadas.length ? (
        <div>
          <p>Escadas</p>
          {itensFormList.Escadas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEscadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editEscadasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editEscadasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEscadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editEscadasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editEscadasUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              {itemForm.fileProject.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Arquivo" ? (
                  <div>
                    {!uploadArchiveProgress ? (
                      <InputFile>
                        {attributeItem.file ? (<p>
                          {attributeItem.file.map((item) => (
                            item.length
                          ))} arquivos selecionados
                        </p>) : (<p>Faça uploud do arquivo</p>)}
                        <label htmlFor={`stairwaysFile`}>
                          Selecionar o arquivo
                        </label>
                        <input
                          type="file"
                          name="stairwaysFile"
                          id={`stairwaysFile`}
                          onChange={(event) => UploudFile(event, indexAttribute, indexItemForm)}
                          multiple
                        />
                      </InputFile>
                    ) : (
                      <UploadMessage>
                        Upload do arquivo em {uploadArchiveProgress}%
                      </UploadMessage>
                    )}
                  </div>
                ) : null
              ))}
              <div className="cancelX" onClick={() => removeItemEscadas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default StairwaysStepForm;
