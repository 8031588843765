import React, {
  useState,
  createContext,
  useContext,
  Provider,
  SetStateAction,
} from "react";

import { documentsProps } from "../documentType";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

interface detailmentBudgetActivityContexttType {
  documentsList: documentsProps[];
  setDocumentsList: React.Dispatch<SetStateAction<documentsProps[]>>;
  beforeInitService: string[];
  setBeforeInitService: React.Dispatch<SetStateAction<string[]>>;
  duringService: string[];
  setDuringService: React.Dispatch<SetStateAction<string[]>>;
  afterService: string[];
  setafterService: React.Dispatch<SetStateAction<string[]>>;
}

const defaultContext = {
  documentsList: [
    {
      photo: [],
      typeDocument: "",
      numberDocument: "",
    },
  ],
  setDocumentsList: () => {},
  beforeInitService: [""],
  setBeforeInitService: () => {},
  duringService: [""],
  setDuringService: () => {},
  afterService: [""],
  setafterService: () => {},
};

const DetailmentBudgetActivityContext =
  React.createContext<detailmentBudgetActivityContexttType>(defaultContext);

export const UseDetailmentBudgetActivityContext = () =>
  useContext(DetailmentBudgetActivityContext);

export const DetailmentBudgetActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [documentsList, setDocumentsList] = useState<documentsProps[]>([
    {
      photo: [],
      typeDocument: "",
      numberDocument: "",
    },
  ]);

  const [beforeInitService, setBeforeInitService] = useState<string[]>([""]);
  const [duringService, setDuringService] = useState<string[]>([""]);
  const [afterService, setafterService] = useState<string[]>([""]);

  return (
    <DetailmentBudgetActivityContext.Provider
      value={{
        documentsList,
        setDocumentsList,
        beforeInitService,
        setBeforeInitService,
        duringService,
        setDuringService,
        afterService,
        setafterService,
      }}
    >
      {children}
    </DetailmentBudgetActivityContext.Provider>
  );
};
