import React, { SetStateAction } from "react";

import { allActivitiesProps, BudgetsProps, SupplierProps, stepsShowElementsProps } from "../../../../types/typesEntitiesProject"
import { simpleSupplier } from "../../../../Config/dictionary"

import { TooltipBox, TooltipCard, TooltipText, } from "../../../../components/stylesDomElements/htmlStyledElements"

import { CotentItemStep } from "../../style"

/**Scripts */
import { budgetMinValue } from "../../scripts/budgetMinValue";
import { renderTotalActivityBudget } from "../../scripts/renderTotalActivityBudget"
import { totalStepBudgets } from "../../scripts/totalStepBudgets";

import dolarIcon from "../../../../assets/dollar-sign.svg"
interface stepProps {
    _id: string;
    clientId: string;
    name: string;
    percentage: number;
    startDate: string;
    endDate: string;
}

interface simpleSupplierProps {
    budgets: BudgetsProps[],
    suppliers: SupplierProps[],
    activities: allActivitiesProps[],
    step: stepProps,
    stepsShowElements: stepsShowElementsProps[],
    setActivitySelected: React.Dispatch<SetStateAction<allActivitiesProps | undefined>>
    setShowModal: (() => void) | any
}

const SimpleSupplier: React.FC<simpleSupplierProps> = (props) => {
    const oppenModalActivity = (activity: allActivitiesProps) => {
        props.setShowModal?.()
        props.setActivitySelected(activity)
    }

    return (
        <CotentItemStep>
            {totalStepBudgets(props.budgets, props.suppliers, props.step._id, simpleSupplier)}
            {
                props.activities.map((activity) => (
                    activity.stepId === props.step._id ? (
                        props.stepsShowElements.filter(stepShow => stepShow.stepId === props.step._id).length > 0 ? (
                            <>
                                {/**Atividade possui orçamentos de fornecedores avulsos não selecionados */}
                                {!activity.selectedBudget ? (
                                    props.budgets.filter(budgetFilter => budgetFilter.typeSupplier === simpleSupplier && budgetFilter.activityId === activity._id && !budgetFilter.selectedBudget).length > 0 ? (
                                        <div className="cursorPointerElement" onClick={() => oppenModalActivity(activity)} >
                                            <p>{renderTotalActivityBudget(props.budgets, budgetMinValue(props.budgets, activity), activity._id)}</p>
                                        </div>
                                    ) : (
                                        <div className="cursorPointerElement">
                                            <p>R$0,00</p>
                                        </div>
                                    )
                                ) : (<></>)}

                                {/**Atividade possui orçamentos de fornecedores avulsos selecionados */}
                                {activity.selectedBudget ? (
                                    <>
                                        {/*Exibe o valor do orçamento selecionado se for de um fornecedor, se não, exibe 0,00 */}
                                        {props.budgets.filter(budgetFilter => budgetFilter.activityId === activity._id && budgetFilter.selectedBudget).length > 0 ? (
                                            props.budgets.filter(budgetFilter => budgetFilter.activityId === activity._id && budgetFilter.selectedBudget).map((budget) => (
                                                budget.typeSupplier === simpleSupplier ? (
                                                    <div className="contentTotalImg cursorPointerElement" onClick={() => oppenModalActivity(activity)}>
                                                        <TooltipCard >
                                                            <TooltipText>
                                                                <img src={dolarIcon} alt="icone de dolar" />
                                                            </TooltipText>
                                                            <TooltipBox>
                                                                Este fornecedor foi selecionado, por ser o mais barato entre os que enviaram orçamentos.
                                                            </TooltipBox>
                                                        </TooltipCard>
                                                        <div className="contentTotalCenter">
                                                            {renderTotalActivityBudget(props.budgets, budget.totalBudgetPayment)}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <p className="cursorPointerElement" onClick={() => oppenModalActivity(activity)}>R${budgetMinValue(props.budgets, activity)}</p>
                                                )
                                            ))
                                        ) : (<p className="cursorPointerElement" onClick={() => oppenModalActivity(activity)}>R$0,00</p>)}

                                    </>
                                ) : (<></>)}
                            </>
                        ) : (<></>)
                    ) : ('')
                ))
            }
        </CotentItemStep>
    )
}

export default SimpleSupplier