import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";

import { UseModalContext } from "../../Context/ModalContext";
import { UseUserContext } from "../../Context/UserContext";
import { InfoArchivesProps, ProjectProps } from "../../types/typesEntitiesProject";
import {
  Container,
  LeftContainer,
  RightContainer,
  ContainerImage,
  Button,
  InputFile,
  InputForm,
  ContainerInputForm,
  ContentFiles,
  SubmitArea,
  SelectedFiles,
  ContainerListItens,
  Item,
  SelectedVideos
} from "./style";
import { UploadContentMessage } from "../Home/style";
import { ContainerModal } from "../../components/stylesDomElements/htmlStyledElements";
import { FormContainer, HeaderFormContainer } from "../Login/style";
import iconCloseModal from '../../assets/XIcon.svg'

import ArchiveItem from "./ArchiveItem";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import AcessMenu from "../../components/AcessMenu";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";


const InformationProject: React.FC = () => {
  const { acessLink, editPermission } = UseAuthAcessContext()
  const { projectSelected, typeUser, setprojectSelected } = UseUserContext();
  const [project, setProject] = useState<ProjectProps>();
  const [archives, setArchives] = useState<InfoArchivesProps[]>([]);
  const [location, setLocation] = useState();

  const { setModalNameHeader } = UseModalContext();

  const [name, setNameProject] = useState<string>();
  const [adress, setAdress] = useState<string>();
  const [adressNumber, setAdressNumber] = useState<number>();
  const [adressAditional, setAdressAditional] = useState<string>();
  const [adressCep, setAdressCep] = useState<string>();
  const [dimension, setDimension] = useState<string>();
  const [typeProject, setTypeProject] = useState<string>();
  const [typeConstruction, setTypeConstruction] = useState<string>();
  const [clienteName, setClientName] = useState<string>();
  const [responsibleName, setResponsibleName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [constructinEnbededLink, setConstructinEnbededLink] = useState<string>();
  const [marginArquitetool, setMarginArquitetool] = useState<string>('')
  const [fileInfoProject, setFileInfoProject] = useState<FileList[]>([]);
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  const [progressUploadedArchiveProject, setProgressUploadedArchiveProject] = useState<number>(0)
  const [uploadMessageArchiveProject, setUploadMessageArchiveProject] = useState<string>('Fazendo upload do(s) PDF(s) e/ou video(s) do projeto!')

  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    }
  }, []);

  useEffect(() => {
    if (editPermission === 'Editor' || editPermission === '') {
      setIsEditAuthorized(true)
    } else if (editPermission === 'Visualizacao') {
      setIsEditAuthorized(false)
    }
  }, [editPermission]);

  useEffect(() => {
    if (fileInfoProject) {
      sendUploadFileInfoProject()
    }
  }, [fileInfoProject])

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
        });
    }
  }, [projectSelected, acessLink]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/listArchiveProjectInfo/${projectSelected}`)
        .then((res) => {
          setArchives(res.data)
        });
    }
  }, [projectSelected, acessLink]);

  async function sendUploadFileInfoProject() {
    if (fileInfoProject && name) {
      setModalNameHeader('Upload de arquivos')
      setLoadingRequest(true)
      const fileInfoProjectPDF = new FormData();

      for (var i = 0; i < fileInfoProject[0].length; i++) {
        fileInfoProjectPDF.append("file", fileInfoProject[0][i])
      }

      fileInfoProject.forEach((file) => {
        fileInfoProjectPDF.append("file", file[0])
      })

      await axios.post(`${process.env.REACT_APP_BASE_URL}/newArchiveProjectInfo/${projectSelected}`,
        fileInfoProjectPDF,
        {
          onUploadProgress: (progressEvent) => {
            let progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setProgressUploadedArchiveProject(progress)
          }
        }).then((res) => {
          if (res.status === 200) {
            setUploadMessageArchiveProject('Upload dos arquivos concluído!')
            setLoadingRequest(false)
          }
        });
    }
  }


  const setFileArchiveProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      var fileInfoProjectCopy = fileInfoProject
      fileInfoProjectCopy.push(e.target.files)
      setFileInfoProject([...fileInfoProjectCopy])
    }

  }

  useEffect(() => {
    if (project) {
      setNameProject(project?.name);
      setAdress(project?.adress);
      setAdressNumber(project?.adressNumber);
      setAdressAditional(project?.adressAditional);
      setAdressCep(project?.adressCep);
      setDimension(project?.dimension);
      setTypeProject(project?.typeProject);
      setTypeConstruction(project?.typeConstruction);
      setClientName(project?.clienteName);
      setResponsibleName(project?.responsibleName);
      setDescription(project?.description);
      setMarginArquitetool(project.marginArquitetool);
      setConstructinEnbededLink(project?.constructinEnbededLink)
      setChanged(false)
    }
  }, [project]);


  useEffect(() => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${project?.adressCep}&key=${process.env.REACT_APP_API_KEY_GOOGLE}`
      )
      .then((res) => {
        if (res.data.results[0])
          setLocation(res.data.results[0].geometry.location);
        setChanged(false)
        return
      });
  }, [project?.adressCep]);

  useEffect(() => {
    setChanged(true);
    if (constructinEnbededLink === undefined || constructinEnbededLink === "") {
      setConstructinEnbededLink(project?.constructinEnbededLink)

    }
  }, [
    name,
    adress,
    adressNumber,
    adressAditional,
    adressCep,
    dimension,
    typeConstruction,
    typeProject,
    clienteName,
    responsibleName,
    description,
    constructinEnbededLink,
    fileInfoProject,
    marginArquitetool
  ]);

  const updateProjectInformations = () => {
    axios.post(
      `${process.env.REACT_APP_BASE_URL}/updateProject/${projectSelected}`,
      {
        name: name,
        adress: adress,
        adressNumber: adressNumber,
        adressAditional: adressAditional,
        adressCep: adressCep,
        dimension: dimension,
        typeProject: typeProject,
        typeConstruction: typeConstruction,
        clienteName: clienteName,
        responsibleName: responsibleName,
        description: description,
        marginArquitetool,
        constructinEnbededLink: constructinEnbededLink,
      }
    ).then((res) => {
      if (res.status === 200)
        window.location.reload()
    })
  };

  return (
    <div>
      {loadingRequest ? (
        <ContainerModal>
          <FormContainer>
            <HeaderFormContainer>
              <p></p>
              <img
                src={iconCloseModal}
                alt="icone de fechar"
                onClick={() => setLoadingRequest(false)}
              />
            </HeaderFormContainer>


            <UploadContentMessage>
              {fileInfoProject ? (
                <>
                  {uploadMessageArchiveProject}
                  <br />
                  {progressUploadedArchiveProject}%
                </>
              ) : (null)}
            </UploadContentMessage>

          </FormContainer>
        </ContainerModal>
      ) : (
        <></>
      )}

      <Header></Header>

      {!acessLink ? (
        <ItensMenu optionSelected="Infos" />
      ) : (
        <AcessMenu />
      )}

      <Container>
        <LeftContainer>
          <ContainerImage>
            <img src={project?.urlImage} alt="" />
          </ContainerImage>
          <div className="content1">
            <label htmlFor="">Nome do projeto</label>
            <input
              type="text"
              placeholder={project?.name}
              onChange={(e) => setNameProject(e.target.value)}
              disabled={!isEditAuthorized}
            />
          </div>

          <div className="content2">
            <div className="contentDefault">
              <label htmlFor="">Link ConstructIN</label>
              <input
                type="text"
                placeholder={project?.constructinEnbededLink}
                onChange={(e) => setConstructinEnbededLink(e.target.value)}
                disabled={!isEditAuthorized}
              />
            </div>

            {typeUser === "Admin" && (
              <div className="contentDefault">
                <label htmlFor="">Margem Arquitetool (%)</label>
                <input
                  type="number"
                  placeholder={project?.marginArquitetool}
                  onChange={(e) => setMarginArquitetool(e.target.value)}
                  disabled={!isEditAuthorized}
                />
              </div>
            )}
          </div>

          <div className="content2">
            <div className="contentDefault">
              <label htmlFor="">Dimensões (m²)</label>
              <input
                type="text"
                placeholder={project?.dimension}
                onChange={(e) => setDimension(e.target.value)}
                disabled={!isEditAuthorized}
              />
            </div>
            <div className="contentDefault">
              <label htmlFor="">Tipo de projeto</label>
              <select
                name=""
                id=""
                value={typeProject}
                onChange={(e) => setTypeProject(e.target.value)}
                disabled={!isEditAuthorized}
              >
                <option value="Apto. Novo">Apto. Novo</option>
                <option value="Apto. Reforma">Apto. Reforma</option>
              </select>
            </div>
            <div className="contentDefault">
              <label htmlFor="">Tipo de construção</label>
              <select
                name=""
                id=""
                value={typeConstruction}
                onChange={(e) => setTypeConstruction(e.target.value)}
                disabled={!isEditAuthorized}
              >
                <option value="Casa nova">Casa nova</option>
                <option value="Casa reforma">Casa reforma</option>
                <option value="Comercial">Comercial</option>
                <option value="Outros">Outros</option>
              </select>
            </div>
          </div>

          <div className="content3">
            <div className="contentDefault">
              <label htmlFor="">Previsão de início</label>
              <input type="text" readOnly placeholder={project?.startDate} />
            </div>
            <div className="contentDefault">
              <label htmlFor="">Previsão de encerramento</label>
              <input type="text" readOnly placeholder={project?.endDate} />
            </div>
          </div>

          <div className="content4">
            <div className="contentDefault">
              <label htmlFor="">Nome do cliente</label>
              <input
                type="text"
                placeholder={project?.clienteName}
                onChange={(e) => setClientName(e.target.value)}
                disabled={!isEditAuthorized}
              />
            </div>
            <div className="contentDefault">
              <label htmlFor="">Nome do responsável</label>
              <input
                type="text"
                placeholder={project?.responsibleName}
                onChange={(e) => setResponsibleName(e.target.value)}
                disabled={!isEditAuthorized}
              />
            </div>
          </div>
          <SelectedVideos>
            Videos da visita
            <ContainerListItens>
              {archives.map((item) => (
                item.infoArchives.map((archive, index) => (
                  archive.type === 'video/mp4' ? (
                    <Item>
                      <ArchiveItem
                        key={index}
                        _id={item._id}
                        index={index}
                        projectId={item.projectId}
                        archiveUrl={archive.archiveUrl}
                        archiveName={archive.archiveName}
                        size={archive.size}
                        type={archive.type}
                        modification={archive.modification}
                      />
                    </Item>
                  ) : (null)
                ))
              ))}
            </ContainerListItens>
          </SelectedVideos>
        </LeftContainer>
        <RightContainer>
          <ContainerImage>
            {/* <LoadScript googleMapsApiKey={`${process.env.REACT_APP_API_KEY_GOOGLE}`}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={16}
              >
              </GoogleMap>
            </LoadScript> */}
          </ContainerImage>

          <div className="content1">
            <label htmlFor="">Endereço</label>
            <input
              type="text"
              placeholder={project?.adress}
              onChange={(e) => setAdress(e.target.value)}
              disabled={!isEditAuthorized}
            />
          </div>

          <div className="content2">
            <div className="contentDefault">
              <label htmlFor="">Número</label>
              {project?.adressNumber != undefined ? (
                <input
                  type="text"
                  placeholder={project?.adressNumber.toString()}
                  onChange={(e) => setAdressNumber(parseInt(e.target.value))}
                />
              ) : (
                <input
                  type="text"
                  onChange={(e) => setAdressNumber(parseInt(e.target.value))}
                />
              )}
            </div>
            <div className="contentDefault">
              <label htmlFor="">Complemento</label>
              <input
                type="text"
                placeholder={project?.adressAditional}
                onChange={(e) => setAdressAditional(e.target.value)}
                disabled={!isEditAuthorized}
              />
            </div>
            <div className="contentDefault">
              <label htmlFor="">Cep</label>
              <input
                type="text"
                placeholder={project?.adressCep}
                onChange={(e) => setAdressCep(e.target.value)}
                disabled={!isEditAuthorized}
              />
            </div>
          </div>

          <div className="content3">
            <label htmlFor="">Descrição</label>
            <textarea
              name=""
              id=""
              placeholder={project?.description}
              cols={30}
              rows={10}
              onChange={(e) => setDescription(e.target.value)}
              disabled={!isEditAuthorized}
            ></textarea>
          </div>
          <ContentFiles>
            <ContainerInputForm>
              <label htmlFor="">Arquivos do projeto</label>
              <InputFile>
                <p>Faça o upload do arquivo</p>
                <label htmlFor="pdf">Selecione</label>
                <InputForm
                  name="pdf"
                  id="pdf"
                  type="file"
                  accept="application/pdf,video/*"
                  multiple
                  required
                  onChange={(event) => {
                    setFileArchiveProject(event)
                  }}
                />
              </InputFile>

            </ContainerInputForm>
            <ContainerInputForm>
              <label htmlFor="">Videos da visita</label>
              <InputFile>
                <p>Faça o upload do video</p>
                <label htmlFor="pdf">Selecione</label>
                <InputForm
                  name="pdf"
                  id="pdf"
                  type="file"
                  accept="application/pdf,video/*"
                  multiple
                  required
                  onChange={(event) => {
                    setFileArchiveProject(event)
                  }}
                />
              </InputFile>

            </ContainerInputForm>


          </ContentFiles>

          <SelectedFiles>
            Arquivos do projeto
            <ContainerListItens>
              {archives.map((item) => (
                item.infoArchives.map((archive, index) => (
                  archive.type === 'application/pdf' ? (
                    <Item>
                      <ArchiveItem
                        key={index}
                        _id={item._id}
                        index={index}
                        projectId={item.projectId}
                        archiveUrl={archive.archiveUrl}
                        archiveName={archive.archiveName}
                        size={archive.size}
                        type={archive.type}
                        modification={archive.modification}
                      />
                    </Item>
                  ) : (null)
                ))
              ))}
            </ContainerListItens>
          </SelectedFiles>
        </RightContainer>

      </Container>

      {changed && (
        <SubmitArea>
          <div className="buttons">
            <Button
              onClick={() => setChanged(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => updateProjectInformations()}
            >
              Salvar alterações
            </Button>
          </div>
        </SubmitArea>
      )}
    </div>
  );
};

export default InformationProject;
