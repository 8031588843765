import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../style"
import { GreenDestack } from "../../style"
import { DoorsAndWindowsFormType } from "../../../../Models/DoorsAndWindows/type";


interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface attributeType {
    attributes: {
        itemName: string,
        unit_of_measurement: string,
        value: string
    }[]
}

interface activityProps {
    selectedBudget?: boolean,
    DoorsAndWindows?: DoorsAndWindowsFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        Porta?: string[]
        Janela?: string[]
    }
}

export const DoorsAndWindowsComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: any, activityId?: string, itemType?: any) => {
        const budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        const totalBudgetsList: number[] = []

        if (!totalItem) {
            return <p>R$0,00</p>
        }

        const minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        }, 1000000000)

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>

    }

    return (
        <ContainerItensBudget>
            <>
                {activity.DoorsAndWindows?.Porta ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Porta</p>
                            <p>{" "}</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.DoorsAndWindows?.Porta?.map((item, index) => (
                            <div key={index}>
                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 3).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Porta ? renderItemBudget(budget.budget?.Porta[index], budget.activityId, 'Porta') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Porta ? renderItemBudget(budget.budget?.Porta[index], budget.activityId, 'Porta') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>
                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.DoorsAndWindows?.Janela ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Janela</p>
                            <p>{" "}</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.DoorsAndWindows?.Janela?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 3).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Janela ? renderItemBudget(budget.budget?.Janela[index], budget.activityId, 'Janela') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Janela ? renderItemBudget(budget.budget?.Janela[index], budget.activityId, 'Janela') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
            </>
        </ContainerItensBudget>
    )
}

export default DoorsAndWindowsComparartive