import React, { useEffect, useState } from "react";
import axios from "axios";

import { UseModalContext } from "../../Context/ModalContext";
import { UseUserContext } from "../../Context/UserContext";

import {
  Select,
  InputFile,
  Button,
  FooterFormContainer,
  TopBar,
  ContainerInput,
  Input,
} from "../../components/stylesDomElements/htmlStyledElements";

import {
  FilterContainer,
  ButtonNewProject,
  ProjectsContainer,
  Form,
  ModalWarnNoFileProject,
  BoxMessage,
  ButtonActionModalNoFileProject,
  FormProjectData,
  ContainerInputFormProjectData,
  ContainerInputForm,
  OptionTypeProjectForm,
  ContainerListItens,
  Item,
  InputForm,
  SelectedSteps,
  FileTitle,
  UploadContentMessage,
  ConfirmationNewProject,
  Container,
  MenuActionsPage,
} from "./style";

import Header from "../../components/Header";
import CardProject from "../../components/CardProject";
import Modal from "../../components/Modal";
import IconSearch from "../../assets/searchIcon.svg";
import AddIcon from "../../assets/addIcon.svg";
import warnIcon from "../../assets/warnIcon.svg";

interface ProjectProps {
  _id: string;
  name: string;
  adress: string;
  status: string;
  progress: number;
  urlImage: string;
  urlArchiveProject: string;
  urlInfoArchiveProject: string;
  urlVideoProject?: string;
  clienteName?: string;
}

const ClientPanel: React.FC = () => {
  const { userId, token, typeUser } = UseUserContext();
  const { setShowModal, setModalNameHeader } = UseModalContext();

  const [projects, setProjects] = useState<ProjectProps[]>([]);
  const [filterProjects, setFilterProjects] = useState<string>("");

  const [modalWarnNoFileProject, setModalWarnNoFileProject] =
    useState<boolean>(false);

  const [step, setStep] = useState<number>(1);
  const [checked, setChecked] = useState<boolean>(true);

  const [closeButtonModal, setCloseButtonModal] = useState<boolean>(false);

  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [progressUploadedArchiveProject, setProgressUploadedArchiveProject] =
    useState<number>(0);
  const [uploadMessageArchiveProject, setUploadMessageArchiveProject] =
    useState<string>("Fazendo upload do PDF do projeto!");

  const [progressUploadedImageProject, setProgressUploadedImageProject] =
    useState<number>(0);
  const [uploadMessageImageProject, setUploadMessageImageProject] =
    useState<string>("Fazendo upload da capa do projeto!");

  const [name, setNameProject] = useState<string>();
  const [fileProjetc, setFileProjetc] = useState<FileList>();
  const [image, setImage] = useState<FileList>();
  const [adress, setAdress] = useState<string>("");
  const [adressNumber, setAdressNumber] = useState<number>();
  const [adressAditional, setAdressAditional] = useState<string>("");
  const [adressCep, setAdressCep] = useState<string>("");
  const [dimension, setDimension] = useState<string>();
  const [typeProject, setTypeProject] = useState<string>();
  const [typeConstruction, setTypeConstruction] = useState<string>();
  const [projectSize, setProjectSize] = useState<string>();
  const [projectPeriod, setProjectPeriod] = useState<string>();
  const [projectFollowUp, setProjectFollowUp] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [clienteName, setClientName] = useState<string>();
  const [responsibleName, setResponsibleName] = useState<string>();
  const [confirmationNewProject, setConfirmationNewProject] =
    useState<boolean>();

  useEffect(() => {
    if (userId) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/projects/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setProjects(res.data);
        });
    }
  }, [token, userId]);

  async function sendUploadFileProject() {
    if (fileProjetc && name) {
      setModalNameHeader("Upload de arquivos");
      setCloseButtonModal(true);
      setLoadingRequest(true);
      const fileProjectPDF = new FormData();

      fileProjectPDF.append("architectId", userId);
      fileProjectPDF.append("projectName", name);

      for (var i = 0; i < fileProjetc?.length; i++) {
        fileProjectPDF.append("file", fileProjetc[i]);
      }

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/uploadArchiveProject`,
          fileProjectPDF,
          {
            onUploadProgress: (progressEvent) => {
              let progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgressUploadedArchiveProject(progress);
            },
          }
        )
        .then((res) => {
          setUploadMessageArchiveProject("Aguarde o processamento...");
          if (res.status === 200) {
            setUploadMessageArchiveProject("Upload do PDF concluído!");
            window.location.reload();
          }
        });
    }
  }

  async function sendUploadImageProject() {
    if (image && name) {
      const imageArchive = new FormData();
      imageArchive.append("file", image[0]);
      imageArchive.append("architectId", userId);
      imageArchive.append("projectName", name);

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/newImageProject`,
          imageArchive,
          {
            onUploadProgress: (progressEvent) => {
              let progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgressUploadedImageProject(progress);
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setUploadMessageImageProject(
              "Upload da capa do projeto concluído!"
            );
          }
        });
    }
  }

  const submit = () => {
    if (
      name !== undefined &&
      fileProjetc !== undefined &&
      adress !== undefined &&
      dimension !== undefined &&
      typeProject !== undefined &&
      typeConstruction !== undefined &&
      projectSize !== undefined &&
      projectPeriod !== undefined &&
      projectFollowUp !== undefined &&
      startDate !== undefined &&
      endDate !== undefined &&
      clienteName !== undefined &&
      responsibleName !== undefined
    ) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/newProject`, {
          architectId: userId,
          name,
          urlImage:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKytDZEvtbV-JAOMiLkowWIsDBlcc0obbgqZPOJWQtJX4WfnrSEw9I0iyCJd7DEd5peRo&usqp=CAU",
          urlArchiveProject: fileProjetc,
          constructinEnbededLink: "",
          adress,
          adressNumber,
          adressAditional,
          adressCep,
          dimension,
          typeProject,
          typeConstruction,
          startDate,
          endDate,
          clienteName,
          responsibleName,
          client: clienteName,
          status: "Em andamento",
          progress: 0,
          description: "",
          marginArquitetool: "",
        })
        .then((res) => {
          if (res.status === 200) {
            setStep(0);
            sendUploadFileProject();
            if (image) {
              sendUploadImageProject();
            }
          }
        });
    } else {
      alert("Conclua o preenchimento do cadastro!");
    }
  };

  const closeModalWarnNoFileProject = () => {
    setModalWarnNoFileProject(false);
    setShowModal?.();
  };

  const projectsRender = () => {
    var projectsListed = projects.filter(
      (project) =>
        project.name.includes(filterProjects) ||
        project.adress.includes(filterProjects) ||
        project.status.includes(filterProjects) ||
        filterProjects === "" ||
        filterProjects === " "
    );

    if (projectsListed.length > 0) {
      return projectsListed.map((project, index) => (
        <CardProject
          _id={project._id}
          imgProject={project.urlImage}
          projectName={project.name}
          projectAdress={project.adress}
          status={project.status}
          progress={project.progress}
          client={project.clienteName}
          key={index}
        />
      ));
    } else {
      return (
        <div className="NoregisterIdentifield">
          <p>Não há registros disponíveis!</p>
        </div>
      );
    }
  };

  const setFileArchiveProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileProjetc(e.target.files);
    }
  };

  const setFileImageProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && e.target.files.item.name ? (
      setImage(e.target.files)
    ) : (
      <></>
    );
  };

  const selectTypeProjectRegister = (typeRegister: number) => {
    if (typeRegister !== step) {
      setStep(typeRegister);
      setChecked(!checked);
    }
  };

  const renderFilesUploadPDF = () => {
    var filesRender = [];
    if (fileProjetc) {
      for (var i = 0; i < fileProjetc?.length; i++) {
        filesRender.push(fileProjetc[i].name);
      }
      return (
        <>
          {filesRender.map((file, index) => (
            <div key={index}>
              <Item>
                <FileTitle> {file}</FileTitle>
              </Item>
            </div>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {projects ? (
        <Container>
          {confirmationNewProject ? (
            <Modal
              titleForm="Novo projeto"
              removeCloseButton={closeButtonModal}
            >
              {step !== 0 ? (
                <OptionTypeProjectForm>
                  <div>
                    <input
                      type="radio"
                      id="newRegister"
                      name="typeRegister"
                      value=""
                      checked={checked}
                      onClick={() => selectTypeProjectRegister(1)}
                    />
                    <label htmlFor="newRegister">
                      <div>Dados Cadastrais</div>
                    </label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="dataPayment"
                      name="typeRegister"
                      value=""
                      checked={!checked}
                      onClick={() => selectTypeProjectRegister(2)}
                    />
                    <label htmlFor="dataPayment">
                      {" "}
                      <div>Dados de Projeto</div>
                    </label>
                  </div>
                </OptionTypeProjectForm>
              ) : (
                <></>
              )}

              {step === 1 ? (
                <Form>
                  <div className="grid1">
                    <ContainerInputForm>
                      <label htmlFor="name">Nome do projeto</label>
                      <InputForm
                        id="name"
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setNameProject(e.target.value)}
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="">Arquivos do projeto (PDF)</label>
                      <InputFile>
                        {fileProjetc ? (
                          <p>{fileProjetc.length} - Arquivo(s) selecionados</p>
                        ) : (
                          <p>Faça o upload do arquivo</p>
                        )}
                        <label htmlFor="pdf">Selecione o Arquivo</label>
                        <InputForm
                          name="pdf"
                          id="pdf"
                          type="file"
                          accept="*"
                          multiple
                          required
                          onChange={(event) => {
                            setFileArchiveProject(event);
                          }}
                        />
                      </InputFile>
                    </ContainerInputForm>
                  </div>

                  <SelectedSteps>
                    {fileProjetc && (
                      <ContainerListItens>
                        {renderFilesUploadPDF()}
                      </ContainerListItens>
                    )}
                  </SelectedSteps>

                  <div className="grid3">
                    <ContainerInputForm>
                      <label htmlFor="">Imagem de capa (JPG ou PNG)</label>
                      <InputFile>
                        {image?.item(0)?.name ? (
                          <p>{image.item(0)?.name}</p>
                        ) : (
                          <p>Faça o upload do arquivo</p>
                        )}
                        <label htmlFor="img">Selecione o arquivo</label>
                        <InputForm
                          name="img"
                          id="img"
                          type="file"
                          accept="image/jpeg, image/png, image/gif, image/bmp, image/tiff"
                          onChange={(event) => {
                            setFileImageProject(event);
                          }}
                        />
                      </InputFile>
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="dimension">Dimensões (m²)</label>
                      <InputForm
                        id="dimension"
                        type="text"
                        value={dimension}
                        required
                        onChange={(e) => setDimension(e.target.value)}
                      />
                    </ContainerInputForm>

                    <></>
                  </div>

                  <div className="grid4">
                    <ContainerInputForm>
                      <label htmlFor="cep">Cep</label>
                      <InputForm
                        id="cep"
                        type="text"
                        value={adressCep}
                        required
                        onChange={(e) => setAdressCep(e.target.value)}
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="adress">Endereço da obra</label>
                      <InputForm
                        id="adress"
                        type="text"
                        required
                        value={adress}
                        onChange={(e) => setAdress(e.target.value)}
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="number">Numero</label>
                      <InputForm
                        id="number"
                        type="number"
                        value={adressNumber}
                        required
                        onChange={(e) =>
                          setAdressNumber(parseInt(e.target.value))
                        }
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="aditionalAdress">Complemento</label>
                      <InputForm
                        id="aditionalAdress"
                        type="text"
                        value={adressAditional}
                        onChange={(e) => setAdressAditional(e.target.value)}
                      />
                    </ContainerInputForm>

                    <></>
                  </div>

                  <div className="grid5">
                    <ContainerInputForm>
                      <label htmlFor="initProject">Previsão de início</label>
                      <InputForm
                        id="initProject"
                        type="date"
                        value={startDate}
                        required
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="findProject">
                        Previsão de encerramento
                      </label>
                      <InputForm
                        id="findProject"
                        type="date"
                        value={endDate}
                        required
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="clientName">Nome do cliente</label>
                      <InputForm
                        id="clientName"
                        type="text"
                        value={clienteName}
                        required
                        onChange={(e) => setClientName(e.target.value)}
                      />
                    </ContainerInputForm>
                    <ContainerInputForm>
                      <label htmlFor="responsibleName">
                        Nome do responsável
                      </label>
                      <InputForm
                        id="responsibleName"
                        type="text"
                        value={responsibleName}
                        required
                        onChange={(e) => setResponsibleName(e.target.value)}
                      />
                    </ContainerInputForm>
                  </div>

                  <FooterFormContainer>
                    <div></div>
                    <Button onClick={() => selectTypeProjectRegister(2)}>
                      Próximo
                    </Button>
                  </FooterFormContainer>
                </Form>
              ) : step === 2 ? (
                <FormProjectData>
                  <div className="grid1">
                    <ContainerInputFormProjectData>
                      <label htmlFor="typeProjet">Tipo de projeto</label>
                      <select
                        id="typeProjet"
                        value={typeProject}
                        required
                        onChange={(e) => setTypeProject(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="Escritorio">Escritório</option>
                        <option value="Loja">Loja</option>
                        <option value="Area comum">Área comum</option>
                        <option value="Casa Residencial">
                          Casa Residencial
                        </option>
                        <option value="Apartamento">Apartamento</option>
                        <option value="Outros">Outros</option>
                      </select>
                    </ContainerInputFormProjectData>
                    <ContainerInputFormProjectData>
                      <label htmlFor="typeConstruction">
                        Tipo de construção
                      </label>
                      <select
                        id="typeConstruction"
                        value={typeConstruction}
                        required
                        onChange={(e) => setTypeConstruction(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="Obra do zero">Obra do zero</option>
                        <option value="Reforma leve">Reforma leve</option>
                        <option value="Reforma pesada">Reforma pesada</option>
                        <option value="Outros">Outros</option>
                      </select>
                    </ContainerInputFormProjectData>
                    <ContainerInputFormProjectData>
                      <label htmlFor="projectSize">Tamanho de obra</label>
                      <select
                        id="projectSize"
                        value={projectSize}
                        required
                        onChange={(e) => setProjectSize(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="Pequena">Pequena</option>
                        <option value="Média">Média</option>
                        <option value="Grande">Grande</option>
                      </select>
                    </ContainerInputFormProjectData>
                    <ContainerInputFormProjectData>
                      <label htmlFor="projectFollowUp">
                        Como a obra será acompanhada ?
                      </label>
                      <select
                        id="projectFollowUp"
                        value={projectFollowUp}
                        required
                        onChange={(e) => setProjectFollowUp(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="Por mim">Por mim</option>
                        <option value="Selecionar na plataforma">
                          Selecionar na plataforma
                        </option>
                        <option value="Indicar">Indicar</option>
                        <option value="Pelo cliente">Pelo cliente</option>
                      </select>
                    </ContainerInputFormProjectData>
                  </div>

                  <div className="grid2">
                    <ContainerInputFormProjectData>
                      <label htmlFor="projectPeriod">Período da obra</label>
                      <select
                        id="projectPeriod"
                        value={projectPeriod}
                        required
                        onChange={(e) => setProjectPeriod(e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="Diurno">Diurno</option>
                        <option value="Noturno">Noturno</option>
                        <option value="Dia de semana - diurno/noturno">
                          Dia de semana - diurno/noturno
                        </option>
                        <option value="Dia de semana + fins de semana - diurno/noturno">
                          Dia de semana + fins de semana - diurno/noturno
                        </option>
                      </select>
                    </ContainerInputFormProjectData>
                  </div>
                  <FooterFormContainer>
                    <div></div>
                    <Button onClick={() => submit()}>
                      Enviar para aprovação
                    </Button>
                  </FooterFormContainer>
                </FormProjectData>
              ) : (
                <></>
              )}

              {loadingRequest ? (
                <UploadContentMessage>
                  {uploadMessageArchiveProject}
                  <br />
                  {progressUploadedArchiveProject}%
                  {image ? (
                    <>
                      <br />
                      <br />
                      {uploadMessageImageProject}
                      <br />
                      {progressUploadedImageProject}%
                    </>
                  ) : (
                    <></>
                  )}
                </UploadContentMessage>
              ) : (
                <></>
              )}
            </Modal>
          ) : (
            <Modal
              titleForm="Novo projeto"
              removeCloseButton={closeButtonModal}
            >
              <ConfirmationNewProject>
                <p>
                  Crie seu projeto novo e submeta para ser analisado pelo nosso
                  time!
                  <br></br>
                  Entraremos em contato por seu contato de preferência em até 1
                  dia útil.
                </p>
                <Button onClick={() => setConfirmationNewProject(true)}>
                  Criar projeto
                </Button>
              </ConfirmationNewProject>
            </Modal>
          )}

          {modalWarnNoFileProject ? (
            <ModalWarnNoFileProject>
              <BoxMessage>
                <img src={warnIcon} alt="Icone de Alerta" />
                <p>Você não selecionou nenhum arquivo de projeto.</p>
                <p className="confirmActionMessage">
                  Deseja iniciar o seu projeto do zero?
                </p>

                <div className="buttons">
                  <ButtonActionModalNoFileProject
                    borderColor="#FFB800"
                    backgroundColor="#FFB800"
                    onClick={() => closeModalWarnNoFileProject()}
                  >
                    Não, cancelar
                  </ButtonActionModalNoFileProject>
                  <ButtonActionModalNoFileProject
                    borderColor="#FFB800"
                    backgroundColor="#fff"
                  >
                    Sim, iniciar
                  </ButtonActionModalNoFileProject>
                </div>
              </BoxMessage>
            </ModalWarnNoFileProject>
          ) : null}

          <Header nameHeader="Meus projetos" />

          <TopBar>
            <MenuActionsPage>
              <div className="leftContent">
                <ContainerInput>
                  <img src={IconSearch} alt="Icone Lupa" />
                  <Input
                    placeholder="Busca..."
                    onChange={(e) => setFilterProjects(e.target.value)}
                  />
                </ContainerInput>

                <FilterContainer>
                  <p>Exibir:</p>
                  <Select>
                    <option value="all projects">Todos os projetos</option>
                    <option value="ativos">Ativos</option>
                    <option value="arquivados">Arquivados</option>
                  </Select>
                </FilterContainer>
              </div>
              <ButtonNewProject onClick={() => setShowModal?.()}>
                <img src={AddIcon} alt="Icone adicionar" />
                Novo Projeto
              </ButtonNewProject>
            </MenuActionsPage>
          </TopBar>

          <ProjectsContainer>{projectsRender()}</ProjectsContainer>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default ClientPanel;
