import React, { useEffect, useState } from "react";

import {UseMarbleworkActivityContext} from "../../../../Context/ActivitiesContext/Marblework/MarbleworkContext"

import { InputActivity, ContainerCheckBox } from "../../../stylesDomElements/htmlStyledElements";
import { Container, Content, Select, FlexContainer, CheckBoxContainer } from "../../style";

const disabledColorStyle = {color:"#77777780"}
const cursorBlocked = {cursor:"no-drop", color:"#77777780"}

const Coating: React.FC = () => {
  const {marbleworkActivities, editMarbleWorkActivity, indexMarbleworkActivitySelected}=UseMarbleworkActivityContext()
  
  const [dimensaoPedrasNaoAplicavel, setDimensaoPedrasNaoAplicavel]=useState<boolean>(false)

  const [item_revestido, setItemRevestido]=useState<string>('')
  const [espessura_chapa, setEspessuraChapa]=useState<string>('')
  const [altura_pedras, setAlturaPedra]=useState<string>('')
  const [largura_pedras, setLarguraPedra]=useState<string>('')
  const [pedra, setPedra]=useState<string>('Granito')
  const [cor_pedra, setCorPedra]=useState<string>('')
  const [acabamento_pedra, setAcabamentoPedra]=useState<string>('Natural')
  const [altura_item_revestido, setAlturaItemRevestido]=useState<string>('')
  const [largura_item_revestido, setLarguraItemRevestido]=useState<string>('')

  useEffect(()=>{
    if(marbleworkActivities.length>0){
      let activityEdited = marbleworkActivities[0];
      activityEdited.acabamento_pedra = acabamento_pedra;
      editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    if(marbleworkActivities.length>0){
      setItemRevestido(marbleworkActivities[indexMarbleworkActivitySelected].item_revestido)
      setEspessuraChapa(marbleworkActivities[indexMarbleworkActivitySelected].espessura_chapa)
      setAlturaPedra(marbleworkActivities[indexMarbleworkActivitySelected].altura_pedras)
      setLarguraPedra(marbleworkActivities[indexMarbleworkActivitySelected].largura_pedras)
      setPedra(marbleworkActivities[indexMarbleworkActivitySelected].pedra)
      setCorPedra(marbleworkActivities[indexMarbleworkActivitySelected].cor_pedra)
      setAcabamentoPedra(marbleworkActivities[indexMarbleworkActivitySelected].acabamento_pedra)
      setAlturaItemRevestido(marbleworkActivities[indexMarbleworkActivitySelected].altura_item_revestido)
      setLarguraItemRevestido(marbleworkActivities[indexMarbleworkActivitySelected].largura_item_revestido)
      setDimensaoPedrasNaoAplicavel(marbleworkActivities[indexMarbleworkActivitySelected].dimensaoPedrasNaoAplicavel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[indexMarbleworkActivitySelected,marbleworkActivities])

  useEffect(()=>{
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.dimensaoPedrasNaoAplicavel = dimensaoPedrasNaoAplicavel;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dimensaoPedrasNaoAplicavel])

  const editItemRevestido=(value:string)=>{
    setItemRevestido(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.item_revestido = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editEspessuraChapa=(value:string)=>{
    setEspessuraChapa(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.espessura_chapa = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editAlturaPedra=(value:string)=>{
    setAlturaPedra(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.altura_pedras = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editLarguraPedra=(value:string)=>{
    setLarguraPedra(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.largura_pedras = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editPedra=(value:string)=>{
    setPedra(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.pedra = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editCorPedra=(value:string)=>{
    setCorPedra(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.cor_pedra = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editAcabamentoPedra=(value:string)=>{
    setAcabamentoPedra(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.acabamento_pedra = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editAlturaImtemRevestido=(value:string)=>{
    setAlturaItemRevestido(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.altura_item_revestido = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  const editLarguraItemRevestido=(value:string)=>{
    setLarguraItemRevestido(value);
    let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
    activityEdited.largura_item_revestido = value;
    editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
  }

  return (
    <Container>
      <Content>
        <FlexContainer>
          <div>
            <p>O que será revestido?</p>
            <Select value={item_revestido} onChange={(e) => editItemRevestido(e.target.value)}>
              <option value="Parede">Parede</option>
              <option value="Piso">Piso</option>
              <option value="Outros">Outros</option>
            </Select>
          </div>
          {item_revestido === "Outros" ? (
            <div>
              <br />
              <p>&nbsp;</p>
              <InputActivity>
                <input type="text" placeholder="Ex.: Lareira, bancada ..." />
              </InputActivity>
            </div>
          ) : (
            <></>
          )}
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Pedra</p>
            <Select value={pedra} onChange={(e) => editPedra(e.target.value)}>
              <option value="Granito">Granito</option>
              <option value="Quartzo">Quartzo</option>
              <option value="Mármore">Mármore</option>
              <option value="Prime">Prime</option>
              <option value="Lâmina ultracompctada">
                Lâmina ultracompctada
              </option>
              <option value="Travertino">Travertino</option>
            </Select>
          </div>
          <div>
            <br />
            <p>Cor da pedra</p>
            <InputActivity>
              <input type="text"  value={cor_pedra} onChange={(e) => editCorPedra(e.target.value)}/>
              &nbsp;
            </InputActivity>
          </div>
          <div>
            <p>Acabamento da pedra</p>
            <Select  value={acabamento_pedra} onChange={(e) => editAcabamentoPedra(e.target.value)}>
              <option value="Natural">Natural</option>
              <option value="Polido">Polido</option>
              <option value="Levigado/Satinado">Levigado/Satinado</option>
            </Select>
          </div>
        </FlexContainer>
        <div>
          Dimensões do Item a ser revestido:
          <div className="contentSizeInputs">
            <div>
              <p>Altura</p>
              <InputActivity>
                <input type="text"  value={altura_item_revestido} onChange={(e) => editAlturaImtemRevestido(e.target.value)}/>m
              </InputActivity>
            </div>
            <div>
              <p>Largura</p>
              <InputActivity>
                <input type="text"  value={largura_item_revestido} onChange={(e) => editLarguraItemRevestido(e.target.value)}/>m
              </InputActivity>
            </div>
            <div></div>
            <div>
              <p>Espessura da chapa</p>
              <Select  value={espessura_chapa} onChange={(e) => editEspessuraChapa(e.target.value)}>
                <option value="6mm">6mm</option>
                <option value="12mm">12mm</option>
                <option value="18mm">18mm</option>
                <option value="20mm">20mm</option>
              </Select>
            </div>
          </div>
        </div>
        <div className="no_marginBottom">
          Dimensões das pedras
          <div className="contentSizeInputs">
            {dimensaoPedrasNaoAplicavel ?(<div>
              <p style={disabledColorStyle}>Altura</p>
              <InputActivity>
                <input style={cursorBlocked} type="text" disabled /><p style={disabledColorStyle}>m</p>
              </InputActivity>
            </div>):(<div>
              <p>Altura</p>
              <InputActivity>
                <input type="text"  value={altura_pedras} onChange={(e) => editAlturaPedra(e.target.value)}/><p>m</p>
              </InputActivity>
            </div>)}

            {dimensaoPedrasNaoAplicavel ?(<div>
              <p style={disabledColorStyle}>Largura</p>
              <InputActivity>
                <input  style={cursorBlocked} type="text" /><p style={disabledColorStyle}>cm</p>
              </InputActivity>
            </div>):(<div>
              <p>Largura</p>
              <InputActivity>
                <input type="text"  value={largura_pedras} onChange={(e) => editLarguraPedra(e.target.value)}/><p>cm</p>
              </InputActivity>
            </div>)}
          </div>
          <div>
            <CheckBoxContainer>
              <div className="buttonSelectOption">
                <ContainerCheckBox>
                <input type="checkbox" name="no aplicable option" id="no_aplicable_option" onClick={()=>setDimensaoPedrasNaoAplicavel(!dimensaoPedrasNaoAplicavel)} checked={dimensaoPedrasNaoAplicavel}/>
                </ContainerCheckBox>
                <input type="radio" id="no_aplicable_option" name="no aplicable option" value="no_aplicable_option" checked={dimensaoPedrasNaoAplicavel} />
                <label htmlFor="no_aplicable_option" onClick={()=>setDimensaoPedrasNaoAplicavel(!dimensaoPedrasNaoAplicavel)}>
                  Não se aplica/Não definido
                </label>
              </div>
            </CheckBoxContainer>
          </div>
        </div>
      </Content>
      <Content>
        <div></div>
      </Content>
    </Container>
  );
};

export default Coating;
