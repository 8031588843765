import React from "react";

import moment from "moment";

import { SelectDayActualyContent } from "../SelectDayActualy/style";
import { markDowPeridActualGantt } from "./semianual";

interface periodProps {
    period: string,
    semesterNumber: number,
    yearNumber: number
}

const SelectPeriodActual: React.FC<periodProps> = (props) => {
    return (
        <>
            {markDowPeridActualGantt(moment().format('DD/MM/YYYY').toString(), props.semesterNumber + 1, props.yearNumber, props.period) ? (
                <SelectDayActualyContent width="auto" backgroundColor="#7592CC" textColor="#FFF">
                    {props.period}
                </SelectDayActualyContent>
            ) : (
                <SelectDayActualyContent width="auto" backgroundColor="transparent" textColor="#A5A5A5">
                    {props.period}
                </SelectDayActualyContent>
            )}

        </>
    )
}

export default SelectPeriodActual