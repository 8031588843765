import { SetStateAction } from "react"
import { attachmentProps } from "../type"

export const addFile = (attachmentList: attachmentProps[], setAttachmentList: React.Dispatch<SetStateAction<any>>, period: string, responsible: string) => {
    const attachmentListCopy = Array.from(attachmentList)
    attachmentListCopy.push({
        archive: [],
        link: '',
        period: period,
        responsible: responsible
    })
    setAttachmentList(attachmentListCopy)
}

export const editAttachment = (attachmentList: attachmentProps[], responsible: string, index: number, setAttachmentList: React.Dispatch<SetStateAction<any>>, event: React.ChangeEvent<HTMLInputElement>) => {
    const attachmentListCopy = Array.from(attachmentList)
    if (event.target.files) {
        attachmentListCopy.splice(index, 1, {
            archive: [event.target.files],
            link: attachmentListCopy[index].link,
            period: attachmentListCopy[index].period,
            responsible: attachmentListCopy[index].responsible
        })
    }
    setAttachmentList(attachmentListCopy)
}