import React from "react";

import {
  ContanierFinalization,
  ItemContainerFinzalization,
  ContentInputPrice,
  PriceItem,
  ContainerButtonSendBudget,
  ConditionsPayment,
  Observations,
  ObservationsArea,
} from "../../style";

interface footerBudgetProps {
  setMinimunBudgetValue: React.Dispatch<React.SetStateAction<string>>;
  setWorkingDays: React.Dispatch<React.SetStateAction<number>>;
  setConditions: React.Dispatch<React.SetStateAction<string>>;
  setObservations: React.Dispatch<React.SetStateAction<string>>;
  submitBudget: any;
}

const FooterBudget: React.FC<footerBudgetProps> = (props) => {
  return (
    <div>
      <ContanierFinalization>
        <ItemContainerFinzalization>
          <p>Valor total do serviço:</p>
          <ContentInputPrice>
            R$:
            <PriceItem
              onChange={(e) => props.setMinimunBudgetValue(e.target.value)}
            />
          </ContentInputPrice>
        </ItemContainerFinzalization>

        <ItemContainerFinzalization>
          <p>Prazo de entrega</p>
          <div className="workingDays">
            <input
              type="number"
              onChange={(e) => props.setWorkingDays(parseInt(e.target.value))}
            />
            dias úteis
          </div>
        </ItemContainerFinzalization>
        <ConditionsPayment>
          <p>Condições de pagamento</p>
          <div>
            <input
              type="text"
              onChange={(e) => props.setConditions(e.target.value)}
            />
          </div>
        </ConditionsPayment>
      </ContanierFinalization>
      <ObservationsArea>
        <Observations>
          <p>Observações</p>
          <div>
            <input
              type="text"
              onChange={(e) => props.setObservations(e.target.value)}
            />
          </div>
        </Observations>

        <ContainerButtonSendBudget onClick={props.submitBudget}>
          <button>Enviar orçamento</button>
        </ContainerButtonSendBudget>
      </ObservationsArea>
      <br />
    </div>
  );
};

export default FooterBudget;
