import React, { useState, useEffect } from "react";
import axios from "axios";

import { UseUserContext } from "../../../Context/UserContext";
import { UseGanttContext } from "../../../Context/GanttContext";

import { Container, Input, FlexElement, CenterButton } from "../styles";
import { Button, Select } from "../../stylesDomElements/htmlStyledElements";
import { UseModalContext } from "../../../Context/ModalContext";
import { stepProps } from "../../../pages/ConstructionManagement/type";

interface createDependencyProps{
    steps: stepProps[]
    setpName: string
}

const CreateDependency: React.FC<createDependencyProps>= (props) => {
    const { setModalNameHeader } = UseModalContext()
    const { projectSelected } = UseUserContext()
    const { stepId } = UseGanttContext();

    const [initAfter, setInitAfter] = useState<string>("Fim")
    const [stepReference, setStepReference] = useState<string>("")
    const [daysOfDelay, setDaysOfDelay] = useState<number>(0)

    useEffect(() => {
        setModalNameHeader("Adicionar dependencia: " + props.setpName)
    }, [initAfter, stepReference])

    const generateDependency = async () => {
        if (stepReference && stepId[0]) {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/newDependency`, {
                projectId: projectSelected,
                stepId: stepId[0],
                initAfter,
                stepReference,
                daysOfDelay
            }).then(res => {
                if (res.status === 200) {
                    window.location.reload()
                }
            })
        } else {
            alert('Dados incompletos!')
        }
    }

    return (
        <Container>
            <FlexElement>
                <label>A etapa começa após o</label>

                <Select
                    className="selectElement"
                    value={initAfter}
                    onChange={(e) => setInitAfter(e.target.value)}
                >
                    <option value="Inicio">Início</option>
                    <option value="Fim">Fim</option>
                </Select>

                <label htmlFor="">de </label>

                <Select
                    className="selectElement"
                    value={stepReference}
                    onChange={(e) => setStepReference(e.target.value)}
                >
                    <option value="">Selecione</option>
                    {props.steps.map((step, index)=>(
                        <option value={step.name} key={index}>{step.name}</option>
                    ))}
                </Select>
            </FlexElement>
            <FlexElement>
                <label>Atraso (dias)</label>
                <Input
                    onChange={(e) => setDaysOfDelay(Number(e.target.value))}
                    type="number"
                    className="selectElement"
                    placeholder="0"
                    min={0}
                />
            </FlexElement>
            <CenterButton>
                <Button className="buttonRegister" onClick={() => generateDependency()}>{"Salvar"}</Button>
            </CenterButton>
        </Container >
    )
}

export default CreateDependency