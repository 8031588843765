import { verify } from "jsonwebtoken";
import React, { useState, useEffect } from "react";

const token = localStorage.getItem('token');

export const verifyTypeProjectExibition = () => {
    var projectOfLink = ''
    var dataDecodeJwt: any
        
    if (token) {
        verify(token, process.env.REACT_APP_SECRET_KEY_JWT || '', (err, data) => {
            dataDecodeJwt = data
            if (err) {
                console.log(err)
            } else {
                projectOfLink = dataDecodeJwt.projectId
                console.log(data)
            }
        })
        console.log(projectOfLink)
    }

    return projectOfLink
}