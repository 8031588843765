import React from "react"

import { allActivitiesProps, BudgetsProps, SupplierProps, stepsShowElementsProps } from "../../../../types/typesEntitiesProject"
import { constructor, worker } from "../../../../Config/dictionary"

import { CotentItemStep } from "../../style"

/**Scripts */
import { totalStepBudgets } from "../../scripts/totalStepBudgets";
import { renderTotalActivityBudget } from "../../scripts/renderTotalActivityBudget"

interface stepProps {
    _id: string;
    clientId: string;
    name: string;
    percentage: number;
    startDate: string;
    endDate: string;
}

interface constructorsAndWorkersProps {
    budgets: BudgetsProps[],
    suppliers: SupplierProps[],
    activities: allActivitiesProps[],
    step: stepProps,
    stepsShowElements: stepsShowElementsProps[]
}

const ConstructorsAndWorkers: React.FC<constructorsAndWorkersProps> = (props) => {
    return (
        <>
            {props.suppliers?.map((supplier, index) => (
                supplier.typeSupplier === constructor || supplier.typeSupplier === worker ? (
                    <>
                        <CotentItemStep key={index}>
                            {props.budgets?.filter(budget => budget.providerId === supplier._id).length > 0 ? (
                                totalStepBudgets(props.budgets, props.suppliers, props.step._id, supplier.typeSupplier, supplier._id)
                            ) : (
                                <p>R$0,00</p>
                            )}

                            {props.activities.map((activity) => (
                                activity.stepId === props.step._id ? (
                                    props.stepsShowElements.filter(stepShow => stepShow.stepId === props.step._id).length > 0 ? (

                                        props.budgets && props.budgets.find(budget => budget.providerId === supplier._id && budget.activityId === activity._id)?.totalBudgetPayment ? (
                                            renderTotalActivityBudget(props.budgets, props.budgets.find(budget => budget.providerId === supplier._id && budget.activityId === activity._id)?.totalBudgetPayment, activity?._id)
                                        ) : (<p>R$ 0,00</p>)

                                    ) : (<></>)
                                ) : ('')
                            ))}
                        </CotentItemStep>
                    </>
                ) : (null)
            ))}
        </>
    )
}

export default ConstructorsAndWorkers