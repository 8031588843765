import { propsDate } from "../type"

export const dateFormate = (date: string) => {
    var dayMonth = date.split('/')[0]
    var month = date.split('/')[1]
    var year = date.split('/')[2]

    if (parseInt(month) < 10) {
        month = '0' + month
    }
    if (parseInt(dayMonth) < 10) {
        dayMonth = '0' + dayMonth
    }
    var dateFormate = dayMonth + '/' + month + '/' + year
    return dateFormate
}