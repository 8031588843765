const activitiesNameRepository = [
    'Demolição',
    'Fundação',
    'Requadramento de vão',
    'Área de serviço',
    'Painel elétrico',
    'Passagem de conduíte',
    'Instalações',
    'Automações',
    'Água fria',
    'Água quente',
    'Gás',
    'Revestimento de piso cerâmico',
    'Revestimento de piso vinílico',
    'Rodapé',
    'Acessórios',
    'Sóculo em alvenaria',
    'Luminárias',
    'Câmera de segurança',
    'Portas e janelas',
    'Esquadrias',
    'Mobiliario'
]

export const textTransform = (text: string) => { 
    var indexNameRepository = activitiesNameRepository.findIndex(nameRepository => nameRepository.normalize('NFD').replace(/[\u0300-\u036f]/g, "") === text)
    if (indexNameRepository < 0)
        return text
    return activitiesNameRepository[indexNameRepository]
}