import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import {
  AccessoriesFormType,
  FaucetAndMixerFormType,
  ShowersFormType,
  VasesFormType,
  VatsFormType,
} from "../../../Models/TablewareAndMetals/type";

import { FaucetAndMixer } from "../../../Models/TablewareAndMetals/FaucetAndMixerModel";
import { Showers } from "../../../Models/TablewareAndMetals/ShowersModel";
import { Vases } from "../../../Models/TablewareAndMetals/VasesModel";
import { Vats } from "../../../Models/TablewareAndMetals/VatsModel";
import { Accessories } from "../../../Models/TablewareAndMetals/AccessoriesModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesTablewareAndMetalsProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Accessories: AccessoriesFormType;
  FaucetAndMixer: FaucetAndMixerFormType;
  Showers: ShowersFormType;
  Vases: VasesFormType;
  Vats: VatsFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  tablewareAndMetalsActivities: activitiesTablewareAndMetalsProps[];
  tablewareAndMetalsActivityName: string;
  indexTablewareAndMetalsActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesTablewareAndMetalsProps[]>
  >;
  setNewTablewareAndMetalsActivity?: () => void;
  setTablewareAndMetalsActivityName?: React.Dispatch<
    React.SetStateAction<string>
  >;
  setIndexTablewareAndMetalsActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editTablewareAndMetalsActivity?: (
    index: number,
    activity: activitiesTablewareAndMetalsProps
  ) => void;
  deleteTablewareAndMetalsActivity?: (index: number) => void;
}

const activityContextDefault = {
  tablewareAndMetalsActivities: [],
  tablewareAndMetalsActivityName: "",
  indexTablewareAndMetalsActivitySelected: 0,
  setNewTablewareAndMetalsActivity: () => {},
  setTablewareAndMetalsActivityName: () => {},
  setIndexTablewareAndMetalsActivitySelected: () => {},
  editTablewareAndMetalsActivity: () => {},
  deleteTablewareAndMetalsActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseTablewareAndMetalsActivityContext = () =>
  useContext(ActivitiesContext);

export const TablewareAndMetalsActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [tablewareAndMetalsActivities, setActivity] = useState<
    activitiesTablewareAndMetalsProps[]
  >(activityContextDefault.tablewareAndMetalsActivities);
  const [
    indexTablewareAndMetalsActivitySelected,
    setIndexTablewareAndMetalsActivitySelected,
  ] = useState<number>(0);
  const [tablewareAndMetalsActivityName, setTablewareAndMetalsActivityName] =
    useState<string>("Vasos");

  const editTablewareAndMetalsActivity = (
    index: number,
    activity: activitiesTablewareAndMetalsProps
  ) => {
    let activitiesEdited = tablewareAndMetalsActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteTablewareAndMetalsActivity = (index: number) => {
    const activity = [...tablewareAndMetalsActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewTablewareAndMetalsActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: tablewareAndMetalsActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Loucas e metais",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Accessories: Accessories,
        FaucetAndMixer: FaucetAndMixer,
        Showers: Showers,
        Vases: Vases,
        Vats: Vats,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        tablewareAndMetalsActivities,
        tablewareAndMetalsActivityName,
        indexTablewareAndMetalsActivitySelected,
        setActivity,
        setTablewareAndMetalsActivityName,
        setNewTablewareAndMetalsActivity,
        editTablewareAndMetalsActivity,
        deleteTablewareAndMetalsActivity,
        setIndexTablewareAndMetalsActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
