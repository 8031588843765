import React from "react";

import BalconyClosingDescription from "./BalconyClosing";
import BathroomDescription from "./Bathroombox";
import GlassdoorandFramesDescription from "./GlassdoorandFrames";
import MirrorsDescription from "./Mirrors";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const GlassworksDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Fechamento de sacada" ? (<BalconyClosingDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Box de banheiro" ? (<BathroomDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Porta de vidro e esquadrias" ? (<GlassdoorandFramesDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Espelhos" ? (<MirrorsDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default GlassworksDescription