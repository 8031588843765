import React, { useState, useEffect } from 'react'
import moment from 'moment';
import axios from 'axios';

import { BudgetsProps } from '../../../../types/typesEntitiesProject';
import { UseModalContext } from "../../../../Context/ModalContext";
import { UseSelectionBudgetContext } from "../../Context/SelectionBudgetContext"

import {
    BudgetContainer,
    FilterContainer,
    ClearFilters,
    ItemBudget,
    HeaderTableContent,
    ItensBudgetSelect,
    WarningBudgets,
    TextCondition,
    Button
} from "./style";

import {
    ContainerCheckBox,
    TooltipCard,
    TooltipBox,
    TooltipText
} from "../../../../components/stylesDomElements/htmlStyledElements";

//import verifieldIcon from "../../../assets/verifieldIcon.svg";
//import starNoFavoritedIcon from "../../../assets/starNofavorited.svg";
import seachBlackIcon from "../../../../assets/lupaBlackIcon.svg"
import { UseGanttContext } from '../../../../Context/GanttContext';
interface activityPops {
    activityId: string
}

const Budget: React.FC<activityPops> = (props) => {
    const { setShowModal } = UseModalContext();
    const { stepId } = UseGanttContext()
    const { showBudgetSupplier, setShowBudgetSupplier, setBudget, setSupplier } = UseSelectionBudgetContext()
    const [budgets, setBudgets] = useState<BudgetsProps[]>([])

    const [selectedBudget, setSelectedBudget] = useState<BudgetsProps>();
    const [check, setCheck] = useState<boolean>(false);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/listAllBudget/${props.activityId}`
            )
            .then((res) => {
                setBudgets(res.data);
            });
        // eslint-disable-next-line
    }, []);

    const orderItensPrice = () => {
        var newOrderBudget = budgets.sort(function (itemA, itemB) {
            return itemA.totalBudgetPayment < itemB.totalBudgetPayment
                ? -1
                : itemA.totalBudgetPayment > itemB.totalBudgetPayment
                    ? 1
                    : 0;
        });
        setBudgets(newOrderBudget);
    };

    const orderItensDeadLine = () => {
        var newOrderBudget = budgets.sort(function (itemA, itemB) {
            return itemA.workingDays < itemB.workingDays
                ? -1
                : itemA.workingDays > itemB.workingDays
                    ? 1
                    : 0;
        });
        setBudgets(newOrderBudget);
    };

    const viewBudgetDetails = (budget: BudgetsProps) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/supplier/${budget.providerId}`).then((res => {
            setBudget?.({ ...budget })
            setSupplier?.({ ...res.data })
            setShowBudgetSupplier?.(!showBudgetSupplier)
        }))
    }

    const sendSelectedBudget = () => {
        if (selectedBudget) {
            axios
                .put(`${process.env.REACT_APP_BASE_URL}/selectedBudget`, {
                    selectedBudget,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setShowModal?.();
                    }
                });
        }
    };

    return (
        <BudgetContainer>
            <FilterContainer>
                <p className="order">Ordernar por: </p>
                <div
                    className="buttonSelectOption"
                    onClick={() => orderItensDeadLine()}>
                    <input type="radio" id="key1" name="drone" value="huey" />
                    <label htmlFor="key1">Prazo de entrega</label>
                </div>

                <div
                    className="buttonSelectOption"
                    onClick={() => orderItensPrice()}
                >
                    <input type="radio" id="key2" name="drone" value="huey" />
                    <label htmlFor="key2">Preço</label>
                </div>

                <div className="buttonSelectOption">
                    <input type="radio" id="key3" name="drone" value="huey" />
                    <label htmlFor="key3">Avaliação</label>
                </div>

                <ContainerCheckBox onClick={() => setCheck(!check)}>
                    <label htmlFor="check1">
                        <input
                            type="checkbox"
                            name="favorites"
                            id="check1"
                            checked={check}
                        />
                        <p>Filtrar por favoritos</p>
                    </label>
                </ContainerCheckBox>
                <ClearFilters>Limpar filtros</ClearFilters>
            </FilterContainer>
            {budgets.length ? (
                <div>
                    <HeaderTableContent>
                        <div>Fornecedor</div>
                        <div>Prazo</div>
                        <div>Pagamento</div>
                        <div>Avaliação</div>
                        <div>Preço total</div>
                        <div><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></div>
                    </HeaderTableContent>
                    <ItensBudgetSelect>
                        {budgets.map((item, index) => (
                            <div key={index} className="budgetItemList">
                                {item.selectedBudget ? (
                                    <input
                                        type="radio"
                                        id={`keyValue:${index}`}
                                        name="budget"
                                        value="huey"
                                        checked={true}
                                    />
                                ) : (
                                    <input
                                        type="radio"
                                        id={`keyValue:${index}`}
                                        name="budget"
                                        value="huey"
                                    />
                                )}
                                <label htmlFor={`keyValue:${index}`}>
                                    <ItemBudget className='itemBudget' onClick={() =>
                                        setSelectedBudget({
                                            _id: item._id,
                                            providerId: item.providerId,
                                            activityId: item.activityId,
                                            projectId: item.projectId,
                                            stepId:item.stepId,
                                            selectedStartDate: moment().format("DD/MM/YYYY"),
                                            workingDays: item.workingDays,
                                            selectedBudget: true,
                                            minimumBudgetValue: item.minimumBudgetValue,
                                            totalBudgetPayment: item.totalBudgetPayment
                                        })
                                    }>
                                        {/**Nome Fornecedor */}
                                        <div className="supplierName">
                                            {item.supplierName}
                                        </div>

                                        {/**Prazo */}
                                        <div>{item.workingDays} dias úteis</div>

                                        {/**Pagamento */}
                                        {item.conditions ? (
                                            <div>
                                                <TooltipCard >
                                                    <TooltipText>
                                                        <input disabled type="text" value={item.conditions} />
                                                    </TooltipText>
                                                    <TooltipBox>
                                                        <TextCondition>{item.conditions}</TextCondition>
                                                    </TooltipBox>
                                                </TooltipCard>
                                            </div>
                                        ) : (<div>-</div>)}

                                        {/**Avaliação */}
                                        <div>-</div>

                                        {/**Total */}
                                        <div>R$ {item.totalBudgetPayment}</div>

                                        {/**Visualizar detalhes */}
                                        <div className='viewBudgetDetails' onClick={() => viewBudgetDetails(item)}>
                                            <img src={seachBlackIcon} alt="icone de lupa" />
                                        </div>
                                    </ItemBudget>
                                </label>
                            </div>
                        ))}
                    </ItensBudgetSelect>
                    <div className="containerButton">
                        {budgets.filter(budget => budget.selectedBudget).length > 0 ? (
                            <div></div>
                        ) : (
                            <Button onClick={() => sendSelectedBudget()}>
                                <p className="contractButton">Contratar</p>
                            </Button>
                        )}
                    </div>
                </div>

            ) : (
                <WarningBudgets>
                    <h4>Não há orçamentos disponíveis para esta atividade!</h4>
                </WarningBudgets>
            )}
        </BudgetContainer>
    )
}

export default Budget