export const ColdWater = {
    PontoDeAguaFria: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    RetirarPontoDeAguaFria: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    PontoDeFiltro: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    PontoDeRegistro: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Tubulacao: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Medida',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Emendas: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    Ralo: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Fornecedor',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ],
    ItensEspeciais: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ]

}
