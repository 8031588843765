import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import iconClose from "../../assets/XIcon.svg";
import { UseUserContext } from "../../Context/UserContext";
import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import AcessMenu from "../../components/AcessMenu";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import {
  Container,
  ContentElement,
  Info,
  ActivityDateContainer,
  ResponsibleItem,
  Responsible,
  MenuOverlay,
  OptionButton,
  MenuEditItem,
  InputForItemEdit,
} from "./style";
import {
  Button,
  InputForm,
  Select,
} from "../../components/stylesDomElements/htmlStyledElements";
import { startOfWeek, endOfWeek, addDays, format, isDate } from "date-fns";
import { UseEditWeeklySummaryContext } from "../../Context/EditWeeklySummaryContext";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";

interface Activity {
  _id: string;
  projectId: string;
  activityId: string;
  stepId: string;
  supplierActivities: SupplierActivity[];
  architectActivities: {
    beforeInitService: ArchitectActivity[];
    duringService: ArchitectActivity[];
    afterService: ArchitectActivity[];
  };
  responsibles: string[];
  weekDate: string;
  __v: number;
}

interface SupplierActivity {
  activity: string;
  createdAt: string;
  checkUpdatedAt: string;
  check: boolean;
}

interface ArchitectActivity {
  activity: string;
  check: boolean;
  renameOrDelete: boolean;
  checkUpdatedAt: string;
  createdAt: string;
  attachment: boolean;
  attachmentList: any[];
}

interface Step {
  _id: string;
  clientId: string;
  projectId: string;
  name: string;
  percentage: number;
  quantity: number;
  measure: string;
  budgetName: string;
  valueUnit: string;
  partialsDivisor: string[];
  startDate: string;
  endDate: string;
  selectedBudget: boolean;
  __v: number;
}

interface Option {
  value: string;
  label: string;
}

const WeeklySummary: React.FC = () => {
  const { projectSelected, setprojectSelected } = UseUserContext();
  const { acessLink } = UseAuthAcessContext();
  const { editMode, setShowEditWeeklySummary } = UseEditWeeklySummaryContext();
  const [weekStart, setWeekStart] = useState<string>(""); // data inicial da semana (domingo)
  const [weekEnd, setWeekEnd] = useState<string>(""); // data final da semana (sábado)
  const [selectedDate, setSelectedDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [steps, setSteps] = useState<Step[]>([]);
  const [weeklySummary, setWeeklySummary] = useState<Activity[]>([]);
  const [responsibles, setResponsibles] = useState<string[]>([]);

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    } else {
      const projectId = localStorage.getItem("projectId");
      if (projectId && typeof setprojectSelected !== "undefined") {
        setprojectSelected(projectId);
      }
    }
  }, []);

  useEffect(() => {
    if (isDate(new Date(selectedDate))) {
      const date = new Date(selectedDate);
      const weekStart = new Date(date.setDate(date.getDate() - date.getDay()));
      const weekEnd = new Date(date.setDate(date.getDate() + 6));
      const weekStartFormatted = format(weekStart, "dd/MM/yyyy");
      const weekEndFormatted = format(weekEnd, "dd/MM/yyyy");
      setWeekStart(weekStartFormatted);
      setWeekEnd(weekEndFormatted);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/steps/${projectSelected}`, {})
        .then((res) => {
          setSteps(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [projectSelected, acessLink]);

  useEffect(() => {
    if (acessLink) {
      localStorage.removeItem("projectId");
    }
  }, [acessLink]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/listResumeProject/${projectSelected}`,
          {
            dateWeek: selectedDate,
          }
        )
        .then((response) => {
          setWeeklySummary(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedDate, projectSelected, acessLink]);

  useEffect(() => {
    weeklySummary.map(() => {
      setResponsibles(weeklySummary[0].responsibles);
    });
  }, [weeklySummary]);

  useEffect(() => {
    updateResponsibles();
  }, [responsibles]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    if (inputText.includes(",")) {
      const newResponsibles = inputText
        .split(",")
        .map((name: string) => name.trim())
        .filter((name: string) => name !== ""); // remove os elementos vazios do array
      setResponsibles([...responsibles, ...newResponsibles]);
      event.target.value = "";
      const newWeeklySummary = [...weeklySummary];
      // atualizar o valor de responsibles na cópia
      newWeeklySummary[0].responsibles = [...responsibles, ...newResponsibles];
      // chamar setWeeklySummary com a nova cópia
      setWeeklySummary(newWeeklySummary);
    }
  };

  const dropItem = (index: number) => {
    const responsiblesCopy = [...responsibles]; // cria uma cópia do array original usando o spread operator
    responsiblesCopy.splice(index, 1);
    setResponsibles(responsiblesCopy.filter((name: string) => name !== "")); // remove os elementos vazios do array
  };

  //Essa função irá atualizar o weeklySummary
  const handleEditItemSubmit = (
    currentValue: string,
    newValue: string,
    itemId: string
  ) => {
    const newWeeklySummary = weeklySummary.map((item) => {
      if (item._id === itemId) {
        const archiActs = item.architectActivities;
        const suppActs = item.supplierActivities;
        const updatedArchiActs = {
          beforeInitService: archiActs.beforeInitService.map((act) => {
            if (act.activity === currentValue) {
              return { ...act, activity: newValue };
            }
            return act;
          }),
          duringService: archiActs.duringService.map((act) => {
            if (act.activity === currentValue) {
              return { ...act, activity: newValue };
            }
            return act;
          }),
          afterService: archiActs.afterService.map((act) => {
            if (act.activity === currentValue) {
              return { ...act, activity: newValue };
            }
            return act;
          }),
        };
        const updatedSuppActs = suppActs.map((act) => {
          if (act.activity === currentValue) {
            return { ...act, activity: newValue };
          }
          return act;
        });
        return {
          ...item,
          architectActivities: updatedArchiActs,
          supplierActivities: updatedSuppActs,
        };
      } else {
        return item;
      }
    });
    setWeeklySummary(newWeeklySummary);
  };

  const handleDeleteItem = (itemToDelete: string, itemId: string) => {
    const newWeeklySummary = weeklySummary.map((item) => {
      if (item._id === itemId) {
        const archiActs = item.architectActivities;
        const suppActs = item.supplierActivities.filter(
          (act) => act.activity !== itemToDelete
        );
        const updatedArchiActs = {
          beforeInitService: archiActs.beforeInitService.filter(
            (act) => act.activity !== itemToDelete
          ),
          duringService: archiActs.duringService.filter(
            (act) => act.activity !== itemToDelete
          ),
          afterService: archiActs.afterService.filter(
            (act) => act.activity !== itemToDelete
          ),
        };
        return {
          ...item,
          architectActivities: updatedArchiActs,
          supplierActivities: suppActs,
        };
      }
      return item;
    });
    setWeeklySummary(newWeeklySummary);
  };

  const handleSelectUncheckedChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // Extrai o nome da atividade selecionada e o nome da etapa selecionada da lista suspensa.
    const selectedActivity = event.target.value
      .substring(event.target.value.indexOf(" ") + 1)
      .trim();
    const selectedStep = event.target.value
      .substring(0, event.target.value.indexOf(" "))
      .trim();
    const selectedOption = event.target.selectedOptions[0];
    const selectedStepId = selectedOption.getAttribute("data-stepid");

    // Atualiza o valor do check da atividade selecionada na cópia do objeto sem modificar o objeto original.
    const updatedWeeklySummary = weeklySummary.map((activity) => {
      const updatedSupplierActivities = activity.supplierActivities.map(
        (supplierAct) => {
          if (
            supplierAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...supplierAct, check: true };
          } else {
            return supplierAct;
          }
        }
      );

      const updatedBeforeInitServiceActivities =
        activity.architectActivities.beforeInitService.map((archiAct) => {
          if (
            archiAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...archiAct, check: true };
          } else {
            return archiAct;
          }
        });

      const updatedDuringServiceActivities =
        activity.architectActivities.duringService.map((archiAct) => {
          if (
            archiAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...archiAct, check: true };
          } else {
            return archiAct;
          }
        });

      const updatedAfterServiceActivities =
        activity.architectActivities.afterService.map((archiAct) => {
          if (
            archiAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...archiAct, check: true };
          } else {
            return archiAct;
          }
        });

      return {
        ...activity,
        supplierActivities: updatedSupplierActivities,
        architectActivities: {
          beforeInitService: updatedBeforeInitServiceActivities,
          duringService: updatedDuringServiceActivities,
          afterService: updatedAfterServiceActivities,
        },
      };
    });

    // Atualiza o estado com o novo objeto.
    setWeeklySummary(updatedWeeklySummary);
  };

  const handleSelectCheckedChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // Extrai o nome da atividade selecionada e o nome da etapa selecionada da lista suspensa.
    const selectedActivity = event.target.value
      .substring(event.target.value.indexOf(" ") + 1)
      .trim();
    const selectedStep = event.target.value
      .substring(0, event.target.value.indexOf(" "))
      .trim();
    const selectedOption = event.target.selectedOptions[0];
    const selectedStepId = selectedOption.getAttribute("data-stepid");

    // Atualiza o valor do check da atividade selecionada na cópia do objeto sem modificar o objeto original.
    const updatedWeeklySummary = weeklySummary.map((activity) => {
      const updatedSupplierActivities = activity.supplierActivities.map(
        (supplierAct) => {
          if (
            supplierAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...supplierAct, check: false };
          } else {
            return supplierAct;
          }
        }
      );

      const updatedBeforeInitServiceActivities =
        activity.architectActivities.beforeInitService.map((archiAct) => {
          if (
            archiAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...archiAct, check: false };
          } else {
            return archiAct;
          }
        });

      const updatedDuringServiceActivities =
        activity.architectActivities.duringService.map((archiAct) => {
          if (
            archiAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...archiAct, check: false };
          } else {
            return archiAct;
          }
        });

      const updatedAfterServiceActivities =
        activity.architectActivities.afterService.map((archiAct) => {
          if (
            archiAct.activity === selectedActivity &&
            activity.stepId === selectedStepId
          ) {
            return { ...archiAct, check: false };
          } else {
            return archiAct;
          }
        });

      return {
        ...activity,
        supplierActivities: updatedSupplierActivities,
        architectActivities: {
          beforeInitService: updatedBeforeInitServiceActivities,
          duringService: updatedDuringServiceActivities,
          afterService: updatedAfterServiceActivities,
        },
      };
    });

    // Atualiza o estado com o novo objeto.
    setWeeklySummary(updatedWeeklySummary);
  };

  const updateResponsibles = () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/updateResponsiblesResume/${projectSelected}`,
        {
          responsibles,
          dateWeek: selectedDate,
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submit = async () => {
    /* await updateResponsibles(); */
    await axios
      .put(`${process.env.REACT_APP_BASE_URL}/updateResume`, weeklySummary)
      .then((res) => {
        console.log(res);
        setShowEditWeeklySummary?.();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <Header />

      {!acessLink ? (
        <ItensMenu optionSelected="Resumo semanal" />
      ) : (
        <AcessMenu />
      )}
      <ContentElement>
        <h4>Relatório semanal</h4>
        <div className="infoArea">
          <p>
            Período:
            <ActivityDateContainer>
              <>
                {weekStart + " - " + weekEnd}
                <input
                  type="date"
                  name=""
                  value={selectedDate}
                  onChange={handleDateChange}
                  id=""
                />
              </>
            </ActivityDateContainer>
          </p>

          {editMode ? (
            <>
              <div className="responsiblesArea">
                Responsáveis:
                <div className="reponsiblesBox">
                  {responsibles?.map(
                    (name, index) =>
                      name.trim() !== "" && (
                        <div key={index}>
                          <ResponsibleItem>
                            <Responsible>{name}</Responsible>
                            <img
                              src={iconClose}
                              alt="icone de fechar"
                              onClick={() => dropItem(index)}
                            />
                          </ResponsibleItem>
                        </div>
                      )
                  )}
                  <InputForm onChange={handleInput} type="text" />
                </div>
              </div>
              <p className="orientations">
                Separe as pessoas por uma vírgula ( , ). Exemplo: Pessoa 1,
                Pessoa 2, Pessoa 3
              </p>
            </>
          ) : (
            <p>
              Responsáveis:
              {responsibles.map((name, index) => (
                <span key={index}>
                  {responsibles.length === 1
                    ? "\u00A0" + name
                    : index === responsibles.length - 1
                    ? "\u00A0" + name
                    : index === responsibles.length - 2
                    ? "\u00A0" + name + " e "
                    : "\u00A0" + name + ", "}
                </span>
              ))}
            </p>
          )}
          <ul></ul>
        </div>
      </ContentElement>

      <Info>
        <div className="left-side">
          {editMode && (
            <div className="addActivityArea">
              <label htmlFor="addActivity">Adicionar atividade:</label>
              <Select
                id="addActivity"
                className="addActivity"
                onChange={handleSelectUncheckedChange}
              >
                <option value="">Selecione</option>
                {steps.map((step) => {
                  const weeklySummaryFiltered = weeklySummary.filter(
                    (item) => item.stepId === step._id
                  );

                  /** variável auxiliar que verifica se há atividades para aquele step */
                  const hasActivities = weeklySummaryFiltered.some((item) => {
                    return (
                      item.supplierActivities.some(
                        (supplierAct) => supplierAct.check === false
                      ) ||
                      item.architectActivities.afterService.some(
                        (archiAct) => archiAct.check === false
                      ) ||
                      item.architectActivities.duringService.some(
                        (archiAct) => archiAct.check === false
                      ) ||
                      item.architectActivities.beforeInitService.some(
                        (archiAct) => archiAct.check === false
                      )
                    );
                  });

                  // Crie uma matriz de opções para este passo
                  const options = weeklySummaryFiltered.reduce<Option[]>(
                    (acc, item) => {
                      const supplierActivities = item.supplierActivities.filter(
                        (supplierAct) => supplierAct.check === false
                      );
                      const architectActivitiesAfterService =
                        item.architectActivities.afterService.filter(
                          (archiAct) => archiAct.check === false
                        );
                      const architectActivitiesDuringService =
                        item.architectActivities.duringService.filter(
                          (archiAct) => archiAct.check === false
                        );
                      const architectActivitiesBeforeInitService =
                        item.architectActivities.beforeInitService.filter(
                          (archiAct) => archiAct.check === false
                        );

                      const supplierOptions = supplierActivities.map(
                        (supplierAct) => ({
                          value: `${step.name} ${supplierAct.activity}`,
                          label: `${step.name}: ${supplierAct.activity}`,
                        })
                      );

                      const architectOptionsAfterService =
                        architectActivitiesAfterService.map((archiAct) => ({
                          value: `${step.name} ${archiAct.activity}`,
                          label: `${step.name}: ${archiAct.activity}`,
                        }));

                      const architectOptionsDuringService =
                        architectActivitiesDuringService.map((archiAct) => ({
                          value: `${step.name} ${archiAct.activity}`,
                          label: `${step.name}: ${archiAct.activity}`,
                        }));

                      const architectOptionsBeforeInitService =
                        architectActivitiesBeforeInitService.map(
                          (archiAct) => ({
                            value: `${step.name} ${archiAct.activity}`,
                            label: `${step.name}: ${archiAct.activity}`,
                          })
                        );

                      // Adicione as opções deste item às opções globais
                      return acc.concat(
                        supplierOptions,
                        architectOptionsAfterService,
                        architectOptionsDuringService,
                        architectOptionsBeforeInitService
                      );
                    },
                    []
                  );

                  // Retorne as opções para este passo
                  return (
                    <React.Fragment key={step._id}>
                      {hasActivities && (
                        <>
                          {options.map((option: any) => (
                            <option
                              key={option.value}
                              value={option.value}
                              data-stepid={step._id}
                            >
                              {option.label}
                            </option>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </Select>

              <br />
              <br />
            </div>
          )}
          <h4>Assuntos vistos</h4>
          <div className="list">
            {steps.flatMap((step) => {
              const weeklySummaryFiltered = weeklySummary.filter(
                (item) => item.stepId === step._id
              );
              /** variável auxiliar que verifica se há atividades para aquele step */
              const hasActivities = weeklySummaryFiltered.some((item) => {
                return (
                  item.supplierActivities.some(
                    (supplierAct) => supplierAct.check
                  ) ||
                  item.architectActivities.afterService.some(
                    (archiAct) => archiAct.check
                  ) ||
                  item.architectActivities.duringService.some(
                    (archiAct) => archiAct.check
                  ) ||
                  item.architectActivities.beforeInitService.some(
                    (archiAct) => archiAct.check
                  )
                );
              });
              return (
                <div className="itens" key={step._id}>
                  {hasActivities && <p className="stepName">{step.name}</p>}
                  {weeklySummaryFiltered.map((item) => {
                    return item.supplierActivities.map(
                      (supplierAct) =>
                        supplierAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={supplierAct.activity}
                              isEdit={editMode}
                              placeholder={supplierAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  supplierAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(
                                    supplierAct.activity,
                                    item._id
                                  )
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                  {weeklySummaryFiltered.map((item) => {
                    return item.architectActivities.afterService.map(
                      (archiAct) =>
                        archiAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={archiAct.activity}
                              isEdit={editMode}
                              placeholder={archiAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  archiAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(archiAct.activity, item._id)
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                  {weeklySummaryFiltered.map((item) => {
                    return item.architectActivities.duringService.map(
                      (archiAct) =>
                        archiAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={archiAct.activity}
                              isEdit={editMode}
                              placeholder={archiAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  archiAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(archiAct.activity, item._id)
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                  {weeklySummaryFiltered.map((item) => {
                    return item.architectActivities.beforeInitService.map(
                      (archiAct) =>
                        archiAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={archiAct.activity}
                              isEdit={editMode}
                              placeholder={archiAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  archiAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(archiAct.activity, item._id)
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="right-side">
          {editMode && (
            <div className="addActivityArea">
              <label htmlFor="addActivity">Adicionar atividade:</label>
              {/* O código abaixo é um componente React que renderiza um dropdown de seleção para atividades
              O handleChange é uma função passada como propriedade que será chamada quando a opção for selecionada */}
              <Select
                id="addActivity"
                className="addActivity"
                onChange={handleSelectCheckedChange}
              >
                {/* Cria uma opção default vazia */}
                <option value="">Selecione</option>
                {steps.flatMap((step) => {
                  // Filtra os resumos semanais com o id deste passo
                  const weeklySummaryFiltered = weeklySummary.filter(
                    (item) => item.stepId === step._id
                  );

                  /** variável auxiliar que verifica se há atividades para aquele step */
                  const hasActivities = weeklySummaryFiltered.some((item) => {
                    return (
                      item.supplierActivities.some(
                        (supplierAct) => supplierAct.check
                      ) ||
                      item.architectActivities.afterService.some(
                        (archiAct) => archiAct.check
                      ) ||
                      item.architectActivities.duringService.some(
                        (archiAct) => archiAct.check
                      ) ||
                      item.architectActivities.beforeInitService.some(
                        (archiAct) => archiAct.check
                      )
                    );
                  });

                  // Crie uma matriz de opções para este passo
                  const options = weeklySummaryFiltered.reduce<Option[]>(
                    (acc, item) => {
                      // Filtra as atividades do fornecedor que estão marcadas
                      const supplierActivities = item.supplierActivities.filter(
                        (supplierAct) => supplierAct.check
                      );
                      // Filtra as atividades do arquiteto depois do serviço que estão marcadas
                      const architectActivitiesAfterService =
                        item.architectActivities.afterService.filter(
                          (archiAct) => archiAct.check
                        );
                      // Filtra as atividades do arquiteto durante o serviço que estão marcadas
                      const architectActivitiesDuringService =
                        item.architectActivities.duringService.filter(
                          (archiAct) => archiAct.check
                        );
                      // Filtra as atividades do arquiteto antes do início do serviço que estão marcadas
                      const architectActivitiesBeforeInitService =
                        item.architectActivities.beforeInitService.filter(
                          (archiAct) => archiAct.check
                        );

                      // Mapeia as atividades do fornecedor em opções
                      const supplierOptions = supplierActivities.map(
                        (supplierAct) => ({
                          value: `${step.name} ${supplierAct.activity}`,
                          label: `${step.name}: ${supplierAct.activity}`,
                        })
                      );

                      // Mapeia as atividades do arquiteto depois do serviço em opções
                      const architectOptionsAfterService =
                        architectActivitiesAfterService.map((archiAct) => ({
                          value: `${step.name} ${archiAct.activity}`,
                          label: `${step.name}: ${archiAct.activity}`,
                        }));

                      // Mapeia as atividades do arquiteto durante o serviço em opções
                      const architectOptionsDuringService =
                        architectActivitiesDuringService.map((archiAct) => ({
                          value: `${step.name} ${archiAct.activity}`,
                          label: `${step.name}: ${archiAct.activity}`,
                        }));

                      // Mapeia as atividades do arquiteto antes do início do serviço em opções
                      const architectOptionsBeforeInitService =
                        architectActivitiesBeforeInitService.map(
                          (archiAct) => ({
                            value: `${step.name} ${archiAct.activity}`,
                            label: `${step.name}: ${archiAct.activity}`,
                          })
                        );

                      // Adicione as opções deste item às opções globais
                      return acc.concat(
                        supplierOptions,
                        architectOptionsAfterService,
                        architectOptionsDuringService,
                        architectOptionsBeforeInitService
                      );
                    },
                    []
                  );

                  // Retorne as opções para este passo
                  return (
                    <React.Fragment key={step._id}>
                      {hasActivities && (
                        <>
                          {options.map((option: any) => (
                            <option
                              key={option.value}
                              value={option.value}
                              data-stepid={step._id}
                            >
                              {option.label}
                            </option>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </Select>
              <br />
              <br />
            </div>
          )}
          <h4>Assuntos pendentes</h4>
          <div className="list">
            {steps.map((step) => {
              const weeklySummaryFiltered = weeklySummary.filter(
                (item) => item.stepId === step._id
              );
              /** */
              const hasActivities = weeklySummaryFiltered.some((item) => {
                return (
                  item.supplierActivities.some(
                    (supplierAct) => !supplierAct.check
                  ) ||
                  item.architectActivities.afterService.some(
                    (archiAct) => !archiAct.check
                  ) ||
                  item.architectActivities.duringService.some(
                    (archiAct) => !archiAct.check
                  ) ||
                  item.architectActivities.beforeInitService.some(
                    (archiAct) => !archiAct.check
                  )
                );
              });
              return (
                <div className="itens" key={step._id}>
                  {hasActivities && <p className="stepName">{step.name}</p>}
                  {weeklySummaryFiltered.map((item) => {
                    return item.supplierActivities.map(
                      (supplierAct) =>
                        !supplierAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={supplierAct.activity}
                              isEdit={editMode}
                              placeholder={supplierAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  supplierAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(
                                    supplierAct.activity,
                                    item._id
                                  )
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                  {weeklySummaryFiltered.map((item) => {
                    return item.architectActivities.afterService.map(
                      (archiAct) =>
                        !archiAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={archiAct.activity}
                              isEdit={editMode}
                              placeholder={archiAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  archiAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(archiAct.activity, item._id)
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                  {weeklySummaryFiltered.map((item) => {
                    return item.architectActivities.duringService.map(
                      (archiAct) =>
                        !archiAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={archiAct.activity}
                              isEdit={editMode}
                              placeholder={archiAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  archiAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(archiAct.activity, item._id)
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                  {weeklySummaryFiltered.map((item) => {
                    return item.architectActivities.beforeInitService.map(
                      (archiAct) =>
                        !archiAct.check && (
                          <div className="itensForList">
                            <InputForItemEdit
                              value={archiAct.activity}
                              isEdit={editMode}
                              placeholder={archiAct.activity}
                              disabled={!editMode}
                              onChange={(e) =>
                                handleEditItemSubmit(
                                  archiAct.activity,
                                  e.target.value,
                                  item._id
                                )
                              }
                              onContextMenu={(e) => {
                                e.preventDefault();
                              }}
                            ></InputForItemEdit>
                            {editMode && (
                              <Button
                                onClick={() =>
                                  handleDeleteItem(archiAct.activity, item._id)
                                }
                              >
                                X
                              </Button>
                            )}
                          </div>
                        )
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </Info>
      {editMode && (
        <div className="buttonArea">
          <Button
            onClick={() => {
              setShowEditWeeklySummary?.();
              window.location.reload();
            }}
          >
            Cancelar
          </Button>
          <Button onClick={() => submit()}>Salvar alterações</Button>
        </div>
      )}
    </Container>
  );
};

export default WeeklySummary;
