import React, { useEffect } from "react"

import { UseEletricActivityContext } from "../../../Context/ActivitiesContext/Eletric/EletricContext"
import { UseStepContext } from "../../../Context/stepContext"

import { textTransform } from "../script"

import { ActionSelect, } from "../../stylesDomElements/htmlStyledElements";

const EletricController: React.FC = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()
    const { eletricActivities, editEletricActivity } = UseEletricActivityContext()

    useEffect(() => {
        setActivitySelected?.('Painel eletrico')
    }, [])

    useEffect(() => {
        if (eletricActivities.length > 0) {
            var activityEdited = eletricActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editEletricActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])
    /*
        useEffect(() => {
            if (eletricActivities.length > 0 && eletricActivities[props.indexSelected].activityType !== "") {
                setActivitySelected?.(eletricActivities[props.indexSelected].activityType)
            }
            eletricActivities.length > 0 && eletricActivities[props.indexSelected].activityType === "" ? (setActivitySelected?.('Painel eletrico')):(<></>)
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[props.indexSelected]) */

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="eletricStep" id="eletricStep" onChange={(e) => setActivitySelected?.(e.target.value)}>
                <option value="Painel eletrico">Painel elétrico</option>
                <option value="Passagem da infra">Passagem da infra</option>
                <option value="Passagem de fios">Passagem de fios</option>
                <option value="Instalacoes">Instalações</option>
                <option value="Automacoes">Automações</option>
                <option value="Materiais para eletrica">Materiais</option>
            </select>
        </ActionSelect>
    </div>
}

export default EletricController