import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import {
  ColdWaterFormType,
  GasFormType,
  HotWaterFormType,
  SewerFormType,
} from "../../../Models/Hydraulic/type";
import { ColdWater } from "../../../Models/Hydraulic/ColdWaterModel";
import { HotWater } from "../../../Models/Hydraulic/HotWaterModel";
import { Sewer } from "../../../Models/Hydraulic/SewerModel";
import { Gas } from "../../../Models/Hydraulic/GasModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesHydraulicProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation?: boolean;
  partialDivisor: string;
  estimatedTime: number;
  estimatedValue: number;

  ColdWater: ColdWaterFormType;
  HotWater: HotWaterFormType;
  Sewer: SewerFormType;
  Gas: GasFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  hydraulicActivities: activitiesHydraulicProps[];
  hydraulicActivityName: string;
  indexHydraulicActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesHydraulicProps[]>
  >;
  setNewHydraulicActivity?: () => void;
  setHydraulicActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexHydraulicActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editHydraulicActivity?: (
    index: number,
    activity: activitiesHydraulicProps
  ) => void;
  deleteHydraulicActivity?: (index: number) => void;
}

const activityContextDefault = {
  hydraulicActivities: [],
  hydraulicActivityName: "",
  indexHydraulicActivitySelected: 0,
  setNewHydraulicActivity: () => {},
  setHydraulicActivityName: () => {},
  setIndexHydraulicActivitySelected: () => {},
  editHydraulicActivity: () => {},
  deleteHydraulicActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseHydraulicContext = () => useContext(ActivitiesContext);

export const HydraulicContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [hydraulicActivities, setActivity] = useState<
    activitiesHydraulicProps[]
  >(activityContextDefault.hydraulicActivities);
  const [indexHydraulicActivitySelected, setIndexHydraulicActivitySelected] =
    useState<number>(0);
  const [hydraulicActivityName, setHydraulicActivityName] =
    useState<string>("Água fria");

  const editHydraulicActivity = (
    index: number,
    activity: activitiesHydraulicProps
  ) => {
    let activitiesEdited = hydraulicActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteHydraulicActivity = (index: number) => {
    const activity = [...hydraulicActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewHydraulicActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: hydraulicActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Hidraulica",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        ColdWater: ColdWater,
        HotWater: HotWater,
        Sewer: Sewer,
        Gas: Gas,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        hydraulicActivities,
        hydraulicActivityName,
        indexHydraulicActivitySelected,
        setActivity,
        setHydraulicActivityName,
        setNewHydraulicActivity,
        editHydraulicActivity,
        deleteHydraulicActivity,
        setIndexHydraulicActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
