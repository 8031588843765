import styled from "styled-components";

interface buttonProps {
    backgroundColor: string
}

export const ContainerItensBudget = styled.div`
    padding:2rem;
    padding-top:0;
`

export const Button = styled.button<buttonProps>`
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    border: none;
    padding: 0.25rem;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    font-weight: 500;
    background-color: ${props => props.backgroundColor};

    img{
        width: 1.25rem;
    }
`

export const SectionItensBudget = styled.div`
    display:grid;
    background-color:transparent;;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    grid-gap:0.75rem;
    padding:0.5rem;

    p{
        text-align:center;
        font-weight:500;
        color: #000;
        font-size:1rem;
        padding:0.5rem;
    }

    .itemSectionName{
        text-align:left;
    }
`

export const ItemBudget = styled.div`
    display:grid;
    background-color:#fff;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    grid-gap:0.75rem;
    padding:0.75rem;
    margin-bottom:0.3rem;
    border-radius: 0.4rem;

    .groupContent{
        font-weight:400;
        color: #777777;
        div{
            margin-top:0.5rem;
        }
    }

    p{
        text-align:center;
        font-weight:400;
        color: #777777;
        font-size:1rem;
    }

    .itemName{
        text-align:left;
    }
`

export const SectionItensBudgetNewModel = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    grid-gap:0.75rem;
    background-color: transparent;

    p{
        font-weight:500;
        color: #000;
        font-size:1rem;
        padding-bottom: 0.2rem;
    }

    .itemSectionName{
        text-align:left;
    }
`

export const ItemBudgetNewModel = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    grid-gap:0.75rem;
    background-color: #fff;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 0.4rem;

    .groupContent{
        font-weight:400;
        color: #777777;
        div{
            margin-top:0.5rem;
        }
    }

    p{
        font-weight:400;
        color: #777777;
        font-size:1rem;
        
    }

    .itemName{
       text-align: left;
    }
`

export const MeansureOfItem = styled.p`
    
`


