import React from "react";

import DemolitionSendCotationAndReview from "./Demolition";
import MasonarySendCotationAndReview from "./Masonary";
import ImpermeabilizationSendCotationAndReview from "./Impermeabilization"
import ElectricSendCotationAndReview from "./Electric";
import HydraulicSendCotationAndReview from "./Hydraulic";
import AirConditioningSendCotationAndReview from "./AirConditioning";
import FloorsAndCoatingSendCotationAndReview from "./FloorsAndCoverings";
import LiningSendCotationAndReview from "./Lining";
import PaintSendCotationAndReview from "./Paint";
import TablewareAndMetalsSendCotationAndReview from "./TablewareAndMetals";
import RoofSendCotationAndReview from "./Roof";
import GlassworksSendCotationAndReview from "./Glassworks";
import MarbleWorkModifyActivity from "./Marblework";
import SawmillModifyActivities from "./Sawmill";
import WoodworkModifyActivities from "./Woodwork";
import DoorsAndWindowsModifyActivities from "./DoorsAndWindows";
import FramesModifyActivities from "./Frames";
import ProjectStepModifyActivities from "./ProjectStep";
import FurnitureModifyActivities from "./Furniture";
import CleaningModifyActivities from "./Cleaning";
import MovingModifyActivities from "./Moving";
import { activityProps } from "./ModifyActivityType"



const StepSelectForm: React.FC<activityProps> = (props) => {
    return (
        <>
            {props.activity[0].stepType === "Demolicao" ? (<DemolitionSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                props.activity[0].stepType === "Alvenaria" ? (<MasonarySendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                    props.activity[0].stepType === "Impermeabilizacao" ? (<ImpermeabilizationSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                        props.activity[0].stepType === "Eletrica" ? (<ElectricSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                            props.activity[0].stepType === "Hidraulica" ? (<HydraulicSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                props.activity[0].stepType === "Ar condicionado" ? (<AirConditioningSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                    props.activity[0].stepType === "Pisos e revestimento" ? (<FloorsAndCoatingSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                        props.activity[0].stepType === "Forro" ? (<LiningSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                            props.activity[0].stepType === "Pintura" ? (<PaintSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                props.activity[0].stepType === "Loucas e metais" ? (<TablewareAndMetalsSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                    props.activity[0].stepType === "Telhado" ? (<RoofSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                        props.activity[0].stepType === "Vidracaria" ? (<GlassworksSendCotationAndReview activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                            props.activity[0].stepType === "Marmoraria" ? (<MarbleWorkModifyActivity activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                props.activity[0].stepType === "Serralheria" ? (<SawmillModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                    props.activity[0].stepType === "Marcenaria" ? (<WoodworkModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                        props.activity[0].stepType === "Esquadrias" ? (<FramesModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                            props.activity[0].stepType === "Mobiliario" ? (<FurnitureModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                                props.activity[0].stepType === "Projeto" ? (<ProjectStepModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                                    props.activity[0].stepType === "Limpeza" ? (<CleaningModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                                        props.activity[0].stepType === "Mudança" ? (<MovingModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) :
                                                                                            props.activity[0].stepType === "Portas e janelas" ? (<DoorsAndWindowsModifyActivities activity={props.activity} activityType={props.activityType} endPointRequestApi={props.endPointRequestApi} ModifyActivityExibition={true} />) : (<></>)}
        </>
    )
}

export default StepSelectForm