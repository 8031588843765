import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";

import {
  UserProviderProps,
  espelhoProps,
} from "../../../types/typesEntitiesProject";

export interface activitiesGlassworkProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  /**Fechamento de sacada */
  formato_sacada: string;
  altura_sacada: string;
  largura_frente_sacada: string;
  largura_esquerda_sacada: string;
  largura_direita_sacada: string;
  tipo_vidro: string;
  modelo_ferragem: string;
  caixa_ar_condicionado: boolean;

  /**Box de banheiro */
  tipo_box: string;
  altura: string;
  comprimento: string;
  largura: string;
  formato_ferragem: string;
  material_estrutura: string;
  descricaoEstrutura: string;
  descricao_puxador: string;

  /**Porta de vidro e esquadrias */
  numero_folhas: string;
  altura_porta: string;
  comprimento_porta: string;
  material_ferragem: string;
  descricao: string;

  /**Espelhos */
  espelhos: espelhoProps[];

  cor_ferragem: string;
  cor_vidro: string;
  tipo_porta: string;
  porta_outros: string;
  material_acabamento_outros: string;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  glassworkActivities: activitiesGlassworkProps[];
  glassworkActivityName: string;
  indexGlassworkActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesGlassworkProps[]>
  >;
  setNewGlassworkActivity?: () => void;
  setGlassworkActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexGlassworkActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editGlassworkActivity?: (
    index: number,
    activity: activitiesGlassworkProps
  ) => void;
  deleteGlassworkActivity?: (index: number) => void;
}

const activityContextDefault = {
  glassworkActivities: [],
  glassworkActivityName: "",
  indexGlassworkActivitySelected: 0,
  setNewGlassworkActivity: () => {},
  setGlassworkActivityName: () => {},
  setIndexGlassworkActivitySelected: () => {},
  editGlassworkActivity: () => {},
  deleteGlassworkActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseGlassworkActivityContext = () => useContext(ActivitiesContext);

export const GlassworkActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [glassworkActivities, setActivity] = useState<
    activitiesGlassworkProps[]
  >(activityContextDefault.glassworkActivities);
  const [indexGlassworkActivitySelected, setIndexGlassworkActivitySelected] =
    useState<number>(0);
  const [glassworkActivityName, setGlassworkActivityName] =
    useState<string>("Box de banheiro");

  const editGlassworkActivity = (
    index: number,
    activity: activitiesGlassworkProps
  ) => {
    let activitiesEdited = glassworkActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteGlassworkActivity = (index: number) => {
    const activity = [...glassworkActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewGlassworkActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: glassworkActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Vidracaria",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        /**Fechamento de sacada */
        formato_sacada: "Reta",
        altura_sacada: "",
        largura_frente_sacada: "",
        largura_esquerda_sacada: "",
        largura_direita_sacada: "",
        tipo_vidro: "",
        modelo_ferragem: "",
        caixa_ar_condicionado: false,

        /**Box de banheiro */
        tipo_box: "",
        altura: "",
        comprimento: "",
        largura: "",
        formato_ferragem: "",
        material_estrutura: "",
        descricaoEstrutura: "",
        descricao_puxador: "",

        /**Porta de vidro e esquadrias */
        numero_folhas: "",
        altura_porta: "",
        comprimento_porta: "",
        material_ferragem: "",
        descricao: "",

        /**Espelhos */
        espelhos: [
          {
            tipo_espelho: "Retangular",
            cor_espelho: "Prata",
            altura_espelho: "",
            comprimento_espelho: "",
            acabamento_borda: "Bisotado",
            tipo_espelhos_outros: "",
            cor_espelho_outros: "",
            acabamento_borda_outros: "",
            quantidade: "",
          },
        ],

        cor_ferragem: "",
        cor_vidro: "",
        tipo_porta: "",
        porta_outros: "",
        material_acabamento_outros: "",
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        glassworkActivities,
        glassworkActivityName,
        indexGlassworkActivitySelected,
        setActivity,
        setGlassworkActivityName,
        setNewGlassworkActivity,
        editGlassworkActivity,
        deleteGlassworkActivity,
        setIndexGlassworkActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
