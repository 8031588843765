import React, { useEffect } from 'react'

import { UseHydraulicContext } from "../../../Context/ActivitiesContext/Hydraulic/HidraulicContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"

import { RequestApi } from "../RequestApi"

import ColdWater from '../../StepsForm/Hydraulic/ColdWater'
import Gas from '../../StepsForm/Hydraulic/Gas'
import HotWater from '../../StepsForm/Hydraulic/HotWater'
import Sewer from '../../StepsForm/Hydraulic/Sewer'

import { textButton } from '../script/textButtonSubmit'
import { Container } from "../style"
import MaterialsHydraulicStepForm from '../../StepsForm/Hydraulic/Materials'

const HydraulicModifyActivities: React.FC<activityProps> = (props) => {
    const { hydraulicActivities, setActivity, setIndexHydraulicActivitySelected } = UseHydraulicContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexHydraulicActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Agua fria" ? (<ColdWater stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Gas" ? (<Gas stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Agua quente" ? (<HotWater stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Esgoto" ? (<Sewer stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsHydraulicStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(hydraulicActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(hydraulicActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default HydraulicModifyActivities