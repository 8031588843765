import styled from "styled-components";

export const Th = styled.th`
    cursor: pointer;
`

export const MenuOverlay = styled.div`
    background-color: var(--dark-background);
    border-radius: 0.3rem;
    display: grid;
    position: absolute;
    right: 0;
    margin-right: 4rem;
    color: #ffff;
    padding: 0.2rem;
    font-size:0.85rem;
    text-align:center;

    .optionsPermission{
        display: grid;
        justify-items: center;
    }
`

