import React, { useState } from "react";

import { PlantProps } from "../../../types/typesEntitiesProject";
import { Button, Tr } from "./style";
import { Th, MenuOverlay } from "./style";
import { OptionButton } from "../../../components/stylesDomElements/htmlStyledElements"

import pointsMenu from "../../../assets/pointsMenu.svg";
import axios from "axios";

const MultipleSelected: React.FC<PlantProps> = (props) => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
  
  const SelectArchives = () => {
    setShowDownloadButton(true)
  }

  return (
    <>

      <Tr>

        <th>{props.archiveName}</th>
        <th>{props.type}</th>
        <th>{props.size}</th>
        <th>{props.modification}</th>
        <Th onClick={() => setVisibleMenu(!visibleMenu)}>
          {/* <Button onClick={() => setVisibleMenu(!visibleMenu)}><a href={props.archiveUrl} download>Baixar arquivo</a></Button> */}
          <input type='checkbox' onChange={() => SelectArchives()} value={props.archiveUrl}></input>
        </Th>
      </Tr>
    </>
  );
};

export default MultipleSelected;
