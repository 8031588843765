import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ItemContentListPassageOfInfra,
  ListContainerItensPassageOfInfra,
  ListContainerItensConduite,
  ItemContentListConduite,
} from "./style";

import { SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { PassageOfInfraFormType } from "../../../../Models/Eletric/type";

import FooterBudget from "../../Components/FooterBudget"
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  PassageOfInfra: PassageOfInfraFormType
}

const PassagemDeInfraBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [conduite, setConduite] = useState<string[]>(["0,00"])
  const [novosInterruptores, setNovosInterruptores] = useState<string[]>(["0,00"])
  const [novasTomadas, setNovasTomadas] = useState<string[]>(["0,00"])
  const [novosPontosDeIluminacao, setNovosPontosDeIluminacao] = useState<string[]>(["0,00"])

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.PassageOfInfra.Conduite.forEach(() => {
      setConduite((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.PassageOfInfra.NovosInterruptores?.forEach(() => {
      setNovosInterruptores((prev) => [...prev, "0,00"])
    })

    activity.PassageOfInfra.NovasTomadas?.forEach(() => {
      setNovasTomadas((prev) => [...prev, "0,00"])
    })

    activity.PassageOfInfra.NovosPontosDeIluminacao?.forEach(() => {
      setNovosPontosDeIluminacao((prev) => [...prev, "0,00"])
    })

  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const conduiteTotal = sum(conduite)
    const novosInterruptoresTotal = sum(novosInterruptores)
    const novasTomadasTotal = sum(novasTomadas)
    const novosPontosDeIluminacaoTotal = sum(novosPontosDeIluminacao)
    setTotalBudgetPayment(sum([conduiteTotal, novosInterruptoresTotal, novasTomadasTotal, novosPontosDeIluminacaoTotal]))
  }, [conduite, novosInterruptores, novasTomadas, novosPontosDeIluminacao])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const conduiteTotal = sum(conduite)
  const novosInterruptoresTotal = sum(novosInterruptores)
  const novasTomadasTotal = sum(novasTomadas)
  const novosPontosDeIluminacaoTotal = sum(novosPontosDeIluminacao)
  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([conduiteTotal, novosInterruptoresTotal, novasTomadasTotal, novosPontosDeIluminacaoTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const conduiteMargin: number[] = []
    const novosInterruptoresMargin: number[] = []
    const novasTomadasMargin: number[] = []
    const novosPontosDeIluminacaoMargin: number[] = []


    conduite.forEach((item) => {
      if (item != "0,00") {
        conduiteMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    novosInterruptores.forEach((item) => {
      if (item != "0,00") {
        novosInterruptoresMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    novasTomadas.forEach((item) => {
      if (item != "0,00") {
        novasTomadasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    novosPontosDeIluminacao.forEach((item) => {
      if (item != "0,00") {
        novosPontosDeIluminacaoMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Conduite: conduiteMargin,
          NovosInterruptores: novosInterruptoresMargin,
          NovasTomadas: novasTomadasMargin,
          NovosPontosDeIluminacao: novosPontosDeIluminacaoMargin
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensConduite>

        {activity.PassageOfInfra.Conduite?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.Conduite.length > 1 ? (<P>Conduíte {index + 1}</P>) : (<P>Conduíte</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListConduite >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, conduite, setConduite, e.target.value)} />
              </ContentInputPrice>
              {conduite && conduite[index] !== '0' ?
                (<p className="item">{conduite[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListConduite>
          </div>
        ))}

      </ListContainerItensConduite>

      <ListContainerItensPassageOfInfra>
        {activity.PassageOfInfra.NovosInterruptores?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.NovosInterruptores.length > 1 ? (<P>Novos interruptores {index + 1}</P>) : (<P>Novos interruptores</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListPassageOfInfra >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, novosInterruptores, setNovosInterruptores, e.target.value)} />
              </ContentInputPrice>
              {novosInterruptores && novosInterruptores[index] !== '0' ?
                (<p className="item">{novosInterruptores[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListPassageOfInfra>
          </div>
        ))}
      </ListContainerItensPassageOfInfra>

      <ListContainerItensPassageOfInfra>
        {activity.PassageOfInfra.NovasTomadas?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.NovasTomadas.length > 1 ? (<P>NovasTomadas {index + 1}</P>) : (<P>NovasTomadas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListPassageOfInfra >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, novasTomadas, setNovasTomadas, e.target.value)} />
              </ContentInputPrice>
              {novasTomadas && novasTomadas[index] !== '0' ?
                (<p className="item">{novasTomadas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListPassageOfInfra>
          </div>
        ))}
      </ListContainerItensPassageOfInfra>


      <ListContainerItensPassageOfInfra>
        {activity.PassageOfInfra.NovosPontosDeIluminacao?.map((item, index) => (
          <div key={index}>
            {activity.PassageOfInfra.NovosPontosDeIluminacao.length > 1 ? (<P>Novos pontos de iluminação {index + 1}</P>) : (<P>Novos pontos de iluminação</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListPassageOfInfra >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, novosPontosDeIluminacao, setNovosPontosDeIluminacao, e.target.value)} />
              </ContentInputPrice>
              {novosPontosDeIluminacao && novosPontosDeIluminacao[index] !== '0' ?
                (<p className="item">{novosPontosDeIluminacao[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListPassageOfInfra>
          </div>
        ))}
      </ListContainerItensPassageOfInfra>


      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default PassagemDeInfraBudget;