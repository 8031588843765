import React from "react"

import { ErrContainer } from "./style"

interface errLoginProps {
    errStatus: number
}

export const ErrLogin: React.FC<errLoginProps> = (props) => {
    return (
        <div>
            {props.errStatus === 404 ? (
                <ErrContainer>
                    Email e/ou senha inválidos
                </ErrContainer>
            ) : (<></>)}
        </div>
    )
}

export default ErrLogin