export const Frames = {
    Portas: [
        {
            attributes: [
                {
                    itemName: 'Linha',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Observações',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
    Janelas: [
        {
            attributes: [
                {
                    itemName: 'Linha',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Observações',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
    Divisorias: [
        {
            attributes: [
                {
                    itemName: 'Linha',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Altura',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Largura',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Observações',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}
