import styled from "styled-components";

export const Container = styled.div`
  padding: 1.75rem;

  .flexElements {
    display: flex;
    align-items: center;

    .button {
      margin-top: 1rem;
      margin-left: 1rem;
      p {
        font-size: 1rem;
        @media (max-width: 480px) {
          font-size: 2rem;
        }
      }

      @media (max-width: 480px) {
        width: 15rem;
        height: 8rem;
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 5rem;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 83vh;
`;

export const ContainerInput = styled.div`
  width: 60rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.3rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  input {
    border: none;
    width: 100%;
    background-color: #e1e1e1;
    padding: 0.5rem;
    border-radius: 0.3rem;

    @media (max-width: 480px) {
      height: 8rem;
      font-size: 3rem;
    }
  }
`;

export const Component = styled.div`
  @media (max-width: 480px) {
    .toCellPhoneVisualization {
      width: 152em;
      display: grid;
      align-items: center;
      justify-content: center;
      button {
        font-size: 5rem;
        padding: 7rem;
      }
    }
  }

  @media (max-width: 820px) {
    width: 78rem;
  }
`;

export const Page = styled.div`
  touch-action: manipulation;
  @media (max-width: 480px) {
    max-width: 152em;
    -webkit-transform: scale(0.17);
    -webkit-transform-origin: 0 0;
  }
`;
