import React, { useState } from "react"
import { shelf } from "../../../../types/typesEntitiesProject";

import { stateType } from "../../../SelectionBudget/type";
interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    prateleira?: shelf[]
}
const ShelfDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);
    return (
        <div>
            {activity.prateleira ? (
                activity.prateleira.map((element, index) =>
                    <>
                        <p className="bold">Pratileira {index + 1}</p>
                        {element?.largura_shelf ? (
                            <div className="line">
                                <p>Largura</p>
                                <p>
                                    {element.largura_shelf} cm
                                </p>
                            </div>
                        ) : null}

                        {element?.comprimento_shelf ? (
                            <div className="line">
                                <p>Comprimento</p>
                                <p>
                                    {element.comprimento_shelf} cm
                                </p>
                            </div>
                        ) : null}

                        {element?.altura_shelf ? (
                            <div className="line">
                                <p>Altura</p>
                                <p>
                                    {element.altura_shelf} cm
                                </p>
                            </div>
                        ) : null}

                        {element?.material_shelf ? (
                            <div className="line">
                                <p>Material</p>
                                <p>
                                    {element.material_shelf}
                                </p>
                            </div>
                        ) : null}
                        <br />
                    </>
                )
            ) : (<></>)}
        </div>
    );
};


export default ShelfDescription