import React, { useEffect, useState } from "react"
import axios from "axios";
import { UseFetch } from "./useFetch";

import { allActivitiesProps, BudgetsProps, SupplierProps, stepsShowElementsProps } from "../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../Config/dictionary"
import { UseModalContext } from "../../Context/ModalContext";

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";

import DropDown from "../../components/DropDownElement";

import TopElementItensBudgets from "./Components/TopElementItensBudgets";
import Comparatives from "./Comparatives";
import SimpleSupplier from "./Components/SimpleSuppplier";
import ConstructorsAndWorkers from "./Components/ConstructorsAndWorkers";
import PaginationContent from "./Components/PaginationContent";

import { ContainerSearch, DropDownElement } from "../../components/stylesDomElements/htmlStyledElements"

import {
    CotentItemStep,
    BudgetDetails,
    StepBudgetContainer,
    ContainerItensBudget,
    ActionsBudgetContainer,
    Footer,
    ContainerComparative
} from "./style"

/**Scripts */
import { changeExpandStepBudgets } from "./scripts/changeExpandStepBudgets";
import { totalGeneralStepsBudgets } from "./scripts/totalGeneralStepsBudgets";
import { suppliersOrder } from "./scripts/suppliersOrder";

import { primaryColor } from "../../Config/colorConfig";

import searchIcon from "../../assets/searchIcon.svg"
import seachBlackIcon from "../../assets/lupaBlackIcon.svg"
import dropDowIcon from "../../assets/dropDown.svg"
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import AcessMenu from "../../components/AcessMenu";
import { UseUserContext } from "../../Context/UserContext";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";
interface stepProps {
    _id: string;
    clientId: string;
    name: string;
    percentage: number;
    startDate: string;
    endDate: string;
}

interface materialsDetailmentProps {
    _id: string,
    projectId: string,
    description: string,
    supplierId: string,
}

const CotationMap: React.FC = () => {
    const { acessLink } = UseAuthAcessContext()
    const { setShowModal } = UseModalContext();
    const { setprojectSelected } = UseUserContext()
    const [projectSelected] = useState<any>(localStorage.getItem("projectId"))
    const { data: activities } = UseFetch<allActivitiesProps[]>(`${process.env.REACT_APP_BASE_URL}/activitiesProject/${projectSelected}`)
    const [budgets, setBudgets] = useState<BudgetsProps[]>([])
    const [suppliers, setSuppliers] = useState<SupplierProps[]>([])

    const [materialsDetailment, setMaterialsDetailment] = useState<materialsDetailmentProps[]>([])

    const [steps, setSteps] = useState<stepProps[]>([])
    const [activitySelected, setActivitySelected] = useState<allActivitiesProps>()
    const [activityComparativeSelected, setActivityComparativeSelected] = useState<allActivitiesProps>()
    const [scaleY, setScaleY] = useState<number>(1);

    const [stepsShowElements, setStepsShowElements] = useState<stepsShowElementsProps[]>([])
    const [intervalShowSuppliers, setIntervalShowSuppliers] = useState<number>(5)
    
    useEffect(() => {
        if (acessLink) {
          const projectOfLink = verifyTypeProjectExibition();
          if (projectOfLink && setprojectSelected) {
            setprojectSelected(projectOfLink);
          }
        } else {
          const projectId = localStorage.getItem("projectId");
          if (projectId && typeof setprojectSelected !== 'undefined') {
            setprojectSelected(projectId);
          }
        }
      }, []);

    useEffect(() => {
        if (projectSelected) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/listSuppliersBudgetsProject/${projectSelected}`).then(res => {
                setSuppliers(suppliersOrder(intervalShowSuppliers, res.data, budgets))
            })
        }
    }, [projectSelected, acessLink])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/steps/${localStorage.getItem("projectId")}`).then(res => {
            setSteps(res.data)
        })
    }, [projectSelected, acessLink])

    useEffect(() => {
        if (projectSelected) {

            axios.get(`${process.env.REACT_APP_BASE_URL}/listBudgetsProject/${projectSelected}`).then(res => {
                setBudgets(res.data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectSelected, acessLink])

    useEffect(() => {
        if (projectSelected) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/listMaterialDetailmentProject/${projectSelected}`).then(res => {
                setMaterialsDetailment(res.data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectSelected, acessLink])

    return (
        <div>
            <Header />

            {!acessLink ? (
                <ItensMenu optionSelected="Mapa cotacao" />
            ) : (
                <AcessMenu />
            )}

            {!activityComparativeSelected ? (
                <ContainerItensBudget>
                    {/**Header de exibição */}
                    <TopElementItensBudgets suppliers={suppliers} />

                    <div>
                        {steps?.map((step, indexStep) => (
                            activities?.filter(activity => activity.stepId === step._id).length > 0 ? (
                                <StepBudgetContainer trBorderColor={primaryColor[indexStep]} key={indexStep} >
                                    <div className="gridBudgetContainer">
                                        {/**Nome da etapa e Atividade*/}
                                        <CotentItemStep>
                                            <p className="itemName">{step.name}</p>
                                            {stepsShowElements.filter(stepShow => stepShow.stepId === step._id).length > 0 ? (
                                                activities?.map((activity) => (
                                                    activity.stepId === step._id ? (
                                                        <p className="itemName">{activity.activityName}</p>
                                                    ) : (<></>)
                                                ))
                                            ) : (<></>)}
                                        </CotentItemStep>

                                        {/**Coluna Fornecedores Avulsos */}
                                        <SimpleSupplier
                                            activities={activities}
                                            budgets={budgets}
                                            setActivitySelected={setActivitySelected}
                                            setShowModal={setShowModal}
                                            step={step}
                                            stepsShowElements={stepsShowElements}
                                            suppliers={suppliers}
                                            key={indexStep}
                                        />

                                        {/**Coluna Construtora/Empreiteiro */}
                                        <ConstructorsAndWorkers
                                            activities={activities}
                                            budgets={budgets}
                                            step={step}
                                            stepsShowElements={stepsShowElements}
                                            suppliers={suppliers}
                                            key={indexStep + 1}
                                        />
                                    </div>
                                    <ActionsBudgetContainer>
                                        {/**Icone DropDown orçamentos da etapa */}
                                        <div className="detailsContainer">
                                            {
                                                activities.map((activity) => (
                                                    activity.stepId === step._id ? (
                                                        stepsShowElements.filter(stepShow => stepShow.stepId === step._id).length > 0 ? (
                                                            <p className="detailElement">
                                                                <BudgetDetails onClick={() => setActivityComparativeSelected({ ...activity })}>
                                                                    <img src={seachBlackIcon} alt="icone Lupa" />
                                                                </BudgetDetails>
                                                            </p>
                                                        ) : (<></>)
                                                    ) : ('')
                                                ))
                                            }
                                        </div>
                                        <DropDown scaleY={1} function={() => changeExpandStepBudgets(stepsShowElements, setStepsShowElements, scaleY, setScaleY, step._id)} />
                                    </ActionsBudgetContainer>
                                </StepBudgetContainer>
                            ) : (<></>)
                        ))}
                    </div>
                    {/**footer */}
                    <Footer>
                        <div className="footerContentTable">
                            <CotentItemStep><p className="itemFooterDescrible">Total</p></CotentItemStep>
                            <CotentItemStep>{totalGeneralStepsBudgets(budgets, suppliers, simpleSupplier)}</CotentItemStep>
                            {suppliers?.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                totalGeneralStepsBudgets(budgets, suppliers, supplier.typeSupplier || '', supplier._id)
                            ))}
                        </div>
                        <div className="footerContentTable">
                            <CotentItemStep><p className="itemFooterDescrible">Materiais</p></CotentItemStep>
                            <CotentItemStep><p>-</p></CotentItemStep>
                            {suppliers?.map((supplier, index) => (
                                supplier.typeSupplier === constructor || supplier.typeSupplier === worker ? (
                                    <CotentItemStep>
                                        <p>{materialsDetailment.find(material => material.supplierId === supplier._id)?.description}</p>
                                    </CotentItemStep>

                                ) : (null)
                            ))}
                        </div>
                    </Footer>
                    <PaginationContent suppliers={suppliers} intervalShowSuppliers={intervalShowSuppliers} setIntervalShowSuppliers={setIntervalShowSuppliers} />
                </ContainerItensBudget>
            ) : (
                budgets &&
                    suppliers &&
                    activityComparativeSelected._id &&
                    activityComparativeSelected.stepType ?
                    (<Comparatives
                        comparativeScreenConfigure="comparative visualization"
                        budgetSuppliersInformation={true}
                        trBorderColor={primaryColor[steps.findIndex(stepFind => stepFind._id === activityComparativeSelected.stepId)]}
                        setActivityComparativeSelected={setActivityComparativeSelected}
                        activity={activityComparativeSelected}
                        activityId={activityComparativeSelected._id}
                        budgets={budgets}
                        suppliers={suppliers}
                    />
                    ) :
                    (<></>)
            )}
        </div >
    )
}

export default CotationMap