import styled from "styled-components";

interface gridTemplateColumsProps {
  columsRepeat: number;
  width: string;
}

interface dependenciesProps {
  xAxis: number;
  yAxis: number;
}
interface liProps {
  backgroundColor?: string;
  gridColum?: string;
  gridRow?: number;
  width?: number;
  border?: string;
  isEditAuthorized: boolean;
}

interface GanttRowProps {
  width: string;
}

export const GanttContainer = styled.div`
  display: grid;
  padding: 2rem;
  padding-top: 0;
  position: relative;
  border: 0;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 820px) {
    width: 77rem;
  }

  @media (max-width: 480px) {
    width: 148rem;
  }
`;

export const GanttRowDays = styled.div<gridTemplateColumsProps>`
  display: grid;
  grid-template-columns: ${(props) => props.width} repeat(
      ${(props) => props.columsRepeat},
      1fr
    );
  background-color: transparent;

  @media (max-width: 820px) {
    margin-left: 8.7rem;
  }

  @media (max-width: 480px) {
    margin-left: 46rem;
  }

  span {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @media (max-width: 480px) {
      border-bottom: 4px solid rgba(0, 0, 0, 0.1);
    }
    font-weight: 400;
  }
`;

export const GanttColummSeparate = styled.div<gridTemplateColumsProps>`
  display: grid;
  position: absolute;
  height: 100%;
  width: 100%;
  grid-template-columns: ${(props) => props.width} repeat(
      ${(props) => props.columsRepeat},
      1fr
    );
  padding-left: 2rem;
  padding-right: 2rem;
  span {
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    @media (max-width: 480px) {
      border-right: 4px solid rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 820px) {
    margin-left: 8.7rem;
  }
  @media (max-width: 480px) {
    margin-left: 46rem;
  }
`;

export const GanttRow = styled.div<GanttRowProps>`
  display: grid;
  grid-template-columns: ${(props) => props.width} 1fr;

  @media (max-width: 820px) {
    z-index: 1;
  }
`;

export const GantRowBars = styled.ul<gridTemplateColumsProps>`
  list-style: none;
  display: grid;
  margin: 0;
  grid-template-columns: repeat(${(props) => props.columsRepeat}, 1fr);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (max-width: 480px) {
    border-bottom: 4px solid rgba(0, 0, 0, 0.1);
  }

  padding-bottom: 0.75rem;

  .noVisisbleStepGantt {
    margin-bottom: 1.75rem;
  }

  @media (max-width: 820px) {
    margin-left: 8.7rem;
  }
  @media (max-width: 480px) {
    margin-left: 46rem;
  }
`;
export const Li = styled.li<liProps>`
  width: ${(props) => props.width};
  margin-top: 1.25rem;
  height: 2.75vh;
  overflow: hidden;
  position: relative;
  cursor: ${(props) => (props.isEditAuthorized ? "pointer" : "not-allowed")};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  border: ${(props) => props.border};

  grid-column: ${(props) => props.gridColum};
  grid-row: ${(props) => props.gridRow};
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 480px) {
    height: 8rem;
  }
`;

export const ActivityMaterialsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GanttPeriodDescript = styled.div`
  color: #a5a5a5;
  font-size: 0.74rem;
  border-radius: 0.3rem;
`;

export const DescriptionInfoCronogram = styled.div`
  width: 33rem;
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr;
  text-align: center;
  font-size: 0.85rem;
  grid-gap: 0.5rem;
`;

export const MenuOverlay = styled.div<dependenciesProps>`
  background-color: var(--dark-background);
  border-radius: 0.5rem;
  display: grid;
  position: fixed;
  z-index: 3;
  left: ${(props) => props.xAxis}px;
  top: ${(props) => props.yAxis}px;
`;

export const OptionButton = styled.button`
  color: var(--primary-color);
  font-weight: 400;
  margin: 0.5rem;
  border: none;
  background-color: transparent;
  font-size: 0.85rem;
  cursor: pointer;
  a {
    color: var(--primary-color);
  }
`;
