import React from "react";

import { UseModalContext } from "../../Context/ModalContext";

import {
  ContainerModal,
  FormContainer,
  HeaderFormContainer,
} from "../../components/stylesDomElements/htmlStyledElements";

import XIcon from "../../assets/XIcon.svg";

interface ModalProps {
  titleForm: string;
  removeCloseButton?: boolean;
  children: any;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { showModal, setShowModal } = UseModalContext();
  const hanndleModal = () => {
    setShowModal?.();
  };
  return (
    <>
      {showModal ? (
        <ContainerModal>
          <FormContainer>
            <HeaderFormContainer>
              <p>{props.titleForm}</p>
              <div>
                {props.removeCloseButton ? (
                  <></>
                ) : (
                  <img
                    src={XIcon}
                    alt="Icone de fachar"
                    onClick={() => hanndleModal()}
                  />
                )}
              </div>
            </HeaderFormContainer>
            <div className="form">{props.children}</div>
          </FormContainer>
        </ContainerModal>
      ) : null}
    </>
  );
};

export default Modal;
