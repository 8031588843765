import React, { useEffect, useState } from "react"
import { UseCleaningActivityContext } from "../../../Context/ActivitiesContext/Cleaning/CleaningContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";
import { textTransform } from "../script"

const CleaningController: React.FC = () => {
  const { activitySelected, setActivitySelected } = UseStepContext()
  const { cleaningActivities, editCleaningActivity } = UseCleaningActivityContext()

  useEffect(() => {
    setActivitySelected?.('Limpeza')
  }, [])

  useEffect(() => {
    if (cleaningActivities.length > 0) {
      var activityEdited = cleaningActivities[0]
      activityEdited.activityType = activitySelected
      activityEdited.activityName = textTransform(activitySelected)
      editCleaningActivity?.(0, activityEdited)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySelected])

  /* useEffect(() => {
      if (masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType !== "") {
          setOptionSelected(masonaryActivities[props.indexSelected].activityType)
      }
      masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType === "" ? (setOptionSelected('Fundacao')) : (<></>)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indexSelected]) */

  return <div>
    <ActionSelect>
      Atividade:
      <select value={activitySelected} name="CleaningStep" id="CleaningStep" onChange={(e) => setActivitySelected(e.target.value)}>
        <option value="Limpeza">Limpeza</option>
        <option value="Materiais para limpeza">Materiais</option>
      </select>
    </ActionSelect>
  </div>
}

export default CleaningController