import React, { useState } from "react";

import {
  BudgetsProps,
  activitiesProps,
  SupplierProps,
  marble,
} from "../../../../../types/typesEntitiesProject";
import {
  simpleSupplier,
  constructor,
  worker,
} from "../../../../../Config/dictionary";
import {
  ContainerItensBudget,
  SectionItensBudget,
  ItemBudget,
} from "../../style";
import { GreenDestack } from "../../../style";

interface budgetProps {
  budget: BudgetsProps[];
  activity: activitiesProps;
  destackMinItemValue: boolean;
  suppliers: SupplierProps[];
}

interface activityProps {
  selectedBudget?: boolean;
  marble?: marble[];
}

interface budgetComparartiveProps {
  _id?: string;
  activityId?: string;
  providerId?: string;
  typeSupplier?: string;
  selectedBudget?: boolean;
  totalBudgetPayment: string;

  budget?: {
    marble?: string[];
  };
}

export const TentoSoleiraBagueteRodabaseComparartive: React.FC<budgetProps> = (
  props
) => {
  const [budgets] = useState<budgetComparartiveProps[]>(props.budget);
  const [activity] = useState<activityProps>(props.activity);

  const renderItemBudget = (
    totalItem: string,
    activityId?: string,
    itemType?: any
  ) => {
    var budgetsFilter = budgets?.filter(
      (budget) => budget.activityId === activityId
    );

    var totalBudgetsList: number[] = [];
    /**Identifica o tipo de item para calcular o menor valor total */
    itemType === "marble" ? (
      budgetsFilter.forEach((budget, index) => {
        budget.budget?.marble ? (
          totalBudgetsList.push(parseFloat(budget.budget?.marble[index]))
        ) : (
          <></>
        );
      })
    ) : (
      <></>
    );

    var minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
      return Math.min(a, b);
    });

    if (!totalItem) {
      return <p>R$0,00</p>;
    }

    if (
      parseFloat(totalItem) === minValueSelected &&
      props.destackMinItemValue
    ) {
      return (
        <p>
          <GreenDestack>R${totalItem}</GreenDestack>
        </p>
      );
    } else return <p>R${totalItem}</p>;
  };

  return (
    <ContainerItensBudget>
      {budgets ? (
        <>
          <SectionItensBudget>
            <p className="itemSectionName">Itens</p>
            <p>Medidas</p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
            <p> </p>
          </SectionItensBudget>

          {activity.marble?.map((element, index) => (
            <ItemBudget>
              {element.acabamento_pedra ? (
                <p className="itemName">
                  Pedra de {element.pedra} <br /> Acabamento:{" "}
                  {element.acabamento_pedra}, {element.cor_pedra}
                </p>
              ) : (
                <p className="itemName">Pedra de {element.pedra}</p>
              )}
              <p>
                {String(element.altura_item_revestido)}m X{" "}
                {String(element.largura_item_revestido)}m X{" "}
                {String(element.profundidade_item_revestido)}m
              </p>
              {props.suppliers ? (
                <>
                  {/**Item orçado pelo Fornecedor avulso */}
                  {props.suppliers
                    .filter(
                      (supplier) => supplier.typeSupplier === simpleSupplier
                    )
                    .map((supplier) =>
                      budgets
                        .filter(
                          (budget) =>
                            budget.typeSupplier === simpleSupplier &&
                            budget.providerId === supplier._id
                        )
                        .map((budget) =>
                          budget.budget?.marble
                            ? renderItemBudget(
                                budget.budget?.marble[index],
                                budget.activityId,
                                "marble"
                              )
                            : ""
                        )
                    )}
                  {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                  {props.suppliers
                    .filter(
                      (supplier) =>
                        supplier.typeSupplier === constructor ||
                        supplier.typeSupplier === worker
                    )
                    .map((supplier) =>
                      budgets
                        .filter(
                          (budget) =>
                            budget.typeSupplier === constructor ||
                            (budget.typeSupplier === worker &&
                              budget.providerId === supplier._id)
                        )
                        .map((budget) =>
                          budget.budget?.marble
                            ? renderItemBudget(
                                budget.budget?.marble[index],
                                budget.activityId,
                                "marble"
                              )
                            : ""
                        )
                    )}
                </>
              ) : (
                <></>
              )}
            </ItemBudget>
          ))}
        </>
      ) : (
        <></>
      )}
    </ContainerItensBudget>
  );
};

export default TentoSoleiraBagueteRodabaseComparartive;
