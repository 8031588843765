import styled from "styled-components";
interface ButtonOptionalProps {
  borderColor: string;
  hoverColor: string;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1.1fr 1fr;

  @media (max-width: 480px) {
    background-color: #ffffff;
  }
`;

export const ImageContainer = styled.div`
  img {
    width: 100%;
    height: 100%;
    @media (max-width: 480px) {
      display: none;
    }
  }
`;

export const CredentialsContainer = styled.div`
  background-color: var(--primary-color);
  display: grid;
  grid-template-rows: 1fr 0.4fr 0.6fr;
  justify-content: center;
  padding: 1rem;
`;

export const ButtonLoginActived = styled.button`
  background-color: var(--primary-color);
  color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  height: 50%;
  border: 1px solid var(--secondary-color);
  margin-bottom: 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  padding: 0.2rem;
  :hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }

  .linkRedirect {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  cursor: pointer;
`;

export const ButtonLoginNoActived = styled.button`
  background-color: #e8e8e8;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  height: 50%;
  border: none;
  margin-bottom: 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  padding: 0.2rem;

  .linkRedirect {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  cursor: no-drop;
`;

export const LoginArea = styled.div`
  .logoArquitetool {
    width: 10rem;
    margin-bottom: 0.5rem;
  }
  display: grid;
  align-content: center;

  div {
    margin-top: 1rem;
    display: grid;

    @media (max-width: 480px) {
      width: 22.5rem;
    }
  }

  #email {
    margin-top: 1rem;
    padding: 0.7rem;
    @media (max-width: 480px) {
      width: 22.5rem;
    }
  }

  input {
    padding: 0.5rem;
    width: 25rem;
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
  }

  p {
    cursor: pointer;
    text-decoration: underline;
    margin-top: 1rem;
  }

  .passwordContent {
    display: flex;
    align-items: center;
    padding: 0.2rem;
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
    input {
      background-color: transparent;
      border: none;
      width: 90%;
    }
  }

  .visiblePassword {
    cursor: pointer;
  }
`;

export const ButtonOptional = styled.a<ButtonOptionalProps>`
  border: 1px solid ${(props) => props.borderColor};
  margin-top: 0.5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.3rem;
  color: #000;
  .googleIcon {
    width: 1rem;
    margin-right: 0.5rem;
  }
  .facebookIcon {
    width: 0.55rem;
    margin-right: 0.5rem;
  }
  :hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

export const FormContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;
  z-index: 1;

  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const HeaderFormContainer = styled.div`
  padding: 1rem;
  display: flex;
  box-shadow: 0px 1px 0px #f1f2f3;
  justify-content: right;

  p {
    margin-right: 2.5rem;
  }

  img {
    cursor: pointer;
    margin: 0.1rem;
  }
`;
