import React, { useContext, useState } from "react";

import { ForroFormType } from "../../../Models/Lining/type";
import { Forro } from "../../../Models/Lining/LiningModel";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesLiningProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Forro: ForroFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  liningActivities: activitiesLiningProps[];
  liningActivityName: string;
  indexLiningActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesLiningProps[]>>;
  setNewLiningActivity?: () => void;
  setLiningActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexLiningActivitySelected?: React.Dispatch<React.SetStateAction<number>>;
  editLiningActivity?: (index: number, activity: activitiesLiningProps) => void;
  deleteLiningActivity?: (index: number) => void;
}

const activityContextDefault = {
  liningActivities: [],
  liningActivityName: "",
  indexLiningActivitySelected: 0,
  setNewLiningActivity: () => {},
  setLiningActivityName: () => {},
  setIndexLiningActivitySelected: () => {},
  editLiningActivity: () => {},
  deleteLiningActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseLiningActivityContext = () => useContext(ActivitiesContext);

export const LiningActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [liningActivities, setActivity] = useState<activitiesLiningProps[]>(
    activityContextDefault.liningActivities
  );
  const [indexLiningActivitySelected, setIndexLiningActivitySelected] =
    useState<number>(0);
  const [liningActivityName, setLiningActivityName] = useState<string>("Forro");

  const editLiningActivity = (
    index: number,
    activity: activitiesLiningProps
  ) => {
    let activitiesEdited = liningActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteLiningActivity = (index: number) => {
    const activity = [...liningActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewLiningActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: liningActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Forro",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,
        Forro: Forro,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        liningActivities,
        liningActivityName,
        indexLiningActivitySelected,
        setActivity,
        setLiningActivityName,
        setNewLiningActivity,
        editLiningActivity,
        deleteLiningActivity,
        setIndexLiningActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
