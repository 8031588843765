import React, { useEffect } from 'react'

import { UseGlassworkActivityContext } from "../../../Context/ActivitiesContext/Glasswork/GlassworkContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"

import { RequestApi } from "../RequestApi"

import BalconyClosing from '../../StepsForm/Glassworks/BalconyClosing'
import BathroomBox from '../../StepsForm/Glassworks/BathroomBox'
import GlassDoor from '../../StepsForm/Glassworks/GlassDoor'
import Mirror from '../../StepsForm/Glassworks/Mirror'

import { textButton } from '../script/textButtonSubmit'
import { Container } from "../style"
import MaterialsGlassworksStepForm from '../../StepsForm/Glassworks/Materials'

const GlassworksModifyActivities: React.FC<activityProps> = (props) => {
    const { glassworkActivities, setActivity, setIndexGlassworkActivitySelected } = UseGlassworkActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexGlassworkActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Box de banheiro" ? (<BathroomBox />) : (<></>)}
            {props.activityType === "Fechamento de sacada" ? (<BalconyClosing />) : (<></>)}
            {props.activityType === "Porta de vidro e esquadrias" ? (<GlassDoor />) : (<></>)}
            {props.activityType === "Espelhos" ? (<Mirror />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsGlassworksStepForm />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(glassworkActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(glassworkActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default GlassworksModifyActivities