import React, { useContext, useState } from "react";

import { textTransform } from "../../../components/StepsForm/script";
import { AirConditioner } from "../../../Models/AirConditioner/AirConditionerModel";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { AirConditionerFormType } from "../../../Models/AirConditioner/type";
import { MaterialsFormType } from "../../../Models/attributeType";
import { UserProviderProps } from "../../../types/typesEntitiesProject";
export interface activitiesAirConditionerProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  AirConditioner: AirConditionerFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  airConditionerActivities: activitiesAirConditionerProps[];
  airConditionerActivityName: string;
  indexAirConditionerActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesAirConditionerProps[]>
  >;
  setNewAirConditionerActivity?: () => void;
  setAirConditionerActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexAirConditionerActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editAirConditionerActivity?: (
    index: number,
    activity: activitiesAirConditionerProps
  ) => void;
  deleteAirConditionerActivity?: (index: number) => void;
}

const activityContextDefault = {
  airConditionerActivities: [],
  airConditionerActivityName: "",
  indexAirConditionerActivitySelected: 0,
  setActivity: () => {},
  setNewAirConditionerActivity: () => {},
  setAirConditionerActivityName: () => {},
  setIndexAirConditionerActivitySelected: () => {},
  editAirConditionerActivity: () => {},
  deleteAirConditionerActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseAirConditionerActivityContext = () =>
  useContext(ActivitiesContext);

export const AirConditionerActivityContextProvider: React.FC<
  UserProviderProps
> = ({ children }) => {
  const [airConditionerActivities, setActivity] = useState<
    activitiesAirConditionerProps[]
  >(activityContextDefault.airConditionerActivities);
  const [
    indexAirConditionerActivitySelected,
    setIndexAirConditionerActivitySelected,
  ] = useState<number>(0);
  const [airConditionerActivityName, setAirConditionerActivityName] =
    useState<string>(textTransform("Ar condicionado"));

  const editAirConditionerActivity = (
    index: number,
    activity: activitiesAirConditionerProps
  ) => {
    let activitiesEdited = airConditionerActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteAirConditionerActivity = (index: number) => {
    const activity = [...airConditionerActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewAirConditionerActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: airConditionerActivityName,
        activityStatus: "inProgress",
        activityType: "Ar condicionado",
        stepType: "Ar condicionado",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        AirConditioner: AirConditioner,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        airConditionerActivities,
        airConditionerActivityName,
        indexAirConditionerActivitySelected,
        setActivity,
        setAirConditionerActivityName,
        setNewAirConditionerActivity,
        editAirConditionerActivity,
        deleteAirConditionerActivity,
        setIndexAirConditionerActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
