const trimesters = [
    { namePeriod: 'Primeiro Trimestre', init: 0, finish: 89 },
    { namePeriod: 'Segundo Trimestre', init: 90, finish: 179 },
    { namePeriod: 'Terceiro Trimestre', init: 180, finish: 269 },
    { namePeriod: 'Quarto Trimestre', init: 270, finish: 364 }
];

/* export function trimesterFormat(trimesterNumber: number) {
    const trimester = trimesters[trimesterNumber];
    if (!trimester) {
        throw new Error(`Invalid trimester number: ${trimesterNumber}`);
    }
    const startDay = trimester.init + 1;
    const endDay = trimester.init + 7 * 13 - 1; // 13 semanas por trimestre
    var start = new Date(new Date().getFullYear(), 0, startDay);
    const end = new Date(new Date().getFullYear(), 0, endDay);
    end.setDate(end.getDate() - 1); // subtrai um dia para obter o último dia da última semana

    var trimestrer = []

    while (start <= end) {
        let dateWeekFinish = new Date(start.getTime() + (6 * 24 * 60 * 60 * 1000));
        const initialMonth = start.toLocaleString('default', { month: 'short' }).replace(/\./g, '');
        const finishMonth = dateWeekFinish.getMonth() !== start.getMonth()
            ? dateWeekFinish.toLocaleString('default', { month: 'short' }).replace(/\./g, ' ')
            : '';
        const initialDay = start.getDate();
        const finishDay = dateWeekFinish.getDate();
        trimestrer.push(`${initialMonth.charAt(0).toUpperCase()}${initialMonth.slice(1)} ${initialDay}-${finishMonth.charAt(0).toUpperCase()}${finishMonth.slice(1)}${finishDay}`)
        start = new Date(start.getTime() + (7 * 24 * 60 * 60 * 1000));
    }
    return trimestrer
} */

export function trimesterFormat(trimesterNumber: number) {
    const trimester = trimesters[trimesterNumber];
    if (!trimester) {
        throw new Error(`Invalid trimester number: ${trimesterNumber}`);
    }
    const startDay = trimester.init + 1;
    const endDay = trimester.init + 7 * 13 - 1; // 13 semanas por trimestre
    var start = new Date(new Date().getFullYear(), 0, startDay);
    const end = new Date(new Date().getFullYear(), 0, endDay);
    end.setDate(end.getDate() - 1); // subtrai um dia para obter o último dia da última semana

    var trimestrer = []

    while (start <= end) {
        let weekStart = new Date(start.getTime());
        let weekEnd = new Date(start.getTime() + (6 * 24 * 60 * 60 * 1000)); // sábado seguinte ao início da semana
        const initialMonth = weekStart.toLocaleString('default', { month: 'short' }).replace(/\./g, '');
        const finishMonth = weekEnd.getMonth() !== weekStart.getMonth()
            ? weekEnd.toLocaleString('default', { month: 'short' }).replace(/\./g, ' ')
            : '';
        const initialDay = weekStart.getDate();
        const finishDay = weekEnd.getDate();
        trimestrer.push(`${initialMonth.charAt(0).toUpperCase()}${initialMonth.slice(1)} ${initialDay}-${finishMonth.charAt(0).toUpperCase()}${finishMonth.slice(1)}${finishDay}`)
        start = new Date(start.getTime() + (7 * 24 * 60 * 60 * 1000));
    }
    return trimestrer;
}


export const totalTrimesterPeriod = (trimesterNumber: number) => {
    const trimester = trimesters[trimesterNumber];
    const trimesterTotalDays = trimester.finish - trimester.init + 1;

    return trimesterTotalDays
}

export function thisTrimester(data: string, trimestre: number, yearNumber: number) {
    // converte a data em um objeto Date do JavaScript
    var partsData = data.split("/");
    var objData = new Date(Number(partsData[2]), Number(partsData[1]) - 1, Number(partsData[0]));

    // verifica o trimestre
    var trimestreInicio = new Date(objData.getFullYear(), Math.floor((objData.getMonth()) / 3) * 3, 1);
    var trimestreFim = new Date(trimestreInicio.getFullYear(), trimestreInicio.getMonth() + 3, 0);
    var yearActual = Number(partsData[2])

    console.log(yearActual === yearNumber)


    return yearActual === yearNumber && trimestreInicio <= objData && objData <= trimestreFim && trimestreInicio <= objData && objData <= trimestreFim && trimestre === Math.floor((objData.getMonth()) / 3) + 1;
}


export function markDowPeridActualGantt(data: string, trimestre: number, yearNumber: number, period: string) {
    const formatPeriod = [{ "monthName": "Jan", "monthNumber": 1, "daysMonth": 31 }, { "monthName": "Fev", "monthNumber": 2, "daysMonth": 28 }, { "monthName": "Mar", "monthNumber": 3, "daysMonth": 31 }, { "monthName": "Abr", "monthNumber": 4, "daysMonth": 30 }, { "monthName": "Mai", "monthNumber": 5, "daysMonth": 31 }, { "monthName": "Jun", "monthNumber": 6, "daysMonth": 30 }, { "monthName": "Jul", "monthNumber": 7, "daysMonth": 31 }, { "monthName": "Ago", "monthNumber": 8, "daysMonth": 31 }, { "monthName": "Set", "monthNumber": 9, "daysMonth": 30 }, { "monthName": "Out", "monthNumber": 10, "daysMonth": 31 }, { "monthName": "Nov", "monthNumber": 11, "daysMonth": 30 }, { "monthName": "Dez", "monthNumber": 12, "daysMonth": 31 }]

    if (thisTrimester(data, trimestre, yearNumber)) {
        var day = Number(data.split('/')[0])
        var month = Number(data.split('/')[1])
        //var year = Number(data.split('/')[2])

        var initialPeriod = period.split('-')[0]
        var finalPeriod = period.split('-')[1]

        var periodSelected = formatPeriod.find(format => format.monthNumber === month)

        if (periodSelected?.monthName === initialPeriod.split(' ')[0] &&
            Number(initialPeriod.split(' ')[1]) <= periodSelected.daysMonth ||
            periodSelected?.monthName === finalPeriod.split(' ')[0]
        ) {
            if (finalPeriod.split(' ').length > 1) {
                if (day >= Number(initialPeriod.split(' ')[1]) && day <= Number(finalPeriod.split(' ')[1])) {
                    return true
                }
            } else if (day >= Number(initialPeriod.split(' ')[1]) && day <= Number(finalPeriod.split(' ')[0])) {
                return true
            }
        }
    } else {
        return false
    }
}

export function markDowPeridDateLineGantt(data: string, trimestre: number, yearNumber: number, period: string) {
    if (thisTrimester(data, trimestre, yearNumber)) {
        return true
    } else {
        return false
    }
}

export function periodoTrimestral(trimesterNumber: number, dataInicialStr: string, dataFinalStr: string, yearNumber: number) {
    const startDateParts = dataInicialStr.split("/");
    const endDateParts = dataFinalStr.split("/");
    const startDate = new Date(
        Number(startDateParts[2]),
        Number(startDateParts[1]) - 1,
        Number(startDateParts[0])
    );
    const endDate = new Date(
        Number(endDateParts[2]),
        Number(endDateParts[1]) - 1,
        Number(endDateParts[0])
    );
    const start = new Date(startDate.getFullYear(), 0, 0);
    const end = new Date(endDate.getFullYear(), 0, 0);

    const startDiff = startDate.getTime() - start.getTime();
    const endDiff = endDate.getTime() - end.getTime();
    const oneDay = 1000 * 60 * 60 * 24;

    var startDay = Math.floor(startDiff / oneDay);
    var endDay = Math.floor(endDiff / oneDay);

    var limitFinishTrimester = 0
    var limitCalcInterval = 0

    if (trimesters[trimesterNumber].finish + 1 === 90) {
        limitFinishTrimester = 90
    } else if (trimesters[trimesterNumber].finish + 1 === 180) {
        limitFinishTrimester = 180
        limitCalcInterval = 90
    } else if (trimesters[trimesterNumber].finish + 1 === 270) {
        limitFinishTrimester = 270
        limitCalcInterval = 180
    } else if (trimesters[trimesterNumber].finish + 1 === 365) {
        limitFinishTrimester = 365
        limitCalcInterval = 270
    }

    if (trimesters[trimesterNumber].finish + 1 === 90) { /* 1º trimestre */
        if (thisTrimester(dataInicialStr, trimesterNumber + 1, yearNumber) && thisTrimester(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay}/${endDay + 1}`;
        } else if (thisTrimester(dataInicialStr, trimesterNumber + 1, yearNumber) && !thisTrimester(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay}/${91}`;
        } else if (!thisTrimester(dataInicialStr, trimesterNumber + 1, yearNumber) && thisTrimester(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${1}/${endDay}`;
        } else if (startDay < limitFinishTrimester && endDay > 90 && Number(endDateParts[2]) === yearNumber) {
            return `${1}/${91}`;
        } else {
            return `-1/-1`;
        }
    } else if (trimesters[trimesterNumber].finish + 1 === limitFinishTrimester) { /* 2º trimestre em diante*/
        if (thisTrimester(dataInicialStr, trimesterNumber + 1, yearNumber) && thisTrimester(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${startDay - (trimesters[trimesterNumber].init)}/${endDay - (trimesters[trimesterNumber].finish + 3)}`;
        } else if (thisTrimester(dataInicialStr, trimesterNumber + 1, yearNumber) && !thisTrimester(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${(startDay - 1) - Number(limitCalcInterval)}/${96}`;
        } else if (!thisTrimester(dataInicialStr, trimesterNumber + 1, yearNumber) && thisTrimester(dataFinalStr, trimesterNumber + 1, yearNumber)) {
            return `${1}/${endDay + 1 - Number(limitCalcInterval)}`;
        } else if (startDay < limitFinishTrimester && endDay > limitFinishTrimester && Number(endDateParts[2]) === yearNumber) {
            return `${1}/${91}`;
        } else {
            return `${-1}/${-1}`;
        }
    }
}

