import styled from "styled-components";

export const ListContainerItens = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    text-align: center;
    grid-gap:0.75rem;
   
    p{
        display:flex;
        justify-content:center;
        font-size:1rem;
    }

    .textLeft{
        display: flex;
        justify-content: left;    
        align-items:center;
        margin-right: 1rem;
        img{
            width:1rem;
            margin-right:0.3rem;
            cursor:pointer;
        }
    }

    .textCenter{
        text-align:center;
    }
`

export const ActionComparativeContainer=styled.div`
    display: flex;
    align-items: center;
`