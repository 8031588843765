import React, { useContext, useState } from "react";

import {
  UserProviderProps,
  sawmillActivity,
} from "../../../types/typesEntitiesProject";
import { SawmillFormType } from "../../../Models/Sawmill/type";

import { Mesas } from "../../../Models/Sawmill/Sawmill";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";

export interface activitiesSawmillProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  sawmillActivityTable: sawmillActivity[];
  Frames: SawmillFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  sawmillActivities: activitiesSawmillProps[];
  sawmillActivityName: string;
  indexSawmillActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesSawmillProps[]>>;
  setNewSawmillActivity?: () => void;
  setSawmillActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexSawmillActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editSawmillActivity?: (
    index: number,
    activity: activitiesSawmillProps
  ) => void;
  deleteSawmillActivity?: (index: number) => void;
}

const activityContextDefault = {
  sawmillActivities: [],
  sawmillActivityName: "",
  indexSawmillActivitySelected: 0,
  setNewSawmillActivity: () => {},
  setSawmillActivityName: () => {},
  setIndexSawmillActivitySelected: () => {},
  editSawmillActivity: () => {},
  deleteSawmillActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseSawmillActivityContext = () => useContext(ActivitiesContext);

export const SawmillActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [sawmillActivities, setActivity] = useState<activitiesSawmillProps[]>(
    activityContextDefault.sawmillActivities
  );
  const [indexSawmillActivitySelected, setIndexSawmillActivitySelected] =
    useState<number>(0);
  const [sawmillActivityName, setSawmillActivityName] =
    useState<string>("Mesas");

  const editSawmillActivity = (
    index: number,
    activity: activitiesSawmillProps
  ) => {
    let activitiesEdited = sawmillActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteSawmillActivity = (index: number) => {
    const activity = [...sawmillActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewSawmillActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: sawmillActivityName,
        activityStatus: "inProgress",
        activityType: "Mesas",
        stepType: "Serralheria",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        sawmillActivityTable: [
          {
            comprimento: "",
            largura: "",
            altura: "",
            material_tampo: "",
            material_estrutura: "",
            pintura: "",
            adicionais: "",
            quantidade: "",
          },
        ],
        Frames: Mesas,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        sawmillActivities,
        sawmillActivityName,
        indexSawmillActivitySelected,
        setActivity,
        setSawmillActivityName,
        setNewSawmillActivity,
        editSawmillActivity,
        deleteSawmillActivity,
        setIndexSawmillActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
