import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps, espelhoProps } from "../../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget } from "../../style"
import { GreenDestack } from "../../../style"

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    suppliers: SupplierProps[]
    destackMinItemValue: boolean
}

interface activityProps {
    selectedBudget?: boolean,
    espelhos?: espelhoProps[];

}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        espelhos: string[];
    }
}

export const EspelhosComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: string, activityId?: string, itemType?: any) => {
        const budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        const totalBudgetsList: number[] = []

        if (!totalItem) {
            return <p>R$0,00</p>
        }

        const minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        }, 1000000000)

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>
    }

    return (
        <ContainerItensBudget>
            {budgets ? (
                <>
                    {activity && activity.espelhos ? (
                        activity.espelhos.map((item, index) => (
                            <>
                                <SectionItensBudget>
                                    <p className="itemSectionName">Espelho {index + 1}</p>
                                    <p>Medidas</p>
                                    <p>{" "}</p>
                                    <p>{" "}</p>
                                    <p>{" "}</p>
                                    <p>{" "}</p>
                                    <p>{" "}</p>
                                    <p>{" "}</p>
                                </SectionItensBudget>
                                {item.tipo_espelho ? (
                                    <ItemBudget>
                                        <p className="itemName">
                                            Quantidade: {item.quantidade} <br></br>
                                            {item.tipo_espelho !== 'Outros' ? ('Tipo: ' + item.tipo_espelho) : ('Tipo: ' + item.tipo_espelhos_outros)} <br></br>
                                            {item.cor_espelho !== 'Outros' ? ('Cor: ' + item.cor_espelho) : ('Cor: ' + item.cor_espelho_outros)}<br></br>
                                            {item.acabamento_borda !== 'Outros' ? ('Acabamento de borda: ' + item.acabamento_borda) : ('Acabamento de borda: ' + item.acabamento_borda_outros)}
                                        </p>
                                        <p>{item.comprimento_espelho} X {item.altura_espelho}cm</p>
                                        {props.suppliers ? (
                                            <>
                                                {/**Item orçado pelo Fornecedor avulso */}
                                                {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                    budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                        budget.budget?.espelhos ? renderItemBudget(budget.budget?.espelhos[index], budget.activityId, 'espelhos') : ('')
                                                    ))
                                                ))}
                                                {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                                {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                    budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                        budget.budget?.espelhos ? renderItemBudget(budget.budget?.espelhos[index], budget.activityId, 'espelhos') : ('')
                                                    ))
                                                ))}
                                            </>
                                        ) : (<></>)}
                                    </ItemBudget>
                                ) : (
                                    <></>
                                )}
                            </>
                        ))
                    ) : (<></>)}
                </>
            ) : (<></>)}
        </ContainerItensBudget>
    )
}

export default EspelhosComparartive