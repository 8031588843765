import React, { useEffect } from "react"

import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const RoofController: React.FC = (props) => {
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Telhado')
    }, [])

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="impermeabilizationStep" id="impermeabilizationStep" onChange={(e) => setActivitySelected?.(e.target.value)}>
                <option value="Telhado">Telhado</option>
                <option value="Materiais para telhado">Materiais</option>

            </select>
        </ActionSelect>
    </div>
}

export default RoofController