import React, { useState, useEffect } from "react";

import { UseFloorsAndCoveringsActivityContext } from "../../../../Context/ActivitiesContext/FloorsAndCoverings/FloorsAndCoveringsContext";
import { attributeType } from "../../../../Models/attributeType";
import { StoneFloorCoveringFormType } from "../../../../Models/FloorsAndCoverings/type";
import { StoneFloorCovering } from "../../../../Models/FloorsAndCoverings/StoneFloorCoveringModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const StoneFloorCoveringStepForm: React.FC<stepFormProps> = (props) => {
  const { floorsAndCoveringsActivities, indexFloorsAndCoveringsActivitySelected, editFloorsAndCoveringsActivity } = UseFloorsAndCoveringsActivityContext();

  const [assignment, setAssignment] = useState<string>('')
  const [itensFormList, setItensFormList] = useState<StoneFloorCoveringFormType>(StoneFloorCovering)

  useEffect(() => {
    if (floorsAndCoveringsActivities.length > 0) {
      let floorsAndCoveringsActivityCopy = floorsAndCoveringsActivities[indexFloorsAndCoveringsActivitySelected]
      floorsAndCoveringsActivityCopy.activityType = 'Revestimento de piso - Pedras'
      editFloorsAndCoveringsActivity?.(indexFloorsAndCoveringsActivitySelected, floorsAndCoveringsActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexFloorsAndCoveringsActivitySelected])

  useEffect(() => {
    if (floorsAndCoveringsActivities.length > 0) {
      setItensFormList(floorsAndCoveringsActivities[indexFloorsAndCoveringsActivitySelected].StoneFloorCovering)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexFloorsAndCoveringsActivitySelected, floorsAndCoveringsActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemRevestimento(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Revestimento") {
      addRevestimento({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Largura da peça',
            unit_of_measurement: 'cm',
            value: ''
          },
          {
            itemName: 'Área',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Tipo de peça',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    }
  }

  const addRevestimento = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
    itensFormListCopy.Revestimento.push(object)
    floorsAndCoveringsActivitiesCopy[0].StoneFloorCovering = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
    console.log('oi')
  }



  const removeItemRevestimento = (index: number) => {
    var itensFormListCopy = itensFormList
    var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
    itensFormListCopy.Revestimento.splice(index, 1)
    floorsAndCoveringsActivitiesCopy[0].StoneFloorCovering = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
  }



  const editRevestimentoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
    itensFormListCopy.Revestimento[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
  }

  const editRevestimentoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
    itensFormListCopy.Revestimento[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
  }


  const editRevestimentoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
    itensFormListCopy.Revestimento[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Revestimento.length ? (
        <div>
          <p>Revestimento de piso - pedras</p>
          {itensFormList.Revestimento.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRevestimentoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRevestimentoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRevestimentoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRevestimentoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRevestimentoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editRevestimentoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRevestimento(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default StoneFloorCoveringStepForm;

