import React, { useContext, useEffect, useState } from "react";

import { textTransform } from "../../../components/StepsForm/script";
import { Frames } from "../../../Models/Frames/FramesModel";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { FramesFormType } from "../../../Models/Frames/type";
import { MaterialsFormType } from "../../../Models/attributeType";
import { UserProviderProps } from "../../../types/typesEntitiesProject";
export interface activitiesFramesProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Frames: FramesFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  framesActivities: activitiesFramesProps[];
  framesActivityName: string;
  indexFramesActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesFramesProps[]>>;
  setNewFramesActivity?: () => void;
  setFramesActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexFramesActivitySelected?: React.Dispatch<React.SetStateAction<number>>;
  editFramesActivity?: (index: number, activity: activitiesFramesProps) => void;
  deleteFramesActivity?: (index: number) => void;
}

const activityContextDefault = {
  framesActivities: [],
  framesActivityName: "",
  indexFramesActivitySelected: 0,
  setActivity: () => {},
  setNewFramesActivity: () => {},
  setFramesActivityName: () => {},
  setIndexFramesActivitySelected: () => {},
  editFramesActivity: () => {},
  deleteFramesActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseFramesActivityContext = () => useContext(ActivitiesContext);

export const FramesActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [framesActivities, setActivity] = useState<activitiesFramesProps[]>(
    activityContextDefault.framesActivities
  );
  const [indexFramesActivitySelected, setIndexFramesActivitySelected] =
    useState<number>(0);
  const [framesActivityName, setFramesActivityName] = useState<string>(
    textTransform("Esquadrias")
  );

  const editFramesActivity = (
    index: number,
    activity: activitiesFramesProps
  ) => {
    let activitiesEdited = framesActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteFramesActivity = (index: number) => {
    const activity = [...framesActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewFramesActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: framesActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Esquadrias",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Frames: Frames,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        framesActivities,
        framesActivityName,
        indexFramesActivitySelected,
        setActivity,
        setFramesActivityName,
        setNewFramesActivity,
        editFramesActivity,
        deleteFramesActivity,
        setIndexFramesActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
