import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ItemContentListPilar,
  ListContainerItensPilar,
  ListContainerItensVigas,
  ItemContentListVigas,
} from "./style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { StructureFormType } from "../../../../Models/Masonary/type";

import FooterBudget from "../../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  Structure: StructureFormType
}

const StructureBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [pilar, setPilar] = useState<string[]>(["0,00"])
  const [vigas, setVigas] = useState<string[]>(["0,00"])


  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Structure.Pilar.forEach(() => {
      setPilar((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.Structure.Vigas?.forEach(() => {
      setVigas((prev) => [...prev, "0,00"])
    })

  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const pilarTotal = sum(pilar)
    const vigasTotal = sum(vigas)


    setTotalBudgetPayment(sum([pilarTotal, vigasTotal]))
  }, [pilar, vigas])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const pilarTotal = sum(pilar)
  const vigasTotal = sum(vigas)


  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([pilarTotal, vigasTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const pilarMargin: number[] = []
    const vigasMargin: number[] = []

    pilar.forEach((item) => {
      if (item != "0,00") {
        pilarMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    vigas.forEach((item) => {
      if (item != "0,00") {
        vigasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Pilar: pilar,
          Vigas: vigas,
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensPilar>

        {activity.Structure.Pilar?.map((item, index) => (
          <div key={index}>
            {activity.Structure.Pilar.length > 1 ? (<P>Pilar {index + 1}</P>) : (<P>Pilar</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p key={index}>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListPilar >
              {item.attributes?.map((tarefa, index) => (
                <p key={index} >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pilar, setPilar, e.target.value)} />
              </ContentInputPrice>
              {pilar && pilar[index] !== '0' ?
                (<p className="item">{pilar[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListPilar>
          </div>
        ))}

      </ListContainerItensPilar>

      <ListContainerItensVigas>
        {activity.Structure.Vigas?.map((item, index) => (
          <div key={index}>
            {activity.Structure.Vigas.length > 1 ? (<P>Vigas {index + 1}</P>) : (<P>Vigas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p key={index}>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListVigas >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, vigas, setVigas, e.target.value)} />
              </ContentInputPrice>
              {vigas && vigas[index] !== '0' ?
                (<p className="item">{vigas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListVigas>
          </div>
        ))}
      </ListContainerItensVigas>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default StructureBudget;