import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { RoofFormType } from "../../../Models/Roof/type";
import { Roof } from "../../../Models/Roof/RoofModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

export interface activitiesRoofProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Roof: RoofFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  roofActivities: activitiesRoofProps[];
  roofActivityName: string;
  indexRoofActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesRoofProps[]>>;
  setNewRoofActivity?: () => void;
  setRoofActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexRoofActivitySelected?: React.Dispatch<React.SetStateAction<number>>;
  editRoofActivity?: (index: number, activity: activitiesRoofProps) => void;
  deleteRoofActivity?: (index: number) => void;
}

const activityContextDefault = {
  roofActivities: [],
  roofActivityName: "",
  indexRoofActivitySelected: 0,
  setNewRoofActivity: () => {},
  setRoofActivityName: () => {},
  setIndexRoofActivitySelected: () => {},
  editRoofActivity: () => {},
  deleteRoofActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseRoofActivityContext = () => useContext(ActivitiesContext);

export const RoofActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [roofActivities, setActivity] = useState<activitiesRoofProps[]>(
    activityContextDefault.roofActivities
  );
  const [indexRoofActivitySelected, setIndexRoofActivitySelected] =
    useState<number>(0);
  const [roofActivityName, setRoofActivityName] = useState<string>("Telhado");

  const editRoofActivity = (index: number, activity: activitiesRoofProps) => {
    let activitiesEdited = roofActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteRoofActivity = (index: number) => {
    const activity = [...roofActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewRoofActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: roofActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Telhado",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Roof: Roof,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        roofActivities,
        roofActivityName,
        indexRoofActivitySelected,
        setActivity,
        setRoofActivityName,
        setNewRoofActivity,
        editRoofActivity,
        deleteRoofActivity,
        setIndexRoofActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
