import React, { useState, useEffect } from "react";
import { UseRoofActivityContext } from "../../../../Context/ActivitiesContext/Roof/RoofContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid } from "./style"

import { RoofFormType } from "../../../../Models/Roof/type";
import { attributeType } from "../../../../Models/attributeType";
import { Roof } from "../../../../Models/Roof/RoofModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const RoofStepForm: React.FC<stepFormProps> = (props) => {
  const { roofActivities, editRoofActivity, indexRoofActivitySelected } = UseRoofActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<RoofFormType>(Roof)
  const [otherTypeValue, setOtherTypeValue] = useState<string>('')


  useEffect(() => {
    if (roofActivities.length > 0) {
      let roofActivityCopy = roofActivities[indexRoofActivitySelected]
      roofActivityCopy.activityType = 'Telhado'
      editRoofActivity?.(indexRoofActivitySelected, roofActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexRoofActivitySelected])


  useEffect(() => {
    if (roofActivities.length > 0) {
      setItensFormList(roofActivities[indexRoofActivitySelected].Roof);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexRoofActivitySelected, roofActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemTelhado(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Telhado") {
      addTelhado({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    }
  }

  const addTelhado = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var roofActivitiesCopy = roofActivities
    itensFormListCopy.Telhado.push(object)
    roofActivitiesCopy[0].Roof = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editRoofActivity?.(0, roofActivitiesCopy[0])
  }

  const removeItemTelhado = (index: number) => {
    var itensFormListCopy = itensFormList
    var roofActivitiesCopy = roofActivities
    itensFormListCopy.Telhado.splice(index, 1)
    roofActivitiesCopy[0].Roof = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editRoofActivity?.(0, roofActivitiesCopy[0])
  }


  const editTelhadoValues = (value: string, indexItem: number, indexTelhado: number) => {
    var itensFormListCopy = itensFormList
    var roofActivitiesCopy = roofActivities
    itensFormListCopy.Telhado[indexTelhado].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editRoofActivity?.(0, roofActivitiesCopy[0])
  }

  const editTelhadoAttributeName = (value: string, indexItem: number, indexTelhado: number) => {
    var itensFormListCopy = itensFormList
    var roofActivitiesCopy = roofActivities
    itensFormListCopy.Telhado[indexTelhado].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editRoofActivity?.(0, roofActivitiesCopy[0])
  }

  const editTelhadoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const roofActivitiesCopy = roofActivities
    itensFormListCopy.Telhado[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editRoofActivity?.(0, roofActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.Telhado.length ? (
        <div>
          <P>Telhado</P>
          {itensFormList.Telhado.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Material" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTelhadoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editTelhadoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Ceramico">Cerâmico</option>
                      <option value="Outros">Outros</option>
                      {otherTypeValue ? (<option value={otherTypeValue} selected>{otherTypeValue}</option>) : null}

                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={otherTypeValue}
                            onChange={(e) => setOtherTypeValue(e.target.value)}
                          />
                          <button onClick={() => editTelhadoValues(otherTypeValue, indexAttribute, indexItemForm)}>&#10003;</button>

                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTelhadoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editTelhadoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editTelhadoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemTelhado(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default RoofStepForm;
