import React, { useState } from "react";
import { marble } from "../../../../types/typesEntitiesProject";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string;
  activityName?: string;
  activityType?: string;

  marble?: marble[];
}

const TentoDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);
  return (
    <div>
      {activity.marble ? (
        activity.marble.map((element, index) => (
          <div className="item">
            {element?.pedra ? (
              <div className="line">
                <p>Pedra</p>
                <p>{element.pedra}</p>
              </div>
            ) : null}

            {element?.cor_pedra ? (
              <div className="line">
                <p>Cor da pedra</p>
                <p>{element.cor_pedra}</p>
              </div>
            ) : null}

            {element?.acabamento_pedra ? (
              <div className="line">
                <p>Acabamento</p>
                <p>{element.acabamento_pedra}</p>
              </div>
            ) : null}

            <p className="bold">
              <br />
              Item a ser revestido:
            </p>

            {element?.altura_item_revestido ? (
              <div className="line">
                <p>
                  <br />
                  Altura
                </p>
                <p>
                  <br />
                  {String(element.altura_item_revestido)} cm
                </p>
              </div>
            ) : null}

            {element?.largura_item_revestido ? (
              <div className="line">
                <p>Largura</p>
                <p>{String(element.largura_item_revestido)} cm</p>
              </div>
            ) : null}

            {element?.profundidade_item_revestido ? (
              <div className="line">
                <p>Profundidade</p>
                <p>{String(element.profundidade_item_revestido)} cm</p>
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default TentoDescription;
