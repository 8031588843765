import React from "react";

import moment from "moment";

import { SelectDayActualyContent } from "../SelectDayActualy/style";
import { markdowSelectPeriod } from "./cronogram";

interface periodProps {
    period: string,
    yearNumber: number
}

const SelectCronogramPeriodActual: React.FC<periodProps> = (props) => {
    return (
        <>
            {markdowSelectPeriod(props.period, props.yearNumber) ? (
                <SelectDayActualyContent width="auto" backgroundColor="#7592CC" textColor="#FFF">
                    {props.period}
                </SelectDayActualyContent>
            ) : (
                <SelectDayActualyContent width="auto" backgroundColor="transparent" textColor="#A5A5A5">
                    {props.period}
                </SelectDayActualyContent>
            )}

        </>
    )
}

export default SelectCronogramPeriodActual