import React from "react";

import ImpermeabilizationDescription from "./Impermeabilization";


import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const ImpermeabilizationStepDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Impermeabilizacao" ? (<ImpermeabilizationDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}
        </>
    )
}

export default ImpermeabilizationStepDescription