import styled from "styled-components";

export const Th = styled.th`
    cursor: pointer;

    img {
        width: 1.7rem;
    }
`

export const MenuOverlay = styled.div`
    background-color: var(--dark-background);
    border-radius: 0.3rem;
    display: grid;
    position: absolute;
    right: 0;
    margin-right: 3rem;
    margin-top:1.7rem;
`

export const OptionButton = styled.a`
    color: var(--primary-color);
    font-weight: 400;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    font-size:0.85rem;
    cursor: pointer;

    a{
        color: var(--primary-color);
        font-weight: 400;
    }
`

export const Button = styled.button`
    background-color: var(--primary-color);
    padding: 0.75rem;
    border-radius: 4px;
    color: var(--secondary-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    border: 1px solid var(--secondary-color);
    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
        a{
            color: var(--primary-color);
        }
    }

    a{
        color: var(--secondary-color);
    }

    img{
        width: 1rem;
        margin-right: 0.5rem;
    }

    label{
        cursor: pointer;
    }

    cursor: pointer;
`

export const Tr = styled.tr`
    border-bottom: 5px solid #E5E5E5;
    color: #777777;
    font-weight: 700;
    a {
        color: #777777;
    }
    :hover {
        cursor: pointer;
        a{
            font-weight: 800;
            color: var(--secondary-color);
        }
    }
`
