import styled from "styled-components";

export const Container = styled.div`
    margin-top:1rem;
    display: grid;
    padding: 0.25rem;
`

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 0fr;
    grid-gap:3rem;
    padding: 0.75rem;
    border: 1px solid #E1E1E1;
    border-radius:0.3rem;
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    
    .content{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .resizeInputs{
        width: 6rem;
        p{
            white-space: nowrap;
        }
        input{
            width: 100%;
        }
    }

    .flexContentInput{
        display: flex;
        align-items:center;
       
        p{
            margin-right:0.75rem;
        }

        div{
            width: 6rem;
            input{
                width: 100%;
            }
            margin: 0.3rem;
        }
    }

    .cancelX{
      color: #777777;
      cursor: pointer;
    }
`