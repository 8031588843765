import styled from "styled-components";

export const Container=styled.div`
    max-height: 65vh;
    overflow-y: auto;
`

export const ItemName=styled.p`
    margin-left: 1rem;
`

export const HeaderElementItens = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1rem;
    padding-top: 0;

    .centerItem{
        text-align: center;
    }
`

export const Content = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 0fr;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #fff;
    padding: 0.65rem;
    border-radius: 0.3rem;
    margin-top: 1rem;

    .inputElement{
        display: flex;
        justify-content: center;
        border-radius: 0.3rem;
        margin-right: 2rem;
        border: 1px solid #E1E1E1;

        input{
            width: 100%;
            border: none;
            background-color: #F1F2F3;
            padding: 0.25rem;
        }
    }
`

export const ContentList = styled.div`
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #fff;
    padding: 0.65rem;
    border-radius: 0.3rem;
    margin-top: 1rem;

    .inputElement{
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: 0.3rem;
        margin-right: 2rem;
        border: 1px solid #E1E1E1;

        input{
            width: 100%;
            border: none;
            background-color: #F1F2F3;
            padding: 0.25rem;
        }
    }
`


export const RemoveContent=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
`

export const ContainerButton=styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`

export const FooterButton=styled.div`
    margin-right: 1.5rem;
    margin-top: 1rem;
    display:  flex;
    justify-content: end;
`