import React, { useState, useEffect } from "react";

import { UseFloorsAndCoveringsActivityContext } from "../../../../Context/ActivitiesContext/FloorsAndCoverings/FloorsAndCoveringsContext";
import { attributeType } from "../../../../Models/attributeType";
import { FlooringFormType } from "../../../../Models/FloorsAndCoverings/type";
import { Flooring } from "../../../../Models/FloorsAndCoverings/FlooringModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const FlooringStepForm: React.FC<stepFormProps> = (props) => {
    const { floorsAndCoveringsActivities, indexFloorsAndCoveringsActivitySelected, editFloorsAndCoveringsActivity } = UseFloorsAndCoveringsActivityContext();

    const [assignment, setAssignment] = useState<string>('')
    const [itensFormList, setItensFormList] = useState<FlooringFormType>(Flooring)

    useEffect(() => {
        if (floorsAndCoveringsActivities.length > 0) {
            let floorsAndCoveringsActivityCopy = floorsAndCoveringsActivities[indexFloorsAndCoveringsActivitySelected]
            floorsAndCoveringsActivityCopy.activityType = 'Assoalho'
            editFloorsAndCoveringsActivity?.(indexFloorsAndCoveringsActivitySelected, floorsAndCoveringsActivityCopy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexFloorsAndCoveringsActivitySelected])

    useEffect(() => {
        if (floorsAndCoveringsActivities.length > 0) {
            setItensFormList(floorsAndCoveringsActivities[indexFloorsAndCoveringsActivitySelected].Flooring)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexFloorsAndCoveringsActivitySelected, floorsAndCoveringsActivities]);

    useEffect(() => {
        if (props.stepFormExibition) {
            removeItemAssoalho(0)
        }
    }, [])

    const addAssignment = () => {
        if (assignment === "Assoalho") {
            addAssoalho({
                attributes: [
                    {
                        itemName: 'Descrição',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Altura',
                        unit_of_measurement: 'cm',
                        value: ''
                    },
                    {
                        itemName: 'Largura da peça',
                        unit_of_measurement: 'cm',
                        value: ''
                    },
                    {
                        itemName: 'Área',
                        unit_of_measurement: 'm²',
                        value: ''
                    },
                    {
                        itemName: 'Material',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Tipo de peça',
                        unit_of_measurement: '',
                        value: ''
                    },
                    {
                        itemName: 'Fornecedor',
                        unit_of_measurement: '',
                        value: ''
                    },
                ]
            })
        }
    }

    const addAssoalho = (object: attributeType) => {
        var itensFormListCopy = itensFormList
        var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
        itensFormListCopy.Assoalho.push(object)
        floorsAndCoveringsActivitiesCopy[0].Flooring = itensFormListCopy
        setItensFormList(itensFormListCopy)
        editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
        console.log('oi')
    }



    const removeItemAssoalho = (index: number) => {
        var itensFormListCopy = itensFormList
        var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
        itensFormListCopy.Assoalho.splice(index, 1)
        floorsAndCoveringsActivitiesCopy[0].Flooring = itensFormListCopy
        setItensFormList(itensFormListCopy)
        editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
    }



    const editAssoalhoValues = (value: string, indexItem: number, indexItemForm: number) => {
        var itensFormListCopy = itensFormList
        var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
        itensFormListCopy.Assoalho[indexItemForm].attributes[indexItem].value = value
        setItensFormList(itensFormListCopy)
        editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
    }

    const editAssoalhoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
        var itensFormListCopy = itensFormList
        var floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
        itensFormListCopy.Assoalho[indexItemForm].attributes[indexItem].itemName = value
        setItensFormList(itensFormListCopy)
        editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
    }

    const editAssoalhoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
        const itensFormListCopy = itensFormList
        const floorsAndCoveringsActivitiesCopy = floorsAndCoveringsActivities
        itensFormListCopy.Assoalho[indexItemForm].attributes[indexItem].unit_of_measurement = value
        setItensFormList(itensFormListCopy)
        editFloorsAndCoveringsActivity?.(0, floorsAndCoveringsActivitiesCopy[0])
    }


    return (
        <div>
            <div>
                <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
            </div>
            <br />
            {itensFormList.Assoalho.length ? (
                <div>
                    <p>Assoalho</p>
                    {itensFormList.Assoalho.map((itemForm, indexItemForm) => (
                        <ContainerGrid key={indexItemForm}>
                            {itemForm.attributes.map((attributeItem, indexAttribute) => (
                                attributeItem.itemName === "Tipo" ? (
                                    <div >
                                        <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAssoalhoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                                        <Select value={attributeItem.value} onChange={(e) => editAssoalhoValues(e.target.value, indexAttribute, indexItemForm)}>
                                            <option value="Gesso">Gesso</option>
                                            <option value="Pvc">Pvc</option>
                                            <option value="Madeira">Madeira</option>
                                            <option value="Isopor">Isopor</option>
                                            <option value="Cimento">Cimento</option>
                                            <option value="Aparente">Aparente</option>
                                            <option value="Fibra">Fibra</option>
                                            <option value="Drywall reto">Drywall reto</option>
                                            <option value="Drywall tabicado">Drywall tabicado</option>
                                            <option value="Jateamento de celulose">Jateamento de celulose</option>
                                            <option value="Outros">Outros</option>
                                        </Select>
                                        <div>{attributeItem.value === "Outros" ? (
                                            <div>
                                                <InputActivity>
                                                    <input type="text" id="tipooutros" placeholder="Digite o tipo"
                                                        value={attributeItem.value}
                                                        onChange={(e) => editAssoalhoValues(e.target.value, indexAttribute, indexItemForm)}
                                                    />
                                                </InputActivity>
                                            </div>
                                        ) : (
                                            <></>
                                        )}</div>

                                    </div>
                                ) : (
                                    <div className="resizeContentInputs">
                                        <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAssoalhoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                                        <InputActivity>
                                            <input type="text" value={attributeItem.value} onChange={(e) => editAssoalhoValues(e.target.value, indexAttribute, indexItemForm)} />
                                            {attributeItem.unit_of_measurement ? (
                                                <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editAssoalhoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                                            ) : (<></>)}
                                        </InputActivity>
                                    </div>
                                )
                            ))}
                            <div className="cancelX" onClick={() => removeItemAssoalho(indexItemForm)}>X</div>
                        </ContainerGrid>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default FlooringStepForm;

