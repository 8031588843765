import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  ContanierFinalization,
  ItemContainerFinzalization,
  TotalContainer,
  ContainerButtonSendBudget,
} from "../../style";

import {
  activitiesProps,
  SupplierProps,
  marble,
} from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate";

import FooterBudget from "../../Components/FooterBudget";
interface budgetProps {
  activity: activitiesProps;
  supplier: SupplierProps;
  margin: number;
}
interface activityProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string;
  activityType?: string;
  stepType?: string;

  marble?: marble[];
}

const TentoSoleiraBagueteRodaBaseBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [marble, setMarble] = useState<string[]>(["0,00"]);

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>("0,00");
  const [conditions, setConditions] = useState<string>("");

  useEffect(() => {
    activity.marble?.forEach(() => {
      setMarble((prev) => [...prev, "0,00"]);
    });
  });

  /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    var sum_totalMarble = sum(marble);
    setTotalBudgetPayment(sum([sum_totalMarble]));
  }, [marble]);

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  useEffect(() => {
    var sum_totalMarble = sum(marble);
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(
        parseFloat(minimumBudgetValue).toFixed(2).replace(".", ",")
      );
    } else {
      setTotalBudgetPayment(sum([sum_totalMarble]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (
    index: number,
    stateVar: string[],
    setStateRef: any,
    value: string,
    measurement: string
  ) => {
    var stateCopy = Array.from(stateVar);
    stateCopy.splice(index, 1, multiply(measurement, value));
    setStateRef(stateCopy);
  };

  const submitBudget = () => {
    var marbleMargin: number[] = [];

    marble.forEach((item) => {
      if (item != "0,00") {
        marbleMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: "",
        workingDays,
        selectedBudget: false,
        budget: {
          marble: marbleMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (
          Number(totalBudgetPayment.replace(",", ".")) +
          Number(totalBudgetPayment.replace(",", ".")) * (props.margin / 100)
        ).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItens>
        {activity.marble?.map((element, index) => {
          return (
            <div key={index} className="upContent">
              <p>{activity.activityName}</p>
              <p>Medidas</p>
              <p>Preço unit.</p>
              <p className="item">Preço total</p>

              {element.pedra !== "" ? (
                <ItemContentList>
                  <p>
                    {element.pedra} - {element.cor_pedra} -{" "}
                    {element.acabamento_pedra}
                  </p>
                  <p>
                    {String(element.altura_item_revestido)} cm x{" "}
                    {String(element.largura_item_revestido)} cm x{" "}
                    {String(element.profundidade_item_revestido)} cm
                  </p>
                  <ContentInputPrice>
                    R$:
                    <PriceItem
                      onChange={(e) =>
                        updateState(
                          index,
                          marble,
                          setMarble,
                          e.target.value,
                          "1"
                        )
                      }
                    />
                  </ContentInputPrice>
                  {marble && marble[index] !== "0" ? (
                    <p className="item">{marble}</p>
                  ) : (
                    <p className="item">0,00</p>
                  )}
                </ItemContentList>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </ListContainerItens>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  );
};

export default TentoSoleiraBagueteRodaBaseBudget;
