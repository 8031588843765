import React, { useState, useEffect } from "react";

import { UseCleaningActivityContext } from "../../../../Context/ActivitiesContext/Cleaning/CleaningContext";
import { attributeType } from "../../../../Models/attributeType";
import { CleaningFormType } from "../../../../Models/Cleaning/type";
import { Cleaning } from "../../../../Models/Cleaning/CleaningModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const CleaningStepForm: React.FC<stepFormProps> = (props) => {
  const { cleaningActivities, indexCleaningActivitySelected, editCleaningActivity } = UseCleaningActivityContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<CleaningFormType>(Cleaning)

  useEffect(() => {
    if (cleaningActivities.length > 0) {
      let cleaningActivityCopy = cleaningActivities[indexCleaningActivitySelected]
      cleaningActivityCopy.activityType = 'Limpeza'
      editCleaningActivity?.(indexCleaningActivitySelected, cleaningActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexCleaningActivitySelected])

  useEffect(() => {
    if (cleaningActivities.length > 0) {
      setItensFormList(cleaningActivities[indexCleaningActivitySelected].Cleaning)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexCleaningActivitySelected, cleaningActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemLimpeza(0)
    }

  }, [])

  const addAssignment = () => {
    if (assignment === "Limpeza") {
      addLimpeza({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    }
  }

  const addLimpeza = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var cleaningActivitiesCopy = cleaningActivities
    itensFormListCopy.Limpeza.push(object)
    cleaningActivitiesCopy[0].Cleaning = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editCleaningActivity?.(0, cleaningActivitiesCopy[0])
  }


  const removeItemLimpeza = (index: number) => {
    var itensFormListCopy = itensFormList
    var cleaningActivitiesCopy = cleaningActivities
    itensFormListCopy.Limpeza.splice(index, 1)
    cleaningActivitiesCopy[0].Cleaning = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editCleaningActivity?.(0, cleaningActivitiesCopy[0])
  }



  const editLimpezaValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var cleaningActivitiesCopy = cleaningActivities
    itensFormListCopy.Limpeza[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editCleaningActivity?.(0, cleaningActivitiesCopy[0])
  }

  const editLimpezaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var cleaningActivitiesCopy = cleaningActivities
    itensFormListCopy.Limpeza[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editCleaningActivity?.(0, cleaningActivitiesCopy[0])
  }

  const editLimpezaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const cleaningActivitiesCopy = cleaningActivities
    itensFormListCopy.Limpeza[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editCleaningActivity?.(0, cleaningActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Limpeza.length ? (
        <div>
          <p>Limpeza</p>
          {itensFormList.Limpeza.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLimpezaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editLimpezaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editLimpezaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLimpezaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editLimpezaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editLimpezaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemLimpeza(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


    </div>
  );
};

export default CleaningStepForm;
