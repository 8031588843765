import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
  ListContainerItensGas,
  ItemContentListGas,
  ListContainerItensTubulacao,
  ItemContentListTubulacao,
  ListContainerItensEmendas,
  ItemContentListEmendas,
} from "./style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { GasFormType } from "../../../../Models/Hydraulic/type";

import FooterBudget from "../../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  Gas: GasFormType
}

const GasBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [pontoDeGas, setPontoDeGas] = useState<string[]>(["0,00"])
  const [retirarPontoDeGas, setRetirarPontoDeGas] = useState<string[]>(["0,00"])
  const [tubulacao, setTubulacao] = useState<string[]>(["0,00"])
  const [emendas, setEmendas] = useState<string[]>(["0,00"])

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Gas.PontoDeGas.forEach(() => {
      setPontoDeGas((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.Gas.RetirarPontoDeGas?.forEach(() => {
      setRetirarPontoDeGas((prev) => [...prev, "0,00"])
    })

    activity.Gas.Tubulacao?.forEach(() => {
      setTubulacao((prev) => [...prev, "0,00"])
    })

    activity.Gas.Emendas?.forEach(() => {
      setEmendas((prev) => [...prev, "0,00"])
    })
  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const pontoDeGasTotal = sum(pontoDeGas)
    const retirarPontoDeGasTotal = sum(retirarPontoDeGas)
    const tubulacaoTotal = sum(tubulacao)
    const emendasTotal = sum(emendas)

    setTotalBudgetPayment(sum([pontoDeGasTotal, retirarPontoDeGasTotal, tubulacaoTotal, emendasTotal]))
  }, [pontoDeGas, retirarPontoDeGas, tubulacao, emendas])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const pontoDeGasTotal = sum(pontoDeGas)
  const retirarPontoDeGasTotal = sum(retirarPontoDeGas)
  const tubulacaoTotal = sum(tubulacao)
  const emendasTotal = sum(emendas)

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([pontoDeGasTotal, retirarPontoDeGasTotal, tubulacaoTotal, emendasTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const pontoDeGasMargin: number[] = []
    const retirarPontoDeGasMargin: number[] = []
    const tubulacaoMargin: number[] = []
    const emendasMargin: number[] = []

    pontoDeGas.forEach((item) => {
      if (item != "0,00") {
        pontoDeGasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    retirarPontoDeGas.forEach((item) => {
      if (item != "0,00") {
        retirarPontoDeGasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    tubulacao.forEach((item) => {
      if (item != "0,00") {
        tubulacaoMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    emendas.forEach((item) => {
      if (item != "0,00") {
        emendasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          PontoDeGas: pontoDeGasMargin,
          RetirarPontoDeGas: retirarPontoDeGasMargin,
          Tubulacao: tubulacaoMargin,
          Emendas: emendasMargin
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensGas>

        {activity.Gas.PontoDeGas?.map((item, index) => (
          <div key={index}>
            {activity.Gas.PontoDeGas.length > 1 ? (<P>Ponto de gás {index + 1}</P>) : (<P>Ponto de gás</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListGas >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, pontoDeGas, setPontoDeGas, e.target.value)} />
              </ContentInputPrice>
              {pontoDeGas && pontoDeGas[index] !== '0' ?
                (<p className="item">{pontoDeGas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListGas>
          </div>
        ))}

      </ListContainerItensGas>

      <ListContainerItensGas>
        {activity.Gas.RetirarPontoDeGas?.map((item, index) => (
          <div key={index}>
            {activity.Gas.RetirarPontoDeGas.length > 1 ? (<P>Retirar ponto de gás {index + 1}</P>) : (<P>Retirar ponto de gás</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListGas >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, retirarPontoDeGas, setRetirarPontoDeGas, e.target.value)} />
              </ContentInputPrice>
              {retirarPontoDeGas && retirarPontoDeGas[index] !== '0' ?
                (<p className="item">{retirarPontoDeGas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListGas>
          </div>
        ))}
      </ListContainerItensGas>

      <ListContainerItensTubulacao>
        {activity.Gas.Tubulacao?.map((item, index) => (
          <div key={index}>
            {activity.Gas.Tubulacao.length > 1 ? (<P>Tubulação {index + 1}</P>) : (<P>Tubulação</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListTubulacao >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, tubulacao, setTubulacao, e.target.value)} />
              </ContentInputPrice>
              {tubulacao && tubulacao[index] !== '0' ?
                (<p className="item">{tubulacao[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListTubulacao>
          </div>
        ))}
      </ListContainerItensTubulacao>

      <ListContainerItensEmendas>
        {activity.Gas.Emendas?.map((item, index) => (
          <div key={index}>
            {activity.Gas.Emendas.length > 1 ? (<P>Emendas {index + 1}</P>) : (<P>Emendas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListEmendas >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, emendas, setEmendas, e.target.value)} />
              </ContentInputPrice>
              {emendas && emendas[index] !== '0' ?
                (<p className="item">{emendas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListEmendas>
          </div>
        ))}

      </ListContainerItensEmendas>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default GasBudget;