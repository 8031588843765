import React, { useEffect } from 'react'

import { UseTablewareAndMetalsActivityContext } from "../../../Context/ActivitiesContext/TablewareAndMetals/TablewareAndMetalsContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"
import { textButton } from '../script/textButtonSubmit'
import { RequestApi } from "../RequestApi"

import Accessories from '../../StepsForm/TablewareAndMetals/Accessories'
import Cuba from '../../StepsForm/TablewareAndMetals/Vats'
import FaucetAndMixer from '../../StepsForm/TablewareAndMetals/FaucetAndMixer'
import Showers from '../../StepsForm/TablewareAndMetals/Showers'
import Vases from '../../StepsForm/TablewareAndMetals/Vases'

import { Container } from "../style"
import MaterialsTablewareAndMetalsStepForm from '../../StepsForm/TablewareAndMetals/Materials'

const TablewareAndMetalsModifyActivities: React.FC<activityProps> = (props) => {
    const { tablewareAndMetalsActivities, setActivity, setIndexTablewareAndMetalsActivitySelected } = UseTablewareAndMetalsActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            if (props.endPointRequestApi === "activityReplace") {
                setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
            } else if (props.endPointRequestApi === "sendCotations") {
                setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
            }
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexTablewareAndMetalsActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Vasos" ? (<Vases stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Torneira e misturador" ? (<FaucetAndMixer stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Cubas" ? (<Cuba stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Chuveiros" ? (<Showers stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Acessorios" ? (<Accessories stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsTablewareAndMetalsStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(tablewareAndMetalsActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(tablewareAndMetalsActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default TablewareAndMetalsModifyActivities