import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../../style"
import { GreenDestack } from "../../../style"
import { InstallationsFormType } from "../../../../../Models/Eletric/type";

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface activityProps {
    selectedBudget?: boolean,
    Installations?: InstallationsFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        Lampadas?: string[]
        Interruptores?: string[]
        Tomadas?: string[]
        FitasDeLed?: string[]
        Luminarias?: string[]
        Spot?: string[]
        Arandela?: string[]
        Trilhos?: string[]
        Pendentes?: string[]
        Antena?: string[]
        CaboDeInternet?: string[]
        Interfone?: string[]
        CameraDeSeguranca?: string[]
        Sensores?: string[]
        Plafon?: string[]
        PontoDeDados?: string[]
        PontoDeTv?: string[]
        Exaustor?: string[]
        PontoDeForca?: string[]
    }
}

export const InstalacoesComparative: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: any, activityId?: string, itemType?: any) => {
        const budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        const totalBudgetsList: number[] = []

        if (!totalItem) {
            return <p>R$0,00</p>
        }

        const minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        }, 1000000000)

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>

    }

    return (
        <ContainerItensBudget>
            <>
                {activity.Installations?.Lampadas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Lampadas</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Lampadas?.map((item, index) => (
                            <div key={index}>
                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Lampadas ? renderItemBudget(budget.budget?.Lampadas[index], budget.activityId, 'Lampadas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Lampadas ? renderItemBudget(budget.budget?.Lampadas[index], budget.activityId, 'Lampadas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>
                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Interruptores.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Interruptores</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Interruptores?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Interruptores ? renderItemBudget(budget.budget?.Interruptores[index], budget.activityId, 'Interruptores') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Interruptores ? renderItemBudget(budget.budget?.Interruptores[index], budget.activityId, 'Interruptores') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Tomadas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Tomadas</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Tomadas?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Tomadas ? renderItemBudget(budget.budget?.Tomadas[index], budget.activityId, 'Tomadas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Tomadas ? renderItemBudget(budget.budget?.Tomadas[index], budget.activityId, 'Tomadas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.FitasDeLed.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Fitas de led</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.FitasDeLed?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.FitasDeLed ? renderItemBudget(budget.budget?.FitasDeLed[index], budget.activityId, 'Fitas de led') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.FitasDeLed ? renderItemBudget(budget.budget?.FitasDeLed[index], budget.activityId, 'Fitas de led') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Luminarias.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Luminárias</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Luminarias?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Luminarias ? renderItemBudget(budget.budget?.Luminarias[index], budget.activityId, 'Luminarias') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Luminarias ? renderItemBudget(budget.budget?.Luminarias[index], budget.activityId, 'Luminarias') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Spot.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Spot</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Spot?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Spot ? renderItemBudget(budget.budget?.Spot[index], budget.activityId, 'Spot') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Spot ? renderItemBudget(budget.budget?.Spot[index], budget.activityId, 'Spot') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Arandela.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Arandela</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Arandela?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Arandela ? renderItemBudget(budget.budget?.Arandela[index], budget.activityId, 'Arandela') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Arandela ? renderItemBudget(budget.budget?.Arandela[index], budget.activityId, 'Arandela') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Trilhos.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Trilhos</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Trilhos?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Trilhos ? renderItemBudget(budget.budget?.Trilhos[index], budget.activityId, 'Trilhos') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Trilhos ? renderItemBudget(budget.budget?.Trilhos[index], budget.activityId, 'Trilhos') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Pendentes.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Pendentes</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Pendentes?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Pendentes ? renderItemBudget(budget.budget?.Pendentes[index], budget.activityId, 'Pendentes') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Pendentes ? renderItemBudget(budget.budget?.Pendentes[index], budget.activityId, 'Pendentes') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Antena.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Antena</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Antena?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Antena ? renderItemBudget(budget.budget?.Antena[index], budget.activityId, 'Antena') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Antena ? renderItemBudget(budget.budget?.Antena[index], budget.activityId, 'Antena') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.CaboDeInternet.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Cabo de internet</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.CaboDeInternet?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.CaboDeInternet ? renderItemBudget(budget.budget?.CaboDeInternet[index], budget.activityId, 'Cabo de internet') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.CaboDeInternet ? renderItemBudget(budget.budget?.CaboDeInternet[index], budget.activityId, 'Cabo de internet') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Interfone.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Interfone</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Interfone?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Interfone ? renderItemBudget(budget.budget?.Interfone[index], budget.activityId, 'Interfone') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Interfone ? renderItemBudget(budget.budget?.Interfone[index], budget.activityId, 'Interfone') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Installations?.CameraDeSeguranca.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Câmera de segurança</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.CameraDeSeguranca?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.CameraDeSeguranca ? renderItemBudget(budget.budget?.CameraDeSeguranca[index], budget.activityId, 'Camera de seguranca') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.CameraDeSeguranca ? renderItemBudget(budget.budget?.CameraDeSeguranca[index], budget.activityId, 'Camera de seguranca') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Installations?.Sensores.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Sensores</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Sensores?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Sensores ? renderItemBudget(budget.budget?.Sensores[index], budget.activityId, 'Sensores') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Sensores ? renderItemBudget(budget.budget?.Sensores[index], budget.activityId, 'Sensores') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Installations?.Plafon.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Plafon</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Plafon?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Plafon ? renderItemBudget(budget.budget?.Plafon[index], budget.activityId, 'Plafon') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Plafon ? renderItemBudget(budget.budget?.Plafon[index], budget.activityId, 'Plafon') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Installations?.PontoDeDados.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de dados</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.PontoDeDados?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeDados ? renderItemBudget(budget.budget?.PontoDeDados[index], budget.activityId, 'Ponto de dados') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeDados ? renderItemBudget(budget.budget?.PontoDeDados[index], budget.activityId, 'Ponto de dados') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Installations?.PontoDeTv.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de TV</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.PontoDeTv?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeTv ? renderItemBudget(budget.budget?.PontoDeTv[index], budget.activityId, 'Ponto de TV') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeTv ? renderItemBudget(budget.budget?.PontoDeTv[index], budget.activityId, 'Ponto de TV') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.Exaustor.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Exaustor</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.Exaustor?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Exaustor ? renderItemBudget(budget.budget?.Exaustor[index], budget.activityId, 'Exaustor') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Exaustor ? renderItemBudget(budget.budget?.Exaustor[index], budget.activityId, 'Exaustor') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}

                {activity.Installations?.PontoDeForca.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de força</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Installations?.PontoDeForca?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 2).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeForca ? renderItemBudget(budget.budget?.PontoDeForca[index], budget.activityId, 'Ponto de forca') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeForca ? renderItemBudget(budget.budget?.PontoDeForca[index], budget.activityId, 'Ponto de forca') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (null)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
            </>
        </ContainerItensBudget>
    )
}

export default InstalacoesComparative