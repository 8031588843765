import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";

import {
  Container,
  TopBarBudget,
  ContentButtonTopBarBudget,
  FormContainer,
  ModalContent,
  FormContainerOf3dPictures,
  ContainerModal,
  HeaderFormContainer,
  FormContainerOfProjectArchives,
} from "./style";

import {
  allActivitiesProps,
  ProjectProps,
  SupplierProps,
  InfoArchivesProps,
} from "../../types/typesEntitiesProject";

import HeaderBudgets from "../../components/HeaderBudgets";
import Pagination from "./Components/Pagination";

import DemolitionSupplierBudgetPerItem from "./Demolicao/Demolicao/PorItem";
import DemolitionSupplierBudgetPerSet from "./Demolicao/Demolicao/PorConjunto";
import FundacaoBudget from "./Alvenaria/Fundacao";
import EstruturaBudget from "./Alvenaria/Estrutura";
import FechamentoBudget from "./Alvenaria/Fechamento";
import PisoBudget from "./Alvenaria/Piso";
import LajeBudget from "./Alvenaria/Laje";
import SoculoBudget from "./Alvenaria/Soculo";
import RequadramentoVaoBudget from "./Alvenaria/Requadramento";
import EscadasBudget from "./Alvenaria/Escadas";
import ImpermeabilizacaoBudget from "./Impermeabilizacao/Impermeabilizacao";
import PainelEletricoBudget from "./Eletrica/PainelEletrico";
import PassagemDeConduites from "./Eletrica/PassagemDeInfra";
import PassagemDeFiosBudget from "./Eletrica/PassagemDeFios";
import AutomacoesBudget from "./Eletrica/Automacoes";
import InstalacoesBudget from "./Eletrica/Instalacoes";
import AguaQuenteBudget from "./Hidraulica/AguaQuente";
import AguaFriaBudget from "./Hidraulica/AguaFria";
import EsgotoBudget from "./Hidraulica/Esgoto";
import GasBudget from "./Hidraulica/Gas";
import ArCondicionadoBudget from "./ArCondicionado/PorItem";
import ArCondicionadoBudgetPerSet from "./ArCondicionado/PorConjunto";
import RevestimentoDeParedeBudget from "./PisoRevestimento/RevestimentoDeParede";
import RevestimentoDePisoCeramicoBudget from "./PisoRevestimento/RevestimentoDePisoCeramico";
import RevestimentoDePisoVinilicoBudget from "./PisoRevestimento/RevestimentoDePisoVinilico";
import AssoalhoBudget from "./PisoRevestimento/Assoalho";
import RevestimentoDePisoPedrasBudget from "./PisoRevestimento/RevestimentoDePisoPedras";
import RevestimentoMadeiraBudget from "./PisoRevestimento/RevestimentoDeMadeira";
import RodapeBudget from "./PisoRevestimento/Rodape";
import ForroBudget from "./Forro";
import PinturaBudget from "./Pintura/Pintura";
import PapelDeParedeBudget from "./Pintura/PapelParede";
import VasosBudget from "./LoucasMetais/Vasos";
import CubasBudget from "./LoucasMetais/Cubas";
import TorneiraMisturadorBudget from "./LoucasMetais/TorneiraMisturador";
import ChuveiroBudget from "./LoucasMetais/Chuveiro";
import AcessorioBudget from "./LoucasMetais/Acessorios";
import TelhadoBudget from "./Telhado";
import FechamentoSacadaBudget from "./Vidracaria/FechamentoSacada";
import BoxBanheiroBudget from "./Vidracaria/BoxBanheiro";
import PortaVidroEsquadriasBudget from "./Vidracaria/PortaVidroEsquadrias";
import EspelhosBudget from "./Vidracaria/Espelhos";
import NichoBudget from "./Alvenaria/Nicho";
import RevestimentoBudget from "./Marmoraria/Revestimento";
import TentoSoleiraBagueteRodaBaseBudget from "./Marmoraria/TentoSoleiraBagueteRodaBase";
import PiasBancadasBudget from "./Marmoraria/PiasBancadas";
import PrateleiraBudget from "./Marmoraria/Prateleiras";
import MesaSerralheriaBudget from "./Serralheria/Mesas";
import MarcenariaBudget from "./Marcenaria";
import EsquadriasBudget from "./Esquadrias/Esquadrias";
import DoorsAndWindowsBudget from "./PortasEJanelas/PortasEJanelas";
import MateriaisBudget from "./Materiais/index";
import anexoIcon from "../../assets/anexoIcon.svg";
import imageIcon from "../../assets/imageIcon.svg";
import videoIcon from "../../assets/videoIcon.svg";
import {
  Button,
  Table,
} from "../../components/stylesDomElements/htmlStyledElements";
import iconCloseModal from "../../assets/XIcon.svg";
import { Iframe } from "../FollowUpProject/style";
import { UseUserContext } from "../../Context/UserContext";
import TrTable from "./Tr/index";
import MultipleSelected from "./MultipleSelected";
import MobiliarioBudget from "./Mobiliario/Mobiliario";
import ProjetoArquitetonicoBudget from "./ProjectStep/ArchitecturalProject";
import ProjetoEstruturalBudget from "./ProjectStep/StructuralProject";
import ProjetoEletricoBudget from "./ProjectStep/EletricProject";
import ProjetoHidrossanitarioBudget from "./ProjectStep/HydrosanitaryProject";
import ProjetoDeArCondicionadoBudget from "./ProjectStep/AirConditionerProject";
import LimpezaBudget from "./Limpeza";
import MudancaBudget from "./Mudança";

const Budget: React.FC = () => {
  const { projectSelected } = UseUserContext();
  const paramUrl = useParams();
  const [activity, setActivity] = useState<allActivitiesProps>();
  const [margin, setMargin] = useState<number>(0);

  const [supplier, setSupplier] = useState<SupplierProps>({});

  const [archives, setArchives] = useState<InfoArchivesProps[]>([]);

  const [project, setProject] = useState<ProjectProps>();
  const [loading, setLoading] = useState<boolean>(true);

  const [typeOfModal, setTypeOfModal] = useState<string>("");
  const [archivesSelected, setArchivesSelected] = useState<boolean>(false);
  const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
  const [budgetType, setBudgetType] = useState<string>("set");

  const [videosSelected, setVideosSelected] = useState<boolean>(false);
  const [showDownloadVideoButton, setShowDownloadVideoButton] =
    useState<boolean>(false);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
          setLoading(false);
        });
    }
  }, [projectSelected]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/rateMarginArquitetoolAndArchitect/${projectSelected}`
        )
        .then((res) => {
          console.log(res.data.marginTotal);
          setMargin(res.data.marginTotal);
        });
    }
  }, [projectSelected]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/listArchiveProjectInfo/${projectSelected}`
        )
        .then((res) => {
          setArchives(res.data);
        });
    }
  }, [projectSelected]);

  useEffect(() => {
    if (paramUrl.projectId) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${paramUrl.projectId}`)
        .then((res) => {
          setProject(res.data[0]);
        });
    }
  }, [paramUrl.projectId]);

  useEffect(() => {
    if (paramUrl.activityId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/activity/${paramUrl.activityId}`
        )
        .then((res) => {
          setActivity(res.data[0]);
        });
    }
  }, [paramUrl.activityId]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/supplier/${paramUrl.supplierId}`)
      .then((res) => {
        setSupplier(res.data);
      });
  }, [paramUrl.supplierId, supplier]);

  return (
    <Container budgetType={budgetType}>
      {typeOfModal === "Fotos 3D" ? (
        <>
          <ContainerModal>
            <>
              {!project?.constructinEnbededLink ? (
                <FormContainer>
                  <HeaderFormContainer>
                    <p></p>
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeOfModal("")}
                    />
                  </HeaderFormContainer>
                  <ModalContent>
                    <h1>
                      Não há fotos 3D da obra <br></br>disponíveis até o momento
                    </h1>
                  </ModalContent>
                </FormContainer>
              ) : (
                <FormContainerOf3dPictures>
                  <HeaderFormContainer>
                    <p>Fotos 3D da obra</p>
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeOfModal("")}
                    />
                  </HeaderFormContainer>
                  <Iframe src={project?.constructinEnbededLink}></Iframe>
                </FormContainerOf3dPictures>
              )}
            </>
          </ContainerModal>
        </>
      ) : null}

      {typeOfModal === "Projeto da obra" ? (
        <>
          <ContainerModal>
            <div>
              {!archives ? (
                <FormContainer>
                  <HeaderFormContainer>
                    <p></p>
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeOfModal("")}
                    />
                  </HeaderFormContainer>
                  <ModalContent>
                    <h1>
                      Não há arquivos da obra <br></br>disponíveis até o momento
                    </h1>
                  </ModalContent>
                </FormContainer>
              ) : (
                <FormContainerOfProjectArchives>
                  <HeaderFormContainer>
                    <p>Arquivos da obra</p>
                    {showDownloadButton ? (
                      <Button onClick={() => {}}>Baixar</Button>
                    ) : null}
                    {/*                     {archives.length >= 2 && !archivesSelected ? (<Button onClick={() => selectModeOn()}>Selecionar todos</Button>) : (<Button onClick={() => selectModeOff()}>Selecionar todos</Button>)}
                     */}{" "}
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeOfModal("")}
                    />
                  </HeaderFormContainer>
                  <Table>
                    <thead>
                      <tr className="headerTable">
                        <th>Arquivo</th>
                        <th>Tipo</th>
                        <th>Tamanho</th>
                        <th>Última modificação</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {archives.map((item, index) =>
                        item.infoArchives.map((archive, index) =>
                          archive.type === "application/pdf" &&
                          !archivesSelected ? (
                            <TrTable
                              key={index}
                              projectId={item.projectId}
                              archiveUrl={archive.archiveUrl}
                              archiveName={archive.archiveName}
                              size={archive.size}
                              type={archive.type}
                              modification={archive.modification}
                            ></TrTable>
                          ) : null
                        )
                      )}

                      {archives.map((item, index) =>
                        item.infoArchives.map((archive, index) =>
                          archive.type === "application/pdf" &&
                          archivesSelected ? (
                            <MultipleSelected
                              key={index}
                              projectId={item.projectId}
                              archiveUrl={archive.archiveUrl}
                              archiveName={archive.archiveName}
                              size={archive.size}
                              type={archive.type}
                              modification={archive.modification}
                            ></MultipleSelected>
                          ) : null
                        )
                      )}
                    </tbody>
                  </Table>
                </FormContainerOfProjectArchives>
              )}
            </div>
          </ContainerModal>
        </>
      ) : null}

      {typeOfModal === "Filmagem da obra" ? (
        <>
          <ContainerModal>
            <div>
              {loading ? (
                <></>
              ) : !archives ? (
                <FormContainer>
                  <HeaderFormContainer>
                    <p></p>
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeOfModal("")}
                    />
                  </HeaderFormContainer>
                  <ModalContent>
                    <h1>
                      Não há filmagens da obra <br></br>disponíveis até o
                      momento
                    </h1>
                  </ModalContent>
                </FormContainer>
              ) : (
                <FormContainerOfProjectArchives>
                  <HeaderFormContainer>
                    <p>Arquivos da obra</p>
                    {showDownloadVideoButton ? <Button>Baixar</Button> : null}
                    {/*                       {archives.length >= 2 && !videosSelected ? (<Button onClick={() => videoSelectModeOn()}>Selecionar</Button>) : (<Button onClick={() => videoSelectModeOff()}>Selecionar</Button>)}
                     */}{" "}
                    <img
                      src={iconCloseModal}
                      alt="icone de fechar"
                      onClick={() => setTypeOfModal("")}
                    />
                  </HeaderFormContainer>
                  <Table>
                    <thead>
                      <tr className="headerTable">
                        <th>Arquivo</th>
                        <th>Tipo</th>
                        <th>Tamanho</th>
                        <th>Última modificação</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {archives.map((item, index) =>
                        item.infoArchives.map((archive, index) =>
                          archive.type === "video/mp4" && !videosSelected ? (
                            <TrTable
                              key={index}
                              projectId={item.projectId}
                              archiveUrl={archive.archiveUrl}
                              archiveName={archive.archiveName}
                              size={archive.size}
                              type={archive.type}
                              modification={archive.modification}
                            ></TrTable>
                          ) : null
                        )
                      )}

                      {archives.map((item, index) =>
                        item.infoArchives.map((archive, index) =>
                          archive.type === "video/mp4" && videosSelected ? (
                            <MultipleSelected
                              key={index}
                              projectId={item.projectId}
                              archiveUrl={archive.archiveUrl}
                              archiveName={archive.archiveName}
                              size={archive.size}
                              type={archive.type}
                              modification={archive.modification}
                            ></MultipleSelected>
                          ) : null
                        )
                      )}
                    </tbody>
                  </Table>
                </FormContainerOfProjectArchives>
              )}
            </div>
          </ContainerModal>
        </>
      ) : null}

      <HeaderBudgets projetcName={project?.name} adress={project?.adress} />

      <div className="paginationContent">
        <Pagination
          projectId={paramUrl.projectId || ""}
          supplierId={paramUrl.supplierId || ""}
          activityId={paramUrl.activityId || ""}
        />
      </div>
      <div className="budgetTypeSelection">
        <label htmlFor="">Orçar por:</label>
        <button className="itemButton" onClick={() => setBudgetType("item")}>
          Item
        </button>
        <button className="setButton" onClick={() => setBudgetType("set")}>
          Conjunto
        </button>
      </div>
      <TopBarBudget>
        <div className="left">
          <p>Enviar orçamento: {activity?.activityName}</p>
        </div>
        <div className="right">
          {archives ? (
            <ContentButtonTopBarBudget
              onClick={() => setTypeOfModal("Filmagem da obra")}
            >
              <img src={videoIcon} alt="icone de filmadora" />
              Visita gravada
            </ContentButtonTopBarBudget>
          ) : null}
          {project?.constructinEnbededLink ? (
            <ContentButtonTopBarBudget
              onClick={() => setTypeOfModal("Fotos 3D")}
            >
              <img src={imageIcon} alt="Icone de imagem" />
              Fotos 3D da obra
            </ContentButtonTopBarBudget>
          ) : null}
          {archives ? (
            <ContentButtonTopBarBudget
              onClick={() => setTypeOfModal("Projeto da obra")}
            >
              <img src={anexoIcon} alt="icone de clip anexo" />
              <a>Projeto da obra</a>
            </ContentButtonTopBarBudget>
          ) : null}
        </div>
      </TopBarBudget>

      <div>
        {activity?.activityType === "Demolicao" ? (
          budgetType === "item" ? (
            <DemolitionSupplierBudgetPerItem
              activity={activity}
              supplier={supplier}
              margin={margin}
            />
          ) : budgetType === "set" ? (
            <DemolitionSupplierBudgetPerSet
              activity={activity}
              supplier={supplier}
              margin={margin}
            />
          ) : null
        ) : activity?.activityType === "Fundacao" ? (
          <FundacaoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Estrutura" ? (
          <EstruturaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Fechamento" ? (
          <FechamentoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Piso" ? (
          <PisoBudget activity={activity} supplier={supplier} margin={margin} />
        ) : activity?.activityType === "Laje" ? (
          <LajeBudget activity={activity} supplier={supplier} margin={margin} />
        ) : activity?.activityType === "Requadramento de vao" ? (
          <RequadramentoVaoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Escadas" ? (
          <EscadasBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Materiais" ? (
          <MateriaisBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Impermeabilizacao" ? (
          <ImpermeabilizacaoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Painel eletrico" ? (
          <PainelEletricoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Passagem da infra" ? (
          <PassagemDeConduites
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Passagem de fios" ? (
          <PassagemDeFiosBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Automacoes" ? (
          <AutomacoesBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Instalacoes" ? (
          <InstalacoesBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Agua quente" ? (
          <AguaQuenteBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Agua fria" ? (
          <AguaFriaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Esgoto" ? (
          <EsgotoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Gas" ? (
          <GasBudget activity={activity} supplier={supplier} margin={margin} />
        ) : activity?.activityType === "Ar condicionado" ? (
          budgetType === "item" ? (
            <ArCondicionadoBudget
              activity={activity}
              supplier={supplier}
              margin={margin}
            />
          ) : budgetType === "set" ? (
            <ArCondicionadoBudgetPerSet
              activity={activity}
              supplier={supplier}
              margin={margin}
            />
          ) : null
        ) : activity?.activityType === "Revestimento de parede" ? (
          <RevestimentoDeParedeBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Revestimento de piso ceramico" ? (
          <RevestimentoDePisoCeramicoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Revestimento de piso vinilico" ? (
          <RevestimentoDePisoVinilicoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Assoalho" ? (
          <AssoalhoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Revestimento de piso - Pedras" ? (
          <RevestimentoDePisoPedrasBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Rodape" ? (
          <RodapeBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Revestimento de madeira" ? (
          <RevestimentoMadeiraBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Forro" ? (
          <ForroBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Pintura" ? (
          <PinturaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Papel de parede" ? (
          <PapelDeParedeBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Vasos" ? (
          <VasosBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Cubas" ? (
          <CubasBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Torneira e misturador" ? (
          <TorneiraMisturadorBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Chuveiros" ? (
          <ChuveiroBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Acessorios" ? (
          <AcessorioBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Telhado" ? (
          <TelhadoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Fechamento de sacada" ? (
          <FechamentoSacadaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Box de banheiro" ? (
          <BoxBanheiroBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Porta de vidro e esquadrias" ? (
          <PortaVidroEsquadriasBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Espelhos" ? (
          <EspelhosBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Revestimento" ? (
          <RevestimentoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Tento" ||
          activity?.activityType === "Soleira" ||
          activity?.activityType === "Roda base" ||
          activity?.activityType === "Baguete" ? (
          <TentoSoleiraBagueteRodaBaseBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Pias e bancadas" ? (
          <PiasBancadasBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Prateleira" ? (
          <PrateleiraBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Mesas" ? (
          <MesaSerralheriaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Marcenaria" ? (
          <MarcenariaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Esquadrias" ? (
          <EsquadriasBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Mobiliario" ? (
          <MobiliarioBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Soculo em alvenaria" ? (
          <SoculoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Nicho" ? (
          <NichoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Projeto arquitetonico" ? (
          <ProjetoArquitetonicoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Projeto estrutural" ? (
          <ProjetoEstruturalBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Projeto eletrico" ? (
          <ProjetoEletricoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Projeto hidrossanitario" ? (
          <ProjetoHidrossanitarioBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Projeto de ar condicionado" ? (
          <ProjetoDeArCondicionadoBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Limpeza" ? (
          <LimpezaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Mudança" ? (
          <MudancaBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : activity?.activityType === "Portas e janelas" ? (
          <DoorsAndWindowsBudget
            activity={activity}
            supplier={supplier}
            margin={margin}
          />
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};

export default Budget;
