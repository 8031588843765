import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import { UseModalContext } from "../../Context/ModalContext";

import { allActivitiesProps } from "../../types/typesEntitiesProject";
import { activityAndPendencesType, attachmentProps } from "./type";

import {
  ContainerCheckBox,
  Button,
} from "../stylesDomElements/htmlStyledElements";
import {
  Container,
  FlexContent,
  ContainerListPendences,
  Input,
  ElementDescribleShowItens,
  SendFile,
  AttachmentList,
  ActivityDateContainer,
} from "./style";

import SupplierDocumentList from "./Components/SupplierDocumentList";
import Attachment from "./Components/AttachmentController";
import NewActivityPendency from "./Components/NewActivity";
import ActivityDescription from "../ActivityDescrition";

import { addFile } from "./script/attachment";

import addIcon from "../../assets/addIcon.svg";
import checkIcon from "../../assets/checkIconGreen.svg";
import downloadIcon from "../../assets/dowloadIcon.svg";

import {
  editCheckActivityBeforeInitService,
  editCheckActivityAfterService,
  editCheckActivityDuringServiceService,
} from "./script/editCheck";

import {
  editAfterServiceActivityName,
  editBeforeInitServiceActivityName,
  editDuringServiceActivityName,
} from "./script/editName";

import {
  removeBeforeInitServiceAcitivity,
  removeDuringServiceAcitivity,
  removeAfterServiceAcitivity,
} from "./script/deleteActivity";

interface activity {
  activity: allActivitiesProps;
}

export const DemolicaoActivitiesAndPendences: React.FC<activity> = (props) => {
  const { setModalNameHeader } = UseModalContext();
  const [modifyStaus, setModifyStatus] = useState<boolean>(false);
  const [activityAndPendences, setActivityAndPendences] =
    useState<activityAndPendencesType>();
  const [attachmentList, setAttachmentList] = useState<attachmentProps[]>([]);
  const [progressUploaded, setProgressUploaded] = useState<number>(0);

  const [activityStartDate, setActivityStartDate] = useState<string>("");
  const [activityEndDate, setActivityEndDate] = useState<string>("");

  useEffect(() => {
    if (props.activity.startDate) {
      setActivityStartDate(props.activity.startDate);
    }
    if (props.activity.endDate) {
      setActivityEndDate(props.activity.endDate);
    }
  }, []);

  const updateActivityDate = () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/updateActivityDate/${props.activity._id}/${props.activity.stepId}/${props.activity.projectId}`,
        {
          startDate: activityStartDate,
          endDate: activityEndDate,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    setModalNameHeader(
      `Atividades e pendências - ${props.activity.activityName}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/listActivityAndPendences/${props.activity._id}`
      )
      .then((res) => {
        setActivityAndPendences(res.data[0]);
      });
  }, [props.activity._id]);

  useEffect(() => {
    const currentDate = moment();
    const startDate = moment(activityStartDate, "DD/MM/YYYY").startOf("day");
    const endDate = moment(activityEndDate, "DD/MM/YYYY").startOf("day");

    const activityTotalTime = endDate.diff(startDate);
    const conclusionTime = currentDate.diff(startDate);
    let totalActivities = (conclusionTime / activityTotalTime) * 100;

    if (totalActivities > 100 || endDate.isBefore(currentDate)) {
      totalActivities = 100;
    } else if (totalActivities < 0) {
      totalActivities = 0;
    }

    axios.put(
      `${
        process.env.REACT_APP_BASE_URL
      }/updateActivityComplete/${totalActivities.toFixed(0)}/${
        props.activity._id
      }`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityAndPendences, activityStartDate, activityEndDate]);

  useEffect(() => {
    if (
      activityAndPendences?.architectActivities &&
      activityAndPendences.supplierActivities.length > 0
    ) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/updateActivityAndPendences`, {
          activityId: props.activity._id,
          supplierId: activityAndPendences.supplierId,
          supplierActivities: activityAndPendences.supplierActivities,
          architectActivities: activityAndPendences.architectActivities,
        })
        .then((res) => {
          if (modifyStaus && res.data?.supplierActivities) {
            setActivityAndPendences(res.data);
          }
          setModifyStatus(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityAndPendences]);

  const editActivityName = (
    activityStateVar: activityAndPendencesType,
    setStateVar: any,
    indexActivity: number,
    textValue: string
  ) => {
    const activityCopy = activityStateVar;
    activityCopy.supplierActivities.splice(indexActivity, 1, {
      activity: textValue,
      check: activityCopy.supplierActivities[indexActivity].check,
      renameOrDelete:
        activityCopy.supplierActivities[indexActivity].renameOrDelete,
      checkUpdatedAt: moment().format("DD/MM/YYYY"),
      createdAt: activityCopy.supplierActivities[indexActivity].createdAt,
      attachment: activityCopy.supplierActivities[indexActivity].attachment,
      attachmentList:
        activityCopy.supplierActivities[indexActivity].attachmentList,
    });
    setStateVar({ ...activityCopy });
  };

  const editCheckActivity = (
    activityStateVar: activityAndPendencesType,
    setStateVar: any,
    indexActivity: number,
    checkValue: boolean
  ) => {
    const activityCopy = activityStateVar;
    activityCopy.supplierActivities.splice(indexActivity, 1, {
      activity: activityCopy.supplierActivities[indexActivity].activity,
      check: checkValue,
      renameOrDelete:
        activityCopy.supplierActivities[indexActivity].renameOrDelete,
      checkUpdatedAt: moment().format("DD/MM/YYYY"),
      createdAt: activityCopy.supplierActivities[indexActivity].createdAt,
      attachment: activityCopy.supplierActivities[indexActivity].attachment,
      attachmentList:
        activityCopy.supplierActivities[indexActivity].attachmentList,
    });
    setStateVar({ ...activityCopy });
  };

  const removeAcitivity = (
    activityStateVar: activityAndPendencesType,
    setStateVar: any,
    indexActivity: number
  ) => {
    const activityCopy = activityStateVar;
    activityStateVar.supplierActivities.length > 1 ? (
      activityCopy.supplierActivities.splice(indexActivity, 1)
    ) : (
      <></>
    );
    setStateVar({ ...activityCopy });
  };

  const sendAttachment = (
    attachments: attachmentProps[],
    responsible: string
  ) => {
    const formData = new FormData();
    attachments.forEach((element) => {
      formData.append("file", element.archive[0][0]);
    });
    formData.append("responsible", responsible);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/newArchiveActivityAndPendence/${props.activity._id}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            let progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressUploaded(progress);
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };

  return (
    <Container>
      {props.activity._id ? (
        <NewActivityPendency
          setActivityAndPendences={setActivityAndPendences}
          activityId={props.activity._id}
        />
      ) : (
        <></>
      )}

      <ActivityDateContainer>
        <input
          type="date"
          name=""
          id=""
          value={`${activityStartDate.split("/")[2]}-${
            activityStartDate.split("/")[1]
          }-${activityStartDate.split("/")[0]}`}
          onChange={(e) =>
            setActivityStartDate(
              `${e.target.value.split("-")[2]}/${
                e.target.value.split("-")[1]
              }/${e.target.value.split("-")[0]}`
            )
          }
        />
        -
        <input
          type="date"
          name=""
          id=""
          value={`${activityEndDate.split("/")[2]}-${
            activityEndDate.split("/")[1]
          }-${activityEndDate.split("/")[0]}`}
          onChange={(e) =>
            setActivityEndDate(
              `${e.target.value.split("-")[2]}/${
                e.target.value.split("-")[1]
              }/${e.target.value.split("-")[0]}`
            )
          }
        />
        <button onClick={() => updateActivityDate()}>Atualizar data</button>
      </ActivityDateContainer>

      <FlexContent>
        <ActivityDescription activity={props.activity} />
        <ContainerListPendences>
          <ElementDescribleShowItens>
            <h3>Fornecedor</h3>
          </ElementDescribleShowItens>

          {activityAndPendences?.supplierId &&
          activityAndPendences.activityId ? (
            <SupplierDocumentList
              activityId={activityAndPendences?.activityId}
              supplierId={activityAndPendences?.supplierId}
            />
          ) : (
            <></>
          )}

          <ul>
            {activityAndPendences?.supplierActivities.map((element, index) => (
              <>
                <li key={index}>
                  <ContainerCheckBox>
                    <div className="activityContentItem">
                      <label htmlFor={`supplierActivity${index}`}>
                        <input
                          type="checkbox"
                          checked={element.check}
                          onClick={() =>
                            editCheckActivity(
                              activityAndPendences,
                              setActivityAndPendences,
                              index,
                              !element.check
                            )
                          }
                          name="supplierActivitiesAndPendences"
                          id={`supplierActivity${index}`}
                        />
                        <Input
                          type="text"
                          placeholder={element.activity}
                          onChange={(e) =>
                            editActivityName(
                              activityAndPendences,
                              setActivityAndPendences,
                              index,
                              e.target.value
                            )
                          }
                        />
                      </label>

                      <div className="actionsActivityContentItem">
                        {element.attachment &&
                        element.attachmentList?.length === 0 &&
                        progressUploaded === 0 ? (
                          <>
                            <Button
                              className="buttonAddFile"
                              onClick={() =>
                                addFile(
                                  attachmentList,
                                  setAttachmentList,
                                  "",
                                  "supplier"
                                )
                              }
                            >
                              <img src={addIcon} alt="Icone de adição" />
                              Adicionar
                            </Button>
                            <SendFile
                              className="checkIcon"
                              src={checkIcon}
                              alt="checkIcon"
                              onClick={() =>
                                sendAttachment(
                                  attachmentList.filter(
                                    (element) =>
                                      element.responsible === "supplier"
                                  ),
                                  "supplier"
                                )
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        <span
                          onClick={() =>
                            removeAcitivity(
                              activityAndPendences,
                              setActivityAndPendences,
                              index
                            )
                          }
                        >
                          X
                        </span>
                      </div>
                    </div>

                    {element.attachmentList?.length ? (
                      <AttachmentList>
                        {element.attachmentList.map((attachment, index) => (
                          <li key={index}>
                            <a href={attachment.url}>
                              <img
                                src={downloadIcon}
                                alt="icone de seta apontando para baixo"
                              />{" "}
                              {attachment.fileName}
                            </a>
                          </li>
                        ))}
                      </AttachmentList>
                    ) : (
                      <></>
                    )}

                    {element.attachment &&
                    element.attachmentList?.length === 0 &&
                    progressUploaded > 0 ? (
                      <p>Enviando arquivos: {progressUploaded}%</p>
                    ) : (
                      <></>
                    )}
                  </ContainerCheckBox>
                </li>
                {element.attachment && element.attachmentList?.length === 0 ? (
                  <Attachment
                    attachmentList={attachmentList}
                    setAttachmentList={setAttachmentList}
                    responsible="supplier"
                  />
                ) : (
                  <></>
                )}
              </>
            ))}
          </ul>
        </ContainerListPendences>
        <ContainerListPendences>
          <h3>Arquiteto</h3>
          <ul>
            <ElementDescribleShowItens>
              <p>Antes do início do serviço</p>
            </ElementDescribleShowItens>

            {activityAndPendences?.architectActivities?.beforeInitService.map(
              (element, index) =>
                element.renameOrDelete ? (
                  <>
                    <li>
                      <ContainerCheckBox>
                        <label htmlFor={`architectActivity${index}`}>
                          <input
                            type="checkbox"
                            checked={element.check}
                            onClick={() =>
                              editCheckActivityBeforeInitService(
                                activityAndPendences,
                                setActivityAndPendences,
                                index,
                                !element.check
                              )
                            }
                            name="architectActivitiesAndPendences"
                            id={`architectActivity${index}`}
                          />
                          <Input
                            type="text"
                            placeholder={element.activity}
                            onChange={(e) =>
                              editBeforeInitServiceActivityName(
                                activityAndPendences,
                                setActivityAndPendences,
                                index,
                                e.target.value
                              )
                            }
                          />
                          {element.attachment ? (
                            <>
                              <Button
                                className="buttonAddFile"
                                onClick={() =>
                                  addFile(
                                    attachmentList,
                                    setAttachmentList,
                                    "beforeInitService",
                                    "architect"
                                  )
                                }
                              >
                                <img src={addIcon} alt="Icone de adição" />
                                Adicionar
                              </Button>
                              <SendFile
                                className="checkIcon"
                                src={checkIcon}
                                alt="checkIcon"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          <span
                            onClick={() =>
                              removeBeforeInitServiceAcitivity(
                                activityAndPendences,
                                setActivityAndPendences,
                                index
                              )
                            }
                          >
                            X
                          </span>
                        </label>
                      </ContainerCheckBox>
                    </li>
                    {element.attachment ? (
                      <Attachment
                        attachmentList={attachmentList}
                        setAttachmentList={setAttachmentList}
                        responsible="architect"
                        period="beforeInitService"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <li>
                    <ContainerCheckBox>
                      <label htmlFor={`architectActivity${index}`}>
                        <input
                          type="checkbox"
                          checked={element.check}
                          onClick={() =>
                            editCheckActivityBeforeInitService(
                              activityAndPendences,
                              setActivityAndPendences,
                              index,
                              !element.check
                            )
                          }
                          name="architectActivitiesAndPendences"
                          id={`architectActivity${index}`}
                        />
                        <Input
                          type="text"
                          placeholder={element.activity}
                          disabled
                        />
                      </label>
                    </ContainerCheckBox>
                  </li>
                )
            )}
            <br />

            <ElementDescribleShowItens>
              <p>Durante o serviço</p>
            </ElementDescribleShowItens>
            {activityAndPendences?.architectActivities?.duringService.map(
              (element, index) =>
                element.renameOrDelete ? (
                  <>
                    <li>
                      <ContainerCheckBox>
                        <label htmlFor={`architectActivity${index}`}>
                          <input
                            type="checkbox"
                            checked={element.check}
                            onClick={() =>
                              editCheckActivityDuringServiceService(
                                activityAndPendences,
                                setActivityAndPendences,
                                index,
                                !element.check
                              )
                            }
                            name="architectActivitiesAndPendences"
                            id={`architectActivity${index}`}
                          />
                          <Input
                            type="text"
                            placeholder={element.activity}
                            onChange={(e) =>
                              editDuringServiceActivityName(
                                activityAndPendences,
                                setActivityAndPendences,
                                index,
                                e.target.value
                              )
                            }
                          />
                          {element.attachment ? (
                            <>
                              <Button
                                className="buttonAddFile"
                                onClick={() =>
                                  addFile(
                                    attachmentList,
                                    setAttachmentList,
                                    "duringService",
                                    "architect"
                                  )
                                }
                              >
                                <img src={addIcon} alt="Icone de adição" />
                                Adicionar
                              </Button>
                              <SendFile
                                className="checkIcon"
                                src={checkIcon}
                                alt="checkIcon"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          <span
                            onClick={() =>
                              removeDuringServiceAcitivity(
                                activityAndPendences,
                                setActivityAndPendences,
                                index
                              )
                            }
                          >
                            X
                          </span>
                        </label>
                      </ContainerCheckBox>
                    </li>
                    {element.attachment ? (
                      <Attachment
                        attachmentList={attachmentList}
                        setAttachmentList={setAttachmentList}
                        responsible="architect"
                        period="duringService"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <li>
                    <ContainerCheckBox>
                      <label htmlFor={`architectActivity${index}`}>
                        <input
                          type="checkbox"
                          checked={element.check}
                          onClick={() =>
                            editCheckActivityDuringServiceService(
                              activityAndPendences,
                              setActivityAndPendences,
                              index,
                              !element.check
                            )
                          }
                          name="architectActivitiesAndPendences"
                          id={`architectActivity${index}`}
                        />
                        <Input
                          type="text"
                          placeholder={element.activity}
                          disabled
                        />
                      </label>
                    </ContainerCheckBox>
                  </li>
                )
            )}
            <br />

            <ElementDescribleShowItens>
              <p>Após o serviço</p>
            </ElementDescribleShowItens>
            {activityAndPendences?.architectActivities?.afterService.map(
              (element, index) =>
                element.renameOrDelete ? (
                  <>
                    <li>
                      <ContainerCheckBox>
                        <label htmlFor={`architectActivity${index}`}>
                          <input
                            type="checkbox"
                            checked={element.check}
                            onClick={() =>
                              editCheckActivityAfterService(
                                activityAndPendences,
                                setActivityAndPendences,
                                index,
                                !element.check
                              )
                            }
                            name="architectActivitiesAndPendences"
                            id={`architectActivity${index}`}
                          />
                          <Input
                            type="text"
                            placeholder={element.activity}
                            onChange={(e) =>
                              editAfterServiceActivityName(
                                activityAndPendences,
                                setActivityAndPendences,
                                index,
                                e.target.value
                              )
                            }
                          />
                          {element.attachment ? (
                            <>
                              <Button
                                className="buttonAddFile"
                                onClick={() =>
                                  addFile(
                                    attachmentList,
                                    setAttachmentList,
                                    "afterService",
                                    "architect"
                                  )
                                }
                              >
                                <img src={addIcon} alt="Icone de adição" />
                                Adicionar
                              </Button>
                              <SendFile
                                className="checkIcon"
                                src={checkIcon}
                                alt="checkIcon"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          <span
                            onClick={() =>
                              removeAfterServiceAcitivity(
                                activityAndPendences,
                                setActivityAndPendences,
                                index
                              )
                            }
                          >
                            X
                          </span>
                        </label>
                      </ContainerCheckBox>
                    </li>
                    {element.attachment ? (
                      <Attachment
                        attachmentList={attachmentList}
                        setAttachmentList={setAttachmentList}
                        responsible="architect"
                        period="afterService"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <li>
                    <ContainerCheckBox>
                      <label htmlFor={`architectActivity${index}`}>
                        <input
                          type="checkbox"
                          checked={element.check}
                          onClick={() =>
                            editCheckActivityAfterService(
                              activityAndPendences,
                              setActivityAndPendences,
                              index,
                              !element.check
                            )
                          }
                          name="architectActivitiesAndPendences"
                          id={`architectActivity${index}`}
                        />
                        <Input
                          type="text"
                          placeholder={element.activity}
                          disabled
                        />
                      </label>
                    </ContainerCheckBox>
                  </li>
                )
            )}
          </ul>
        </ContainerListPendences>
      </FlexContent>
    </Container>
  );
};

export default DemolicaoActivitiesAndPendences;
