import React, { SetStateAction } from "react"
import { documentsProps } from "../../../documentType"

export const addDocument = (setDocumentsList: React.Dispatch<SetStateAction<documentsProps[]>>) => {
    setDocumentsList((prev) => [...prev, {
        photo: [],
        typeDocument: '',
        numberDocument: ''
    }])
}

export const removeDocument = (index: number, setDocumentsList: React.Dispatch<SetStateAction<documentsProps[]>>, documentsList: documentsProps[]) => {
    var documentListCopy = documentsList
    documentListCopy.splice(index, 1)
    setDocumentsList([...documentListCopy])
}

export const editFile = (e: React.ChangeEvent<HTMLInputElement>, index: number, setDocumentsList: React.Dispatch<SetStateAction<documentsProps[]>>, documentsList: documentsProps[]) => {
    console.log(index)
    if (e.target.files && e.target.files.item.name) {
        var documentListCopy = documentsList
        documentListCopy.splice(index, 1, {
            photo: [e.target.files],
            typeDocument: documentListCopy[index].typeDocument,
            numberDocument: documentListCopy[index].numberDocument
        })
        setDocumentsList([...documentListCopy])
    }
}

export const editTypeDocument = (value: string, index: number, setDocumentsList: React.Dispatch<SetStateAction<documentsProps[]>>, documentsList: documentsProps[]) => {
    var documentListCopy = documentsList
    documentListCopy.splice(index, 1, {
        photo: documentListCopy[index].photo,
        typeDocument: value,
        numberDocument: documentListCopy[index].numberDocument
    })

    setDocumentsList([...documentListCopy])
}

export const editNumberDocument = (value: string, index: number, setDocumentsList: React.Dispatch<SetStateAction<documentsProps[]>>, documentsList: documentsProps[]) => {
    var documentListCopy = documentsList
    documentListCopy.splice(index, 1, {
        photo: documentListCopy[index].photo,
        typeDocument: documentListCopy[index].typeDocument,
        numberDocument: value
    })
    setDocumentsList([...documentListCopy])
}