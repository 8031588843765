import React from "react";

import PaintingDescription from "./Paint";
import WallpaperDescription from "./Wallpaper";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const PaintingAndWallpaperDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Pintura" ? (<PaintingDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Papel de parede" ? (<WallpaperDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default PaintingAndWallpaperDescription