import styled from "styled-components";

import { devices } from "../../style/Responsive"


export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: var(--primary-color);
    padding: 1rem;
    
    
    @media${devices.tableS}{
        display: flex;
        justify-content: space-between;
    }

    @media${devices.cells}{
        display: block;
        margin: auto;
    }
`

export const Logo = styled.div`
    margin-left: 1rem;
    display: flex;
    align-items: center;  
    
    @media${devices.cells}{
        margin: auto;
        align-items: center;
        justify-content: center;
    }
`

export const ProjectDetails = styled.div`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 500;
    display: block;

    .projectName{
        font-size: 1.25rem;
       
        }
    
    .adress{
        color: #777777;
        font-weight: 400;
        margin-top: 0.2rem;
    }
    
    @media${devices.tableS}{       
        .projectName{
            font-size: 1rem;  
        }
        .adress{
            font-size: 0.75rem;
        }
        margin-left: 0.8rem;
    }

    @media${devices.cell}{       
        .projectName{
            font-size: 0.75rem;  
        }
        .adress{
            font-size: 0.60rem;
        }
        margin-left: 0.9rem;
    }
    
    @media${devices.cells}{
        margin-top: 0.5rem;
        margin-left: 0;
    }
    
    @media${devices.cells2}{       
        .projectName{
            font-size: 0.75rem;  
        }
        .adress{
            font-size: 0.60rem;
        }
    }
`