import React, { useContext, useState } from "react";

import { StepProps, UserProviderProps } from "../types/typesEntitiesProject";

interface StepContextTypes {
  step: StepProps;
  setStep?: React.Dispatch<React.SetStateAction<StepProps>>;
  activitySelected: string;
  setActivitySelected: React.Dispatch<React.SetStateAction<string>>;
}

const defaultStateStep = {
  step: {
    clientId: "618ae7536aea527854f4467d",
    projectId: "",
    name: "",
    percentage: 0,
    quantity: 0,
    measure: "",
    budgetName: "",
    valueUnit: "",
    partialsDivisor: ["Geral"],
    startDate: "",
    endDate: "",
    selectedBudget: false,
  },
  activitySelected: "",
  setActivitySelected: () => {},
};

const StepContext = React.createContext<StepContextTypes>(defaultStateStep);

export const UseStepContext = () => useContext(StepContext);

export const StepContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [step, setStep] = useState(defaultStateStep.step);
  const [activitySelected, setActivitySelected] = useState("");

  return (
    <StepContext.Provider
      value={{ step, activitySelected, setStep, setActivitySelected }}
    >
      {children}
    </StepContext.Provider>
  );
};
