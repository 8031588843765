export const AirConditioner = {
    InstalacaoDeConjuntosEInfra: [
        {
            attributes: [
                {
                    itemName: 'Metragem da tubulação',
                    unit_of_measurement: 'm linear',
                    value: ''
                },
                {
                    itemName: 'Material da tubulação',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Diâmetro da tubulação',
                    unit_of_measurement: 'cm',
                    value: ''
                },
                {
                    itemName: 'Condensadora',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Evaporadora 1',
                    unit_of_measurement: 'W',
                    value: ''
                },
                {
                    itemName: 'Evaporadora 2',
                    unit_of_measurement: 'W',
                    value: ''
                },
                {
                    itemName: 'Evaporadora 3',
                    unit_of_measurement: 'W',
                    value: ''
                },
                {
                    itemName: 'Já existe infraestrutura para esse conjunto no local',
                    unit_of_measurement: '',
                    value: 'Não'
                }
            ]
        }
    ],
    LimpezaDeConjuntos: [
        {
            attributes: [
                {
                    itemName: 'Condensadoras a serem limpas',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Evaporadoras a serem limpas',
                    unit_of_measurement: 'un',
                    value: ''
                }
            ]
        }
    ],
    RemocaoDeConjuntosEInfra: [
        {
            attributes: [
                {
                    itemName: 'Condensadoras a serem removidas',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Evaporadoras a serem removidas',
                    unit_of_measurement: 'un',
                    value: ''
                },
                {
                    itemName: 'Metragem da tubulação a ser removida',
                    unit_of_measurement: 'm linear',
                    value: ''
                }
            ]
        }
    ],
}
