import React, { useState } from "react";
import { DemolitionFormType } from "../../../../Models/Demolition/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
  _id?: string;
  activityName?: string;
  activityType?: string;
  Demolition: DemolitionFormType;
}

const DemolitionDescription: React.FC<stateType> = (props) => {
  const [activity] = useState<activityBudgetsProps>(props.activity);

  return (
    <div>
      <div>
        {activity.Demolition.ParedeDeAlvenaria
          ? activity.Demolition.ParedeDeAlvenaria?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.ParedeDeAlvenaria.length > 1 ? (
                  <u>Parede de alvenaria {index + 1}</u>
                ) : (
                  <u>Parede de alvenaria </u>
                )}
                <br />
                <br />
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.Drywall
          ? activity.Demolition.Drywall?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.Drywall.length > 1 ? (
                  <u>Drywall {index + 1}</u>
                ) : (
                  <u>Drywall </u>
                )}
                <br />
                <br />
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.RevestimentoDePiso
          ? activity.Demolition.RevestimentoDePiso?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.RevestimentoDePiso.length > 1 ? (
                  <u>Revestimento de piso {index + 1}</u>
                ) : (
                  <u>Revestimento de piso </u>
                )}
                <br />
                <br />
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.RevestimentoDeParede
          ? activity.Demolition.RevestimentoDeParede?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.RevestimentoDeParede.length > 1 ? (
                  <u>Revestimento de parede {index + 1}</u>
                ) : (
                  <u>Revestimento de parede </u>
                )}
                <br />
                <br />
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.ContraPiso
          ? activity.Demolition.ContraPiso?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.ContraPiso.length > 1 ? (
                  <u>Contrapiso {index + 1}</u>
                ) : (
                  <u>Contrapiso </u>
                )}
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.PedrasEMarmores
          ? activity.Demolition.PedrasEMarmores?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.PedrasEMarmores.length > 1 ? (
                  <u>Pedras e marmores{index + 1}</u>
                ) : (
                  <u>Pedras e marmores</u>
                )}
                <br />
                <br />
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.Forro
          ? activity.Demolition.Forro?.map((item, index) => (
              <div key={index}>
                {activity.Demolition.Forro.length > 1 ? (
                  <u>Forro {index + 1}</u>
                ) : (
                  <u>Forro </u>
                )}
                <br />
                <br />
                {item.attributes?.map((tarefa, index) => (
                  <div className="line" key={index}>
                    <p>
                      {tarefa.itemName && tarefa.value
                        ? tarefa.itemName + ":  "
                        : ""}
                    </p>
                    <p>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + " " + tarefa.unit_of_measurement
                        : ""}
                    </p>
                  </div>
                ))}
                <hr />
              </div>
            ))
          : null}

        {activity.Demolition.Marcenaria?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.Marcenaria.length > 1 ? (
              <u>Marcenaria {index + 1}</u>
            ) : (
              <u>Marcenaria </u>
            )}
            <br />
            <br />
            {item.attributes?.map((tarefa, index) => (
              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value
                    ? tarefa.itemName + ":  "
                    : ""}
                </p>
                <p>
                  {tarefa.value || (tarefa.value && tarefa.unit_of_measurement)
                    ? tarefa.value + " " + tarefa.unit_of_measurement
                    : ""}
                </p>
              </div>
            ))}
            <hr />
          </div>
        ))}

        {activity.Demolition.ItensASeremDesmontados?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.ItensASeremDesmontados.length > 1 ? (
              <u>Itens a serem desmontados {index + 1}</u>
            ) : (
              <u>Itens a serem desmontados </u>
            )}
            <br />
            <br />
            {item.attributes?.map((tarefa, index) => (
              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value
                    ? tarefa.itemName + ":  "
                    : ""}
                </p>
                <p>
                  {tarefa.value || (tarefa.value && tarefa.unit_of_measurement)
                    ? tarefa.value + " " + tarefa.unit_of_measurement
                    : ""}
                </p>
              </div>
            ))}
            <hr />
          </div>
        ))}

        {activity.Demolition.ItensEspeciaisDemolicao?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.ItensEspeciaisDemolicao.length > 1 ? (
              <u>Demolição de itens especiais {index + 1}</u>
            ) : (
              <u>Demolição de itens especiais </u>
            )}
            <br />
            <br />
            {item.attributes?.map((tarefa, index) => (
              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value
                    ? tarefa.itemName + ":  "
                    : ""}
                </p>
                <p>
                  {tarefa.value || (tarefa.value && tarefa.unit_of_measurement)
                    ? tarefa.value + " " + tarefa.unit_of_measurement
                    : ""}
                </p>
              </div>
            ))}
            <hr />
          </div>
        ))}

        {activity.Demolition.OutrosDemolicao?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.OutrosDemolicao.length > 1 ? (
              <u>Outros de demolição {index + 1}</u>
            ) : (
              <u>Outros de demolição </u>
            )}
            <br />
            <br />
            {item.attributes?.map((tarefa, index) => (
              <div className="line" key={index}>
                <p>
                  {tarefa.itemName && tarefa.value
                    ? tarefa.itemName + ":  "
                    : ""}
                </p>
                <p>
                  {tarefa.value || (tarefa.value && tarefa.unit_of_measurement)
                    ? tarefa.value + " " + tarefa.unit_of_measurement
                    : ""}
                </p>
              </div>
            ))}
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DemolitionDescription;
