import React, { useState, useEffect } from "react";

import { UseGlassworkActivityContext } from "../../../../Context/ActivitiesContext/Glasswork/GlassworkContext"
import { espelhoProps } from "../../../../types/typesEntitiesProject";
import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { ContainerGrid, Select, P, Button, ContainerButtom } from "../../style";

import addIcon from "../../../../assets/addIcon.svg";

const Mirror: React.FC = () => {
  const { glassworkActivities, editGlassworkActivity, indexGlassworkActivitySelected } = UseGlassworkActivityContext()

  const [espelhos, setEspelhos] = useState<espelhoProps[]>([{
    tipo_espelho: 'Retangular',
    cor_espelho: 'Prata',
    altura_espelho: '',
    comprimento_espelho: '',
    acabamento_borda: 'Bisotado',
    tipo_espelhos_outros: '',
    cor_espelho_outros: '',
    acabamento_borda_outros: '',
    quantidade: ''
  }])

  useEffect(() => {
    if (glassworkActivities.length > 0) {
      setEspelhos(glassworkActivities[indexGlassworkActivitySelected].espelhos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexGlassworkActivitySelected, glassworkActivities])

  useEffect(() => {
    if (glassworkActivities.length > 0 && glassworkActivities[indexGlassworkActivitySelected].espelhos.length > 0) {
      setEspelhos(glassworkActivities[indexGlassworkActivitySelected].espelhos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [espelhos])

  const addEspelho = () => {
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos.push({
      tipo_espelho: 'Retangular',
      cor_espelho: 'Prata',
      altura_espelho: '',
      comprimento_espelho: '',
      acabamento_borda: 'Bisotado',
      tipo_espelhos_outros: '',
      cor_espelho_outros: '',
      acabamento_borda_outros: '',
      quantidade: ''
    })
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
    setEspelhos((prev) => [...prev, activityEdited.espelhos[activityEdited.espelhos.length - 1]])
  }

  const editTipoEspelho = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: value,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editTipoEspelhoOutros = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: value,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorEspelho = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: value,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorEspelhoOutros = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: value,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editQuantidade = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: value
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editAlturtaEspelho = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: value,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editComprimentoEspelho = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: value,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editAcabamentoBorda = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: value,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: espelhosCopy[index].acabamento_borda_outros,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editAcabamentoBordaOutros = (index: number, value: string) => {
    const espelhosCopy = Array.from(espelhos);
    espelhosCopy.splice(index, 1, {
      tipo_espelho: espelhosCopy[index].tipo_espelho,
      cor_espelho: espelhosCopy[index].cor_espelho,
      altura_espelho: espelhosCopy[index].altura_espelho,
      comprimento_espelho: espelhosCopy[index].comprimento_espelho,
      acabamento_borda: espelhosCopy[index].acabamento_borda,
      tipo_espelhos_outros: espelhosCopy[index].tipo_espelhos_outros,
      cor_espelho_outros: espelhosCopy[index].cor_espelho_outros,
      acabamento_borda_outros: value,
      quantidade: espelhosCopy[index].quantidade
    })
    setEspelhos(espelhosCopy)
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.espelhos = espelhosCopy
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  return (
    <div>
      {espelhos.map((espelho, index) => (
        <div>
          <P>Espelho {index + 1}</P>
          <ContainerGrid>
            <div>
              <div>
                <p>Tipo de espelho</p>
                <Select value={espelho.tipo_espelho} onChange={(e) => editTipoEspelho(index, e.target.value)}>
                  <option value="Retangular">Retangular</option>
                  <option value="Veneziano">Veneziano</option>
                  <option value="Vitoriano">Vitoriano</option>
                  <option value="Provençal">Provençal</option>
                  <option value="Redondo/Oval">RedondoOval</option>
                  <option value="Com moldura">Com moldura</option>
                  <option value="Jateado">Jateado</option>
                  <option value="Camarim">Camarim</option>
                  <option value="Adesivo decorativo">Adesivo decorativo</option>
                  <option value="Outros">Outros</option>
                </Select>
                {espelho.tipo_espelho === "Outros" ? (
                  <div>
                    <InputActivity>
                      <input type="text" id="tipoOutros" placeholder="Digite o material"
                        value={espelho.tipo_espelhos_outros}
                        onChange={(e) => editTipoEspelhoOutros(index, e.target.value)} />
                    </InputActivity>
                  </div>

                ) : (
                  <></>
                )}
              </div>
              <div>
                <p>Cor do espelho</p>
                <Select value={espelho.cor_espelho} onChange={(e) => editCorEspelho(index, e.target.value)}>
                  <option value="Prata">Prata (padrão)</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Fume">Fumê</option>
                  <option value="Outros">Outros</option>
                </Select>
                {espelho.cor_espelho === "Outros" ? (
                  <div>
                    <InputActivity>
                      <input type="text" id="tipoOutros" placeholder="Digite a cor"
                        value={espelho.cor_espelho_outros}
                        onChange={(e) => editCorEspelhoOutros(index, e.target.value)} />
                    </InputActivity>
                  </div>

                ) : (
                  <></>
                )}
              </div>
            </div>

            <div>
              Dimensões do espelho:
              <div className="resizeInputs">
                <div>
                  <p>Altura</p>
                  <InputActivity>
                    <input type="text" value={espelho.altura_espelho} onChange={(e) => editAlturtaEspelho(index, e.target.value)} />
                    cm
                  </InputActivity>
                </div>
                <div>
                  <p>Comprimento</p>
                  <InputActivity>
                    <input type="text" value={espelho.comprimento_espelho} onChange={(e) => editComprimentoEspelho(index, e.target.value)} />
                    cm
                  </InputActivity>
                </div>
              </div>
            </div>

            <div>
              <p>Acabamento da borda</p>
              <Select value={espelho.acabamento_borda} onChange={(e) => editAcabamentoBorda(index, e.target.value)}>
                <option value="Bisotado">Bisotado</option>
                <option value="Lapidado">Lapidado</option>
                <option value="Facetado">Facetado</option>
                <option value="Juncao (agrupamento de espelhos)">Junção (agrupamento de espelhos)</option>
                <option value="Outros">Outros</option>
                <option value="Nenhum">Nenhum</option>
              </Select>
              {espelho.acabamento_borda === "Outros" ? (
                <div>
                  <InputActivity>
                    <input type="text" id="tipoOutros" placeholder="Digite o acabamento"
                      value={espelho.acabamento_borda_outros}
                      onChange={(e) => editAcabamentoBordaOutros(index, e.target.value)} />
                  </InputActivity>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="resizeInputs">
              <p>Quantidade</p>
              <InputActivity>
                <input type="text" id="quantidade"
                  value={espelho.quantidade}
                  onChange={(e) => editQuantidade(index, e.target.value)} />
              </InputActivity>
            </div>
          </ContainerGrid>
        </div>
      ))}
      <ContainerButtom>
        <Button onClick={() => addEspelho()}>
          <img src={addIcon} alt="Iconde de adição" />
          Adicionar espelho
        </Button>
      </ContainerButtom>
    </div>
  );
};

export default Mirror;
