import styled from "styled-components";

export const Container = styled.div`
    margin-top:1rem;
    display: grid;
    padding: 0.25rem;
`

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    grid-gap:3rem;
    padding: 0.75rem;
    border: 1px solid #E1E1E1;
    border-radius:0.3rem;
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    
    .content{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .resizeInputs{
        width: 6rem;
        p{
            white-space: nowrap;
        }
        input{
            width: 100%;
        }
    }

    .flexContentInput{
        display: flex;
        align-items:center;
       
        p{
            margin-right:0.75rem;
        }

        div{
            width: 6rem;
            input{
                width: 100%;
            }
            margin: 0.3rem;
        }
    }

    .cancelX{
      color: #777777;
      cursor: pointer;
    }
`

export const CheckBoxContainer = styled.div`
    .buttonSelectOption{
        display: flex;
        align-items:center;
        div{
            margin: 0;
        }
        label{
            color: #77777780;
            padding: 0.5rem;
            font-size: 0.85rem;
            cursor: pointer;
        }
    }

    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label{
        color: #000000;
        cursor: pointer;
    }

    input[type="checkbox"]:checked ~ label{
        color: #000000;
        cursor: pointer;
    }
`

export const ContainerCheckBox = styled.div`
    color: #777777;
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
    margin-top:0.5rem;
    display: flex;
    white-space: nowrap;

    .buttonAddFile{
        padding: 0.25rem;
        font-size: 0.75rem;
        height: 1.75rem;
        margin-right: 1rem;
    }

    .activityContentItem,
    .actionsActivityContentItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .actionsActivityContentItem{
        span{
            cursor: pointer;
        }
    }
    
    label{
        display: flex;
        align-items: center;
    }

    input[type="checkbox"]{
        width: 1.15rem;
        height: 1.15rem;
        margin-right: 0.5rem;
        margin-bottom: 0.1rem;
        position: relative;
        cursor: pointer;
    }

    input[type='checkbox']::before {
        content: ' ';
        width: inherit;
        height: inherit;
        position: absolute;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
    }

    input[type='checkbox']:checked::after {
        content: ' ';
        width: 0.3rem;
        height: 0.7rem;
        margin-top: 0.1rem;
        margin-left: 0.4rem;
        border-color: #777777;
        border-style: solid;
        border-width: 0 2px 2px 0;
        position: absolute;
        transform: rotate(45deg);
    }

`
