import React, { useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";

import { stateType } from "../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;
    Materials: MaterialsFormType;
}

const MaterialsDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            <div>


                {activity.Materials.Materiais?.map((item, index) => (
                    <div key={index}>
                        {activity.Materials.Materiais.length > 1 ? (<u>Material {index + 1}</u>) : (<u>Material </u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName !== 'Data inicial' && tarefa.itemName !== 'Data final' && tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.itemName === 'Link' && tarefa.value ? (<a href={tarefa.value}>Acessar</a>) :
                                        tarefa.itemName !== 'Preço total' && tarefa.itemName !== 'Data inicial' && tarefa.itemName !== 'Data final' && tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement)
                                            : tarefa.itemName === 'Preço total' && tarefa.value ? ('R$' + tarefa.value)
                                                : null
                                    }
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}

            </div>
        </div>
    );
};

export default MaterialsDescription;