import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Materials } from "../../../Models/Materials/MaterialsModel";

import {
  nicho,
  shelf,
  marble,
  UserProviderProps,
} from "../../../types/typesEntitiesProject";

interface piaProps {
  tipoPia: string;
  largura: string;
  comprimento: string;
  altura?: string;
  espessuraAba?: string;
  diametroCorte?: string;
}
interface medidas_bancadasProps {
  braco1: {
    altura: string;
    largura: string;
  };
  braco2: {
    altura: string;
    largura: string;
  };
  braco3: {
    altura: string;
    largura: string;
  };
}

interface painelProps {
  tipoPainel: string;
  altura: string;
  largura: string;
  saiaPainel: string;
}
interface recortesFurosItensSuspensosProps {
  tipo: string;
  nome: string;
  altura: string;
  largura: string;
  comprimento: string;
  diametro: string;
}
interface frontaoProps {
  altura: string;
  largura: string;
}

export interface activitiesMarbleWorkProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  /**Revestimento */
  item_revestido: string;
  espessura_chapa: string;
  altura_pedras: string;
  largura_pedras: string;

  /**Nicho */
  nicho: nicho[];

  /**Prateleira */
  prateleira: shelf[];

  /**RodaBase, Tento, Baguete, Soleira e demais...*/
  marble: marble[];
  pedra: string;
  cor_pedra: string;
  acabamento_pedra: string;
  altura_item_revestido: string;
  largura_item_revestido: string;
  profundidade_item_revestido: string;

  /**Pias e bancadas */
  formato_bancada: string;
  disposicao_bancada: string;
  possuiPias: boolean;
  pias: piaProps[];
  medidas_bancadas: medidas_bancadasProps;
  possuiPainel: boolean;
  painel: painelProps;
  possui_recortes_furos_suspensos: boolean;
  recortes_furos_suspensos: recortesFurosItensSuspensosProps;
  tipo_saia: string;
  altura_saia: string;
  profundidade_saia: string;
  frontao: string;
  medidas_fundo: frontaoProps;
  medidas_lateral_1: frontaoProps;
  medidas_lateral_2: frontaoProps;
  pingadeira: boolean;
  rebaixo: boolean;

  dimensaoPedrasNaoAplicavel: boolean;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  marbleworkActivities: activitiesMarbleWorkProps[];
  marbleworkActivityName: string;
  indexMarbleworkActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesMarbleWorkProps[]>
  >;
  setNewMarbleworkActivity?: () => void;
  setMarbleworkActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexMarbleworkActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editMarbleWorkActivity?: (
    index: number,
    activity: activitiesMarbleWorkProps
  ) => void;
  deleteMarbleworkActivity?: (index: number) => void;
}

const activityContextDefault = {
  marbleworkActivities: [],
  marbleworkActivityName: "",
  indexMarbleworkActivitySelected: 0,
  setNewMarbleworkActivity: () => {},
  setMarbleworkActivityName: () => {},
  setIndexMarbleworkActivitySelected: () => {},
  editMarbleWorkActivity: () => {},
  deleteMarbleworkActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseMarbleworkActivityContext = () => useContext(ActivitiesContext);

export const MarbleworkActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [marbleworkActivities, setActivity] = useState<
    activitiesMarbleWorkProps[]
  >(activityContextDefault.marbleworkActivities);
  const [indexMarbleworkActivitySelected, setIndexMarbleworkActivitySelected] =
    useState<number>(0);
  const [marbleworkActivityName, setMarbleworkActivityName] =
    useState<string>("Nicho");

  const editMarbleWorkActivity = (
    index: number,
    activity: activitiesMarbleWorkProps
  ) => {
    let activitiesEdited = marbleworkActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteMarbleworkActivity = (index: number) => {
    const activity = [...marbleworkActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewMarbleworkActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: marbleworkActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Marmoraria",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        /**Revestimento */
        item_revestido: "Parede",
        espessura_chapa: "6mm",
        altura_pedras: "",
        largura_pedras: "",

        /**Nicho */
        nicho: [
          {
            tipo_nicho: "Interno",
            area_nicho: "",
            altura_nicho: "",
            largura_nicho: "",
            profundidade_nicho: "",
            espessura_aba_nicho: "",
            pedra: "Granito",
            cor_pedra: "",
            acabamento_pedra: "Natural",
            divisorias_horizontais: "",
          },
        ],

        prateleira: [
          {
            altura_shelf: "",
            comprimento_shelf: "",
            largura_shelf: "",
            material_shelf: "",
          },
        ],

        /**RodaBase, Tento, Baguete, Soleira e demais...*/
        marble: [
          {
            pedra: "Granito",
            cor_pedra: "",
            acabamento_pedra: "",
            descricao_marble: "",
            altura_item_revestido: {
              tipo_medida: "",
              medida: "",
            },
            largura_item_revestido: {
              tipo_medida: "",
              medida: "",
            },
            profundidade_item_revestido: {
              tipo_medida: "",
              medida: "",
            },
          },
        ],
        pedra: "Granito",
        cor_pedra: "",
        acabamento_pedra: "",
        altura_item_revestido: "",
        largura_item_revestido: "",
        profundidade_item_revestido: "",

        /**Pias e bancadas */
        formato_bancada: "Reta",
        disposicao_bancada: "Simples",
        possuiPias: false,
        pias: [
          {
            tipoPia: "Esculpida",
            largura: "",
            comprimento: "",
            altura: "",
            espessuraAba: "",
            diametroCorte: "",
          },
        ],
        medidas_bancadas: {
          braco1: { altura: "", largura: "" },
          braco2: { altura: "", largura: "" },
          braco3: { altura: "", largura: "" },
        },
        possuiPainel: false,
        painel: {
          tipoPainel: "Lateral/Virada até o chão",
          altura: "",
          largura: "",
          saiaPainel: "Em ambos os lados",
        },
        possui_recortes_furos_suspensos: false,
        recortes_furos_suspensos: {
          tipo: "Recorte",
          nome: "",
          altura: "",
          largura: "",
          comprimento: "",
          diametro: "",
        },
        tipo_saia: "Saia simples",
        altura_saia: "",
        profundidade_saia: "",
        frontao: "",
        medidas_fundo: { altura: "", largura: "" },
        medidas_lateral_1: { altura: "", largura: "" },
        medidas_lateral_2: { altura: "", largura: "" },
        pingadeira: false,
        rebaixo: false,

        dimensaoPedrasNaoAplicavel: false,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        marbleworkActivities,
        marbleworkActivityName,
        indexMarbleworkActivitySelected,
        setActivity,
        setMarbleworkActivityName,
        setNewMarbleworkActivity,
        editMarbleWorkActivity,
        deleteMarbleworkActivity,
        setIndexMarbleworkActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
