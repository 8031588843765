import React, { useState } from "react";
import { sawmillActivity } from "../../../types/typesEntitiesProject"

import { stateType } from "../../SelectionBudget/type";


interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    sawmillActivityTable?: sawmillActivity[]
}

const SawmillActivityDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            {activity.sawmillActivityTable?.map((mesa, indexMesa) => (
                <>
                    <b><br />Mesa {indexMesa + 1}</b>
                    <div className="item">
                        <div className="line">
                            <p><b>Quantidade: </b></p>
                            <p>{mesa.quantidade}un.</p>
                        </div>
                        <div className="line">
                            <p><b>Material do tampo</b></p>
                            <p>{mesa.material_tampo}</p>
                        </div>
                        <div className="line">
                            <p><b>Material estrutura</b></p>
                            <p>{mesa.material_estrutura}</p>
                        </div>
                        <div className="line">
                            <p><b>Pintura</b></p>
                            <p>{mesa.pintura}</p>
                        </div>
                        <div className="line">
                            <p><b>Altura</b></p>
                            <p>{mesa.altura} m</p>
                        </div>
                        <div className="line">
                            <p><b>Comprimento</b></p>
                            <p>{mesa.comprimento} m</p>
                        </div>
                        <div className="line">
                            <p><b>Largura</b></p>
                            <p>{mesa.largura} m</p>
                        </div>
                        <div className="line">
                            <p><b>Adicionais</b></p>
                            <p>{mesa.adicionais} m</p>
                        </div>
                    </div>
                </>

            ))}
        </div>
    );
};

export default SawmillActivityDescription;
