import React, { useState, useEffect } from "react";

import { UseProjectStepActivityContext } from "../../../Context/ActivitiesContext/ProjectStep/ProjectStepContext"
import { UseStepContext } from "../../../Context/stepContext"
import { textTransform } from "../script"

import { ActionSelect, } from "../../stylesDomElements/htmlStyledElements";


const ProjectStepController: React.FC = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()
    const { projectStepActivities, editProjectStepActivity } = UseProjectStepActivityContext()

    useEffect(() => {
        setActivitySelected('Projeto arquitetonico')
    }, [])

    useEffect(() => {
        if (projectStepActivities.length > 0) {
            var activityEdited = projectStepActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editProjectStepActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="FloorsAndCoverings" id="FloorsAndCoverings" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Projeto arquitetonico">Projeto arquitetônico</option>
                <option value="Projeto estrutural">Projeto estrutural</option>
                <option value="Projeto eletrico">Projeto elétrico</option>
                <option value="Projeto hidrossanitario">Projeto hidrossanitário</option>
                <option value="Projeto de ar condicionado">Projeto de ar condicionado</option>
                <option value="Materiais para projeto"> Materiais</option>
            </select>
        </ActionSelect>
    </div>
}

export default ProjectStepController