import React, { useEffect, useState } from "react"

import { UseWoodWorkActivityContext } from "../../../Context/ActivitiesContext/WoodWork"
import { WoodWorkProps } from "../../../types/typesEntitiesProject"

import { InputFile } from "../../stylesDomElements/htmlStyledElements";
import { P, ContainerButtom, Button, ContainerGrid, } from "../style";
import { InputActivity, FlexContainer, UploadMessage } from "./style";

import addIcon from "../../../assets/addIcon.svg";
import dowloadIcon from "../../../assets/dowloadIcon.svg"

interface componentProps {
    modifyActivity: boolean
}

const WoodWorkController: React.FC<componentProps> = (props) => {
    const { woodWorkActivities, editWoodWorkActivity, indexWoodWorkActivitySelected, uploadArchiveProgress } = UseWoodWorkActivityContext()
    const [projetoMarcenaria, setProjetoMarcenaria] = useState<WoodWorkProps[]>([])

    useEffect(() => {
        if (woodWorkActivities[indexWoodWorkActivitySelected]) {
            setProjetoMarcenaria(woodWorkActivities[indexWoodWorkActivitySelected].projetoMarcenaria)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexWoodWorkActivitySelected, woodWorkActivities])

    const editQuantidade = (index: number, value: string) => {
        const marcenariaCopy = Array.from(projetoMarcenaria)
        marcenariaCopy.splice(index, 1, {
            quantidade: value,
            nome: marcenariaCopy[index].nome,
            arquivoProjeto: marcenariaCopy[index].arquivoProjeto,
            link: ''
        })
        setProjetoMarcenaria(marcenariaCopy)
        let activityEdited = woodWorkActivities[indexWoodWorkActivitySelected];
        activityEdited.projetoMarcenaria = marcenariaCopy;
        editWoodWorkActivity?.(indexWoodWorkActivitySelected, activityEdited);
    }

    const editNome = (index: number, value: string) => {
        const marcenariaCopy = Array.from(projetoMarcenaria)
        marcenariaCopy.splice(index, 1, {
            quantidade: marcenariaCopy[index].quantidade,
            nome: value,
            arquivoProjeto: marcenariaCopy[index].arquivoProjeto,
            link: ''
        })
        setProjetoMarcenaria(marcenariaCopy)
        let activityEdited = woodWorkActivities[indexWoodWorkActivitySelected];
        activityEdited.projetoMarcenaria = marcenariaCopy;
        editWoodWorkActivity?.(indexWoodWorkActivitySelected, activityEdited);
    }

    const editArquivoProjeto = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const marcenariaCopy = Array.from(projetoMarcenaria)
        if (event.target.files) {
            marcenariaCopy.splice(index, 1, {
                quantidade: marcenariaCopy[index].quantidade,
                nome: marcenariaCopy[index].nome,
                arquivoProjeto: [event.target.files],
                link: ''
            })
        }
        setProjetoMarcenaria(marcenariaCopy)
        let activityEdited = woodWorkActivities[indexWoodWorkActivitySelected];
        activityEdited.projetoMarcenaria = marcenariaCopy;
        editWoodWorkActivity?.(indexWoodWorkActivitySelected, activityEdited);
    }

    const addProjetoMarcenaria = () => {
        let activityEdited = woodWorkActivities[indexWoodWorkActivitySelected];
        activityEdited.projetoMarcenaria.push(
            {
                quantidade: '',
                nome: '',
                arquivoProjeto: [],
                link: ''
            }
        )
        editWoodWorkActivity?.(indexWoodWorkActivitySelected, activityEdited);
        setProjetoMarcenaria((prev) => [...prev, {
            quantidade: '',
            nome: '',
            arquivoProjeto: [],
            link: ''
        }])
    }

    const removeItem = (index: number) => {
        let activityEdited = woodWorkActivities[indexWoodWorkActivitySelected];
        const marcenariaCopy = [...projetoMarcenaria]
        marcenariaCopy.splice(index, 1)
        activityEdited.projetoMarcenaria = marcenariaCopy
        setProjetoMarcenaria(marcenariaCopy);
        editWoodWorkActivity?.(indexWoodWorkActivitySelected, activityEdited);
    }

    return (
        <div>
            <div>
                {projetoMarcenaria.map((element, index) =>
                    <div>
                        <P>Projeto {index + 1}</P>
                        <ContainerGrid>
                            <FlexContainer>
                                <div>
                                    <p>Quantidade</p>
                                    <InputActivity>
                                        <input type="text" value={element.quantidade} onChange={(e) => editQuantidade(index, e.target.value)} />
                                        un
                                    </InputActivity>
                                </div>
                                <div>
                                    <p>Nome (opcional)</p>
                                    <InputActivity>
                                        <input type="text" value={element.nome} onChange={(e) => editNome(index, e.target.value)} />
                                    </InputActivity>
                                </div>

                                {props.modifyActivity ? (
                                    <div className="fileIcon">
                                        <a href={element.link}>
                                            <img src={dowloadIcon} alt="icone de arquivo" />
                                        </a>
                                    </div>
                                ) : (<></>)}

                                <div>
                                    {!uploadArchiveProgress ? (
                                        <InputFile>
                                            {element.arquivoProjeto ? (
                                                element.arquivoProjeto[0]?.item(0)?.name ? (<p>{element.arquivoProjeto[0]?.item(0)?.name}</p>) : (<p>Faça o upload do arquivo</p>)
                                            ) : (<p>Selecionar novo arquivo</p>)}
                                            <label htmlFor={`eletricPanelImage+${index}`}>
                                                Selecionar o arquivo
                                            </label>
                                            <input
                                                type="file"
                                                name="eletricPanelImage"
                                                id={`eletricPanelImage+${index}`}
                                                multiple
                                                onChange={(event) => editArquivoProjeto(index, event)}
                                            />
                                        </InputFile>
                                    ) : (
                                        <UploadMessage>
                                            Upload do arquivo em {uploadArchiveProgress}%
                                        </UploadMessage>
                                    )}
                                </div>

                                <div className="cancelX" onClick={() => removeItem(index)}>X</div>
                            </FlexContainer>
                        </ContainerGrid>
                    </div>
                )}
                <ContainerButtom>
                    <Button onClick={() => addProjetoMarcenaria()}>
                        Adicionar projeto
                        <img src={addIcon} alt="Icone de adição" />
                    </Button>
                </ContainerButtom>
            </div>
        </div>
    );
};

export default WoodWorkController;
