import { BudgetsProps, SupplierProps } from "../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../Config/dictionary"

import { GreenDestack } from "../style"

export const totalGeneralStepsBudgets = (budgets: BudgetsProps[], suppliers: SupplierProps[], typeSupplier: string, supplierId?: string) => {
    var total = 0
    var totalBudgetsList: number[] = []
    var budgetsFilterSupplierOrdinary = budgets?.filter(budget => budget.typeSupplier === simpleSupplier)
    var budgetsFilterSupplierConstructionCompany = budgets?.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker)

    var totalAllStepsBudgets = 0
    budgetsFilterSupplierOrdinary?.forEach((budget) => {
        totalAllStepsBudgets += parseFloat(budget.totalBudgetPayment.replace(',', '.'))
    })
    totalBudgetsList.push(totalAllStepsBudgets)

    suppliers?.forEach((supplier) => {
        if (supplier.typeSupplier === constructor || supplier.typeSupplier === worker) {
            var totalStepBudget = 0
            budgetsFilterSupplierConstructionCompany?.forEach(item => {
                supplier._id === item.providerId ? (totalStepBudget += parseFloat(item.totalBudgetPayment.replace(',', '.'))) : (<></>)
            })
            totalBudgetsList.push(totalStepBudget)
        }
    })

    if (typeSupplier === simpleSupplier) {
        budgets?.filter(budget => budget.typeSupplier === simpleSupplier).forEach((budget) => {
            total += parseFloat(budget.totalBudgetPayment.replace(',', '.'))
        })
    } else if (typeSupplier === constructor || typeSupplier === worker) {
        budgets?.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && supplierId === budget.providerId).forEach((budget) => {
            total += parseFloat(budget.totalBudgetPayment.replace(',', '.'))
        })
    }

    var minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
        return Math.min(a, b)
    })

    if (parseFloat(total.toFixed(2)) === minValueSelected && total > 0) {
        return <p><GreenDestack>R${total.toFixed(2)}</GreenDestack></p>
    }
    else
        return <p style={{ color: 'gray', fontWeight: 'normal' }}>R${total.toFixed(2)}</p>
}