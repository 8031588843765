import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
  ListContainerItensAirConditioner,
  ItemContentListAirConditioner,
  ListContainerItensRemocaoDeConjuntosEInfra,
  ItemContentListRemocaoDeConjuntosEInfra,
  ListContainerItensLimpezaDeConjuntos,
  ItemContentListLimpezaDeConjuntos,
} from "./style";

import {
  activitiesProps,
  SupplierProps,
} from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate";
import { AirConditionerFormType } from "../../../../Models/AirConditioner/type";

import FooterBudget from "../../Components/FooterBudget";
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";
import FooterBudgetPerSet from "../../Components/FooterBudgetPerSet";

interface budgetProps {
  activity: any;
  supplier: SupplierProps;
  margin: number;
}

interface activityProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string;
  activityType?: string;
  stepType?: string;

  AirConditioner: AirConditionerFormType;
}

const AirConditionerBudget: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [instalacaoDeConjuntosEInfra, setInstalacaoDeConjuntosEInfra] =
    useState<string[]>(["0,00"]);
  const [limpezaDeConjuntos, setLimpezaDeConjuntos] = useState<string[]>([
    "0,00",
  ]);
  const [remocaoDeConjuntosEInfra, setRemocaoDeConjuntosEInfra] = useState<
    string[]
  >(["0,00"]);
  const [emendas, setEmendas] = useState<string[]>(["0,00"]);

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>("0,00");
  const [conditions, setConditions] = useState<string>("");
  const [observations, setObservations] = useState<string>("");

  useEffect(() => {
    activity.AirConditioner.InstalacaoDeConjuntosEInfra.forEach(() => {
      setInstalacaoDeConjuntosEInfra((prev) => [...prev, "0,00"]);
    });
    // eslint-disable-next-line
    activity.AirConditioner.LimpezaDeConjuntos?.forEach(() => {
      setLimpezaDeConjuntos((prev) => [...prev, "0,00"]);
    });

    activity.AirConditioner.RemocaoDeConjuntosEInfra?.forEach(() => {
      setRemocaoDeConjuntosEInfra((prev) => [...prev, "0,00"]);
    });
  }, [activity]);

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const instalacaoDeConjuntosEInfraTotal = sum(instalacaoDeConjuntosEInfra);
    const limpezaDeConjuntosTotal = sum(limpezaDeConjuntos);
    const remocaoDeConjuntosEInfraTotal = sum(remocaoDeConjuntosEInfra);
    const emendasTotal = sum(emendas);

    setTotalBudgetPayment(
      sum([
        instalacaoDeConjuntosEInfraTotal,
        limpezaDeConjuntosTotal,
        remocaoDeConjuntosEInfraTotal,
        emendasTotal,
      ])
    );
  }, [
    instalacaoDeConjuntosEInfra,
    limpezaDeConjuntos,
    remocaoDeConjuntosEInfra,
    emendas,
  ]);

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const instalacaoDeConjuntosEInfraTotal = sum(instalacaoDeConjuntosEInfra);
  const limpezaDeConjuntosTotal = sum(limpezaDeConjuntos);
  const remocaoDeConjuntosEInfraTotal = sum(remocaoDeConjuntosEInfra);
  const emendasTotal = sum(emendas);

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(
        parseFloat(minimumBudgetValue).toFixed(2).replace(".", ",")
      );
    } else {
      setTotalBudgetPayment(
        sum([
          instalacaoDeConjuntosEInfraTotal,
          limpezaDeConjuntosTotal,
          remocaoDeConjuntosEInfraTotal,
          emendasTotal,
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (
    index: number,
    stateVar: string[],
    setStateRef: any,
    value: string,
    quantityMultiply?: string
  ) => {
    const stateCopy = Array.from(stateVar);
    stateCopy.splice(index, 1, multiply("1", value));
    quantityMultiply ? (
      stateCopy.splice(index, 1, multiply(quantityMultiply, value))
    ) : (
      <></>
    );
    setStateRef(stateCopy);
  };

  const submitBudget = () => {
    const instalacaoDeConjuntosEInfraMargin: number[] = [];
    const limpezaDeConjuntosaMargin: number[] = [];
    const remocaoDeConjuntosEInfraMargin: number[] = [];

    instalacaoDeConjuntosEInfra.forEach((item) => {
      if (item != "0,00") {
        instalacaoDeConjuntosEInfraMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    limpezaDeConjuntos.forEach((item) => {
      if (item != "0,00") {
        limpezaDeConjuntosaMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    remocaoDeConjuntosEInfra.forEach((item) => {
      if (item != "0,00") {
        remocaoDeConjuntosEInfraMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: "",
        workingDays,
        selectedBudget: false,
        budget: {
          InstalacaoDeConjuntosEInfra: instalacaoDeConjuntosEInfraMargin,
          LimpezaDeConjuntos: limpezaDeConjuntosaMargin,
          RemocaoDeConjuntosEInfra: remocaoDeConjuntosEInfraMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (
          Number(totalBudgetPayment.replace(",", ".")) +
          Number(totalBudgetPayment.replace(",", ".")) * (props.margin / 100)
        ).toString(),
        observations,
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensAirConditioner>
        {activity.AirConditioner.InstalacaoDeConjuntosEInfra?.map(
          (item, index) => (
            <div key={index}>
              {activity.AirConditioner.InstalacaoDeConjuntosEInfra.length >
              1 ? (
                <P>Instalação de conjuntos e infra {index + 1}</P>
              ) : (
                <P>Instalação de conjuntos e infra</P>
              )}
              <div className="upContent">
                {item.attributes?.map((tarefa, index) => (
                  <p>{tarefa.itemName ? <span>{tarefa.itemName}</span> : ""}</p>
                ))}
              </div>
              <ItemContentListAirConditioner>
                {item.attributes?.map((tarefa, index) => (
                  <p>
                    <span onClick={() => console.log(tarefa.itemName)}>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + "" + tarefa.unit_of_measurement
                        : ""}
                    </span>
                  </p>
                ))}
              </ItemContentListAirConditioner>
            </div>
          )
        )}
      </ListContainerItensAirConditioner>

      <ListContainerItensLimpezaDeConjuntos>
        {activity.AirConditioner.LimpezaDeConjuntos?.map((item, index) => (
          <div key={index}>
            {activity.AirConditioner.LimpezaDeConjuntos.length > 1 ? (
              <P>Limpeza de conjuntos {index + 1}</P>
            ) : (
              <P>Limpeza de conjuntos</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? <span>{tarefa.itemName}</span> : ""}</p>
              ))}
            </div>
            <ItemContentListLimpezaDeConjuntos>
              {item.attributes?.map((tarefa, index) => (
                <p>
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value ||
                    (tarefa.value && tarefa.unit_of_measurement)
                      ? tarefa.value + "" + tarefa.unit_of_measurement
                      : ""}
                  </span>
                </p>
              ))}
            </ItemContentListLimpezaDeConjuntos>
          </div>
        ))}
      </ListContainerItensLimpezaDeConjuntos>

      <ListContainerItensRemocaoDeConjuntosEInfra>
        {activity.AirConditioner.RemocaoDeConjuntosEInfra?.map(
          (item, index) => (
            <div key={index}>
              {activity.AirConditioner.RemocaoDeConjuntosEInfra.length > 1 ? (
                <P>Remoção de conjuntos {index + 1}</P>
              ) : (
                <P>Remoção de conjuntos</P>
              )}
              <div className="upContent">
                {item.attributes?.map((tarefa, index) => (
                  <p>{tarefa.itemName ? <span>{tarefa.itemName}</span> : ""}</p>
                ))}
              </div>
              <ItemContentListRemocaoDeConjuntosEInfra>
                {item.attributes?.map((tarefa, index) => (
                  <p>
                    <span onClick={() => console.log(tarefa.itemName)}>
                      {tarefa.value ||
                      (tarefa.value && tarefa.unit_of_measurement)
                        ? tarefa.value + "" + tarefa.unit_of_measurement
                        : ""}
                    </span>
                  </p>
                ))}
              </ItemContentListRemocaoDeConjuntosEInfra>
            </div>
          )
        )}
      </ListContainerItensRemocaoDeConjuntosEInfra>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudgetPerSet
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
        setObservations={() => setObservations}
      />
    </div>
  );
};

export default AirConditionerBudget;
