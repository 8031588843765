import React, { useState, useEffect } from "react";

import { PlantProps } from "../../../types/typesEntitiesProject";
import { Tr } from "../style";
import { Th, MenuOverlay } from "./style";
import { OptionButton } from "../../../components/stylesDomElements/htmlStyledElements"
import { UseAuthAcessContext } from "../../../Context/AuthAcessContext";
import pointsMenu from "../../../assets/pointsMenu.svg";
import axios from "axios";

const TrTable: React.FC<PlantProps> = (props) => {
  const { editPermission } = UseAuthAcessContext()
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);

  useEffect(() => {
    if (editPermission === 'Editor' || editPermission === '') {
      setIsEditAuthorized(true)
    } else if (editPermission === 'Visualizacao') {
      setIsEditAuthorized(false)
    }
  }, [editPermission]);

  const deletePlant = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/deletePlant/${props._id}`)
      .then((res) => {
        if (res.status === 200) setVisibleMenu(!visibleMenu);
        window.location.reload()
      });
  };

  return (
    <>
      {visibleMenu ? (
        <MenuOverlay>
          {isEditAuthorized ? (
            <OptionButton onClick={() => deletePlant()}>Excluir</OptionButton>
          ) : null}
          <OptionButton onClick={() => setVisibleMenu(!visibleMenu)}><a href={props.archiveUrl} download>Baixar arquivo</a></OptionButton>
        </MenuOverlay>
      ) : null}

      <Tr>
        <th>{props.archiveName}</th>
        <th>{props.type}</th>
        <th>{props.size}</th>
        <th>{props.modification}</th>
        <Th onClick={() => setVisibleMenu(!visibleMenu)}>
          <img src={pointsMenu} alt="pontinhos" />
        </Th>
      </Tr>
    </>
  );
};

export default TrTable;
