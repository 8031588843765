import React, { useState, useEffect } from "react";

import { UseModalContext } from "../../Context/ModalContext";
import { ContainerModal, FormContainer, HeaderFormContainer } from "../stylesDomElements/htmlStyledElements"
import axios from "axios";
import { UseGanttContext } from "../../Context/GanttContext";
import { UseUserContext } from "../../Context/UserContext"

import CreateDependency from "../Forms/CreateDependency";
import ShowAndDeleteDependecy from "../Forms/ShowAndDeleteDependency";

import closeIcon from "../../assets/XIcon.svg"
import { dependencesProps, stepProps } from "../../pages/ConstructionManagement/type";

interface dependenciesModalProps {
  dependences: dependencesProps[]
  steps: stepProps[]
}

const DependenciesModal: React.FC<dependenciesModalProps> = (props) => {
  const { modalNameHeader, dependencesModal, setShowDependences } = UseModalContext()
  const { stepId } = UseGanttContext();

  const [showDependencyDelete, setShowDependencyDelete] = useState<boolean>(true)

  return (
    <>
      {dependencesModal && (
        <ContainerModal>
          <FormContainer>
            <HeaderFormContainer>
              <p>{modalNameHeader}</p>
              <div>
                <img src={closeIcon} alt="Icone de fachar" onClick={() => setShowDependences()} />
              </div>
            </HeaderFormContainer>
            {showDependencyDelete ? (
              <ShowAndDeleteDependecy
                dependences={props.dependences.filter((dependence=>dependence.stepId === stepId[0]))}
                setpName={props.steps.find((step) => step._id === stepId[0])?.name || ''}
                setShowDependencyDelete={setShowDependencyDelete} />
            ) : (
              <CreateDependency 
                steps={props.steps.filter(step=>step._id !== stepId[0])}
                setpName={props.steps.find((step) => step._id === stepId[0])?.name || ''}
              />
            )}
          </FormContainer >
        </ContainerModal >
      )}
    </>
  );
};

export default DependenciesModal;
