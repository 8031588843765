import React, { useState } from "react";
import { FundationFormType } from "../../../../Models/Masonary/type";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;
    Fundation: FundationFormType;
}

const FundationDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            <div>


                {activity.Fundation.Sapatas?.map((item, index) => (
                    <div key={index}>
                        {activity.Fundation.Sapatas.length > 1 ? (<u>Sapatas {index + 1}</u>) : (<u>Sapatas </u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}

                {activity.Fundation.Baldrame?.map((item, index) => (
                    <div key={index}>
                        {activity.Fundation.Baldrame.length > 1 ? (<u>Baldrame {index + 1}</u>) : (<u>Baldrame </u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}

                {activity.Fundation.Brocas?.map((item, index) => (
                    <div key={index}>
                        {activity.Fundation.Brocas.length > 1 ? (<u>Brocas {index + 1}</u>) : (<u>Brocas </u>)}
                        <br /><br />
                        {item.attributes?.map((tarefa, index) => (

                            <div className="line" key={index}>
                                <p>
                                    {tarefa.itemName && tarefa.value ? (tarefa.itemName + ':  ') : ('')}

                                </p>
                                <p>
                                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + " " + tarefa.unit_of_measurement) : ('')}
                                </p>
                            </div>
                        ))}
                        <hr />
                    </div>

                ))}

            </div>
        </div>
    );
};

export default FundationDescription;