import React, { useEffect, useState } from "react";
import axios from "axios";

import { UseUserContext } from "../../Context/UserContext";
import { UseModalContext } from "../../Context/ModalContext";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";

import { Container, TopBar } from "./style";

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import ProgressTotal from "../../components/ProgressTotal";
import MenuGraph from "../../components/MenuGraph";
import Modal from "../../components/Modal";
import DisplayFlow from "./Components/DisplayFlow";
import GanttMonth from "./Components/GanttMonth";
import GanttCronogram from "./Components/Cronogram";
import GanttTrimester from "./Components/GanttTrimester";
import GanttSemester from "./Components/GanttSemester";
import AcessMenu from "../../components/AcessMenu";

import { UseGanttContext } from "../../Context/GanttContext";
import { progressTotalCalc } from "../../scripts/progressTotalCalc";

import { allActivitiesProps } from "../../types/typesEntitiesProject";
import { stepProps, dependencesProps } from "./type";
import DependenciesModal from "../../components/DependenciesModal";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";
interface projectProps {
  name: string;
  startDate: string;
  endDate: string;
}

const ConstructorManagement: React.FC = () => {
  const { acessLink } = UseAuthAcessContext();
  const { projectSelected, setprojectSelected } = UseUserContext();
  const { modalNameHeader, setShowModal } = UseModalContext();
  const { filterPeriod } = UseGanttContext();

  const [activitySelected, setActivitySelected] =
    useState<allActivitiesProps>();

  const [project, setProject] = useState<projectProps>();

  const [steps, setSteps] = useState<stepProps[]>([]);
  const [actvities, setActivities] = useState<allActivitiesProps[]>([]);
  const [dependences, setDependences] = useState<dependencesProps[]>([]);
  const [currentPage, setCurrentPage] = useState<boolean>(false);

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    } else {
      const projectId = localStorage.getItem("projectId");
      if (projectId && typeof setprojectSelected !== "undefined") {
        setprojectSelected(projectId);
      }
    }

    window.scrollTo(0, 0);
  }, [projectSelected]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
        });
    }
  }, [projectSelected, acessLink]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/steps/${projectSelected}`)
        .then((res) => {
          setSteps(res.data);
        });
    }
  }, [projectSelected, acessLink]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/activitiesProject/${projectSelected}`
        )
        .then((res) => {
          setActivities(res.data);
        });
    }
  }, [actvities, projectSelected, acessLink]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/listDependencies/${projectSelected}`
        )
        .then((res) => {
          setDependences(res.data);
        });
    }
  }, [actvities, projectSelected, acessLink]);

  return (
    <Container>
      <Modal titleForm={modalNameHeader}>
        <DisplayFlow
          activitySelected={activitySelected}
          requestCotation={activitySelected?.requestCotation}
          selectedBudget={activitySelected?.selectedBudget}
          stepType={activitySelected?.stepType}
        />
      </Modal>

      <DependenciesModal dependences={dependences} steps={steps} />

      <Header />

      {!acessLink ? <ItensMenu optionSelected="Gestão" /> : <AcessMenu />}

      <TopBar>
        <ProgressTotal total={progressTotalCalc(steps, 0)} />
        <MenuGraph />
      </TopBar>

      {project?.startDate && project.endDate && setShowModal ? (
        filterPeriod === "month" ? (
          <GanttMonth
            startDate={project?.startDate}
            endDate={project?.endDate}
            actvities={actvities}
            dependences={dependences}
            setActivitySelected={setActivitySelected}
            steps={steps}
            setSteps={setSteps}
            setShowModal={setShowModal}
          />
        ) : filterPeriod === "cronogram" ? (
          <GanttCronogram
            startDate={project?.startDate}
            endDate={project?.endDate}
            actvities={actvities}
            setActivitySelected={setActivitySelected}
            steps={steps}
            setSteps={setSteps}
            dependences={dependences}
            setShowModal={setShowModal}
          />
        ) : filterPeriod === "trimester" ? (
          <GanttTrimester
            startDate={project?.startDate}
            endDate={project?.endDate}
            actvities={actvities}
            dependences={dependences}
            setActivitySelected={setActivitySelected}
            steps={steps}
            setSteps={setSteps}
            setShowModal={setShowModal}
          />
        ) : filterPeriod === "semianual" ? (
          <GanttSemester
            startDate={project?.startDate}
            endDate={project?.endDate}
            actvities={actvities}
            dependences={dependences}
            setActivitySelected={setActivitySelected}
            steps={steps}
            setSteps={setSteps}
            setShowModal={setShowModal}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ConstructorManagement;
