export function trimesterName(year:number, trimester:number) {
  const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  
  const firstMonth = (trimester - 1) * 3;
  const lastMonth = firstMonth + 2;
  
  const startDate = new Date(year, firstMonth, 1);
  const endDate = new Date(year, lastMonth + 1, 0);
  
  const startMonthName = monthNames[startDate.getMonth()];
  const endMonthName = monthNames[endDate.getMonth()];
  
  const trimesterName = startMonthName + ' - ' + endMonthName + ' ' + year;

  console.log(trimester)
  
  return trimesterName;
}