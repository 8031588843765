import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ContentVisualizationMenu from "./Components/ContentVisualizationItemMenu";
import DropDown from "../DropDownElement";
import { Container, MenuOptions, Option, Button } from "./style";
import addStepIcon from "../../assets/addIcon.svg";
import { UseEditWeeklySummaryContext } from "../../Context/EditWeeklySummaryContext";

interface optionProps {
  optionSelected: string;
  component?: any;
}

const Menu: React.FC<optionProps> = (props) => {
  const [showContentVisualizationMenu, setShowContentVisualizationMenu] =
    useState<boolean>(false);
  const { editMode, setShowEditWeeklySummary } = UseEditWeeklySummaryContext();

  return (
    <Container>
      <MenuOptions>
        {props.optionSelected === "Gestão" ||
        props.optionSelected === "Mapa cotacao" ||
        props.optionSelected === "Lista" ||
        props.optionSelected === "Resumo semanal" ? (
          <div className="boxSelect">
            <Option className="selected">Gestão</Option>

            {
              <DropDown
                scaleY={1}
                function={() =>
                  setShowContentVisualizationMenu(!showContentVisualizationMenu)
                }
              />
            }
          </div>
        ) : (
          <Link to={`/gestaoCronograma`}>
            <div>
              <Option>Gestão</Option>
            </div>
          </Link>
        )}

        {showContentVisualizationMenu ? (
          <ContentVisualizationMenu optionSelected={props.optionSelected} />
        ) : (
          <></>
        )}

        {props.optionSelected === "Plantas" ? (
          <div className="boxSelect">
            <Option className="selected">Plantas</Option>
          </div>
        ) : (
          <Link to={`/plantas`}>
            <div>
              <Option>Plantas</Option>
            </div>
          </Link>
        )}

        {props.optionSelected === "Acompanhamento" ? (
          <div className="boxSelect">
            <Option className="selected">Acompanhamento</Option>
          </div>
        ) : (
          <Link to={`/acompanhamento`}>
            <div>
              <Option>Acompanhamento</Option>
            </div>
          </Link>
        )}

        {props.optionSelected === "Acessos" ? (
          <div className="boxSelect">
            <Option className="selected">Acessos</Option>
          </div>
        ) : (
          <Link to={`/acessoProjeto`}>
            <div>
              <Option>Acessos</Option>
            </div>
          </Link>
        )}

        {props.optionSelected === "Infos" ? (
          <div className="boxSelect">
            <Option className="selected">Infos</Option>
          </div>
        ) : (
          <Link to={`/info`}>
            <div>
              <Option>Infos</Option>
            </div>
          </Link>
        )}

        {props.optionSelected === "Meus fornecedores" ? (
          <div className="boxSelect">
            <Option className="selected">Meus fornecedores</Option>
          </div>
        ) : (
          <Link to={`/meusFornecedores`}>
            <div>
              <Option>Meus fornecedores</Option>
            </div>
          </Link>
        )}
      </MenuOptions>

      {props.optionSelected === "Gestão" ? (
        <Link to="/adicionarEtapa">
          <Button>
            <img src={addStepIcon} alt="Icon de adicionar" />
            Adicionar etapa
          </Button>
        </Link>
      ) : (
        <></>
      )}

      {props.optionSelected === "Resumo semanal" ? (
        !editMode && (
          <Link to="/gestaoResumoSemanal">
            <Button onClick={() => setShowEditWeeklySummary?.()}>
              Editar período
            </Button>
          </Link>
        )
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Menu;
