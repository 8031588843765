import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../../style"
import { GreenDestack } from "../../../style"
import { ColdWaterFormType } from "../../../../../Models/Hydraulic/type";

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface activityProps {
    selectedBudget?: boolean,
    ColdWater?: ColdWaterFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        PontoDeAguaFria?: string[]
        RetirarPontoDeAguaFria?: string[]
        PontoDeFiltro?: string[]
        PontoDeRegistro?: string[]
        Tubulacao?: string[]
        Emendas?: string[]
        Ralo?: string[]
        ItensEspeciais?: string[]
    }
}

export const AguaFriaComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    console.log(activity.ColdWater)

    const renderItemBudget = (totalItem: any, activityId?: string, itemType?: any) => {
        const budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        const totalBudgetsList: number[] = []

        if (!totalItem) {
            return <p>R$0,00</p>
        }

        const minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        }, 1000000000)

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>

    }

    return (
        <ContainerItensBudget>
            <>
                {activity.ColdWater?.PontoDeAguaFria.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de água fria</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.PontoDeAguaFria?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 2).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.PontoDeAguaFria ? renderItemBudget(budget.budget?.PontoDeAguaFria[index], budget.activityId, 'Ponto de agua fria') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.PontoDeAguaFria ? renderItemBudget(budget.budget?.PontoDeAguaFria[index], budget.activityId, 'Ponto de agua fria') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}

                {activity.ColdWater?.RetirarPontoDeAguaFria.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Retirar ponto de água fria</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.RetirarPontoDeAguaFria?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 2).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.RetirarPontoDeAguaFria ? renderItemBudget(budget.budget?.RetirarPontoDeAguaFria[index], budget.activityId, 'Retirar ponto de agua fria') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.RetirarPontoDeAguaFria ? renderItemBudget(budget.budget?.RetirarPontoDeAguaFria[index], budget.activityId, 'Retirar ponto de agua fria') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}
                {activity.ColdWater?.PontoDeFiltro.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de filtro</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.PontoDeFiltro?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 2).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.PontoDeFiltro ? renderItemBudget(budget.budget?.PontoDeFiltro[index], budget.activityId, 'Ponto de filtro') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.PontoDeFiltro ? renderItemBudget(budget.budget?.PontoDeFiltro[index], budget.activityId, 'Ponto de filtro') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}

                {activity.ColdWater?.PontoDeRegistro.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de registro</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.PontoDeRegistro?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 2).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.PontoDeRegistro ? renderItemBudget(budget.budget?.PontoDeRegistro[index], budget.activityId, 'Ponto de registro') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.PontoDeRegistro ? renderItemBudget(budget.budget?.PontoDeRegistro[index], budget.activityId, 'Ponto de registro') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}

                {activity.ColdWater?.Tubulacao.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Tubulação</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.Tubulacao?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 1).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Tubulacao ? renderItemBudget(budget.budget?.Tubulacao[index], budget.activityId, 'Tubulacao') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Tubulacao ? renderItemBudget(budget.budget?.Tubulacao[index], budget.activityId, 'Tubulacao') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}

                {activity.ColdWater?.Emendas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Emendas</p>
                            <p>Material</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.Emendas?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 3).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Emendas ? renderItemBudget(budget.budget?.Emendas[index], budget.activityId, 'Emendas') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Emendas ? renderItemBudget(budget.budget?.Emendas[index], budget.activityId, 'Emendas') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}

                {activity.ColdWater?.Ralo.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ralo</p>
                            <p>Fornecedor</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.Ralo?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 3).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Ralo ? renderItemBudget(budget.budget?.Ralo[index], budget.activityId, 'Ralo') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.Ralo ? renderItemBudget(budget.budget?.Ralo[index], budget.activityId, 'Ralo') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}

                {activity.ColdWater?.ItensEspeciais.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Itens especiais</p>
                            <p>Quantidade</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.ColdWater?.ItensEspeciais?.map((item, index) => (
                            <ItemBudgetNewModel key={index}>
                                {item.attributes.slice(0, 2).map((tarefa, index) => (
                                    <p className="itemName" key={index}>
                                        {tarefa.value}
                                    </p>
                                ))}

                                {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                    tarefa.unit_of_measurement ? (
                                        <p key={index}>
                                            <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )
                                ))}

                                {props.suppliers ? (
                                    <>
                                        {/**Item orçado pelo Fornecedor avulso */}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.ItensEspeciais ? renderItemBudget(budget.budget?.ItensEspeciais[index], budget.activityId, 'Itens especiais') : ('')
                                            ))
                                        ))}
                                        {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                        {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                            budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                budget.budget?.ItensEspeciais ? renderItemBudget(budget.budget?.ItensEspeciais[index], budget.activityId, 'Itens especiais') : ('')
                                            ))
                                        ))}
                                    </>
                                ) : null}
                            </ItemBudgetNewModel>
                        ))}
                    </>
                ) : null}
            </>
        </ContainerItensBudget>
    )
}

export default AguaFriaComparartive