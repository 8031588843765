import React, { useEffect } from "react";

import { UseStepContext } from "../../../Context/stepContext"
import { ActivitiesContainer, MessageContent } from "./style"

/**Demolição */
import Demolition from "../../../components/StepsForm/Demolition/Demolition";
import MaterialsDemolitionStepForm from "../../../components/StepsForm/Demolition/Materials";

/**Alvenaria */
import Fundation from "../../../components/StepsForm/Masonry/Fundation";
import Structure from "../../../components/StepsForm/Masonry/Structure";
import Closure from "../../../components/StepsForm/Masonry/Closure";
import Floor from "../../../components/StepsForm/Masonry/Floor";
import Flagstone from "../../../components/StepsForm/Masonry/Flagstone";
import Refraiming from "../../../components/StepsForm/Masonry/Refraiming";
import Soculo from "../../../components/StepsForm/Masonry/Soculo";
import Stairways from "../../../components/StepsForm/Masonry/Stairways";
import Nicho from "../../../components/StepsForm/Masonry/Nicho";
import MaterialsMasonaryStepForm from "../../../components/StepsForm/Masonry/Materials";

/**Impermeabilização */
import ImpermeabilizationStepForm from "../../../components/StepsForm/Impermeabilization/Impermeabilization";
import MaterialsImpermeabilizationStepForm from "../../../components/StepsForm/Impermeabilization/Materials";

/**Elétrica */
import PanelEletric from "../../../components/StepsForm/Eletric/ElectricPanel";
import Conduite from "../../../components/StepsForm/Eletric/PassageOfInfra";
import PassingOfWires from "../../../components/StepsForm/Eletric/PassageOfWires";
import Instalation from "../../../components/StepsForm/Eletric/Instalations";
import Automations from "../../../components/StepsForm/Eletric/Automations";
import MaterialsEletricStepForm from "../../../components/StepsForm/Eletric/Materials";

/**Hidráulica */
import ColdWaterStepForm from "../../../components/StepsForm/Hydraulic/ColdWater";
import HotWaterStepForm from "../../../components/StepsForm/Hydraulic/HotWater";
import SewerStepForm from "../../../components/StepsForm/Hydraulic/Sewer";
import GasStepForm from "../../../components/StepsForm/Hydraulic/Gas";
import MaterialsHydraulicStepForm from "../../../components/StepsForm/Hydraulic/Materials";

/**Ar condicionado */
import AirConditioner from "../../../components/StepsForm/AirConditioner/AirConditioner";
import MaterialsAirConditionerStepForm from "../../../components/StepsForm/AirConditioner/Materials";

/**Pisos e revestimento */
import WallCoveringStepForm from "../../../components/StepsForm/FloorsAndCoverings/WallCovering";
import BaseBoardStepForm from "../../../components/StepsForm//FloorsAndCoverings/BaseBoard";
import CeramicFloorCoveringStepForm from "../../../components/StepsForm/FloorsAndCoverings/CeramicFloorCovering";
import StoneFloorCoveringStepForm from "../../../components/StepsForm/FloorsAndCoverings/StoneFloorCovering";
import VinylFloorCoveringStepForm from "../../../components/StepsForm/FloorsAndCoverings/VinylFloorCovering";
import WoodCoveringStepForm from "../../../components/StepsForm/FloorsAndCoverings/WoodCovering";
import FlooringStepForm from "../../../components/StepsForm/FloorsAndCoverings/Flooring";
import MaterialsFloorsAndCoveringsStepForm from "../../../components/StepsForm/FloorsAndCoverings/Materials";

/**Forro */
import Lining from "../../../components/StepsForm/Lining/Lining/Lining";
import MaterialsLiningStepForm from "../../../components/StepsForm/Lining/Materials";

/**Pintura */
import PaintStepForm from "../../../components/StepsForm/Painting/Paint"
import WallpaperStepForm from "../../../components/StepsForm/Painting/Wallpaper"
import MaterialsPaintingStepForm from "../../../components/StepsForm/Painting/Materials";

/**Louças e metais */
import Accessories from "../../../components/StepsForm/TablewareAndMetals/Accessories";
import Cuba from "../../../components/StepsForm/TablewareAndMetals/Vats";
import FaucetAndMixer from "../../../components/StepsForm/TablewareAndMetals/FaucetAndMixer";
import Showers from "../../../components/StepsForm/TablewareAndMetals/Showers";
import Vases from "../../../components/StepsForm/TablewareAndMetals/Vases";
import MaterialsTablewareAndMetalsStepForm from "../../../components/StepsForm/TablewareAndMetals/Materials";

/**Telhado */
import RoofStepForm from "../../../components/StepsForm/Roof/Roof";
import MaterialsRoofStepForm from "../../../components/StepsForm/Roof/Materials";

/**Vidraçaria */
import BalconyClosing from "../../../components/StepsForm/Glassworks/BalconyClosing";
import BathroomBox from "../../../components/StepsForm/Glassworks/BathroomBox";
import GlassDoor from "../../../components/StepsForm/Glassworks/GlassDoor";
import Mirror from "../../../components/StepsForm/Glassworks/Mirror";
import MaterialsGlassworksStepForm from "../../../components/StepsForm/Glassworks/Materials";

/**Marmoraria */
import ActivityGenericForm from "../../../components/StepsForm/MarbleWork/ActivityGenericForm";
import FloorsAndCountertops from "../../../components/StepsForm/MarbleWork/SinkAndCountertops";
import Coating from "../../../components/StepsForm/MarbleWork/Coating";
import Shelf from "../../../components/StepsForm/MarbleWork/Shelf"
import MaterialsMarbleworkStepForm from "../../../components/StepsForm/MarbleWork/Materials";

/**Serralheria */
import SawmillTable from "../../../components/StepsForm//Sawmill/SawmillActivity"
import MaterialsSawmillStepForm from "../../../components/StepsForm/Sawmill/Materials";

/** Marcenaria */
import WoodWork from "../../../components/StepsForm/WoodWork/WoodWork";
import MaterialsWoodWorkStepForm from "../../../components/StepsForm/WoodWork/Materials";

/**Portas e janelas */
import DoorsAndWindowsStepForm from "../../../components/StepsForm/DoorsAndWindows/DoorsAndWindows/DoorsAndWindows";
import MaterialsDoorsAndWindowsStepForm from "../../../components/StepsForm/DoorsAndWindows/Materials";

/**Esquadrias */
import FramesStepForm from "../../../components/StepsForm/Frames/Frames";
import MaterialsFramesStepForm from "../../../components/StepsForm/Frames/Materials";
import FurnitureStepForm from "../../../components/StepsForm/Furniture/Furniture";
import MaterialsFurnitureStepForm from "../../../components/StepsForm/Furniture/Materials";
import MaterialsProjectStepStepForm from "../../../components/StepsForm/ProjectStep/Materials";
import ArchitecturalProjectStepForm from "../../../components/StepsForm/ProjectStep/ArchitecturalProject";
import EletricProjectStepForm from "../../../components/StepsForm/ProjectStep/EletricProject";
import StructuralProjectStepForm from "../../../components/StepsForm/ProjectStep/StructuralProject";
import HydrosanitaryProjectStepForm from "../../../components/StepsForm/ProjectStep/HydrosanitaryProject";
import AirConditionerProjectStepForm from "../../../components/StepsForm/ProjectStep/AirConditionerProject";

/**Limpeza */
import CleaningStepForm from "../../../components/StepsForm/Cleaning/Cleaning";

/**Mudança */
import MovingStepForm from "../../../components/StepsForm/Moving/Moving";
import MaterialsCleaningStepForm from "../../../components/StepsForm/Cleaning/Materials";
import MaterialsMovingStepForm from "../../../components/StepsForm/Moving/Materials";

const ShowActivityController: React.FC = () => {
    const { activitySelected } = UseStepContext()

    return (
        <ActivitiesContainer>
            {
                activitySelected === "Demolicao" ? (<Demolition stepFormExibition={true} />) :
                    activitySelected === "Materiais para demolicao" ? (<MaterialsDemolitionStepForm stepFormExibition={true} />) :
                        activitySelected === "Materiais para hidraulica" ? (<MaterialsHydraulicStepForm stepFormExibition={true} />) :
                            activitySelected === "Materiais para ar-condicionado" ? (<MaterialsAirConditionerStepForm stepFormExibition={true} />) :
                                activitySelected === "Materiais para pisos e revestimentos" ? (<MaterialsFloorsAndCoveringsStepForm stepFormExibition={true} />) :
                                    activitySelected === "Materiais para vidracaria" ? (<MaterialsGlassworksStepForm stepFormExibition={true} />) :
                                        activitySelected === "Materiais para forro" ? (<MaterialsLiningStepForm stepFormExibition={true} />) :
                                            activitySelected === "Materiais para marmoaria" ? (<MaterialsMarbleworkStepForm stepFormExibition={true} />) :
                                                activitySelected === "Materiais para pintura" ? (<MaterialsPaintingStepForm stepFormExibition={true} />) :
                                                    activitySelected === "Materiais para telhado" ? (<MaterialsRoofStepForm stepFormExibition={true} />) :
                                                        activitySelected === "Materiais para serralheria" ? (<MaterialsSawmillStepForm stepFormExibition={true} />) :
                                                            activitySelected === "Materiais para loucas e metais" ? (<MaterialsTablewareAndMetalsStepForm stepFormExibition={true} />) :
                                                                activitySelected === "Materiais para marcenaria" ? (<MaterialsWoodWorkStepForm stepFormExibition={true} />) :
                                                                    activitySelected === "Materiais para limpeza" ? (<MaterialsCleaningStepForm stepFormExibition={true} />) :
                                                                        activitySelected === "Materiais para mudanca" ? (<MaterialsMovingStepForm stepFormExibition={true} />) :
                                                                            activitySelected === "Fundacao" ? (<Fundation stepFormExibition={true} />) :
                                                                                activitySelected === "Estrutura" ? (<Structure stepFormExibition={true} />) :
                                                                                    activitySelected === "Fechamento" ? (<Closure stepFormExibition={true} />) :
                                                                                        activitySelected === "Piso" ? (<Floor stepFormExibition={true} />) :
                                                                                            activitySelected === "Laje" ? (<Flagstone stepFormExibition={true} />) :
                                                                                                activitySelected === "Requadramento de vao" ? (<Refraiming stepFormExibition={true} />) :
                                                                                                    activitySelected === "Soculo em alvenaria" ? (<Soculo stepFormExibition={true} />) :
                                                                                                        activitySelected === "Escadas" ? (<Stairways stepFormExibition={true} />) :
                                                                                                            activitySelected === "Materiais para alvenaria" ? (<MaterialsMasonaryStepForm stepFormExibition={true} />) :
                                                                                                                activitySelected === "Materiais para impermeabilizacao" ? (<MaterialsImpermeabilizationStepForm stepFormExibition={true} />) :
                                                                                                                    activitySelected === "Impermeabilizacao" ? (<ImpermeabilizationStepForm stepFormExibition={true} />) :
                                                                                                                        activitySelected === "Painel eletrico" ? (<PanelEletric stepFormExibition={true} />) :
                                                                                                                            activitySelected === "Passagem da infra" ? (<Conduite stepFormExibition={true} />) :
                                                                                                                                activitySelected === "Passagem de fios" ? (<PassingOfWires stepFormExibition={true} />) :
                                                                                                                                    activitySelected === "Instalacoes" ? (<Instalation stepFormExibition={true} />) :
                                                                                                                                        activitySelected === "Automacoes" ? (<Automations stepFormExibition={true} />) :
                                                                                                                                            activitySelected === "Materiais para eletrica" ? (<MaterialsEletricStepForm stepFormExibition={true} />) :
                                                                                                                                                activitySelected === "Agua fria" ? (<ColdWaterStepForm stepFormExibition={true} />) :
                                                                                                                                                    activitySelected === "Agua quente" ? (<HotWaterStepForm stepFormExibition={true} />) :
                                                                                                                                                        activitySelected === "Esgoto" ? (<SewerStepForm stepFormExibition={true} />) :
                                                                                                                                                            activitySelected === "Gas" ? (<GasStepForm stepFormExibition={true} />) :
                                                                                                                                                                activitySelected === "Ar condicionado" ? (<AirConditioner stepFormExibition={true} />) :
                                                                                                                                                                    activitySelected === "Revestimento de parede" ? (<WallCoveringStepForm stepFormExibition={true} />) :
                                                                                                                                                                        activitySelected === "Revestimento de piso ceramico" ? (<CeramicFloorCoveringStepForm stepFormExibition={true} />) :
                                                                                                                                                                            activitySelected === "Revestimento de piso vinilico" ? (<VinylFloorCoveringStepForm stepFormExibition={true} />) :
                                                                                                                                                                                activitySelected === "Revestimento de piso - Pedras" ? (<StoneFloorCoveringStepForm stepFormExibition={true} />) :
                                                                                                                                                                                    activitySelected === "Revestimento de madeira" ? (<WoodCoveringStepForm stepFormExibition={true} />) :
                                                                                                                                                                                        activitySelected === "Rodape" ? (<BaseBoardStepForm stepFormExibition={true} />) :
                                                                                                                                                                                            activitySelected === "Assoalho" ? (<FlooringStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                activitySelected === "Forro" ? (<Lining stepFormExibition={true} />) :
                                                                                                                                                                                                    activitySelected === "Pintura" ? (<PaintStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                        activitySelected === "Papel de parede" ? (<WallpaperStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                            activitySelected === "Vasos" ? (<Vases stepFormExibition={true} />) :
                                                                                                                                                                                                                activitySelected === "Torneira e misturador" ? (<FaucetAndMixer stepFormExibition={true} />) :
                                                                                                                                                                                                                    activitySelected === "Cubas" ? (<Cuba stepFormExibition={true} />) :
                                                                                                                                                                                                                        activitySelected === "Chuveiros" ? (<Showers stepFormExibition={true} />) :
                                                                                                                                                                                                                            activitySelected === "Acessorios" ? (<Accessories stepFormExibition={true} />) :
                                                                                                                                                                                                                                activitySelected === "Telhado" ? (<RoofStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                    activitySelected === "Box de banheiro" ? (<BathroomBox />) :
                                                                                                                                                                                                                                        activitySelected === "Fechamento de sacada" ? (<BalconyClosing />) :
                                                                                                                                                                                                                                            activitySelected === "Porta de vidro e esquadrias" ? (<GlassDoor />) :
                                                                                                                                                                                                                                                activitySelected === "Espelhos" ? (<Mirror />) :
                                                                                                                                                                                                                                                    activitySelected === "Nicho" ? (<Nicho />) :
                                                                                                                                                                                                                                                        activitySelected === "Revestimento" ? (<Coating />) :
                                                                                                                                                                                                                                                            activitySelected === "Tento" ? (<ActivityGenericForm />) :
                                                                                                                                                                                                                                                                activitySelected === "Soleira" ? (<ActivityGenericForm />) :
                                                                                                                                                                                                                                                                    activitySelected === "Baguete" ? (<ActivityGenericForm />) :
                                                                                                                                                                                                                                                                        activitySelected === "Roda base" ? (<ActivityGenericForm />) :
                                                                                                                                                                                                                                                                            activitySelected === "Pias e bancadas" ? (<FloorsAndCountertops />) :
                                                                                                                                                                                                                                                                                activitySelected === "Prateleira" ? (<Shelf />) :
                                                                                                                                                                                                                                                                                    activitySelected === "Mesas" ? (<SawmillTable />) :
                                                                                                                                                                                                                                                                                        activitySelected === "Portas e janelas" ? (<DoorsAndWindowsStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                            activitySelected === "Materiais para portas e janelas" ? (<MaterialsDoorsAndWindowsStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                activitySelected === "Esquadrias" ? (<FramesStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                    activitySelected === "Materiais para esquadrias" ? (<MaterialsFramesStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                        activitySelected === "Mobiliario" ? (<FurnitureStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                            activitySelected === "Materiais para mobiliario" ? (<MaterialsFurnitureStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                activitySelected === "Materiais para projeto" ? (<MaterialsProjectStepStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                    activitySelected === "Projeto arquitetonico" ? (<ArchitecturalProjectStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                        activitySelected === "Projeto eletrico" ? (<EletricProjectStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                            activitySelected === "Projeto estrutural" ? (<StructuralProjectStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                                activitySelected === "Projeto hidrossanitario" ? (<HydrosanitaryProjectStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                                    activitySelected === "Projeto de ar condicionado" ? (<AirConditionerProjectStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                                        activitySelected === "Marcenaria" ? (<WoodWork modifyActivity={false} />) :
                                                                                                                                                                                                                                                                                                                                            activitySelected === "Limpeza" ? (<CleaningStepForm stepFormExibition={true} />) :
                                                                                                                                                                                                                                                                                                                                                activitySelected === "Mudança" ? (<MovingStepForm stepFormExibition={true} />) : (
                                                                                                                                                                                                                                                                                                                                                    <MessageContent>Selecione uma etapa!</MessageContent>
                                                                                                                                                                                                                                                                                                                                                )
            }
        </ActivitiesContainer>
    )
}

export default ShowActivityController