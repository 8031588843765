import styled from "styled-components"

export const InputActivity = styled.div`
    margin-top: 0.5rem;
    margin-bottom:0.5rem;
    padding: 0.5rem;
    background-color: #E1E1E1;
    color: #777777;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size:0.9rem;
    input{
        border: none;
        background-color: #E1E1E1;

    }

    .noBreakLine{
        white-space: nowrap;
    }
`

export const FlexContainer = styled.div`
    display: flex;
    align-items:center;
    div{
        margin-right:3rem;
    }

    .fileIcon{
        width: 0.5rem;
        img{
            width: 2rem;
            cursor: pointer;
        }
    }

    .containerBancada{
        margin: 0;
        div{
            margin-right: 0.5rem;
        }
        :last-child{
            margin-right: 0;
        }
    }

    .containerBorder{
        border: 1px solid #E1E1E1;
        display: flex;
        align-items:center;
        padding: 0.5rem;
        border-radius:0.4rem;

        div{
            margin: 0;
        }
    }

    .selectContainerDiv{
        width: 12rem
    }
    .observation{
        color: #777777;
        font-size:0.7rem;
    }
    :last-child{
        margin: 0;
    }
`

export const UploadMessage = styled.div`
    background-color: #fff;
    white-space: nowrap;
`
