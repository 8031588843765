import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    max-height:70vh;
    padding:0.5rem;
`

export const ModalContainer = styled.div`
    max-height:70vh;
    padding:0.5rem;
`

export const ActivityDescriptionContainer = styled.div`
    background-color: #F1F2F3;
    padding: 1.5rem;
    padding-bottom: 2rem;
    border-radius: 0.3rem;
    width: 95%;
   
    height: 100%;

    .bold{
        color: #141414;
        font-weight: 500;
    }

    h4{
        font-weight: 500;
    }

    p{
        color: #777777;
        font-weight: 400;
    }

    .item{
        margin-top: 1rem;

        .line{
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            margin-bottom:0.5rem;
            p{
                white-space: nowrap;
            }
        }
    }
`

export const ModifyActivity = styled.div`
    display:flex;
    justify-content:end;
    margin-top:1rem;
    cursor:pointer;
    p{
        padding:0.3rem;
        text-decoration:underline;
        color:#141414;
        :hover{
            font-weight:500;
        }
    }
`

export const ComparativeContainer = styled.div`
    background-color:  #E5E5E5;
    max-width: 70vw;
    max-height: 70vh;
    overflow-y: auto;
    
`





