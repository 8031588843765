import React, { useEffect, useState } from "react";
import axios from "axios";
import { UseUserContext } from "../../Context/UserContext";
import { UseModalContext } from "../../Context/ModalContext";

import { Container, ContentBox, ContainerInput, Button, ContainerModal, FormContainer, HeaderFormContainer, ModalContent, FormRegisterServiceInformation, ContainerListItens, OrientationDescrible, Item, SavedLinksDescription, DisabledButton } from "./style";
import {
  ContainerInputForm,
  ContainerSearch,
  FooterFormContainer,
  Select,
  Table,
} from "../../components/stylesDomElements/htmlStyledElements";

import { ProjectProps } from "../../types/typesEntitiesProject"

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import Modal from "../../components/Modal";

import TrTable from "./Tr";

import searchIcon from "../../assets/searchIcon.svg";
import addIcon from "../../assets/addIcon.svg";
import createLinkIcon from "../../assets/create-link.svg"
import iconCloseModal from '../../assets/XIcon.svg'
import iconClose from "../../assets/XIcon.svg";

const AcessProject: React.FC = () => {
  const { setShowModal } = UseModalContext();
  const { userId, projectSelected } = UseUserContext()
  const [project] = useState<ProjectProps>();

  const [email, setEmail] = useState<string>('')
  const [permission, setPermission] = useState<string>('')
  const [linkGenerationModal, setLinkGenerationModal] = useState<boolean>(false)
  const [permissionsList, setPermissionsList] = useState<string[]>([])
  const [permissionsListed, setPermissionsListed] = useState<string>('selecione')
  const [savedLinks, setSavedLinks] = useState<any[]>([])
  const [actualLink, setActualLink] = useState<string>('')
  const [updateSavedLinksArea, setUpdateSavedLinksArea] = useState<boolean>(false)
  const [linkExpiration, setLinkExpiration] = useState<string>('168h')
  const [editAuthorization, setEditAuthorization] = useState<string>('Visualizacao')

  useEffect(() => {
    getSavedLinks()
  }, [updateSavedLinksArea])

  const sendInvite = () => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/inviteProject`, {
      guestEmail: email,
      permission: permission,
      projectName: project?.name,
      projectId: projectSelected,
      architectIdSender: userId
    }).then(res => {
      if (res.status === 200)
        setShowModal?.();
    })
  }

  const generateLink = async () => {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/newLinkAcess`, {
      projectId: projectSelected,
      architectId: userId,
      features: permissionsList,
      linkExpiration,
      editAuthorization
    }).then(res => {
      if (res.status === 200) {
        setActualLink(res.data)
        copyLinkToClipboard(res.data)
        setUpdateSavedLinksArea(!updateSavedLinksArea)
        console.log(projectSelected)
      }
    })
  }


  const getSavedLinks = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/listAccessLinkProject/${projectSelected}`, {
    }).then(res => {
      if (res.status === 200) {
        setSavedLinks(res.data)
      }
    })
  }

  const DeleteLink = (id: string) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/removeLink/${id}`, {

    }).then(res => {
      if (res.status === 200) {
        console.log('link deletado')
      }
      setUpdateSavedLinksArea(!updateSavedLinksArea)
    }).catch(err => {
      console.log(err)
    })
  }

  const addPermissionsList = (step: string) => {
    setPermissionsListed(step);
    let permissionsListCopy = permissionsList
    permissionsListCopy.push(step)
    setPermissionsList([...permissionsListCopy])
    setPermissionsListed("selecione");
  };

  const dropItem = (index: number) => {
    let permissionsCopy = permissionsList
    permissionsCopy.splice(index, 1)
    setPermissionsList([...permissionsCopy])
  };

  const copyLinkToClipboard = (link: string) => {
    navigator.clipboard.writeText(link)
      .then(() => console.log(`Link ${link} copiado para a área de transferência.`))
      .catch((error) => console.error(`Erro ao copiar link ${link}: ${error}`));
  }

  return (
    <div>
      <Modal titleForm="Acesso">
        <div>
          <p>Convite enviado para: {email} </p>
        </div>
      </Modal>

      {linkGenerationModal ? (
        <ContainerModal>
          <div>
            <FormContainer>
              <HeaderFormContainer>
                <p>Compartilhar {/**Nome do projeto*/}</p>
                <img
                  src={iconCloseModal}
                  alt="icone de fechar"
                  onClick={() => setLinkGenerationModal(!linkGenerationModal)}
                />
              </HeaderFormContainer>
              <ModalContent>

                <SavedLinksDescription>
                  <label>Links de acesso:</label>
                  <div className="itensDescription">
                    {savedLinks.map((item, index) => (

                      <div key={index}>
                        {item.features.join(', ')}
                        <Button onClick={() => copyLinkToClipboard(item.link)}><img src={createLinkIcon} alt="Icone de adicionar" /> Copiar link</Button>
                        <Button onClick={() => DeleteLink(item._id)}><span>x</span>Excluir</Button>
                      </div>
                    ))}
                  </div>
                </SavedLinksDescription>
                <div>
                  <FormRegisterServiceInformation>
                    <div className="gridContent">
                      <div className="group1">
                        <ContainerInputForm>
                          <label htmlFor="stepsSelection">Modo</label>
                          <Select name="bank select" id="stepsSelection" value={editAuthorization} onChange={(e) => setEditAuthorization(e.target.value)}>
                            <option value="Visualizacao">Visualização</option>
                            <option value="Editor">Editor</option>
                          </Select>
                        </ContainerInputForm>
                        <ContainerInputForm>
                          <label htmlFor="stepsSelection">Selecionar recurso</label>
                          <Select name="bank select" id="stepsSelection" value={permissionsListed} onChange={(e) => addPermissionsList(e.target.value)}>
                            <option value="selecione">Selecione</option>
                            <option value="Cronograma">Cronograma</option>
                            <option value="Mapa de cotacao">Mapa de cotação</option>
                            <option value="Cronograma de obra">Cronograma de obra</option>
                            <option value="Lista">Lista</option>
                            <option value="Acompanhamento">Acompanhamento</option>
                            <option value="Plantas">Plantas</option>
                            <option value="Fornecedores">Fornecedores</option>
                            <option value="Infos">Infos</option>
                            <option value="Resumo semanal">Resumo semanal</option>
                          </Select>
                        </ContainerInputForm>
                      </div>
                      <div className="group2">
                        <ContainerInputForm>
                          <label htmlFor="stepsSelection">Link expira em </label>
                          <Select name="bank select" id="stepsSelection" value={linkExpiration} onChange={(e) => setLinkExpiration(e.target.value)}>
                            <option value="1h">1h</option>
                            <option value="6h">6h</option>
                            <option value="24h">1 dia</option>
                            <option value="168h">1 semana</option>
                            <option value="731h">1 mês</option>
                            <option value="9999 years">Nunca</option>
                          </Select>
                        </ContainerInputForm>

                        <label>Permissões selecionadas</label>
                        <ContainerListItens>
                          {permissionsList.map(
                            (item, index) => (
                              <div key={index}>
                                {item !== "" ? (
                                  <Item key={index}>
                                    {item}
                                    <img src={iconClose} alt="icone de fechar" onClick={() => dropItem(index)} />
                                  </Item>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ))}
                        </ContainerListItens>
                      </div>
                    </div>

                  </FormRegisterServiceInformation>
                </div>
                <div className="buttonArea">

                  {permissionsList.length !== 0 && linkExpiration !== '' && editAuthorization !== '' ? (
                    <Button onClick={() => generateLink()}>Gerar link</Button>
                  ) : (
                    <DisabledButton disabled onClick={() => generateLink()}>Gerar link</DisabledButton>
                  )}
                </div>
                <div className="copyMessage">
                  {actualLink !== '' ? (
                    <p>Link copiado para área de transferência.</p>
                  ) : null}
                </div>
                <br />
              </ModalContent>
            </FormContainer>
          </div>
        </ContainerModal>
      ) : null
      }

      <Header />
      <ItensMenu optionSelected="Acessos" />
      <Container>
        <ContentBox>
          <ContainerSearch>
            <img src={searchIcon} alt="Icone Lupa" />
            <input type="text" placeholder="Pesquisar no time" />
            <span>X</span>
          </ContainerSearch>

          <div className="leftContents">
            <div className="containerAddMenbers">
              <p>Adicionar membros: </p>
              <ContainerInput>
                <input
                  type="text"
                  placeholder="nome@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </ContainerInput>
            </div>

            <ContainerInput>
              <select name="" id="" onChange={(e) => setPermission(e.target.value)}>
                <option value="Visualizacao">Visualização</option>
                <option selected value="Arquiteto">Arquiteto</option>
                <option value="Cliente">Cliente</option>
              </select>
            </ContainerInput>

            <Button onClick={() => sendInvite()}>
              <img src={addIcon} alt="Icone de adicionar" /> Convidar
            </Button>
            <Button onClick={() => setLinkGenerationModal(!linkGenerationModal)}>
              <img src={createLinkIcon} alt="Icone de adicionar" /> Gerar link
            </Button>
          </div>
        </ContentBox>
        <Table>
          <thead>
            <tr className="headerTable">
              <th>Usuário</th>
              <th>Permissão</th>
              <th>Email</th>
              <th>Contato</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <TrTable idUser={userId} projectId={projectSelected}></TrTable>
          </tbody>
        </Table>
      </Container>
    </div >
  );
};

export default AcessProject;
