import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Container,
  Complete,
  ActivityContent,
  MenuOverlay,
  Input,
  ActivityTitle,
  ActivityDetailsRow,
} from "./style";
import { OptionButton } from "../../components/stylesDomElements/htmlStyledElements";

import pointsMenu from "../../assets/pointsMenu.svg";
import checkIcon from "../../assets/checkIconGreen.svg";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";

interface ActivtyProps {
  activityId?: string;
  activityName?: string;
  percentage?: number;
  backgroundColor: string;
  oppenModalActivity: any;
  setEditActivity?: () => void;
}

const Activity: React.FC<ActivtyProps> = (props) => {
  const { editPermission } = UseAuthAcessContext();
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [renameActivity, setRenameActivity] = useState<boolean>(false);
  const [changeRename, setChangeRename] = useState<boolean>(false);
  const [isEditAuthorized, setIsEditAuthorized] = useState<boolean>(false);

  const [newActivityName, setNewActivityName] = useState<string>("");

  useEffect(() => {
    if (editPermission === "Editor" || editPermission === "") {
      setIsEditAuthorized(true);
    } else if (editPermission === "Visualizacao") {
      setIsEditAuthorized(false);
    }
  }, [editPermission]);

  const deleteActivity = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/deleteActivity/${props.activityId}`
      )
      .then((res) => {
        if (res.status === 200) setVisibleMenu(false);
        window.location.reload();
      });
  };

  const setStates = () => {
    setRenameActivity(true);
    setVisibleMenu(false);
  };

  const setChangeName = (value: string) => {
    setChangeRename(true);
    setNewActivityName(value);
  };

  const renameActivityName = () => {
    if (newActivityName) {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/renameActivity/${props.activityId}`,
          { newActivityName },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          if (res.status === 200) {
            setChangeRename(false);
            setRenameActivity(false);
          }
        });
    }
  };

  return (
    <Container>
      <Complete
        width={`${props.percentage}%`}
        backgroundColor={props.backgroundColor}
      >
        <ActivityContent isEditAuthorized={isEditAuthorized}>
          {renameActivity ? (
            <Input
              type="text"
              placeholder="Digite aqui"
              onChange={(e) =>
                e.target.value.length
                  ? setChangeName(e.target.value)
                  : setChangeRename(false)
              }
            />
          ) : (
            <ActivityDetailsRow onClick={props.oppenModalActivity}>
              <ActivityTitle>{props.activityName}</ActivityTitle>{" "}
              <span>({props.percentage?.toFixed(0)}%)</span>
            </ActivityDetailsRow>
          )}

          {changeRename ? (
            <img
              src={checkIcon}
              alt="Icone de check"
              onClick={() => renameActivityName()}
            />
          ) : isEditAuthorized ? (
            <img
              onClick={() => setVisibleMenu(!visibleMenu)}
              src={pointsMenu}
              alt="três pontinhos"
            />
          ) : null}

          {visibleMenu ? (
            <MenuOverlay>
              <OptionButton onClick={() => setStates()}>Renomear</OptionButton>
              <OptionButton onClick={() => deleteActivity()}>
                Excluir
              </OptionButton>
              {window.location.pathname === "/adicionarEtapa" ? (
                <OptionButton onClick={props.setEditActivity}>
                  Editar
                </OptionButton>
              ) : (
                <></>
              )}
            </MenuOverlay>
          ) : (
            <></>
          )}
        </ActivityContent>
      </Complete>
    </Container>
  );
};

export default Activity;
