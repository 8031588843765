import { differenceInCalendarMonths } from "date-fns"
export const formateDay = (startDate?: string, endDate?: string, monthSelected?: number, yearActual?: number) => {
  if (startDate && endDate) {
    var startDay = startDate.split("/");
    var endDay = endDate.split("/");

    var dayInitialDate = parseInt(startDay[0])

    var dayFinishDate = parseInt(endDay[0])

    var monthInitialDate = parseInt(startDay[1])

    var monthFinishDate = parseInt(endDay[1])

    var yearInitialDate = parseInt(startDay[2])

    var yearFinishDate = parseInt(endDay[2])

    const intervalMonths = differenceInCalendarMonths(
      new Date(yearFinishDate, monthFinishDate, dayFinishDate),
      new Date(yearInitialDate, monthInitialDate, dayInitialDate)
    )

    


    if (yearInitialDate === yearActual || yearFinishDate === yearActual) {
      if (yearInitialDate === yearFinishDate && monthSelected == monthInitialDate && monthSelected == monthFinishDate) {

        /**1) O ano inicial é igual o ano final e o mês selecionado é igual o mês inicial e o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${dayInitialDate}/${dayFinishDate + 1}`;
        return dateFinal;
      } else if (yearInitialDate === yearFinishDate && monthSelected == monthInitialDate && monthInitialDate < monthFinishDate) {

        /**2) O ano inicial é igual o ano final e o mês selecionado é igual o mês inicial e o mês inicial é menor que o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${dayInitialDate}/${numberOfDaysMonth + 1}`;
        return dateFinal;
      } else if (yearInitialDate === yearFinishDate && monthSelected && monthSelected > monthInitialDate && monthSelected < monthFinishDate) {

        /**3) O ano inicial é igual o ano final e o mês selecionado é maior que o mês inicial e menor que o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${1}/${numberOfDaysMonth + 2}`;
        return dateFinal;
      } else if (yearInitialDate === yearFinishDate && monthSelected && monthSelected > monthInitialDate && monthSelected == monthFinishDate) {

        /**4) O ano inicial é igual o ano final e o mês selecionado é maior que o mês inicial igual o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${1}/${dayFinishDate + 1}`;
        return dateFinal;

        /**Fim periodo no mesmo ano */
      } else if (yearInitialDate === yearFinishDate && monthSelected && monthSelected > monthInitialDate && monthSelected < monthFinishDate) {

        /**5) O ano inicial é igual o ano final e o mês selecionado é menor que o mês inicial e menor que o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${1}/${numberOfDaysMonth + 1}`;
        return dateFinal;

        /**Fim periodo no mesmo ano */
      } else if (yearInitialDate < yearFinishDate && monthSelected == monthInitialDate && monthInitialDate > monthFinishDate) {

        /**6) O ano inicial é menor que o ano final e o mês selecionado é igual o mês inicial e o mês inicial é maior que o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${dayInitialDate}/${numberOfDaysMonth + 1}`;
        return dateFinal;
      } else if (yearInitialDate < yearFinishDate && monthSelected && monthSelected < monthInitialDate && monthSelected < monthFinishDate) {

        /**6) O ano inicial é menor que o ano final e o mês selecionado é menor que o mês inicial e o mês selecionado é menor que o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${1}/${numberOfDaysMonth + 1}`;
        return dateFinal;
      } else if (yearInitialDate < yearFinishDate && monthSelected && monthSelected == monthFinishDate) {

        /**7) O ano inicial é menor que o ano final e o mês selecionado é igual o mês final */
        var numberOfDaysMonth = new Date(yearInitialDate, monthInitialDate, 0).getDate()
        var dateFinal = `${1}/${dayFinishDate + 1}`;
        return dateFinal;
      } else {
        dateFinal = `-1/-1`;
        return dateFinal;
      }
    }
    else {
      dateFinal = `-1/-1`;
      return dateFinal;
    }
  }
};

export const formateMontsCronogramExibition = (initialDate: string, finishDate: string, yearNumber: number) => {
  const startDateParts = initialDate.split("/");
  const endDateParts = finishDate.split("/");
  const startDate = new Date(
    Number(startDateParts[2]),
    Number(startDateParts[1]) - 1,
    Number(startDateParts[0])
  );
  const endDate = new Date(
    Number(endDateParts[2]),
    Number(endDateParts[1]) - 1,
    Number(endDateParts[0])
  );
  const start = new Date(startDate.getFullYear(), 0, 0);
  const end = new Date(endDate.getFullYear(), 0, 0);

  const startDiff = startDate.getTime() - start.getTime();
  const endDiff = endDate.getTime() - end.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const startDayOfYear = Math.floor(startDiff / oneDay);
  const endDayOfYear = Math.floor(endDiff / oneDay);

  if (Number(startDateParts[2]) === yearNumber && Number(endDateParts[2]) === yearNumber) {
    return `${startDayOfYear}/${endDayOfYear + 1}`;
  } else if (Number(startDateParts[2]) < yearNumber && Number(endDateParts[2]) === yearNumber) {
    return `${1}/${endDayOfYear + 1}`
  } else if (Number(startDateParts[2]) === yearNumber && Number(endDateParts[2]) > yearNumber) {
    return `${startDayOfYear}/${366}`
  } else {
    return `-1/-1`;
  }
}
