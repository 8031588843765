import React, { useState, useEffect } from "react";

import { UseHydraulicContext } from "../../../../Context/ActivitiesContext/Hydraulic/HidraulicContext";
import { attributeType } from "../../../../Models/attributeType";
import { SewerFormType } from "../../../../Models/Hydraulic/type";
import { Sewer } from "../../../../Models/Hydraulic/SewerModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const SewerStepForm: React.FC<stepFormProps> = (props) => {
  const { hydraulicActivities, indexHydraulicActivitySelected, editHydraulicActivity } = UseHydraulicContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<SewerFormType>(Sewer)

  useEffect(() => {
    if (hydraulicActivities.length > 0) {
      let hydraulicActivityCopy = hydraulicActivities[indexHydraulicActivitySelected]
      hydraulicActivityCopy.activityType = 'Esgoto'
      editHydraulicActivity?.(indexHydraulicActivitySelected, hydraulicActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHydraulicActivitySelected])

  useEffect(() => {
    if (hydraulicActivities.length > 0) {
      setItensFormList(hydraulicActivities[indexHydraulicActivitySelected].Sewer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHydraulicActivitySelected, hydraulicActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemEmendas(0)
      removeItemPontoDeEsgoto(0)
      removeItemRetirarPontoDeEsgoto(0)
      removeItemTubulacao(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Saida de esgoto") {
      addSaidaDeEsgoto({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Retirar ponto de esgoto") {
      addRetirarPontoDeEsgoto({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Tubulacao") {
      addTubulacao(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm linear',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    } else if (assignment === "Emendas") {
      addEmendas(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            }
          ]
        }
      )
    }
  }

  const addRetirarPontoDeEsgoto = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeEsgoto.push(object)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addTubulacao = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao.push(object)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addEmendas = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas.push(object)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const addSaidaDeEsgoto = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.SaidaDeEsgoto.push(object)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemRetirarPontoDeEsgoto = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeEsgoto.splice(index, 1)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemTubulacao = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao.splice(index, 1)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemEmendas = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas.splice(index, 1)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const removeItemPontoDeEsgoto = (index: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.SaidaDeEsgoto.splice(index, 1)
    hydraulicActivitiesCopy[0].Sewer = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRetirarPontoDeEsgotoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeEsgoto[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editRetirarPontoDeEsgotoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.RetirarPontoDeEsgoto[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editTubulacaoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Tubulacao[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editEmendasValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editEmendasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.Emendas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeEsgotoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.SaidaDeEsgoto[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  const editPontoDeEsgotoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var hydraulicActivitiesCopy = hydraulicActivities
    itensFormListCopy.SaidaDeEsgoto[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editHydraulicActivity?.(0, hydraulicActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />

      {itensFormList.RetirarPontoDeEsgoto.length ? (
        <div>
          <p>Retirar ponto de esgoto</p>
          {itensFormList.RetirarPontoDeEsgoto.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRetirarPontoDeEsgotoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRetirarPontoDeEsgotoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRetirarPontoDeEsgotoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRetirarPontoDeEsgotoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRetirarPontoDeEsgotoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemRetirarPontoDeEsgoto(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


      {itensFormList.Tubulacao.length ? (
        <div>
          <p>Tubulação</p>
          {itensFormList.Tubulacao.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTubulacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTubulacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editTubulacaoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editTubulacaoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemTubulacao(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Emendas.length ? (
        <div>
          <p>Emendas</p>
          {itensFormList.Emendas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmendasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmendasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editEmendasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemEmendas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.SaidaDeEsgoto.length ? (
        <div>
          <p>Saída de esgoto</p>
          {itensFormList.SaidaDeEsgoto.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeEsgotoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeEsgotoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeEsgotoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeEsgotoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeEsgotoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeEsgoto(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

    </div>
  );
};

export default SewerStepForm;
