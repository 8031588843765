import React, { useState, useEffect } from "react";

import { UseEletricActivityContext } from "../../../../Context/ActivitiesContext/Eletric/EletricContext";
import { attributeType } from "../../../../Models/attributeType";
import { InstallationsFormType } from "../../../../Models/Eletric/type";
import { Installations } from "../../../../Models/Eletric/InstallationsModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const InstallationsStepForm: React.FC<stepFormProps> = (props) => {
  const { eletricActivities, indexEletricActivitySelected, editEletricActivity } = UseEletricActivityContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<InstallationsFormType>(Installations)

  useEffect(() => {
    if (eletricActivities.length > 0) {
      let eletricActivityCopy = eletricActivities[indexEletricActivitySelected]
      eletricActivityCopy.activityType = 'Instalacoes'
      editEletricActivity?.(indexEletricActivitySelected, eletricActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected])

  useEffect(() => {
    if (eletricActivities.length > 0) {
      setItensFormList(eletricActivities[indexEletricActivitySelected].Installations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected, eletricActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemAntena(0)
      removeItemArandela(0)
      removeItemCaboDeInternet(0)
      removeItemCameraDeSeguranca(0)
      removeItemExaustor(0)
      removeItemFitasDeLed(0)
      removeItemInterfone(0)
      removeItemInterruptores(0)
      removeItemLampadas(0)
      removeItemLuminarias(0)
      removeItemPendentes(0)
      removeItemPlafon(0)
      removeItemPontoDeDados(0)
      removeItemPontoDeForca(0)
      removeItemPontoDeTv(0)
      removeItemSensores(0)
      removeItemSpot(0)
      removeItemTomadas(0)
      removeItemTrilhos(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Lampadas") {
      addLampadas({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Interruptores") {
      addInterruptores({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Tomadas") {
      addTomadas({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Altura',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Fitas de led") {
      addFitasDeLed(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Luminarias") {
      addLuminarias(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Spot") {
      addSpot(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Arandela") {
      addArandela(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Trilhos") {
      addTrilhos(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Pendentes") {
      addPendentes(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Antena") {
      addAntena(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Cabo de internet") {
      addCaboDeInternet(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Interfone") {
      addInterfone(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Camera de seguranca") {
      addCameraDeSeguranca(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Sensores") {
      addSensores(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Plafon") {
      addPlafon(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Ponto de dados") {
      addPontoDeDados(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Ponto de TV") {
      addPontoDeTv(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Exaustor") {
      addExaustor(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Ponto de forca") {
      addPontoDeForca(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Altura',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    }
  }

  const addArandela = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Arandela.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
    console.log('oi')
  }

  const addInterruptores = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interruptores.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addFitasDeLed = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.FitasDeLed.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addTomadas = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Tomadas.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addLuminarias = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Luminarias.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addSpot = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Spot.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addLampadas = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Lampadas.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addTrilhos = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Trilhos.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addPendentes = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Pendentes.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addAntena = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Antena.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addCaboDeInternet = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CaboDeInternet.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addInterfone = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interfone.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addCameraDeSeguranca = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CameraDeSeguranca.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addSensores = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Sensores.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addPlafon = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Plafon.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addPontoDeDados = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeDados.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addPontoDeTv = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeTv.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addExaustor = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Exaustor.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addPontoDeForca = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeForca.push(object)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemArandela = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Arandela.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemInterruptores = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interruptores.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemFitasDeLed = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.FitasDeLed.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemTomadas = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Tomadas.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemLuminarias = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Luminarias.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemSpot = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Spot.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemLampadas = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Lampadas.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemTrilhos = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Trilhos.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemPendentes = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Pendentes.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemAntena = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Antena.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemCaboDeInternet = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CaboDeInternet.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemInterfone = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interfone.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemCameraDeSeguranca = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CameraDeSeguranca.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemSensores = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Sensores.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemPlafon = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Plafon.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemPontoDeDados = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeDados.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemPontoDeTv = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeTv.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemExaustor = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Exaustor.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemPontoDeForca = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeForca.splice(index, 1)
    eletricActivitiesCopy[0].Installations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editArandelaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Arandela[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editArandelaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Arandela[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInterruptoresValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interruptores[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInterruptoresAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interruptores[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editFitasDeLedValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.FitasDeLed[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editFitasDeLedAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.FitasDeLed[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editTomadasValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Tomadas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editTomadasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Tomadas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editLuminariasValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Luminarias[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editLuminariasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Luminarias[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editSpotValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Spot[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editSpotAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Spot[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editLampadasValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Lampadas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editLampadasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Lampadas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editTrilhosValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Trilhos[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editTrilhosAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Trilhos[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPendentesValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Pendentes[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPendentesAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Pendentes[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editAntenaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Antena[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editAntenaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Antena[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editCaboDeInternetValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CaboDeInternet[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editCaboDeInternetAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CaboDeInternet[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInterfoneValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interfone[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editInterfoneAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Interfone[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editCameraDeSegurancaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CameraDeSeguranca[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editCameraDeSegurancaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.CameraDeSeguranca[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editSensoresValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Sensores[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editSensoresAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Sensores[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPlafonValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Plafon[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPlafonAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Plafon[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPontoDeDadosValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeDados[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPontoDeDadosAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeDados[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPontoDeTvValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeTv[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPontoDeTvAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeTv[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editExaustorValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Exaustor[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editExaustorAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Exaustor[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPontoDeForcaValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeForca[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editPontoDeForcaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.PontoDeForca[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Arandela.length ? (
        <div>
          <p>Arandela</p>
          {itensFormList.Arandela.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editArandelaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editArandelaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editArandelaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editArandelaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editArandelaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m linear']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemArandela(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


      {itensFormList.FitasDeLed.length ? (
        <div>
          <p>Fitas de led</p>
          {itensFormList.FitasDeLed.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editFitasDeLedAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editFitasDeLedValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editFitasDeLedValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editFitasDeLedAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editFitasDeLedValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemFitasDeLed(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Interruptores.length ? (
        <div>
          <p>Interruptores</p>
          {itensFormList.Interruptores.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInterruptoresAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editInterruptoresValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editInterruptoresValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInterruptoresAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editInterruptoresValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemInterruptores(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Tomadas.length ? (
        <div>
          <p>Tomadas</p>
          {itensFormList.Tomadas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTomadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editTomadasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editTomadasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTomadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editTomadasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemTomadas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Luminarias.length ? (
        <div>
          <p>Luminárias</p>
          {itensFormList.Luminarias.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLuminariasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editLuminariasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editLuminariasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLuminariasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editLuminariasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemLuminarias(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Spot.length ? (
        <div>
          <p>Spot</p>
          {itensFormList.Spot.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSpotAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editSpotValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editSpotValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSpotAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editSpotValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemSpot(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Lampadas.length ? (
        <div>
          <p>Lâmpadas</p>
          {itensFormList.Lampadas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLampadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editLampadasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editLampadasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editLampadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editLampadasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemLampadas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Trilhos.length ? (
        <div>
          <p>Trilhos</p>
          {itensFormList.Trilhos.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTrilhosAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editTrilhosValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editTrilhosValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTrilhosAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editTrilhosValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemTrilhos(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Pendentes.length ? (
        <div>
          <p>Pendentes</p>
          {itensFormList.Pendentes.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPendentesAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPendentesValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPendentesValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPendentesAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPendentesValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPendentes(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Antena.length ? (
        <div>
          <p>Antena</p>
          {itensFormList.Antena.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAntenaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editAntenaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editAntenaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAntenaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editAntenaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemAntena(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.CaboDeInternet.length ? (
        <div>
          <p>Cabo de internet</p>
          {itensFormList.CaboDeInternet.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editCaboDeInternetAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editCaboDeInternetValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editCaboDeInternetValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editCaboDeInternetAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editCaboDeInternetValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemCaboDeInternet(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Interfone.length ? (
        <div>
          <p>Interfone</p>
          {itensFormList.Interfone.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInterfoneAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editInterfoneValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editInterfoneValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editInterfoneAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editInterfoneValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemInterfone(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.CameraDeSeguranca.length ? (
        <div>
          <p>Câmera de segurança</p>
          {itensFormList.CameraDeSeguranca.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editCameraDeSegurancaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editCameraDeSegurancaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editCameraDeSegurancaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editCameraDeSegurancaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editCameraDeSegurancaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemCameraDeSeguranca(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Sensores.length ? (
        <div>
          <p>Sensores</p>
          {itensFormList.Sensores.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSensoresAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editSensoresValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editSensoresValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSensoresAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editSensoresValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemSensores(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Plafon.length ? (
        <div>
          <p>Plafon</p>
          {itensFormList.Plafon.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPlafonAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPlafonValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPlafonValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPlafonAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPlafonValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPlafon(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PontoDeDados.length ? (
        <div>
          <p>Ponto de dados</p>
          {itensFormList.PontoDeDados.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeDadosAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeDadosValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeDadosValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeDadosAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeDadosValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeDados(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PontoDeTv.length ? (
        <div>
          <p>Ponto de TV</p>
          {itensFormList.PontoDeTv.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeTvAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeTvValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeTvValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeTvAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeTvValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeTv(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Exaustor.length ? (
        <div>
          <p>Exaustor</p>
          {itensFormList.Exaustor.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editExaustorAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editExaustorValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editExaustorValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editExaustorAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editExaustorValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemExaustor(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PontoDeForca.length ? (
        <div>
          <p>Ponto de força</p>
          {itensFormList.PontoDeForca.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeForcaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editPontoDeForcaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editPontoDeForcaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editPontoDeForcaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editPontoDeForcaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemPontoDeForca(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InstallationsStepForm;
