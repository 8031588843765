import React, { useState, useEffect } from "react";

import { UseMasonaryActivityContext } from "../../../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { attributeType } from "../../../../Models/attributeType";
import { ClosureFormType } from "../../../../Models/Masonary/type";
import { Closure } from "../../../../Models/Masonary/ClosureModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const ClosureStepForm: React.FC<stepFormProps> = (props) => {
  const { masonaryActivities, indexMasonaryActivitySelected, editMasonaryActivity } = UseMasonaryActivityContext();

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<ClosureFormType>(Closure)

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      let masonaryActivityCopy = masonaryActivities[indexMasonaryActivitySelected]
      masonaryActivityCopy.activityType = 'Fechamento'
      editMasonaryActivity?.(indexMasonaryActivitySelected, masonaryActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected])

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      setItensFormList(masonaryActivities[indexMasonaryActivitySelected].Closure)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected, masonaryActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemDrywallDuplo(0)
      removeItemDrywallSimples(0)
      removeItemEmboço(0)
      removeItemEncunhamento(0)
      removeItemGessoLisoParaParede(0)
      removeItemParedeDeAlvenaria(0)
      removeItemReboco(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Encunhamento") {
      addEncunhamento({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Reboco") {
      addReboco({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Emboço") {
      addEmboço({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Gesso liso para parede") {
      addGessoLisoParaParede(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm²',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Drywall simples") {
      addDrywallSimples(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm²',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Drywall duplo") {
      addDrywallDuplo(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm²',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    } else if (assignment === "Parede de alvenaria") {
      addParedeDeAlvenaria(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'm²',
              value: ''
            },
            {
              itemName: 'Material',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    }
  }

  const addEncunhamento = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var closureActivitiesCopy = masonaryActivities
    itensFormListCopy.Encunhamento.push(object)
    closureActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, closureActivitiesCopy[0])
    console.log('oi')
  }

  const addReboco = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var closureActivitiesCopy = masonaryActivities
    itensFormListCopy.Reboco.push(object)
    closureActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, closureActivitiesCopy[0])
  }

  const addGessoLisoParaParede = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var closureActivitiesCopy = masonaryActivities
    itensFormListCopy.GessoLisoParaParede.push(object)
    closureActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, closureActivitiesCopy[0])
  }

  const addEmboço = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Emboço.push(object)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const addDrywallSimples = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallSimples.push(object)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const addDrywallDuplo = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallDuplo.push(object)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const addParedeDeAlvenaria = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.ParedeDeAlvenaria.push(object)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemEncunhamento = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Encunhamento.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemReboco = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Reboco.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemGessoLisoParaParede = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.GessoLisoParaParede.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemEmboço = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Emboço.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemDrywallSimples = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallSimples.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemDrywallDuplo = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallDuplo.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemParedeDeAlvenaria = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.ParedeDeAlvenaria.splice(index, 1)
    masonaryActivitiesCopy[0].Closure = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEncunhamentoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Encunhamento[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEncunhamentoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Encunhamento[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEncunhamentoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Encunhamento[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editRebocoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Reboco[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editRebocoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Reboco[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editRebocoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Reboco[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editGessoLisoParaParedeValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.GessoLisoParaParede[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editGessoLisoParaParedeAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.GessoLisoParaParede[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editGessoLisoParaParedeUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.GessoLisoParaParede[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEmboçoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Emboço[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEmboçoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Emboço[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editEmboçoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Emboço[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editDrywallSimplesValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallSimples[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editDrywallSimplesAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallSimples[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editDrywallSimplesUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallSimples[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editDrywallDuploValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallDuplo[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editDrywallDuploAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallDuplo[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editDrywallDuploUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.DrywallDuplo[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editParedeDeAlvenariaValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.ParedeDeAlvenaria[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editParedeDeAlvenariaAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.ParedeDeAlvenaria[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editParedeDeAlvenariaUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.ParedeDeAlvenaria[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Encunhamento.length ? (
        <div>
          <p>Encunhamento</p>
          {itensFormList.Encunhamento.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEncunhamentoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editEncunhamentoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editEncunhamentoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEncunhamentoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editEncunhamentoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editEncunhamentoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />

                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemEncunhamento(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}


      {itensFormList.GessoLisoParaParede.length ? (
        <div>
          <p>Gesso liso para parede</p>
          {itensFormList.GessoLisoParaParede.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editGessoLisoParaParedeAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editGessoLisoParaParedeValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editGessoLisoParaParedeValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editGessoLisoParaParedeAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editGessoLisoParaParedeValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editGessoLisoParaParedeUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemGessoLisoParaParede(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Reboco.length ? (
        <div>
          <p>Reboco</p>
          {itensFormList.Reboco.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRebocoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editRebocoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editRebocoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRebocoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editRebocoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editRebocoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemReboco(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Emboço.length ? (
        <div>
          <p>Emboço</p>
          {itensFormList.Emboço.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmboçoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editEmboçoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editEmboçoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editEmboçoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editEmboçoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editEmboçoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemEmboço(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.DrywallSimples.length ? (
        <div>
          <p>Drywall simples</p>
          {itensFormList.DrywallSimples.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editDrywallSimplesAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editDrywallSimplesValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editDrywallSimplesValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editDrywallSimplesAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editDrywallSimplesValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editDrywallSimplesUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemDrywallSimples(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.DrywallDuplo.length ? (
        <div>
          <p>Drywall duplo</p>
          {itensFormList.DrywallDuplo.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editDrywallDuploAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editDrywallDuploValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editDrywallDuploValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editDrywallDuploAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editDrywallDuploValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editDrywallDuploUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemDrywallDuplo(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.ParedeDeAlvenaria.length ? (
        <div>
          <p>Parede de alvenaria</p>
          {itensFormList.ParedeDeAlvenaria.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editParedeDeAlvenariaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editParedeDeAlvenariaValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editParedeDeAlvenariaValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editParedeDeAlvenariaAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editParedeDeAlvenariaValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editParedeDeAlvenariaUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemParedeDeAlvenaria(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ClosureStepForm;
