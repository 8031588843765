import styled from "styled-components";

export const Container = styled.div`
    max-height:70vh;
    width: 70vw;
   
    
    .buttonContainer{
        margin-top: 1rem;
        border-top: 2px solid #e1e1e1;
        display:flex;
        justify-content:end;
        padding: 2rem 1rem 2rem;
    }
`