import React, { useEffect, useState } from "react"
import { UseMasonaryActivityContext } from "../../../Context/ActivitiesContext/Mansonary/MansonaryContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";
import { textTransform } from "../script"

const MasonryController: React.FC = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()
    const { masonaryActivities, editMasonaryActivity } = UseMasonaryActivityContext()

    useEffect(() => {
        setActivitySelected?.('Fundacao')
    }, [])

    useEffect(() => {
        if (masonaryActivities.length > 0) {
            var activityEdited = masonaryActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editMasonaryActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    /* useEffect(() => {
        if (masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType !== "") {
            setOptionSelected(masonaryActivities[props.indexSelected].activityType)
        }
        masonaryActivities.length > 0 && masonaryActivities[props.indexSelected].activityType === "" ? (setOptionSelected('Fundacao')) : (<></>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.indexSelected]) */

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="masonryStep" id="masonryStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Fundacao">Fundação</option>
                <option value="Estrutura">Estrutura</option>
                <option value="Fechamento">Fechamento</option>
                <option value="Piso">Piso</option>
                <option value="Laje">Laje</option>
                <option value="Requadramento de vao">Requadramento de vão</option>
                <option value="Soculo em alvenaria">Sóculo em alvenaria</option>
                <option value="Escadas">Escadas</option>
                <option value="Nicho">Nicho</option>
                <option value="Materiais para alvenaria">Materiais</option>
            </select>
        </ActionSelect>
    </div>
}

export default MasonryController