import React, { useEffect, useState } from "react";

import { UseMarbleworkActivityContext } from "../../../../Context/ActivitiesContext/Marblework/MarbleworkContext"
import { shelf } from "../../../../types/typesEntitiesProject"
import { InputActivity, Button } from "../../../stylesDomElements/htmlStyledElements";
import { Container, Content, Select, FlexContainer, ContainerButtom, ContainerGrid, P } from "../../style";

import addIcon from "../../../../assets/addIcon.svg";

const Shelf: React.FC = () => {
    const { marbleworkActivities, editMarbleWorkActivity, indexMarbleworkActivitySelected } = UseMarbleworkActivityContext()

    const [prateleira, setPrateleira] = useState<shelf[]>([])

    useEffect(() => {
        if (marbleworkActivities.length > 0) {
            setPrateleira(marbleworkActivities[indexMarbleworkActivitySelected].prateleira)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexMarbleworkActivitySelected, marbleworkActivities])

    const addShelf = () => {
        let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
        activityEdited.prateleira.push({
            altura_shelf: '',
            comprimento_shelf: '',
            largura_shelf: '',
            material_shelf: '',
        })
        editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
        setPrateleira((prev) => [...prev, {
            altura_shelf: '',
            comprimento_shelf: '',
            largura_shelf: '',
            material_shelf: '',
        }])
    }

    const editAltura = (index: number, value: string) => {
        const shelfCopy = Array.from(prateleira);
        shelfCopy.splice(index, 1, {
            altura_shelf: value,
            comprimento_shelf: shelfCopy[index].comprimento_shelf,
            largura_shelf: shelfCopy[index].largura_shelf,
            material_shelf: shelfCopy[index].material_shelf
        })
        setPrateleira(shelfCopy)
        let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
        activityEdited.prateleira = shelfCopy
        editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }

    const editComprimento = (index: number, value: string) => {
        const shelfCopy = Array.from(prateleira);
        shelfCopy.splice(index, 1, {
            altura_shelf: shelfCopy[index].altura_shelf,
            comprimento_shelf: value,
            largura_shelf: shelfCopy[index].largura_shelf,
            material_shelf: shelfCopy[index].material_shelf
        })
        setPrateleira(shelfCopy)
        let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
        activityEdited.prateleira = shelfCopy
        editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }

    const editLargura = (index: number, value: string) => {
        const shelfCopy = Array.from(prateleira);
        shelfCopy.splice(index, 1, {
            altura_shelf: shelfCopy[index].altura_shelf,
            comprimento_shelf: shelfCopy[index].comprimento_shelf,
            largura_shelf: value,
            material_shelf: shelfCopy[index].material_shelf
        })
        setPrateleira(shelfCopy)
        let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
        activityEdited.prateleira = shelfCopy
        editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }

    const editMaterial = (index: number, value: string) => {
        const shelfCopy = Array.from(prateleira);
        shelfCopy.splice(index, 1, {
            altura_shelf: shelfCopy[index].altura_shelf,
            comprimento_shelf: shelfCopy[index].comprimento_shelf,
            largura_shelf: shelfCopy[index].largura_shelf,
            material_shelf: value
        })
        setPrateleira(shelfCopy)
        let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
        activityEdited.prateleira = shelfCopy
        editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }

    const removeItem = (index: number) => {
        let activityEdited = marbleworkActivities[indexMarbleworkActivitySelected];
        const prateleiraCopy = [...prateleira]
        prateleiraCopy.splice(index, 1)
        activityEdited.prateleira = prateleiraCopy
        setPrateleira(prateleiraCopy);
        editMarbleWorkActivity?.(indexMarbleworkActivitySelected, activityEdited);
    }

    return (
        <div>
            {prateleira.map((element, index) =>
                <div>
                    <P>Prateleira {index + 1}</P>
                    <Content key={index}>
                        <ContainerGrid>
                            <div>
                                <div className="no_marginBottom">
                                    <p>Largura</p>
                                    <InputActivity>
                                        <input type="text" value={element.largura_shelf} onChange={(e) => editLargura(index, e.target.value)} />
                                        cm
                                    </InputActivity>
                                </div>

                                <div className="resizeContentInputs">
                                    <p>Altura</p>
                                    <InputActivity>
                                        <input type="text" value={element.altura_shelf} onChange={(e) => editAltura(index, e.target.value)} />
                                        cm
                                    </InputActivity>
                                </div>
                            </div>

                            <div>
                                <div className="no_marginBottom">
                                    <p>Comprimento</p>
                                    <InputActivity>
                                        <input type="text" value={element.comprimento_shelf} onChange={(e) => editComprimento(index, e.target.value)} />
                                        cm
                                    </InputActivity>
                                </div>

                                <div className="resizeContentInputs">
                                    <p>Material</p>
                                    <InputActivity>
                                        <input type="text" value={element.material_shelf} onChange={(e) => editMaterial(index, e.target.value)} />
                                    </InputActivity>
                                </div>
                            </div>
                            <div></div>
                            <div className="cancelX" onClick={() => removeItem(index)}>X</div>
                        </ContainerGrid>
                    </Content>
                </div>
            )}
            <ContainerButtom>
                <Button onClick={() => addShelf()}>
                    <img src={addIcon} alt="Iconde de adição" />
                    Adicionar prateleira
                </Button>
            </ContainerButtom>
        </div>
    );
};

export default Shelf;
