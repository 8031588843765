import React, { useContext, useEffect, useState } from "react";

import { textTransform } from "../../../components/StepsForm/script";
import { Cleaning } from "../../../Models/Cleaning/CleaningModel";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { CleaningFormType } from "../../../Models/Cleaning/type";
import { MaterialsFormType } from "../../../Models/attributeType";
import { UserProviderProps } from "../../../types/typesEntitiesProject";
export interface activitiesCleaningProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Cleaning: CleaningFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  cleaningActivities: activitiesCleaningProps[];
  cleaningActivityName: string;
  indexCleaningActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesCleaningProps[]>>;
  setNewCleaningActivity?: () => void;
  setCleaningActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexCleaningActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editCleaningActivity?: (
    index: number,
    activity: activitiesCleaningProps
  ) => void;
  deleteCleaningActivity?: (index: number) => void;
}

const activityContextDefault = {
  cleaningActivities: [],
  cleaningActivityName: "",
  indexCleaningActivitySelected: 0,
  setActivity: () => {},
  setNewCleaningActivity: () => {},
  setCleaningActivityName: () => {},
  setIndexCleaningActivitySelected: () => {},
  editCleaningActivity: () => {},
  deleteCleaningActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseCleaningActivityContext = () => useContext(ActivitiesContext);

export const CleaningActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [cleaningActivities, setActivity] = useState<activitiesCleaningProps[]>(
    activityContextDefault.cleaningActivities
  );
  const [indexCleaningActivitySelected, setIndexCleaningActivitySelected] =
    useState<number>(0);
  const [cleaningActivityName, setCleaningActivityName] = useState<string>(
    textTransform("Limpeza")
  );

  const editCleaningActivity = (
    index: number,
    activity: activitiesCleaningProps
  ) => {
    const activitiesEdited = cleaningActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteCleaningActivity = (index: number) => {
    const activity = [...cleaningActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewCleaningActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: cleaningActivityName,
        activityStatus: "inProgress",
        activityType: "Limpeza",
        stepType: "Limpeza",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Cleaning: Cleaning,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        cleaningActivities,
        cleaningActivityName,
        indexCleaningActivitySelected,
        setActivity,
        setCleaningActivityName,
        setNewCleaningActivity,
        editCleaningActivity,
        deleteCleaningActivity,
        setIndexCleaningActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
