import styled from "styled-components";

interface weekSummaryActivitiesProps {
  xAxis: number;
  yAxis: number;
}

interface menuEditItemActivitiesProps {
  xAxis: number;
  yAxis: number;
}

interface editListItemProps {
  isEdit: boolean;
}

export const Container = styled.div`
  .buttonArea {
    float: right;
    display: flex;
    margin: 1px 40px 20px 0px;
    button {
      background-color: var(--primary-color);
      :hover {
        background-color: var(--secondary-color);
      }
    }
  }

  @media (max-width: 480px) {
    -webkit-transform: scale(0.17);
    -webkit-transform-origin: 0 0;
  }
`;

export const ContentElement = styled.div`
  display: grid;
  align-items: center;
  background-color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin: 20px 40px 20px 40px;

  h4 {
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    display: flex;
  }

  .responsiblesArea {
    display: flex;
    align-items: center;
  }

  .reponsiblesBox {
    margin-left: 0.5rem;
    width: 92%;
    display: flex;
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;
    max-height: 40px;
    align-items: center;

    input {
      height: 32px;
      border: none;
      width: 100%;

      @media (max-width: 480px) {
        height: 150px;
      }
    }
  }

  .orientations {
    margin-left: 118px;
    color: #777777;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    width: 148rem;
    font-size: 5rem;
  }

  @media (max-width: 820px) {
    width: 73rem;
    font-size: 1rem;
  }
`;

export const Info = styled.table`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;

  @media (max-width: 480px) {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: none;

    width: 153rem;
    font-size: 5rem;
  }

  @media (max-width: 820px) {
    width: 78rem;
  }

  .left-side {
    background-color: var(--primary-color);
    margin: 0px 15px 0px 40px;
    border-radius: 0.3rem;
    padding: 10px;
    height: 40rem;
    @media (max-width: 820px) {
      height: 78rem;
    }

    @media (max-width: 480px) {
      width: 147rem;
      font-size: 5rem;
      margin-bottom: 1rem;
      height: 150rem;
      margin: 50px 0px 100px 15px;
    }

    .list {
      height: 32rem;
      scrollbar-width: thin;
      overflow-y: auto;

      @media (max-width: 480px) {
        width: 146rem;
        font-size: 5rem;
        height: 130rem;
      }

      li {
        margin-left: 1rem;
      }

      ::-webkit-scrollbar {
        height: 0.2rem;
        width: 0.5rem;
        background-color: #e1e1e1;
        border-radius: 0.5rem;
      }
      ::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 0.5rem;
        border: 1px solid #e1e1e1;
      }
    }
    .addActivity {
      margin-left: 0.5rem;
      width: 75%;
    }

    .itens {
      margin-left: 1rem;
      margin-bottom: 1rem;

      input {
        display: grid;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0px 0px 0px 10px;
      }
    }

    .itensForList {
      display: flex;
      justify-content: center;
      margin-right: 1rem;

      button {
        height: 5px;
        width: 5px;
      }
    }

    .stepName {
      font-size: 17px;
      @media (max-width: 480px) {
        font-size: 5rem;
      }
    }

    .addActivityArea {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .right-side {
    background-color: var(--primary-color);
    margin: 0px 40px 0px 15px;
    border-radius: 0.3rem;
    padding: 10px;
    height: 40rem;
    @media (max-width: 820px) {
      height: 78rem;
    }

    @media (max-width: 480px) {
      font-size: 5rem;
      margin-bottom: 1rem;
      height: 150rem;
    }

    .addActivity {
      margin-left: 0.5rem;
      width: 75%;
    }
    .list {
      height: 32rem;
      scrollbar-width: thin;
      overflow-y: auto;

      @media (max-width: 480px) {
        width: 146rem;
        font-size: 5rem;
        height: 130rem;
      }

      li {
        margin-left: 1rem;
      }

      ::-webkit-scrollbar {
        height: 0.2rem;
        width: 0.5rem;
        background-color: #e1e1e1;
        border-radius: 0.5rem;
      }
      ::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 0.5rem;
        border: 1px solid #e1e1e1;
      }
    }

    .itens {
      margin-left: 1rem;
      margin-bottom: 1rem;
      input {
        display: grid;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0px 0px 0px 10px;
      }
    }

    .itensForList {
      display: flex;
      justify-content: center;
      margin-right: 1rem;
      button {
        height: 5px;
        width: 5px;
      }
    }

    .stepName {
      font-size: 17px;

      @media (max-width: 480px) {
        font-size: 5rem;
      }
    }

    .addActivityArea {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  h4 {
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const ActivityDateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding-bottom: 0.75rem;
  margin-left: 50px;

  input {
    border: none;
    color: #141414;
    font-size: 15px;
    font-weight: 600;
  }

  .calendarArea {
    display: flex;
    align-items: baseline;
  }

  .calendar-icon {
    cursor: pointer;
  }

  .x-icon {
    margin-left: 8px;
    cursor: pointer;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 14px;
    text-align: center;
  }

  .react-calendar {
    width: 300px;
    border: 1px solid #141414;
    border-radius: 2px;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    border: 0.1px solid #141414;
    background-color: #fff;
    cursor: pointer;
    :hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
    }
  }

  input[type="date"] {
    text-indent: 100%;
    transform: scaleX(-1);
    color: transparent;
    width: 19px;
  }
`;
export const ContainerListItens = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 25rem;
  max-height: 10rem;
  padding: 0.3rem;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid #e1e1e1;
  overflow-x: auto;
  overflow-y: hidden;

  div {
    display: flex;
  }
  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
`;

export const ResponsibleItem = styled.span`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  margin: 1px 0;
  margin-right: 0.25rem;
  border: 1px solid #e1e1e1;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  height: 32px;
  img {
    width: 0.5rem;
    margin-left: 0.35rem;
    cursor: pointer;
  }
`;

export const Responsible = styled.p`
  max-width: 21ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 14px;
`;

export const MenuOverlay = styled.div<weekSummaryActivitiesProps>`
  background-color: var(--dark-background);
  border-radius: 0.5rem;
  display: grid;
  position: fixed;
  z-index: 3;
  left: ${(props) => props.xAxis}px;
  top: ${(props) => props.yAxis}px;
`;

export const OptionButton = styled.button`
  color: var(--primary-color);
  font-weight: 400;
  margin: 0.5rem;
  border: none;
  background-color: transparent;
  font-size: 0.85rem;
  cursor: pointer;
  width: 100px;
  a {
    color: var(--primary-color);
  }
`;

export const MenuEditItem = styled.div<menuEditItemActivitiesProps>`
  left: ${(props) => props.xAxis}px;
  top: ${(props) => props.yAxis}px;
`;

export const InputForItemEdit = styled.input<editListItemProps>`
  ::placeholder {
    color: #121317;
  }
  border: ${({ isEdit }) => (isEdit ? "1px solid black" : "none")};
  border-radius: ${({ isEdit }) => (isEdit ? "0.3rem" : "none")};
  font-size: ${({ isEdit }) => (isEdit ? "" : "16px")};
  color: ${({ isEdit }) => (isEdit ? "" : "#121317")};
  background-color: ${({ isEdit }) => (isEdit ? "" : "transparent")};
  list-style-type: ${({ isEdit }) => (isEdit ? "none" : "circle")};
  margin-left: ${({ isEdit }) => (isEdit ? "" : "1rem")};
  height: ${({ isEdit }) => (isEdit ? "1.7rem" : "")};
  font-weight: ${({ isEdit }) => (isEdit ? "" : "bold")};

  @media (max-width: 480px) {
    font-size: 4rem;
    height: 9rem;
  }
`;
