import React, { useState } from "react"

import { piaProps, medidas_bancadasProps, recortesFurosItensSuspensosProps, painelProps, frontaoProps } from "../../../../types/typesEntitiesProject"

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    pedra: string,
    cor_pedra: string,
    acabamento_pedra: string,
    altura_item_revestido: string,
    largura_item_revestido: string,
    profundidade_item_revestido: string,

    formato_bancada: string,
    disposicao_bancada: string,
    possuiPias: boolean
    pias: piaProps[],
    medidas_bancadas: medidas_bancadasProps,
    possuiPainel: boolean,
    painel: painelProps,
    possui_recortes_furos_suspensos: boolean,
    recortes_furos_suspensos: recortesFurosItensSuspensosProps,
    tipo_saia: string,
    altura_saia: string,
    profundidade_saia: string,
    frontao: string,
    medidas_fundo: frontaoProps,
    medidas_lateral_1: frontaoProps,
    medidas_lateral_2: frontaoProps,
    pingadeira: boolean,
    rebaixo: boolean,
}

const SinksAndCountertopsDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);
    return (
        <div>
            <div className="item">
                {activity?.formato_bancada ? (
                    <div className="line">
                        <p className="bold">Formato da bancada</p>
                        <p>
                            {activity.formato_bancada}
                        </p>
                    </div>
                ) : null}
            </div>

            <div className="item">
                {activity?.disposicao_bancada ? (
                    <div className="line">
                        <p className="bold">Disposição da bancada</p>
                        <p>
                            {activity.disposicao_bancada}
                        </p>
                    </div>
                ) : null}

                {activity?.pias.length ? (
                    <>
                        {activity.pias[0].tipoPia && activity.pias[0].altura || activity.pias[0].comprimento || activity.pias[0].diametroCorte || activity.pias[0].espessuraAba ? (
                            <p className="bold"><br />Pias</p>
                        ) : ('')}
                        {activity.pias.map((pia, indexPia) => (
                            pia.tipoPia && pia.altura || pia.comprimento || pia.diametroCorte || pia.espessuraAba ? (
                                <>
                                    <div key={indexPia} >
                                        <p>Pia {pia.tipoPia}</p>
                                        {pia.tipoPia === "Esculpida" ? (
                                            <p>- Largura: {pia.largura}cm, Comprimento: {pia.comprimento}cm,  Altura:{pia.altura}cm</p>
                                        ) : pia.tipoPia === "De embutir quadrada" ? (
                                            <p>- Largura: {pia.largura}cm, Comprimento: {pia.comprimento}cm</p>
                                        ) : pia.tipoPia === "De embutir redonda" ? (
                                            <p>- Diâmetro do corte: {pia.diametroCorte}cm</p>
                                        ) : pia.tipoPia === "Aparente" || "Semi embutida" ? (
                                            <p>- Largura: {pia.largura} cm, Comprimento: {pia.comprimento}cm,  Altura:{pia.altura}cm, Espessura da aba: {pia.espessuraAba}cm</p>
                                        ) : (<></>)}
                                    </div>
                                    <br />
                                </>
                            ) : (<></>)
                        ))}
                    </>
                ) : null}
            </div>

            <div className="item">
                {activity?.pedra ? (
                    <div className="line">
                        <p className="bold">Pedra</p>
                        <p>
                            {activity.pedra}
                        </p>
                    </div>
                ) : null}
            </div>
            <div className="item">
                {activity?.acabamento_pedra ? (
                    <div className="line">
                        <p className="bold">Acabamento pedra</p>
                        <p>
                            {activity.acabamento_pedra}
                        </p>
                    </div>
                ) : null}
            </div>
            <div className="item">
                {activity.medidas_bancadas ? (
                    <>
                        <p className="bold">Medidas da bancada</p>
                        <div className="line">
                            {activity.disposicao_bancada === "Simples" ? (
                                <p>Braço 1: {activity.medidas_bancadas.braco1.altura}, X {activity.medidas_bancadas.braco1.largura}</p>
                            ) : activity.disposicao_bancada === "Em L" ? (
                                <div>
                                    <p>Braço 1: {activity.medidas_bancadas.braco1.altura}, X {activity.medidas_bancadas.braco1.largura}</p>
                                    <p>Braço 2: {activity.medidas_bancadas.braco2.altura}, X {activity.medidas_bancadas.braco2.largura}</p>
                                </div>
                            ) : activity.disposicao_bancada === "Em U" ? (
                                <div>
                                    <p>Braço 1: {activity.medidas_bancadas.braco1.altura}, X {activity.medidas_bancadas.braco1.largura}</p>
                                    <p>Braço 2: {activity.medidas_bancadas.braco2.altura}, X {activity.medidas_bancadas.braco2.largura}</p>
                                    <p>Braço 3: {activity.medidas_bancadas.braco3.altura}, X {activity.medidas_bancadas.braco3.largura}</p>
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>

            <div className="item">
                {activity.possuiPainel ? (
                    <>
                        <p className="bold">Painel</p>
                        <div className="line">
                            <p>Tipo:</p>
                            <p>{activity.painel.tipoPainel}</p>
                        </div>
                        <div className="line">
                            <p>Altura:</p>
                            <p>{activity.painel.altura}cm</p>
                        </div>
                        <div className="line">
                            <p>Largura:</p>
                            <p>{activity.painel.largura}cm</p>
                        </div>
                        <div className="line">
                            <p>Saia no painel</p>
                            <p>{activity.painel.saiaPainel}</p>
                        </div>
                    </>
                ) : (<></>)}
            </div>

            <div className="item">
                {activity.possui_recortes_furos_suspensos ? (
                    <>
                        <p className="bold">Recortes, furos, itens suspensos</p>
                        <div>
                            <p className="line">Tipo:{activity.recortes_furos_suspensos.tipo}</p>
                            {activity.recortes_furos_suspensos.tipo === "Recortes" ? (
                                <div>
                                    <p className="line">Nome: {activity.recortes_furos_suspensos.nome}</p>
                                    <p className="line">Largura: {activity.recortes_furos_suspensos.largura} cm</p>
                                    <p className="line">Comprimento: {activity.recortes_furos_suspensos.comprimento} cm</p>
                                </div>
                            ) : activity.recortes_furos_suspensos.tipo === "Furos" ? (
                                <div>
                                    <p className="line">Nome: {activity.recortes_furos_suspensos.nome}</p>
                                    <p className="line">Diâmetro: {activity.recortes_furos_suspensos.diametro} cm</p>
                                </div>
                            ) : activity.recortes_furos_suspensos.tipo === "Itens Suspensos" ? (
                                <div>
                                    <p className="line">Nome: {activity.recortes_furos_suspensos.nome}</p>
                                    <p className="line">Altura: {activity.recortes_furos_suspensos.altura} cm</p>
                                    <p className="line">Largura: {activity.recortes_furos_suspensos.largura} cm</p>
                                    <p className="line">Comprimento: {activity.recortes_furos_suspensos.comprimento} cm</p>
                                </div>
                            ) : (<></>)}
                        </div>
                    </>
                ) : (<></>)}
            </div>

            <div className="item">
                {activity.tipo_saia ? (
                    <>
                        <p className="bold">Acabamento de borda</p>
                        <div>
                            <p className="line">Tipo de saia:{activity.tipo_saia}</p>
                            {activity.tipo_saia === "Saia simples" ? (
                                <div>
                                    <p className="line">Altura: {activity.altura_saia} cm</p>
                                </div>
                            ) : activity.recortes_furos_suspensos.tipo === "Saia em L" ? (
                                <div>
                                    <p className="line">Altura: {activity.altura_saia} cm</p>
                                    <p className="line">Profundidade: {activity.profundidade_saia} cm</p>
                                </div>
                            ) : (<></>)}
                        </div>
                    </>
                ) : (<></>)}
            </div>

            <div className="item">
                {activity.frontao ? (
                    <>
                        <p className="bold">Frontão</p>
                        <div>
                            <p className="line">Tipo: {activity.frontao}</p>
                            {activity.frontao === "Somente no fundo" ? (
                                <div>
                                    <p className="line">Altura: {activity.medidas_fundo.altura} cm</p>
                                    <p className="line">Largura: {activity.medidas_fundo.largura} cm</p>
                                </div>
                            ) : activity.frontao === "No fundo e 1 lateral" ? (
                                <>
                                    <div>
                                        <p>Medidas fundo</p>
                                        <p className="line">Altura: {activity.medidas_fundo.altura} cm</p>
                                        <p className="line">Largura: {activity.medidas_fundo.largura} cm</p>
                                    </div>
                                    <div>
                                        <p>Medidas Lateral 1</p>
                                        <p className="line">Altura: {activity.medidas_lateral_1.altura} cm</p>
                                        <p className="line">Largura: {activity.medidas_lateral_1.largura} cm</p>
                                    </div>
                                </>
                            ) : activity.frontao === "No fundo e 2 laterais" ? (
                                <>
                                    <p>Medidas fundo:</p>
                                    <div>
                                        <p >Altura: {activity.medidas_fundo.altura} cm</p>
                                        <p >Largura: {activity.medidas_fundo.largura} cm</p>
                                        <br />
                                    </div>

                                    <p>Medidas Lateral 1:</p>
                                    <div>
                                        <p>Altura: {activity.medidas_lateral_1.altura} cm</p>
                                        <p>Largura: {activity.medidas_lateral_1.largura} cm</p>
                                        <br />
                                    </div>

                                    <p>Medidas Lateral 2:</p>
                                    <div>
                                        <p>Altura: {activity.medidas_lateral_2.altura} cm</p>
                                        <p>Largura: {activity.medidas_lateral_2.largura} cm</p>
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>
                    </>
                ) : (<></>)}
            </div>
            <div className="item">
                {activity.pingadeira ? (
                    <p className="bold line">Pingadeira</p>
                ) : null}
                {activity.rebaixo ? (
                    <p className="bold line">Rebaixo</p>
                ) : null}
            </div>
        </div>
    );
};

export default SinksAndCountertopsDescription;
