import axios from "axios";

import { useState, useEffect } from "react"

export function UseFetch<T = unknown>(url: string) {
    const [data, setData] = useState<T | []>([])
    const [isFetching, setIsFetching] = useState(true)

    useEffect(() => {
        axios.get(url).then(response => {
            setData(response.data)
        }).finally(() => {
            setIsFetching(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { data, isFetching }
}