import styled from "styled-components"

export const ButtonValidationCode = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    background-color: transparent;
    border-radius: 0.3rem;
    margin-top: 6rem;
    height: 2.5rem;
    position: relative;
    float: right;
    width: 10rem;

    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }
    img{
        margin-right: 0.5rem;
        color: var(--secondary-color);
    }

    cursor: pointer;
`
export const ButtonSaveNewPassword = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    background-color: transparent;
    border-radius: 0.3rem;
    margin-top: 2rem;
    height: 2.5rem;
    position: relative;
    float: right;
    width: 10rem;

    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }
    img{
        margin-right: 0.5rem;
        color: var(--secondary-color);
    }

    cursor: pointer;
`

export const Form = styled.form`
display: grid;
width: 100%;

`
export const FormContainer = styled.div`
    background-color: var(--primary-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    overflow-y: auto;

`

export const ContentValidationForm = styled.div`
    padding: 1rem;
`

export const FormValidateArea = styled.div`
    background-color: var(--primary-color);
    padding: 1rem;

    div{
        display: grid;
        justify-content: center;
        align-items: center;
    }

    label {
        margin-right: 0.5rem;
    }

    .NewPasswordArea{
       display: inline-flex;
       justify-content: end;
    }


    .ConfirmNewPasswordArea {
       display: inline-flex;
       justify-content: end;
    }
`

export const FormValidateCodeArea = styled.div`
    background-color: var(--primary-color);
    padding: 1rem;

    div{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    label {
        margin-right: 0.5rem;
    }

`

export const InputValidation = styled.input`
    margin-top: 0.5rem;
    height: 2.5rem;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: #f1f1f2;
    padding: 0.25rem;
    width: 45rem;
    align: right;
`
export const NewPasswordInput = styled.input`
    margin-top: 0.5rem;
    height: 2.5rem;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: #f1f1f2;
    padding: 0.25rem;
    width: 45rem;   
    align: right;
 
`

export const MessageConfirm = styled.div`
    color: red;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--primary-color);
`

export const Header = styled.div`
    display:flex;
    justify-content:center;
    padding:1rem;
    gap:3rem;
    background-color:#fff;
`

export const HeaderFormContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 0px #F1F2F3;
    div{
        background-color: #F1F2F3;
        padding: 0.4rem;
        border-radius: 0.3rem;
    }
    img{
        cursor: pointer;
        margin: 0.1rem;
    }
`

export const OptionTypeRegister = styled.div`
    display: flex;
    justify-content:center;
    width: 100%;
    padding: 1rem;

    div{
        width:100%;
        display: flex;
        justify-content:center;
        align-items:center;
        label{
            color: #777777;
            width: 100%;
            cursor: pointer;
            div{
                width: 100%;
                padding: 1rem;
                border-bottom: 0.2rem solid #F1F2F3;
            }
            background-color: var(--primary-color);
        }
        
    }

    input[type="radio"]{
        display: none;
    }

    input[type="radio"]:checked + label > div {
        color: #000000;
        border-bottom: 0.2rem solid var(--secondary-color);
        cursor: pointer;
    }
`

export const PasswordConfirmation = styled.div`
background-color: var(--primary-color);
div{
    align-items:center;
    display: flex;
    justify-content: center;
    color: red;
}
`