import React, { useState, useEffect } from "react";

import { UseGlassworkActivityContext } from "../../../../Context/ActivitiesContext/Glasswork/GlassworkContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Container, Content, Select, FlexContainer } from "../../style";

const BathroomBox: React.FC = () => {
  const { glassworkActivities, editGlassworkActivity, indexGlassworkActivitySelected } = UseGlassworkActivityContext()

  const [tipo_box, setTipoBox] = useState<string>('Frontal')
  const [altura, setAltura] = useState<string>('')
  const [comprimento, setComprimento] = useState<string>('')
  const [largura, setLargura] = useState<string>('')
  const [formato_ferragem, setFormatoFerragem] = useState<string>('Roldana aparente')
  const [cor_ferragem, setCorFerragem] = useState<string>('Alumínio natural')
  const [cor_vidro, setCorVidro] = useState<string>('Incolor')
  const [tipo_porta, setTipoPorta] = useState<string>('De correr')
  const [material_estrutura, setMaterial_estrutura] = useState<string>('')
  const [descricaoEstrutura, setDescricao_estrutura] = useState<string>('')
  const [descricao_puxador, setDescricao_puxador] = useState<string>('')


  useEffect(() => {
    if (glassworkActivities.length > 0) {
      let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
      activityEdited.tipo_box = tipo_box
      activityEdited.formato_ferragem = formato_ferragem
      activityEdited.tipo_porta = tipo_porta
      activityEdited.cor_vidro = cor_vidro
      activityEdited.cor_ferragem = cor_ferragem;
      activityEdited.material_estrutura = material_estrutura;
      activityEdited.descricaoEstrutura = descricaoEstrutura;
      activityEdited.descricao_puxador = descricao_puxador;
      editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (glassworkActivities.length > 0) {
      setTipoBox(glassworkActivities[indexGlassworkActivitySelected].tipo_box);
      setAltura(glassworkActivities[indexGlassworkActivitySelected].altura);
      setComprimento(glassworkActivities[indexGlassworkActivitySelected].comprimento);
      setLargura(glassworkActivities[indexGlassworkActivitySelected].largura)
      setFormatoFerragem(glassworkActivities[indexGlassworkActivitySelected].formato_ferragem)
      setCorFerragem(glassworkActivities[indexGlassworkActivitySelected].cor_ferragem)
      setCorVidro(glassworkActivities[indexGlassworkActivitySelected].cor_vidro)
      setTipoPorta(glassworkActivities[indexGlassworkActivitySelected].tipo_porta)
      setMaterial_estrutura(glassworkActivities[indexGlassworkActivitySelected].material_estrutura)
      setDescricao_estrutura(glassworkActivities[indexGlassworkActivitySelected].descricaoEstrutura)
      setDescricao_puxador(glassworkActivities[indexGlassworkActivitySelected].descricao_puxador)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexGlassworkActivitySelected, glassworkActivities]);

  const editTipoBox = (value: string) => {
    setTipoBox(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.tipo_box = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editAltura = (value: string) => {
    setAltura(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.altura = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editLargura = (value: string) => {
    setLargura(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.largura = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editComprimento = (value: string) => {
    setComprimento(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.comprimento = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editFormatoFerragem = (value: string) => {
    setFormatoFerragem(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.formato_ferragem = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorFerragem = (value: string) => {
    setCorFerragem(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.cor_ferragem = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorVidro = (value: string) => {
    setCorVidro(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.cor_vidro = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editTipoPorta = (value: string) => {
    setTipoPorta(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.tipo_porta = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editMaterialEstrutura = (value: string) => {
    setMaterial_estrutura(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.material_estrutura = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editDrescricaoEstrutura = (value: string) => {
    setDescricao_estrutura(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.descricaoEstrutura = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editDescricaoPuxador = (value: string) => {
    setDescricao_puxador(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.descricao_puxador = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  return (
    <Container>
      <Content>
        <FlexContainer>
          <div>
            <p>Tipo de box</p>
            <Select value={tipo_box} onChange={(e) => editTipoBox(e.target.value)}>
              <option value="Frontal">Frontal</option>
              <option value="De canto">De canto</option>
              <option value="Piso teto">Piso teto</option>
            </Select>
          </div>
          <div>
            <p>Cor do vidro</p>
            <Select value={cor_vidro} onChange={(e) => editCorVidro(e.target.value)}>
              <option value="Incolor">Incolor</option>
              <option value="Bronze">Bronze</option>
              <option value="Fumê">Fumê</option>
              <option value="Verde">Verde</option>
              <option value="Pontilhado">Pontilhado</option>
            </Select>
          </div>
        </FlexContainer>
        <div>
          Dimensões do box:
          <div className="contentSizeInputs">
            <div>
              <p>Altura</p>
              <InputActivity>
                <input type="text" value={altura} onChange={(e) => editAltura(e.target.value)} />
                m
              </InputActivity>
            </div>
            <div>
              <p>Comprimento</p>
              <InputActivity>
                <input type="text" value={comprimento} onChange={(e) => editComprimento(e.target.value)} />
                m
              </InputActivity>
            </div>
            <div>
              <p>Largura</p>
              <InputActivity>
                <input type="text" value={largura} onChange={(e) => editLargura(e.target.value)} />
                m
              </InputActivity>
            </div>
          </div>
        </div>
        <FlexContainer>
          <div>
            <p>Tipo de porta</p>
            <Select value={tipo_porta} onChange={(e) => editTipoPorta(e.target.value)}>
              <option value="De correr">De correr</option>
              <option value="De abrir">De abrir</option>
              <option value="Fixa">Fixa</option>
            </Select>
          </div>
          <div></div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Cor da ferragem</p>
            <Select value={cor_ferragem} onChange={(e) => editCorFerragem(e.target.value)}>
              <option value="Alumínio natural">Alumínio natural</option>
              <option value="Branco">Branco</option>
              <option value="Preto">Preto</option>
              <option value="Cromado">Cromado</option>
              <option value="Bronze">Bronze</option>
              <option value="Rose gold">Rose gold</option>

            </Select>
          </div>
          <div>
            <p>Formato da ferragem</p>
            <Select value={formato_ferragem} onChange={(e) => editFormatoFerragem(e.target.value)}>
              <option value="Roldana aparente">Roldana aparente</option>
              <option value="Quadrada">Quadrada</option>
              <option value="Arredondada">Arredondada</option>
              <option value="Redonda">Redonda</option>
            </Select>
          </div>
        </FlexContainer>

        <FlexContainer>
          <div>
            <p>Material da estrutura</p>
            <InputActivity>
              <input type="text" value={material_estrutura} onChange={(e) => editMaterialEstrutura(e.target.value)} />
            </InputActivity>
          </div>

          <div>
            <p>Descrição</p>
            <InputActivity>
              <input type="text" value={descricaoEstrutura} onChange={(e) => editDrescricaoEstrutura(e.target.value)} />
            </InputActivity>
          </div>

          <div>
            <p>Descrição do puxador</p>
            <InputActivity>
              <input type="text" value={descricao_puxador} onChange={(e) => editDescricaoPuxador(e.target.value)} />
            </InputActivity>
          </div>
        </FlexContainer>
      </Content>
      <Content>
        <div></div>
      </Content>
    </Container>
  );
};

export default BathroomBox;
