import React, { useContext, useEffect, useState } from "react";

import { textTransform } from "../../../components/StepsForm/script";
import { Demolition } from "../../../Models/Demolition/DemolitionModel";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { DemolitionFormType } from "../../../Models/Demolition/type";
import { MaterialsFormType } from "../../../Models/attributeType";
import { UserProviderProps } from "../../../types/typesEntitiesProject";
export interface activitiesDemolitionProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  Demolition: DemolitionFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  demolitionActivities: activitiesDemolitionProps[];
  demolitionActivityName: string;
  indexDemolitionActivitySelected: number;
  setActivity?: React.Dispatch<
    React.SetStateAction<activitiesDemolitionProps[]>
  >;
  setNewDemolitionActivity?: () => void;
  setDemolitionActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexDemolitionActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editDemolitionActivity?: (
    index: number,
    activity: activitiesDemolitionProps
  ) => void;
  deleteDemolitionActivity?: (index: number) => void;
}

const activityContextDefault = {
  demolitionActivities: [],
  demolitionActivityName: "",
  indexDemolitionActivitySelected: 0,
  setActivity: () => {},
  setNewDemolitionActivity: () => {},
  setDemolitionActivityName: () => {},
  setIndexDemolitionActivitySelected: () => {},
  editDemolitionActivity: () => {},
  deleteDemolitionActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseDemolitionActivityContext = () => useContext(ActivitiesContext);

export const DemolitionActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [demolitionActivities, setActivity] = useState<
    activitiesDemolitionProps[]
  >(activityContextDefault.demolitionActivities);
  const [indexDemolitionActivitySelected, setIndexDemolitionActivitySelected] =
    useState<number>(0);
  const [demolitionActivityName, setDemolitionActivityName] = useState<string>(
    textTransform("Demolicao")
  );

  const editDemolitionActivity = (
    index: number,
    activity: activitiesDemolitionProps
  ) => {
    let activitiesEdited = demolitionActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteDemolitionActivity = (index: number) => {
    const activity = [...demolitionActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewDemolitionActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: demolitionActivityName,
        activityStatus: "inProgress",
        activityType: "Demolicao",
        stepType: "Demolicao",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        Demolition: Demolition,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        demolitionActivities,
        demolitionActivityName,
        indexDemolitionActivitySelected,
        setActivity,
        setDemolitionActivityName,
        setNewDemolitionActivity,
        editDemolitionActivity,
        deleteDemolitionActivity,
        setIndexDemolitionActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
