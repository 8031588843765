import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ItemContentListSapatas,
  ListContainerItensSapatas,
  ListContainerItensBrocasBaldrame,
  ItemContentListBrocasBaldrame,
} from "./style";

import { SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { FundationFormType } from "../../../../Models/Masonary/type";

import FooterBudget from "../../Components/FooterBudget"
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  Fundation: FundationFormType
}

const FundacaoBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [sapatas, setSapatas] = useState<string[]>(["0,00"])
  const [brocas, setBrocas] = useState<string[]>(["0,00"])
  const [baldrame, setBaldrame] = useState<string[]>(["0,00"])


  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Fundation.Sapatas.forEach(() => {
      setSapatas((prev) => [...prev, "0,00"])
    })
    // eslint-disable-next-line
    activity.Fundation.Brocas?.forEach(() => {
      setBrocas((prev) => [...prev, "0,00"])
    })

    activity.Fundation.Baldrame?.forEach(() => {
      setBaldrame((prev) => [...prev, "0,00"])
    })

  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const sapatasTotal = sum(sapatas)
    const brocasTotal = sum(brocas)
    const baldrameTotal = sum(baldrame)

    setTotalBudgetPayment(sum([sapatasTotal, brocasTotal, baldrameTotal]))
  }, [sapatas, brocas, baldrame])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const sapatasTotal = sum(sapatas)
  const brocasTotal = sum(brocas)
  const baldrameTotal = sum(baldrame)

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([sapatasTotal, brocasTotal, baldrameTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const sapatasMargin: number[] = []
    const brocasMargin: number[] = []
    const baldrameMargin: number[] = []

    sapatas.forEach((item) => {
      if (item != "0,00") {
        sapatasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    brocas.forEach((item) => {
      if (item != "0,00") {
        brocasMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    baldrame.forEach((item) => {
      if (item != "0,00") {
        baldrameMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Sapatas: sapatasMargin,
          Brocas: brocasMargin,
          Baldrame: baldrameMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensSapatas>

        {activity.Fundation.Sapatas?.map((item, index) => (
          <div key={index}>
            {activity.Fundation.Sapatas.length > 1 ? (<P>Sapatas {index + 1}</P>) : (<P>Sapatas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p key={index}>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListSapatas >
              {item.attributes?.map((tarefa, index) => (
                <p key={index}>
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, sapatas, setSapatas, e.target.value)} />
              </ContentInputPrice>
              {sapatas && sapatas[index] !== '0' ?
                (<p className="item">{sapatas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListSapatas>
          </div>
        ))}

      </ListContainerItensSapatas>

      <ListContainerItensBrocasBaldrame>
        {activity.Fundation.Brocas?.map((item, index) => (
          <div key={index}>
            {activity.Fundation.Brocas.length > 1 ? (<P>Brocas {index + 1}</P>) : (<P>Brocas</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p key={index}>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListBrocasBaldrame >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, brocas, setBrocas, e.target.value)} />
              </ContentInputPrice>
              {brocas && brocas[index] !== '0' ?
                (<p className="item">{brocas[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListBrocasBaldrame>
          </div>
        ))}
      </ListContainerItensBrocasBaldrame>

      <ListContainerItensBrocasBaldrame>
        {activity.Fundation.Baldrame?.map((item, index) => (
          <div key={index}>
            {activity.Fundation.Baldrame.length > 1 ? (<P>Baldrame {index + 1}</P>) : (<P>Baldrame</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p key={index}>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListBrocasBaldrame >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, baldrame, setBaldrame, e.target.value)} />
              </ContentInputPrice>
              {baldrame && baldrame[index] !== '0' ?
                (<p className="item">{baldrame[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListBrocasBaldrame>
          </div>
        ))}
      </ListContainerItensBrocasBaldrame>


      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default FundacaoBudget;