import React, { useState, useEffect } from "react";

import { UseDemolitionActivityContext } from "../../../../Context/ActivitiesContext/Demolition/DemolitionContext";
import { attributeType } from "../../../../Models/attributeType";
import { DemolitionFormType } from "../../../../Models/Demolition/type";
import { Demolition } from "../../../../Models/Demolition/DemolitionModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json";
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const DemolitionStepForm: React.FC<stepFormProps> = (props) => {
  const {
    demolitionActivities,
    indexDemolitionActivitySelected,
    editDemolitionActivity,
  } = UseDemolitionActivityContext();

  const [assignment, setAssignment] = useState<string>("");

  const [itensFormList, setItensFormList] =
    useState<DemolitionFormType>(Demolition);

  useEffect(() => {
    if (demolitionActivities.length > 0) {
      let demolitionActivityCopy =
        demolitionActivities[indexDemolitionActivitySelected];
      demolitionActivityCopy.activityType = "Demolicao";
      editDemolitionActivity?.(
        indexDemolitionActivitySelected,
        demolitionActivityCopy
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexDemolitionActivitySelected]);

  useEffect(() => {
    if (demolitionActivities.length > 0) {
      setItensFormList(
        demolitionActivities[indexDemolitionActivitySelected].Demolition
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexDemolitionActivitySelected, demolitionActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemContraPiso(0);
      removeItemDrywall(0);
      removeItemForro(0);
      removeItemItensASeremDesmontados(0);
      removeItemItensEspeciaisDemolicao(0);
      removeItemMarcenaria(0);
      removeItemOutrosDemolicao(0);
      removeItemParedeDeAlvenaria(0);
      removeItemPedrasEMarmores(0);
      removeItemRevestimentoDeParede(0);
      removeItemRevestimentoDePiso(0);
    }
  }, []);

  const addAssignment = () => {
    if (assignment === "Parede de alvenaria") {
      addParedeDeAlvenaria({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Drywall") {
      addDrywall({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Revestimento de piso") {
      addRevestimentoDePiso({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Revestimento de parede") {
      addRevestimentoDeParede({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Contrapiso") {
      addContraPiso({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Pedras e marmores") {
      addPedrasEMarmores({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Forro") {
      addForro({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Marcenaria") {
      addMarcenaria({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "m²",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Itens a serem desmontados") {
      addItensASeremDesmontados({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "un",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Demolição de itens especiais") {
      addItensEspeciaisDemolicao({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "un",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    } else if (assignment === "Outros de demolição") {
      addOutrosDemolicao({
        attributes: [
          {
            itemName: "Descrição",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Material",
            unit_of_measurement: "",
            value: "",
          },
          {
            itemName: "Medida",
            unit_of_measurement: "un",
            value: "",
          },
          {
            itemName: "Quantidade",
            unit_of_measurement: "",
            value: "",
          },
        ],
      });
    }
  };

  const addForro = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Forro.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
    console.log("oi");
  };

  const addDrywall = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Drywall.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addRevestimentoDeParede = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDeParede.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addRevestimentoDePiso = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDePiso.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addContraPiso = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ContraPiso.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addPedrasEMarmores = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.PedrasEMarmores.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addParedeDeAlvenaria = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ParedeDeAlvenaria.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addMarcenaria = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Marcenaria.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addItensASeremDesmontados = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensASeremDesmontados.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addItensEspeciaisDemolicao = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensEspeciaisDemolicao.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const addOutrosDemolicao = (object: attributeType) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.OutrosDemolicao.push(object);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemForro = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Forro.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemDrywall = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Drywall.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemRevestimentoDeParede = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDeParede.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemRevestimentoDePiso = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDePiso.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemContraPiso = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ContraPiso.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemPedrasEMarmores = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.PedrasEMarmores.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemParedeDeAlvenaria = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ParedeDeAlvenaria.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemMarcenaria = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Marcenaria.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemItensASeremDesmontados = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensASeremDesmontados.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemItensEspeciaisDemolicao = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensEspeciaisDemolicao.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const removeItemOutrosDemolicao = (index: number) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.OutrosDemolicao.splice(index, 1);
    demolitionActivitiesCopy[0].Demolition = itensFormListCopy;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editForroValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Forro[indexItemForm].attributes[indexItem].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editForroAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Forro[indexItemForm].attributes[indexItem].itemName =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editForroUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Forro[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editDrywallValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Drywall[indexItemForm].attributes[indexItem].value =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editDrywallAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Drywall[indexItemForm].attributes[indexItem].itemName =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editDrywallUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Drywall[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editRevestimentoDeParedeValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDeParede[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editRevestimentoDeParedeAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDeParede[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editRevestimentoDeParedeUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDeParede[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editRevestimentoDePisoValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDePiso[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editRevestimentoDePisoAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDePiso[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editRevestimentoDePisoUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.RevestimentoDePiso[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editContraPisoValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ContraPiso[indexItemForm].attributes[indexItem].value =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editContraPisoAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ContraPiso[indexItemForm].attributes[indexItem].itemName =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editContraPisoUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ContraPiso[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editPedrasEMarmoresValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.PedrasEMarmores[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editPedrasEMarmoresAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.PedrasEMarmores[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editPedrasEMarmoresUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.PedrasEMarmores[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editParedeDeAlvenariaValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ParedeDeAlvenaria[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editParedeDeAlvenariaAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ParedeDeAlvenaria[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editParedeDeAlvenariaUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ParedeDeAlvenaria[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editMarcenariaValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Marcenaria[indexItemForm].attributes[indexItem].value =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editMarcenariaAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Marcenaria[indexItemForm].attributes[indexItem].itemName =
      value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editMarcenariaUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.Marcenaria[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editItensASeremDesmontadosValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensASeremDesmontados[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editItensASeremDesmontadosAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensASeremDesmontados[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editItensASeremDesmontadosUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensASeremDesmontados[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editItensEspeciaisDemolicaoValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensEspeciaisDemolicao[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editItensEspeciaisDemolicaoAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensEspeciaisDemolicao[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editItensEspeciaisDemolicaoUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.ItensEspeciaisDemolicao[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editOutrosDemolicaoValues = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.OutrosDemolicao[indexItemForm].attributes[
      indexItem
    ].value = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editOutrosDemolicaoAttributeName = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    var itensFormListCopy = itensFormList;
    var demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.OutrosDemolicao[indexItemForm].attributes[
      indexItem
    ].itemName = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  const editOutrosUnitOfMeasurement = (
    value: string,
    indexItem: number,
    indexItemForm: number
  ) => {
    const itensFormListCopy = itensFormList;
    const demolitionActivitiesCopy = demolitionActivities;
    itensFormListCopy.OutrosDemolicao[indexItemForm].attributes[
      indexItem
    ].unit_of_measurement = value;
    setItensFormList(itensFormListCopy);
    editDemolitionActivity?.(0, demolitionActivitiesCopy[0]);
  };

  return (
    <div>
      <div>
        <Assignment
          assignmentList={assignmentList}
          assignment={assignment}
          setAssignment={setAssignment}
          addAssignment={addAssignment}
        />
      </div>
      <br />
      {itensFormList.Forro.length ? (
        <div>
          <p>Forro</p>
          {itensFormList.Forro.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editForroAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editForroValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editForroValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editForroAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editForroValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editForroUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemForro(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.RevestimentoDeParede.length ? (
        <div>
          <p>Revestimento de parede</p>
          {itensFormList.RevestimentoDeParede.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editRevestimentoDeParedeAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editRevestimentoDeParedeValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editRevestimentoDeParedeValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editRevestimentoDeParedeAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editRevestimentoDeParedeValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editRevestimentoDeParedeUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemRevestimentoDeParede(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Drywall.length ? (
        <div>
          <p>Drywall</p>
          {itensFormList.Drywall.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editDrywallAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editDrywallValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editDrywallValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editDrywallAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editDrywallValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editDrywallUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemDrywall(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.RevestimentoDePiso.length ? (
        <div>
          <p>Revestimento de piso</p>
          {itensFormList.RevestimentoDePiso.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editRevestimentoDePisoAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editRevestimentoDePisoValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editRevestimentoDePisoValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editRevestimentoDePisoAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editRevestimentoDePisoValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editRevestimentoDePisoUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemRevestimentoDePiso(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.ContraPiso.length ? (
        <div>
          <p>Contrapiso</p>
          {itensFormList.ContraPiso.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editContraPisoAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editContraPisoValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editContraPisoValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editContraPisoAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editContraPisoValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editContraPisoUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemContraPiso(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.PedrasEMarmores.length ? (
        <div>
          <p>Pedras e marmores</p>
          {itensFormList.PedrasEMarmores.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editPedrasEMarmoresAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editPedrasEMarmoresValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editPedrasEMarmoresValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editPedrasEMarmoresAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editPedrasEMarmoresValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editPedrasEMarmoresUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemPedrasEMarmores(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.ParedeDeAlvenaria.length ? (
        <div>
          <p>Parede de alvenaria</p>
          {itensFormList.ParedeDeAlvenaria.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editParedeDeAlvenariaAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editParedeDeAlvenariaValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editParedeDeAlvenariaValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editParedeDeAlvenariaAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editParedeDeAlvenariaValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editParedeDeAlvenariaUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemParedeDeAlvenaria(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Marcenaria.length ? (
        <div>
          <p>Marcenaria</p>
          {itensFormList.Marcenaria.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editMarcenariaAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editMarcenariaValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editMarcenariaValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editMarcenariaAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editMarcenariaValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editMarcenariaUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemMarcenaria(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.ItensASeremDesmontados.length ? (
        <div>
          <p>Itens a serem desmontados</p>
          {itensFormList.ItensASeremDesmontados.map(
            (itemForm, indexItemForm) => (
              <ContainerGrid key={indexItemForm}>
                {itemForm.attributes.map((attributeItem, indexAttribute) =>
                  attributeItem.itemName === "Tipo" ? (
                    <div>
                      <AttributeNameItem
                        placeholder={attributeItem.itemName}
                        onChange={(e) =>
                          editItensASeremDesmontadosAttributeName(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      <Select
                        value={attributeItem.value}
                        onChange={(e) =>
                          editItensASeremDesmontadosValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      >
                        <option value="Gesso">Gesso</option>
                        <option value="Pvc">Pvc</option>
                        <option value="Madeira">Madeira</option>
                        <option value="Isopor">Isopor</option>
                        <option value="Cimento">Cimento</option>
                        <option value="Aparente">Aparente</option>
                        <option value="Fibra">Fibra</option>
                        <option value="Drywall reto">Drywall reto</option>
                        <option value="Drywall tabicado">
                          Drywall tabicado
                        </option>
                        <option value="Jateamento de celulose">
                          Jateamento de celulose
                        </option>
                        <option value="Outros">Outros</option>
                      </Select>
                      <div>
                        {attributeItem.value === "Outros" ? (
                          <div>
                            <InputActivity>
                              <input
                                type="text"
                                id="tipooutros"
                                placeholder="Digite o tipo"
                                value={attributeItem.value}
                                onChange={(e) =>
                                  editItensASeremDesmontadosValues(
                                    e.target.value,
                                    indexAttribute,
                                    indexItemForm
                                  )
                                }
                              />
                            </InputActivity>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="resizeContentInputs">
                      <AttributeNameItem
                        placeholder={attributeItem.itemName}
                        onChange={(e) =>
                          editItensASeremDesmontadosAttributeName(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      <InputActivity>
                        <input
                          type="text"
                          value={attributeItem.value}
                          onChange={(e) =>
                            editItensASeremDesmontadosValues(
                              e.target.value,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                        {attributeItem.unit_of_measurement ? (
                          <SelectTypeMeasureItem
                            defaultValue={attributeItem.unit_of_measurement}
                            options={[
                              "m²",
                              "m³",
                              "m linear",
                              "peça",
                              "cm",
                              "un",
                              "m",
                            ]}
                            function={(e) =>
                              editItensASeremDesmontadosUnitOfMeasurement(
                                e,
                                indexAttribute,
                                indexItemForm
                              )
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </InputActivity>
                    </div>
                  )
                )}
                <div
                  className="cancelX"
                  onClick={() =>
                    removeItemItensASeremDesmontados(indexItemForm)
                  }
                >
                  X
                </div>
              </ContainerGrid>
            )
          )}
        </div>
      ) : null}

      {itensFormList.ItensEspeciaisDemolicao.length ? (
        <div>
          <p>Demolição de itens especiais</p>
          {itensFormList.ItensEspeciaisDemolicao.map(
            (itemForm, indexItemForm) => (
              <ContainerGrid key={indexItemForm}>
                {itemForm.attributes.map((attributeItem, indexAttribute) =>
                  attributeItem.itemName === "Tipo" ? (
                    <div>
                      <AttributeNameItem
                        placeholder={attributeItem.itemName}
                        onChange={(e) =>
                          editItensEspeciaisDemolicaoAttributeName(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      <Select
                        value={attributeItem.value}
                        onChange={(e) =>
                          editItensEspeciaisDemolicaoValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      >
                        <option value="Gesso">Gesso</option>
                        <option value="Pvc">Pvc</option>
                        <option value="Madeira">Madeira</option>
                        <option value="Isopor">Isopor</option>
                        <option value="Cimento">Cimento</option>
                        <option value="Aparente">Aparente</option>
                        <option value="Fibra">Fibra</option>
                        <option value="Drywall reto">Drywall reto</option>
                        <option value="Drywall tabicado">
                          Drywall tabicado
                        </option>
                        <option value="Jateamento de celulose">
                          Jateamento de celulose
                        </option>
                        <option value="Outros">Outros</option>
                      </Select>
                      <div>
                        {attributeItem.value === "Outros" ? (
                          <div>
                            <InputActivity>
                              <input
                                type="text"
                                id="tipooutros"
                                placeholder="Digite o tipo"
                                value={attributeItem.value}
                                onChange={(e) =>
                                  editItensEspeciaisDemolicaoValues(
                                    e.target.value,
                                    indexAttribute,
                                    indexItemForm
                                  )
                                }
                              />
                            </InputActivity>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="resizeContentInputs">
                      <AttributeNameItem
                        placeholder={attributeItem.itemName}
                        onChange={(e) =>
                          editItensEspeciaisDemolicaoAttributeName(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      <InputActivity>
                        <input
                          type="text"
                          value={attributeItem.value}
                          onChange={(e) =>
                            editItensEspeciaisDemolicaoValues(
                              e.target.value,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                        {attributeItem.unit_of_measurement ? (
                          <SelectTypeMeasureItem
                            defaultValue={attributeItem.unit_of_measurement}
                            options={[
                              "m²",
                              "m³",
                              "m linear",
                              "peça",
                              "cm",
                              "un",
                              "m",
                            ]}
                            function={(e) =>
                              editItensEspeciaisDemolicaoUnitOfMeasurement(
                                e,
                                indexAttribute,
                                indexItemForm
                              )
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </InputActivity>
                    </div>
                  )
                )}
                <div
                  className="cancelX"
                  onClick={() =>
                    removeItemItensEspeciaisDemolicao(indexItemForm)
                  }
                >
                  X
                </div>
              </ContainerGrid>
            )
          )}
        </div>
      ) : null}

      {itensFormList.OutrosDemolicao.length ? (
        <div>
          <p>Outros de demolição</p>
          {itensFormList.OutrosDemolicao.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) =>
                attributeItem.itemName === "Tipo" ? (
                  <div>
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editOutrosDemolicaoAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <Select
                      value={attributeItem.value}
                      onChange={(e) =>
                        editOutrosDemolicaoValues(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    >
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">
                        Jateamento de celulose
                      </option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>
                      {attributeItem.value === "Outros" ? (
                        <div>
                          <InputActivity>
                            <input
                              type="text"
                              id="tipooutros"
                              placeholder="Digite o tipo"
                              value={attributeItem.value}
                              onChange={(e) =>
                                editOutrosDemolicaoValues(
                                  e.target.value,
                                  indexAttribute,
                                  indexItemForm
                                )
                              }
                            />
                          </InputActivity>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem
                      placeholder={attributeItem.itemName}
                      onChange={(e) =>
                        editOutrosDemolicaoAttributeName(
                          e.target.value,
                          indexAttribute,
                          indexItemForm
                        )
                      }
                    />
                    <InputActivity>
                      <input
                        type="text"
                        value={attributeItem.value}
                        onChange={(e) =>
                          editOutrosDemolicaoValues(
                            e.target.value,
                            indexAttribute,
                            indexItemForm
                          )
                        }
                      />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem
                          defaultValue={attributeItem.unit_of_measurement}
                          options={[
                            "m²",
                            "m³",
                            "m linear",
                            "peça",
                            "cm",
                            "un",
                            "m",
                          ]}
                          function={(e) =>
                            editOutrosUnitOfMeasurement(
                              e,
                              indexAttribute,
                              indexItemForm
                            )
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </InputActivity>
                  </div>
                )
              )}
              <div
                className="cancelX"
                onClick={() => removeItemOutrosDemolicao(indexItemForm)}
              >
                X
              </div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DemolitionStepForm;
