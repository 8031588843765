import React, { SetStateAction } from "react";

export const addItem = (setItem: React.Dispatch<SetStateAction<string[]>>) => {
    setItem((prev) => [...prev, ''])
}

export const editItem = (item: string, listItens: string[], index: number, setItem: React.Dispatch<SetStateAction<string[]>>) => {
    var listItensCopy = listItens
    listItensCopy.splice(index, 1, item)
    setItem([...listItensCopy])
}

export const removeItem = (index: number, listItens: string[], setItem: React.Dispatch<SetStateAction<string[]>>) => {
    var listItensCopy = listItens
    listItensCopy.splice(index, 1)
    setItem([...listItensCopy])
}