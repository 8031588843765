import React, { useContext, useEffect, useState } from "react";
import { UserProviderProps } from "../types/typesEntitiesProject";

interface LoginContextTypes {
  userId: string;
  typeUser?: string;
  imageProfile?: string;
  token?: string;
  projectSelected: string;

  setUserId?: React.Dispatch<React.SetStateAction<string>>;
  setTypeUser?: React.Dispatch<React.SetStateAction<string>>;
  setImageProfile?: React.Dispatch<React.SetStateAction<string>>;
  setToken?: React.Dispatch<React.SetStateAction<string>>;
  setprojectSelected?: React.Dispatch<React.SetStateAction<string>>;
}

const defaultStateLogin = {
  userId: "",
  projectSelected: "",
};

const LoginContext = React.createContext<LoginContextTypes>(defaultStateLogin);

export const UseUserContext = () => useContext(LoginContext);

export const LoginContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [userId, setUserId] = useState("");
  const [imageProfile, setImageProfile] = useState<string>(
    "https://marcas-logos.net/wp-content/plugins/accelerated-mobile-pages/images/SD-default-image.png"
  );
  const [projectSelected, setprojectSelected] = useState<string>("");
  const [typeUser, setTypeUser] = useState<string>("");
  const [token, setToken] = useState<string>(
    localStorage.getItem("token") || ""
  );

  return (
    <LoginContext.Provider
      value={{
        userId,
        imageProfile,
        projectSelected,
        typeUser,
        token,
        setUserId,
        setprojectSelected,
        setImageProfile,
        setTypeUser,
        setToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
