export const EletricProject = {
    ProjetoEletrico: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Arquivo',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Quantidade',
                    unit_of_measurement: '',
                    value: ''
                }
            ]
        }
    ]
}