import React from "react";

import { PlantProps } from "../../../types/typesEntitiesProject";
import { Th } from "./style";
import axios from "axios";

const ArchiveItem: React.FC<PlantProps> = (props) => {

  const deleteArchiveProjectInfo = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/deleteArchiveProjectInfo/${props._id}/${props.index}`)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload()
        }
      });
  };

  return (
    <Th>
      <a href={props.archiveUrl} download>{props.archiveName}</a>
      <p onClick={() => deleteArchiveProjectInfo()}>
        X
      </p>
    </Th>
  );
};

export default ArchiveItem;
