import React, { useEffect, useState } from "react";
import axios from "axios";

import {
    PriceItem,
    ContentInputPrice,
    ListContainerItens,
    ItemContentList,
    TotalContainer,
} from "../../style";

import { activitiesProps, SupplierProps, shelf } from "../../../../types/typesEntitiesProject";

import { multiply, sum } from "../../script/calculate"

import FooterBudget from "../../Components/FooterBudget"

interface budgetProps {
    activity: activitiesProps,
    supplier: SupplierProps
    margin:number
}
interface activityProps {
    _id?: string
    projectId?: string;
    stepId?: string;
    budgetId?: [];
    activityName?: string;
    activityStatus?: string,
    activityType?: string,
    stepType?: string

    prateleira?: shelf[]
}

const PrateleiraBudget: React.FC<budgetProps> = (props) => {
    const [activity] = useState<activityProps>(props.activity);

    const [prateleira, setPrateleira] = useState<string[]>(['0,00'])

    const [workingDays, setWorkingDays] = useState<number>(0);
    const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
    const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
    const [conditions, setConditions] = useState<string>('')

    useEffect(() => {
        activity.prateleira?.forEach(() => {
            setPrateleira((prev) => [...prev, "0,00"])
        })
    }, [activity])

    /**Para calcuar o total dos valores de cada item ao identificar alterações em seus valores */
    useEffect(() => {
        var sum_totalPrateleira = sum(prateleira)
        setTotalBudgetPayment(sum([sum_totalPrateleira]))
    }, [prateleira])

    /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
    useEffect(() => {
        var sum_totalPrateleira = sum(prateleira)
        if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
            setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
        } else {
            setTotalBudgetPayment(sum([sum_totalPrateleira]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minimumBudgetValue]);

    const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, measurement: string) => {
        var stateCopy = Array.from(stateVar)
        stateCopy.splice(index, 1, multiply(measurement, value))
        setStateRef(stateCopy)
    }

    const submitBudget = () => {
        var prateleiraMargin: number[] = []

        prateleira.forEach((item) => {
            if (item != "0,00") {
                prateleiraMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
            }
        })
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
                projectId: activity?.projectId,
                activityId: activity?._id,
                stepId: activity.stepId,
                providerId: props.supplier._id,
                supplierName: props.supplier.supplierName,
                typeSupplier: props.supplier.typeSupplier,
                activityName: activity.activityName,
                conditions,
                selectedStartDate: '',
                workingDays,
                selectedBudget: false,
                budget: {
                    prateleira: prateleiraMargin
                },
                minimumBudgetValue,
                totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
            })
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            });
    };
    return (
        <div>
            <ListContainerItens>
                {activity.prateleira?.map((element, index) =>
                    <div key={index}>
                        <div className="upContent">
                            <p>Prateleira</p>
                            <p>Medidas</p>
                            <p>Preço unit.</p>
                            <p className="item">Preço total</p>
                        </div>

                        <ItemContentList>
                            <p>Material: {element.material_shelf}</p>
                            <p> {element.comprimento_shelf}cm X {element.largura_shelf}cm X{element.altura_shelf}cm</p>
                            <ContentInputPrice>
                                R$:
                                <PriceItem onChange={(e) => updateState(index, prateleira, setPrateleira, e.target.value, '1')} />
                            </ContentInputPrice>
                            {prateleira && prateleira[index] !== '0' ?
                                (<p className="item">{prateleira[index]}</p>) :
                                (<p className="item">0,00</p>)
                            }
                        </ItemContentList>
                    </div>
                )}
            </ListContainerItens>

            <TotalContainer>
                Valor total: <p>R${totalBudgetPayment}</p>
            </TotalContainer>

            <FooterBudget
                setWorkingDays={setWorkingDays}
                setMinimunBudgetValue={setMinimunBudgetValue}
                setConditions={setConditions}
                submitBudget={() => submitBudget()}
            />
        </div>
    )
};

export default PrateleiraBudget;