import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRouter";

import ValidateSocialLogin from "../pages/ValidadeSocialLogin";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ConstructorManagement from "../pages/ConstructionManagement/";
import FollowUp from "../pages/FollowUpProject";
import CotationMap from "../pages/CotationMap";
import AcessProject from "../pages/AcessProject/indext";
import InformationProject from "../pages/InformationProject";
import PlantsProject from "../pages/PlantsProject";
import ListManagement from "../pages/ListManagement";
import NewStep from "../pages/NewStep";
import Budget from "../pages/Budget/index";
import MySuppliers from "../pages/MySuppliers/index"
import DetailmentBudgetActivity from "../pages/DetailmentBudgetActivity"
import PasswordResetValidation from "../pages/PasswordResetValidation";
import Auth from "../pages/Auth";
import WeeklySummary from "../pages/WeeklySummary";


import Arrows from "../pages/arrows";

const Router: React.FC = () => {
    return (
        <div>
            <BrowserRouter basename="/">
                <Routes>
                    {/**Public routes */}
                    <Route path="/" element={<Login />} />

                    <Route path="/arrows" element={<Arrows />} />

                    <Route path="/validateSocialLogin" element={<ValidateSocialLogin />} />
                    <Route path="/orcamento/:activityId/:projectId/:supplierId" element={<Budget />} />
                    <Route path="/detalhamento/:activityId/:projectId/:supplierId/" element={<DetailmentBudgetActivity />} />
                    <Route path="/resetPassword" element={<PasswordResetValidation />} />
                    <Route path="/acessLink" element={<Auth />} />

                    {/**Private Routes */}
                    <Route path="/home" element={<PrivateRoute>{<Home />}</PrivateRoute>}></Route>
                    <Route path="/gestaoCronograma" element={<PrivateRoute>{<ConstructorManagement />}</PrivateRoute>}></Route>
                    <Route path="/acompanhamento" element={<PrivateRoute>{<FollowUp />}</PrivateRoute>}></Route>
                    <Route path="/gestaoLista" element={<PrivateRoute>{<ListManagement />}</PrivateRoute>}></Route>
                    <Route path="/mapaCotacao" element={<PrivateRoute>{<CotationMap />}</PrivateRoute>}></Route>
                    <Route path="/acessoProjeto" element={<PrivateRoute>{<AcessProject />}</PrivateRoute>}></Route>
                    <Route path="/plantas" element={<PrivateRoute>{<PlantsProject />}</PrivateRoute>}></Route>
                    <Route path="/info" element={<PrivateRoute>{<InformationProject />}</PrivateRoute>}></Route>
                    <Route path="/adicionarEtapa" element={<PrivateRoute>{<NewStep />}</PrivateRoute>}></Route>
                    <Route path="/meusFornecedores" element={<PrivateRoute>{<MySuppliers />}</PrivateRoute>}></Route>
                    <Route path="/gestaoResumoSemanal" element={<PrivateRoute>{<WeeklySummary/>}</PrivateRoute>}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Router