import React from "react";

import { Button } from "../../../components/stylesDomElements/htmlStyledElements"
import addIcon from "../../../assets/addIcon.svg";

interface buttonFileProps {
    loadingRequest: boolean
    progressUploaded: number
    setFile?: React.Dispatch<React.SetStateAction<any>>;
}

const ButtonFile: React.FC<buttonFileProps> = (props) => {
    return (
        <Button>
            {props.loadingRequest ? (
                props.progressUploaded === 100 ? (
                    <p>Salvando arquivo ...</p>
                ) : (
                    <p>Carregando arquivo ({props.progressUploaded})%</p>
                )
            ) : (
                <>
                    <img src={addIcon} alt="Icon de adicionar" />
                    <label htmlFor="plantArchive"> Adicionar arquivo</label>
                    <input type="file" id="plantArchive" onChange={(e) => props.setFile?.(e.target.files)} />
                </>
            )}
        </Button>
    )
}

export default ButtonFile