import {BudgetsProps} from "../../../types/typesEntitiesProject"

import {GreenDestack} from "../style"

export const renderTotalActivityBudget = (budgets:BudgetsProps[], total: string|any, activityId?: string) => {
    var budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

    var totalBudgetsList: number[] = []
    budgetsFilter?.forEach(item => {
        totalBudgetsList.push(parseFloat(item.totalBudgetPayment))
    })
    
    // eslint-disable-next-line
    var minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
        return Math.min(a, b)
    },10000000)

    if (parseFloat(total) === minValueSelected) {
        return <p><GreenDestack>R${total}</GreenDestack></p>
    }
    else
        return <p>R${total}</p>
}