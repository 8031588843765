import React, { useState, useEffect } from "react";

import { UseMasonaryActivityContext } from "../../../../Context/ActivitiesContext/Mansonary/MansonaryContext";
import { attributeType } from "../../../../Models/attributeType";
import { FundationFormType } from "../../../../Models/Masonary/type";
import { Fundation } from "../../../../Models/Masonary/FundationModel";
import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Container, Content } from "../../style";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const FundationStepForm: React.FC<stepFormProps> = (props) => {
  const { masonaryActivities, indexMasonaryActivitySelected, editMasonaryActivity } = UseMasonaryActivityContext();

  const [assignment, setAssignment] = useState<string>('')
  const [itensFormList, setItensFormList] = useState<FundationFormType>(Fundation)

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      let masonaryActivityCopy = masonaryActivities[indexMasonaryActivitySelected]
      masonaryActivityCopy.activityType = 'Fundacao'
      editMasonaryActivity?.(indexMasonaryActivitySelected, masonaryActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected])

  useEffect(() => {
    if (masonaryActivities.length > 0) {
      setItensFormList(masonaryActivities[indexMasonaryActivitySelected].Fundation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMasonaryActivitySelected, masonaryActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemBaldrame(0)
      removeItemBrocas(0)
      removeItemSapatas(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Sapatas") {
      addSapatas({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm²',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Baldrame") {
      addBaldrame({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    } else if (assignment === "Brocas") {
      addBrocas({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    }
  }

  const addSapatas = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var fundationActivitiesCopy = masonaryActivities
    itensFormListCopy.Sapatas.push(object)
    fundationActivitiesCopy[0].Fundation = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, fundationActivitiesCopy[0])
    console.log('oi')
  }

  const addBaldrame = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var fundationActivitiesCopy = masonaryActivities
    itensFormListCopy.Baldrame.push(object)
    fundationActivitiesCopy[0].Fundation = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, fundationActivitiesCopy[0])
  }

  const addBrocas = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var fundationActivitiesCopy = masonaryActivities
    itensFormListCopy.Brocas.push(object)
    fundationActivitiesCopy[0].Fundation = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, fundationActivitiesCopy[0])
  }

  const removeItemSapatas = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Sapatas.splice(index, 1)
    masonaryActivitiesCopy[0].Fundation = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemBaldrame = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Baldrame.splice(index, 1)
    masonaryActivitiesCopy[0].Fundation = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const removeItemBrocas = (index: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Brocas.splice(index, 1)
    masonaryActivitiesCopy[0].Fundation = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }


  const editSapatasValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Sapatas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editSapatasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Sapatas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editSapatasUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Sapatas[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editBaldrameValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Baldrame[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editBaldrameAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Baldrame[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editBaldrameUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Baldrame[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editBrocasValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Brocas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editBrocasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Brocas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  const editBrocasUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const masonaryActivitiesCopy = masonaryActivities
    itensFormListCopy.Brocas[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editMasonaryActivity?.(0, masonaryActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Sapatas.length ? (
        <div>
          <p>Sapatas</p>
          {itensFormList.Sapatas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSapatasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editSapatasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editSapatasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editSapatasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editSapatasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editSapatasUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemSapatas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Baldrame.length ? (
        <div>
          <p>Baldrame</p>
          {itensFormList.Baldrame.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editBaldrameAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editBaldrameValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editBaldrameValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editBaldrameAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editBaldrameValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editBaldrameUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemBaldrame(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}

      {itensFormList.Brocas.length ? (
        <div>
          <p>Brocas</p>
          {itensFormList.Brocas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editBrocasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editBrocasValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editBrocasValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editBrocasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editBrocasValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editBrocasUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemBrocas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FundationStepForm;
