import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudget, ItemBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../../style"
import { GreenDestack } from "../../../style"
import { GasFormType } from "../../../../../Models/Hydraulic/type";

interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface activityProps {
    selectedBudget?: boolean,
    Gas?: GasFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        PontoDeGas?: string[]
        RetirarPontoDeGas?: string[]
        PontoDeRegistro?: string[]
        Tubulacao?: string[]
        Emendas?: string[]
        Ralo?: string[]
    }
}

export const GasComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: any, activityId?: string, itemType?: any) => {
        const budgetsFilter = budgets?.filter(budget => budget.activityId === activityId)

        const totalBudgetsList: number[] = []
        if (!totalItem) {
            return <p>R$0,00</p>
        }

        const minValueSelected = totalBudgetsList.map(Number).reduce(function (a, b) {
            return Math.min(a, b)
        }, 1000000000)

        if (parseFloat(totalItem) === minValueSelected && props.destackMinItemValue) {
            return <p><GreenDestack>R${totalItem}</GreenDestack></p>
        }
        else
            return <p>R${totalItem}</p>

    }

    return (
        <ContainerItensBudget>
            <>
                {activity.Gas?.PontoDeGas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Ponto de gas</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Gas?.PontoDeGas?.map((item, index) => (
                            <div key={index}>
                                <ItemBudgetNewModel >
                                    {item.attributes.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value}
                                        </p>
                                    ))}

                                    {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                        tarefa.unit_of_measurement ? (
                                            <p key={index}>
                                                <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                            </p>
                                        ) : (
                                            <p>-</p>
                                        )
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeGas ? renderItemBudget(budget.budget?.PontoDeGas[index], budget.activityId, 'Ponto de gas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.PontoDeGas ? renderItemBudget(budget.budget?.PontoDeGas[index], budget.activityId, 'Ponto de gas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : null}
                                </ItemBudgetNewModel>
                            </div>
                        ))}
                    </>
                ) : null}

                {activity.Gas?.RetirarPontoDeGas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Retirar ponto de gas</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Gas?.RetirarPontoDeGas?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value}
                                        </p>
                                    ))}

                                    {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                        tarefa.unit_of_measurement ? (
                                            <p key={index}>
                                                <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                            </p>
                                        ) : (
                                            <p>-</p>
                                        )
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.RetirarPontoDeGas ? renderItemBudget(budget.budget?.RetirarPontoDeGas[index], budget.activityId, 'Retirar ponto de gas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.RetirarPontoDeGas ? renderItemBudget(budget.budget?.RetirarPontoDeGas[index], budget.activityId, 'Retirar ponto de gas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : null}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : null}


                {activity.Gas?.Tubulacao.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Tubulação</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Gas?.Tubulacao?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value}
                                        </p>
                                    ))}

                                    {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                        tarefa.unit_of_measurement ? (
                                            <p key={index}>
                                                <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                            </p>
                                        ) : (
                                            <p>-</p>
                                        )
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Tubulacao ? renderItemBudget(budget.budget?.Tubulacao[index], budget.activityId, 'Tubulacao') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Tubulacao ? renderItemBudget(budget.budget?.Tubulacao[index], budget.activityId, 'Tubulacao') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : null}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : null}

                {activity.Gas?.Emendas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Emendas</p>
                            <p>Medidas</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Gas?.Emendas?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value}
                                        </p>
                                    ))}

                                    {item.attributes?.slice(1, 2).map((tarefa, index) => (
                                        tarefa.unit_of_measurement ? (
                                            <p key={index}>
                                                <MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>
                                            </p>
                                        ) : (
                                            <p>-</p>
                                        )
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Emendas ? renderItemBudget(budget.budget?.Emendas[index], budget.activityId, 'Emendas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Emendas ? renderItemBudget(budget.budget?.Emendas[index], budget.activityId, 'Emendas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : null}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : null}

            </>
        </ContainerItensBudget>
    )
}

export default GasComparartive