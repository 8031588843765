import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 1rem;
    .divFileContainer{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .archiveName{
        color: #777777;
    }

    .fileContainer{
        width: 16rem;
        font-size: 0.65rem;
        label{
            padding: 0.3rem;
        }
    }
`