import React, { SetStateAction } from "react";

import { Content } from "./style"

interface navigationMenuProps {
    optionSelected: string
    setOptionSelected: React.Dispatch<SetStateAction<string>>
}

const NavigationMenu: React.FC<navigationMenuProps> = (props) => {
    return (
        <Content>
            <div>
                {props.optionSelected === "optionSendDocument" ? (
                    <>
                        <input type="radio" name="optionNavigation" id="optionSendDocument" checked />
                        <label htmlFor="optionSendDocument">
                            Enviar documentos - Anexar foto ou preencher os campos
                        </label>
                        <div className="selection"></div>
                    </>
                ) : (
                    <>
                        <input type="radio" name="optionNavigation" id="optionSendDocument" />
                        <label htmlFor="optionSendDocument" onClick={() => props.setOptionSelected('optionSendDocument')}>
                            Enviar documentos - Anexar foto ou preencher os campos
                        </label>
                        <div className="selection"></div>
                    </>
                )}
            </div>
            <div>
                {props.optionSelected === "necessitiesList" ? (
                    <>
                        <input type="radio" name="optionNavigation" id="necessitiesList" checked />
                        <label htmlFor="necessitiesList">
                            Lista de necessidades para o serviço
                        </label>
                        <div className="selection"></div>
                    </>
                ) : (
                    <>
                        <input type="radio" name="optionNavigation" id="necessitiesList" />
                        <label htmlFor="necessitiesList" onClick={() => props.setOptionSelected('necessitiesList')}>
                            Lista de necessidades para o serviço
                        </label>
                        <div className="selection"></div>
                    </>
                )}
            </div>
        </Content>
    )
}

export default NavigationMenu