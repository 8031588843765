import React, { useEffect } from 'react'

import { UseImpermeabilizationActivityContext } from "../../../Context/ActivitiesContext/Impermeabilization/ImpermeabilizationContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"
import { textButton } from '../script/textButtonSubmit'
import { RequestApi } from "../RequestApi"


import ImpermeabilizationStepForm from '../../StepsForm/Impermeabilization/Impermeabilization'


import { Container } from "../style"
import MaterialsImpermeabilizationStepForm from '../../StepsForm/Impermeabilization/Materials'

const ImpermeabilizationModifyActivities: React.FC<activityProps> = (props) => {
    const { impermeabilizationActivities, setActivity, setIndexImpermeabilizationActivitySelected } = UseImpermeabilizationActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexImpermeabilizationActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Impermeabilizacao" ? (<ImpermeabilizationStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsImpermeabilizationStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(impermeabilizationActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(impermeabilizationActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default ImpermeabilizationModifyActivities