import React, { useEffect, useState } from "react";
import axios from "axios";
import { UseModalContext } from "../../Context/ModalContext";
import {
  InputForm,
  ContainerInputForm,
  HeaderFormContainer,
  FormContainer,
  Select,
  FooterFormContainer,
  Button,
} from "../stylesDomElements/htmlStyledElements";

import {
  FormNewSupplier,
  OptionTypeRegister,
  FormRegisterDataPayment,
  FormRegisterServiceInformation,
  ContainerListItens,
  Item,
  OrientationDescrible,
} from "./style";

import iconClose from "../../assets/XIcon.svg";

interface supplierProps {
  typeSupplier: string;
  setFeedbackUserRegister?: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewSupplier: React.FC<supplierProps> = (
  props,
  { setFeedbackUserRegister }
) => {
  const { setShowModal } = UseModalContext();
  const [formNewRegisterSelected, setFormNewRegisterSelected] =
    useState<boolean>(true);
  const [informationServiceFormSelected, setInformationServiceFormSelected] =
    useState<boolean>(false);
  const [paymentInformationFormSelected, setPaymentInformationFormSelected] =
    useState<boolean>(false);

  const [typeFormSelected, setTypeFormSelected] =
    useState<string>("novo cadastro");

  const [supplierName, setSupplierName] = useState<string>("");
  const [cpf_cnpj, setCpf_cnpj] = useState<string>("");
  const [address, setAdress] = useState<string>("");
  const [adressNumber, setAdressNumber] = useState<number>(0);
  const [adressComplement, setAdressComplement] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [contactPreference, setContactPreference] = useState<string>("");
  const [budgetResponsible, setBudgetResponsible] = useState<string>("");
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [phoneExists, setPhoneExists] = useState<boolean>(false);
  const [stepsAtuationList, setStepsAtuationList] = useState<string[]>([]);
  const [stepsListed, setSteplisted] = useState<string>("selecione");

  const [bankName, setBankName] = useState<string>("");
  const [cpf_cnpjPayment, setCpf_cnpjPayment] = useState<string>("");
  const [agency, setAgency] = useState<number>(0);
  const [accountNumnber, setaAccountNumnber] = useState<number>(0);
  const [pixKey, setPixKey] = useState<string>("");
  const [marginContratation, setMarginContratation] = useState<number>();

  useEffect(() => {
    if (email !== "") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/verifyIfEmailSupplierExists`, {
          email: email,
        })
        .then((res) => {
          setEmailExists(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (phone !== "") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/verifyIfPhoneSupplierExists`, {
          phone: phone,
        })
        .then((res) => {
          setPhoneExists(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [email, phone]);

  const selectOptionForm = (
    typeFormSelected: string,
    formNewRegisterSelected: boolean
  ) => {
    if (typeFormSelected === "novo cadastro") {
      setFormNewRegisterSelected(formNewRegisterSelected);
      setInformationServiceFormSelected(false);
      setPaymentInformationFormSelected(false);
    } else if (typeFormSelected === "informações de serviço") {
      setFormNewRegisterSelected(false);
      setInformationServiceFormSelected(formNewRegisterSelected);
      setPaymentInformationFormSelected(false);
    } else if (typeFormSelected === "dados de pagamento") {
      setFormNewRegisterSelected(false);
      setInformationServiceFormSelected(false);
      setPaymentInformationFormSelected(formNewRegisterSelected);
    }
    setTypeFormSelected(typeFormSelected);
  };

  const addStepList = (step: string) => {
    setSteplisted(step);
    let stepAtuationListCopy = stepsAtuationList;
    stepAtuationListCopy.push(step);
    setStepsAtuationList([...stepAtuationListCopy]);
    setSteplisted("selecione");
  };

  const dropItem = (index: number) => {
    let stepAtuationCopy = stepsAtuationList;
    stepAtuationCopy.splice(index, 1);
    setStepsAtuationList([...stepAtuationCopy]);
  };

  const submit = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newSupplier`, [
        {
          supplierName,
          cpf_cnpj,
          address,
          adressNumber,
          adressComplement,
          cep,
          phone,
          email,
          site,
          contactPreference,
          budgetResponsible,
          typeSupplier: props.typeSupplier,
          stepsAtuationList,
        },
        {
          supplierId: "",
          bankName: bankName,
          cpf_cnpj: cpf_cnpjPayment,
          agency: agency,
          accountNumnber: accountNumnber,
          pixKey: pixKey,
          marginContratation: marginContratation,
        },
      ])
      .then((res) => {
        window.location.reload();
        setFeedbackUserRegister(true);
        setShowModal?.();
      })
      .catch((err) => {
        selectOptionForm("novo cadastro", true);
      });
  };

  return (
    <div>
      <FormContainer>
        <HeaderFormContainer>
          <p>Cadastrar {props.typeSupplier}</p>
          <img
            src={iconClose}
            alt="icone de fechar"
            onClick={() => setShowModal?.()}
          />
        </HeaderFormContainer>
        <OptionTypeRegister>
          <div onClick={() => selectOptionForm("novo cadastro", true)}>
            <input
              type="radio"
              checked={formNewRegisterSelected}
              id="newRegister"
              name="typeRegister"
              value="novo cadastro"
            />
            <label htmlFor="newRegister">
              <div>Dados cadastrais</div>
            </label>
          </div>
          {props.typeSupplier === "Fornecedor avulso" ? (
            <div
              onClick={() => selectOptionForm("informações de serviço", true)}
            >
              <input
                type="radio"
                checked={informationServiceFormSelected}
                id="serviceInformations"
                name="typeRegister"
                value="informações de serviço"
              />
              <label htmlFor="serviceInformations">
                {" "}
                <div>Informações de serviço</div>
              </label>
            </div>
          ) : (
            <></>
          )}
          <div onClick={() => selectOptionForm("dados de pagamento", true)}>
            <input
              type="radio"
              checked={paymentInformationFormSelected}
              id="dataPayment"
              name="typeRegister"
              value="dados de pagamento"
            />
            <label htmlFor="dataPayment">
              {" "}
              <div>Dados de pagamento</div>
            </label>
          </div>
        </OptionTypeRegister>
        {typeFormSelected === "novo cadastro" ? (
          <FormNewSupplier>
            <div className="grid1">
              <ContainerInputForm>
                {props.typeSupplier === "Fornecedor avulso" ? (
                  <label htmlFor="name">Nome da empresa / fornecedor</label>
                ) : (
                  <label htmlFor="name">Nome da empresa</label>
                )}
                <InputForm
                  id="name"
                  type="text"
                  value={supplierName}
                  onChange={(e) => setSupplierName(e.target.value)}
                  required
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="cpf-cnpj">CPF/CNPJ</label>
                <InputForm
                  id="cpf-cnpj"
                  value={cpf_cnpj}
                  type="text"
                  onChange={(e) => setCpf_cnpj(e.target.value)}
                  required
                />
              </ContainerInputForm>
            </div>

            <div className="grid2">
              <ContainerInputForm>
                <label htmlFor="officeAdress">Endereço</label>
                <InputForm
                  id="officeAdress"
                  value={address}
                  type="text"
                  onChange={(e) => setAdress(e.target.value)}
                  required
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="number">Numero</label>
                <InputForm
                  id="number"
                  type="number"
                  value={adressNumber}
                  onChange={(e) => setAdressNumber(parseInt(e.target.value))}
                  required
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="aditionalAdress">Complemento</label>
                <InputForm
                  id="aditionalAdress"
                  type="text"
                  value={adressComplement}
                  onChange={(e) => setAdressComplement(e.target.value)}
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="cep">Cep</label>
                <InputForm
                  id="cep"
                  type="text"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                  required
                />
              </ContainerInputForm>
            </div>

            <div className="grid3">
              <ContainerInputForm>
                {phoneExists ? (
                  <label className="alert">Telefone já cadastrado</label>
                ) : (
                  <label htmlFor="phone">Telefone</label>
                )}
                <InputForm
                  id="phone"
                  value={phone}
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </ContainerInputForm>

              <ContainerInputForm>
                {emailExists ? (
                  <label className="alert">Email já cadastrado</label>
                ) : (
                  <label htmlFor="mail">Email</label>
                )}
                <InputForm
                  id="mail"
                  value={email}
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="siteSocialNetwork">Site</label>
                <InputForm
                  id="siteSocialNetwork"
                  type="text"
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                />
              </ContainerInputForm>
            </div>

            <div className="grid4">
              <ContainerInputForm>
                <label htmlFor="phone">Responsável pelo orçamento</label>
                <InputForm
                  id="phone"
                  type="text"
                  value={budgetResponsible}
                  onChange={(e) => setBudgetResponsible(e.target.value)}
                  required
                />
              </ContainerInputForm>

              <ContainerInputForm>
                <label htmlFor="preferenceContact">
                  Contato de preferência
                </label>

                <Select
                  value={contactPreference}
                  className="selectElement"
                  onChange={(e) => setContactPreference(e.target.value)}
                >
                  <option value="">Selecione</option>
                  <option value="email">Email</option>
                  <option value="whatsapp">Whatsapp</option>
                </Select>
              </ContainerInputForm>

              <div className="information">
                <br />
                <br /> Não enviaremos mensagens promocionais e afins.
              </div>
            </div>

            <FooterFormContainer>
              <div></div>
              {props.typeSupplier === "Fornecedor avulso" ? (
                <Button
                  className="buttonModal"
                  onClick={() =>
                    selectOptionForm("informações de serviço", true)
                  }
                >
                  Próximo
                </Button>
              ) : (
                <Button
                  className="buttonModal"
                  onClick={() => selectOptionForm("dados de pagamento", true)}
                >
                  Próximo
                </Button>
              )}
            </FooterFormContainer>
          </FormNewSupplier>
        ) : typeFormSelected === "informações de serviço" ? (
          <FormRegisterServiceInformation>
            <div className="gridContent">
              <div className="group1">
                <ContainerInputForm>
                  <label htmlFor="stepsSelection">Etapas de atuação</label>
                  <Select
                    name="bank select"
                    id="stepsSelection"
                    value={stepsListed}
                    onChange={(e) => addStepList(e.target.value)}
                  >
                    <option value="selecione">Selecione</option>
                    <option value="Demolicao">Demolição</option>
                    <option value="Alvenaria">Alvenaria</option>
                    <option value="Impermeabilizacao">Impermeabilização</option>
                    <option value="Eletrica">Elétrica</option>
                    <option value="Hidraulica">Hidráulica</option>
                    <option value="Ar condicionado">Ar condicionado</option>
                    <option value="Pisos e revestimento">
                      Pisos e revestimento
                    </option>
                    <option value="Forro">Forro</option>
                    <option value="Pintura">Pintura</option>
                    <option value="Loucas e metais">Louças e metais</option>
                    <option value="Telhado">Telhado</option>
                    <option value="Vidracaria">Vidraçaria</option>
                    <option value="Marmoraria">Marmoraria</option>
                    <option value="Portas e janelas">Portas e janelas</option>
                    <option value="Esquadrias">Esquadrias</option>
                    <option value="Mobiliario">Mobiliario</option>
                    <option value="Projeto">Projeto</option>
                    <option value="Limpeza">Limpeza</option>
                    <option value="Mudança">Mudança</option>
                  </Select>
                </ContainerInputForm>
                <p> </p>
              </div>
              <div className="group2">
                <label>Etapas selecionadas</label>
                <ContainerListItens>
                  {stepsAtuationList.map((item, index) => (
                    <div key={index}>
                      {item !== "" ? (
                        <Item key={index}>
                          {item}
                          <img
                            src={iconClose}
                            alt="icone de fechar"
                            onClick={() => dropItem(index)}
                          />
                        </Item>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </ContainerListItens>
                <OrientationDescrible>
                  Somente enviaremos orçamentos referentes às etapas
                  selecionadas.
                </OrientationDescrible>
              </div>
            </div>

            <FooterFormContainer>
              <div></div>
              <Button
                type="button"
                className="buttonModal"
                onClick={() => selectOptionForm("dados de pagamento", true)}
              >
                Próximo
              </Button>
            </FooterFormContainer>
          </FormRegisterServiceInformation>
        ) : typeFormSelected === "dados de pagamento" ? (
          <FormRegisterDataPayment>
            <div className="group1">
              <ContainerInputForm>
                <label htmlFor="banckSelect">Banco</label>
                <Select
                  value={bankName}
                  name="bank select"
                  id="banckSelect"
                  onChange={(e) => setBankName(e.target.value)}
                >
                  <option value="Bradesco">Bradesco</option>
                  <option value="Caixa">Caixa</option>
                  <option value="Itaú">Itaú</option>
                  <option value="Nubank">Nubank</option>
                </Select>
              </ContainerInputForm>

              {props.typeSupplier === "Construtora/Empreiteiro" ? (
                <ContainerInputForm>
                  <label htmlFor="contratationMargin">
                    Margem de contratação
                  </label>
                  <div className="marginContrataionContainer">
                    <input
                      value={marginContratation}
                      onChange={(e) =>
                        setMarginContratation(Number(e.target.value))
                      }
                      id=""
                      type="number"
                      required
                    />
                    %
                  </div>
                </ContainerInputForm>
              ) : (
                <></>
              )}
              <ContainerInputForm>
                <label htmlFor="cpf_cnpj">CPF/CNPJ</label>
                <InputForm
                  id="cpf_cnpj"
                  value={cpf_cnpjPayment}
                  type="text"
                  onChange={(e) => setCpf_cnpjPayment(e.target.value)}
                  required
                />
              </ContainerInputForm>
            </div>
            <div className="group2">
              <ContainerInputForm>
                <label htmlFor="cpf_cnpj">Agência</label>
                <InputForm
                  id="agencia"
                  value={agency}
                  type="text"
                  onChange={(e) => setAgency(parseInt(e.target.value))}
                  required
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="cpf_cnpj">Conta</label>
                <InputForm
                  id="conta"
                  value={accountNumnber}
                  type="text"
                  onChange={(e) => setaAccountNumnber(parseInt(e.target.value))}
                  required
                />
              </ContainerInputForm>
              <ContainerInputForm>
                <label htmlFor="cpf_cnpj">Chave PIX</label>
                <InputForm
                  id="PIX"
                  value={pixKey}
                  type="text"
                  onChange={(e) => setPixKey(e.target.value)}
                  required
                />
              </ContainerInputForm>
            </div>

            <FooterFormContainer>
              <div></div>
              <Button
                type="button"
                className="buttonModal"
                onClick={() => submit()}
              >
                Cadastrar
              </Button>
            </FooterFormContainer>
          </FormRegisterDataPayment>
        ) : (
          <></>
        )}
      </FormContainer>
    </div>
  );
};

export default NewSupplier;
