import styled from "styled-components";

interface ImageBackgroundProps {
  backgroundUrl: string;
}

export const Container = styled.div`
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 0.5rem;

  @media (max-width: 480px) {
    margin 5em 0 5em 0;
  }

  .containerProjectInformations {
    display: grid;
    align-items: center;
    padding: 0.6rem;
    cursor: pointer;

    div {
      @media (max-width: 480px) {
        font-size: 80px;
      }
    }

    p {
      @media (max-width: 480px) {
        font-size: 80px;
      }
    }

    .projectName {
      font-size: 0.85rem;
      color: var(--black-color);

      @media (max-width: 480px) {
        font-size: 80px;
      }
    }

    .projectAdress {
      font-size: 0.85rem;
      color: var(--text-light-color);

      @media (max-width: 480px) {
        font-size: 80px;
      }
    }

    .status {
      display: flex;
      color: var(--text-light-color);
      font-size: 0.85rem;

      @media (max-width: 480px) {
        font-size: 80px;
      }

      p {
        color: var(--black-color);
        margin-left: 0.5rem;
      }
    }

    .progress {
      display: flex;
      color: var(--text-light-color);
      font-size: 0.85rem;

      @media (max-width: 480px) {
        font-size: 80px;
      }

      p {
        color: var(--black-color);
        margin-left: 0.5rem;
      }
    }
  }
`;

export const ImgContent = styled.div<ImageBackgroundProps>`
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 21vh;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  img {
    width: 100%;
    height: 21vh;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  @media (max-width: 480px) {
    height: 98em;
  }

  .spaceTop {
    padding: 0.25rem;
  }
`;

export const ImgContentPendingReview = styled.div<ImageBackgroundProps>`
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 21vh;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  img {
    width: 100%;
    height: 21vh;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    filter: blur(8px);
  }

  .spaceTop {
    padding: 0.25rem;
  }
`;

export const CicularOptions = styled.div`
  float: right;
  margin: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.33);

  cursor: pointer;
`;

export const ReviewCicularOptions = styled.div`
  float: right;
  margin: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffe600;

  cursor: pointer;
`;

export const PointIcon = styled.div`
  border: 1px solid;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0.13rem;
  background-color: var(--primary-color);
  margin: 0.1rem;
  border-radius: 50%;
`;

export const FeatureIcon = styled.div`
  border: 1px solid;
  width: 0.3rem;
  height: 1rem;
  padding: 0.13rem;
  background-color: var(--primary-color);
  margin: 0.1rem;
  border-radius: 50%;
`;

export const LinkButton = styled.a`
  color: var(--primary-color);
  font-weight: 400;
  margin: 0.75rem;
  display: flex;
  align-items: center;
  white-space: nowrap;

  img {
    height: 2vh;
  }

  cursor: pointer;
`;
export const MenuOverlay = styled.div`
  position: absolute;
  background-color: var(--dark-background);
  border-radius: 0.5rem;
  display: grid;
  margin-top: 0;
  margin-left: 7.8rem;
  margin-right: 0.5rem;

  img {
    margin: 0.5rem;
  }

  div {
    border-top: 1px solid #ffffff;
  }

  .pointsMenu {
    display: flex;
    justify-content: flex-end;
    border: none;

    .poitElements {
      position: absolute;
      margin: 0.5rem;
      width: 1.75rem;
      display: grid;
      align-content: center;
      justify-content: center;
      border: none;
      cursor: pointer;
    }
  }
`;
export const CentralContent = styled.div`
  padding: 1rem;

  p {
    margin-top: 1rem;
  }

  select {
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.3rem;
    border: 1px solid #e5e5e5;
    background-color: #f1f1f2;
  }

  #email {
    margin-top: 1rem;
    padding: 0.7rem;
  }

  input {
    padding: 0.5rem;
    background-color: #f1f1f2;
    border: 1px solid #e1e1e1;
    border-radius: 0.3rem;
  }
`;

export const Button = styled.a`
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.3rem;
  font-size: 0.85rem;
  cursor: pointer;
  :hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
`;

export const RevisionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 4.5rem;
  left: 1.4rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.3rem;
  font-size: 0.85rem;
  cursor: pointer;
  :hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
`;

export const ConfirmationMessage = styled.div`
  color: var(--secondary-color);
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: var(--primary-color);
`;
export const ContainerModal = styled.div`
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(66 66 66 / 35%);
  z-index: 1;
  transition: 0.5s;
`;

export const FormContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;

  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const FormReviewContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow-y: hidden;

  .form {
    padding: 1rem;
  }

  overflow-y: auto;
`;

export const FormProjectData = styled.div`
  .grid1,
  .grid2 {
    display: grid;
  }

  .grid1 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .grid2 {
    grid-template-columns: 0.5fr 1fr;
    grid-column-gap: 1rem;
  }
`;

export const HeaderFormContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    background-color: #f1f2f3;
    padding: 0.4rem;
    border-radius: 0.3rem;
  }
  img {
    cursor: pointer;
    margin: 0.1rem;
  }
`;

export const FooterFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormProjectDataReview = styled.div`
  .grid1,
  .grid2 {
    display: grid;
  }

  .grid1 {
    grid-template-columns: 0.5fr 0.5fr;
    grid-column-gap: 1rem;
  }

  .grid2 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
`;

export const FormReview = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.3fr;

  .grid1 {
    display: grid;
    margin-right: 1rem;
  }

  .grid2 {
    margin-left: 2rem;
  }
`;

export const OptionTypeProjectReviewForm = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      color: #777777;
      width: 100%;
      cursor: pointer;
      div {
        width: 100%;
        padding: 1rem;
        border-bottom: 0.2rem solid #f1f2f3;
      }
      background-color: var(--primary-color);
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label > div {
    color: #000000;
    border-bottom: 0.2rem solid var(--secondary-color);
    cursor: pointer;
  }
`;
export const ContainerReviewListItens = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 37.1rem;
  max-height: 13.5rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: 1px solid #e1e1e1;
  margin-top: 0.3rem;
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 480px) {
    width: 60em;
  }

  ::-webkit-scrollbar {
    height: 0.5rem;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 0.3rem;
  }
`;

export const Item = styled.span`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  margin: 4px 0;
  margin-right: 0.25rem;
  border: 1px solid #e1e1e1;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  background-color: var(--primary-color);

  img {
    width: 0.5rem;
    margin-left: 0.35rem;
    cursor: pointer;
  }
`;

export const OrientationDescrible = styled.div`
  color: #777777;
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;

export const SelectedSteps = styled.div`
  max-width: 60rem;

  label {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
`;

export const InputForm = styled.input`
  border: 1px solid #e1e1e1;
  background-color: #f1f1f2;
  padding: 0.7rem;
  border-radius: 0.3rem;
`;

export const ReviewInputForm = styled.input`
  border: 1px solid #e1e1e1;
  background-color: #f1f1f2;
  padding: 0.7rem;
  border-radius: 0.3rem;
  float: right;
  width: 100%;
`;

export const DownloadButton = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    float: center;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--secondary-color);
    padding: 0.75rem;
    border: 1px solid var(--secondary-color);
    border-radius: 0.3rem;
    font-size: 0.85rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    :hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
    }

    img {
      margin-right: 0.5rem;
    }
  }
`;
export const ReviewForm = styled.div`
  min-width: 27rem;
`;
export const ContainerInputReviewForm = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;

  label {
    font-size: 0.85rem;
    margin-right: 1rem;
    width: 100%;
  }

  select {
    padding: 0.5rem;
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    color: #141414;
  }

  div {
    display: flex;
    width: 100%;
  }

  .marginContrataionContainer {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;

    input {
      border: none;
      background-color: #f1f1f2;
    }
  }
`;

export const ContainerInputDataReviewForm = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;

  label {
    font-size: 0.85rem;
    margin-right: 1rem;
    width: 100%;
  }

  select {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    color: #141414;
    width: 100%;
  }

  div {
    display: flex;
    width: 100%;
  }

  .marginContrataionContainer {
    border: 1px solid #e1e1e1;
    background-color: #f1f1f2;
    padding: 0.7rem;
    border-radius: 0.3rem;

    display: flex;
    justify-content: space-between;

    input {
      border: none;
      background-color: #f1f1f2;
    }
  }
`;
