import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  ListContainerItens,
  ItemContentList,
  TotalContainer,
  ItemContentListSapatas,
  ListContainerItensSapatas,
  ListContainerItensDemolition,
  ItemContentListDemolition,
} from "./style";

import {
  activitiesProps,
  SupplierProps,
} from "../../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../../script/calculate";
import { DemolitionFormType } from "../../../../../Models/Demolition/type";

import FooterBudgetPerSet from "../../../Components/FooterBudgetPerSet";
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../../components/StepsForm/style";

interface budgetProps {
  activity: any;
  supplier: SupplierProps;
  margin: number;
}

interface activityProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string;
  activityType?: string;
  stepType?: string;

  Demolition: DemolitionFormType;
}

const DemolicaoBudgetPerSet: React.FC<budgetProps> = (props) => {
  const [activity] = useState<activityProps>(props.activity);

  const [paredeDeAlvenaria, setParedeDeAlvenaria] = useState<string[]>([
    "0,00",
  ]);
  const [drywall, setDrywall] = useState<string[]>(["0,00"]);
  const [revestimentoDePiso, setRevestimentoDePiso] = useState<string[]>([
    "0,00",
  ]);
  const [revestimentoDeParede, setRevestimentoDeParede] = useState<string[]>([
    "0,00",
  ]);
  const [contraPiso, setContraPiso] = useState<string[]>(["0,00"]);
  const [pedrasEMarmores, setPedrasEMarmores] = useState<string[]>(["0,00"]);
  const [forro, setForro] = useState<string[]>(["0,00"]);
  const [marcenaria, setMarcenaria] = useState<string[]>(["0,00"]);
  const [itensASeremDesmontados, setItensASeremDesmontados] = useState<
    string[]
  >(["0,00"]);
  const [itensEspeciaisDemolicao, setItensEspeciaisDemolicao] = useState<
    string[]
  >(["0,00"]);
  const [outrosDemolicao, setOutrosDemolicao] = useState<string[]>(["0,00"]);

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>("0,00");
  const [observations, setObservations] = useState<string>("");
  const [conditions, setConditions] = useState<string>("");

  useEffect(() => {
    activity.Demolition.ParedeDeAlvenaria.forEach(() => {
      setParedeDeAlvenaria((prev) => [...prev, "0,00"]);
    });
    // eslint-disable-next-line
    activity.Demolition.Drywall?.forEach(() => {
      setDrywall((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.RevestimentoDePiso?.forEach(() => {
      setRevestimentoDePiso((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.RevestimentoDeParede?.forEach(() => {
      setRevestimentoDeParede((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.ContraPiso?.forEach(() => {
      setContraPiso((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.PedrasEMarmores?.forEach(() => {
      setPedrasEMarmores((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.Forro?.forEach(() => {
      setForro((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.Marcenaria?.forEach(() => {
      setMarcenaria((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.ItensASeremDesmontados?.forEach(() => {
      setItensASeremDesmontados((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.ItensEspeciaisDemolicao?.forEach(() => {
      setItensEspeciaisDemolicao((prev) => [...prev, "0,00"]);
    });

    activity.Demolition.OutrosDemolicao?.forEach(() => {
      setOutrosDemolicao((prev) => [...prev, "0,00"]);
    });
  }, [activity]);

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const paredeDeAlvenariaTotal = sum(paredeDeAlvenaria);
    const drywallTotal = sum(drywall);
    const revestimentoDePisoTotal = sum(revestimentoDePiso);
    const revestimentoDeParedeTotal = sum(revestimentoDeParede);
    const contraPisoTotal = sum(contraPiso);
    const pedrasEMarmoresTotal = sum(pedrasEMarmores);
    const forroTotal = sum(forro);
    const marcenariaTotal = sum(marcenaria);
    const itensASeremDesmontadosTotal = sum(itensASeremDesmontados);
    const itensEspeciaisDemolicaoTotal = sum(itensEspeciaisDemolicao);
    const outrosDemolicaoTotal = sum(outrosDemolicao);
    setTotalBudgetPayment(
      sum([
        paredeDeAlvenariaTotal,
        drywallTotal,
        revestimentoDePisoTotal,
        revestimentoDeParedeTotal,
        contraPisoTotal,
        pedrasEMarmoresTotal,
        forroTotal,
        marcenariaTotal,
        itensASeremDesmontadosTotal,
        itensEspeciaisDemolicaoTotal,
        outrosDemolicaoTotal,
      ])
    );
  }, [
    paredeDeAlvenaria,
    drywall,
    revestimentoDePiso,
    revestimentoDeParede,
    contraPiso,
    pedrasEMarmores,
    forro,
    marcenaria,
    itensASeremDesmontados,
    itensEspeciaisDemolicao,
    outrosDemolicao,
  ]);

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const paredeDeAlvenariaTotal = sum(paredeDeAlvenaria);
  const drywallTotal = sum(drywall);
  const revestimentoDePisoTotal = sum(revestimentoDePiso);
  const revestimentoDeParedeTotal = sum(revestimentoDeParede);
  const contraPisoTotal = sum(contraPiso);
  const pedrasEMarmoresTotal = sum(pedrasEMarmores);
  const forroTotal = sum(forro);
  const marcenariaTotal = sum(marcenaria);
  const itensASeremDesmontadosTotal = sum(itensASeremDesmontados);
  const itensEspeciaisDemolicaoTotal = sum(itensEspeciaisDemolicao);
  const outrosDemolicaoTotal = sum(outrosDemolicao);

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(
        parseFloat(minimumBudgetValue).toFixed(2).replace(".", ",")
      );
    } else {
      setTotalBudgetPayment(
        sum([
          paredeDeAlvenariaTotal,
          drywallTotal,
          revestimentoDePisoTotal,
          revestimentoDeParedeTotal,
          contraPisoTotal,
          pedrasEMarmoresTotal,
          forroTotal,
          marcenariaTotal,
          itensASeremDesmontadosTotal,
          itensEspeciaisDemolicaoTotal,
          outrosDemolicaoTotal,
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (
    index: number,
    stateVar: string[],
    setStateRef: any,
    value: string,
    quantityMultiply?: string
  ) => {
    const stateCopy = Array.from(stateVar);
    stateCopy.splice(index, 1, multiply("1", value));
    quantityMultiply ? (
      stateCopy.splice(index, 1, multiply(quantityMultiply, value))
    ) : (
      <></>
    );
    setStateRef(stateCopy);
  };

  const submitBudget = () => {
    const paredeDeAlvenariaMargin: number[] = [];
    const drywallMargin: number[] = [];
    const revestimentoDePisoMargin: number[] = [];
    const revestimentoDeParedeMargin: number[] = [];
    const contraPisoMargin: number[] = [];
    const pedrasEMarmoresMargin: number[] = [];
    const forroMargin: number[] = [];
    const marcenariaMargin: number[] = [];
    const itensASeremDesmontadosMargin: number[] = [];
    const itensEspeciaisDemolicaoMargin: number[] = [];
    const outrosDemolicaoMargin: number[] = [];

    paredeDeAlvenaria.forEach((item) => {
      if (item != "0,00") {
        paredeDeAlvenariaMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    drywall.forEach((item) => {
      if (item != "0,00") {
        drywallMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    revestimentoDePiso.forEach((item) => {
      if (item != "0,00") {
        revestimentoDePisoMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    revestimentoDeParede.forEach((item) => {
      if (item != "0,00") {
        revestimentoDeParedeMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    contraPiso.forEach((item) => {
      if (item != "0,00") {
        contraPisoMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    pedrasEMarmores.forEach((item) => {
      if (item != "0,00") {
        pedrasEMarmoresMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    forro.forEach((item) => {
      if (item != "0,00") {
        forroMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    marcenaria.forEach((item) => {
      if (item != "0,00") {
        marcenariaMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    itensASeremDesmontados.forEach((item) => {
      if (item != "0,00") {
        itensASeremDesmontadosMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    itensEspeciaisDemolicao.forEach((item) => {
      if (item != "0,00") {
        itensEspeciaisDemolicaoMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    outrosDemolicao.forEach((item) => {
      if (item != "0,00") {
        outrosDemolicaoMargin.push(
          Number(item.replace(",", ".")) +
            Number(item.replace(",", ".")) * (props.margin / 100)
        );
      }
    });

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: "",
        workingDays,
        selectedBudget: false,
        budget: {
          ParedeDeAlvenaria: paredeDeAlvenariaMargin,
          Drywall: drywallMargin,
          RevestimentoDePiso: revestimentoDePisoMargin,
          RevestimentoDeParede: revestimentoDeParedeMargin,
          ContraPiso: contraPisoMargin,
          PedrasEMarmores: pedrasEMarmoresMargin,
          Forro: forroMargin,
          Marcenaria: marcenariaMargin,
          ItensASeremDesmontados: itensASeremDesmontadosMargin,
          ItensEspeciaisDemolicao: itensEspeciaisDemolicaoMargin,
          OutrosDemolicao: outrosDemolicaoMargin,
        },
        minimumBudgetValue,
        totalBudgetPayment: (
          Number(totalBudgetPayment.replace(",", ".")) +
          Number(totalBudgetPayment.replace(",", ".")) * (props.margin / 100)
        ).toString(),
        observations,
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensDemolition>
        {activity.Demolition.ParedeDeAlvenaria?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.ParedeDeAlvenaria.length > 1 ? (
              <P>Parede de alvenaria {index + 1}</P>
            ) : (
              <P>Parede de alvenaria</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.Drywall?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.Drywall.length > 1 ? (
              <P>Drywall {index + 1}</P>
            ) : (
              <P>Drywall</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.RevestimentoDePiso?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.RevestimentoDePiso.length > 1 ? (
              <P>Revestimento de piso {index + 1}</P>
            ) : (
              <P>Revestimento de piso</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.RevestimentoDeParede?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.RevestimentoDeParede.length > 1 ? (
              <P>Revestimento de parede {index + 1}</P>
            ) : (
              <P>Revestimento de parede</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.ContraPiso?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.ContraPiso.length > 1 ? (
              <P>Contrapiso {index + 1}</P>
            ) : (
              <P>Contrapiso</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.PedrasEMarmores?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.PedrasEMarmores.length > 1 ? (
              <P>Pedras e marmores {index + 1}</P>
            ) : (
              <P>Pedras e marmores</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>
      <ListContainerItensDemolition>
        {activity.Demolition.Forro?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.Forro.length > 1 ? (
              <P>Forro {index + 1}</P>
            ) : (
              <P>Forro</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.Marcenaria?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.Marcenaria.length > 1 ? (
              <P>Marcenaria {index + 1}</P>
            ) : (
              <P>Marcenaria</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.ItensASeremDesmontados?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.ItensASeremDesmontados.length > 1 ? (
              <P>Itens a serem desmontados {index + 1}</P>
            ) : (
              <P>Itens a serem desmontados</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.ItensEspeciaisDemolicao?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.ItensEspeciaisDemolicao.length > 1 ? (
              <P>Demolição de itens especiais {index + 1}</P>
            ) : (
              <P>Demolição de itens especiais</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <ListContainerItensDemolition>
        {activity.Demolition.OutrosDemolicao?.map((item, index) => (
          <div key={index}>
            {activity.Demolition.OutrosDemolicao.length > 1 ? (
              <P>Outros de demolição {index + 1}</P>
            ) : (
              <P>Outros de demolição</P>
            )}
            <div className="upContent">
              {item.attributes?.map((tarefa, index) => (
                <p>
                  {tarefa.itemName === "Descrição" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : tarefa.itemName === "Medida" ? (
                    <span key={index}>{tarefa.itemName}</span>
                  ) : null}
                </p>
              ))}
            </div>
            <ItemContentListDemolition>
              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Descrição" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>

              <div>
                {item.attributes?.map((tarefa, index) =>
                  tarefa.itemName === "Medida" ? (
                    <p key={index}>
                      <span>
                        {tarefa.value ||
                        (tarefa.value && tarefa.unit_of_measurement)
                          ? tarefa.value + "" + tarefa.unit_of_measurement
                          : ""}
                      </span>
                    </p>
                  ) : null
                )}
              </div>
            </ItemContentListDemolition>
          </div>
        ))}
      </ListContainerItensDemolition>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudgetPerSet
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
        setObservations={() => setObservations}
      />
    </div>
  );
};

export default DemolicaoBudgetPerSet;
