import React, { useEffect, useState } from "react";
import axios from "axios";

import { Tr } from "../style";
import { Th, MenuOverlay } from "./style";
import { OptionButton } from "../../../components/stylesDomElements/htmlStyledElements"

import { UserProps } from "../../../types/typesEntitiesProject"

import pointsMenu from "../../../assets/pointsMenu.svg";
import dropDownIcon from "../../../assets/expand_less.svg";

interface AcessProjectProps {
  idUser: string
  projectId: string
}

interface ArchitectProps {
  name: string,
  phone: string
}

interface accessProps {
  guestName: String,
  guestEmail: String,
  guestContact: String,
  projectId: String,
  permission: String,
}

const TrTable: React.FC<AcessProjectProps> = (props) => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [scaleY, setScaleY] = useState<number>(1);

  const [mainUser, setMainUser] = useState<UserProps>()
  const [architect, setArchitetct] = useState<ArchitectProps>()
  const [accessList, setAccessList] = useState<accessProps[]>([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/listUser/${props.idUser}`).then(res => {
      setMainUser(res.data)
    })
  }, [props.idUser])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/listArchitect/${props.idUser}`).then(res => {
      setArchitetct(res.data[0])
    })
  }, [props.idUser])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/listAcess/${props.projectId}`)
      .then((res) => {
        setAccessList(res.data);
      });
  }, [accessList, props.projectId]);

  const modify = () => {
    if (scaleY === 1) {
      setScaleY(-1);
    } else if (scaleY === -1) {
      setScaleY(1);
    }
  };

  return (
    <>

      {visibleMenu ? (
        <MenuOverlay>
          Editar permissões
          <OptionButton onClick={() => modify()} valueScaleY={scaleY}>
            Alterar permissão <img src={dropDownIcon} alt="dropDown" />
          </OptionButton>
          <div className="optionsPermission">
            {scaleY === 1 ? (
              <>
                <OptionButton>Arquiteto</OptionButton>
                <OptionButton>Cliente</OptionButton>
                <OptionButton>Visualização</OptionButton>
              </>
            ) : (
              <></>
            )}
          </div>
          <OptionButton>Excluir do projeto</OptionButton>
        </MenuOverlay>
      ) : null}

      <Tr>
        <th>{architect?.name} (você)</th>
        <th>{mainUser?.typeUser}</th>
        <th>{mainUser?.email}</th>
        <th>{architect?.phone}</th>
        <th></th>
      </Tr>
      {accessList.map((access, index) => (
        <Tr key={index}>
          <th>{access.guestName}</th>
          <th>{access.permission}</th>
          <th>{access.guestEmail}</th>
          <th>{access.guestContact}</th>
          <Th onClick={() => setVisibleMenu(!visibleMenu)}>
            <img src={pointsMenu} alt="pontinhos" />
          </Th>
        </Tr>
      ))}
    </>
  );
};

export default TrTable;
