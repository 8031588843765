import React, { useState, useContext } from "react";
import { UserProviderProps } from "../types/typesEntitiesProject";

interface editWeeklySummaryState {
  editMode: boolean;
  setShowEditWeeklySummary?: () => void;
}

const defaultEditWeeklySummary = {
  editMode: false,
  setShowEditWeeklySummary: () => {},
};

const EditWeeklySummaryContext = React.createContext<editWeeklySummaryState>(
  defaultEditWeeklySummary
);

export const UseEditWeeklySummaryContext = () =>
  useContext(EditWeeklySummaryContext);

export const EditWeeklySummaryProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [editMode, setEditMode] = useState(defaultEditWeeklySummary.editMode);

  const setShowEditWeeklySummary = () => {
    setEditMode(!editMode);
    console.log(editMode);
  };

  return (
    <EditWeeklySummaryContext.Provider
      value={{ editMode, setShowEditWeeklySummary }}
    >
      {children}
    </EditWeeklySummaryContext.Provider>
  );
};
