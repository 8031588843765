import React, { useState, useEffect } from "react";

import {UseGlassworkActivityContext} from "../../../../Context/ActivitiesContext/Glasswork/GlassworkContext"

import { InputActivity, ContainerCheckBox } from "../../../stylesDomElements/htmlStyledElements";
import { Container, Content, Select, FlexContainer } from "../../style";

const BalconyClosing: React.FC = () => {
  
  const {glassworkActivities, editGlassworkActivity, indexGlassworkActivitySelected}=UseGlassworkActivityContext()
  
  const [formato_sacada, setFormatoSacada]=useState<string>('Reta')
  const [altura_sacada, setAlturaSacada]=useState<string>('')
  const [largura_frente_sacada,setLarguraFrenteSacada]=useState<string>('')
  const [largura_esquerda_sacada, setLarguraEsquerdaSacada]=useState<string>('')
  const [largura_direita_sacada, setLarguraDireitaSacada]=useState<string>('')
  const [tipo_vidro, setTipoVidro]=useState<string>('Laminado')
  const [modelo_ferragem, setModeloFerragem]=useState<string>('Tradicional, com roldanas')
  const [cor_ferragem, setCorFerragem]=useState<string>('Branco')
  const [caixa_ar_condicionado, setCaixaArcondicionado] = useState<boolean>(false)

  useEffect(()=>{
    if(glassworkActivities.length>0){
      let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
      activityEdited.formato_sacada = formato_sacada;
      activityEdited.tipo_vidro = tipo_vidro;
      activityEdited.modelo_ferragem = modelo_ferragem;
      activityEdited.cor_ferragem = cor_ferragem;
      editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(glassworkActivities.length>0){
      setFormatoSacada(glassworkActivities[indexGlassworkActivitySelected].formato_sacada);
      setAlturaSacada(glassworkActivities[indexGlassworkActivitySelected].altura_sacada);
      setLarguraFrenteSacada(glassworkActivities[indexGlassworkActivitySelected].largura_frente_sacada);
      setLarguraEsquerdaSacada(glassworkActivities[indexGlassworkActivitySelected].largura_esquerda_sacada)
      setLarguraDireitaSacada(glassworkActivities[indexGlassworkActivitySelected].largura_direita_sacada)
      setTipoVidro(glassworkActivities[indexGlassworkActivitySelected].tipo_vidro)
      setModeloFerragem(glassworkActivities[indexGlassworkActivitySelected].modelo_ferragem)
      setCorFerragem(glassworkActivities[indexGlassworkActivitySelected].cor_ferragem)
      setCaixaArcondicionado(glassworkActivities[indexGlassworkActivitySelected].caixa_ar_condicionado)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexGlassworkActivitySelected, glassworkActivities]);

  const editFormatoSacada=(value:string)=>{
    setFormatoSacada(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.formato_sacada = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editAlturaSacada=(value:string)=>{
    setAlturaSacada(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.altura_sacada = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editLarguraFrenteSacada=(value:string)=>{
    setLarguraFrenteSacada(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.largura_frente_sacada = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editLarguraEsquerdaSacada=(value:string)=>{
    setLarguraEsquerdaSacada(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.largura_esquerda_sacada = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editLarguraDireitaSacada=(value:string)=>{
    setLarguraDireitaSacada(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.largura_direita_sacada = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editTipoVidro=(value:string)=>{
    setTipoVidro(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.tipo_vidro = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editModeloFerragem=(value:string)=>{
    setModeloFerragem(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.modelo_ferragem = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCorFerragem=(value:string)=>{
    setCorFerragem(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.cor_ferragem = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }

  const editCaixaArCondicionado=(value:boolean)=>{
    setCaixaArcondicionado(value);
    let activityEdited = glassworkActivities[indexGlassworkActivitySelected];
    activityEdited.caixa_ar_condicionado = value;
    editGlassworkActivity?.(indexGlassworkActivitySelected, activityEdited);
  }
  return (
    <Container>
      <Content>
        <FlexContainer>
          <div>
            <p>Formato da sacada</p>
            <Select value={formato_sacada} onChange={(e)=>editFormatoSacada(e.target.value)}>
              <option value="Reta">Reta</option>
              <option value="Curva simples">Curva simples</option>
              <option value="Em L">Em L</option>
              <option value="Em U">Em U</option>
            </Select>
          </div>
          <div></div>
        </FlexContainer>
        <div>
          Dimensões da sacada:
          <div className="contentSizeInputs contentGrid3">
            <div>
              <p>Altura</p>
              <InputActivity>
                <input type="text" value={altura_sacada} onChange={(e)=>editAlturaSacada(e.target.value)} />
               m
              </InputActivity>
            </div>
            <div>
              <p>Largura da frente</p>
              <InputActivity>
                <input type="text" value={largura_frente_sacada} onChange={(e)=>editLarguraFrenteSacada(e.target.value)} />
               m
              </InputActivity>
            </div>
            <div>
              <p>Largura lado esquerdo</p>
              <InputActivity>
                <input type="text" value={largura_esquerda_sacada} onChange={(e)=>editLarguraEsquerdaSacada(e.target.value)} />
               m
              </InputActivity>
            </div>
            <div>
              <p>Largura lado direito</p>
              <InputActivity>
                <input type="text" value={largura_direita_sacada} onChange={(e)=>editLarguraDireitaSacada(e.target.value)} />
               m
              </InputActivity>
            </div>
          </div>
        </div>
        <FlexContainer>
          <div>
            <p>Tipo de vidro</p>
            <Select value={tipo_vidro} onChange={(e)=>editTipoVidro(e.target.value)}>
              <option value="Laminado">Laminado</option>
              <option value="Temperado">Temperado</option>
            </Select>
          </div>
          <div>
            <p className="observation">
              Recomendações: <br />
              <b>Laminado</b>: Para apartamentos em andares menores que 22{" "}
              <br />
              <b>Temperado</b>: Para apartamentos em andares 23 ou superior
            </p>
          </div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Modelo da ferragem</p>
            <Select value={modelo_ferragem} onChange={(e)=>editModeloFerragem(e.target.value)}>
              <option value="Tradicional, com roldanas">Tradicional, com roldanas</option>
              <option value="Deslizante">Deslizante</option>
            </Select>
          </div>
          <div className="resizeContentInputs">
            <p>Cor da ferragem*</p>
            <Select value={cor_ferragem} onChange={(e)=>editCorFerragem(e.target.value)}>
              <option value="Branco">Branco</option>
              <option value="Preto">Preto</option>
              <option value="Natural">Natural</option>
              <option value="Fosco">Fosco</option>
              <option value="Bronze">Bronze</option>
              <option value="Cromado">Cromado</option>
            </Select>
            <p className="observation">*Se prédio, consultar o padrão definido para fachada</p>
          </div>
        </FlexContainer>
        <ContainerCheckBox onClick={() => editCaixaArCondicionado(!caixa_ar_condicionado)}>
            <label htmlFor="check2">
              <input
                type="checkbox"
                name="ar condicionado"
                id="check2"
                checked={caixa_ar_condicionado}
              />
              <p>Precisa de caixa para ar condicionado</p>
            </label>
          </ContainerCheckBox>
      </Content>
      <Content>
        <div></div>
      </Content>
    </Container>
  );
};

export default BalconyClosing;
