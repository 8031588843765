export const multiply = (measurement?: string, value?: string) => {
    var total = 0.00
    if (measurement && value) {
        total = parseFloat(measurement.replace(",",'.')) * parseFloat(value.replace(",", "."))
        return total.toFixed(2).toString().replace('.', ',')
    } else
        return total.toString()
}

export const sum =(values:string[])=>{
    var total = 0.00
    values.forEach((value)=>{
        total += parseFloat(value.replace(',','.'))
    })
    return total.toFixed(2).replace('.',',')
}