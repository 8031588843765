import React, { useState } from "react"
import { allActivitiesProps, BudgetsProps, SupplierProps } from "../../../../../types/typesEntitiesProject"
import { ListContainerItens, ActionComparativeContainer } from "./style"
import { Button } from "../../style";

import arrowLeft from "../../../../../assets/leftArrow.svg";
import serviceIcon from "../../../../../assets/serviceIcon.svg"
import groupAddIcon from "../../../../../assets/group_add.svg"
import { UseSelectionBudgetContext } from "../../../../../components/SelectionBudget/Context/SelectionBudgetContext";
import { simpleSupplier } from "../../../../../Config/dictionary";


interface headerListProps {
    activityId: string
    budgets: BudgetsProps[]
    suppliers: SupplierProps[]
    setActivityComparativeSelected?: any
    comparativeScreenConfigure: string
    suppliersVisualization: string
    filterFunction: (filterValue: string) => void
}

const HeaderList: React.FC<headerListProps> = (props) => {
    const { setShowBudgetSupplier } = UseSelectionBudgetContext()

    return (
        <ListContainerItens>
            {props.comparativeScreenConfigure === "comparative visualization" ? (
                <>
                    <ActionComparativeContainer>
                        <p className="textLeft">
                            <img onClick={() => props.setActivityComparativeSelected?.(undefined)} src={arrowLeft} alt="Seta apontada para esquerda" />
                            Comparartivos
                        </p>
                        <Button
                            onClick={() => props.filterFunction('onlySimpleSuppliers')}
                            backgroundColor={props.suppliersVisualization === 'onlySimpleSuppliers' ? ("#7592cc") : ""}
                        >
                            <img src={serviceIcon} alt="Ferramentas" />
                        </Button>
                        <Button
                            onClick={() => props.filterFunction('allSuppliers')}
                            backgroundColor={props.suppliersVisualization === 'allSuppliers' ? ("#7592cc") : ""}
                        >
                            <img src={serviceIcon} alt="Ferramentas" />
                            <img src={groupAddIcon} alt="Adicionar grupo" />
                        </Button>
                    </ActionComparativeContainer>


                    <p>{" "}</p>

                    {props.suppliersVisualization === 'allSuppliers' ? (
                        <>
                            <p>Fornecedores Avulsos</p>

                            {props.suppliers.filter((supplier => supplier.typeSupplier !== simpleSupplier)).map((supplier) =>
                                props.budgets.findIndex(budgetFind => budgetFind.providerId === supplier._id) !== -1 ? (
                                    <p>{supplier.supplierName}</p>
                                ) : (<></>)
                            )}
                        </>
                    ) : (
                        props.suppliers.map((supplier) =>
                            props.budgets.findIndex(budgetFind => budgetFind.providerId === supplier._id) !== -1 ? (
                                <p>{supplier.supplierName}</p>
                            ) : (<></>))
                    )}
                </>
            ) : props.comparativeScreenConfigure === "list visualization" ? (
                <>
                    <p className="textLeft">
                        <img onClick={() => props.setActivityComparativeSelected?.(undefined)} src={arrowLeft} alt="Seta apontada para esquerda" />
                        Lista
                    </p>
                    <p>{props.suppliers[0].supplierName}</p>
                </>
            ) :
                props.comparativeScreenConfigure === "selectionBudgets visualization" ? (
                    <>
                        <p className="textLeft">
                            <img onClick={() => setShowBudgetSupplier?.(false)} src={arrowLeft} alt="Seta apontada para esquerda" />
                            Orçamentos
                        </p>
                        <p>{" "}</p>
                        <p>{props.suppliers[0] ? (props.suppliers[0].supplierName) : ('')}</p>
                    </>
                ) : ('')}
        </ListContainerItens>
    )
}

export default HeaderList