import React, { useState, useEffect } from "react";

import { UseGlassworkActivityContext } from "../../../Context/ActivitiesContext/Glasswork/GlassworkContext"
import { UseStepContext } from "../../../Context/stepContext"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const GlassworksController: React.FC = () => {
    const { glassworkActivities, editGlassworkActivity } = UseGlassworkActivityContext()
    const { activitySelected, setActivitySelected } = UseStepContext()

    useEffect(() => {
        setActivitySelected('Box de banheiro')
    }, [])

    useEffect(() => {
        if (glassworkActivities.length > 0) {
            var activityEdited = glassworkActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = activitySelected
            editGlassworkActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    useEffect(() => {
        if (glassworkActivities.length > 0 && glassworkActivities[0].activityType !== "") {
            setActivitySelected(glassworkActivities[0].activityType)
        }
        glassworkActivities.length > 0 && glassworkActivities[0].activityType === "" ? (setActivitySelected('Box de banheiro')) : (<></>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="eletricStep" id="eletricStep" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Box de banheiro">Box de banheiro</option>
                <option value="Fechamento de sacada">Fechamento de sacada</option>
                <option value="Porta de vidro e esquadrias">Porta de vidro e esquadrias</option>
                <option value="Espelhos">Espelhos</option>
                <option value="Materiais para vidracaria">Materiais</option>

            </select>
        </ActionSelect>
    </div>
};

export default GlassworksController;
