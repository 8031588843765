import React from "react";

import BagueteDescription from "./Baguete";
import CoatingDescription from "./Coating";
import RodaBaseDescription from "./RodaBase";
import ShelfDescription from "./Shelf";
import SinksAndCountertopsDescription from "./SinksAndCountertops";
import TentoDescription from "./Tento";
import TresholdDescription from "./Threshold";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const MarbleWorkDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Baguete" ? (<BagueteDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Revestimento" ? (<CoatingDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Roda base" ? (<RodaBaseDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Pias e bancadas" ? (<SinksAndCountertopsDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Tento" ? (<TentoDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Soleira" ? (<TresholdDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Prateleira" ? (<ShelfDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default MarbleWorkDescription