import React, { useState } from "react";

import { BudgetsProps, activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../../Config/dictionary"
import { ContainerItensBudget, SectionItensBudgetNewModel, ItemBudgetNewModel, MeansureOfItem } from "../style"
import { GreenDestack } from "../../style"
import { FramesFormType } from "../../../../Models/Frames/type";


interface budgetProps {
    budget: BudgetsProps[]
    activity: activitiesProps
    destackMinItemValue: boolean
    suppliers: SupplierProps[]
}

interface attributeType {
    attributes: {
        itemName: string,
        unit_of_measurement: string,
        value: string
    }[]
}

interface activityProps {
    selectedBudget?: boolean,
    Frames?: FramesFormType
}

interface budgetComparartiveProps {
    _id?: string,
    activityId?: string
    providerId?: string,
    typeSupplier?: string,
    selectedBudget?: boolean,
    totalBudgetPayment: string

    budget?: {
        Portas?: string[]
        Janelas?: string[]
        Divisorias?: string[]
    }
}

export const EsquadriasComparartive: React.FC<budgetProps> = (props) => {
    const [budgets] = useState<budgetComparartiveProps[]>(props.budget)
    const [activity] = useState<activityProps>(props.activity)

    const renderItemBudget = (totalItem: any, activityId?: string, itemType?: any) => {
        if(!totalItem){
            return <p>R$0,00</p>
        }
        return <p>R${totalItem}</p>
    }

    return (
        <ContainerItensBudget>
            <>
                {activity.Frames?.Portas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Escada</p>

                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Frames?.Portas?.map((item, index) => (
                            <div key={index}>
                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Portas ? renderItemBudget(budget.budget?.Portas[index], budget.activityId, 'Portas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Portas ? renderItemBudget(budget.budget?.Portas[index], budget.activityId, 'Portas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>
                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Frames?.Janelas.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Janelas</p>

                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Frames?.Janelas?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Janelas ? renderItemBudget(budget.budget?.Janelas[index], budget.activityId, 'Janelas') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Janelas ? renderItemBudget(budget.budget?.Janelas[index], budget.activityId, 'Janelas') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
                {activity.Frames?.Divisorias.length ? (
                    <>
                        <SectionItensBudgetNewModel>
                            <p className="itemSectionName">Portas</p>

                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                            <p>{" "}</p>
                        </SectionItensBudgetNewModel>
                        {activity.Frames?.Divisorias?.map((item, index) => (
                            <div key={index}>


                                <ItemBudgetNewModel >
                                    {item.attributes?.slice(0, 1).map((tarefa, index) => (
                                        <p className="itemName" key={index}>
                                            {tarefa.value && !tarefa.unit_of_measurement ? (tarefa.value) : ('')}
                                            {tarefa.value && tarefa.unit_of_measurement ? (<MeansureOfItem>{tarefa.value + " " + tarefa.unit_of_measurement}</MeansureOfItem>) : ('')}
                                        </p>
                                    ))}

                                    {props.suppliers ? (
                                        <>
                                            {/**Item orçado pelo Fornecedor avulso */}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === simpleSupplier).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === simpleSupplier && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Divisorias ? renderItemBudget(budget.budget?.Divisorias[index], budget.activityId, 'Divisorias') : ('')
                                                ))
                                            ))}
                                            {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                                            {props.suppliers.filter(supplier => supplier.typeSupplier === constructor || supplier.typeSupplier === worker).map((supplier) => (
                                                budgets.filter(budget => budget.typeSupplier === constructor || budget.typeSupplier === worker && budget.providerId === supplier._id).map((budget) => (
                                                    budget.budget?.Divisorias ? renderItemBudget(budget.budget?.Divisorias[index], budget.activityId, 'Divisorias') : ('')
                                                ))
                                            ))}
                                        </>
                                    ) : (<></>)}
                                </ItemBudgetNewModel>

                            </div>
                        ))}
                    </>
                ) : (null)}
            </>
        </ContainerItensBudget>
    )
}

export default EsquadriasComparartive