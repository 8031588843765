import styled from "styled-components";

export const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:1rem;
    align-items: baseline;
    width: 55rem;

    label{
        font-size:0.85rem;
        margin-bottom: 0.5rem;
    }

`

export const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    .contentSelect{
        margin-left:1rem;
    }
`

export const SelectedSteps = styled.div`
    max-width: 60rem;
    
    label{
    font-size:0.85rem;
    margin-bottom: 0.5rem;
    }

`
export const ContainerListItens = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    max-width: 60rem;
    padding:0.3rem;
    border-radius: 0.3rem;
    border: 1px solid #E1E1E1;
    margin-top:0.3rem;

    ::-webkit-scrollbar {
        height: 0.5rem;
        background-color: #E1E1E1;
    }
    ::-webkit-scrollbar-thumb {
        background: #333333;
        border-radius: 0.3rem;
    }
`

export const ContainerInputForm = styled.div`
    display: grid;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    label{
        font-size:0.85rem;
        margin-bottom: 0.5rem;
    }

    select{
        padding: 0.5rem;
        border: 1px solid #E1E1E1;
        background-color: #F1F1F2;
        color: #141414;
    }

    #number{
        width: 100%;
    }

    div{
        display: flex;
        width: 100%;
    }

    .marginContrataionContainer{
        border: 1px solid #E1E1E1;
        background-color: #F1F1F2;
        padding: 0.7rem;
        border-radius: 0.3rem;

        display:flex;
        justify-content: space-between;

        input{
            border:none;
            background-color: #F1F1F2;
        }
    }
`

export const Item = styled.span`
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px ;
    margin: 4px 0;
    margin-right: 0.25rem;
    border: 1px solid #E1E1E1;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    background-color: var(--primary-color);

    img{
        width: 0.5rem;
        margin-left: 0.35rem;
        cursor: pointer;
    }
`


