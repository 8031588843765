import axios from "axios"
import { activitiesProps } from "../../types/typesEntitiesProject"

export const sendStepActivityFile = (activities: activitiesProps[], step: any, setUploadArchiveProgress: React.Dispatch<React.SetStateAction<number>>) => {
    let activityFile: { projectFile: string }[]
    const formData = new FormData()
    activities.forEach((element) => {
        if (element.projetoMarcenaria) {
            element.projetoMarcenaria.forEach((project) => {
                formData.append("file", project.arquivoProjeto[0][0])
                project.arquivoProjeto = []
            })
        }
    })

    axios
        .post(`${process.env.REACT_APP_BASE_URL}/newActivityImageFile`,
            formData,
            {
                onUploadProgress: (progressEvent) => {
                    const progress: number = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                    setUploadArchiveProgress?.(progress)
                }
            }
        )
        .then((res) => {
            activityFile = res.data
            activities.forEach((element) => {
                if (element.projetoMarcenaria) {
                    element.projetoMarcenaria.forEach((project, index) => {
                        project.link = activityFile[index].projectFile
                    })
                }
            })
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/newStep`, [step, activities])
                .then((res) => {
                    if (res.status === 200) {
                        window.location.reload();
                    }
                })

        });
}