import React, { SetStateAction } from "react";
import { InputFile, InputForm } from "../../../stylesDomElements/htmlStyledElements";
import { Container } from "../AttachmentFile/style"

import { editAttachment } from "../../script/attachment"
import { attachmentProps } from "../../../ActivitiesAndPendences/type"

interface props {
    attachmentList: attachmentProps[],
    setAttachmentList: React.Dispatch<SetStateAction<any>>,
    archive: FileList[]
    responsible: string,
    index: number
}

const AttachmentFile: React.FC<props> = (props) => {
    return (
        <Container>
            <div className="divFileContainer">
                <InputFile className="fileContainer">
                    {props.archive ? (
                        props.archive[0]?.item(0)?.name ? (<p>{props.archive[0]?.item(0)?.name}</p>) : (<p>Faça o upload do arquivo</p>)
                    ) : (<p>Selecionar novo arquivo</p>)}
                    <label htmlFor={'attachment' + props.index}>Selecione o arquivo</label>
                    <InputForm
                        name={'attachment'}
                        id={'attachment' + props.index}
                        type="file"
                        accept="application/pdf"
                        required
                        onChange={(event) => {
                            editAttachment(props.attachmentList, props.responsible, props.index, props.setAttachmentList, event)
                        }}
                    />
                </InputFile>
            </div>
        </Container>
    )
}

export default AttachmentFile