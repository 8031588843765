export const WoodCovering = {
    Revestimento: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Área',
                    unit_of_measurement: 'm²',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Fornecedor',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}