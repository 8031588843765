import React from "react";

import ColdWaterDescription from "./ColdWater";
import GasDescription from "./Gas";
import HotWaterDescription from "./HotWater";
import SewerDescription from "./Sewer";

import { allActivitiesProps } from "../../../types/typesEntitiesProject"
import MaterialsDescription from "../Materials";

interface props {
    activity: allActivitiesProps
}

const HydraulicDescription: React.FC<props> = (props) => {
    return (
        <>
            {props.activity.activityType === "Agua fria" ? (<ColdWaterDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Agua quente" ? (<HotWaterDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Esgoto" ? (<SewerDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Gas" ? (<GasDescription activity={props.activity} />) : (<></>)}
            {props.activity.activityType === "Materiais" ? (<MaterialsDescription activity={props.activity} />) : (<></>)}

        </>
    )
}

export default HydraulicDescription