export const Moving = {
    Mudanca: [
        {
            attributes: [
                {
                    itemName: 'Descrição',
                    unit_of_measurement: '',
                    value: ''
                },
                {
                    itemName: 'Material',
                    unit_of_measurement: '',
                    value: ''
                },
            ]
        }
    ],
}