import styled from "styled-components";

export const ContainerComparative = styled.div`
    max-height: 70vh;
    max-width: 90vw;
    overflow-y: auto;
`

interface tableProps {
    trBorderColor: string
}

export const TopBar = styled.div`
    padding: 2rem;
    padding-bottom: 1rem;
    margin: 0 auto;
`

export const MenuActionsPage = styled.div`
    background-color: var(--primary-color);
    width: 95vw;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 0.3rem;
    .contentLefth, .containerRight{
        display: flex;
        align-items: center;
    }
`

export const Tr = styled.tr<tableProps>`
    border-left: 4px solid ${props => props.trBorderColor};
    border-bottom: 5px solid #E5E5E5;
    color: #777777;
    font-weight: 700;

    th{
        text-align:center;
    }
`

export const CotentItemStep = styled.div`
    display: grid;
    grid-gap: 0.75rem;
    .stepLine{
        color: #000;
        font-weight: 400;
    }
    
    p{
        color: #777777;
        font-weight: 400;
        text-align: center;
    }

    .contentTotalImg{
        display: flex;
        align-items: center;
        img{
            width:0.85rem;
            margin-left:1.5rem;
        }

        .contentTotalCenter{
            width:59%;
        }
    }

    .itemName{
        text-align:left;
    }

   

`

export const GreenDestack = styled.span`
    width:min-content;
    background-color:rgba(0, 149, 24, 0.8);
    font-weight: 400;
    color:#ffff;
    margin-bottom:0.75rem;
    border-radius:0.3rem;
    padding:0.1rem;  
`

export const BudgetDetails = styled.a`
   cursor:pointer;
   justify-content: center;


    img{
        width:1.1rem;
        margin-top:0.4rem;
        position:absolute;
    }
`

export const ComparativeHeader = styled.div`
    padding: 2rem;

    .centerItens{
        text-align:center;
    }
`

export const StepBudgetContainer = styled.div<tableProps>`
    display:flex;
    justify-content:space-between;
    margin-top:0.5rem;
    background-color:#fff;
    border-left: 5px solid ${props => props.trBorderColor};
    padding:0.75rem;
    padding-bottom:0;
    border-radius:0.4rem;
 
    font-weight: 500;

    .gridBudgetContainer{
        width: 100%;
        display: grid;
        grid-template-columns:2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
        text-align:center;
        align-items: center;
        justify-content: center;
    }

    .textLeft{
        display: flex;
        justify-content: left;
        padding-bottom:0.5rem;
    }
`

export const ActivityBudgetContainer = styled.div<tableProps>`
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    grid-gap: 0.75rem;
    text-align: center;
    margin-top:1rem;
    background-color:#fff;
    border-left: 5px solid ${props => props.trBorderColor};
    padding-top:0.75rem;
    padding-bottom:0;
    border-radius:0.4rem;

    .textLeft{
        display: flex;
        justify-content: left;
        padding-bottom:0.5rem;
        margin-left: 0.5rem;
    }
`

export const ContainerItensBudget = styled.div`
    padding:2rem;
    padding-top:0;
`

export const SectionItensBudget = styled.div`
    display:grid;
    background-color:transparent;;
    grid-template-columns:2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    padding-right:0.75rem;
    background-color: #fff;
    margin-top: 1rem;
    align-items: center;
    border-radius: 0.3rem;
    justify-content: center;
    
    p{
        text-align:center;
        font-weight:500;
        color: #000;
        font-size:1rem;
        padding:0.5rem;
    }

    .itemSectionName{
        text-align:left;
    }
`

export const ActionsBudgetContainer = styled.div`
    display:flex;

    .detailsContainer{
        display:grid;
        margin-top:1.5rem;
        margin-right:1rem;
    }
`

export const Footer = styled.footer`
    background-color:#fff;
    padding:0.75rem;
    margin-top:0.75rem;
    display:grid;
    border-radius:0.4rem;

    .footerContentTable{
        display:grid;
        background-color:transparent;;
        grid-template-columns:2.5fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
        background-color: #fff;
        align-items: center;
        border-radius: 0.3rem;
        justify-content: center;
        text-align: center;
        margin-right: 2.3rem;
    }

    .itemFooterDescrible{
        color:#000;
        font-weight: 500;
        text-align:left;
    }
`

export const PaginationElement = styled.div`
    display: flex;
    margin-top: 0.5rem;
    justify-content: center;
    button{
        font-weight: 500;
        border-radius: 0.3rem;
        margin: 0.5rem;
        margin-bottom: 0;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        color: #c85b3e;
    }
    .activeButton{ 
    }
    .disabled{
        cursor: no-drop;
        background-color: #c4c4c4;
    }
`