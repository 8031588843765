const sizes = {
    tablet: '816px',
    tableS: '667px',
    laptop: '1026px',
    cell: '515px',
    cells: '453px',
    cells2: '409px',
}

export const devices = {
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    tableS: `(max-width: ${sizes.tableS})`,
    cell: `(max-width: ${sizes.cell})`,
    cells: `(max-width: ${sizes.cells})`,
    cells2: `(max-width: ${sizes.cells2})`,
}