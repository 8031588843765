import React, { useEffect } from 'react'

import { UseProjectStepActivityContext } from "../../../Context/ActivitiesContext/ProjectStep/ProjectStepContext"
import { UseModalContext } from "../../../Context/ModalContext"
import { activityProps } from "../ModifyActivityType"

import { Button } from "../../stylesDomElements/htmlStyledElements"

import { RequestApi } from "../RequestApi"

import ArchitecturalProjectStepForm from '../../StepsForm/ProjectStep/ArchitecturalProject'
import StructuralProjectStepForm from '../../StepsForm/ProjectStep/StructuralProject'
import EletricProjectStepForm from '../../StepsForm/ProjectStep/EletricProject'
import HydrosanitaryProjectStepForm from '../../StepsForm/ProjectStep/HydrosanitaryProject'
import AirConditionerProjectStepForm from '../../StepsForm/ProjectStep/AirConditionerProject'


import { textButton } from '../script/textButtonSubmit'
import { Container } from "../style"
import MaterialsProjectStepStepForm from '../../StepsForm/ProjectStep/Materials'


const ProjectStepModifyActivities: React.FC<activityProps> = (props) => {
    const { projectStepActivities, setActivity, setIndexProjectStepActivitySelected } = UseProjectStepActivityContext()
    const { setModalNameHeader } = UseModalContext()

    useEffect(() => {
        console.log(props.activityType)
        if (props.endPointRequestApi === "activityReplace") {
            setModalNameHeader('Alterar atividade - ' + props.activity[0].activityName)
        } else if (props.endPointRequestApi === "sendCotations") {
            setModalNameHeader('Pedir cotação - ' + props.activity[0].activityName)
        }
        setIndexProjectStepActivitySelected?.(0)
        setActivity?.([...props.activity])
    }, [props.activity])

    return (
        <Container>
            {props.activityType === "Projeto de ar condicionado" ? (<AirConditionerProjectStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Projeto arquitetonico" ? (<ArchitecturalProjectStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Projeto estrutural" ? (<StructuralProjectStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Projeto eletrico" ? (<EletricProjectStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Projeto hidrossanitario" ? (<HydrosanitaryProjectStepForm stepFormExibition={false} />) : (<></>)}
            {props.activityType === "Materiais" ? (<MaterialsProjectStepStepForm stepFormExibition={false} />) : (<></>)}

            <div className='buttonContainer'>
                {props.ModifyActivityExibition === true && props.endPointRequestApi === "sendCotations" ? (<>
                    <Button onClick={() => RequestApi(projectStepActivities, "activityReplace")}>
                        Salvar alterações
                    </Button>
                </>) : null}
                <Button onClick={() => RequestApi(projectStepActivities, props.endPointRequestApi)}>
                    {textButton(props.endPointRequestApi)}
                </Button >
            </div>
        </Container>
    )
}

export default ProjectStepModifyActivities