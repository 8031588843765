import React, { useState, useEffect } from "react";

import { UseProjectStepActivityContext } from "../../../../Context/ActivitiesContext/ProjectStep/ProjectStepContext";
import { attributeType } from "../../../../Models/attributeType";
import { EletricProjectFormType } from "../../../../Models/ProjectStep/type";
import { EletricProject } from "../../../../Models/ProjectStep/EletricProjectModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, InputFile, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const EletricProjectStepForm: React.FC<stepFormProps> = (props) => {
  const { projectStepActivities, indexProjectStepActivitySelected, editProjectStepActivity } = UseProjectStepActivityContext();

  const [assignment, setAssignment] = useState<string>('')
  const [itensFormList, setItensFormList] = useState<EletricProjectFormType>(EletricProject)

  useEffect(() => {
    if (projectStepActivities.length > 0) {
      let projectStepActivityCopy = projectStepActivities[indexProjectStepActivitySelected]
      projectStepActivityCopy.activityType = 'Projeto eletrico'
      editProjectStepActivity?.(indexProjectStepActivitySelected, projectStepActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexProjectStepActivitySelected])

  useEffect(() => {
    if (projectStepActivities.length > 0) {
      setItensFormList(projectStepActivities[indexProjectStepActivitySelected].EletricProject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexProjectStepActivitySelected, projectStepActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemProjetoEletrico(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Projeto eletrico") {
      addProjetoEletrico({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Arquivo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          }
        ]
      })
    }
  }

  const addProjetoEletrico = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var eletricProjectActivitiesCopy = projectStepActivities
    itensFormListCopy.ProjetoEletrico.push(object)
    eletricProjectActivitiesCopy[0].EletricProject = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editProjectStepActivity?.(0, eletricProjectActivitiesCopy[0])
    console.log('oi')
  }



  const removeItemProjetoEletrico = (index: number) => {
    var itensFormListCopy = itensFormList
    var projectStepActivitiesCopy = projectStepActivities
    itensFormListCopy.ProjetoEletrico.splice(index, 1)
    projectStepActivitiesCopy[0].EletricProject = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editProjectStepActivity?.(0, projectStepActivitiesCopy[0])
  }



  const editProjetoEletricoValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var projectStepActivitiesCopy = projectStepActivities
    itensFormListCopy.ProjetoEletrico[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editProjectStepActivity?.(0, projectStepActivitiesCopy[0])
  }

  const editProjetoEletricoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var projectStepActivitiesCopy = projectStepActivities
    itensFormListCopy.ProjetoEletrico[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editProjectStepActivity?.(0, projectStepActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.ProjetoEletrico.length ? (
        <div>
          <p>Projeto elétrico</p>
          {itensFormList.ProjetoEletrico.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editProjetoEletricoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editProjetoEletricoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editProjetoEletricoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : attributeItem.itemName === 'Arquivo' ? (
                  <InputFile>

                    <label htmlFor={`ModernizationPanelFile`}>
                      Selecionar o arquivo
                    </label>
                    <input
                      type="file"
                      name="ModernizationPanelFile"
                      id={`ModernizationPanelFile`}
/*                     onChange={(event) => editModernizationPanelFile(index, event)}
 */                    multiple
                    />
                  </InputFile>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editProjetoEletricoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editProjetoEletricoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemProjetoEletrico(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default EletricProjectStepForm;

