import styled from "styled-components";

export const TopBar = styled.div`
    padding: 2rem;
    padding-bottom: 1rem;
    margin: 0 auto;
`

export const MenuActionsPage = styled.div`
    background-color: var(--primary-color);
    width: 95vw;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 0.3rem;
    .contentLefth, .containerRight{
        display: flex;
        align-items: center;
    }
`
interface tableProps {
    trBorderColor: string
}

export const Tr = styled.tr<tableProps>`
    border-left: 4px solid ${props => props.trBorderColor};
    color: #777777;
    font-weight: 700;
    

    .dropDonw{
        cursor:pointer;
    }

    .actionElement{
        display: flex;
        justify-content: start;
        img{
            width:1rem;
        }
    }
`

export const ActionContent = styled.div`
    img{
        margin-right:0.5rem;
    }
`

export const TableContainer = styled.div`
    display:flex;
    justify-content:center;
`