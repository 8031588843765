import { BudgetsProps } from "../../../types/typesEntitiesProject"
import { simpleSupplier, constructor, worker } from "../../../Config/dictionary"
import { SupplierProps } from "../../../types/typesEntitiesProject"
import { allActivitiesProps } from "../../../types/typesEntitiesProject"

import { GreenDestack } from "../style"

export const totalStepBudgets = (budgets: BudgetsProps[], suppliers: SupplierProps[], stepId: string, typeSupplier: string, supplierId?: string) => {
    var totalStepBudget = 0
    var stepBudgets = budgets?.filter(budget => budget.stepId === stepId)

    var totalBudgetsList: number[] = []

    /**Calcular o total  de orçamento dos fornecedores para cada etapa */
    if (typeSupplier === constructor || typeSupplier === worker) {
        stepBudgets.filter(budget => budget.providerId === supplierId).map((budgetSupplier) => {
            totalStepBudget += parseFloat(budgetSupplier.totalBudgetPayment.replace(',', '.'))
        })

        return <p>R${totalStepBudget.toFixed(2).replace('.', ',')}</p>

    } else if (typeSupplier === simpleSupplier) {
        suppliers.filter((supplier => supplier.typeSupplier === simpleSupplier)).forEach((sup => {
            stepBudgets.filter((budget => budget.providerId === sup._id && budget.typeSupplier === simpleSupplier)).forEach((value => {
                totalStepBudget += parseFloat(value.totalBudgetPayment.replace(',', '.'))
            }))
        }))

    
        return <p>R${totalStepBudget.toFixed(2).replace('.', ',')}</p>
    }
}