import React from "react";
import { SelectTypeMeasure } from "./style";

interface selectProps {
    defaultValue: string
    function: (value: string) => void
    options: string[]
}

const SelectTypeMeasureItem: React.FC<selectProps> = (props) => {

    return (
        <SelectTypeMeasure value={props.defaultValue} onChange={(e) => props.function(e.target.value)}>
            {props.options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </SelectTypeMeasure>
    )
}

export default SelectTypeMeasureItem