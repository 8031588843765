import React, { useState } from "react";

import {
  BudgetsProps,
  activitiesProps,
  SupplierProps,
} from "../../../../types/typesEntitiesProject";
import {
  simpleSupplier,
  constructor,
  worker,
} from "../../../../Config/dictionary";
import {
  ContainerItensBudget,
  SectionItensBudgetNewModel,
  ItemBudgetNewModel,
  MeansureOfItem,
} from "../style";
import { GreenDestack } from "../../style";
import { DemolitionFormType } from "../../../../Models/Demolition/type";

interface budgetProps {
  budget: BudgetsProps[];
  activity: activitiesProps;
  destackMinItemValue: boolean;
  suppliers: SupplierProps[];
}

interface activityProps {
  selectedBudget?: boolean;
  Demolition?: DemolitionFormType;
}

interface budgetComparartiveProps {
  _id?: string;
  activityId?: string;
  providerId?: string;
  typeSupplier?: string;
  selectedBudget?: boolean;
  totalBudgetPayment: string;

  budget?: {
    ParedeDeAlvenaria?: string[];
    Drywall?: string[];
    RevestimentoDePiso?: string[];
    RevestimentoDeParede?: string[];
    ContraPiso?: string[];
    PedrasEMarmores?: string[];
    Forro?: string[];
    Marcenaria?: string[];
    ItensASeremDesmontados?: string[];
    ItensEspeciaisDemolicao?: string[];
    OutrosDemolicao?: string[];
  };
}

export const DemolicaoComparative: React.FC<budgetProps> = (props) => {
  const [budgets] = useState<budgetComparartiveProps[]>(props.budget);
  const [activity] = useState<activityProps>(props.activity);

  const renderItemBudget = (
    totalItem: any,
    activityId?: string,
    itemType?: any
  ) => {
    const budgetsFilter = budgets?.filter(
      (budget) => budget.activityId === activityId
    );

    const totalBudgetsList: number[] = [];

    const minValueSelected = totalBudgetsList
      .map(Number)
      .reduce(function (a, b) {
        return Math.min(a, b);
      }, 1000000000);

    if (!totalItem) {
      return <p>R$0,00</p>;
    }

    if (
      parseFloat(totalItem) === minValueSelected &&
      props.destackMinItemValue
    ) {
      return (
        <p>
          <GreenDestack>R${totalItem}</GreenDestack>
        </p>
      );
    } else return <p>R${totalItem}</p>;
  };

  return (
    <ContainerItensBudget>
      <>
        {activity.Demolition?.ParedeDeAlvenaria.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Parede de alvenaria</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.ParedeDeAlvenaria?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.ParedeDeAlvenaria
                              ? renderItemBudget(
                                  budget.budget?.ParedeDeAlvenaria[index],
                                  budget.activityId,
                                  "ParedeDeAlvenaria"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.ParedeDeAlvenaria
                              ? renderItemBudget(
                                  budget.budget?.ParedeDeAlvenaria[index],
                                  budget.activityId,
                                  "ParedeDeAlvenaria"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}
        {activity.Demolition?.Drywall.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Drywall</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.Drywall?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.Drywall
                              ? renderItemBudget(
                                  budget.budget?.Drywall[index],
                                  budget.activityId,
                                  "Drywall"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.Drywall
                              ? renderItemBudget(
                                  budget.budget?.Drywall[index],
                                  budget.activityId,
                                  "Drywall"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.RevestimentoDePiso.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Revestimento de piso</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.RevestimentoDePiso?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.RevestimentoDePiso
                              ? renderItemBudget(
                                  budget.budget?.RevestimentoDePiso[index],
                                  budget.activityId,
                                  "RevestimentoDePiso"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.RevestimentoDePiso
                              ? renderItemBudget(
                                  budget.budget?.RevestimentoDePiso[index],
                                  budget.activityId,
                                  "RevestimentoDePiso"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.RevestimentoDeParede.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Revestimento de parede</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.RevestimentoDeParede?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.RevestimentoDeParede
                              ? renderItemBudget(
                                  budget.budget?.RevestimentoDeParede[index],
                                  budget.activityId,
                                  "RevestimentoDeParede"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.RevestimentoDeParede
                              ? renderItemBudget(
                                  budget.budget?.RevestimentoDeParede[index],
                                  budget.activityId,
                                  "RevestimentoDeParede"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.ContraPiso.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Contrapiso</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.ContraPiso?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.ContraPiso
                              ? renderItemBudget(
                                  budget.budget?.ContraPiso[index],
                                  budget.activityId,
                                  "ContraPiso"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.ContraPiso
                              ? renderItemBudget(
                                  budget.budget?.ContraPiso[index],
                                  budget.activityId,
                                  "ContraPiso"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.PedrasEMarmores.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Pedras e marmores</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.PedrasEMarmores?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.PedrasEMarmores
                              ? renderItemBudget(
                                  budget.budget?.PedrasEMarmores[index],
                                  budget.activityId,
                                  "PedrasEMarmores"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.PedrasEMarmores
                              ? renderItemBudget(
                                  budget.budget?.PedrasEMarmores[index],
                                  budget.activityId,
                                  "PedrasEMarmores"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.Forro.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Forro</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.Forro?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.Forro
                              ? renderItemBudget(
                                  budget.budget?.Forro[index],
                                  budget.activityId,
                                  "Forro"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.Forro
                              ? renderItemBudget(
                                  budget.budget?.Forro[index],
                                  budget.activityId,
                                  "Forro"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.Marcenaria.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Marcenaria</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.Marcenaria?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.Marcenaria
                              ? renderItemBudget(
                                  budget.budget?.Marcenaria[index],
                                  budget.activityId,
                                  "Marcenaria"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.Marcenaria
                              ? renderItemBudget(
                                  budget.budget?.Marcenaria[index],
                                  budget.activityId,
                                  "Marcenaria"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.ItensASeremDesmontados.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Itens a serem desmontados</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.ItensASeremDesmontados?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.ItensASeremDesmontados
                              ? renderItemBudget(
                                  budget.budget?.ItensASeremDesmontados[index],
                                  budget.activityId,
                                  "ItensASeremDesmontados"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.ItensASeremDesmontados
                              ? renderItemBudget(
                                  budget.budget?.ItensASeremDesmontados[index],
                                  budget.activityId,
                                  "ItensASeremDesmontados"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}

        {activity.Demolition?.ItensEspeciaisDemolicao.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Demolição de itens especiais</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.ItensEspeciaisDemolicao?.map(
              (item, index) => (
                <ItemBudgetNewModel key={index}>
                  {item.attributes.slice(0, 1).map((tarefa, index) => (
                    <p className="itemName" key={index}>
                      {tarefa.value}
                    </p>
                  ))}

                  {item.attributes?.slice(2, 3).map((tarefa, index) => (
                    <p className="itemName" key={index}>
                      <MeansureOfItem>
                        {tarefa.value + " " + tarefa.unit_of_measurement}
                      </MeansureOfItem>
                    </p>
                  ))}

                  {props.suppliers ? (
                    <>
                      {/**Item orçado pelo Fornecedor avulso */}
                      {props.suppliers
                        .filter(
                          (supplier) => supplier.typeSupplier === simpleSupplier
                        )
                        .map((supplier) =>
                          budgets
                            .filter(
                              (budget) =>
                                budget.typeSupplier === simpleSupplier &&
                                budget.providerId === supplier._id
                            )
                            .map((budget) =>
                              budget.budget?.ItensEspeciaisDemolicao
                                ? renderItemBudget(
                                    budget.budget?.ItensEspeciaisDemolicao[
                                      index
                                    ],
                                    budget.activityId,
                                    "ItensEspeciaisDemolicao"
                                  )
                                : ""
                            )
                        )}
                      {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                      {props.suppliers
                        .filter(
                          (supplier) =>
                            supplier.typeSupplier === constructor ||
                            supplier.typeSupplier === worker
                        )
                        .map((supplier) =>
                          budgets
                            .filter(
                              (budget) =>
                                budget.typeSupplier === constructor ||
                                (budget.typeSupplier === worker &&
                                  budget.providerId === supplier._id)
                            )
                            .map((budget) =>
                              budget.budget?.ItensEspeciaisDemolicao
                                ? renderItemBudget(
                                    budget.budget?.ItensEspeciaisDemolicao[
                                      index
                                    ],
                                    budget.activityId,
                                    "ItensEspeciaisDemolicao"
                                  )
                                : ""
                            )
                        )}
                    </>
                  ) : null}
                </ItemBudgetNewModel>
              )
            )}
          </>
        ) : null}

        {activity.Demolition?.OutrosDemolicao.length ? (
          <>
            <SectionItensBudgetNewModel>
              <p className="itemSectionName">Outros de demolição</p>
              <p>Medidas</p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </SectionItensBudgetNewModel>
            {activity.Demolition?.OutrosDemolicao?.map((item, index) => (
              <ItemBudgetNewModel key={index}>
                {item.attributes.slice(0, 1).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    {tarefa.value}
                  </p>
                ))}

                {item.attributes?.slice(2, 3).map((tarefa, index) => (
                  <p className="itemName" key={index}>
                    <MeansureOfItem>
                      {tarefa.value + " " + tarefa.unit_of_measurement}
                    </MeansureOfItem>
                  </p>
                ))}

                {props.suppliers ? (
                  <>
                    {/**Item orçado pelo Fornecedor avulso */}
                    {props.suppliers
                      .filter(
                        (supplier) => supplier.typeSupplier === simpleSupplier
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === simpleSupplier &&
                              budget.providerId === supplier._id
                          )
                          .map((budget) =>
                            budget.budget?.OutrosDemolicao
                              ? renderItemBudget(
                                  budget.budget?.OutrosDemolicao[index],
                                  budget.activityId,
                                  "OutrosDemolicao"
                                )
                              : ""
                          )
                      )}
                    {/**Item orçado pela(o) Construtora/Empreiteiro*/}
                    {props.suppliers
                      .filter(
                        (supplier) =>
                          supplier.typeSupplier === constructor ||
                          supplier.typeSupplier === worker
                      )
                      .map((supplier) =>
                        budgets
                          .filter(
                            (budget) =>
                              budget.typeSupplier === constructor ||
                              (budget.typeSupplier === worker &&
                                budget.providerId === supplier._id)
                          )
                          .map((budget) =>
                            budget.budget?.OutrosDemolicao
                              ? renderItemBudget(
                                  budget.budget?.OutrosDemolicao[index],
                                  budget.activityId,
                                  "OutrosDemolicao"
                                )
                              : ""
                          )
                      )}
                  </>
                ) : null}
              </ItemBudgetNewModel>
            ))}
          </>
        ) : null}
      </>
    </ContainerItensBudget>
  );
};

export default DemolicaoComparative;
