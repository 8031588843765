export const projectPeriodDefined = (dateInitial: string, dateFinal: string) => {
    var period = [{ month: 'Janeiro', indexMonth: 0, year: "" }, { month: 'Fevereiro', indexMonth: 1, year: "" }, { month: 'Março', indexMonth: 2, year: "" }, { month: 'Abril', indexMonth: 3, year: "" }, { month: 'Maio', indexMonth: 4, year: "" }, { month: 'Junho', indexMonth: 5, year: "" }, { month: 'Julho', indexMonth: 6, year: "" }, { month: 'Agosto', indexMonth: 7, year: "" }, { month: 'Setembro', indexMonth: 8, year: "" }, { month: 'Outubro', indexMonth: 9, year: "" }, { month: 'Novembro', indexMonth: 10, year: "" }, { month: 'Dezembro', indexMonth: 11, year: "" }]
    var periodNextYear = [{ month: 'Janeiro', indexMonth: 0, year: "" }, { month: 'Fevereiro', indexMonth: 1, year: "" }, { month: 'Março', indexMonth: 2, year: "" }, { month: 'Abril', indexMonth: 3, year: "" }, { month: 'Maio', indexMonth: 4, year: "" }, { month: 'Junho', indexMonth: 5, year: "" }, { month: 'Julho', indexMonth: 6, year: "" }, { month: 'Agosto', indexMonth: 7, year: "" }, { month: 'Setembro', indexMonth: 8, year: "" }, { month: 'Outubro', indexMonth: 9, year: "" }, { month: 'Novembro', indexMonth: 10, year: "" }, { month: 'Dezembro', indexMonth: 11, year: "" }]

    var projectInitialDate = dateInitial.split("-")
    var projectFinishDate = dateFinal.split("-")

    var monthInitialDate = projectInitialDate[1]
    var yearInitialDate = projectInitialDate[0]

    var monthFinishDate = projectFinishDate[1]
    var yearFinishDate = projectFinishDate[0]


    var periodSelected = []

    var nextYearPeriodSelected = []

    if (yearInitialDate === yearFinishDate) {
        /**Se forem do mesmo ano */
        for (var i = parseInt(monthInitialDate); i <= parseInt(monthFinishDate); i++) {
            var thePeriodModel = period[i - 1]
            thePeriodModel.year = yearInitialDate
            periodSelected.push(thePeriodModel)
        }
        return periodSelected
    } else if (yearInitialDate < yearFinishDate) {
        /**Se forem de anos diferentes */
        for (i = parseInt(monthInitialDate); i <= 12; i++) {
            thePeriodModel = period[i - 1]
            thePeriodModel.year = yearInitialDate
            periodSelected.push(thePeriodModel)
        }

        for (i = 1; i <= parseInt(monthFinishDate); i++) {
            var theNextYearPeriodModel = periodNextYear[i - 1]
            theNextYearPeriodModel.year = yearFinishDate
            nextYearPeriodSelected.push(theNextYearPeriodModel)
        }
        var period = periodSelected.concat(nextYearPeriodSelected);
       
        console.log(period)

        return period 
    }
}