import React, { useState } from "react";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    tipo_porta?: string,
    cor_vidro?: string,
    numero_folhas?: string,
    altura_porta?: string,
    comprimento_porta?: string,
    material_ferragem?: string,
    cor_ferragem?: string,
    descricao?: string,
    porta_outros?: string
    material_acabamento_outros?: string
}

const GlassdoorandFramesDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>

            <div className="item">
                {activity?.tipo_porta ? (
                    <div className="line">
                        <p>Tipo de porta</p>
                        <p>
                            {activity.tipo_porta === "Outros" ? (
                                activity.porta_outros
                            ) : (
                                activity.tipo_porta
                            )}
                        </p>
                    </div>
                ) : null}

                {activity?.altura_porta ? (
                    <div className="line">
                        <p>Altura</p>
                        <p>
                            {activity.altura_porta} cm
                        </p>
                    </div>
                ) : null}

                {activity?.comprimento_porta ? (
                    <div className="line">
                        <p>Comprimento</p>
                        <p>
                            {activity.comprimento_porta} cm
                        </p>
                    </div>
                ) : null}

                {activity?.descricao ? (
                    <div className="line">
                        <p>Descrição</p>
                        <p>
                            {activity.descricao}
                        </p>
                    </div>
                ) : null}

                <h4><br />Vidro</h4>
                <br />

                {activity?.cor_vidro ? (
                    <div className="line">
                        <p>Cor</p>
                        <p>
                            {activity.cor_vidro}
                        </p>
                    </div>
                ) : null}

                {activity?.numero_folhas ? (
                    <div className="line">
                        <p>Numero de folhas</p>
                        <p>
                            {activity.numero_folhas} un.
                        </p>
                    </div>
                ) : null}

                <h4><br />Ferragem</h4>
                <br />

                {activity?.cor_ferragem ? (
                    <div className="line">
                        <p>Cor</p>
                        <p>
                            {activity.cor_ferragem}
                        </p>
                    </div>
                ) : null}

                {activity?.material_ferragem ? (
                    <div className="line">
                        <p>Material</p>
                        <p>
                            {activity.material_ferragem === "Outros" ? (
                                activity.material_acabamento_outros
                            ) : (
                                activity.material_ferragem
                            )}
                        </p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default GlassdoorandFramesDescription;
