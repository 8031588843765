import React, { useState } from "react";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    tipo_box?:string,
    cor_vidro?:string,
    altura?:string,
    comprimento?:string,
    largura?:string,
    tipo_porta?:string,
    formato_ferragem?:string,
    cor_ferragem?:string,
}

const BathroomboxDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>

            <div className="item">
                {activity?.tipo_box ? (
                    <div className="line">
                        <p>Tipo de box</p>
                        <p>
                            {activity.tipo_box}
                        </p>
                    </div>
                ) : null}

                {activity?.cor_vidro ? (
                    <div className="line">
                        <p>Cor do vidro</p>
                        <p>
                            {activity.cor_vidro}
                        </p>
                    </div>
                ) : null}

                {activity?.altura ? (
                    <div className="line">
                        <p>Altura</p>
                        <p>
                            {activity.altura} cm
                        </p>
                    </div>
                ) : null}

                {activity?.comprimento ? (
                    <div className="line">
                        <p>Comprimento</p>
                        <p>
                            {activity.comprimento} cm
                        </p>
                    </div>
                ) : null}

                {activity?.largura ? (
                    <div className="line">
                        <p>Largura</p>
                        <p>
                            {activity.largura} cm
                        </p>
                    </div>
                ) : null}

                {activity?.tipo_porta ? (
                    <div className="line">
                        <p>Tipo de porta</p>
                        <p>
                            {activity.tipo_porta} 
                        </p>
                    </div>
                ) : null}

                <h4><br/>Ferragem</h4>
                <br/>

                {activity?.cor_ferragem ? (
                    <div className="line">
                        <p>Cor</p>
                        <p>
                            {activity.cor_ferragem} 
                        </p>
                    </div>
                ) : null}

                {activity?.formato_ferragem ? (
                    <div className="line">
                        <p>Formato</p>
                        <p>
                            {activity.formato_ferragem} 
                        </p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default BathroomboxDescription;
