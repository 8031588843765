import React, { useState, useEffect } from "react";

import { UseFloorsAndCoveringsActivityContext } from "../../../Context/ActivitiesContext/FloorsAndCoverings/FloorsAndCoveringsContext"
import { UseStepContext } from "../../../Context/stepContext"
import { textTransform } from "../script"

import { ActionSelect, } from "../../stylesDomElements/htmlStyledElements";


const FloorsAndCoveringsController: React.FC = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()
    const { floorsAndCoveringsActivities, editFloorsAndCoveringsActivity } = UseFloorsAndCoveringsActivityContext()

    useEffect(() => {
        setActivitySelected('Revestimento de parede')
    }, [])

    useEffect(() => {
        if (floorsAndCoveringsActivities.length > 0) {
            var activityEdited = floorsAndCoveringsActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editFloorsAndCoveringsActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])
    /*
        useEffect(() => {
            if (floorsAndCoveringsActivities.length > 0 && floorsAndCoveringsActivities[props.indexSelected].activityType !== "") {
                setOptionSelected(floorsAndCoveringsActivities[props.indexSelected].activityType)
            }
            floorsAndCoveringsActivities.length > 0 && floorsAndCoveringsActivities[props.indexSelected].activityType === "" ? (setOptionSelected('Revestimento de parede')) : (<></>)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.indexSelected]) */

    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="FloorsAndCoverings" id="FloorsAndCoverings" onChange={(e) => setActivitySelected(e.target.value)}>
                <option value="Revestimento de parede">Revestimento de parede</option>
                <option value="Revestimento de piso ceramico">Revestimento de piso cerâmico</option>
                <option value="Revestimento de piso vinilico">Revestimento de piso vinílico</option>
                <option value="Revestimento de piso - Pedras">Revestimento de piso - Pedras</option>
                <option value="Rodape">Rodapé</option>
                <option value="Assoalho">Assoalho</option>
                <option value="Revestimento de madeira"> Revestimento de madeira</option>
                <option value="Materiais para pisos e revestimentos"> Materiais</option>
            </select>
        </ActionSelect>
    </div>
}

export default FloorsAndCoveringsController