import React, { useState, useEffect } from "react";
import axios from "axios";

import { documentListProps } from "../../type"

import { DocumentItem, DocumentContainer } from "./style"

import dowloadIcon from "../../../../assets/dowloadIcon.svg"
import { ContainerCheckBox } from "../../../stylesDomElements/htmlStyledElements";
import { Input } from "../../style";

interface props {
    supplierId: string,
    activityId: string 
}

const SupplierDocumentList: React.FC<props> = (props) => {
    const [supplierDocumentation, setSupplierDocumentation] = useState<documentListProps>()
   
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/listDocumentsSupplierBudget/${props.supplierId}/${props.activityId}`).then((res) => {
            setSupplierDocumentation(res.data[0])
        })
    }, [])

    return (
        <DocumentContainer>
            {supplierDocumentation?.documentsList.length ? (
                <>
                    <ContainerCheckBox className="ContainerCheckBox">
                        <label htmlFor={'supplierDocumentListVerifield'}>
                            <input type="checkbox" checked /* onClick={() => editCheckActivity(activityAndPendences, setActivityAndPendences, index, !element.check)} */ name="supplierActivitiesAndPendences" id={'supplierDocumentListVerifield'} />
                            <Input type="text" placeholder={'Enviar documentos para visita'} disabled /* onChange={(e) => editActivityName(activityAndPendences, setActivityAndPendences, index, e.target.value)} */ />
                        </label>
                    </ContainerCheckBox>
                    <ul>
                        {supplierDocumentation?.documentsList.map((document, index) => (
                            <li key={index}>
                                <DocumentItem href={document.archiveUrl}>
                                    <img src={dowloadIcon} alt="icone de seta para baixo" /> {document.typeDocument} - {document.numberDocument}
                                </DocumentItem>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <></>
            )}
        </DocumentContainer>
    )
}

export default SupplierDocumentList