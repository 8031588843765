import React, { useState } from "react";

import { espelhoProps } from "../../../../types/typesEntitiesProject";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string
    projectId?: string;
    stepId?: string;
    budgetId?: [];
    activityName?: string;
    activityStatus?: string,
    activityType?: string,
    stepType?: string

    espelhos?: espelhoProps[];
}

const MirrorsDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            {activity.espelhos ? (
                activity.espelhos?.map((item, index) => (
                    <div>
                        <p className="bold">Espelho {index + 1}</p>
                        <p>
                            <p>
                                {item.quantidade ? ('Quantidade: ' + item.quantidade) : ('')}

                            </p>
                            <p>
                                {item.cor_espelho !== 'Outros' ? ('Cor: ' + item.cor_espelho) : ('')}
                                {item.cor_espelho_outros ? ('Cor: ' + item.cor_espelho_outros) : ('')}
                            </p>
                            <p>
                                {item.tipo_espelho !== 'Outros' ? ('Tipo: ' + item.tipo_espelho) : ('')}
                                {item.tipo_espelhos_outros ? ('Tipo: ' + item.tipo_espelhos_outros) : ('')}
                            </p>

                            <p>
                                {item.comprimento_espelho ? ('Medidas: ' + item.comprimento_espelho) : ('')}
                                {item.altura_espelho ? (' X ' + item.altura_espelho) : ('')}
                            </p>
                            <p>
                                {item.acabamento_borda ? ('Acabamento da borda: ' + item.acabamento_borda) : ('')}
                                {item.acabamento_borda_outros ? ('Acabamento da borda: ' + item.acabamento_borda_outros) : ('')}
                            </p>
                            <br></br><br></br>
                        </p>
                    </div>
                ))
            ) : (<></>)}
        </div>
    );
};

export default MirrorsDescription;
