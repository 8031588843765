import styled from "styled-components";

interface CompleteProps {
  width: number;
}

interface containerProps {
  width: string;
}

export const Container = styled.div<containerProps>`
  width: ${(props) => props.width};
  height: 3rem;
  background-color: var(--primary-color);
  border-radius: 0.2rem;
  margin-right: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;

  @media (max-width: 480px) {
    height: 14rem;
    width: 45rem;
    font-size: 60px;
    white-space: nowrap;
  }

  svg {
    cursor: pointer;
    @media (max-width: 480px) {
      display: none;
    }
  }
`;

export const Complete = styled.span<CompleteProps>`
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: var(--blue-light);
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
  display: flex;
  align-items: center;

  p {
    position: absolute;
    margin-left: 0.5rem;
    @media (max-width: 480px) {
      margin-left: 7rem;
      display: grid;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
`;
