import React, { useEffect, useState } from "react";
import axios from "axios";

import { UseUserContext } from "../../Context/UserContext";
import { PlantProps, ProjectProps } from "../../types/typesEntitiesProject";

import { Container, ContentBox } from "./style";
import { ContainerSearch, ActionSelect, Table } from "../../components/stylesDomElements/htmlStyledElements";

import Header from "../../components/Header";
import ItensMenu from "../../components/ItensMenu";
import TrTable from "./Tr";
import ButtonFile from "./ButtonFile";

import searchIcon from "../../assets/searchIcon.svg";
import { UseAuthAcessContext } from "../../Context/AuthAcessContext";
import AcessMenu from "../../components/AcessMenu";
import { verifyTypeProjectExibition } from "../../Utils/verifyProjectLinkId";

const PlantsProject: React.FC = () => {
  const { acessLink } = UseAuthAcessContext()
  const { projectSelected, setprojectSelected } = UseUserContext();
  const [project, setProject] = useState<ProjectProps>();

  const [file, setFile] = useState<FileList>();
  const [plants, setPlants] = useState<PlantProps[]>([]);

  const [filterArchive, setFilterArchive] = useState<string>("");
  const [optionOrder, setOptionOrder] = useState("");

  const [archives, setArchives] = useState<PlantProps[]>([]);

  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
  const [progressUploaded, setProgressUploaded] = useState<number>(0)

  useEffect(() => {
    if (acessLink) {
      const projectOfLink = verifyTypeProjectExibition();
      if (projectOfLink && setprojectSelected) {
        setprojectSelected(projectOfLink);
      }
    } else {
      const projectId = localStorage.getItem("projectId");
      if (projectId && typeof setprojectSelected !== 'undefined') {
        setprojectSelected(projectId);
      }
    }
  }, []);

  useEffect(() => {
    if (projectSelected) {
      
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/project/${projectSelected}`)
        .then((res) => {
          setProject(res.data[0]);
        });
    }
  }, [projectSelected, acessLink]);

  useEffect(() => {
    if (file) {
      const plantArchive = new FormData();
      plantArchive.append("file", file[0]);
      plantArchive.append("projectId", projectSelected);
      setLoadingRequest(true)
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/newPlant`, plantArchive,
          {
            onUploadProgress: (progressEvent) => {
              let progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setProgressUploaded(progress)
            }
          }).then((res) => {
            if (res.status === 200) {
              window.location.reload()
            }
          })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (projectSelected) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/listPlants/${projectSelected}`)
        .then((res) => {
          setPlants(res.data);
          setArchives(res.data);
        });
    }
  }, [projectSelected, acessLink]);

  return (
    <div>
      <Header />

      {!acessLink ? (
        <ItensMenu optionSelected="Plantas" component={<ButtonFile loadingRequest={loadingRequest} progressUploaded={progressUploaded} setFile={setFile} />} />
      ) : (
        <AcessMenu />
      )}

      <Container>
        <ContentBox>
          <ContainerSearch>
            <img src={searchIcon} alt="Icone Lupa" />
            <input
              type="text"
              placeholder="Pesquisar"
              onChange={(e) => setFilterArchive(e.target.value)}
            />
            <span>X</span>
          </ContainerSearch>
          <ActionSelect>
            Ordenar por:
            <select onChange={(e) => setOptionOrder(e.target.value)}>
              <option value="date_modification" selected>
                Data de modificação
              </option>
              <option value="type">Tipo</option>
            </select>
          </ActionSelect>
        </ContentBox>
        <Table>
          <thead>
            <tr className="headerTable">
              <th>Arquivo</th>
              <th>Tipo</th>
              <th>Tamanho</th>
              <th>Última modificação</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {project?.urlArchiveProject.map((archive, index) => (
              <TrTable key={index}
                projectId={project._id}
                archiveUrl={archive.archiveUrl}
                archiveName={archive.archiveName}
                size={archive.size}
                type={archive.type}
                modification={archive.modification}
              ></TrTable>
            ))}

            {archives.map((item, index) => (
              <TrTable
                key={index}
                _id={item._id}
                projectId={item.projectId}
                archiveUrl={item.archiveUrl}
                archiveName={item.archiveName}
                size={item.size}
                type={item.type}
                modification={item.modification}
              ></TrTable>
            ))}
          </tbody>
        </Table>

      </Container>
    </div>
  );
};

export default PlantsProject;
