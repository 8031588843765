import styled from "styled-components";

export const Th = styled.th`
    cursor: pointer;

    input[type="checkbox"]{
        width: 1.15rem;
        height: 1.15rem;
        margin-right: 0.5rem;
        margin-bottom: 0.1rem;
        position: relative;
        cursor: pointer;
    }

    input[type='checkbox']::before {
        content: ' ';
        width: inherit;
        height: inherit;
        position: absolute;
        background-color: #F1F1F2;
        border: 1px solid #E1E1E1;
    }

    input[type='checkbox']:checked::after {
        content: ' ';
        width: 0.3rem;
        height: 0.7rem;
        margin-top: 0.1rem;
        margin-left: 0.4rem;
        border-color: #777777;
        border-style: solid;
        border-width: 0 2px 2px 0;
        position: absolute;
        transform: rotate(45deg);
    }
`

export const MenuOverlay = styled.div`
    background-color: var(--dark-background);
    border-radius: 0.3rem;
    display: grid;
    position: absolute;
    right: 0;
    margin-right: 3rem;
    margin-top:1.7rem;
`

export const OptionButton = styled.a`
    color: var(--primary-color);
    font-weight: 400;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    font-size:0.85rem;
    cursor: pointer;

    a{
        color: var(--primary-color);
        font-weight: 400;
    }
`

export const Button = styled.button`
    background-color: var(--primary-color);
    padding: 0.75rem;
    border-radius: 4px;
    color: var(--secondary-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    border: 1px solid var(--secondary-color);
    :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
        a{
            color: var(--primary-color);
        }
    }

    a{
        color: var(--secondary-color);
    }

    img{
        width: 1rem;
        margin-right: 0.5rem;
    }

    label{
        cursor: pointer;
    }

    cursor: pointer;
`

export const Tr = styled.tr`
    border-bottom: 5px solid #E5E5E5;
    color: #777777;
    font-weight: 700;

    label{
        color: #77777780;
        padding: 0.5rem;
        font-size: 0.85rem;
        cursor: pointer;
    }

`

