import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  PriceItem,
  ContentInputPrice,
  TotalContainer,
  ListContainerItensAccessories,
  ItemContentListAccessories
} from "./style";

import { activitiesProps, SupplierProps } from "../../../../types/typesEntitiesProject";
import { multiply, sum } from "../../script/calculate"
import { AccessoriesFormType } from "../../../../Models/TablewareAndMetals/type";

import FooterBudget from "../../Components/FooterBudget"
import { JsonWebTokenError } from "jsonwebtoken";
import { P } from "../../../../components/StepsForm/style";

interface budgetProps {
  activity: any,
  supplier: SupplierProps
  margin: number
}

interface activityProps {
  _id?: string
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName?: string;
  activityStatus?: string,
  activityType?: string,
  stepType?: string

  Accessories: AccessoriesFormType
}

const AcessoriosBudget: React.FC<budgetProps> = (props) => {

  const [activity] = useState<activityProps>(props.activity);

  const [acessorio, setAcessorio] = useState<string[]>(["0,00"])

  const [workingDays, setWorkingDays] = useState<number>(0);
  const [minimumBudgetValue, setMinimunBudgetValue] = useState<string>("0,00");
  const [totalBudgetPayment, setTotalBudgetPayment] = useState<string>('0,00')
  const [conditions, setConditions] = useState<string>('')

  useEffect(() => {
    activity.Accessories.Acessorio.forEach(() => {
      setAcessorio((prev) => [...prev, "0,00"])
    })
  }, [activity])

  /**Para calcular o total dos valores de cada item ao identificar alterações em seus valores */
  useEffect(() => {
    const acessorioTotal = sum(acessorio)

    setTotalBudgetPayment(sum([acessorioTotal]))
  }, [acessorio])

  /**Para atualizar o total quando o valor minimo for superior ao valor total orçado */
  const acessorioTotal = sum(acessorio)

  useEffect(() => {
    if (parseFloat(minimumBudgetValue) > parseFloat(totalBudgetPayment)) {
      setTotalBudgetPayment(parseFloat(minimumBudgetValue).toFixed(2).replace('.', ','));
    } else {
      setTotalBudgetPayment(sum([acessorioTotal]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudgetValue]);

  const updateState = (index: number, stateVar: string[], setStateRef: any, value: string, quantityMultiply?: string) => {
    const stateCopy = Array.from(stateVar)
    stateCopy.splice(index, 1, multiply('1', value))
    quantityMultiply ? (stateCopy.splice(index, 1, multiply(quantityMultiply, value))) : (<></>)
    setStateRef(stateCopy)
  }

  const submitBudget = () => {
    const acessorioMargin: number[] = []

    acessorio.forEach((item) => {
      if (item != "0,00") {
        acessorioMargin.push(Number(item.replace(',', '.')) + (Number(item.replace(',', '.')) * (props.margin / 100)))
      }
    })

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newBudget`, {
        projectId: activity?.projectId,
        activityId: activity?._id,
        stepId: activity.stepId,
        providerId: props.supplier._id,
        supplierName: props.supplier.supplierName,
        typeSupplier: props.supplier.typeSupplier,
        activityName: activity.activityName,
        conditions,
        selectedStartDate: '',
        workingDays,
        selectedBudget: false,
        budget: {
          Acessorio: acessorioMargin
        },
        minimumBudgetValue,
        totalBudgetPayment: (Number(totalBudgetPayment.replace(',', '.')) + Number(totalBudgetPayment.replace(',', '.')) * (props.margin / 100)).toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  return (
    <div>
      <ListContainerItensAccessories>

        {activity.Accessories.Acessorio?.map((item, index) => (
          <div key={index}>
            {activity.Accessories.Acessorio.length > 1 ? (<P>Acessório {index + 1}</P>) : (<P>Acessório</P>)}
            <div className="upContent">

              {item.attributes?.map((tarefa, index) => (
                <p>{tarefa.itemName ? (<span>{tarefa.itemName}</span>) : ('')}</p>
              ))}
              <span>Preço unit.</span>
              <span className="item">Preço total</span>
            </div>
            <ItemContentListAccessories >
              {item.attributes?.map((tarefa, index) => (
                <p >
                  <span onClick={() => console.log(tarefa.itemName)}>
                    {tarefa.value || tarefa.value && tarefa.unit_of_measurement ? (tarefa.value + '' + tarefa.unit_of_measurement) : ('')}
                  </span>
                </p>
              ))}

              <ContentInputPrice>
                R$:
                <PriceItem onChange={(e) => updateState(index, acessorio, setAcessorio, e.target.value)} />
              </ContentInputPrice>
              {acessorio && acessorio[index] !== '0' ?
                (<p className="item">{acessorio[index]}</p>) :
                (<p className="item">0,00</p>)
              }
            </ItemContentListAccessories>
          </div>
        ))}

      </ListContainerItensAccessories>

      <TotalContainer>
        Valor total: <p>R${totalBudgetPayment}</p>
      </TotalContainer>

      <FooterBudget
        setWorkingDays={setWorkingDays}
        setMinimunBudgetValue={setMinimunBudgetValue}
        setConditions={setConditions}
        submitBudget={() => submitBudget()}
      />
    </div>
  )
};

export default AcessoriosBudget;