import React, { useState, useEffect } from "react";
import { UseEletricActivityContext } from "../../../../Context/ActivitiesContext/Eletric/EletricContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid } from "./style"

import { PassageOfInfraFormType } from "../../../../Models/Eletric/type";
import { attributeType } from "../../../../Models/attributeType";
import { PassageOfInfra } from "../../../../Models/Eletric/PassageOfInfraModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const PassageOfInfraStepForm: React.FC<stepFormProps> = (props) => {
  const { eletricActivities, editEletricActivity, indexEletricActivitySelected } = UseEletricActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<PassageOfInfraFormType>(PassageOfInfra)

  useEffect(() => {
    if (eletricActivities.length > 0) {
      let eletricActivityCopy = eletricActivities[indexEletricActivitySelected]
      eletricActivityCopy.activityType = 'Passagem da infra'
      editEletricActivity?.(indexEletricActivitySelected, eletricActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected])


  useEffect(() => {
    if (eletricActivities.length > 0) {
      setItensFormList(eletricActivities[indexEletricActivitySelected].PassageOfInfra);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected, eletricActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemConduite(0)
      removeItemNovasTomadas(0)
      removeItemNovosInterruptores(0)
      removeItemNovosPontosDeIluminacao(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Conduite") {
      addConduite({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'm linear',
            value: ''
          },
          {
            itemName: 'Material',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Novos interruptores") {
      addNovosInterruptores({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'un',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Novas tomadas") {
      addNovasTomadas({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Medida',
            unit_of_measurement: 'un',
            value: ''
          },
          {
            itemName: 'Tipo',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Novos pontos de iluminacao") {
      addNovosPontosDeIluminacao(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Medida',
              unit_of_measurement: 'un',
              value: ''
            },
            {
              itemName: 'Tipo',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    }
  }

  const addConduite = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Conduite.push(object)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addNovosInterruptores = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosInterruptores.push(object)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addNovasTomadas = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovasTomadas.push(object)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addNovosPontosDeIluminacao = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosPontosDeIluminacao.push(object)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemConduite = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Conduite.splice(index, 1)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemNovosInterruptores = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosInterruptores.splice(index, 1)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemNovasTomadas = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovasTomadas.splice(index, 1)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemNovosPontosDeIluminacao = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosPontosDeIluminacao.splice(index, 1)
    eletricActivitiesCopy[0].PassageOfInfra = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editConduiteValues = (value: string, indexItem: number, indexConduite: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Conduite[indexConduite].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editConduiteAttributeName = (value: string, indexItem: number, indexConduite: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Conduite[indexConduite].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editConduiteUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Conduite[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovosInterruptoresValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosInterruptores[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovosInterruptoresAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosInterruptores[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovosInterruptoresUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosInterruptores[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovasTomadasValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovasTomadas[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovasTomadasAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovasTomadas[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovasTomadasUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovasTomadas[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovosPontosDeIluminacaoValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosPontosDeIluminacao[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovosPontosDeIluminacaoAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosPontosDeIluminacao[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editNovosPontosDeIluminacaoUnitOfMeasurement = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.NovosPontosDeIluminacao[indexItemForm].attributes[indexItem].unit_of_measurement = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.Conduite.length ? (
        <div>
          <P>Conduíte</P>
          {itensFormList.Conduite.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editConduiteAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editConduiteValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall NovosPontosDeIluminacaodo">Drywall NovosPontosDeIluminacaodo</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editConduiteValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editConduiteAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editConduiteValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editConduiteUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemConduite(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.NovosInterruptores.length ? (
        <div>
          <P>Novos interruptores</P>
          {itensFormList.NovosInterruptores.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNovosInterruptoresAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editNovosInterruptoresValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editNovosInterruptoresUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemNovosInterruptores(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.NovasTomadas.length ? (
        <div>
          <P>Novas tomadas</P>
          {itensFormList.NovasTomadas.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNovasTomadasAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editNovasTomadasValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editNovasTomadasUnitOfMeasurement(e, indexAttribute, indexItemForm)} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemNovasTomadas(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.NovosPontosDeIluminacao.length ? (
        <div>
          <P>Novos pontos de iluminação</P>
          {itensFormList.NovosPontosDeIluminacao.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editNovosPontosDeIluminacaoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editNovosPontosDeIluminacaoValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue={attributeItem.unit_of_measurement} options={['m²', 'm³', 'm linear', 'peça', 'cm', 'un', 'm']} function={(e) => editNovosPontosDeIluminacaoUnitOfMeasurement(e, indexAttribute, indexItemForm)} />

                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemNovosPontosDeIluminacao(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default PassageOfInfraStepForm;
