import React, { useEffect } from "react"

import { UseImpermeabilizationActivityContext } from "../../../Context/ActivitiesContext/Impermeabilization/ImpermeabilizationContext"
import { UseStepContext } from "../../../Context/stepContext"

import { textTransform } from "../script"

import { ActionSelect } from "../../stylesDomElements/htmlStyledElements";

const ImpermeabilizationController: React.FC = () => {
    const { activitySelected, setActivitySelected } = UseStepContext()
    const { impermeabilizationActivities, editImpermeabilizationActivity } = UseImpermeabilizationActivityContext()

    useEffect(() => {
        setActivitySelected?.('Impermeabilizacao')
    }, [])

    useEffect(() => {
        if (impermeabilizationActivities.length > 0) {
            var activityEdited = impermeabilizationActivities[0]
            activityEdited.activityType = activitySelected
            activityEdited.activityName = textTransform(activitySelected)
            editImpermeabilizationActivity?.(0, activityEdited)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySelected])

    /* useEffect(()=>{
        if(impermeabilizationActivities.length >0 && impermeabilizationActivities[props.indexSelected].activityType !== ""){
            setOptionSelected(impermeabilizationActivities[props.indexSelected].activityType)
        } 
        impermeabilizationActivities.length >0 && impermeabilizationActivities[props.indexSelected].activityType === "" ?(setOptionSelected('Banheiro')):(<></>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.indexSelected]) */


    return <div>
        <ActionSelect>
            Atividade:
            <select value={activitySelected} name="impermeabilizationStep" id="impermeabilizationStep" onChange={(e) => setActivitySelected?.(e.target.value)}>
                <option value="Impermeabilizacao">Impermeabilização</option>
                <option value="Materiais para impermeabilizacao">Materiais</option>
            </select>
        </ActionSelect>
    </div>
}

export default ImpermeabilizationController