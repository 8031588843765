import React, { useState, useEffect } from "react";

import { UseFurnitureActivityContext } from "../../../../Context/ActivitiesContext/Furniture/FurnitureContext";
import { attributeType } from "../../../../Models/attributeType";
import { FurnitureFormType } from "../../../../Models/Furniture/type";
import { Furniture } from "../../../../Models/Furniture/FurnitureModel";

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import Assignment from "../../Components/Assignment";
import assignmentList from "./assignments.json"
import { AttributeNameItem, ContainerGrid, Select } from "./style";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const FurnitureStepForm: React.FC<stepFormProps> = (props) => {
  const { furnitureActivities, indexFurnitureActivitySelected, editFurnitureActivity } = UseFurnitureActivityContext();

  const [assignment, setAssignment] = useState<string>('')
  const [itensFormList, setItensFormList] = useState<FurnitureFormType>(Furniture)

  useEffect(() => {
    if (furnitureActivities.length > 0) {
      let furnitureActivityCopy = furnitureActivities[indexFurnitureActivitySelected]
      furnitureActivityCopy.activityType = 'Mobiliario'
      editFurnitureActivity?.(indexFurnitureActivitySelected, furnitureActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexFurnitureActivitySelected])

  useEffect(() => {
    if (furnitureActivities.length > 0) {
      setItensFormList(furnitureActivities[indexFurnitureActivitySelected].Furniture)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexFurnitureActivitySelected, furnitureActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemMobiliario(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Mobiliario") {
      addMobiliario({
        attributes: [
          {
            itemName: 'Item',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Link',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    }
  }

  const addMobiliario = (object: attributeType) => {
    var itensFormListCopy = itensFormList
    var furnitureActivitiesCopy = furnitureActivities
    itensFormListCopy.Mobiliario.push(object)
    furnitureActivitiesCopy[0].Furniture = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editFurnitureActivity?.(0, furnitureActivitiesCopy[0])
    console.log('oi')
  }



  const removeItemMobiliario = (index: number) => {
    var itensFormListCopy = itensFormList
    var furnitureActivitiesCopy = furnitureActivities
    itensFormListCopy.Mobiliario.splice(index, 1)
    furnitureActivitiesCopy[0].Furniture = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editFurnitureActivity?.(0, furnitureActivitiesCopy[0])
  }



  const editMobiliarioValues = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var furnitureActivitiesCopy = furnitureActivities
    itensFormListCopy.Mobiliario[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editFurnitureActivity?.(0, furnitureActivitiesCopy[0])
  }

  const editMobiliarioAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    var itensFormListCopy = itensFormList
    var furnitureActivitiesCopy = furnitureActivities
    itensFormListCopy.Mobiliario[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editFurnitureActivity?.(0, furnitureActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      <br />
      {itensFormList.Mobiliario.length ? (
        <div>
          <p>Mobiliario</p>
          {itensFormList.Mobiliario.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipos" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editMobiliarioAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editMobiliarioValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall tabicado">Drywall tabicado</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editMobiliarioValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editMobiliarioAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editMobiliarioValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemMobiliario(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FurnitureStepForm;

