import React, { useState, useEffect } from "react";
import { UseEletricActivityContext } from "../../../../Context/ActivitiesContext/Eletric/EletricContext"

import { InputActivity } from "../../../stylesDomElements/htmlStyledElements";
import { Select, AttributeNameItem, P } from "../../style";
import { ContainerGrid } from "./style"

import { AutomationsFormType } from "../../../../Models/Eletric/type";
import { attributeType } from "../../../../Models/attributeType";
import { Automations } from "../../../../Models/Eletric/AutomationsModel"

import Assignment from "../../Components/Assignment";
import SelectTypeMeasureItem from "../../Components/SelectTypeMeasure";

import assignmentList from "./assignments.json"
import { stepFormProps } from "../../../ModifyActivities/ModifyActivityType";

const AutomationsStepForm: React.FC<stepFormProps> = (props) => {
  const { eletricActivities, editEletricActivity, indexEletricActivitySelected } = UseEletricActivityContext()

  const [assignment, setAssignment] = useState<string>('')

  const [itensFormList, setItensFormList] = useState<AutomationsFormType>(Automations)

  useEffect(() => {
    if (eletricActivities.length > 0) {
      const eletricActivityCopy = eletricActivities[indexEletricActivitySelected]
      eletricActivityCopy.activityType = 'Automacoes'
      editEletricActivity?.(indexEletricActivitySelected, eletricActivityCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected])


  useEffect(() => {
    if (eletricActivities.length > 0) {
      setItensFormList(eletricActivities[indexEletricActivitySelected].Automations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexEletricActivitySelected, eletricActivities]);

  useEffect(() => {
    if (props.stepFormExibition) {
      removeItemAssistenteVirtual(0)
      removeItemBlasterInfravermelho(0)
      removeItemRoteador(0)
      removeItemTomadaSmart(0)
    }
  }, [])

  const addAssignment = () => {
    if (assignment === "Blaster infravermelho") {
      addBlasterInfravermelho({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Assistente virtual") {
      addAssistenteVirtual({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Roteador") {
      addRoteador({
        attributes: [
          {
            itemName: 'Descrição',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Fornecedor',
            unit_of_measurement: '',
            value: ''
          },
          {
            itemName: 'Quantidade',
            unit_of_measurement: '',
            value: ''
          },
        ]
      })
    } else if (assignment === "Tomada smart") {
      addTomadaSmart(
        {
          attributes: [
            {
              itemName: 'Descrição',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Fornecedor',
              unit_of_measurement: '',
              value: ''
            },
            {
              itemName: 'Quantidade',
              unit_of_measurement: '',
              value: ''
            },
          ]
        }
      )
    }
  }

  const addBlasterInfravermelho = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.BlasterInfravermelho.push(object)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addAssistenteVirtual = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.AssistenteVirtual.push(object)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addRoteador = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Roteador.push(object)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const addTomadaSmart = (object: attributeType) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.TomadaSmart.push(object)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemBlasterInfravermelho = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.BlasterInfravermelho.splice(index, 1)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemAssistenteVirtual = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.AssistenteVirtual.splice(index, 1)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemRoteador = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Roteador.splice(index, 1)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const removeItemTomadaSmart = (index: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.TomadaSmart.splice(index, 1)
    eletricActivitiesCopy[0].Automations = itensFormListCopy
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editBlasterInfravermelhoValues = (value: string, indexItem: number, indexBlasterInfravermelho: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.BlasterInfravermelho[indexBlasterInfravermelho].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editBlasterInfravermelhoAttributeName = (value: string, indexItem: number, indexBlasterInfravermelho: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.BlasterInfravermelho[indexBlasterInfravermelho].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }


  const editAssistenteVirtualValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.AssistenteVirtual[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editAssistenteVirtualAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.AssistenteVirtual[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editRoteadorValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Roteador[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editRoteadorAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.Roteador[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editTomadaSmartValues = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.TomadaSmart[indexItemForm].attributes[indexItem].value = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  const editTomadaSmartAttributeName = (value: string, indexItem: number, indexItemForm: number) => {
    const itensFormListCopy = itensFormList
    const eletricActivitiesCopy = eletricActivities
    itensFormListCopy.TomadaSmart[indexItemForm].attributes[indexItem].itemName = value
    setItensFormList(itensFormListCopy)
    editEletricActivity?.(0, eletricActivitiesCopy[0])
  }

  return (
    <div>
      <div>
        <Assignment assignmentList={assignmentList} assignment={assignment} setAssignment={setAssignment} addAssignment={addAssignment} />
      </div>
      {itensFormList.BlasterInfravermelho.length ? (
        <div>
          <P>Blaster infravermelho</P>
          {itensFormList.BlasterInfravermelho.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                attributeItem.itemName === "Tipo" ? (
                  <div >
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editBlasterInfravermelhoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <Select value={attributeItem.value} onChange={(e) => editBlasterInfravermelhoValues(e.target.value, indexAttribute, indexItemForm)}>
                      <option value="Gesso">Gesso</option>
                      <option value="Pvc">Pvc</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Isopor">Isopor</option>
                      <option value="Cimento">Cimento</option>
                      <option value="Aparente">Aparente</option>
                      <option value="Fibra">Fibra</option>
                      <option value="Drywall reto">Drywall reto</option>
                      <option value="Drywall TomadaSmartdo">Drywall TomadaSmartdo</option>
                      <option value="Jateamento de celulose">Jateamento de celulose</option>
                      <option value="Outros">Outros</option>
                    </Select>
                    <div>{attributeItem.value === "Outros" ? (
                      <div>
                        <InputActivity>
                          <input type="text" id="tipooutros" placeholder="Digite o tipo"
                            value={attributeItem.value}
                            onChange={(e) => editBlasterInfravermelhoValues(e.target.value, indexAttribute, indexItemForm)}
                          />
                        </InputActivity>
                      </div>
                    ) : (
                      <></>
                    )}</div>

                  </div>
                ) : (
                  <div className="resizeContentInputs">
                    <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editBlasterInfravermelhoAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                    <InputActivity>
                      <input type="text" value={attributeItem.value} onChange={(e) => editBlasterInfravermelhoValues(e.target.value, indexAttribute, indexItemForm)} />
                      {attributeItem.unit_of_measurement ? (
                        <SelectTypeMeasureItem defaultValue="m²" options={['m²']} function={() => () => { }} />
                      ) : (<></>)}
                    </InputActivity>
                  </div>
                )
              ))}
              <div className="cancelX" onClick={() => removeItemBlasterInfravermelho(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.AssistenteVirtual.length ? (
        <div>
          <P>Assistente virtual</P>
          {itensFormList.AssistenteVirtual.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editAssistenteVirtualAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editAssistenteVirtualValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue="m linear" options={['m linear']} function={() => () => { }} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemAssistenteVirtual(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.Roteador.length ? (
        <div>
          <P>Roteador</P>
          {itensFormList.Roteador.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editRoteadorAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editRoteadorValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue="m linear" options={['m linear']} function={() => () => { }} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemRoteador(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}

      {itensFormList.TomadaSmart.length ? (
        <div>
          <P>Tomada smart</P>
          {itensFormList.TomadaSmart.map((itemForm, indexItemForm) => (
            <ContainerGrid key={indexItemForm}>
              {itemForm.attributes.map((attributeItem, indexAttribute) => (
                <div className="resizeContentInputs">
                  <AttributeNameItem placeholder={attributeItem.itemName} onChange={(e) => editTomadaSmartAttributeName(e.target.value, indexAttribute, indexItemForm)} />
                  <InputActivity>
                    <input type="text" value={attributeItem.value} onChange={(e) => editTomadaSmartValues(e.target.value, indexAttribute, indexItemForm)} />
                    {attributeItem.unit_of_measurement ? (
                      <SelectTypeMeasureItem defaultValue="m linear" options={['m linear']} function={() => () => { }} />
                    ) : (<></>)}
                  </InputActivity>
                </div>
              ))}
              <div className="cancelX" onClick={() => removeItemTomadaSmart(indexItemForm)}>X</div>
            </ContainerGrid>
          ))}
        </div>
      ) : (<></>)}
    </div >
  );
};

export default AutomationsStepForm;
