export const Demolition = {
  ParedeDeAlvenaria: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  Drywall: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  RevestimentoDePiso: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  RevestimentoDeParede: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  ContraPiso: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  PedrasEMarmores: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  Forro: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  Marcenaria: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "m²",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  ItensASeremDesmontados: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "un",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  ItensEspeciaisDemolicao: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "un",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
  OutrosDemolicao: [
    {
      attributes: [
        {
          itemName: "Descrição",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Material",
          unit_of_measurement: "",
          value: "",
        },
        {
          itemName: "Medida",
          unit_of_measurement: "un",
          value: "",
        },
        {
          itemName: "Quantidade",
          unit_of_measurement: "",
          value: "",
        },
      ],
    },
  ],
};
