import React, { useContext, useState } from "react";
import { MaterialsFormType } from "../../../Models/attributeType";
import { Automations } from "../../../Models/Eletric/AutomationsModel";
import { EletricPanel } from "../../../Models/Eletric/EletricPanelModel";
import { Installations } from "../../../Models/Eletric/InstallationsModel";
import { PassageOfInfra } from "../../../Models/Eletric/PassageOfInfraModel";
import { PassageOfWires } from "../../../Models/Eletric/PassageOfWiresModel";
import {
  AutomationsFormType,
  EletricPanelFormType,
  InstallationsFormType,
  PassageOfInfraFormType,
  PassageOfWiresFormType,
} from "../../../Models/Eletric/type";
import { Materials } from "../../../Models/Materials/MaterialsModel";
import { UserProviderProps } from "../../../types/typesEntitiesProject";

interface painelProps {
  amperagem: string;
  fotoPainel?: FileList[];
  altura: string;
  largura: string;
  profundidade: string;
}
interface automationsProps {
  descricao: string;
  quantidade: string;
  fabricante: string;
}

interface instalationProps {
  descricao: string;
  quantidade: string;
  fabricante: string;
  altura: string;
}

export interface activitiesEletricProps {
  _id?: string;
  projectId?: string;
  stepId?: string;
  budgetId?: [];
  activityName: string;
  activityStatus: string;
  activityType: string;
  stepType: string;
  activityDescription?: string;
  conditions?: string;
  dateType?: string;
  startDate?: string;
  endDate?: string;
  daysBetween?: string;
  daysAfter?: string;
  previousActivityId?: string;
  time?: string;
  setReminder?: boolean;
  reminderValue?: string;
  clientCheck?: boolean;
  supplierCheck?: boolean;
  percentage?: number;
  selectedBudget?: boolean;
  requestCotation: boolean;
  partialDivisor: string;
  estimatedTime?: number;
  estimatedValue?: number;

  EletricPanel: EletricPanelFormType;
  PassageOfInfra: PassageOfInfraFormType;
  PassageOfWires: PassageOfWiresFormType;
  Installations: InstallationsFormType;
  Automations: AutomationsFormType;
  Materials: MaterialsFormType;
}

interface ActivitiesContextType {
  eletricActivities: activitiesEletricProps[];
  eletricActivityName: string;
  indexEletricActivitySelected: number;
  setActivity?: React.Dispatch<React.SetStateAction<activitiesEletricProps[]>>;
  setNewEletricActivity?: () => void;
  setEletricActivityName?: React.Dispatch<React.SetStateAction<string>>;
  setIndexEletricActivitySelected?: React.Dispatch<
    React.SetStateAction<number>
  >;
  editEletricActivity?: (
    index: number,
    activity: activitiesEletricProps
  ) => void;
  deleteEletricActivity?: (index: number) => void;
}

const activityContextDefault = {
  eletricActivities: [],
  eletricActivityName: "",
  indexEletricActivitySelected: 0,
  setNewEletricActivity: () => {},
  setEletricActivityName: () => {},
  setIndexEletricActivitySelected: () => {},
  editEletricActivity: () => {},
  deleteEletricActivity: () => {},
};

const ActivitiesContext = React.createContext<ActivitiesContextType>(
  activityContextDefault
);

export const UseEletricActivityContext = () => useContext(ActivitiesContext);

export const EletricActivityContextProvider: React.FC<UserProviderProps> = ({
  children,
}) => {
  const [eletricActivities, setActivity] = useState<activitiesEletricProps[]>(
    activityContextDefault.eletricActivities
  );
  const [indexEletricActivitySelected, setIndexEletricActivitySelected] =
    useState<number>(0);
  const [eletricActivityName, setEletricActivityName] =
    useState<string>("Painel elétrico");

  const editEletricActivity = (
    index: number,
    activity: activitiesEletricProps
  ) => {
    let activitiesEdited = eletricActivities;
    activitiesEdited[index] = activity;
    setActivity([...activitiesEdited]);
  };

  const deleteEletricActivity = (index: number) => {
    const activity = [...eletricActivities];
    activity.splice(index, 1);
    setActivity(activity);
  };

  const setNewEletricActivity = () => {
    setActivity((prev) => [
      ...prev,
      {
        projectId: "",
        stepId: "",
        budgetId: [],
        activityName: eletricActivityName,
        activityStatus: "inProgress",
        activityType: "",
        stepType: "Eletrica",
        activityDescription: "",
        conditions: "",
        dateType: "initFinish",
        startDate: "",
        endDate: "",
        daysBetween: "",
        daysAfter: "",
        previousActivityId: "",
        time: "",
        setReminder: false,
        reminderValue: "",
        clientCheck: false,
        supplierCheck: false,
        percentage: 0,
        selectedBudget: false,
        requestCotation: false,
        partialDivisor: "",
        estimatedTime: 0,
        estimatedValue: 0,

        EletricPanel: EletricPanel,
        PassageOfInfra: PassageOfInfra,
        PassageOfWires: PassageOfWires,
        Installations: Installations,
        Automations: Automations,
        Materials: Materials,
      },
    ]);
  };

  return (
    <ActivitiesContext.Provider
      value={{
        eletricActivities,
        eletricActivityName,
        indexEletricActivitySelected,
        setActivity,
        setEletricActivityName,
        setNewEletricActivity,
        editEletricActivity,
        deleteEletricActivity,
        setIndexEletricActivitySelected,
      }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};
