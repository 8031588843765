import React, { useState } from "react";

import { stateType } from "../../../SelectionBudget/type";

interface activityBudgetsProps {
    _id?: string,
    activityName?: string;
    activityType?: string;

    formato_sacada?:string,
    altura_sacada?:string,
    largura_frente_sacada?:string,
    largura_esquerda_sacada?:string,
    largura_direita_sacada?:string,
    tipo_vidro?:string,
    modelo_ferragem?:string,
    cor_ferragem?:string,
    caixa_ar_condicionado?:boolean,
}

const BalconyClosingDescription: React.FC<stateType> = (props) => {
    const [activity] = useState<activityBudgetsProps>(props.activity);

    return (
        <div>
            <div className="item">
                {activity?.formato_sacada ? (
                    <div className="line">
                        <p>Formato da sacada</p>
                        <p>
                            {activity.formato_sacada}
                        </p>
                    </div>
                ) : null}

                <h4><br/>Dimensões da sacada</h4>

                {activity?.altura_sacada ? (
                    <div className="line">
                        <p>Altura</p>
                        <p>
                            {activity.altura_sacada} m
                        </p>
                    </div>
                ) : null}

                {activity?.largura_frente_sacada ? (
                    <div className="line">
                        <p>Largura da frente</p>
                        <p>
                            {activity.largura_frente_sacada} m
                        </p>
                    </div>
                ) : null}

                {activity?.largura_esquerda_sacada ? (
                    <div className="line">
                        <p>Largura do lado esquerdo</p>
                        <p>
                            {activity.largura_esquerda_sacada} m
                        </p>
                    </div>
                ) : null}

                {activity?.largura_direita_sacada ? (
                    <div className="line">
                        <p>Largura do lado direito</p>
                        <p>
                            {activity.largura_direita_sacada} m
                        </p>
                    </div>
                ) : null}

                <br/>

                {activity?.tipo_vidro ? (
                    <div className="line">
                        <p>Tipo de vidro</p>
                        <p>
                            {activity.tipo_vidro} 
                        </p>
                    </div>
                ) : null}

            <h4>Ferragem</h4>
                <br/>

                {activity?.modelo_ferragem ? (
                    <div className="line">
                        <p>Modelo</p>
                        <p>
                            {activity.modelo_ferragem} 
                        </p>
                    </div>
                ) : null}

                {activity?.cor_ferragem ? (
                    <div className="line">
                        <p>Cor</p>
                        <p>
                            {activity.cor_ferragem} 
                        </p>
                    </div>
                ) : null}

                {activity?.caixa_ar_condicionado ? (
                    <div className="line">
                        <p><br/>Precisa de caixa para ar condicionado</p>
                        <p>
                            {activity.caixa_ar_condicionado} 
                        </p>
                    </div>
                ) : null}

            </div>
        </div>
    );
};

export default BalconyClosingDescription;
