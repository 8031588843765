import styled from "styled-components";

interface CompleteProps {
  width: string;
  backgroundColor: string;
}

interface ActivityNameProps {
  isEditAuthorized: boolean;
}

export const Container = styled.div`
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  position: relative;
  .optionsClick {
    cursor: pointer;
  }
`;
export const Complete = styled.div<CompleteProps>`
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  p {
    padding: 0.2rem;
    margin-left: 1rem;
    color: #777777;
  }
`;

export const ActivityContent = styled.div<ActivityNameProps>`
  width: 16rem;
  position: absolute;
  display: flex;
  justify-content: space-between;
  cursor: ${(props) => (props.isEditAuthorized ? "pointer" : "not-allowed")};
`;

export const ActivityTitle = styled.a`
  max-width: 16ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 480px) {
    max-width: 12ch;
  }
`;

export const ActivityDetailsRow = styled.p`
  display: flex;
  white-space: nowrap;
  align-items: center;
  @media (max-width: 480px) {
    display: grid;
    min-width: 38rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const MenuOverlay = styled.div`
  position: absolute;
  background-color: var(--dark-background);
  border-radius: 0.5rem;
  display: grid;
  left: 0;
  margin-left: 15rem;
  margin-top: 1.2rem;
  z-index: 3;
`;

export const Input = styled.input`
  border: none;
  background-color: transparent;
  padding: 0.2rem;
  margin-left: 1rem;
  color: #777777;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--fontSpaceGotesk);
`;
