import React, { useState, useEffect } from "react";
import axios from "axios";
import { verify } from "jsonwebtoken";


import { UseUserContext } from "../Context/UserContext"

interface privateProps {
    children: React.ReactNode;
}

const PrivateRoute: React.FC<privateProps> = (props) => {
    const { setImageProfile, setTypeUser, setUserId, setToken } = UseUserContext()
    const [dataDecodeJwt, setDataDecodeJwt] = useState<any>()
    const [statusValidate, setStatusValidate] = useState<boolean>(false)

    useEffect(() => {
        validadeteAccess()
    },[statusValidate])

    useEffect(() => {
        if (dataDecodeJwt?.idReferenceRegister) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/listArchitect/${dataDecodeJwt?.idReferenceRegister}`)
                .then((res) => {
                    if (res.data[0].logoImage) {
                        setImageProfile?.(res.data[0].logoImage)
                    }
                });
        }
    }, [])

    const validadeteAccess = () => {
        const token = localStorage.getItem("token") || '';
        verify(token, process.env.REACT_APP_SECRET_KEY_JWT || '', (err, data) => {
            setDataDecodeJwt(data)
            if (err?.message === 'jwt expired') {
                window.location.href = '/'
            } else if (err) {
                console.log(err)
                window.location.href = "/"
            } else if (dataDecodeJwt?.typeUser !== "supplier") {
                setToken?.(token || '');
                setUserId?.(dataDecodeJwt?.idReferenceRegister);
                setTypeUser?.(dataDecodeJwt?.typeUser);
                setStatusValidate(true)
            }
        });
    }

    return (
        <>
            {statusValidate ? (
                props.children
            ) : (
                <></>
            )}
        </>
    )

}

export default PrivateRoute